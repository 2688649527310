import React, { useState, useRef } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, Input as InputBase, InputGroup, InputGroupAddon, InputGroupText, Row, Col } from "reactstrap";
import FormGroup, { isUrl } from "../components/FormGroup";
import Input from "../components/Input";
import MutationButton from "../components/MutationButton";
import { ApiErrors, useMutation, useReadyState } from "../hooks/ApiProvider";
import { OrganizationModel, OrganizationInputModel } from "../types/api-graph-types";
import ReactCrop, { Crop } from "react-image-crop";

export const Fragment = `
  id
  key
  type
  name
  unitName
  nickname
  logo
  industry
  description
  websiteUrl
  phoneNumbers
  emailAddresses
  emailDomain
`;

const _updateOrganization = `mutation updateOrganization($id:ID! $value:OrganizationInputModel) {
  organization(id:$id) {
    change(value:$value)
    commit { ${Fragment} }
  }
}`
const _createOrganization = `mutation createOrganization($value:OrganizationInputModel) {
  organization: addOrganization {
    change(value:$value)
    commit { ${Fragment} }
  }
}`

interface OperationResults {
  organization: {
    commit: OrganizationModel
  }
}

interface OperationErrors {
  organization: {
    change: ApiErrors<{ value: OrganizationInputModel }>
  }
}

export const New: OrganizationModel = {
  id: "",
} as any;

const _toOrganizationInputModel = (value: OrganizationModel): OrganizationInputModel => ({
  key: value.key,
  type: value.type,
  name: value.name,
  unitName: value.unitName,
  nickname: value.nickname,
  industry: value.industry,
  logo: value.logo,
  description: value.description,
  websiteUrl: value.websiteUrl,
  phoneNumbers: value.phoneNumbers,
  emailAddresses: value.emailAddresses,
  emailDomain: value.emailDomain,
});

function getHostname(url: string) {
  var a = document.createElement("a");
  a.href = url;
  return a.hostname.toLowerCase();
}

const _invalidDomainPart = /[^a-zA-Z0-9-]/g;
const _invalidDomain = /[^a-zA-Z0-9-.]/g;
const _commonSuffixes = [".ac.in", ".edu.in", ".co.in", ".firm.in"];
const _commonPrefixes = ["www."];
const _publicDomains = ["www.linkedin.com", "in.linkedin.com"]

export default ({ isOpen, value, onUpdate, toggle }: {
  isOpen?: boolean,
  value: OrganizationModel,
  onUpdate?: (value: OrganizationModel) => void,
  toggle?: () => void
}) => {
  const [readyState, setReadyState] = useReadyState();
  const [input, setInput] = useState(_toOrganizationInputModel(value));
  const [imageFile, setImageFile] = useState<File>();
  const [imageData, setImageData] = useState<string>();
  const [crop, setCrop] = useState({ aspect: 1 / 1, unit: "%", width: 100 } as Crop);
  const cropImage = useRef<HTMLImageElement>();
  const [errors, setErrors] = useState<ApiErrors<OrganizationInputModel>>({});
  const updateInput = (props: Partial<OrganizationInputModel>) => setInput({ ...input, ...props });
  const [mutation] = useMutation<OperationResults>();
  const create = async () => {
    try {
      const result = await mutation(_createOrganization, { value: input }, { setReadyState });
      onUpdate?.(result.organization.commit);
      toggle?.();
    } catch (errors) {
      setErrors((errors as OperationErrors).organization.change.value || {})
    }
  }
  const change = async () => {
    try {
      const result = await mutation(_updateOrganization, { id: value.id, value: input }, { setReadyState });
      onUpdate?.(result.organization.commit);
      toggle?.();
    } catch (errors) {
      setErrors((errors as OperationErrors).organization.change.value || {})
    }
  }
  const cancel = () => {
    setInput(_toOrganizationInputModel(value));
    toggle?.();
  }
  const updateImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]?.name) {
      const file = e.target.files[0];
      const reader = new FileReader();
      setImageFile(file);
      reader.addEventListener('load', () => setImageData(reader.result as string));
      reader.readAsDataURL(file);
    }
  }
  const updateLogo = (crop: Crop) => {
    if (cropImage.current && crop.width && crop.height) {
      const image = cropImage.current;
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = 128; // crop.width;
      canvas.height = 128; // crop.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(image, (crop.x ?? 0) * scaleX, (crop.y ?? 0) * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, canvas.width, canvas.height);
        const logo = canvas.toDataURL("image/png");
        updateInput({ logo });
      }
    }
  }
  const tryUpdateDomains = () => {
    const domain = isUrl(input.websiteUrl) ? getHostname(input.websiteUrl) : "";
    if (!_publicDomains.includes(domain)) {
      const parts = domain.split(".");
      const suffixlen = (_commonSuffixes.filter(_ => domain.endsWith(_))[0] ?? ".tld").split(".").length;
      const prefixlen = (_commonPrefixes.filter(_ => domain.startsWith(_))[0] ?? "").split(".").length - 1;
      const fqsld = parts.length >= suffixlen ? parts.slice(prefixlen).join(".") : undefined;
      const slug = parts.length >= suffixlen ? parts.slice(prefixlen, parts.length - suffixlen + 1).reverse().join("-") : undefined;
      const emailDomain = input.emailDomain || fqsld || input.emailDomain;
      const key = input.key || slug || input.key;
      updateInput({ emailDomain, key });
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header w-100">
        <FormGroup label="Name" className="w-100" error={errors.name} value={input.name || ""}>
          <Input type="text" className="form-control-lg" placeholder="Enter your organization name here" onUpdate={name => updateInput({ name })} />
        </FormGroup>
      </div>
      <ModalBody>
        <Form>
          <fieldset>
            <FormGroup label='Subunit name' error={errors.unitName} value={input.unitName || ""}>
              <Input type="text" placeholder="Enter your organization subunit name here" onUpdate={unitName => updateInput({ unitName })} />
            </FormGroup>
            <FormGroup label='Abbreviated name' error={errors.nickname} value={input.nickname || ""}>
              <Input type="text" placeholder="Enter your organization's abbreviated name here" onUpdate={nickname => updateInput({ nickname })} />
            </FormGroup>
            <FormGroup label='Description' error={errors.description} value={input.description || ""} showPreview>
              <Input type="textarea" rows={5} placeholder="Describe your organization here" onUpdate={description => updateInput({ description })} />
            </FormGroup>
            <FormGroup label='Logo' error={errors.logo}>
              <InputGroup>
                {input.logo &&
                  <InputGroupAddon addonType="prepend" className="bg-input">
                    <img width={36} height={36} src={input.logo} />
                  </InputGroupAddon>
                }
                <div className="custom-file">
                  <InputBase type="file" accept="image/*" className="custom-file-input" onChange={updateImage} />
                  <label className={`custom-file-label text-truncate text-nowrap ${!imageFile?.name && "text-muted"}`}>
                    {imageFile?.name || "Select your logo here"}
                  </label>
                </div>
                {imageData &&
                  <InputGroupAddon addonType="append">
                    <Button color="primary" onClick={() => setImageData(undefined)}>Done</Button>
                  </InputGroupAddon>
                }
              </InputGroup>
              {imageData && <ReactCrop src={imageData} crop={crop} onImageLoaded={img => cropImage.current = img} onChange={setCrop} onComplete={updateLogo} />}
            </FormGroup>
            <FormGroup label='Website' error={errors.websiteUrl} uri value={input.websiteUrl || ""}>
              <Input type="url" placeholder="Enter your organization's website address" onUpdate={websiteUrl => updateInput({ websiteUrl })} onBlur={tryUpdateDomains} />
            </FormGroup>
            <Row form>
              <Col>
                <FormGroup label='Email' error={errors.emailAddresses} emails value={input.emailAddresses || ""} help="Separate multiple emails with commas.">
                  <Input type="text" placeholder="Enter contact email" onUpdate={emailAddresses => updateInput({ emailAddresses })} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup label='Phone' error={errors.phoneNumbers} phones value={input.phoneNumbers || ""} help="Separate multiple phones with commas.">
                  <Input type="text" placeholder="Enter contact phone" onUpdate={phoneNumbers => updateInput({ phoneNumbers })} />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup label='Email domain' error={errors.emailDomain} invalidCharacters={_invalidDomain} value={input.emailDomain || ""}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="pr-0">@</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" className="pl-0" placeholder="Enter your organization's exclusive email domain" onUpdate={emailDomain => updateInput({ emailDomain })} />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup label='Managed email domain' error={errors.key} invalidCharacters={_invalidDomainPart} lower value={input.key || ""}>
                  <InputGroup>
                    <Input className="text-right pr-0" type="text" onUpdate={key => updateInput({ key })} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="pl-0">.higherknowledge.in</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>Cancel</Button>
        {!value.id && <MutationButton readyState={readyState} color="primary" onClick={create}>Post</MutationButton>}
        {value.id && <MutationButton readyState={readyState} color="primary" onClick={change}>Update</MutationButton>}
      </ModalFooter>
    </Modal>
  );
}

