import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { faKey, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Collapse, Form, Input, InputGroup, InputGroupAddon } from "reactstrap";
import FormGroup, { isEmail, isPhone } from "../components/FormGroup";
import MutationButton from "../components/MutationButton";
import { asType, OAuth2TokenError, ReadyState, useOAuth2, useReadyState } from "../hooks/ApiProvider";
import { useToggle } from "../hooks/CommonHooks";
import { useNavigation, useQueryString, updateURIComponents } from "../hooks/NavigationHook";
import { useAuthorizationToken } from "../hooks/TokenProvider";
import { ErrorType } from "../types/api-graph-types";

library.add(faUser, faEnvelope, faMobileAlt, faKey);

const _errorInvalidUsername = "https://higherknowledge.in/our/docs/error-invalid-username";
const _errorMissingOtp = "https://higherknowledge.in/our/docs/error-missing-password";
const _errorInvalidOtp = "https://higherknowledge.in/our/docs/error-invalid-password";
const _errorUnauthorizedClient = "https://higherknowledge.in/our/docs/error-unauthorized-client";

export enum View {
  Message = "MESSAGE",
  Authenticator = "AUTHENTICATOR"
}

export default ({ view, scope, signUp = false, onSignIn }: {
  view?: View,
  signUp?: boolean,
  scope?: string,
  onSignIn: (accessToken: string, expiresIn: string, refreshToken: string) => void
}) => {
  const [queryString] = useQueryString({ login_hint: "" });
  const { login_hint: loginHint } = queryString;
  const [loginHintUsername, loginHintPassword] = (loginHint || "").split(":");
  const [username, setUsername] = useState({ value: loginHintUsername || "", isInvalid: "", error: ErrorType.Ok });
  const [password, setPassword] = useState({ value: loginHintPassword || "", isInvalid: "", error: ErrorType.Ok });
  const [isOpen, toggle] = useToggle(view === View.Authenticator || !!password.value);
  const [otpReadyState, setOtpReadyState] = useReadyState();
  const [signinReadyState, setSigninReadyState] = useReadyState();
  const [, exchange] = useOAuth2();
  const submit = (otp?: string) => async () => {
    const setReadyState = otp ? setSigninReadyState : setOtpReadyState;
    setReadyState(ReadyState.Loading);
    try {
      const { protocol, host, pathname } = window.location;
      const search = updateURIComponents(window.location.search || "?", { login_hint: "", code: "" });
      const hash = updateURIComponents(window.location.hash || "#", { error: "", error_uri: "", error_description: "" });
      const redirect_uri = `${protocol}//${host}${pathname}${search}${hash}`;
      const response = await exchange({
        grantType: "password",
        username: username.value,
        password: otp,
        scope: scope,
        redirect_uri
      });
      setReadyState(ReadyState.Complete);
      onSignIn(response.access_token, response.expires_in, response.refresh_token);
    } catch (error) {
      setReadyState(ReadyState.Error);
      if (asType<OAuth2TokenError>(error)) {
        toggle(error.error_uri === _errorMissingOtp || error.error_uri === _errorInvalidOtp);
        if (error.error_uri === _errorInvalidOtp) {
          setPassword({ value: "", isInvalid: "is-invalid", error: ErrorType.InvalidVerificationCode });
        } else if (error.error_uri === _errorUnauthorizedClient) {
          setUsername({ ...username, isInvalid: "is-invalid", error: ErrorType.Unauthorized });
          setPassword({ value: "", isInvalid: "", error: ErrorType.Ok });
        } else if (error.error_uri === _errorInvalidUsername) {
          setUsername({ ...username, isInvalid: "is-invalid", error: ErrorType.Invalid });
          setPassword({ value: "", isInvalid: "", error: ErrorType.Ok });
        } else if (error.error_uri) {
          // Unknown OAuth error. Ignore
        } else {
          // Unhandled
          throw error;
        }
      }
    }
  }
  useEffect(() => {
    loginHintUsername && loginHintPassword && submit(loginHintPassword)();
  }, [loginHint]);

  const isSignIn = !signUp;
  const isMessage = view === View.Message;
  const isAuthenticator = view === View.Authenticator;
  const isValid = isMessage ? isEmail(username.value) || isPhone(username.value)
    : isAuthenticator ? isEmail(username.value) && password.value.length === 6
      : false;
  const actionLabel = isSignIn ? "Sign in" : "Sign up";
  const actionText = isSignIn ? "sign-in" : "sign-up";
  const help = isAuthenticator ? ""
    : isEmail(username.value) ? `If you didn't receive the OTP check your spam or junk folder, or ${actionText} without an OTP using Facebook, LinkedIn, or Google above.`
      : isPhone(username.value) ? `If you didn't receive the OTP try resending, or ${actionText} without an OTP using Facebook, LinkedIn, or Google above.`
        : undefined;

  return (
    <Form>
      <FormGroup error={username.error}>
        {isMessage &&
          <InputGroup>
            <InputGroupAddon addonType="prepend" className="input-group-text px-1">
              {isEmail(username.value) ? <FontAwesomeIcon size="lg" fixedWidth icon={["far", "envelope"]} />
                : isPhone(username.value) ? <FontAwesomeIcon size="lg" fixedWidth icon={["fas", "mobile-alt"]} />
                  : <FontAwesomeIcon size="lg" fixedWidth icon={["far", "user"]} />}
            </InputGroupAddon>
            <Input className={`pl-0 ${username.isInvalid}`} type="text" value={username.value} spellCheck={false} placeholder="Enter your email or phone" onChange={e => { setUsername({ value: e.target.value.trim(), isInvalid: "", error: ErrorType.Ok }); toggle(false); }} />
          </InputGroup>
        }
        {isAuthenticator &&
          <InputGroup>
            <InputGroupAddon addonType="prepend" className="input-group-text px-1">
              <FontAwesomeIcon size="lg" fixedWidth icon={["far", "envelope"]} />
            </InputGroupAddon>
            <Input className={`pl-0 ${username.isInvalid}`} type="text" value={username.value} spellCheck={false} placeholder="Enter your email" onChange={e => { setUsername({ value: e.target.value.trim(), isInvalid: "", error: ErrorType.Ok }); toggle(false); }} />
          </InputGroup>
        }
      </FormGroup>
      <Collapse isOpen={isOpen || isAuthenticator}>
        <FormGroup error={password.error} help={help}>
          <InputGroup>
            <InputGroupAddon addonType="prepend" className="input-group-text px-1">
              <FontAwesomeIcon size="lg" fixedWidth icon={["fas", "key"]} />
            </InputGroupAddon>
            <Input className={`pl-0 ${password.isInvalid}`} type="text" inputMode="numeric" pattern="[0-9]*" value={password.value} spellCheck={false} placeholder="OTP" onChange={e => setPassword({ value: e.target.value.replace(/[^0-9]/, "").substring(0, 6), isInvalid: "", error: ErrorType.Ok })} />
          </InputGroup>
        </FormGroup>
      </Collapse>
      {isAuthenticator && <MutationButton readyState={signinReadyState} color="primary" disabled={username.error !== ErrorType.Ok || !isValid} onClick={submit(password.value)}>{actionLabel}</MutationButton>}
      {isMessage && !isOpen && <MutationButton readyState={otpReadyState} color="primary" disabled={username.error !== ErrorType.Ok || !isValid} onClick={submit()}>Send OTP</MutationButton>}
      {isMessage && isOpen && <MutationButton readyState={signinReadyState} color="primary" disabled={!password.value || password.value.length !== 6 || password.error !== ErrorType.Ok} onClick={submit(password.value)}>{actionLabel}</MutationButton>}
      {isMessage && isOpen && <MutationButton readyState={otpReadyState} color="link" disabled={username.error !== ErrorType.Ok || !isValid} onClick={submit()}>Resend OTP</MutationButton>}
    </Form>
  );
}
