import React from "react";
import { CardText } from "reactstrap";
import { LegalResearchConclusionModel, ReviewOption, TaskType, TaskModel } from "../../types/api-graph-types";
import FormGroup, { len } from "../../components/FormGroup";
import Input from "../../components/Input";
import { ApiErrors, useMutation } from "../../hooks/ApiProvider";
import Highlight from "../../components/Highlight";

export const Fragment = `... on LegalResearchConclusionModel { 
  opinion
  action
  clarification
  description
}`;

const _updateTaskDetails = `mutation updateLegalResearchConclusionTaskDetails($id:ID! $value:LegalResearchConclusionInputModel) {
  task(id:$id) {
    details { ... on LegalResearchConclusionOperations { change(value:$value) } } 
    commit { details { ${Fragment} } }
  }
}`;

interface OperationResults {
  task: {
    commit: LegalResearchConclusionModel
  }
};

const ToInput = (value: LegalResearchConclusionModel) => ({
  opinion: value.opinion || "",
  action: value.action || "",
  clarification: value.clarification || ""
} as any);

export const CommentOptions: [number, number, ReviewOption, number, string][] = [
  [1, 2, ReviewOption.Empty, 0, ""],
  [1, 2, ReviewOption.Spam, -1, ""],
  [1, 2, ReviewOption.Offensive, -1, ""],
  [1, 2, ReviewOption.Plagiarised, -1, ""],
  [3, 6, ReviewOption.LegalResearchConclusionOpinionPoor, -1, ""],
  [3, 6, ReviewOption.LegalResearchConclusionActionItemPoor, 0, ""],
  [3, 6, ReviewOption.LegalResearchConclusionClarifyingQuestionPoor, -1, ""],
  [7, 10, ReviewOption.LegalResearchConclusionOpinionExcellent, 1, ""],
  [7, 10, ReviewOption.LegalResearchConclusionActionItemExcellent, 1, ""],
  [7, 10, ReviewOption.LegalResearchConclusionClarifyingQuestionExcellent, 1, ""],
  [3, 8, ReviewOption.LanguagePoor, -1, ""],
  [9, 10, ReviewOption.LanguageExcellent, 1, ""],
];

export const Is = (value: TaskModel): value is TaskModel & { details: LegalResearchConclusionModel } =>
  value.type === TaskType.LegalResearchConclusion

export const IsValid = (value: LegalResearchConclusionModel) =>
  value.opinion && len(value.opinion) <= 140
  && value.action && len(value.action) <= 140
  && value.clarification && len(value.clarification) <= 140
  || false;

export function InputText({ id, value, help, onUpdate }: {
  value: LegalResearchConclusionModel,
  id: string,
  help?: boolean,
  onUpdate: (value: LegalResearchConclusionModel) => void
}) {
  const input = ToInput(value || {});
  const [mutation] = useMutation<OperationResults>();
  const update = async (partial: Partial<LegalResearchConclusionModel>) => {
    const value = { ...input, ...partial };
    onUpdate(value);
    await mutation(_updateTaskDetails, { id, value }, { debounce: 5000 });
  }
  return (
    <CardText tag='div'>
      <FormGroup label="Opinion" openHelp={help} help="Based on your research above, answer the question posed to you at the top. In some cases you answer may be a simple &quot;Yes, as long as ...&quot; or &quot;No, because ...&quot;. In other cases it may be less certain &quot;Quite possiby, if ...&quot; or &quot;Unlikely, unless ...&quot;. Try to be concise and to-the-point. Do not repeat the question, or copy-paste from the act or judgement. Limit your answer to 140 characters (around 30 words; 3 sentences)." required minLength={14} maxLength={140} value={input.opinion}>
        <Input type="textarea" onUpdate={opinion => update({ opinion })} />
      </FormGroup>
      <FormGroup label="Action" openHelp={help} help="Suggest one or two concrete steps the person can take next. Try to start each sentence with a verb (e.g., &quot;Find documents that ...&quot;, &quot;Meet with ...&quot;, etc.). Limit 140 characters." required maxLength={140} value={input.action}>
        <Input type="textarea" onUpdate={action => update({ action })} />
      </FormGroup>
      <FormGroup label="Clarification" openHelp={help} help="If you think your client could provide one new piece of information that would change your opinion above or the suggested next steps, pose that as a question for your client to answer. Limit 140 characters." required maxLength={140} value={input.clarification} >
        <Input type="textarea" onUpdate={clarification => update({ clarification })} />
      </FormGroup>
    </CardText>
  );
}

export default ({ value }: { value: LegalResearchConclusionModel }) => {
  const description = `${value.opinion} ${value.action} ${value.clarification}`;
  return (
    <CardText className='text-justify'>
      <Highlight value={description} />
    </CardText>
  );
}
