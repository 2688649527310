import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import { faCalendarAlt, faCheckSquare, faClock, faCommentAlt, faHeart, faShareSquare, faStar as farStar, faThumbsUp as farThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faCircle, faEllipsisH, faPlus, faSlash, faStar as fasStar, faStarHalfAlt, faTasks, faThumbsUp as fasThumbsUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Popper from "popper.js";
import React, { createContext, CSSProperties, MouseEventHandler, ReactNode, SVGProps, useContext } from "react";
import { Badge, BadgeProps, Button, ButtonGroup, ButtonGroupProps, ButtonProps, ButtonToolbar, ButtonToolbarProps, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress, UncontrolledDropdown } from "reactstrap";
import MutationButton from "../components/MutationButton";
import { ReadyState } from "../hooks/ApiProvider";
import { useConfirmation } from "../hooks/ConfirmationProvider";
import { preventDefault, stopPropagation } from "../hooks/NavigationHook";
import { useTimer } from "../hooks/TimerHook";
import { DateDisplayFormat, NumberDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { DateTime, ResourcesSummaryModel, ReviewModel, ReviewOption, ReviewsSummaryModel } from "../types/api-graph-types";
import { CustomDropdownItem } from "./CustomDropdown";
import { useTrackedEvent } from "../hooks/AppInsightsProvider";

library.add(
  faCalendarAlt,
  faCircle,
  faCheckSquare,
  faClock,
  faCommentAlt,
  faEllipsisH,
  faHeart,
  farStar,
  farThumbsUp,
  faShareSquare,
  faSlash,
  fasStar,
  faStarHalfAlt,
  fasThumbsUp,
  faTasks,
  faPlus,
  faTimes
);

export type Option = [number, number, ReviewOption, number];
export type Color = "success" | "warning" | "danger" | "primary" | "secondary" | "info";

const _clock: IconProp = ["far", "clock"];
const _starFull: IconProp = ["fas", "star"];
const _starHalf: IconProp = ["fas", "star-half-alt"];
const _starEmpty: IconProp = ["far", "star"];

export const ReviewsSummaryFragment = `
  ratingCount
  ratingAverage
  likeCount
  loveCount
`;

export const CommentsSummaryFragment = `
  completedCount
`;

export const ReviewFragment = `
  id 
  kind
  rating
  message
  like
  love
  options
`;

function _isLight(color: string) {
  const hex = +("0x" + color.slice(1).replace(color.length < 5 && /./g || "", "$&$&"));
  const r = hex >> 16;
  const g = hex >> 8 & 255;
  const b = hex & 255;
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return hsp > 223.5;
}

export function CardBadge({ value, color, size, add, remove, className, onClick, style, icon, muted, del, ...attrs }: {
  value: string | number,
  remove?: boolean,
  add?: boolean,
  icon?: IconProp,
  size?: string,
  muted?: boolean,
  del?: boolean,
} & BadgeProps) {
  const classNames = [
    className,
    onClick ? "cursor-pointer" : "",
    style?.backgroundColor && !_isLight(style.backgroundColor) ? "text-white" : "",
    size == "inline" ? "mr-1"
      : size == "sm" ? "mr-1 mt-1"
        : "p-2 mr-2 mt-2",
    muted ? "text-muted" : "",
    del ? "text-del" : "",
  ].join(" ");
  const styles: CSSProperties = {
    minWidth: "1rem",
    ...style
  };
  return (
    <Badge color={color || "light"} onClick={onClick} className={classNames} style={styles} {...attrs}>
      {add && <FontAwesomeIcon fixedWidth className="mr-1" icon="plus" />}
      {icon && <FontAwesomeIcon fixedWidth className="mr-1" icon={icon} />}
      {value || '\u00a0'}
      {remove && <FontAwesomeIcon fixedWidth className="ml-1" icon="times" />}
    </Badge>
  );
}

export function CardStatusDetail({ value, label, valueClassName, icon, className, iconTransform, ...attrs }: {
  value: string | number,
  label?: string,
  valueClassName?: string,
  icon?: IconProp,
  iconTransform?: string
} & ButtonProps) {
  return (
    <Button color="white" className={`${className || ""} d-flex flex-column text-left`} {...attrs}>
      <div className="h4 mb-0">
        {icon && <FontAwesomeIcon fixedWidth transform={iconTransform} className="mr-1" icon={icon} />}
        <span className={valueClassName}>{value}</span>
      </div>
      {label && <div className="text-muted small text-nowrap overflow-hidden">{label}</div>}
    </Button>
  );
}

export function CardStatusItem({ icon, label, toggle }: {
  icon: IconProp,
  label: string | number,
  toggle?: () => void
}) {
  return (
    <Button color="white" onClick={toggle}>
      <FontAwesomeIcon className='mr-1' icon={icon} />
      {label}
    </Button>
  );
}

export function CardDueStatusItem({ value }: { value: DateTime }) {
  const [, d] = useTranslation();
  const [dueDate] = useTimer(value);
  const message = d(dueDate, DateDisplayFormat.HumanDateTime);
  return dueDate ? (
    <Button color="white" title={`Due ${message}`}>
      <FontAwesomeIcon className={`mr-1 ${dueDate.isBefore(moment(), "d") ? "text-danger" : ""}`} icon={_clock} />
      Due {d(dueDate, DateDisplayFormat.TimeUntil)}
    </Button>
  ) : null;
};

export function CardUsersStatusItem({ value, max }: {
  value: number,
  max?: number
}) {
  const [, , n] = useTranslation();
  const showMax = typeof (value) === "number" && max && max > value || false;
  return (
    <Button color="white">
      <FontAwesomeIcon className='mr-1' icon={["fas", "users"]} />
      {n(value)}
      {showMax && <span className="text-muted small"> / {n(max ?? 0, NumberDisplayFormat.Human)}</span>}
    </Button>
  );
}

export function CardCommentsSummaryStatusItem({ value, toggle }: {
  value: ResourcesSummaryModel,
  toggle?: () => void
}) {
  return (
    <Button color="white" onClick={preventDefault(toggle)}>
      <FontAwesomeIcon className='mr-1' icon={["far", "comment-alt"]} />
      {value.completedCount > 0 ? value.completedCount : null}
    </Button>
  );
}

export function CardTasksStatusItem({ value, max, toggle, ...attrs }: {
  value: number | string,
  toggle?: () => void,
  max?: number
} & ButtonProps) {
  return (
    !value ? null :
      <Button color="white" className="text-nowrap" onClick={preventDefault(toggle)} {...attrs}>
        <FontAwesomeIcon className='mr-1' icon={["fas", "tasks"]} />
        {typeof (value) === "number" && max && max > value ? `${value}/${max}` : value}
      </Button>
  );
}

export function CardReviewsSummaryStatusItem({ value, toggle }: {
  value: ReviewsSummaryModel,
  toggle?: () => void
}) {
  const count = value ? value.likeCount + value.loveCount : 0;
  return !count ? null : (
    <Button color="white" className="text-nowrap" onClick={preventDefault(toggle)}>
      {value.likeCount > 0 && <FontAwesomeIcon icon={["far", "thumbs-up"]} title={`${value.likeCount} likes`} />}
      {value.loveCount > 0 && <FontAwesomeIcon icon={["far", "heart"]} title={`${value.loveCount} hearts`} />}
      <span className="ml-1">{count}</span>
    </Button>
  );
}

export function CardRatingStatusItem({ toggle, color, title, className, value, count }: {
  value: number,
  color?: boolean,
  count?: number,
  className?: string,
  title?: string,
  toggle?: () => void
}) {
  return (
    <Button color="white" className={`text-nowrap ${className}`} onClick={preventDefault(toggle)} title={title}>
      {!color && <FontAwesomeIcon className="mr-1" icon={_starEmpty} />}
      {color && value < 7 && <FontAwesomeIcon className="mr-1 text-warning" icon={_starEmpty} />}
      {color && value >= 7 && <FontAwesomeIcon className="mr-1 text-success" icon={_starEmpty} />}
      {value ? Math.round(value * 10 / 2) / 10 : null}
      {count && count > 1 ? ` (${count})` : null}
    </Button>
  );
}


function CardRatingHistogramItem({ title, value, max }: { title: string, value: number, max: number }) {
  return (
    <li className="d-flex align-items-baseline">
      <small className="text-muted text-nowrap mr-1">{title}</small>
      <Progress color="secondary" className="w-100" value={value} max={max} style={{ height: "3px" }} />
      {max > 0 && <small className="text-muted text-nowrap ml-1 text-right" style={{ width: "2rem" }}>{Math.round(value * 100 / max)}%</small>}
    </li>
  );
}

export function CardRatingHistogram({ value, max }: {
  value: ReadonlyArray<number>,
  max: number
}) {
  return (
    !value || value.length != 11 ? null :
      <ul className="list-unstyled">
        <CardRatingHistogramItem title="5 star" value={value[9] + value[10]} max={max} />
        <CardRatingHistogramItem title="4 star" value={value[7] + value[8]} max={max} />
        <CardRatingHistogramItem title="3 star" value={value[5] + value[6]} max={max} />
        <CardRatingHistogramItem title="2 star" value={value[3] + value[4]} max={max} />
        <CardRatingHistogramItem title="1 star" value={value[0] + value[1] + value[2]} max={max} />
      </ul>
  );
}

export function StarRatingDiv({ title, value, count }: {
  value: number,
  title?: string,
  count?: number
}) {
  return (
    <div>
      {title && <span className='mr-1'>{title}</span>}
      <span className="text-nowrap">
        {value > 1.33 ? <FontAwesomeIcon icon={_starFull} /> : value > 0.66 ? <FontAwesomeIcon icon={_starHalf} /> : <FontAwesomeIcon icon={_starEmpty} />}
        {value > 3.33 ? <FontAwesomeIcon icon={_starFull} /> : value > 2.66 ? <FontAwesomeIcon icon={_starHalf} /> : <FontAwesomeIcon icon={_starEmpty} />}
        {value > 5.33 ? <FontAwesomeIcon icon={_starFull} /> : value > 4.66 ? <FontAwesomeIcon icon={_starHalf} /> : <FontAwesomeIcon icon={_starEmpty} />}
        {value > 7.33 ? <FontAwesomeIcon icon={_starFull} /> : value > 6.66 ? <FontAwesomeIcon icon={_starHalf} /> : <FontAwesomeIcon icon={_starEmpty} />}
        {value > 9.33 ? <FontAwesomeIcon icon={_starFull} /> : value > 8.66 ? <FontAwesomeIcon icon={_starHalf} /> : <FontAwesomeIcon icon={_starEmpty} />}
      </span>
      {count && count > 0 ? ` (${count})` : null}
    </div>
  );
}

export function CircularProgress({ value, max, strokeWidth, title, size, color, ...attrs }: {
  value: number,
  max?: number,
  strokeWidth?: number,
  title?: string,
  size: number,
  color?: "primary" | "secondary" | "success" | "warning" | "danger" | "info" | "light" | "dark" | "muted"
} & SVGProps<SVGSVGElement>) {
  const cx = size / 2;
  const cy = size / 2;
  const r = (size - 2) / 2 - Math.max(1, (strokeWidth || 1) / 2);
  const f = value / (max || 100);
  const th = 2 * Math.PI * f;
  const ex = Math.round(r * Math.sin(th) * 100) / 100.0;
  const ey = Math.round(r * Math.cos(th) * 100) / 100.0;
  const stroke = `stroke-${color || "primary"}`
  return (
    <svg width={size} height={size} {...attrs}>
      {title && <title>{title}</title>}
      <path d={`M ${cx} ${cy - r} A ${r} ${r} 0 1 0 ${cx} ${cy + r} A ${r} ${r} 0 1 0 ${cx} ${cy - r}`} className="stroke-light" strokeWidth={strokeWidth || 1} fill="transparent" />
      {f > 0.5 ? <path d={`M ${cx + ex} ${cy - ey} A ${r} ${r} 0 0 0 ${cx} ${cy + r} A ${r} ${r} 0 1 0 ${cx} ${cy - r}`} className={stroke} strokeWidth={strokeWidth || 1} fill="transparent" />
        : f > 0 ? <path d={`M ${cx + ex} ${cy - ey} A ${r} ${r} 0 0 0 ${cx} ${cy - r}`} className={stroke} strokeWidth={strokeWidth || 1} fill="transparent" />
          : null}
    </svg>
  );
}

export function CardDiscussionSummaryStatusItems({ review, reviews, comments, hideRating, toggleDiscussion }: {
  review?: ReviewModel,
  reviews?: ReviewsSummaryModel,
  comments?: ResourcesSummaryModel,
  toggleDiscussion?: () => void,
  hideRating?: boolean
}) {
  const reviewRating = review?.rating ?? 0;
  const rating = hideRating ? 0 : reviewRating ? reviewRating : reviews?.ratingAverage ?? 0;
  const showRating = !!rating;
  return (
    <ButtonGroup>
      {toggleDiscussion && showRating && <CardRatingStatusItem className={reviewRating ? "font-italic" : ""} value={rating} count={reviewRating ? 1 : reviews?.ratingCount ?? 0} toggle={toggleDiscussion} title={reviews?.authors?.map(_ => _.name).join("\n")} />}
      {toggleDiscussion && reviews && <CardReviewsSummaryStatusItem value={reviews} />}
      {toggleDiscussion && comments && <CardCommentsSummaryStatusItem value={comments} toggle={toggleDiscussion} />}
    </ButtonGroup>
  );
}

export function CardStatusMenu({ className, isOpen, toggle, children }: {
  isOpen?: boolean,
  toggle?: () => void,
  children?: ReactNode
} & ButtonGroupProps) {
  return React.Children.toArray(children).length > 0 ? (
    <ButtonGroup className={`ml-auto ${className}`} onClick={stopPropagation}>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle className="hover-visible" color='white'>
          <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
        </DropdownToggle>
        <DropdownMenu>
          {children}
        </DropdownMenu>
      </Dropdown>
    </ButtonGroup>
  ) : null;
}

export function CardStatusGroup({ children, ...attrs }: { children: ReactNode }) {
  return (
    <ButtonGroup {...attrs}>
      {children}
    </ButtonGroup>
  );
}

export function CardBadges({ className, children }: { className?: string, children: ReactNode }) {
  return (
    <div className={className}>
      {children}
    </div>
  );
}

export function CardStatus({ children, ...attrs }: ButtonToolbarProps) {
  return (
    <ButtonToolbar {...attrs}>
      {children}
    </ButtonToolbar>
  );
}

export const preventOverflow: Popper.Modifiers = {
  preventOverflow: {
    enabled: true,
    boundariesElement: "viewport",
    padding: 35
  }
};

export const offsetLeft: Popper.Modifiers = {
  offset: {
    enabled: true,
    offset: "-100%p + 100%",
  }
};

const _leftModifiers: Popper.Modifiers = {
  ...preventOverflow,
};

const _rightModifiers: Popper.Modifiers = {
  ...preventOverflow,
  ...offsetLeft
};

const _btnContext = createContext({
  color: "primary",
  size: undefined as string | undefined,
  dropdown: false,
  showIcons: false as boolean | undefined,
  fullWidth: false as boolean | undefined,
  readyState: ReadyState.Complete as ReadyState | undefined
});

export function CardButtons({ color = "primary", size, className, right, showIcons, fullWidth, readyState, children }: {
  className?: string,
  color?: string,
  size?: string,
  right?: boolean,
  readyState?: ReadyState,
  showIcons?: boolean,
  fullWidth?: boolean
  children: ReactNode
}) {
  const items = React.Children.toArray(children);
  return items.length > 0 ? (
    <div className={className} onClick={stopPropagation}>
      <UncontrolledDropdown className="d-flex">
        <_btnContext.Provider value={{ dropdown: false, showIcons: false, color, size, fullWidth, readyState }}>
          {items.shift()}
        </_btnContext.Provider>
        {items.length > 0 && <DropdownToggle color={color} size={size} caret />}
        {items.length > 0 &&
          <DropdownMenu modifiers={right ? _rightModifiers : _leftModifiers}>
            <_btnContext.Provider value={{ dropdown: true, showIcons, color, size, fullWidth, readyState }}>
              {items}
            </_btnContext.Provider>
          </DropdownMenu>
        }
      </UncontrolledDropdown>
    </div>
  ) : null;
}

export function CardButton({ icon, onClick: _onClick, confirm, isOpen, toggle, className, event, confirmMessage, prependDivider, children, ...attrs }: {
  className?: string,
  icon?: IconProp,
  href?: string,
  target?: string,
  confirm?: boolean,
  prependDivider?: boolean,
  toggle?: boolean,
  isOpen?: boolean,
  disabled?: boolean,
  confirmMessage?: string,
  event?: string,
  onClick?: MouseEventHandler,
  children: ReactNode
}) {
  const confirmOnClick = useConfirmation();
  const track = useTrackedEvent();
  const onClick = event ? track(_onClick, event) : _onClick;
  const handleClick = confirm && onClick ? confirmOnClick((_, e: React.MouseEvent<any>) => onClick(e), confirmMessage) : onClick;
  const { color, size, dropdown, showIcons, fullWidth, readyState } = useContext(_btnContext);
  const classNames = [
    className,
    fullWidth ? "w-100" : ""
  ].join(" ");
  return dropdown ? (
    <>
      {prependDivider && <DropdownItem divider />}
      <CustomDropdownItem isOpen={isOpen} onClick={handleClick} toggle={toggle} className={className} {...attrs}>
        {icon && showIcons !== false && <FontAwesomeIcon className="mr-3" icon={icon} />}
        {!icon && showIcons === true && <FontAwesomeIcon className="mr-3 invisible" icon="circle" />}
        {children}
      </CustomDropdownItem>
    </>
  ) : readyState ? (
    <MutationButton readyState={readyState} className={classNames} color={color} size={size} onClick={handleClick} {...attrs}>
      {children}
    </MutationButton>
  ) : (
        <Button className={classNames} color={color} size={size} onClick={handleClick} {...attrs}>
          {children}
        </Button>
      );
}

export function CardButtonDivider() {
  const { dropdown } = useContext(_btnContext);
  return dropdown ? (<DropdownItem divider />) : (<></>);
}
