import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import { Button, ButtonGroup } from "reactstrap";

// https://coolors.co/ac59ff-4a95ff-3bbdca-3ac95c-f2c83f-ff7f3b-f23f3f-ff47e3
const _colors = [
  "#ac59ff",
  "#4a95ff",
  "#3bbdca",
  "#3ac95c",
  "#f2c83f",
  "#ff7f3b",
  "#f23f3f",
  "#ff47e3",
  "#ffffff",
  "#f8f9fa",
  "#000000",
]

function _isTooLight(value: string) {
  if (value.startsWith("#")) {
    const c = value.substring(1);
    const rgb = parseInt(c, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma >= 224;
  }
  return false;
}

export function Group({ value, options = _colors, onUpdate }: {
  value: string | undefined,
  options?: string[],
  onUpdate: (value: string) => void
}) {
  return (
    <ButtonGroup style={{ display: "block" }}>
      {options.map((_, i) => <ColorButton key={i} className={_isTooLight(_) ? "b-1 text-dark" : undefined} selected={value === _} value={_} onClick={e => onUpdate(_)} />)}
    </ButtonGroup>
  );
}

function ColorButton({ value, className, selected, onClick }: {
  value: string;
  className?: string;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.value = value;
    onClick(e);
    e.preventDefault();
  }
  const style: CSSProperties = {
    width: 32,
    height: 32,
    marginRight: 11,
    padding: 0,
    border: 0,
    color: "fff",
    backgroundColor: value
  };

  return (
    <Button className={className} style={style} onClick={handleClick}>
      {selected && <FontAwesomeIcon icon={["fas", "check"]} />}
    </Button>
  );
}

export default ColorButton;
