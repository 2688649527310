import React from "react";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { EventModel, ResourceEvent } from "../types/api-graph-types";

export const Fragment = `
  type
  triggeredOn
  prevTriggeredOn
  subject { name }
`

export default ({ values }: { values: ReadonlyArray<EventModel> }) => {
  const [, d] = useTranslation();

  const message = (value: EventModel) => {
    const name = value.subject?.name;
    const on = d(value.triggeredOn, DateDisplayFormat.TimeAgo);
    const times = value.prevTriggeredOn?.length > 0 ? `${value.prevTriggeredOn.length + 1} times, most recently` : "";
    switch (value.type) {
      case ResourceEvent.CustomerViewedOrder: return `${name} viewed this application ${times} ${on}.`;
      case ResourceEvent.SupplierViewedOrder: return `${name} viewed this application ${times} ${on}.`;
      case ResourceEvent.CustomerViewedOrderMessage: return `${name} viewed a message ${times} ${on}.`;
      case ResourceEvent.SupplierViewedOrderMessage: return `${name} viewed a message ${times} ${on}.`;
      default: return value.message;
    }
  }
  return (
    <ul className="list-unstyled text-muted small">
      {values.map(message).filter(_ => !!_).map((_, i) => <li key={i}>{_}</li>)}
    </ul>
  );
}
