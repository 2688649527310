import { library } from "@fortawesome/fontawesome-svg-core";
import { faLongArrowAltDown, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Col, CustomInput, Row, Card, CardBody, CardText, Form } from "reactstrap";
import ProductCard, { Fragment as ProductFragment } from "../cards/ProductCard";
import { CardButton, CardButtons } from "../components/CardStatus";
import { nextSequence, byKey, useMutation, useQuery, useReadyState, ReadyState } from "../hooks/ApiProvider";
import { useFragment, useNavigation } from "../hooks/NavigationHook";
import { AccountSubscriptionModel, OrderStage, ProductDetailsInputModel, ProductModel, ShareModel, IndustryType, PersonModel, PersonInputModel, OrganizationInputModel, ProductType, FunctionType } from "../types/api-graph-types";
import LoadingController from "./LoadingController";
import FormGroup from "../components/FormGroup";
import Input from "../components/Input";
import { getDefaultRule as getProductRule } from "../modals/ProductInputModal";
import { useTrackedEvent } from "../hooks/AppInsightsProvider";

library.add(faLongArrowAltRight, faLongArrowAltDown);

const _getShare = `query getShare($id:ID! $shareToken:String) {
  share(id:$id accessToken:$shareToken) {
    state
    objectProduct { id kind details { title subtitle } ${ProductFragment()} }
    subjectProduct { id kind details { title subtitle } ${ProductFragment()}  }
  }
  me {
    name 
    email
    phone
    products(states:[PROPOSED,IN_PROGRESS] roles:[OWNER,CONTRIBUTOR]) {
      id
      kind
      details { ordersStages }
      policy { accessToken }
      ${ProductFragment()} 
    }
    subscriptions { id kind title plan { id title } }
  }
}`;

const _changeSubject = `mutation changeShareSubject($id:ID! $pid:ID! $details:ProductDetailsInputModel $shareToken:String! $subjectToken:String! $hasProduct:Boolean!) {
  share(id:$id accessToken:$shareToken) {
    changeSubject(accessToken:$subjectToken)
    complete
    commit { id }
  }
  product(id:$pid) @include(if:$hasProduct) {
    details { change(value:$details) }
    commit { id }
  }
}`

const _createListing = `mutation createProduct($me:PersonInputModel $org:OrganizationInputModel $details:ProductDetailsInputModel $hasMe:Boolean! $hasOrg:Boolean!) {
  me @include(if:$hasMe) {
    change(value:$me)
    commit { id }
  }
  myOrganization @include(if:$hasOrg) {
    change(value:$org)
    commit { id }
  }
  product: addProduct {
    details { change(value:$details) }
    start
    commit { id policy { accessToken } }
  }
}`

interface Result {
  me: {
    products: ProductModel[]
    subscriptions: AccountSubscriptionModel[]
  } & PersonModel
  share: {
    subjectProduct: ProductModel
    objectProduct: ProductModel
  }
}

interface OperationResult {
  share: {
    commit: ShareModel
  },
  product: {
    commit: ProductModel
  }
}

const _newListing = {
  function: FunctionType.LegalFunction,
  ordersStages: [OrderStage.Suggested, OrderStage.ScreeningScheduled, OrderStage.InterviewScheduled],
  ordersJobsCount: 0,
  ordersJobsMaxCount: 0,
  isUnclaimed: false,
  isPrivate: true,
  isLocked: false,
  rules: [
    //getProductRule("added"),
    getProductRule("created"),
    getProductRule("screening"),
    getProductRule("interviewing"),
    getProductRule("offered"),
    getProductRule("accepted"),
    getProductRule("delivered"),
    getProductRule("declined"),
    getProductRule("withdrawn"),
    getProductRule("refused"),
  ]
} as Partial<ProductDetailsInputModel> as any as ProductDetailsInputModel;

const _types = new Map<ProductType, Partial<ProductDetailsInputModel>>([
  [ProductType.EmploymentTrainingLevel, { subtitle: "Internship", subtype: ProductType.EmploymentFullTime, duration: "P28D" }],
  [ProductType.EmploymentEntryLevel, { subtitle: "Associate Position", subtype: ProductType.EmploymentFullTime, duration: "P65535D" }],
  [ProductType.EmploymentSeniorLevel, { subtitle: "Senior Associate Position", subtype: ProductType.EmploymentFullTime, duration: "P65535D" }],
  [ProductType.EmploymentManagerLevel, { subtitle: "Partner Position", subtype: ProductType.EmploymentFullTime, duration: "P65535D" }],
  [ProductType.EmploymentVicePresidentLevel, { subtitle: "Managing Partner Position", subtype: ProductType.EmploymentFullTime, duration: "P65535D" }],
  [ProductType.CustomerService, { subtype: ProductType.Null, duration: "P65535D" }],
]);

function SignUpCard({ me, className, product, onUpdate }: {
  me?: PersonModel,
  className?: string,
  product?: ProductModel,
  onUpdate?: (me?: PersonInputModel, org?: OrganizationInputModel, listing?: ProductDetailsInputModel) => Promise<void>
}) {
  const track = useTrackedEvent("VIEW Share");
  const [readyState, setReadyState] = useReadyState();
  const [meInput, setMeInput] = useState<PersonInputModel>();
  const [listingInput, setListingInput] = useState<ProductDetailsInputModel>();
  const submit = async () => {
    setReadyState(ReadyState.Loading);
    await onUpdate?.(meInput, undefined, listingInput);
    setReadyState(ReadyState.Complete);
  }
  const updateMe = (partial: Partial<PersonInputModel>) => setMeInput({ ...meInput ?? {} as any, ...partial });
  const updateListing = (partial: Partial<ProductDetailsInputModel>) => setListingInput({
    ...listingInput ?? _newListing,
    ...partial
  } as ProductDetailsInputModel);
  const updateOrgName = (name: string) => {
    updateListing({ title: name });
  }
  return (
    <Card className={className}>
      <CardBody>
        <Form>
          <FormGroup label="Organization name" required>
            <Input value={listingInput?.title ?? ""} onUpdate={updateOrgName} placeholder="Enter your organization name here" />
          </FormGroup>
          {!me?.name &&
            <FormGroup label="Name" required>
              <Input value={meInput?.name ?? me?.name ?? ""} onUpdate={name => updateMe({ name })} placeholder="Enter your full name here" />
            </FormGroup>
          }
          <FormGroup label='Open position'>
            <select className={`custom-select ${listingInput?.type ? "" : "text-muted"}`} value={listingInput?.type ?? ""} onChange={e => updateListing({ type: e.currentTarget.value as ProductType, ...(_types.get(e.currentTarget.value as ProductType) || {}) })}>
              <option value="" hidden>Select open position type here</option>
              <option value={ProductType.EmploymentTrainingLevel}>Internship</option>
              <option value={ProductType.EmploymentEntryLevel}>Associate Position</option>
            </select>
          </FormGroup>
        </Form>
        <CardText className="small text-muted">
          By submitting you agree to HigherKnowledge's
          {" "}
          <a href="/our/policies/terms" target="_blank">Terms of Use</a>
          {" and "}
          <a href="/our/policies/privacy" target="_blank">Privacy Policy</a>.
        </CardText>
        <CardButtons readyState={readyState}>
          <CardButton onClick={track(submit, "CONNECT Share")}>Submit</CardButton>
        </CardButtons>
      </CardBody>
    </Card>
  );
}

const _initialFragment = {
  token: "",
  collection: ""
};

export default ({ id }: { id: string }) => {
  const [uid] = useState(nextSequence());
  const [navigate, go] = useNavigation();
  const [fragment] = useFragment(_initialFragment);
  const [result, , isLoading] = useQuery<Result>(_getShare, { id, shareToken: fragment.token });
  const [mutation] = useMutation<OperationResult>();
  const [index, setIndex] = useState(0);
  const value = result?.share;
  const me = result?.me;
  const track = useTrackedEvent("VIEW Share");
  useEffect(() => {
    if (value?.subjectProduct) {
      go(`/my/listings/${value.subjectProduct.id}?${query}`);
    }
  }, [value])
  if (!result || !value || !me) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const changeShare = async (subject: ProductModel, details?: Partial<ProductDetailsInputModel>) => {
    const result = await mutation(_changeSubject, {
      id,
      pid: subject.id,
      shareToken: fragment.token,
      subjectToken: subject.policy.accessToken,
      details,
      hasProduct: !!details
    });
    go(`/my/listings/${subject.id}?${query}`);
  }
  const connect = async () => {
    const subject = me.products[index];
    await changeShare(subject, {
      ordersStages: [OrderStage.Suggested, ...subject.details.ordersStages]
    });
  }
  const create = async (me: PersonInputModel, org: OrganizationInputModel, details: ProductDetailsInputModel) => {
    const result = await mutation(_createListing, { hasMe: !!me, hasOrg: !!org, me, org, details });
    await changeShare(result.product.commit);
  }

  const showConnect = !value.subjectProduct;
  const query = `stages=SUGGESTED&sources=${value.objectProduct.id}&sourcesLabel=${encodeURIComponent(value.objectProduct.details.title)}&show=RESUME,EDU_RATING,TOPICS&relatedIds=${fragment.collection}`;

  return showConnect ? (
    <Row>
      <Col lg={5}>
        <ProductCard className="mt-3" value={value.objectProduct} />
      </Col>
      <Col lg={2} className="d-flex">
        <FontAwesomeIcon className="d-none d-lg-inline-block mx-auto" size="6x" icon="long-arrow-alt-right" />
        <FontAwesomeIcon className="d-lg-none mx-auto my-3" size="6x" icon="long-arrow-alt-down" />
      </Col>
      <Col lg={5}>
        {me.products?.length > index && index > -1 &&
          <ProductCard className="mt-3" value={me.products[index]}>
            <CardButtons>
              <CardButton onClick={track(connect, "CONNECT Share")}>Connect</CardButton>
            </CardButtons>
          </ProductCard>
        }
        {(index == -1 || me.products?.length === 0) &&
          <SignUpCard className="mt-3" me={me} product={value.objectProduct} onUpdate={create} />
        }
        {me.products?.length > 0 &&
          <FormGroup label="Your listings" className="mt-3">
            <CustomInput id={uid} type="select" value={index} onChange={e => setIndex(parseInt(e.currentTarget.value))}>
              <option value="-1">Create new listing</option>
              {me.products.sort(byKey(_ => _.details.title)).map((_, i) => <option key={i} value={i}>{_.details.displayTitle}</option>)}
            </CustomInput>
          </FormGroup>
        }
      </Col>
    </Row>
  ) : null
};
