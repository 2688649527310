import { library } from "@fortawesome/fontawesome-svg-core";
import { faFrown, faSmile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Collapse, CustomInput, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormGroup from "../components/FormGroup";
import MutationButton from "../components/MutationButton";
import { nextSequence, useMutation, useReadyState } from "../hooks/ApiProvider";
import { preventDefault } from "../hooks/NavigationHook";
import { useScreenshot } from "../hooks/ScreenshotHook";
import { useToasts } from "../hooks/ToastsProvider";
import { MessageInputModel } from "../types/api-graph-types";

library.add(faSmile, faFrown);

const _sendMyFeedback = `mutation sendMyFeedback($value:MessageInputModel) {
  me { 
    addMessage { 
      change(value:$value)
      send
    } 
  }
}`;

interface OperationResults {
  me: {
    addMessage: {
      send: boolean
    }
  }
};

export default ({ isOpen, toggle }: { isOpen?: boolean, toggle?: () => void }) => {
  const [uid] = useState(nextSequence());
  const [readyState, setReadyState] = useReadyState();
  const [, setToast] = useToasts();
  const [reaction, setReaction] = useState(":|" as ":)" | ":|" | ":(");
  const [message, setMessage] = useState("");
  const [hasScreenshot, setHasScreenshot] = useState(false);
  const [screenshot, setScreenshot] = useState("");
  const [mutation] = useMutation<OperationResults>();
  const [takeScreenshot] = useScreenshot();
  const reset = () => {
    setReaction(":|");
    setMessage("");
    setHasScreenshot(false);
    setScreenshot("");
  }
  const send = async () => {
    try {
      const url = window.location.href.split("?")[0].split("#")[0];
      await mutation(_sendMyFeedback, {
        value: {
          isDraft: false,
          recipientEmails: "support@higherknowledge.in",
          subject: `${reaction} from {{Me}}`,
          content: `On ${url}\n\n${message}\n\n{{Me}}`,
          attachments: !hasScreenshot ? undefined : [{
            filename: "screenshot.png",
            dataUri: screenshot
          }]
        } as Partial<MessageInputModel>
      }, { setReadyState });
      toggle?.();
      reset();
      setToast({ title: "Thank you!", message: "Thank you for helping make HigherKnowledge better." }, 5000);
    } catch (errors) {
    }
  }
  const toggleScreenshot = () => {
    setHasScreenshot(!hasScreenshot);
    setTimeout(async () => setScreenshot(hasScreenshot ? "" : await takeScreenshot()), 300);
  }
  const cancel = () => {
    toggle?.();
    reset();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form>
        <ModalHeader>Send feedback</ModalHeader>
        <ModalBody>
          <FormGroup label='How was your experience?'>
            <Button color={reaction === ":)" ? "primary" : "link"} onClick={() => setReaction(":)")}>
              <FontAwesomeIcon size="2x" icon={["far", "smile"]} />
            </Button>
            <Button color={reaction === ":(" ? "primary" : "link"} onClick={() => setReaction(":(")}>
              <FontAwesomeIcon size="2x" icon={["far", "frown"]} />
            </Button>
          </FormGroup>
          <FormGroup label='Tell us why.' maxLength={260} length={message.length}>
            <Input type="textarea" style={{ height: "5rem" }} className="form-control" value={message} onChange={e => setMessage(e.currentTarget.value.substring(0, 260))} />
          </FormGroup>
          <CustomInput id={`${uid}.0`} type="switch" label="Include screenshot" checked={hasScreenshot} onChange={toggleScreenshot} />
          <Collapse isOpen={hasScreenshot && !!screenshot}>
            <div className="w-100 mt-3 b-1 overflow-auto" style={{ height: "200px", maxHeight: "200px" }}>
              {screenshot && <img className="w-100" src={screenshot} />}
            </div>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={preventDefault(cancel)}>Cancel</Button>
          <MutationButton readyState={readyState} disabled={!message} color="primary" onClick={send}>Send</MutationButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

