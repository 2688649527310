export type Maybe<T> = T;

export interface AccountTransactionInputModel {
  readonly title: Maybe<string>;

  readonly description: Maybe<string>;

  readonly amount: Maybe<number>;

  readonly currency: Maybe<CurrencyCode>;

  readonly razorpayPaymentId: Maybe<string>;
}

export interface CommentInputModel {
  readonly clientUri: Maybe<string>;

  readonly isPrivate: Maybe<boolean>;

  readonly message: Maybe<string>;

  readonly visibilityId: Maybe<string>;

  readonly referencesIds: Maybe<ReadonlyArray<string>>;
}

export interface ReviewInputModel {
  readonly type: Maybe<ReviewType>;

  readonly state: Maybe<ResourceState>;

  readonly clientUri: Maybe<string>;

  readonly message: Maybe<string>;

  readonly isPrivate: Maybe<boolean>;

  readonly rating: Maybe<number>;

  readonly visibilityId: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<ReviewOption>>>;

  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentInputModel>>>;
}

export interface AttachmentInputModel {
  readonly id: Maybe<string>;

  readonly key: Maybe<string>;

  readonly type: Maybe<AttachmentType>;

  readonly state: Maybe<ResourceState>;

  readonly title: Maybe<string>;

  readonly subtitle: Maybe<string>;

  readonly length: Maybe<number>;

  readonly filename: Maybe<string>;

  readonly mediaType: Maybe<string>;

  readonly issuerName: Maybe<string>;

  readonly issuerOrganizationName: Maybe<string>;

  readonly issuerTitle: Maybe<string>;

  readonly issuerEmail: Maybe<string>;

  readonly issuerPhone: Maybe<string>;

  readonly issuerWebsite: Maybe<string>;
  readonly issuerReferenceId: Maybe<string>;
  readonly issuedOn: Maybe<string>;
  readonly issuedUntil: Maybe<string>;

  // @deprecated Use contentUri
  readonly dataUri: Maybe<string>;
  readonly contentUri: Maybe<string>;
  readonly thumbnailUri: Maybe<string>;
  readonly squareCroppedUri: Maybe<string>;

  readonly content: Maybe<string>;

  readonly token: Maybe<string>;
}

export interface OrderDetailsInputModel {
  readonly startOn: Maybe<string>;

  readonly startIn: Maybe<string>;

  readonly endOn: Maybe<string>;

  readonly screeningOn: Maybe<string>;

  readonly screeningDuration: Maybe<string>;

  readonly interviewOn: Maybe<string>;

  readonly interviewDuration: Maybe<string>;

  readonly customerNickname: Maybe<string>;

  readonly customerName: Maybe<string>;

  readonly customerExternalId: Maybe<string>;

  readonly supplierDescription: Maybe<string>;

  readonly customerDescription: Maybe<string>;

  readonly customerCompensation: Maybe<MoneyInputModel>;

  readonly location: Maybe<string>;

  readonly screeningLocation: Maybe<string>;

  readonly interviewLocation: Maybe<string>;

  readonly offerExpiresOn: Maybe<string>;

  readonly labels: Maybe<ReadonlyArray<Maybe<LabelInputModel>>>;

  readonly addLabels: Maybe<ReadonlyArray<Maybe<LabelInputModel>>>;

  readonly removeLabels: Maybe<ReadonlyArray<Maybe<LabelInputModel>>>;
}

export interface MoneyInputModel {
  readonly currency: Maybe<CurrencyCode>;

  readonly isPrivate: Maybe<boolean>;

  readonly isGross: Maybe<boolean>;

  readonly period: Maybe<string>;

  readonly amount: Maybe<number>;

  readonly variableFraction: Maybe<number>;
}

export interface LabelInputModel {
  readonly key: Maybe<string>;

  readonly color: Maybe<string>;

  readonly value: Maybe<string>;

  readonly isArchived: Maybe<boolean>;
}

export interface MapEntry {
  readonly key: string;

  readonly value: string;
}

export interface MessageInputModel {
  readonly key: Maybe<string>;

  readonly name: Maybe<string>;

  readonly isDraft: Maybe<boolean>;

  readonly bccSender: Maybe<boolean>;

  readonly recipientIds: Maybe<ReadonlyArray<string>>;

  readonly recipientEmails: Maybe<string>;

  readonly recipientPhones: Maybe<string>;

  readonly recipientUris: Maybe<ReadonlyArray<string>>;

  readonly subject: Maybe<string>;

  readonly content: Maybe<string>;

  readonly shortContent: Maybe<string>;

  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentInputModel>>>;
}

export interface ProductDetailsInputModel {
  readonly key: Maybe<string>;

  readonly type: Maybe<ProductType>;

  readonly subtype: Maybe<ProductType>;

  readonly variantType: Maybe<ProductVariantType>;

  readonly function: Maybe<FunctionType>;

  readonly industry: Maybe<IndustryType>;

  readonly title: Maybe<string>;

  readonly subtitle: Maybe<string>;

  readonly variantTitle: Maybe<string>;

  readonly description: Maybe<string>;

  readonly detailedDescription: Maybe<string>;

  readonly minCustomersGraduationIn: Maybe<string>;

  readonly maxCustomersGraduationIn: Maybe<string>;

  readonly requirementsDescription: Maybe<string>;

  readonly eduRatingDisplayMax: Maybe<number>;

  readonly service: Maybe<ServiceType>;

  readonly areas: Maybe<ReadonlyArray<Maybe<AreaType>>>;

  readonly languages: Maybe<ReadonlyArray<Maybe<LanguageCode>>>;

  readonly duration: Maybe<string>;

  readonly location: Maybe<LocationInputModel>;

  readonly manufacturerOrgId: Maybe<string>;

  readonly isPrivate: Maybe<boolean>;

  readonly isLocked: Maybe<boolean>;

  readonly isUnclaimed: Maybe<boolean>;

  readonly compensation: Maybe<MoneyInputModel>;

  readonly restartOn: Maybe<string>;

  readonly availableOn: Maybe<string>;

  readonly skipOrgDescription: Maybe<boolean>;

  readonly ordersDescription: Maybe<string>;

  readonly ordersJobsCount: Maybe<number>;

  readonly ordersJobsMaxCount: Maybe<number>;

  readonly ordersDueIn: Maybe<string>;

  readonly ordersDueOn: Maybe<string>;

  readonly ordersStages: Maybe<ReadonlyArray<Maybe<OrderStage>>>;

  readonly ordersCustomerExternalIdMessage: Maybe<string>;

  readonly supplierRecipients: Maybe<string>;

  readonly supplierOriginAddresses: Maybe<string>;

  readonly ordersJobsWorkitemsRelatedIds: Maybe<ReadonlyArray<string>>;

  readonly ordersJobsWorkitemsByAreas: Maybe<boolean>;

  readonly ordersCustomerAttachmentReviews: Maybe<boolean>;

  readonly rules: Maybe<ReadonlyArray<Maybe<RuleInputModel>>>;

  readonly messages: Maybe<ReadonlyArray<Maybe<MessageInputModel>>>;

  readonly labels: Maybe<ReadonlyArray<Maybe<LabelInputModel>>>;

  readonly ordersDiscussionDescription: Maybe<string>;

  readonly ordersDiscussionMessages: Maybe<ReadonlyArray<string>>;

  readonly notificationPreferences: Maybe<ReadonlyArray<Maybe<ResourceEvent>>>;
}

export interface LocationInputModel {
  readonly country: Maybe<CountryCode>;

  readonly city: Maybe<CityCode>;

  readonly locality: Maybe<string>;

  readonly neighborhood: Maybe<string>;

  readonly postalCode: Maybe<string>;

  readonly region: Maybe<string>;

  readonly streetAddress: Maybe<string>;
}

export interface RuleInputModel {
  readonly key: Maybe<string>;

  readonly name: Maybe<string>;

  readonly type: Maybe<RuleType>;

  readonly action: Maybe<RuleActionType>;

  readonly isEnabled: Maybe<boolean>;
  readonly isArchived: Maybe<boolean>;

  readonly includeDefaultRecipients: Maybe<boolean>;

  readonly nextTriggerOn: Maybe<string>;

  readonly nextTriggerIn: Maybe<string>;

  readonly eventsTrigger: Maybe<ReadonlyArray<Maybe<ResourceEvent>>>;

  readonly message: Maybe<MessageInputModel>;

  readonly comment: Maybe<CommentInputModel>;

  readonly messageMatch: Maybe<MessageMatchInputModel>;

  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentInputModel>>>;

  readonly labels: Maybe<ReadonlyArray<Maybe<LabelInputModel>>>;
}

export interface MessageMatchInputModel {
  readonly senderEmails: Maybe<string>;

  readonly content: Maybe<string>;
}

export interface OrderPopulateInputModel {
  readonly id: Maybe<string>;

  readonly name: Maybe<string>;

  readonly email: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly email2: Maybe<string>;

  readonly phone2: Maybe<string>;

  readonly externalId: Maybe<string>;

  readonly college: Maybe<string>;

  readonly collegeId: Maybe<string>;

  readonly graduation: Maybe<string>;

  readonly gpa: Maybe<string>;

  readonly rank: Maybe<string>;

  readonly company: Maybe<string>;

  readonly title: Maybe<string>;

  readonly companyWebsite: Maybe<string>;

  readonly companyLocation: Maybe<string>;
}

export interface ShareInputModel {
  readonly role: Maybe<ResourceRole>;

  readonly title: Maybe<string>;

  readonly url: Maybe<string>;

  readonly subjectKind: Maybe<ResourceKind>;

  readonly subjectId: Maybe<string>;

  readonly subjectExternalId: Maybe<string>;
}

export interface AccountSubscriptionInputModel {
  readonly title: Maybe<string>;

  readonly planId: Maybe<string>;

  readonly customerExternalId: Maybe<string>;
}

export interface OrganizationInputModel {
  readonly key: Maybe<string>;

  readonly name: Maybe<string>;

  readonly unitName: Maybe<string>;

  readonly nickname: Maybe<string>;

  readonly logo: Maybe<string>;

  readonly description: Maybe<string>;

  readonly websiteUrl: Maybe<string>;

  readonly emailDomain: Maybe<string>;


  readonly emailAddresses: Maybe<string>;
  readonly phoneNumbers: Maybe<string>;

  readonly type: Maybe<OrganizationType>;

  readonly industry: Maybe<IndustryType>;
}

export interface ReferenceDataInputModel {
  readonly rank: Maybe<number>;

  readonly value: Maybe<string>;

  readonly title: Maybe<string>;

  readonly description: Maybe<string>;
}

export interface MembershipInputModel {
  readonly organizationId: Maybe<string>;

  readonly organizationName: Maybe<string>;

  readonly organizationNickname: Maybe<string>;

  readonly title: Maybe<string>;

  readonly focus: Maybe<string>;

  readonly isPrimary: Maybe<boolean>;

  readonly isEducational: Maybe<boolean>;

  readonly startOn: Maybe<string>;

  readonly endOn: Maybe<string>;

  readonly description: Maybe<string>;

  readonly location: Maybe<LocationInputModel>;

  readonly cgpa: Maybe<BoundedValueInputModel>;

  readonly rank: Maybe<BoundedValueInputModel>;

  readonly income: Maybe<MoneyInputModel>;
}

export interface BoundedValueInputModel {
  readonly value: Maybe<number>;

  readonly maxValue: Maybe<number>;
}

export interface PersonInputModel {
  readonly name: Maybe<string>;

  readonly nickname: Maybe<string>;

  readonly gender: Maybe<GenderType>;

  readonly title: Maybe<string>;

  readonly picture: Maybe<string>;

  readonly accentColor: Maybe<string>;

  readonly incomePreference: Maybe<IncomePreferenceInputModel>;

  readonly contactsConfirmedOn: Maybe<string>;

  readonly termsAgreedOn: Maybe<string>;
}

export interface IncomePreferenceInputModel {
  readonly value: Maybe<MoneyInputModel>;

  readonly options: Maybe<ReadonlyArray<Maybe<IncomePreferenceOption>>>;
}

export interface ExternalAccountInputModel {
  readonly type: Maybe<ExternalAccountType>;

  readonly value: Maybe<string>;

  readonly uri: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<ExternalAccountOption>>>;

  readonly isPrimary: Maybe<boolean>;

  readonly verificationCode: Maybe<string>;
}

export interface LanguagePreferenceInputModel {
  readonly value: Maybe<LanguageCode>;

  readonly options: Maybe<ReadonlyArray<Maybe<LanguagePreferenceOption>>>;
}

export interface LocationPreferenceInputModel {
  readonly city: Maybe<CityCode>;

  readonly locality: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<LocationPreferenceOption>>>;
}

export interface TopicPreferenceInputModel {
  readonly function: Maybe<FunctionType>;

  readonly industry: Maybe<IndustryType>;

  readonly service: Maybe<ServiceType>;

  readonly area: Maybe<AreaType>;

  readonly organizationId: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<TopicPreferenceOption>>>;
}

export interface LegalResearchBackgroundInputModel {
  readonly searchQuery: Maybe<string>;

  readonly url: Maybe<string>;

  readonly excerpt: Maybe<string>;
}

export interface LegalResearchConclusionInputModel {
  readonly opinion: Maybe<string>;

  readonly action: Maybe<string>;

  readonly clarification: Maybe<string>;
}

export interface LegalResearchInputModel {
  readonly function: Maybe<FunctionType>;

  readonly industry: Maybe<IndustryType>;

  readonly area: Maybe<AreaType>;

  readonly title: Maybe<string>;

  readonly description: Maybe<string>;

  readonly jobsCount: Maybe<number>;

  readonly dueOn: Maybe<string>;

  readonly jobsDueOn: Maybe<string>;
}

export interface LegalResearchPrecedentInputModel {
  readonly searchQuery: Maybe<string>;

  readonly url: Maybe<string>;

  readonly title: Maybe<string>;

  readonly court: Maybe<string>;

  readonly strength: Maybe<string>;

  readonly year: Maybe<string>;

  readonly facts: Maybe<string>;

  readonly excerpt: Maybe<string>;

  readonly citation: Maybe<string>;

  readonly status: Maybe<string>;
}

export interface LegalResearchRephrasingInputModel {
  readonly area: Maybe<string>;

  readonly jurisdiction: Maybe<string>;

  readonly query: Maybe<string>;
}

export interface LegalResearchStatuteInputModel {
  readonly searchQuery: Maybe<string>;

  readonly url: Maybe<string>;

  readonly title: Maybe<string>;

  readonly year: Maybe<string>;

  readonly section: Maybe<string>;

  readonly excerpt: Maybe<string>;
}

export enum ResourceState {
  Null = "NULL",
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  UnderReview = "UNDER_REVIEW",
  Rejected = "REJECTED",
  Accepted = "ACCEPTED",
  Resolved = "RESOLVED",
  Proposed = "PROPOSED",
  Removed = "REMOVED"
}

export enum ResourceRole {
  Null = "NULL",
  Owner = "OWNER",
  Contributor = "CONTRIBUTOR",
  Reviewer = "REVIEWER",
  Reader = "READER",
  Public = "PUBLIC",
  Guest = "GUEST",
  Virtual = "VIRTUAL",
  ProductReader = "PRODUCT_READER",
  ProductContributor = "PRODUCT_CONTRIBUTOR",
  ProductOwner = "PRODUCT_OWNER",
  WorkItemReader = "WORK_ITEM_READER",
  WorkItemContributor = "WORK_ITEM_CONTRIBUTOR",
  WorkItemOwner = "WORK_ITEM_OWNER"
}

export enum CurrencyCode {
  Null = "NULL",
  Inr = "INR",
  Usd = "USD",
  Eur = "EUR",
  Gbp = "GBP"
}

export enum ResourceKind {
  Null = "NULL",
  Person = "PERSON",
  Organization = "ORGANIZATION",
  Product = "PRODUCT",
  Order = "ORDER",
  WorkItem = "WORK_ITEM",
  Job = "JOB",
  Task = "TASK",
  Comment = "COMMENT",
  Attachment = "ATTACHMENT",
  Membership = "MEMBERSHIP",
  Review = "REVIEW",
  Share = "SHARE",
  ReferenceData = "REFERENCE_DATA",
  AccountInvoice = "ACCOUNT_INVOICE",
  AccountTransaction = "ACCOUNT_TRANSACTION",
  AccountSubscription = "ACCOUNT_SUBSCRIPTION",
  Event = "EVENT"
}

export enum ChangeOperationType {
  Replace = "REPLACE",
  Add = "ADD",
  Remove = "REMOVE",
  Move = "MOVE",
  Copy = "COPY",
  Test = "TEST",
  Problem = "PROBLEM"
}

export enum CityCode {
  Null = "NULL",
  InBlr = "IN_BLR",
  InBom = "IN_BOM",
  InDel = "IN_DEL",
  InMaa = "IN_MAA",
  InPnq = "IN_PNQ",
  InHyd = "IN_HYD",
  InCcu = "IN_CCU",
  InAmd = "IN_AMD",
  InJai = "IN_JAI",
  InCok = "IN_COK",
  InIdr = "IN_IDR",
  InNag = "IN_NAG",
  InPat = "IN_PAT",
  InKnu = "IN_KNU",
  InDlNcr = "IN_DL_NCR",
  InUpNoi = "IN_UP_NOI",
  InHrGur = "IN_HR_GUR",
  Other = "OTHER",
  Blr = "BLR",
  Bom = "BOM",
  Del = "DEL",
  Maa = "MAA",
  Pnq = "PNQ",
  Hyd = "HYD",
  Ccu = "CCU",
  IndiaNoida = "INDIA_NOIDA",
  IndiaGurugram = "INDIA_GURUGRAM"
}

export enum CountryCode {
  Null = "NULL",
  In = "IN",
  Us = "US",
  Uk = "UK"
}

export enum IndustryType {
  Null = "NULL",
  LegalIndustry = "LEGAL_INDUSTRY",
  AccountingIndustry = "ACCOUNTING_INDUSTRY",
  EducationIndustry = "EDUCATION_INDUSTRY",
  HealthcareIndustry = "HEALTHCARE_INDUSTRY",
  MediaIndustry = "MEDIA_INDUSTRY",
  InformationTechnologyIndustry = "INFORMATION_TECHNOLOGY_INDUSTRY"
}

export enum AreaType {
  Null = "NULL",
  LegalAreaAdministrativeLaw = "LEGAL_AREA_ADMINISTRATIVE_LAW",
  LegalAreaArbitrationProcedureLaw = "LEGAL_AREA_ARBITRATION_PROCEDURE_LAW",
  LegalAreaBankingLaw = "LEGAL_AREA_BANKING_LAW",
  LegalAreaCivilProcedureLaw = "LEGAL_AREA_CIVIL_PROCEDURE_LAW",
  LegalAreaCompanyLaw = "LEGAL_AREA_COMPANY_LAW",
  LegalAreaConstitutionLaw = "LEGAL_AREA_CONSTITUTION_LAW",
  LegalAreaConsumerLaw = "LEGAL_AREA_CONSUMER_LAW",
  LegalAreaContractLaw = "LEGAL_AREA_CONTRACT_LAW",
  LegalAreaCriminalLaw = "LEGAL_AREA_CRIMINAL_LAW",
  LegalAreaCriminalProcedureLaw = "LEGAL_AREA_CRIMINAL_PROCEDURE_LAW",
  LegalAreaElectionLaw = "LEGAL_AREA_ELECTION_LAW",
  LegalAreaEnvironmentLaw = "LEGAL_AREA_ENVIRONMENT_LAW",
  LegalAreaFamilyLaw = "LEGAL_AREA_FAMILY_LAW",
  LegalAreaFinanceLaw = "LEGAL_AREA_FINANCE_LAW",
  LegalAreaHinduLaw = "LEGAL_AREA_HINDU_LAW",
  LegalAreaHumanRightsLaw = "LEGAL_AREA_HUMAN_RIGHTS_LAW",
  LegalAreaInformationTechnologyLaw = "LEGAL_AREA_INFORMATION_TECHNOLOGY_LAW",
  LegalAreaIntellectualPropertyLaw = "LEGAL_AREA_INTELLECTUAL_PROPERTY_LAW",
  LegalAreaInternationalLaw = "LEGAL_AREA_INTERNATIONAL_LAW",
  LegalAreaInternationalTradeLaw = "LEGAL_AREA_INTERNATIONAL_TRADE_LAW",
  LegalAreaLabourLaw = "LEGAL_AREA_LABOUR_LAW",
  LegalAreaLandLaw = "LEGAL_AREA_LAND_LAW",
  LegalAreaMediaLaw = "LEGAL_AREA_MEDIA_LAW",
  LegalAreaMedicalLaw = "LEGAL_AREA_MEDICAL_LAW",
  LegalAreaMergersAndAcquisitionsLaw = "LEGAL_AREA_MERGERS_AND_ACQUISITIONS_LAW",
  LegalAreaProceduralLaw = "LEGAL_AREA_PROCEDURAL_LAW",
  LegalAreaRealEstateLaw = "LEGAL_AREA_REAL_ESTATE_LAW",
  LegalAreaPropertyLaw = "LEGAL_AREA_PROPERTY_LAW",
  LegalAreaStateLaw = "LEGAL_AREA_STATE_LAW",
  LegalAreaSubstantiveLaw = "LEGAL_AREA_SUBSTANTIVE_LAW",
  LegalAreaTaxLaw = "LEGAL_AREA_TAX_LAW",
  LegalAreaTortLaw = "LEGAL_AREA_TORT_LAW",
  LegalAreaTradeLaw = "LEGAL_AREA_TRADE_LAW",
  LegalAreaTrustLaw = "LEGAL_AREA_TRUST_LAW",
  LegalAreaOther = "LEGAL_AREA_OTHER",
  LegalAreaCompetitionLaw = "LEGAL_AREA_COMPETITION_LAW",
  AccountingAreaFinancialAccounting = "ACCOUNTING_AREA_FINANCIAL_ACCOUNTING",
  AccountingAreaManagerialAccounting = "ACCOUNTING_AREA_MANAGERIAL_ACCOUNTING",
  AccountingAreaAuditingResearch = "ACCOUNTING_AREA_AUDITING_RESEARCH",
  AccountingAreaTaxationResearch = "ACCOUNTING_AREA_TAXATION_RESEARCH",
  AccountingAreaGovernanceResearch = "ACCOUNTING_AREA_GOVERNANCE_RESEARCH",
  AccountingAreaCapitalMarketResearch = "ACCOUNTING_AREA_CAPITAL_MARKET_RESEARCH",
  AccountingAreaCommodityMarketResearch = "ACCOUNTING_AREA_COMMODITY_MARKET_RESEARCH",
  AccountingAreaCostAccounting = "ACCOUNTING_AREA_COST_ACCOUNTING",
  AccountingAreaIncomeTaxAccounting = "ACCOUNTING_AREA_INCOME_TAX_ACCOUNTING",
  AccountingAreaSalesTaxAccounting = "ACCOUNTING_AREA_SALES_TAX_ACCOUNTING",
  AccountingAreaBusinessAccounting = "ACCOUNTING_AREA_BUSINESS_ACCOUNTING",
  AccountingAreaAccountingStandards = "ACCOUNTING_AREA_ACCOUNTING_STANDARDS",
  BusinessDevelopmentAreaSmallEnterprises = "BUSINESS_DEVELOPMENT_AREA_SMALL_ENTERPRISES",
  BusinessDevelopmentAreaMediumEnterprises = "BUSINESS_DEVELOPMENT_AREA_MEDIUM_ENTERPRISES",
  BusinessDevelopmentAreaLargeEnterprises = "BUSINESS_DEVELOPMENT_AREA_LARGE_ENTERPRISES",
  BusinessDevelopmentAreaMicroEnterprises = "BUSINESS_DEVELOPMENT_AREA_MICRO_ENTERPRISES",
  MarketingAreaSocialMediaMarketing = "MARKETING_AREA_SOCIAL_MEDIA_MARKETING",
  MarketingAreaDigitalMarketing = "MARKETING_AREA_DIGITAL_MARKETING",
  MarketingAreaPrintMarketing = "MARKETING_AREA_PRINT_MARKETING",
  MarketingAreaRadioMarketing = "MARKETING_AREA_RADIO_MARKETING",
  MarketingAreaTelevitionMarketing = "MARKETING_AREA_TELEVITION_MARKETING",
  MarketingAreaSearchEngineMarketing = "MARKETING_AREA_SEARCH_ENGINE_MARKETING",
  MarketingAreaEmailMarketing = "MARKETING_AREA_EMAIL_MARKETING",
  MarketingAreaVideoMarketing = "MARKETING_AREA_VIDEO_MARKETING",
  MarketingAreaTeleMarketing = "MARKETING_AREA_TELE_MARKETING",
  SoftwareEngineeringAreaCSharpProgramming = "SOFTWARE_ENGINEERING_AREA_C_SHARP_PROGRAMMING",
  SoftwareEngineeringAreaJavascriptProgramming = "SOFTWARE_ENGINEERING_AREA_JAVASCRIPT_PROGRAMMING",
  SoftwareEngineeringAreaPythonProgramming = "SOFTWARE_ENGINEERING_AREA_PYTHON_PROGRAMMING",
  SoftwareEngineeringAreaJavaProgramming = "SOFTWARE_ENGINEERING_AREA_JAVA_PROGRAMMING",
  SoftwareEngineeringAreaRProgramming = "SOFTWARE_ENGINEERING_AREA_R_PROGRAMMING",
  SoftwareEngineeringAreaSwiftProgramming = "SOFTWARE_ENGINEERING_AREA_SWIFT_PROGRAMMING",
  SoftwareEngineeringAreaCPlusPlusProgramming = "SOFTWARE_ENGINEERING_AREA_C_PLUS_PLUS_PROGRAMMING",
  SoftwareEngineeringAreaSqlProgramming = "SOFTWARE_ENGINEERING_AREA_SQL_PROGRAMMING",
  SoftwareEngineeringAreaGoProgramming = "SOFTWARE_ENGINEERING_AREA_GO_PROGRAMMING",
  SoftwareEngineeringAreaRustProgramming = "SOFTWARE_ENGINEERING_AREA_RUST_PROGRAMMING",
  SoftwareEngineeringAreaPhpProgramming = "SOFTWARE_ENGINEERING_AREA_PHP_PROGRAMMING",
  SoftwareEngineeringAreaReactProgramming = "SOFTWARE_ENGINEERING_AREA_REACT_PROGRAMMING",
  SoftwareEngineeringAreaGraphQlProgramming = "SOFTWARE_ENGINEERING_AREA_GRAPH_QL_PROGRAMMING",
  SoftwareEngineeringAreaAzureProgramming = "SOFTWARE_ENGINEERING_AREA_AZURE_PROGRAMMING",
  SoftwareEngineeringAreaAwsProgramming = "SOFTWARE_ENGINEERING_AREA_AWS_PROGRAMMING",
  SoftwareEngineeringAreaTypescriptProgramming = "SOFTWARE_ENGINEERING_AREA_TYPESCRIPT_PROGRAMMING"
}

export enum FunctionType {
  Null = "NULL",
  LegalFunction = "LEGAL_FUNCTION",
  AccountingFunction = "ACCOUNTING_FUNCTION",
  EducationFunction = "EDUCATION_FUNCTION",
  HealthcareFunction = "HEALTHCARE_FUNCTION",
  MediaFunction = "MEDIA_FUNCTION",
  InformationTechnologyFunction = "INFORMATION_TECHNOLOGY_FUNCTION",
  AdministrativeFunction = "ADMINISTRATIVE_FUNCTION",
  BusinessDevelopmentFunction = "BUSINESS_DEVELOPMENT_FUNCTION",
  CommunityFunction = "COMMUNITY_FUNCTION",
  ConsultingFunction = "CONSULTING_FUNCTION",
  DesignFunction = "DESIGN_FUNCTION",
  EngineeringFunction = "ENGINEERING_FUNCTION",
  EntrepreneurshipFunction = "ENTREPRENEURSHIP_FUNCTION",
  FinanceFunction = "FINANCE_FUNCTION",
  HumanResourcesFunction = "HUMAN_RESOURCES_FUNCTION",
  MarketingFunction = "MARKETING_FUNCTION",
  OperationsFunction = "OPERATIONS_FUNCTION",
  ProductManagementFunction = "PRODUCT_MANAGEMENT_FUNCTION",
  ProtectionFunction = "PROTECTION_FUNCTION",
  PurchasingFunction = "PURCHASING_FUNCTION",
  QualityAssuranceFunction = "QUALITY_ASSURANCE_FUNCTION",
  RealEstateFunction = "REAL_ESTATE_FUNCTION",
  ResearchFunction = "RESEARCH_FUNCTION",
  SalesFunction = "SALES_FUNCTION",
  SupportFunction = "SUPPORT_FUNCTION",
  SoftwareEngineeringFunction = "SOFTWARE_ENGINEERING_FUNCTION"
}

export enum LanguageCode {
  Null = "NULL",
  En = "EN",
  Hi = "HI",
  Kn = "KN",
  Ta = "TA",
  Bn = "BN",
  Gu = "GU",
  Ml = "ML",
  Mr = "MR",
  Pa = "PA",
  Te = "TE",
  Ur = "UR",
  Fr = "FR",
  De = "DE",
  Es = "ES",
  Ar = "AR",
  Ja = "JA"
}

export enum MessageAddressType {
  MergeTo = "MERGE_TO",
  AdditionalTo = "ADDITIONAL_TO",
  Copy = "COPY",
  BlindCopy = "BLIND_COPY",
  From = "FROM"
}

export enum ResourceEvent {
  Null = "NULL",
  CustomerAcceptedOffer = "CUSTOMER_ACCEPTED_OFFER",
  CustomerCreatedOrder = "CUSTOMER_CREATED_ORDER",
  CustomerDeclinedOffer = "CUSTOMER_DECLINED_OFFER",
  CustomerSubmittedOrder = "CUSTOMER_SUBMITTED_ORDER",
  SupplierCreatedOffer = "SUPPLIER_CREATED_OFFER",
  SupplierCreatedOrder = "SUPPLIER_CREATED_ORDER",
  SupplierDeliveredOrder = "SUPPLIER_DELIVERED_ORDER",
  SupplierDeliveredOffer = "SUPPLIER_DELIVERED_OFFER",
  SupplierRefusedOrder = "SUPPLIER_REFUSED_ORDER",
  SupplierRescheduledInterview = "SUPPLIER_RESCHEDULED_INTERVIEW",
  SupplierRescheduledScreening = "SUPPLIER_RESCHEDULED_SCREENING",
  SupplierScheduledInterview = "SUPPLIER_SCHEDULED_INTERVIEW",
  SupplierScheduledScreening = "SUPPLIER_SCHEDULED_SCREENING",
  SupplierShortlistedOrder = "SUPPLIER_SHORTLISTED_ORDER",
  SupplierUpdatedOffer = "SUPPLIER_UPDATED_OFFER",
  SupplierWithdrewOffer = "SUPPLIER_WITHDREW_OFFER",
  WorkerSubmittedJob = "WORKER_SUBMITTED_JOB",
  ReviewerReviewedJob = "REVIEWER_REVIEWED_JOB",
  MessageReceived = "MESSAGE_RECEIVED",
  CustomEvent = "CUSTOM_EVENT",
  CustomerViewedOrder = "CUSTOMER_VIEWED_ORDER",
  SupplierViewedOrder = "SUPPLIER_VIEWED_ORDER",
  CustomerViewedOrderMessage = "CUSTOMER_VIEWED_ORDER_MESSAGE",
  SupplierViewedOrderMessage = "SUPPLIER_VIEWED_ORDER_MESSAGE",
  MessageViewed = "MESSAGE_VIEWED",
  CustomerUpdatedResume = "CUSTOMER_UPDATED_RESUME",
  SupplierProposedShare = "SUPPLIER_PROPOSED_SHARE",
  CustomerRequestedCreateOrder = "CUSTOMER_REQUESTED_CREATE_ORDER",
  SupplierAllowedCreateOrder = "SUPPLIER_ALLOWED_CREATE_ORDER"
}

export enum OrderStage {
  Null = "NULL",
  Stopped = "STOPPED",
  Started = "STARTED",
  Shortlisted = "SHORTLISTED",
  Offered = "OFFERED",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  OfferExpired = "OFFER_EXPIRED",
  Delivered = "DELIVERED",
  Cancelled = "CANCELLED",
  Removed = "REMOVED",
  Submitted = "SUBMITTED",
  ScreeningScheduled = "SCREENING_SCHEDULED",
  InterviewScheduled = "INTERVIEW_SCHEDULED",
  Refused = "REFUSED",
  Recycled = "RECYCLED",
  Returned = "RETURNED",
  Suggested = "SUGGESTED",
  Requested = "REQUESTED"
}

export enum RuleActionType {
  Null = "NULL",
  MessageOther = "MESSAGE_OTHER",
  MessageOrderCustomer = "MESSAGE_ORDER_CUSTOMER",
  MessageProductSupplier = "MESSAGE_PRODUCT_SUPPLIER",
  UpdateOrder = "UPDATE_ORDER"
}

export enum RuleType {
  Null = "NULL",
  Scheduled = "SCHEDULED",
  EventTriggered = "EVENT_TRIGGERED",
  StageChanged = "STAGE_CHANGED"
}

export enum ServiceType {
  Null = "NULL",
  LegalServiceFullService = "LEGAL_SERVICE_FULL_SERVICE",
  LegalServiceCorporateTransactional = "LEGAL_SERVICE_CORPORATE_TRANSACTIONAL",
  LegalServiceCivilLitigation = "LEGAL_SERVICE_CIVIL_LITIGATION",
  LegalServiceTransactional = "LEGAL_SERVICE_TRANSACTIONAL",
  LegalServiceOutsourcing = "LEGAL_SERVICE_OUTSOURCING",
  LegalServiceJudicial = "LEGAL_SERVICE_JUDICIAL",
  LegalServiceResearch = "LEGAL_SERVICE_RESEARCH",
  LegalServiceCriminalLitigation = "LEGAL_SERVICE_CRIMINAL_LITIGATION",
  LegalServiceLitigation = "LEGAL_SERVICE_LITIGATION",
  LegalServiceInHouse = "LEGAL_SERVICE_IN_HOUSE",
  LegalServiceInHouseCounsel = "LEGAL_SERVICE_IN_HOUSE_COUNSEL",
  LegalServiceBoutique = "LEGAL_SERVICE_BOUTIQUE",
  LegalServiceSoloPractitioner = "LEGAL_SERVICE_SOLO_PRACTITIONER",
  LegalServiceVirtualFirm = "LEGAL_SERVICE_VIRTUAL_FIRM",
  AccountingServiceBigFour = "ACCOUNTING_SERVICE_BIG_FOUR",
  AccountingServiceMidTier = "ACCOUNTING_SERVICE_MID_TIER",
  AccountingServiceRegional = "ACCOUNTING_SERVICE_REGIONAL",
  AccountingServiceBoutique = "ACCOUNTING_SERVICE_BOUTIQUE",
  AccountingServiceLocal = "ACCOUNTING_SERVICE_LOCAL",
  AccountingServicePublicFinance = "ACCOUNTING_SERVICE_PUBLIC_FINANCE",
  AccountingServiceCorporateFinance = "ACCOUNTING_SERVICE_CORPORATE_FINANCE",
  AccountingServiceActuarial = "ACCOUNTING_SERVICE_ACTUARIAL",
  AccountingServiceAudit = "ACCOUNTING_SERVICE_AUDIT",
  AccountingServiceAssurance = "ACCOUNTING_SERVICE_ASSURANCE",
  AccountingServiceTax = "ACCOUNTING_SERVICE_TAX",
  AccountingServicePayroll = "ACCOUNTING_SERVICE_PAYROLL",
  AccountingServiceBookkeeping = "ACCOUNTING_SERVICE_BOOKKEEPING",
  BusinessDevelopmentServiceLeadGeneration = "BUSINESS_DEVELOPMENT_SERVICE_LEAD_GENERATION",
  BusinessDevelopmentServiceCustomerRelationshipManagement = "BUSINESS_DEVELOPMENT_SERVICE_CUSTOMER_RELATIONSHIP_MANAGEMENT",
  BusinessDevelopmentServicePartnerRelationshipManagement = "BUSINESS_DEVELOPMENT_SERVICE_PARTNER_RELATIONSHIP_MANAGEMENT",
  BusinessDevelopmentServiceMarketAnalysis = "BUSINESS_DEVELOPMENT_SERVICE_MARKET_ANALYSIS",
  BusinessDevelopmentServiceBusinessStrategyDevelopment = "BUSINESS_DEVELOPMENT_SERVICE_BUSINESS_STRATEGY_DEVELOPMENT",
  MarketingServiceProductMarketing = "MARKETING_SERVICE_PRODUCT_MARKETING",
  MarketingServiceMarketResearch = "MARKETING_SERVICE_MARKET_RESEARCH",
  MarketingServiceChannelMarketing = "MARKETING_SERVICE_CHANNEL_MARKETING",
  MarketingServiceMarketingCommunications = "MARKETING_SERVICE_MARKETING_COMMUNICATIONS",
  SoftwareEngineeringServiceWebDevelopment = "SOFTWARE_ENGINEERING_SERVICE_WEB_DEVELOPMENT",
  SoftwareEngineeringServiceBackEndDevelopment = "SOFTWARE_ENGINEERING_SERVICE_BACK_END_DEVELOPMENT",
  SoftwareEngineeringServiceMobileDevelopment = "SOFTWARE_ENGINEERING_SERVICE_MOBILE_DEVELOPMENT",
  SoftwareEngineeringServiceFullStackDevelopment = "SOFTWARE_ENGINEERING_SERVICE_FULL_STACK_DEVELOPMENT"
}

export enum ProductType {
  Null = "NULL",
  EmploymentTrainingLevel = "EMPLOYMENT_TRAINING_LEVEL",
  LegalEmploymentTrainingLevel = "LEGAL_EMPLOYMENT_TRAINING_LEVEL",
  LegalEmploymentInternLevel = "LEGAL_EMPLOYMENT_INTERN_LEVEL",
  EmploymentEntryLevel = "EMPLOYMENT_ENTRY_LEVEL",
  LegalEmploymentEntryLevel = "LEGAL_EMPLOYMENT_ENTRY_LEVEL",
  EmploymentSeniorLevel = "EMPLOYMENT_SENIOR_LEVEL",
  LegalEmploymentSeniorLevel = "LEGAL_EMPLOYMENT_SENIOR_LEVEL",
  EmploymentManagerLevel = "EMPLOYMENT_MANAGER_LEVEL",
  LegalEmploymentManagerLevel = "LEGAL_EMPLOYMENT_MANAGER_LEVEL",
  EmploymentDirectorLevel = "EMPLOYMENT_DIRECTOR_LEVEL",
  LegalEmploymentDirectorLevel = "LEGAL_EMPLOYMENT_DIRECTOR_LEVEL",
  EmploymentVicePresidentLevel = "EMPLOYMENT_VICE_PRESIDENT_LEVEL",
  LegalEmploymentVicePresidentLevel = "LEGAL_EMPLOYMENT_VICE_PRESIDENT_LEVEL",
  EmploymentChiefOfficerLevel = "EMPLOYMENT_CHIEF_OFFICER_LEVEL",
  LegalEmploymentExecutiveLevel = "LEGAL_EMPLOYMENT_EXECUTIVE_LEVEL",
  EmploymentPartnerLevel = "EMPLOYMENT_PARTNER_LEVEL",
  LegalEmploymentPartnerLevel = "LEGAL_EMPLOYMENT_PARTNER_LEVEL",
  EmploymentOwnerLevel = "EMPLOYMENT_OWNER_LEVEL",
  LegalEmploymentOwnerLevel = "LEGAL_EMPLOYMENT_OWNER_LEVEL",
  EmploymentUnpaidLevel = "EMPLOYMENT_UNPAID_LEVEL",
  EmploymentContract = "EMPLOYMENT_CONTRACT",
  EmploymentPartTime = "EMPLOYMENT_PART_TIME",
  EmploymentFullTime = "EMPLOYMENT_FULL_TIME",
  EmploymentRemote = "EMPLOYMENT_REMOTE",
  EmploymentTemporary = "EMPLOYMENT_TEMPORARY",
  CustomerService = "CUSTOMER_SERVICE"
}

export enum ProductVariantType {
  Null = "NULL",
  Location = "LOCATION",
  ServiceAreas = "SERVICE_AREAS",
  LocationServiceAreas = "LOCATION_SERVICE_AREAS",
  Organization = "ORGANIZATION"
}

export enum AttachmentType {
  Null = "NULL",
  Resume = "RESUME",
  GraduationCertificate = "GRADUATION_CERTIFICATE",
  ProfessionalLicense = "PROFESSIONAL_LICENSE",
  BusinessCard = "BUSINESS_CARD",
  Publication = "PUBLICATION",
  EducationalTranscript = "EDUCATIONAL_TRANSCRIPT",
  Other = "OTHER",
  ProductOrdersReport = "PRODUCT_ORDERS_REPORT",
  OrderScreeningCalendarEvent = "ORDER_SCREENING_CALENDAR_EVENT",
  OrderInterviewCalendarEvent = "ORDER_INTERVIEW_CALENDAR_EVENT",
  OrderStartCalendarEvent = "ORDER_START_CALENDAR_EVENT",
  ReferenceLetter = "REFERENCE_LETTER",
  RecommendationLetter = "RECOMMENDATION_LETTER",
  Photo = "PHOTO",
  TaxpayerIdentification = "TAXPAYER_IDENTIFICATION",
  EmploymentCertificate = "EMPLOYMENT_CERTIFICATE",
  SalaryCertificate = "SALARY_CERTIFICATE",
  InternalReference = "INTERNAL_REFERENCE",
  CourseCertificate = "COURSE_CERTIFICATE",
  OtherCertificate = "OTHER_CERTIFICATE"
}

export enum ReviewOption {
  Null = "NULL",
  Like = "LIKE",
  Love = "LOVE",
  Flag = "FLAG",
  Spam = "SPAM",
  Offensive = "OFFENSIVE",
  Plagiarised = "PLAGIARISED",
  Empty = "EMPTY",
  LanguageExcellent = "LANGUAGE_EXCELLENT",
  LanguagePoor = "LANGUAGE_POOR",
  LegalResearchRephrasingAreaPoor = "LEGAL_RESEARCH_REPHRASING_AREA_POOR",
  LegalResearchRephrasingJurisdictionPoor = "LEGAL_RESEARCH_REPHRASING_JURISDICTION_POOR",
  LegalResearchRephrasingQueryExcellent = "LEGAL_RESEARCH_REPHRASING_QUERY_EXCELLENT",
  LegalResearchRephrasingQueryPoor = "LEGAL_RESEARCH_REPHRASING_QUERY_POOR",
  LegalResearchBackgroundExcerptExcellent = "LEGAL_RESEARCH_BACKGROUND_EXCERPT_EXCELLENT",
  LegalResearchBackgroundExcerptPoor = "LEGAL_RESEARCH_BACKGROUND_EXCERPT_POOR",
  LegalResearchBackgroundSearchQueryPoor = "LEGAL_RESEARCH_BACKGROUND_SEARCH_QUERY_POOR",
  LegalResearchBackgroundUrlPoor = "LEGAL_RESEARCH_BACKGROUND_URL_POOR",
  LegalResearchBackgroundUrlExcellent = "LEGAL_RESEARCH_BACKGROUND_URL_EXCELLENT",
  LegalResearchStatuteExcerptExcellent = "LEGAL_RESEARCH_STATUTE_EXCERPT_EXCELLENT",
  LegalResearchStatuteExcerptFair = "LEGAL_RESEARCH_STATUTE_EXCERPT_FAIR",
  LegalResearchStatuteExcerptPoor = "LEGAL_RESEARCH_STATUTE_EXCERPT_POOR",
  LegalResearchStatuteSearchQueryPoor = "LEGAL_RESEARCH_STATUTE_SEARCH_QUERY_POOR",
  LegalResearchStatuteSectionFair = "LEGAL_RESEARCH_STATUTE_SECTION_FAIR",
  LegalResearchStatuteSectionPoor = "LEGAL_RESEARCH_STATUTE_SECTION_POOR",
  LegalResearchStatuteTitlePoor = "LEGAL_RESEARCH_STATUTE_TITLE_POOR",
  LegalResearchStatuteUrlExcellent = "LEGAL_RESEARCH_STATUTE_URL_EXCELLENT",
  LegalResearchStatuteUrlPoor = "LEGAL_RESEARCH_STATUTE_URL_POOR",
  LegalResearchPrecedentCitationPoor = "LEGAL_RESEARCH_PRECEDENT_CITATION_POOR",
  LegalResearchPrecedentCourtExcellent = "LEGAL_RESEARCH_PRECEDENT_COURT_EXCELLENT",
  LegalResearchPrecedentCourtFair = "LEGAL_RESEARCH_PRECEDENT_COURT_FAIR",
  LegalResearchPrecedentCourtPoor = "LEGAL_RESEARCH_PRECEDENT_COURT_POOR",
  LegalResearchPrecedentExcerptExcellent = "LEGAL_RESEARCH_PRECEDENT_EXCERPT_EXCELLENT",
  LegalResearchPrecedentExcerptFair = "LEGAL_RESEARCH_PRECEDENT_EXCERPT_FAIR",
  LegalResearchPrecedentExcerptPoor = "LEGAL_RESEARCH_PRECEDENT_EXCERPT_POOR",
  LegalResearchPrecedentFactsExcellent = "LEGAL_RESEARCH_PRECEDENT_FACTS_EXCELLENT",
  LegalResearchPrecedentFactsFair = "LEGAL_RESEARCH_PRECEDENT_FACTS_FAIR",
  LegalResearchPrecedentFactsPoor = "LEGAL_RESEARCH_PRECEDENT_FACTS_POOR",
  LegalResearchPrecedentSearchQueryPoor = "LEGAL_RESEARCH_PRECEDENT_SEARCH_QUERY_POOR",
  LegalResearchPrecedentUrlPoor = "LEGAL_RESEARCH_PRECEDENT_URL_POOR",
  LegalResearchPrecedentUrlExcellent = "LEGAL_RESEARCH_PRECEDENT_URL_EXCELLENT",
  LegalResearchConclusionActionItemExcellent = "LEGAL_RESEARCH_CONCLUSION_ACTION_ITEM_EXCELLENT",
  LegalResearchConclusionActionItemPoor = "LEGAL_RESEARCH_CONCLUSION_ACTION_ITEM_POOR",
  LegalResearchConclusionClarifyingQuestionExcellent = "LEGAL_RESEARCH_CONCLUSION_CLARIFYING_QUESTION_EXCELLENT",
  LegalResearchConclusionClarifyingQuestionPoor = "LEGAL_RESEARCH_CONCLUSION_CLARIFYING_QUESTION_POOR",
  LegalResearchConclusionOpinionExcellent = "LEGAL_RESEARCH_CONCLUSION_OPINION_EXCELLENT",
  LegalResearchConclusionOpinionPoor = "LEGAL_RESEARCH_CONCLUSION_OPINION_POOR",
  AttachmentResumeContactEmailPoor = "ATTACHMENT_RESUME_CONTACT_EMAIL_POOR"
}

export enum ReviewType {
  Null = "NULL",
  SystemJobReview = "SYSTEM_JOB_REVIEW",
  SystemOrderReview = "SYSTEM_ORDER_REVIEW",
  SystemPersonReview = "SYSTEM_PERSON_REVIEW",
  TaskReview = "TASK_REVIEW",
  JobReview = "JOB_REVIEW",
  OrderReview = "ORDER_REVIEW",
  OrderScreeningReview = "ORDER_SCREENING_REVIEW",
  OrderInterviewReview = "ORDER_INTERVIEW_REVIEW",
  OrderDeliveryReview = "ORDER_DELIVERY_REVIEW",
  OrderReturnReview = "ORDER_RETURN_REVIEW",
  PersonReview = "PERSON_REVIEW",
  AttachmentReview = "ATTACHMENT_REVIEW"
}

export enum TaskType {
  Null = "NULL",
  LegalResearchRephrasing = "LEGAL_RESEARCH_REPHRASING",
  LegalResearchBackground = "LEGAL_RESEARCH_BACKGROUND",
  LegalResearchStatute = "LEGAL_RESEARCH_STATUTE",
  LegalResearchPrecedent = "LEGAL_RESEARCH_PRECEDENT",
  LegalResearchConclusion = "LEGAL_RESEARCH_CONCLUSION",
  LegalMeetingParticipation = "LEGAL_MEETING_PARTICIPATION"
}

export enum WorkItemType {
  Null = "NULL",
  LegalResearch = "LEGAL_RESEARCH",
  LegalDiscussion = "LEGAL_DISCUSSION"
}

export enum AccountTransactionType {
  Null = "NULL",
  ProductSale = "PRODUCT_SALE",
  CustomerPayment = "CUSTOMER_PAYMENT",
  VendorPayout = "VENDOR_PAYOUT",
  Expense = "EXPENSE",
  ProductRefund = "PRODUCT_REFUND"
}

export enum OrganizationType {
  Null = "NULL",
  Public = "PUBLIC",
  Private = "PRIVATE",
  Voluntary = "VOLUNTARY"
}

export enum MembershipType {
  Null = "NULL",
  Member = "MEMBER",
  Student = "STUDENT",
  Intern = "INTERN",
  Employee = "EMPLOYEE",
  Volunteer = "VOLUNTEER"
}

export enum ExternalAccountOption {
  Null = "NULL",
  PhoneSms = "PHONE_SMS",
  PhoneWhatsApp = "PHONE_WHATS_APP",
  FacebookMessenger = "FACEBOOK_MESSENGER",
  EmailRecommendations = "EMAIL_RECOMMENDATIONS",
  PhoneRecommendations = "PHONE_RECOMMENDATIONS",
  MailRecommendations = "MAIL_RECOMMENDATIONS"
}

export enum ExternalAccountType {
  Null = "NULL",
  Email = "EMAIL",
  Phone = "PHONE",
  Mail = "MAIL",
  Facebook = "FACEBOOK",
  LinkedIn = "LINKED_IN",
  Google = "GOOGLE",
  Microsoft = "MICROSOFT",
  Instagram = "INSTAGRAM",
  Amazon = "AMAZON",
  QuickBooks = "QUICK_BOOKS",
  Twitter = "TWITTER",
  Payment = "PAYMENT",
  Kyc = "KYC",
  Authenticator = "AUTHENTICATOR",
  WhatsApp = "WHATS_APP",
  ClientApp = "CLIENT_APP",
}

export enum ErrorType {
  Ok = "OK",
  Invalid = "INVALID",
  InvalidEmail = "INVALID_EMAIL",
  InvalidPhone = "INVALID_PHONE",
  InvalidAddress = "INVALID_ADDRESS",
  InvalidUri = "INVALID_URI",
  InvalidVerificationCode = "INVALID_VERIFICATION_CODE",
  Duplicate = "DUPLICATE",
  Unauthorized = "UNAUTHORIZED",
  Unauthenticated = "UNAUTHENTICATED",
  Exhausted = "EXHAUSTED",
  InsufficientBalance = "INSUFFICIENT_BALANCE"
}

export enum GenderType {
  Null = "NULL",
  Male = "MALE",
  Female = "FEMALE",
  Other = "OTHER"
}

export enum IncomePreferenceOption {
  Null = "NULL"
}

export enum LanguagePreferenceOption {
  Null = "NULL",
  Speak = "SPEAK",
  Read = "READ",
  Write = "WRITE",
  Fluent = "FLUENT"
}

export enum LocationPreferenceOption {
  Null = "NULL",
  Love = "LOVE",
  Like = "LIKE",
  Dislike = "DISLIKE",
  Current = "CURRENT",
  Past = "PAST",
  Future = "FUTURE",
  Preferred = "PREFERRED",
  FriendsAndFamily = "FRIENDS_AND_FAMILY"
}

export enum TopicPreferenceOption {
  Null = "NULL",
  Love = "LOVE",
  Like = "LIKE",
  Dislike = "DISLIKE",
  Current = "CURRENT",
  Past = "PAST",
  Future = "FUTURE",
  Preferred = "PREFERRED",
  NotPreferred = "NOT_PREFERRED"
}

export enum ReferenceDataType {
  Null = "NULL",
  LocationLocality = "LOCATION_LOCALITY",
  OrganizationName = "ORGANIZATION_NAME",
  Person = "PERSON",
  Product = "PRODUCT"
}

export enum QuickbooksEntity {
  Null = "NULL",
  Customer = "CUSTOMER",
  Vendor = "VENDOR"
}

export enum QuickbooksAccount {
  Null = "NULL",
  Razorpay = "RAZORPAY",
  RazorpayX = "RAZORPAY_X"
}

export enum ReportType {
  Null = "NULL",
  QualifiedActivities = "QUALIFIED_ACTIVITIES",
  ReviewerDiscussions = "REVIEWER_DISCUSSIONS",
  ListingActivity = "LISTING_ACTIVITY"
}

/** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
export type DateTime = string;

export type DateTimeStringGraphType = string;

/** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
export type Date = string;

/** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
export type DateTimeOffset = string;

export type Decimal = number;

/** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
export type Milliseconds = number;

/** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
export type Seconds = number;

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Interfaces
// ====================================================

export interface ITaskDetailsModel {
  readonly rev: number;
}

export interface IWorkItemDetailsModel {
  readonly area: Maybe<AreaType>;

  readonly description: Maybe<string>;

  readonly dueOn: Maybe<string>;

  readonly function: Maybe<FunctionType>;

  readonly industry: Maybe<IndustryType>;

  readonly jobsCount: number;

  readonly jobsDueOn: Maybe<string>;

  readonly rev: number;

  readonly title: Maybe<string>;

  readonly type: Maybe<WorkItemType>;
}

export interface ITaskDetailsOperations {
  readonly commit: Maybe<ITaskDetailsModel>;
}

export interface IWorkItemDetailsOperations {
  readonly commit: Maybe<IWorkItemDetailsModel>;
}

// ====================================================
// Types
// ====================================================

export interface Query {
  readonly attachment: Maybe<AttachmentModel>;

  readonly comment: Maybe<CommentModel>;

  readonly displayOrganizations: Maybe<ReadonlyArray<Maybe<OrganizationModel>>>;

  readonly displayProduct: Maybe<ProductModel>;

  readonly displayProducts: Maybe<ReadonlyArray<Maybe<ProductModel>>>;

  readonly file: Maybe<string>;

  readonly job: Maybe<JobModel>;

  readonly me: Maybe<MeModel>;

  readonly myOrganization: Maybe<OrganizationModel>;

  readonly order: Maybe<OrderModel>;

  readonly organization: Maybe<OrganizationModel>;

  readonly person: Maybe<PersonModel>;

  readonly persons: Maybe<ReadonlyArray<Maybe<PersonModel>>>;

  readonly product: Maybe<ProductModel>;

  readonly reference: Maybe<ReadonlyArray<Maybe<ReferenceDataModel>>>;

  readonly review: Maybe<ReviewModel>;

  readonly reviewJob: Maybe<JobModel>;

  readonly reviewJobs: Maybe<ReadonlyArray<Maybe<JobModel>>>;

  readonly reviewJobsSummary: Maybe<ResourcesSummaryModel>;

  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewModel>>>;

  readonly settings: Maybe<SettingsModel>;

  readonly share: Maybe<ShareModel>;

  readonly subscription: Maybe<AccountSubscriptionModel>;

  readonly task: Maybe<TaskModel>;

  readonly workitem: Maybe<WorkItemModel>;
}

export interface AttachmentActionsModel {
  readonly remove: Maybe<boolean>;
  readonly change: Maybe<boolean>;
  readonly download: Maybe<boolean>;
}

export interface AttachmentModel {
  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly commentsSummary: Maybe<ResourcesSummaryModel>;
  readonly actions: Maybe<AttachmentActionsModel>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly dataUri: Maybe<string>;

  readonly filename: Maybe<string>;

  readonly id: Maybe<string>;

  readonly issuerEmail: Maybe<string>;

  readonly issuerName: Maybe<string>;

  readonly issuedOn: Maybe<string>;
  readonly issuedUntil: Maybe<string>;
  readonly issuerOrganizationName: Maybe<string>;

  readonly issuerPhone: Maybe<string>;

  readonly issuerTitle: Maybe<string>;

  readonly issuerWebsite: Maybe<string>;

  readonly issuerReferenceId: Maybe<string>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly length: Maybe<number>;

  readonly mediaType: Maybe<string>;

  readonly owner: Maybe<PersonModel>;

  readonly pdfUrl: Maybe<string>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly review: Maybe<ReviewModel>;

  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewModel>>>;

  readonly reviewsSummary: Maybe<ReviewsSummaryModel>;

  readonly snippet: Maybe<string>;

  readonly state: Maybe<ResourceState>;

  readonly subtitle: Maybe<string>;

  readonly title: Maybe<string>;

  readonly token: Maybe<string>;

  readonly type: Maybe<AttachmentType>;

  readonly updatedOn: Maybe<string>;

  readonly url: Maybe<string>;
  readonly contentUri: Maybe<string>;
  readonly thumbnailUri: Maybe<string>;
  readonly squareCroppedUri: Maybe<string>;
}

export interface CommentModel {
  readonly actions: Maybe<CommentActions>;

  readonly author: Maybe<PersonModel>;

  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly id: Maybe<string>;

  readonly isEdited: Maybe<boolean>;

  readonly isPrivate: Maybe<boolean>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly message: Maybe<string>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly reviewsSummary: Maybe<ReviewsSummaryModel>;

  readonly rootCommentsSummary: Maybe<ResourcesSummaryModel>;

  readonly state: Maybe<ResourceState>;

  readonly updatedOn: Maybe<string>;

  readonly visibilityId: Maybe<string>;
}

export interface CommentActions {
  readonly change: Maybe<boolean>;

  readonly remove: Maybe<boolean>;

  readonly review: Maybe<boolean>;
}

export interface PersonModel {
  readonly accentColor: Maybe<string>;

  readonly actions: Maybe<PersonActions>;

  readonly affiliation: Maybe<MembershipModel>;

  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentModel>>>;

  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly contactInformation: Maybe<
    ReadonlyArray<Maybe<ExternalAccountModel>>
  >;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly eduAffiliation: Maybe<MembershipModel>;

  readonly email: Maybe<string>;

  readonly gender: Maybe<GenderType>;

  readonly graduationOn: Maybe<string>;

  readonly graduationOrganization: Maybe<OrganizationModel>;

  readonly graduationOrganizationName: Maybe<string>;

  readonly id: Maybe<string>;

  readonly incomePreference: Maybe<IncomePreferenceModel>;

  readonly job: Maybe<JobModel>;

  readonly jobs: Maybe<ReadonlyArray<Maybe<JobModel>>>;

  readonly jobsSummary: Maybe<ResourcesSummaryModel>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly languagePreferences: Maybe<
    ReadonlyArray<Maybe<LanguagePreferenceModel>>
  >;

  readonly locationPreferences: Maybe<
    ReadonlyArray<Maybe<LocationPreferenceModel>>
  >;

  readonly memberships: Maybe<ReadonlyArray<Maybe<MembershipModel>>>;

  readonly name: Maybe<string>;

  readonly nickname: Maybe<string>;

  readonly orders: Maybe<ReadonlyArray<Maybe<OrderModel>>>;

  readonly organization: Maybe<OrganizationModel>;

  readonly organizationName: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly photo: Maybe<AttachmentModel>;

  readonly picture: Maybe<string>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly products: Maybe<ReadonlyArray<Maybe<ProductModel>>>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly resume: Maybe<AttachmentModel>;

  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewModel>>>;

  readonly state: Maybe<ResourceState>;

  readonly subscriptions: Maybe<ReadonlyArray<Maybe<AccountSubscriptionModel>>>;

  readonly tasksSummary: Maybe<ResourcesSummaryModel>;

  readonly title: Maybe<string>;

  readonly topicPreferences: Maybe<ReadonlyArray<Maybe<TopicPreferenceModel>>>;

  readonly updatedOn: Maybe<string>;

  readonly workitems: Maybe<ReadonlyArray<Maybe<WorkItemModel>>>;
}

export interface PersonActions {
  readonly change: Maybe<boolean>;

  readonly impersonate: Maybe<boolean>;
}

export interface MembershipModel {
  readonly cgpa: Maybe<BoundedValueModel>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly description: Maybe<string>;

  readonly endOn: Maybe<string>;

  readonly focus: Maybe<string>;

  readonly id: Maybe<string>;

  readonly income: Maybe<MoneyModel>;

  readonly isEducational: Maybe<boolean>;

  readonly isPrimary: Maybe<boolean>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly location: Maybe<LocationModel>;

  readonly organization: Maybe<OrganizationModel>;

  readonly organizationName: Maybe<string>;

  readonly organizationNickname: Maybe<string>;

  readonly person: Maybe<PersonModel>;

  readonly rank: Maybe<BoundedValueModel>;

  readonly role: Maybe<ResourceRole>;

  readonly startOn: Maybe<string>;

  readonly state: Maybe<ResourceState>;

  readonly title: Maybe<string>;

  readonly type: Maybe<MembershipType>;

  readonly updatedOn: Maybe<string>;
}

export interface BoundedValueModel {
  readonly maxValue: Maybe<number>;

  readonly value: Maybe<number>;
}

export interface MoneyModel {
  readonly amount: Maybe<number>;

  readonly annualAmount: Maybe<number>;

  readonly annualIncome: Maybe<number>;

  readonly currency: Maybe<CurrencyCode>;

  readonly isGross: Maybe<boolean>;

  readonly isPrivate: Maybe<boolean>;

  readonly period: Maybe<string>;

  readonly variableFraction: Maybe<number>;
}

export interface LocationModel {
  readonly city: Maybe<CityCode>;

  readonly country: Maybe<CountryCode>;

  readonly locality: Maybe<string>;

  readonly neighborhood: Maybe<string>;

  readonly postalCode: Maybe<string>;

  readonly region: Maybe<string>;

  readonly streetAddress: Maybe<string>;
}

export interface OrganizationModel {
  readonly actions: Maybe<OrganizationActions>;

  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly description: Maybe<string>;

  readonly displayName: Maybe<string>;

  readonly emailDomain: Maybe<string>;

  readonly id: Maybe<string>;

  readonly industry: Maybe<IndustryType>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly location: Maybe<LocationModel>;

  readonly logo: Maybe<string>;

  readonly members: Maybe<ReadonlyArray<Maybe<PersonModel>>>;

  readonly name: Maybe<string>;

  readonly nickname: Maybe<string>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly products: Maybe<ReadonlyArray<Maybe<ProductModel>>>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly root: Maybe<OrganizationModel>;

  readonly state: Maybe<ResourceState>;

  readonly subscriptions: Maybe<ReadonlyArray<Maybe<AccountSubscriptionModel>>>;

  readonly type: Maybe<OrganizationType>;

  readonly unitName: Maybe<string>;

  readonly updatedOn: Maybe<string>;

  readonly websiteUrl: Maybe<string>;
  readonly phoneNumbers: Maybe<string>;
  readonly emailAddresses: Maybe<string>;

  readonly workitems: Maybe<ReadonlyArray<Maybe<WorkItemModel>>>;
}

export interface OrganizationActions {
  readonly change: Maybe<boolean>;

  readonly switch: Maybe<boolean>;
}

export interface ResourcePolicyModel {
  readonly accessToken: Maybe<string>;

  readonly addToken: Maybe<string>;

  readonly role: Maybe<ResourceRole>;
}

export interface ProductModel {
  readonly actions: Maybe<ProductActionsModel>;

  readonly collection: Maybe<ReadonlyArray<Maybe<ProductModel>>>;

  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly commentsSummary: Maybe<ResourcesSummaryModel>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly details: Maybe<ProductDetailsModel>;

  readonly id: Maybe<string>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly myOrders: Maybe<ReadonlyArray<Maybe<OrderModel>>>;

  readonly order: Maybe<OrderModel>;

  readonly orders: Maybe<ReadonlyArray<Maybe<OrderModel>>>;

  readonly ordersSummary: Maybe<OrdersSummaryModel>;

  readonly owner: Maybe<PersonModel>;

  readonly supplier: Maybe<OrganizationModel>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly publicEmail: Maybe<string>;

  readonly publicUrl: Maybe<string>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly review: Maybe<ReviewModel>;

  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewModel>>>;

  readonly reviewsSummary: Maybe<ReviewsSummaryModel>;

  readonly root: Maybe<ProductModel>;

  readonly shares: Maybe<ReadonlyArray<Maybe<ShareModel>>>;
  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentModel>>>;

  readonly state: Maybe<ResourceState>;

  readonly subscription: Maybe<AccountSubscriptionModel>;

  readonly updatedOn: Maybe<string>;
}

export interface ProductActionsModel {
  readonly change: Maybe<boolean>;

  readonly complete: Maybe<boolean>;

  readonly copy: Maybe<boolean>;

  readonly link: Maybe<boolean>;

  readonly order: Maybe<boolean>;

  readonly remove: Maybe<boolean>;

  readonly share: Maybe<boolean>;

  readonly start: Maybe<boolean>;

  readonly stop: Maybe<boolean>;

  readonly undo: Maybe<boolean>;

  readonly unlink: Maybe<boolean>;
}

export interface ResourcesSummaryModel {
  readonly completedCount: Maybe<number>;

  readonly count: Maybe<number>;

  readonly inprogressCount: Maybe<number>;

  readonly negativeCount: Maybe<number>;

  readonly neutralCount: Maybe<number>;

  readonly positiveCount: Maybe<number>;

  readonly proposedCount: Maybe<number>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly ratingHistogram: Maybe<ReadonlyArray<Maybe<number>>>;

  readonly removedCount: Maybe<number>;

  readonly resolvedCount: Maybe<number>;

  readonly resolvedPercent: Maybe<number>;
}

export interface ProductDetailsModel {
  readonly manufacturerOrg: Maybe<OrganizationModel>;

  readonly areas: Maybe<ReadonlyArray<Maybe<AreaType>>>;

  readonly availableOn: Maybe<string>;

  readonly compensation: Maybe<MoneyModel>;

  readonly description: Maybe<string>;

  readonly detailedDescription: Maybe<string>;

  readonly displayTitle: Maybe<string>;

  readonly duration: Maybe<string>;

  readonly eduRatingDisplayMax: Maybe<number>;

  readonly function: Maybe<FunctionType>;

  readonly industry: Maybe<IndustryType>;

  readonly isLocked: Maybe<boolean>;

  readonly isPrivate: Maybe<boolean>;

  readonly isUnclaimed: Maybe<boolean>;

  readonly key: Maybe<string>;

  readonly labels: Maybe<ReadonlyArray<Maybe<LabelModel>>>;

  readonly languages: Maybe<ReadonlyArray<Maybe<LanguageCode>>>;

  readonly location: Maybe<LocationModel>;

  readonly maxCustomersGraduationIn: Maybe<string>;

  readonly messages: Maybe<ReadonlyArray<Maybe<MessageModel>>>;

  readonly minCustomersGraduationIn: Maybe<string>;

  readonly notificationPreferences: Maybe<ReadonlyArray<Maybe<ResourceEvent>>>;

  readonly ordersCustomerExternalIdMessage: Maybe<string>;

  readonly ordersDescription: Maybe<string>;

  readonly ordersDiscussionDescription: Maybe<string>;

  readonly ordersDiscussionMessages: Maybe<ReadonlyArray<Maybe<string>>>;

  readonly ordersDueIn: Maybe<string>;

  readonly ordersDueOn: Maybe<string>;

  readonly ordersJobsCount: Maybe<number>;

  readonly ordersJobsMaxCount: Maybe<number>;

  readonly ordersJobsWorkitemsByAreas: Maybe<boolean>;

  readonly ordersCustomerAttachmentReviews: Maybe<boolean>;

  readonly ordersJobsWorkitemsRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;

  readonly ordersStages: Maybe<ReadonlyArray<Maybe<OrderStage>>>;

  readonly requirementsDescription: Maybe<string>;

  readonly restartOn: Maybe<string>;

  readonly rules: Maybe<ReadonlyArray<Maybe<RuleModel>>>;

  readonly service: Maybe<ServiceType>;

  readonly skipOrgDescription: Maybe<boolean>;

  readonly subtitle: Maybe<string>;

  readonly subtype: Maybe<ProductType>;

  readonly supplierOriginAddresses: Maybe<string>;

  readonly supplierRecipients: Maybe<string>;

  readonly title: Maybe<string>;

  readonly type: Maybe<ProductType>;

  readonly variantTitle: Maybe<string>;

  readonly variantType: Maybe<ProductVariantType>;
}

export interface LabelModel {
  readonly color: Maybe<string>;

  readonly isArchived: Maybe<boolean>;

  readonly key: Maybe<string>;

  readonly value: Maybe<string>;
}

export interface MessageModel {
  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentModel>>>;

  readonly bccSender: Maybe<boolean>;

  readonly content: Maybe<string>;

  readonly createdOn: Maybe<string>;

  readonly isDraft: Maybe<boolean>;

  readonly key: Maybe<string>;

  readonly name: Maybe<string>;

  readonly recipientEmails: Maybe<string>;

  readonly recipients: Maybe<ReadonlyArray<Maybe<MessageAddressModel>>>;

  readonly requiredData: Maybe<ReadonlyArray<Maybe<string>>>;

  readonly requiredFields: Maybe<ReadonlyArray<Maybe<MessageDataFieldModel>>>;

  readonly sender: Maybe<MessageAddressModel>;

  readonly shortContent: Maybe<string>;

  readonly subject: Maybe<string>;
}

export interface MessageAddressModel {
  readonly accentColor: Maybe<string>;

  readonly email: Maybe<string>;

  readonly name: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly picture: Maybe<string>;

  readonly type: Maybe<MessageAddressType>;
}

export interface MessageDataFieldModel {
  readonly help: Maybe<string>;

  readonly key: Maybe<string>;

  readonly placeholder: Maybe<string>;

  readonly value: Maybe<string>;
}

export interface RuleModel {
  readonly action: Maybe<RuleActionType>;

  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentModel>>>;

  readonly comment: Maybe<CommentModel>;

  readonly eventsTrigger: Maybe<ReadonlyArray<Maybe<ResourceEvent>>>;

  readonly includeDefaultRecipients: Maybe<boolean>;

  readonly isEnabled: Maybe<boolean>;
  readonly isArchived: Maybe<boolean>;

  readonly key: Maybe<string>;

  readonly labels: Maybe<ReadonlyArray<Maybe<LabelModel>>>;

  readonly message: Maybe<MessageModel>;

  readonly messageMatch: Maybe<MessageMatchModel>;

  readonly name: Maybe<string>;

  readonly nextTriggerIn: Maybe<string>;

  readonly nextTriggerOn: Maybe<string>;

  readonly type: Maybe<RuleType>;
}

export interface MessageMatchModel {
  readonly content: Maybe<string>;

  readonly senderEmails: Maybe<string>;
}

export interface OrderModel {
  readonly actions: Maybe<OrderActionsModel>;

  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentModel>>>;

  readonly children: Maybe<ReadonlyArray<Maybe<OrderModel>>>;

  readonly collection: Maybe<ReadonlyArray<Maybe<OrderModel>>>;

  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly commentsSummary: Maybe<ResourcesSummaryModel>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly customer: Maybe<PersonModel>;

  readonly details: Maybe<OrderDetailsModel>;

  readonly events: Maybe<ReadonlyArray<Maybe<EventModel>>>;

  readonly expiresOn: Maybe<string>;

  readonly id: Maybe<string>;

  readonly isExpired: Maybe<boolean>;

  readonly job: Maybe<JobModel>;

  readonly jobs: Maybe<ReadonlyArray<Maybe<JobModel>>>;

  readonly jobsSummary: Maybe<ResourcesSummaryModel>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly op: Maybe<ResourceOperationModel>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly product: Maybe<ProductModel>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly restartOn: Maybe<string>;

  readonly resume: Maybe<AttachmentModel>;

  readonly review: Maybe<ReviewModel>;

  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewModel>>>;

  readonly reviewsSummary: Maybe<ReviewsSummaryModel>;

  readonly snippet: Maybe<string>;

  readonly stage: Maybe<OrderStage>;

  readonly startedOn: Maybe<string>;

  readonly state: Maybe<ResourceState>;

  readonly tasksSummary: Maybe<ResourcesSummaryModel>;

  readonly updatedOn: Maybe<string>;
}

export interface OrderActionsModel {
  readonly accept: Maybe<boolean>;

  readonly add: Maybe<boolean>;

  readonly allow: Maybe<boolean>;

  readonly cancel: Maybe<boolean>;

  readonly change: Maybe<boolean>;

  readonly decline: Maybe<boolean>;

  readonly deliver: Maybe<boolean>;

  readonly event: Maybe<boolean>;

  readonly interview: Maybe<boolean>;

  readonly invite: Maybe<boolean>;

  readonly move: Maybe<boolean>;

  readonly offer: Maybe<boolean>;

  readonly refuse: Maybe<boolean>;

  readonly remove: Maybe<boolean>;

  readonly retract: Maybe<boolean>;

  readonly return: Maybe<boolean>;

  readonly review: Maybe<boolean>;

  readonly screen: Maybe<boolean>;

  readonly shortlist: Maybe<boolean>;

  readonly start: Maybe<boolean>;

  readonly submit: Maybe<boolean>;

  readonly undo: Maybe<boolean>;

  readonly uninterview: Maybe<boolean>;

  readonly unscreen: Maybe<boolean>;

  readonly unshortlist: Maybe<boolean>;

  readonly withdraw: Maybe<boolean>;
}

export interface OrderDetailsModel {
  readonly customerCompensation: Maybe<MoneyModel>;

  readonly customerDescription: Maybe<string>;

  readonly customerExternalId: Maybe<string>;

  readonly customerName: Maybe<string>;

  readonly customerNickname: Maybe<string>;

  readonly endOn: Maybe<string>;

  readonly interviewDuration: Maybe<string>;

  readonly interviewLocation: Maybe<string>;

  readonly interviewOn: Maybe<string>;

  readonly labels: Maybe<ReadonlyArray<Maybe<LabelModel>>>;

  readonly location: Maybe<string>;

  readonly offerExpiresOn: Maybe<string>;

  readonly screeningDuration: Maybe<string>;

  readonly screeningLocation: Maybe<string>;

  readonly screeningOn: Maybe<string>;

  readonly startIn: Maybe<string>;

  readonly startOn: Maybe<string>;

  readonly supplierDescription: Maybe<string>;
}

export interface EventModel {
  readonly createdOn: Maybe<string>;

  readonly id: Maybe<string>;

  readonly message: Maybe<string>;

  readonly objectId: Maybe<string>;

  readonly objectKind: Maybe<ResourceKind>;

  readonly order: Maybe<OrderModel>;

  readonly prevTriggeredOn: Maybe<ReadonlyArray<Maybe<string>>>;

  readonly subject: Maybe<PersonModel>;

  readonly triggeredOn: Maybe<string>;

  readonly type: Maybe<ResourceEvent>;

  readonly updatedOn: Maybe<string>;
}

export interface JobModel {
  readonly actions: Maybe<JobActionsModel>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly expiresOn: Maybe<string>;

  readonly id: Maybe<string>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly order: Maybe<OrderModel>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly review: Maybe<ReviewModel>;

  readonly state: Maybe<ResourceState>;
  readonly pdfUrl: Maybe<string>;

  readonly tasks: Maybe<ReadonlyArray<Maybe<TaskModel>>>;

  readonly updatedOn: Maybe<string>;

  readonly worker: Maybe<PersonModel>;

  readonly workitem: Maybe<WorkItemModel>;
}

export interface JobActionsModel {
  readonly continue: Maybe<boolean>;

  readonly discuss: Maybe<boolean>;

  readonly remove: Maybe<boolean>;

  readonly review: Maybe<boolean>;

  readonly start: Maybe<boolean>;

  readonly submit: Maybe<boolean>;

  readonly undo: Maybe<boolean>;
}

export interface ReviewModel {
  readonly actions: Maybe<ReviewActionsModel>;

  readonly approved: Maybe<boolean>;

  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentModel>>>;

  readonly author: Maybe<PersonModel>;

  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly commentsSummary: Maybe<ResourcesSummaryModel>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly id: Maybe<string>;

  readonly isEdited: Maybe<boolean>;

  readonly isPrivate: Maybe<boolean>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly like: Maybe<boolean>;

  readonly love: Maybe<boolean>;

  readonly message: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<ReviewOption>>>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly rating: Maybe<number>;

  readonly rejected: Maybe<boolean>;

  readonly relatedSummary: Maybe<ReviewsSummaryModel>;

  readonly review: Maybe<ReviewModel>;

  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewModel>>>;

  readonly reviewsSummary: Maybe<ReviewsSummaryModel>;

  readonly state: Maybe<ResourceState>;

  readonly type: Maybe<ReviewType>;

  readonly updatedOn: Maybe<string>;

  readonly visibilityId: Maybe<string>;
}

export interface ReviewActionsModel {
  readonly change: Maybe<boolean>;

  readonly remove: Maybe<boolean>;

  readonly review: Maybe<boolean>;

  readonly undo: Maybe<boolean>;
}

export interface ReviewsSummaryModel {
  readonly didLike: Maybe<boolean>;

  readonly didLove: Maybe<boolean>;

  readonly likeCount: Maybe<number>;

  readonly loveCount: Maybe<number>;

  readonly messageCount: Maybe<number>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;
  readonly authors: Maybe<ReadonlyArray<Maybe<PersonModel>>>;
}

export interface TaskModel {
  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentModel>>>;

  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly commentsSummary: Maybe<ResourcesSummaryModel>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly details: Maybe<ITaskDetailsModel>;

  readonly id: Maybe<string>;

  readonly job: Maybe<JobModel>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly review: Maybe<ReviewModel>;

  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewModel>>>;

  readonly reviewsSummary: Maybe<ReviewsSummaryModel>;

  readonly sequence: Maybe<number>;

  readonly state: Maybe<ResourceState>;

  readonly type: Maybe<TaskType>;

  readonly updatedOn: Maybe<string>;

  readonly worker: Maybe<PersonModel>;

  readonly workitem: Maybe<WorkItemModel>;
}

export interface WorkItemModel {
  readonly actions: Maybe<WorkItemActionsModel>;

  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly commentsSummary: Maybe<ResourcesSummaryModel>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly details: Maybe<IWorkItemDetailsModel>;

  readonly id: Maybe<string>;

  readonly jobs: Maybe<ReadonlyArray<Maybe<JobModel>>>;

  readonly jobsSummary: Maybe<ResourcesSummaryModel>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly organization: Maybe<OrganizationModel>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly review: Maybe<ReviewModel>;

  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewModel>>>;

  readonly reviewsSummary: Maybe<ReviewsSummaryModel>;

  readonly shares: Maybe<ReadonlyArray<Maybe<ShareModel>>>;

  readonly state: Maybe<ResourceState>;

  readonly tasks: Maybe<ReadonlyArray<Maybe<TaskModel>>>;

  readonly updatedOn: Maybe<string>;
}

export interface WorkItemActionsModel {
  readonly change: Maybe<boolean>;

  readonly complete: Maybe<boolean>;

  readonly remove: Maybe<boolean>;

  readonly share: Maybe<boolean>;

  readonly start: Maybe<boolean>;

  readonly stop: Maybe<boolean>;

  readonly undo: Maybe<boolean>;
}

export interface ShareModel {
  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly events: Maybe<ReadonlyArray<Maybe<ResourceEventStateModel>>>;

  readonly id: Maybe<string>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly objectProduct: Maybe<ProductModel>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly role: Maybe<ResourceRole>;

  readonly state: Maybe<ResourceState>;

  readonly subject: Maybe<PersonModel>;

  readonly subjectProduct: Maybe<ProductModel>;

  readonly updatedOn: Maybe<string>;
}

export interface ResourceEventStateModel {
  readonly event: Maybe<ResourceEvent>;

  readonly notifiedOn: Maybe<string>;
}

export interface ResourceOperationModel {
  readonly messageSent: Maybe<boolean>;
  readonly objectId: Maybe<string>;
  readonly changes: Maybe<ReadonlyArray<Maybe<ChangeOperationModel>>>;
}

export interface ChangeOperationModel {
  readonly type: Maybe<ChangeOperationType>;
  readonly message: Maybe<string>;
  readonly sourceText: Maybe<string>;
  readonly targetText: Maybe<string>;
}

export interface OrdersSummaryModel {
  readonly acceptedCount: Maybe<number>;

  readonly completedCount: Maybe<number>;

  readonly count: Maybe<number>;

  readonly declinedCount: Maybe<number>;

  readonly deliveredCount: Maybe<number>;

  readonly inprogressCount: Maybe<number>;

  readonly interviewScheduledCount: Maybe<number>;

  readonly negativeCount: Maybe<number>;

  readonly neutralCount: Maybe<number>;

  readonly offeredCount: Maybe<number>;

  readonly offerExpiredCount: Maybe<number>;

  readonly positiveCount: Maybe<number>;

  readonly proposedCount: Maybe<number>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly ratingHistogram: Maybe<ReadonlyArray<Maybe<number>>>;

  readonly removedCount: Maybe<number>;

  readonly requestedCount: Maybe<number>;

  readonly resolvedCount: Maybe<number>;

  readonly resolvedPercent: Maybe<number>;

  readonly returnedCount: Maybe<number>;

  readonly screeningScheduledCount: Maybe<number>;

  readonly shortlistedCount: Maybe<number>;

  readonly startedCount: Maybe<number>;

  readonly stoppedCount: Maybe<number>;

  readonly submittedCount: Maybe<number>;
}

export interface AccountSubscriptionModel {
  readonly actions: Maybe<AccountSubscriptionActionsModel>;

  readonly balance: Maybe<ReadonlyArray<Maybe<AccountBalanceModel>>>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly customer: Maybe<PersonModel>;

  readonly expiresOn: Maybe<string>;

  readonly id: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly organization: Maybe<OrganizationModel>;

  readonly plan: Maybe<AccountPlanModel>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly renewsOn: Maybe<string>;

  readonly startsOn: Maybe<string>;

  readonly state: Maybe<ResourceState>;

  readonly title: Maybe<string>;

  readonly transactions: Maybe<ReadonlyArray<Maybe<AccountTransactionModel>>>;

  readonly shares: Maybe<ReadonlyArray<Maybe<ShareModel>>>;

  readonly transactionsSummary: Maybe<
    ReadonlyArray<Maybe<AccountTransactionsSummaryModel>>
  >;

  readonly updatedOn: Maybe<string>;
}

export interface AccountSubscriptionActionsModel {
  readonly change: Maybe<boolean>;

  readonly remove: Maybe<boolean>;

  readonly share: Maybe<boolean>;

  readonly upgrade: Maybe<boolean>;
}

export interface AccountBalanceModel {
  readonly amount: Maybe<number>;

  readonly currency: Maybe<CurrencyCode>;

  readonly key: Maybe<string>;
}

export interface AccountPlanModel {
  readonly costs: Maybe<ReadonlyArray<Maybe<AccountSkuModel>>>;

  readonly currency: Maybe<CurrencyCode>;

  readonly description: Maybe<string>;

  readonly discountAmount: Maybe<number>;

  readonly expiresIn: Maybe<string>;

  readonly id: Maybe<string>;

  readonly next: Maybe<AccountPlanModel>;

  readonly nextDescription: Maybe<string>;

  readonly prices: Maybe<ReadonlyArray<Maybe<AccountSkuModel>>>;

  readonly renewsIn: Maybe<string>;

  readonly scope: Maybe<string>;

  readonly subscriptionPrice: Maybe<AccountSkuModel>;

  readonly title: Maybe<string>;
}

export interface AccountSkuModel {
  readonly description: Maybe<string>;

  readonly id: Maybe<string>;

  readonly perQuantity: Maybe<number>;

  readonly rate: Maybe<number>;

  readonly taxName: Maybe<string>;

  readonly taxRate: Maybe<number>;

  readonly title: Maybe<string>;

  readonly unit: Maybe<string>;
}

export interface AccountTransactionModel {
  readonly amount: Maybe<number>;

  readonly createdOn: Maybe<string>;

  readonly currency: Maybe<CurrencyCode>;

  readonly cursor: Maybe<string>;

  readonly description: Maybe<string>;

  readonly displayRate: Maybe<number>;

  readonly displayRatePerQuantity: Maybe<number>;

  readonly displayTaxName: Maybe<string>;

  readonly displayTaxRate: Maybe<number>;

  readonly id: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly quantity: Maybe<number>;

  readonly razorpayId: Maybe<string>;

  readonly references: Maybe<AccountExternalReferenceModel>;

  readonly sku: Maybe<AccountSkuModel>;

  readonly state: Maybe<ResourceState>;

  readonly subscription: Maybe<AccountSubscriptionModel>;

  readonly tax: Maybe<number>;

  readonly title: Maybe<string>;

  readonly type: Maybe<AccountTransactionType>;

  readonly updatedOn: Maybe<string>;

  readonly wallet: Maybe<AccountSubscriptionModel>;
}

export interface AccountExternalReferenceModel {
  readonly quickbooksCustomerId: Maybe<string>;

  readonly quickbooksVendorId: Maybe<string>;
}

export interface AccountTransactionsSummaryModel {
  readonly amount: Maybe<number>;

  readonly blendedRate: Maybe<number>;

  readonly blendedTaxRate: Maybe<number>;

  readonly currency: Maybe<CurrencyCode>;

  readonly description: Maybe<string>;

  readonly quantity: Maybe<number>;

  readonly sku: Maybe<AccountSkuModel>;

  readonly tax: Maybe<number>;

  readonly transactionsSummary: Maybe<
    ReadonlyArray<Maybe<AccountTransactionsSummaryModel>>
  >;
}

export interface ExternalAccountModel {
  readonly externalEmail: Maybe<string>;

  readonly isPrimary: Maybe<boolean>;

  readonly isVerified: Maybe<boolean>;

  readonly key: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<ExternalAccountOption>>>;

  readonly type: Maybe<ExternalAccountType>;

  readonly uri: Maybe<string>;

  readonly value: Maybe<string>;

  readonly verificationError: Maybe<ErrorType>;

  readonly verificationHelp: Maybe<string>;

  readonly verificationRequestedOn: Maybe<string>;
}

export interface IncomePreferenceModel {
  readonly key: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<IncomePreferenceOption>>>;

  readonly updatedOn: Maybe<string>;

  readonly value: Maybe<MoneyModel>;
}

export interface LanguagePreferenceModel {
  readonly key: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<LanguagePreferenceOption>>>;

  readonly updatedOn: Maybe<string>;

  readonly value: Maybe<LanguageCode>;
}

export interface LocationPreferenceModel {
  readonly key: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<LocationPreferenceOption>>>;

  readonly updatedOn: Maybe<string>;

  readonly value: Maybe<LocationModel>;
}

export interface TopicPreferenceModel {
  readonly key: Maybe<string>;

  readonly options: Maybe<ReadonlyArray<Maybe<TopicPreferenceOption>>>;

  readonly updatedOn: Maybe<string>;

  readonly value: Maybe<TopicModel>;
}

export interface TopicModel {
  readonly area: Maybe<AreaType>;

  readonly function: Maybe<FunctionType>;

  readonly industry: Maybe<IndustryType>;

  readonly organization: Maybe<OrganizationModel>;

  readonly service: Maybe<ServiceType>;
}

export interface MeModel {
  readonly accentColor: Maybe<string>;

  readonly access_token: Maybe<string>;

  readonly affiliation: Maybe<MembershipModel>;

  readonly alternates: Maybe<ReadonlyArray<Maybe<PersonModel>>>;

  readonly attachments: Maybe<ReadonlyArray<Maybe<AttachmentModel>>>;

  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;

  readonly contactInformation: Maybe<
    ReadonlyArray<Maybe<ExternalAccountModel>>
  >;

  readonly contactsConfirmedOn: Maybe<string>;

  readonly createdOn: Maybe<string>;

  readonly cursor: Maybe<string>;

  readonly eduAffiliation: Maybe<MembershipModel>;

  readonly email: Maybe<string>;

  readonly gender: Maybe<GenderType>;

  readonly graduationOn: Maybe<string>;

  readonly graduationOrganization: Maybe<OrganizationModel>;

  readonly graduationOrganizationName: Maybe<string>;

  readonly id: Maybe<string>;

  readonly incomePreference: Maybe<IncomePreferenceModel>;

  readonly jobs: Maybe<ReadonlyArray<Maybe<JobModel>>>;

  readonly key: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly languagePreferences: Maybe<
    ReadonlyArray<Maybe<LanguagePreferenceModel>>
  >;

  readonly locationPreferences: Maybe<
    ReadonlyArray<Maybe<LocationPreferenceModel>>
  >;

  readonly memberships: Maybe<ReadonlyArray<Maybe<MembershipModel>>>;

  readonly name: Maybe<string>;

  readonly nickname: Maybe<string>;

  readonly orders: Maybe<ReadonlyArray<Maybe<OrderModel>>>;

  readonly organization: Maybe<OrganizationModel>;

  readonly organizationName: Maybe<string>;

  readonly organizations: Maybe<ReadonlyArray<Maybe<OrganizationModel>>>;

  readonly phone: Maybe<string>;

  readonly photo: Maybe<AttachmentModel>;

  readonly picture: Maybe<string>;

  readonly policy: Maybe<ResourcePolicyModel>;

  readonly products: Maybe<ReadonlyArray<Maybe<ProductModel>>>;

  readonly ratingAverage: Maybe<number>;

  readonly ratingCount: Maybe<number>;

  readonly resume: Maybe<AttachmentModel>;

  readonly state: Maybe<ResourceState>;

  readonly subscriptions: Maybe<ReadonlyArray<Maybe<AccountSubscriptionModel>>>;

  readonly termsAgreedOn: Maybe<string>;

  readonly title: Maybe<string>;

  readonly topicPreferences: Maybe<ReadonlyArray<Maybe<TopicPreferenceModel>>>;

  readonly updatedOn: Maybe<string>;

  readonly wallet: Maybe<AccountSubscriptionModel>;

  readonly workitems: Maybe<ReadonlyArray<Maybe<WorkItemModel>>>;
}

export interface ReferenceDataModel {
  readonly actions: Maybe<ReferenceDataActions>;

  readonly cursor: Maybe<string>;

  readonly description: Maybe<string>;

  readonly id: Maybe<string>;

  readonly kind: Maybe<ResourceKind>;

  readonly organization: Maybe<OrganizationModel>;

  readonly person: Maybe<PersonModel>;

  readonly product: Maybe<ProductModel>;

  readonly rank: Maybe<number>;

  readonly title: Maybe<string>;

  readonly type: Maybe<ReferenceDataType>;

  readonly value: Maybe<string>;
}

export interface ReferenceDataActions {
  readonly change: Maybe<boolean>;
}

export interface SettingsModel {
  readonly plans: Maybe<ReadonlyArray<Maybe<AccountPlanModel>>>;

  readonly razorpayClientId: Maybe<string>;
}

export interface Mutation {
  readonly accountTransaction: Maybe<AccountTransactionOperations>;

  readonly addEvents: Maybe<EventsOperations>;

  readonly addOrganization: Maybe<OrganizationOperations>;

  readonly addProduct: Maybe<ProductOperations>;

  readonly addReferenceData: Maybe<ReferenceDataOperations>;

  readonly addWorkitem: Maybe<WorkItemOperations>;

  readonly administration: Maybe<AdministrationOperations>;

  readonly attachment: Maybe<AttachmentOperations>;

  readonly comment: Maybe<CommentOperations>;

  readonly content: Maybe<ContentOperations>;

  readonly displayProduct: Maybe<ProductOperations>;

  readonly events: Maybe<EventsOperations>;

  readonly job: Maybe<JobOperations>;

  readonly me: Maybe<MeOperations>;

  readonly membership: Maybe<MembershipOperations>;

  readonly myOrganization: Maybe<OrganizationOperations>;

  readonly order: Maybe<OrderOperations>;

  readonly organization: Maybe<OrganizationOperations>;

  readonly person: Maybe<PersonOperations>;

  readonly product: Maybe<ProductOperations>;

  readonly products: Maybe<ProductsOperations>;

  readonly referenceData: Maybe<ReferenceDataOperations>;

  readonly review: Maybe<ReviewOperations>;

  readonly reviewAttachment: Maybe<AttachmentOperations>;

  readonly reviewComment: Maybe<CommentOperations>;

  readonly reviewJob: Maybe<JobOperations>;

  readonly reviewOrder: Maybe<OrderOperations>;

  readonly reviewReview: Maybe<ReviewOperations>;

  readonly reviewTask: Maybe<TaskOperations>;

  readonly reviewWorkitem: Maybe<WorkItemOperations>;

  readonly share: Maybe<SharesOperations>;

  readonly subscription: Maybe<AccountSubscriptionOperations>;

  readonly task: Maybe<TaskOperations>;

  readonly workitem: Maybe<WorkItemOperations>;

  readonly workitems: Maybe<WorkItemsOperations>;
}

export interface AccountTransactionOperations {
  readonly change: Maybe<boolean>;

  readonly commit: Maybe<AccountTransactionModel>;

  readonly resolve: Maybe<boolean>;
}

export interface EventsOperations {
  readonly commit: Maybe<ReadonlyArray<Maybe<EventModel>>>;

  readonly remove: Maybe<number>;

  readonly track: Maybe<number>;
}

export interface OrganizationOperations {
  readonly addProduct: Maybe<ProductOperations>;

  readonly addSubscription: Maybe<AccountSubscriptionOperations>;

  readonly change: Maybe<boolean>;

  readonly commit: Maybe<OrganizationModel>;

  readonly merge: Maybe<boolean>;

  readonly switch: Maybe<AccessTokenModel>;
}

export interface ProductOperations {
  readonly addOrder: Maybe<OrderOperations>;

  readonly addOrders: Maybe<OrdersOperations>;

  readonly addShares: Maybe<SharesOperations>;

  readonly commit: Maybe<ProductModel>;

  readonly complete: Maybe<boolean>;

  readonly copy: Maybe<ProductModel>;

  readonly details: Maybe<ProductDetailsOperations>;

  readonly link: Maybe<boolean>;

  readonly orders: Maybe<OrdersOperations>;

  readonly populateOrders: Maybe<OrdersOperations>;

  readonly remove: Maybe<boolean>;

  readonly shares: Maybe<DeprecatedSharesOperations>;

  readonly start: Maybe<boolean>;

  readonly stop: Maybe<boolean>;

  readonly undo: Maybe<boolean>;

  readonly unlink: Maybe<boolean>;
}

export interface OrderOperations {
  readonly accept: Maybe<boolean>;

  readonly addAttachment: Maybe<AttachmentOperations>;

  readonly addComment: Maybe<CommentOperations>;

  readonly addJob: Maybe<JobOperations>;

  readonly addReview: Maybe<ReviewOperations>;

  readonly apply: Maybe<boolean>;

  readonly cancel: Maybe<boolean>;

  readonly commit: Maybe<OrderModel>;

  readonly decline: Maybe<boolean>;

  readonly details: Maybe<OrderDetailsOperations>;

  readonly event: Maybe<EventOperations>;

  readonly merge: Maybe<OrderModel>;

  readonly move: Maybe<boolean>;

  readonly remove: Maybe<boolean>;

  readonly review: Maybe<ReviewOperations>;

  readonly start: Maybe<boolean>;

  readonly submit: Maybe<boolean>;

  readonly undo: Maybe<boolean>;
}

export interface AttachmentOperations {
  readonly addComment: Maybe<CommentOperations>;

  readonly change: Maybe<boolean>;

  readonly commit: Maybe<AttachmentModel>;

  readonly remove: Maybe<boolean>;

  readonly review: Maybe<ReviewOperations>;
}

export interface CommentOperations {
  readonly addComment: Maybe<CommentOperations>;

  readonly change: Maybe<boolean>;

  readonly commit: Maybe<CommentModel>;

  readonly remove: Maybe<boolean>;

  readonly review: Maybe<ReviewOperations>;
}

export interface ReviewOperations {
  readonly addAttachment: Maybe<AttachmentOperations>;

  readonly addComment: Maybe<CommentOperations>;

  readonly change: Maybe<boolean>;

  readonly commit: Maybe<ReviewModel>;

  readonly remove: Maybe<boolean>;

  readonly resolve: Maybe<boolean>;

  readonly review: Maybe<ReviewOperations>;

  readonly toggleOption: Maybe<boolean>;
}

export interface JobOperations {
  readonly addTask: Maybe<TaskOperations>;

  readonly commit: Maybe<JobModel>;

  readonly continue: Maybe<boolean>;

  readonly remove: Maybe<boolean>;

  readonly review: Maybe<ReviewOperations>;

  readonly start: Maybe<boolean>;

  readonly submit: Maybe<boolean>;

  readonly undo: Maybe<boolean>;
}

export interface TaskOperations {
  readonly addAttachment: Maybe<AttachmentOperations>;

  readonly addComment: Maybe<CommentOperations>;

  readonly commit: Maybe<TaskModel>;

  readonly details: Maybe<ITaskDetailsOperations>;

  readonly remove: Maybe<boolean>;

  readonly review: Maybe<ReviewOperations>;
}

export interface OrderDetailsOperations {
  readonly change: Maybe<boolean>;
}

export interface EventOperations {
  readonly commit: Maybe<EventModel>;

  readonly track: Maybe<boolean>;
}

export interface OrdersOperations {
  readonly accept: Maybe<number>;

  readonly allow: Maybe<number>;

  readonly cancel: Maybe<number>;

  readonly change: Maybe<number>;

  readonly commit: Maybe<ReadonlyArray<Maybe<OrderModel>>>;

  readonly copy: Maybe<ReadonlyArray<Maybe<OrderModel>>>;

  readonly count: Maybe<number>;

  readonly decline: Maybe<number>;

  readonly deliver: Maybe<number>;

  readonly details: Maybe<OrdersDetailsOperations>;

  readonly event: Maybe<number>;

  readonly events: Maybe<EventsOperations>;

  readonly export: Maybe<AttachmentModel>;

  readonly interview: Maybe<number>;

  readonly jobs: Maybe<JobsOperations>;

  readonly merge: Maybe<OrderModel>;

  readonly move: Maybe<ReadonlyArray<Maybe<OrderModel>>>;

  readonly offer: Maybe<number>;

  readonly preview: Maybe<ReadonlyArray<Maybe<MessageModel>>>;

  readonly refuse: Maybe<number>;

  readonly remove: Maybe<number>;

  readonly return: Maybe<number>;

  readonly screen: Maybe<number>;

  readonly shortlist: Maybe<number>;

  readonly undo: Maybe<number>;

  readonly uninterview: Maybe<number>;

  readonly unscreen: Maybe<number>;

  readonly unshortlist: Maybe<number>;

  readonly withdraw: Maybe<number>;
}

export interface OrdersDetailsOperations {
  readonly addLabel: Maybe<number>;

  readonly addLabels: Maybe<number>;

  readonly change: Maybe<number>;

  readonly removeLabel: Maybe<number>;
}

export interface JobsOperations {
  readonly prioritize: Maybe<ReadonlyArray<Maybe<JobModel>>>;
}

export interface SharesOperations {
  readonly changeSubject: Maybe<number>;

  readonly commit: Maybe<ReadonlyArray<Maybe<ShareModel>>>;

  readonly complete: Maybe<number>;

  readonly sendMessages: Maybe<number>;

  readonly upgrade: Maybe<number>;
}

export interface ProductDetailsOperations {
  readonly change: Maybe<boolean>;
}

export interface DeprecatedSharesOperations {
  readonly update: Maybe<ReadonlyArray<Maybe<ShareModel>>>;
}

export interface AccountSubscriptionOperations {
  readonly addDeposit: Maybe<AccountTransactionOperations>;

  readonly change: Maybe<boolean>;

  readonly commit: Maybe<AccountSubscriptionModel>;

  readonly complete: Maybe<boolean>;

  readonly remove: Maybe<boolean>;
}

export interface AccessTokenModel {
  readonly access_token: Maybe<string>;

  readonly expires_in: Maybe<string>;

  readonly token_type: Maybe<string>;
}

export interface ReferenceDataOperations {
  readonly change: Maybe<boolean>;

  readonly commit: Maybe<ReferenceDataModel>;
}

export interface WorkItemOperations {
  readonly addComment: Maybe<CommentOperations>;

  readonly addShares: Maybe<SharesOperations>;

  readonly commit: Maybe<WorkItemModel>;

  readonly details: Maybe<IWorkItemDetailsOperations>;

  readonly remove: Maybe<boolean>;

  readonly review: Maybe<ReviewOperations>;

  readonly shares: Maybe<DeprecatedSharesOperations>;

  readonly start: Maybe<boolean>;

  readonly stop: Maybe<boolean>;

  readonly undo: Maybe<boolean>;
}

export interface AdministrationOperations {
  readonly import: Maybe<number>;

  readonly indexAttachments: Maybe<number>;

  readonly quickbooks: Maybe<QuickbooksOperations>;

  readonly reports: Maybe<ReportsOperations>;

  readonly start: Maybe<ResourcesSummaryModel>;
}

export interface QuickbooksOperations {
  readonly balances: Maybe<QuickbooksBalancesOperations>;

  readonly entities: Maybe<QuickbooksEntitiesOperations>;

  readonly transactions: Maybe<QuickbooksTransactionsOperations>;

  readonly vendor: Maybe<QuickbooksVendorOperations>;
}

export interface QuickbooksBalancesOperations {
  readonly export: Maybe<ReadonlyArray<Maybe<AccountTransactionModel>>>;
}

export interface QuickbooksEntitiesOperations {
  readonly match: Maybe<number>;
}

export interface QuickbooksTransactionsOperations {
  readonly export: Maybe<AttachmentModel>;
}

export interface QuickbooksVendorOperations {
  readonly balance: Maybe<AccountTransactionModel>;

  readonly pay: Maybe<AccountTransactionModel>;
}

export interface ReportsOperations {
  readonly export: Maybe<AttachmentModel>;
}

export interface ContentOperations {
  readonly parseTabular: Maybe<ReadonlyArray<ReadonlyArray<Maybe<string>>>>;
}

export interface MeOperations {
  readonly addAttachment: Maybe<AttachmentOperations>;

  readonly addComment: Maybe<CommentOperations>;

  readonly addMembership: Maybe<MembershipOperations>;

  readonly addMessage: Maybe<MessageOperations>;

  readonly addSubscription: Maybe<AccountSubscriptionOperations>;

  readonly change: Maybe<boolean>;

  readonly changeContactInfo: Maybe<boolean>;

  readonly changeLanguagePreference: Maybe<boolean>;

  readonly changeLocationPreference: Maybe<boolean>;

  readonly changeTopicPreference: Maybe<boolean>;

  readonly commit: Maybe<MeModel>;

  readonly merge: Maybe<boolean>;

  readonly photo: Maybe<AttachmentOperations>;

  readonly removeContactInfo: Maybe<boolean>;

  readonly removeLanguagePreference: Maybe<boolean>;

  readonly removeLocationPreference: Maybe<boolean>;

  readonly removeTopicPreference: Maybe<boolean>;

  readonly resume: Maybe<AttachmentOperations>;

  readonly review: Maybe<ReviewOperations>;

  readonly wallet: Maybe<AccountSubscriptionOperations>;
}

export interface MembershipOperations {
  readonly change: Maybe<boolean>;

  readonly commit: Maybe<MembershipModel>;

  readonly remove: Maybe<boolean>;
}

export interface MessageOperations {
  readonly change: Maybe<boolean>;

  readonly preview: Maybe<ReadonlyArray<Maybe<MessageModel>>>;

  readonly send: Maybe<boolean>;
}

export interface PersonOperations {
  readonly impersonate: Maybe<AccessTokenModel>;
}

export interface ProductsOperations {
  readonly move: Maybe<ReadonlyArray<Maybe<ProductModel>>>;
}

export interface WorkItemsOperations {
  readonly move: Maybe<ReadonlyArray<Maybe<WorkItemModel>>>;
}

export interface ClaimsModel {
  readonly exp: Maybe<number>;

  readonly name: Maybe<string>;

  readonly nickname: Maybe<string>;

  readonly orgid: Maybe<string>;

  readonly picture: Maybe<string>;

  readonly scope: Maybe<string>;

  readonly sub: Maybe<string>;
}

export interface LegalResearchBackgroundModel extends ITaskDetailsModel {
  readonly excerpt: Maybe<string>;

  readonly rev: Maybe<number>;

  readonly searchQuery: Maybe<string>;

  readonly url: Maybe<string>;
}

export interface LegalResearchBackgroundOperations
  extends ITaskDetailsOperations {
  readonly change: Maybe<boolean>;
}

export interface LegalResearchConclusionModel extends ITaskDetailsModel {
  readonly action: Maybe<string>;

  readonly clarification: Maybe<string>;

  readonly description: Maybe<string>;

  readonly opinion: Maybe<string>;

  readonly rev: Maybe<number>;
}

export interface LegalResearchConclusionOperations
  extends ITaskDetailsOperations {
  readonly change: Maybe<boolean>;
}

export interface LegalResearchModel extends IWorkItemDetailsModel {
  readonly area: Maybe<AreaType>;

  readonly description: Maybe<string>;

  readonly dueOn: Maybe<string>;

  readonly function: Maybe<FunctionType>;

  readonly industry: Maybe<IndustryType>;

  readonly jobsCount: Maybe<number>;

  readonly jobsDueOn: Maybe<string>;

  readonly level: Maybe<number>;

  readonly rev: Maybe<number>;

  readonly title: Maybe<string>;

  readonly type: Maybe<WorkItemType>;
}

export interface LegalResearchOperations extends IWorkItemDetailsOperations {
  readonly change: Maybe<boolean>;
}

export interface LegalResearchPrecedentModel extends ITaskDetailsModel {
  readonly citation: Maybe<string>;

  readonly court: Maybe<string>;

  readonly excerpt: Maybe<string>;

  readonly facts: Maybe<string>;

  readonly rev: Maybe<number>;

  readonly searchQuery: Maybe<string>;

  readonly status: Maybe<string>;

  readonly strength: Maybe<string>;

  readonly title: Maybe<string>;

  readonly url: Maybe<string>;

  readonly year: Maybe<string>;
}

export interface LegalResearchPrecedentOperations
  extends ITaskDetailsOperations {
  readonly change: Maybe<boolean>;
}

export interface LegalResearchRephrasingModel extends ITaskDetailsModel {
  readonly area: Maybe<string>;

  readonly jurisdiction: Maybe<string>;

  readonly query: Maybe<string>;

  readonly rev: Maybe<number>;
}

export interface LegalResearchRephrasingOperations
  extends ITaskDetailsOperations {
  readonly change: Maybe<boolean>;
}

export interface LegalResearchStatuteModel extends ITaskDetailsModel {
  readonly excerpt: Maybe<string>;

  readonly rev: Maybe<number>;

  readonly searchQuery: Maybe<string>;

  readonly section: Maybe<string>;

  readonly title: Maybe<string>;

  readonly url: Maybe<string>;

  readonly year: Maybe<string>;
}

export interface LegalResearchStatuteOperations extends ITaskDetailsOperations {
  readonly change: Maybe<boolean>;
}

// ====================================================
// Arguments
// ====================================================

export interface AttachmentQueryArgs {
  /** Id of the task */
  id: Maybe<string>;
}
export interface CommentQueryArgs {
  /** Id of the comment */
  id: Maybe<string>;
}
export interface DisplayOrganizationsQueryArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface DisplayProductQueryArgs {
  /** Id of the product */
  id: Maybe<string>;
}
export interface DisplayProductsQueryArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Types of products */
  types: Maybe<ReadonlyArray<Maybe<ProductType>>>;
  /** Industry that should be included */
  industry: Maybe<IndustryType>;
  /** Functions that should be included */
  functions: Maybe<ReadonlyArray<Maybe<FunctionType>>>;
  /** Services that should be included */
  services: Maybe<ReadonlyArray<Maybe<ServiceType>>>;
  /** Areas that should be included */
  areas: Maybe<ReadonlyArray<Maybe<AreaType>>>;
  /** Cities that should be in product locations */
  cities: Maybe<ReadonlyArray<Maybe<CityCode>>>;
  /** Interval filter */
  deliveryInterval: Maybe<string>;
}
export interface FileQueryArgs {
  /** Namespace of the file */
  namespace: Maybe<string>;
  /** Name of the file */
  name: Maybe<string>;
}
export interface JobQueryArgs {
  /** Id of the job */
  id: Maybe<string>;
}
export interface OrderQueryArgs {
  /** Id of the order */
  id: Maybe<string>;
}
export interface OrganizationQueryArgs {
  /** Id of the organization */
  id: Maybe<string>;
}
export interface PersonQueryArgs {
  /** Id of the person */
  id: Maybe<string>;
}
export interface PersonsQueryArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Minimum average rating to select */
  ratingAverage: Maybe<number>;
  /** Person area interests to use for filtering */
  areas: Maybe<ReadonlyArray<Maybe<AreaType>>>;
  /** Cities that should be in preferred locations */
  cities: Maybe<ReadonlyArray<Maybe<CityCode>>>;
  /** Person graduation organizations to use for filtering */
  graduationOrgs: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Person languages to use for filtering */
  languages: Maybe<ReadonlyArray<Maybe<LanguageCode>>>;
  /** Person service interests to use for filtering */
  services: Maybe<ReadonlyArray<Maybe<ServiceType>>>;
  /** Order delivery dates to use for filtering */
  deliveryInterval: Maybe<string>;
  /** graduationOn should be between these dates */
  graduationInterval: Maybe<string>;
  /** Minimum overlap duration to select */
  minDuration: Maybe<string>;
}
export interface ProductQueryArgs {
  /** Id of the product */
  id: Maybe<string>;
}
export interface ReferenceQueryArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Key of reference data */
  key: Maybe<string>;
  /** Related Ids to include */
  relIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Type of reference data -- DEPRECATED. Use types. */
  type: Maybe<ReferenceDataType>;
  /** Types of reference data */
  types: Maybe<ReadonlyArray<Maybe<ReferenceDataType>>>;
  /** Reference data values to exclude */
  exclude: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewQueryArgs {
  /** Id of the task */
  id: Maybe<string>;
}
export interface ReviewJobQueryArgs {
  /** Id of the job */
  id: Maybe<string>;
}
export interface ReviewJobsQueryArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewsQueryArgs {
  /** Types of reviews */
  types: Maybe<ReadonlyArray<Maybe<ReviewType>>>;
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ShareQueryArgs {
  /** Id of the share */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface SubscriptionQueryArgs {
  /** Id of the subscription */
  id: Maybe<string>;
}
export interface TaskQueryArgs {
  /** Id of the task */
  id: Maybe<string>;
}
export interface WorkitemQueryArgs {
  /** Id of the product */
  id: Maybe<string>;
}
export interface CommentsAttachmentModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface LinesAttachmentModelArgs {
  /** Character set to use */
  charset: Maybe<string>;
  /** Character set to use */
  encoding: Maybe<string>;
}
export interface ReviewAttachmentModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewsAttachmentModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Whether to return only reviews with messages */
  hasMessage: Maybe<boolean>;
}
export interface SnippetAttachmentModelArgs {
  /** Search query filter */
  query: Maybe<string>;
  /** Operators to include */
  includeOperators: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface TextAttachmentModelArgs {
  /** Character set to use */
  charset: Maybe<string>;
  /** Character set to use */
  encoding: Maybe<string>;
}
export interface CommentsCommentModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface AttachmentsPersonModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Types of attachments to return */
  types: Maybe<ReadonlyArray<Maybe<AttachmentType>>>;
}
export interface CommentsPersonModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface JobPersonModelArgs {
  /** Id of the job */
  id: Maybe<string>;
}
export interface JobsPersonModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface MembershipsPersonModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface OrdersPersonModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ProductsPersonModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewsPersonModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface SubscriptionsPersonModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface TasksSummaryPersonModelArgs {
  /** Types of tasks to summarize */
  types: Maybe<ReadonlyArray<Maybe<TaskType>>>;
  /** Types of tasks to summarize */
  _types: Maybe<ReadonlyArray<Maybe<TaskType>>>;
}
export interface WorkitemsPersonModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface CommentsOrganizationModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface MembersOrganizationModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ProductsOrganizationModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface SubscriptionsOrganizationModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface WorkitemsOrganizationModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface CollectionProductModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface CommentsProductModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface MyOrdersProductModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Stages to use for filtering */
  stages: Maybe<ReadonlyArray<Maybe<OrderStage>>>;
}
export interface OrderProductModelArgs {
  /** Id of the order */
  id: Maybe<string>;
}
export interface OrdersProductModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Stages to use for filtering */
  stages: Maybe<ReadonlyArray<Maybe<OrderStage>>>;
  /** Minimum average rating to select */
  ratingAverage: Maybe<number>;
  /** Minimum average rating to select */
  eduRatingAverage: Maybe<number>;
  /** Maximum compensation annual amount to select */
  compensationAnnualAmountMax: Maybe<number>;
  /** Minimum compensation annual amount to select */
  compensationAnnualAmountMin: Maybe<number>;
  /** Minimum overlap duration to select */
  minDuration: Maybe<string>;
  /** Whether to return only supplier created resources */
  isSupplierCreated: Maybe<boolean>;
  /** Customer graduation organizations to use for filtering */
  graduationOrgs: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Sources of suggested orders to select */
  sources: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Customer graduation dates to use for filtering */
  graduationInterval: Maybe<string>;
  /** Order delivery dates to use for filtering */
  deliveryInterval: Maybe<string>;
  /** Customer cities to use for filtering */
  cities: Maybe<ReadonlyArray<Maybe<CityCode>>>;
  /** Customer service interests to use for filtering */
  services: Maybe<ReadonlyArray<Maybe<ServiceType>>>;
  /** Customer area interests to use for filtering */
  areas: Maybe<ReadonlyArray<Maybe<AreaType>>>;
  /** Customer languages to use for filtering */
  languages: Maybe<ReadonlyArray<Maybe<LanguageCode>>>;
  /** Ids to use for filtering */
  relatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewProductModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewsProductModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Whether to return only reviews with messages */
  hasMessage: Maybe<boolean>;
}
export interface SharesProductModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface AttachmentsOrderModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Types of attachments to return */
  types: Maybe<ReadonlyArray<Maybe<AttachmentType>>>;
}
export interface ChildrenOrderModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Stages to use for filtering */
  stages: Maybe<ReadonlyArray<Maybe<OrderStage>>>;
}
export interface CollectionOrderModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Stages to use for filtering */
  stages: Maybe<ReadonlyArray<Maybe<OrderStage>>>;
}
export interface CommentsOrderModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Whether to flatten threads */
  flatten: Maybe<boolean>;
}
export interface EventsOrderModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Types of events to retrieve */
  types: Maybe<ReadonlyArray<Maybe<ResourceEvent>>>;
}
export interface JobOrderModelArgs {
  /** Id of the job */
  id: Maybe<string>;
}
export interface JobsOrderModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewOrderModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewsOrderModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Whether to return only reviews with messages */
  hasMessage: Maybe<boolean>;
}
export interface SnippetOrderModelArgs {
  /** Search query filter */
  query: Maybe<string>;
  /** Operators to include */
  includeOperators: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface TasksSummaryOrderModelArgs {
  /** Types of tasks to summarize */
  types: Maybe<ReadonlyArray<Maybe<TaskType>>>;
  /** Types of tasks to summarize */
  _types: Maybe<ReadonlyArray<Maybe<TaskType>>>;
}
export interface TasksJobModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface AttachmentsReviewModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Types of attachments to return */
  types: Maybe<ReadonlyArray<Maybe<AttachmentType>>>;
}
export interface CommentsReviewModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewReviewModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewsReviewModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Whether to return only reviews with messages */
  hasMessage: Maybe<boolean>;
}
export interface AttachmentsTaskModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface CommentsTaskModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewTaskModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewsTaskModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Whether to return only reviews with messages */
  hasMessage: Maybe<boolean>;
}
export interface CommentsWorkItemModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface JobsWorkItemModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewWorkItemModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ReviewsWorkItemModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Whether to return only reviews with messages */
  hasMessage: Maybe<boolean>;
}
export interface SharesWorkItemModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface TasksWorkItemModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Minimum average rating to select */
  ratingAverage: Maybe<number>;
  /** Types of tasks to select */
  types: Maybe<ReadonlyArray<Maybe<TaskType>>>;
}
export interface TransactionsSummaryAccountSubscriptionModelArgs {
  /** Interval filter */
  interval: Maybe<string>;
}
export interface TransactionsSummaryAccountTransactionsSummaryModelArgs {
  /** Interval filter */
  interval: Maybe<string>;
}
export interface AlternatesMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface AttachmentsMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Types of attachments to return */
  types: Maybe<ReadonlyArray<Maybe<AttachmentType>>>;
}
export interface CommentsMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface JobsMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface MembershipsMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface OrdersMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface OrganizationsMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ProductsMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Industry to filter on */
  industry: Maybe<IndustryType>;
  /** Functions to filter on */
  functions: Maybe<ReadonlyArray<Maybe<FunctionType>>>;
}
export interface SubscriptionsMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface WorkitemsMeModelArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface AccountTransactionMutationArgs {
  /** Id of the transaction */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface AddEventsMutationArgs {
  /** Event type to add */
  type: Maybe<ResourceEvent>;
  /** Access tokens for resources to add events for */
  accessTokens: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface AddReferenceDataMutationArgs {
  /** Reference data type */
  type: Maybe<ReferenceDataType>;
}
export interface AddWorkitemMutationArgs {
  /** Workitem type */
  type: Maybe<WorkItemType>;
}
export interface AttachmentMutationArgs {
  /** Id of the attachment */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface CommentMutationArgs {
  /** Id of the comment */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface DisplayProductMutationArgs {
  /** Id of the product */
  id: Maybe<string>;
}
export interface EventsMutationArgs {
  /** Ids of the events */
  ids: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface JobMutationArgs {
  /** Id of the job */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface MembershipMutationArgs {
  /** Id of the membership */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface OrderMutationArgs {
  /** Id of the order */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface OrganizationMutationArgs {
  /** Id of the organization */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface PersonMutationArgs {
  /** Id of the person */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface ProductMutationArgs {
  /** Id of the product */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface ProductsMutationArgs {
  /** Ids of the products */
  ids: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface ReferenceDataMutationArgs {
  /** Id of the reference data */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface ReviewMutationArgs {
  /** Id of the review */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface ReviewAttachmentMutationArgs {
  /** Id of the attachment */
  id: Maybe<string>;
}
export interface ReviewCommentMutationArgs {
  /** Id of the comment */
  id: Maybe<string>;
}
export interface ReviewJobMutationArgs {
  /** Id of the job */
  id: Maybe<string>;
}
export interface ReviewOrderMutationArgs {
  /** Id of the order */
  id: Maybe<string>;
}
export interface ReviewReviewMutationArgs {
  /** Id of the review */
  id: Maybe<string>;
}
export interface ReviewTaskMutationArgs {
  /** Id of the task */
  id: Maybe<string>;
}
export interface ReviewWorkitemMutationArgs {
  /** Id of the product */
  id: Maybe<string>;
}
export interface ShareMutationArgs {
  /** Id of the share */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface SubscriptionMutationArgs {
  /** Id of the subscription */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface TaskMutationArgs {
  /** Id of the task */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface WorkitemMutationArgs {
  /** Id of the product */
  id: Maybe<string>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface WorkitemsMutationArgs {
  /** Ids of the workitems */
  ids: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Access token for the operation */
  accessToken: Maybe<string>;
}
export interface ChangeAccountTransactionOperationsArgs {
  /** Value */
  value: Maybe<AccountTransactionInputModel>;
}
export interface ChangeOrganizationOperationsArgs {
  /** Value */
  value: Maybe<OrganizationInputModel>;
}
export interface MergeOrganizationOperationsArgs {
  /** Id of the organization to merge into this org */
  id: Maybe<string>;
}
export interface AddOrderProductOperationsArgs {
  /** Label to add to order */
  label: Maybe<string>;
}
export interface AddOrdersProductOperationsArgs {
  /** Emails or phone numbers of the customer */
  usernames: Maybe<string>;
  /** DEPRECATED. Use tokens. */
  customerIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Add authorization tokens */
  tokens: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface AddSharesProductOperationsArgs {
  /** Access level of the share */
  role: Maybe<ResourceRole>;
  /** Emails or phone numbers of the people to share with */
  usernames: Maybe<string>;
  /** Ids of the people to share with */
  subjectIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** State of this share */
  state: Maybe<ResourceState>;
}
export interface CopyProductOperationsArgs {
  /** Id of subscription to move products to */
  subscriptionId: Maybe<string>;
  /** Updated details */
  details: Maybe<ProductDetailsInputModel>;
}
export interface LinkProductOperationsArgs {
  /** Id of the product to link with */
  id: Maybe<string>;
}
export interface OrdersProductOperationsArgs {
  /** Number of items to return */
  first: Maybe<number>;
  /** Cursor value to start iterating forward from */
  after: Maybe<string>;
  /** Cursor value to start iterating backward from */
  before: Maybe<string>;
  /** States filter */
  states: Maybe<ReadonlyArray<Maybe<ResourceState>>>;
  /** Search query filter */
  query: Maybe<string>;
  /** Roles filter */
  roles: Maybe<ReadonlyArray<Maybe<ResourceRole>>>;
  /** Interval filter */
  interval: Maybe<string>;
  /** Exclude ids filter */
  excludeIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Exclude related ids filter */
  excludeRelatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Ids of orders to look up */
  ids: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Stages to use for filtering */
  stages: Maybe<ReadonlyArray<Maybe<OrderStage>>>;
  /** Minimum average rating to select */
  ratingAverage: Maybe<number>;
  /** Minimum average rating to select */
  eduRatingAverage: Maybe<number>;
  /** Maximum compensation annual amount to select */
  compensationAnnualAmountMax: Maybe<number>;
  /** Minimum compensation annual amount to select */
  compensationAnnualAmountMin: Maybe<number>;
  /** Minimum overlap duration to select */
  minDuration: Maybe<string>;
  /** Whether to return only supplier created resources */
  isSupplierCreated: Maybe<boolean>;
  /** Customer graduation organizations to use for filtering */
  graduationOrgs: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Sources of suggested orders to select */
  sources: Maybe<ReadonlyArray<Maybe<string>>>;
  /** Customer graduation dates to use for filtering */
  graduationInterval: Maybe<string>;
  /** Order delivery dates to use for filtering */
  deliveryInterval: Maybe<string>;
  /** Customer cities to use for filtering */
  cities: Maybe<ReadonlyArray<Maybe<CityCode>>>;
  /** Customer service interests to use for filtering */
  services: Maybe<ReadonlyArray<Maybe<ServiceType>>>;
  /** Customer area interests to use for filtering */
  areas: Maybe<ReadonlyArray<Maybe<AreaType>>>;
  /** Customer languages to use for filtering */
  languages: Maybe<ReadonlyArray<Maybe<LanguageCode>>>;
  /** Ids to use for filtering */
  relatedIds: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface PopulateOrdersProductOperationsArgs {
  /** Values to use for populating */
  values: Maybe<ReadonlyArray<Maybe<OrderPopulateInputModel>>>;
}
export interface ApplyOrderOperationsArgs {
  /** Action to apply */
  action: Maybe<string>;
}
export interface EventOrderOperationsArgs {
  /** Type of event */
  type: Maybe<ResourceEvent>;
}
export interface MergeOrderOperationsArgs {
  /** Id of the product to move the order to */
  id: Maybe<string>;
}
export interface MoveOrderOperationsArgs {
  /** Id of the product to move the order to */
  id: Maybe<string>;
}
export interface ChangeAttachmentOperationsArgs {
  /** Value */
  value: Maybe<AttachmentInputModel>;
}
export interface ChangeCommentOperationsArgs {
  /** Value */
  value: Maybe<CommentInputModel>;
}
export interface ChangeReviewOperationsArgs {
  /** Value */
  value: Maybe<ReviewInputModel>;
}
export interface ToggleOptionReviewOperationsArgs {
  /** Option to toggle */
  value: Maybe<ReviewOption>;
}
export interface AddTaskJobOperationsArgs {
  /** Type of the task to create */
  type: Maybe<TaskType>;
}
export interface ChangeOrderDetailsOperationsArgs {
  /** Order details */
  value: Maybe<OrderDetailsInputModel>;
}
export interface ChangeOrdersOperationsArgs {
  /** Screening details */
  details: Maybe<OrderDetailsInputModel>;
  /** Order expires on */
  expiresOn: Maybe<string>;
}
export interface CopyOrdersOperationsArgs {
  /** Id of product to copy orders to */
  productId: Maybe<string>;
}
export interface EventOrdersOperationsArgs {
  /** Cusotm event key */
  key: Maybe<string>;
  /** Event data */
  data: Maybe<ReadonlyArray<Maybe<MapEntry>>>;
}
export interface EventsOrdersOperationsArgs {
  /** Event type */
  type: Maybe<ResourceEvent>;
}
export interface InterviewOrdersOperationsArgs {
  /** Interview details */
  details: Maybe<OrderDetailsInputModel>;
}
export interface MoveOrdersOperationsArgs {
  /** Id of product to move orders to */
  productId: Maybe<string>;
}
export interface OfferOrdersOperationsArgs {
  /** Offer details */
  details: Maybe<OrderDetailsInputModel>;
  /** Offer expiry */
  expiresOn: Maybe<string>;
}
export interface ScreenOrdersOperationsArgs {
  /** Screening details */
  details: Maybe<OrderDetailsInputModel>;
}
export interface AddLabelOrdersDetailsOperationsArgs {
  /** Label key */
  key: Maybe<string>;
}
export interface AddLabelsOrdersDetailsOperationsArgs {
  /** Label keys */
  keys: Maybe<ReadonlyArray<Maybe<string>>>;
}
export interface ChangeOrdersDetailsOperationsArgs {
  /** Order details */
  value: Maybe<OrderDetailsInputModel>;
}
export interface RemoveLabelOrdersDetailsOperationsArgs {
  /** Label key */
  key: Maybe<string>;
}
export interface PrioritizeJobsOperationsArgs {
  /** When reviews are due */
  reviewsDueOn: Maybe<string>;
}
export interface ChangeSubjectSharesOperationsArgs {
  /** Subject access token */
  accessToken: Maybe<string>;
}
export interface SendMessagesSharesOperationsArgs {
  /** Message to send */
  value: Maybe<MessageInputModel>;
  /** URL of resource being shared */
  url: Maybe<string>;
  /** Title of the resource being shared */
  title: Maybe<string>;
}
export interface ChangeProductDetailsOperationsArgs {
  /** Value */
  value: Maybe<ProductDetailsInputModel>;
}
export interface UpdateDeprecatedSharesOperationsArgs {
  /** Updated values */
  values: Maybe<ReadonlyArray<Maybe<ShareInputModel>>>;
}
export interface ChangeAccountSubscriptionOperationsArgs {
  /** Value */
  value: Maybe<AccountSubscriptionInputModel>;
}
export interface ChangeReferenceDataOperationsArgs {
  /** Value */
  value: Maybe<ReferenceDataInputModel>;
}
export interface AddSharesWorkItemOperationsArgs {
  /** Access level of the share */
  role: Maybe<ResourceRole>;
  /** Emails or phone numbers of the people to share with */
  usernames: Maybe<string>;
  /** Ids of the people to share with */
  subjectIds: Maybe<ReadonlyArray<Maybe<string>>>;
  /** State of this share */
  state: Maybe<ResourceState>;
}
export interface ImportAdministrationOperationsArgs {
  /** Kind of resources to import */
  kind: Maybe<ResourceKind>;
  /** Number of resources to import */
  first: Maybe<number>;
  /** Minimum updatedOn of resources to import */
  since: Maybe<string>;
  /** Minimum updatedOn of resources to import */
  until: Maybe<string>;
}
export interface IndexAttachmentsAdministrationOperationsArgs {
  /** Number of attachments to index */
  first: Maybe<number>;
  /** Date filter for attachments to index */
  since: Maybe<string>;
  /** Date filter for attachments to index */
  until: Maybe<string>;
}
export interface StartAdministrationOperationsArgs {
  /** Key of the migration */
  key: Maybe<string>;
  /** Kind of resources to migrate */
  kind: Maybe<ResourceKind>;
  /** Migration arguments */
  args: Maybe<string>;
  /** Migration arguments */
  since: Maybe<string>;
  /** Migration production */
  production: Maybe<boolean>;
}
export interface BalancesQuickbooksOperationsArgs {
  /** Entity type to use */
  type: Maybe<QuickbooksEntity>;
}
export interface EntitiesQuickbooksOperationsArgs {
  /** Entity type to use */
  type: Maybe<QuickbooksEntity>;
}
export interface TransactionsQuickbooksOperationsArgs {
  /** Account to use */
  account: Maybe<QuickbooksAccount>;
}
export interface VendorQuickbooksOperationsArgs {
  /** Id of the vendor */
  id: Maybe<string>;
}
export interface ExportQuickbooksBalancesOperationsArgs {
  /** Number of items to return */
  first: Maybe<number>;
}
export interface ExportQuickbooksTransactionsOperationsArgs {
  /** Number of transactions to sync */
  first: Maybe<number>;
  /** Date filter for transactions to sync */
  since: Maybe<string>;
  /** Date filter for transactions to sync */
  until: Maybe<string>;
}
export interface PayQuickbooksVendorOperationsArgs {
  /** Value to limit payment amount to */
  limit: Maybe<number>;
}
export interface ExportReportsOperationsArgs {
  /** Report type to export */
  type: Maybe<ReportType>;
  /** Number of report rows to get */
  first: Maybe<number>;
  /** Date filter for report */
  since: Maybe<string>;
  /** Date filter for report */
  until: Maybe<string>;
}
export interface ParseTabularContentOperationsArgs {
  /** File to parse */
  value: Maybe<string>;
}
export interface AddMembershipMeOperationsArgs {
  /** Type of membership to add */
  type: Maybe<MembershipType>;
}
export interface AddSubscriptionMeOperationsArgs {
  /** Id of the plan to add a subscription for */
  planId: Maybe<string>;
}
export interface ChangeMeOperationsArgs {
  /** Value */
  value: Maybe<PersonInputModel>;
}
export interface ChangeContactInfoMeOperationsArgs {
  /** Key of the contact information */
  key: Maybe<string>;
  /** Value */
  value: Maybe<ExternalAccountInputModel>;
}
export interface ChangeLanguagePreferenceMeOperationsArgs {
  /** Key of the contact information */
  key: Maybe<string>;
  /** Value */
  value: Maybe<LanguagePreferenceInputModel>;
}
export interface ChangeLocationPreferenceMeOperationsArgs {
  /** Key of the contact information */
  key: Maybe<string>;
  /** Value */
  value: Maybe<LocationPreferenceInputModel>;
}
export interface ChangeTopicPreferenceMeOperationsArgs {
  /** Key of the contact information */
  key: Maybe<string>;
  /** Value */
  value: Maybe<TopicPreferenceInputModel>;
}
export interface MergeMeOperationsArgs {
  /** Id of the person to merge into me */
  id: Maybe<string>;
  /** Verification code */
  otp: Maybe<string>;
}
export interface RemoveContactInfoMeOperationsArgs {
  /** Key of the contact information */
  key: Maybe<string>;
}
export interface RemoveLanguagePreferenceMeOperationsArgs {
  /** Key of the contact information */
  key: Maybe<string>;
}
export interface RemoveLocationPreferenceMeOperationsArgs {
  /** Key of the contact information */
  key: Maybe<string>;
}
export interface RemoveTopicPreferenceMeOperationsArgs {
  /** Key of the contact information */
  key: Maybe<string>;
}
export interface ChangeMembershipOperationsArgs {
  /** Value */
  value: Maybe<MembershipInputModel>;
}
export interface ChangeMessageOperationsArgs {
  /** Value */
  value: Maybe<MessageInputModel>;
}
export interface MoveProductsOperationsArgs {
  /** Id of subscription to move products to */
  subscriptionId: Maybe<string>;
}
export interface MoveWorkItemsOperationsArgs {
  /** Id of subscription to move WorkItems to */
  subscriptionId: Maybe<string>;
}
export interface ChangeLegalResearchBackgroundOperationsArgs {
  /** Value */
  value: Maybe<LegalResearchBackgroundInputModel>;
}
export interface ChangeLegalResearchConclusionOperationsArgs {
  /** Value */
  value: Maybe<LegalResearchConclusionInputModel>;
}
export interface ChangeLegalResearchOperationsArgs {
  /** Value */
  value: Maybe<LegalResearchInputModel>;
}
export interface ChangeLegalResearchPrecedentOperationsArgs {
  /** Value */
  value: Maybe<LegalResearchPrecedentInputModel>;
}
export interface ChangeLegalResearchRephrasingOperationsArgs {
  /** Value */
  value: Maybe<LegalResearchRephrasingInputModel>;
}
export interface ChangeLegalResearchStatuteOperationsArgs {
  /** Value */
  value: Maybe<LegalResearchStatuteInputModel>;
}
