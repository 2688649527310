import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faFlag, faHeart, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Button, ButtonGroup, ListGroup, ListGroupItem, ListGroupItemProps } from "reactstrap";
import { UserThumbnail, UserThumbnailFragment } from "../cards/PersonCard";
import { DeepPartial } from "../hooks/ApiProvider";
import { useReviewOperations } from "../hooks/DiscussionHook";
import { preventDefault } from "../hooks/NavigationHook";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { DateTime, PersonModel, ResourceKind, ResourceState, ReviewModel, ReviewOption } from "../types/api-graph-types";

library.add(faHeart, faCheckSquare, faThumbsUp, faFlag);

export const Fragment = `
  name
  ${UserThumbnailFragment}
`

const _starFull: IconProp = ["fas", "star"];
const _starHalf: IconProp = ["fas", "star-half-alt"];
const _starEmpty: IconProp = ["far", "star"];

export function CardLoveButton({ value, objectId, objectKind, onUpdate }: {
  value: ReviewModel,
  objectId: string,
  objectKind: ResourceKind,
  onUpdate?: (value: DeepPartial<ReviewModel>) => void
}) {
  const { toggleReviewOption } = useReviewOperations();
  const has = value?.options?.includes(ReviewOption.Love);
  const classNames = has ? "" : "hover-display hover-opacity-125";
  const handleClick = async () => {
    const result = await toggleReviewOption(objectId, objectKind, ReviewOption.Love);
    onUpdate?.(result);
  }
  return (
    <Button className={classNames} color="link" onClick={handleClick}>
      <FontAwesomeIcon size="lg" icon={["far", "heart"]} />
    </Button>
  );
}

export function CardLikeButton({ value, objectId, objectKind, onUpdate }: {
  value: ReviewModel,
  objectId: string,
  objectKind: ResourceKind,
  onUpdate?: (value: DeepPartial<ReviewModel>) => void
}) {
  const { toggleReviewOption } = useReviewOperations();
  const has = value?.options?.includes(ReviewOption.Like);
  const classNames = has ? "" : "hover-display hover-opacity-125";
  const handleClick = async () => {
    const result = await toggleReviewOption(objectId, objectKind, ReviewOption.Like);
    onUpdate?.(result);
  }
  return (
    <Button className={classNames} color="link" onClick={handleClick}>
      <FontAwesomeIcon size="lg" icon={["far", "thumbs-up"]} />
    </Button>
  );
}

export function CardFlagButton({ value, objectId, objectKind, onUpdate }: {
  value: ReviewModel,
  objectId: string,
  objectKind: ResourceKind,
  onUpdate?: (value: DeepPartial<ReviewModel>) => void
}) {
  const { toggleReviewOption } = useReviewOperations();
  const has = value?.options?.includes(ReviewOption.Flag);
  const classNames = has ? "" : "hover-display hover-opacity-125";
  const handleClick = async () => {
    const result = await toggleReviewOption(objectId, objectKind, ReviewOption.Flag);
    onUpdate?.(result);
  }
  return (
    <Button className={classNames} color="link" onClick={handleClick}>
      <FontAwesomeIcon size="lg" icon={["far", "flag"]} />
    </Button>
  );
}

function _star(value: number, full: number, half: number): IconProp {
  if (value > full) return _starFull
  else if (value > half) return _starHalf
  else return _starEmpty;
}

export function CardRatingButton({ value, readonly, objectId, objectKind, onUpdate }: {
  value: ReviewModel,
  objectId: string,
  objectKind: ResourceKind,
  readonly?: boolean,
  onUpdate?: (value: DeepPartial<ReviewModel>) => void
}) {
  const { updateReview } = useReviewOperations();
  const setRating = async (rating: number) => {
    const result = await updateReview(objectId, objectKind, { state: ResourceState.Resolved, rating });
    onUpdate?.(result);
  }
  const rating = value.rating;
  const classNames = value.rating > 0 ? "" : "hover-visible hover-opacity-125";
  return readonly ? (
    <span className="text-primary">
      <FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 1, 0)} />
      <FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 3, 2)} />
      <FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 5, 4)} />
      <FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 7, 6)} />
      <FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 9, 8)} />
    </span>
  ) : (
      <ButtonGroup className={classNames}>
        <Button title="Poor" className="m-0 p-0" color="link" onClick={() => setRating(2)}><FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 1, 0)} /></Button>
        <Button title="Fair" className="m-0 p-0" color="link" onClick={() => setRating(4)}><FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 3, 2)} /></Button>
        <Button title="Average" className="m-0 p-0" color="link" onClick={() => setRating(6)}><FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 5, 4)} /></Button>
        <Button title="Good" className="m-0 p-0" color="link" onClick={() => setRating(8)}><FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 7, 6)} /></Button>
        <Button title="Excellent" className="m-0 p-0" color="link" onClick={() => setRating(10)}><FontAwesomeIcon size="lg" fixedWidth icon={_star(rating, 9, 8)} /></Button>
      </ButtonGroup>
    );
}

export function CardSelectButton({ selected, onSelect, className }: {
  selected?: boolean,
  onSelect?: () => void,
  className?: string
}) {
  const classNames = [
    className,
    selected ? "" : "hover-visible hover-opacity-125"
  ].join(" ");
  return (
    <Button className={classNames} color="link" onClick={preventDefault(onSelect)}>
      <FontAwesomeIcon size="lg" icon={["far", "check-square"]} />
    </Button>
  );
}

export default ({ value, at, children, ...attrs }: {
  value: PersonModel,
  at: DateTime,
  children?: ReactNode
} & ListGroupItemProps) => {
  const [, d] = useTranslation();
  return value && (
    <ListGroup flush className="bb-1 hover-container cursor-default">
      <ListGroupItem className="d-flex align-items-center w-100" {...attrs}>
        <UserThumbnail className="mr-3" width={36} value={value} />
        <div className="d-flex align-items-center w-100">
          <span className="mr-auto">
            <small>{value.name}</small>
            <small className="text-muted ml-1">{d(at, DateDisplayFormat.DateShortSameYear)}</small>
          </span>
          {children}
        </div>
      </ListGroupItem>
    </ListGroup>
  ) || null;
}
