import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEvent, useState } from "react";
import { Col, Nav, NavItem, NavLink, Row, Form, UncontrolledDropdown, DropdownToggle, Dropdown, DropdownMenu } from "reactstrap";
import OrganizationCard, { Fragment as OrganizationFragment } from "../cards/OrganizationCard";
import PersonCard, { Fragment as PersonFragment } from "../cards/PersonCard";
import ProductCard, { Fragment as ProductFragment } from "../cards/ProductCard";
import SubscriptionCard, { Fragment as SubscriptionFragment } from "../cards/SubscriptionCard";
import WorkItemCard, { Fragment as WorkItemFragment } from "../cards/WorkItemCard";
import CardColumns from "../components/CardColumns";
import { CustomDropdown, CustomDropdownItem } from "../components/CustomDropdown";
import { useQuery } from "../hooks/ApiProvider";
import { useToggle } from "../hooks/CommonHooks";
import { preventDefault, useFragment, useQueryString } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { Fragment as OrganizationInputFragment } from "../modals/OrganizationInputModal";
import SubscriptionInputModal, { Fragment as SubscriptionInputFragment, New as NewSubscription } from "../modals/SubscriptionInputModal";
import { AccountSubscriptionModel, OrganizationModel, PersonModel, ProductModel, ResourceState, WorkItemModel } from "../types/api-graph-types";
import LoadingController from "./LoadingController";
import PeriodInput, { MenuItems as PeriodInputMenuItems } from "../components/PeriodInput";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import moment from "moment";

const _getOrganization = `query getOrganization($id:ID $states:[ResourceState] $first:Int) {
  organization(id:$id) {
    id
    ${OrganizationFragment}
    ${OrganizationInputFragment}
    products(states:$states first:$first) { id ${ProductFragment({ showStatus: true })} }
    workitems(states:$states first:$first) { id ${WorkItemFragment} }
    members(states:[RESOLVED,COMPLETED] first:$first) { id ${PersonFragment} }
    subscriptions(states:[COMPLETED] first:$first) { id ${SubscriptionFragment} ${SubscriptionInputFragment} }
  }
}`;

interface Result {
  organization: OrganizationModel
};

export enum View {
  Listings = "listings",
  WorkItems = "workitems",
  People = "people",
  Subscriptions = "subscriptions",
}

enum States {
  Complete = "I",
  Archived = "A",
  Active = ""
}

const _tabs = [View.Listings, View.WorkItems, View.People, View.Subscriptions] as View[];
const _titles = new Map<View, string>([
  [View.Listings, "Listings"],
  [View.WorkItems, "Work Items"],
  [View.People, "People"],
  [View.Subscriptions, "Subscriptions"]
]);
const _activeStates = [ResourceState.Proposed, ResourceState.InProgress, ResourceState.Resolved];
const _completedStates = [ResourceState.Completed];
const _archivedStates = [ResourceState.Removed];

const _initialVars = { first: 10 };
const _initialQueryString = {
  states: States.Active
};

export default ({ id, view }: {
  id: string,
  view?: View
}) => {
  const [t, d] = useTranslation();
  const [queryString, setQueryString] = useQueryString(_initialQueryString);
  const [fragment, setFragment] = useFragment({
    show: ""
  });
  const [period, setPeriod] = useState(`${moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD')}/${moment().startOf('month').format('YYYY-MM-DD')}`);
  const tab = fragment.show as View || view || _tabs[0];
  const tabIndex = _tabs.indexOf(tab);
  const [vars, setVars] = useState(_initialVars);
  const [isOpenNewSubscription, toggleNewSubscription] = useToggle();
  const [result, updateResult, isLoading] = useQuery<Result>(_getOrganization, {
    id,
    states: queryString.states === States.Complete ? _completedStates : queryString.states === States.Archived ? _archivedStates : _activeStates,
    ...vars
  });
  const value = result?.organization;
  useTitle(value && value.name);
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const ids = [
    () => value.products?.map(_ => _.id),
    () => value.workitems?.map(_ => _.id),
    () => value.members?.map(_ => _.id),
    () => value.products?.map(_ => _.id),
  ][tabIndex]() || [];
  const count = ids.length;

  const getMore = (e: MouseEvent) => {
    setVars({ ...vars, first: e.getModifierState("Shift") ? 1024 : vars.first * 2 });
  }
  const clearFilters = () => setVars(_initialVars);
  const updateOrganization = (organization: OrganizationModel) => updateResult({ organization });
  const updateWorkItem = (workitem: WorkItemModel) => updateResult({ organization: { workitems: [workitem] } });
  const updateProduct = (product: ProductModel) => updateResult({ organization: { products: [product] } });
  const updatePerson = (member: PersonModel) => updateResult({ organization: { members: [member] } });
  const updateSubscription = (subscription: AccountSubscriptionModel) => updateResult({ organization: { subscriptions: [subscription] } });

  const showStatesFilterNav = tab === View.Listings || tab === View.WorkItems;
  const showFiltersMenu = false;
  const showActions = tab === View.Subscriptions;
  const showPeriodFilterNav = tab === View.Subscriptions;
  const cols = tab === View.Subscriptions ? {} : { md: 2, xl: 3 };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <OrganizationCard value={value} onUpdate={updateOrganization} showButtons showInput />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={2}>
          <Nav className='flex-md-column sticky-top'>
            {_tabs.map((_, i) => (
              <NavItem key={i} className='mr-1'>
                <NavLink active={tabIndex === i} href="#" onClick={() => setFragment({ ...fragment, show: _tabs[i] })}>
                  {_titles.get(_)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
        <Col>
          <Row>
            <Col>
              <Nav>
                {showStatesFilterNav &&
                  <>
                    <NavItem className="mr-1"><NavLink active={queryString.states === States.Active} href="#" onClick={() => setQueryString({ ...queryString, states: States.Active })}>Active</NavLink></NavItem>
                    <NavItem className="mr-1"><NavLink active={queryString.states === States.Complete} href="#" onClick={() => setQueryString({ ...queryString, states: States.Complete })}>Completed</NavLink></NavItem>
                    {queryString.states === States.Archived && <NavItem className="mr-1"><NavLink active={queryString.states === States.Archived} href="#" onClick={() => setQueryString({ ...queryString, states: States.Archived })}>Archived</NavLink></NavItem>}
                  </>
                }
                {showPeriodFilterNav &&
                  <UncontrolledDropdown>
                    <DropdownToggle color="primary">
                      {d(period.split("/")[0], DateDisplayFormat.HumanDateRange, period.split("/")[1])}
                    </DropdownToggle>
                    <DropdownMenu>
                      <PeriodInputMenuItems value={period} onUpdate={setPeriod} />
                    </DropdownMenu>
                  </UncontrolledDropdown>
                }
                {showActions &&
                  <CustomDropdown className={!showFiltersMenu ? "ml-auto" : ""} label='Actions'>
                    {tab === View.Subscriptions && <CustomDropdownItem onClick={toggleNewSubscription}>New subscription</CustomDropdownItem>}
                  </CustomDropdown>
                }
              </Nav>
            </Col>
          </Row>
          {tab === View.Subscriptions && <SubscriptionInputModal value={NewSubscription} customerOrg={value} onUpdate={updateSubscription} isOpen={isOpenNewSubscription} toggle={toggleNewSubscription} />}
          <Row className="mt-3">
            <CardColumns {...cols}>
              {tab === View.Listings && value.products.map((_, i) => <ProductCard key={i} href={`/my/listings/${_.id}`} value={_} onUpdate={updateProduct} showNotice showStatus />)}
              {tab === View.WorkItems && value.workitems.map((_, i) => <WorkItemCard key={i} href={`/my/workitems/${_.id}`} value={_} onUpdate={updateWorkItem} showNotice showStatus />)}
              {tab === View.People && value.members.map((_, i) => <PersonCard key={i} href={`/my/team/${_.id}`} value={_} onUpdate={updatePerson} showStatus />)}
              {tab === View.Subscriptions && value.subscriptions.map((_, i) => <SubscriptionCard key={i} href={`/my/subscriptions/${_.id}`} value={_} onUpdate={updateSubscription} showButtons showStatus showTransactions showInput showShare />)}
            </CardColumns>
          </Row>
          <Row className="mt-3">
            <Col>
              {isLoading && <FontAwesomeIcon className="text-muted" icon="circle-notch" spin />}
              {!isLoading && count == 0 && <span>No matching results. <a href="#" onClick={preventDefault(clearFilters)}>View all</a></span>}
              {!isLoading && count >= vars.first && <a href="#" onClick={preventDefault(getMore)}>See more</a>}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
};
