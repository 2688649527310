import React from "react";
import { Button, DropdownItem, ListGroup, CardHeader, UncontrolledDropdown, ListGroupItem, DropdownToggle, DropdownMenu, Card } from "reactstrap";
import { LocationPreferenceModel, CityCode, LocationPreferenceInputModel, LocationPreferenceOption, PersonModel } from "../../types/api-graph-types";
import { useTranslation } from "../../hooks/TranslationProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeepPartial, useMutation, setDelete } from "../../hooks/ApiProvider";
import { CustomDropdownItem } from "../../components/CustomDropdown";
import { useIcons } from "../../hooks/IconizeHook";
import { preventOverflow, CardBadge, CardBadges } from "../../components/CardStatus";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

library.add(faTrashAlt)

export const Fragment = `
  key
  value {
    city
    locality
  }
  options  
`;

const _updateMyLocationPreference = `mutation updateMyLocationPreference($key:String $value:LocationPreferenceInputModel) {
  me {
    changeLocationPreference(key:$key value:$value)
    commit { locationPreferences { ${Fragment} } }
  }
}`

const _removeMyLocationPreference = `mutation removeMyLocationPreference($key:String) {
  me {
    removeLocationPreference(key:$key)
    commit { locationPreferences { ${Fragment} } }
  }
}`

interface OperationResults {
  me: {
    commit: PersonModel
  }
}

const { Love, Like, Dislike, Current, Future } = LocationPreferenceOption;

export function ItemRow({ value, readonly, onUpdate }: {
  value: LocationPreferenceModel,
  readonly?: boolean,
  onUpdate?: (value: DeepPartial<LocationPreferenceModel>, values: DeepPartial<ReadonlyArray<LocationPreferenceModel>>) => void,
}) {
  const [i] = useIcons();
  const [t] = useTranslation();
  const isCurrent = value.options.includes(Current);
  const isFuture = value.options.includes(Future);
  const rating = value.options.includes(Love) ? Love : value.options.includes(Dislike) ? Dislike : Like;
  const [mutation] = useMutation<OperationResults>();
  const remove = async () => {
    const result = await mutation(_removeMyLocationPreference, { key: value.key });
    onUpdate?.(setDelete(value), result.me.commit.locationPreferences);
  }
  const modifyOptions = async (add: LocationPreferenceOption[], remove: LocationPreferenceOption[]) => {
    const result = await mutation(_updateMyLocationPreference, {
      key: value.key,
      value: {
        options: [...value.options.filter(_ => !remove.includes(_)), ...add]
      }
    });
    onUpdate?.(result.me.commit.locationPreferences.filter(p => p.key === value.key)[0], result.me.commit.locationPreferences)
  };
  return (
    <ListGroupItem className='hover-container d-flex align-items-baseline'>
      {readonly &&
        <>
          <FontAwesomeIcon fixedWidth size='lg' className='mr-1' icon={i(rating)} />
          {value.value.city === CityCode.Other ? value.value.locality || "" : t(value.value.city)}
        </>
      }
      {!readonly &&
        <UncontrolledDropdown>
          <DropdownToggle color='white' className='p-0'>
            <FontAwesomeIcon fixedWidth size='lg' className='mr-1' icon={i(rating)} />
            {value.value.city === CityCode.Other ? value.value.locality || "" : t(value.value.city)}
          </DropdownToggle>
          <DropdownMenu>
            <CustomDropdownItem onClick={() => modifyOptions([Love], [Like, Dislike])}>{t(Love)}</CustomDropdownItem>
            <CustomDropdownItem onClick={() => modifyOptions([Like], [Love, Dislike])}>{t(Like)}</CustomDropdownItem>
            <CustomDropdownItem onClick={() => modifyOptions([Dislike], [Love, Like])}>{t(Dislike)}</CustomDropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      }
      {readonly && isCurrent && <label className="ml-3 small text-muted">{t(Current)}</label>}
      {readonly && isFuture && <label className="ml-3 small text-muted">Willing to relocate</label>}
      {!readonly &&
        <UncontrolledDropdown className='col-2'>
          <DropdownToggle color='white' className='p-0 btn-sm text-muted text-left hover-caret-visible' caret>
            {isCurrent ? t(Current) : isFuture ? "Willing to relocate" : <span className='d-none d-sm-inline hover-visible'>Add preference</span>}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => modifyOptions([Current], [Future])}>{t(Current)}</DropdownItem>
            <DropdownItem onClick={() => modifyOptions([Future], [Current])}>Willing to relocate</DropdownItem>
            <DropdownItem onClick={() => modifyOptions([], [Current, Future])}>Neither</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      }
      {!readonly && <Button className="d-none d-sm-block ml-auto hover-visible" color="link" size="sm" onClick={remove}>Remove</Button>}
      {!readonly && <Button className="d-sm-none ml-auto text-muted" color="link" size="sm" onClick={remove}><FontAwesomeIcon icon={["far", "trash-alt"]} /></Button>}
    </ListGroupItem>
  );
}

export function Badges({ values, size }: {
  values: ReadonlyArray<LocationPreferenceModel>,
  size?: string
}) {
  const [t] = useTranslation();
  return (
    <>
      {values.map((_, i) => <CardBadge key={i} size={size}
        value={_.value.city === CityCode.Other ? _.value.locality || "" : t(_.value.city)}
        del={_.options.includes(LocationPreferenceOption.Dislike)}
        icon={_.options.includes(LocationPreferenceOption.Current) ? ["fas", "map-marker-alt"]
          : _.options.includes(LocationPreferenceOption.Love) ? ["far", "heart"]
            : undefined}
        title={_.options.includes(LocationPreferenceOption.Current) ? "Current"
          : _.options.includes(LocationPreferenceOption.Dislike) ? "Dislike"
            : _.options.includes(LocationPreferenceOption.Future) ? "Willing to relocate"
              : _.options.includes(LocationPreferenceOption.Love) ? "Love"
                : undefined}
      />)}
    </>
  );
}

const _cities = [
  CityCode.InBlr,
  CityCode.InMaa,
  CityCode.InHrGur,
  CityCode.InHyd,
  CityCode.InCcu,
  CityCode.InBom,
  CityCode.InDel,
  CityCode.InDlNcr,
  CityCode.InUpNoi,
  CityCode.InPnq,
];

export default ({ className, values, onUpdate }: {
  className?: string,
  values: ReadonlyArray<LocationPreferenceModel>,
  onUpdate: (values: DeepPartial<ReadonlyArray<LocationPreferenceModel>>) => void,
}) => {
  const [t] = useTranslation();
  const addValues = _cities.filter(_ => values.findIndex(__ => __.value.city === _) === -1);
  const [mutation] = useMutation<OperationResults>();
  const update = (value: DeepPartial<LocationPreferenceModel>, values: DeepPartial<ReadonlyArray<LocationPreferenceModel>>) => onUpdate([...values, value]);
  const addLocation = async (value: Partial<LocationPreferenceInputModel>) => {
    const result = await mutation(_updateMyLocationPreference, { value });
    onUpdate?.(result.me.commit.locationPreferences);
  }
  return (
    <Card className={className}>
      <CardHeader className="bg-white d-flex align-items-baseline">
        <h3>Locations</h3>
        {values.length < 3 ? (
          <UncontrolledDropdown className="ml-auto">
            <DropdownToggle color="link" caret>Add</DropdownToggle>
            <DropdownMenu modifiers={preventOverflow}>
              {addValues.map((_, i) => <DropdownItem key={i} onClick={() => addLocation({ city: _, locality: "" })}>{t(_)}</DropdownItem>)}
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
            <span className="ml-auto text-muted small">0 slots left</span>
          )
        }
      </CardHeader>
      <ListGroup flush>
        {values.map((_, i) => <ItemRow key={i} value={_} onUpdate={update} />)}
        {values.length === 0 && <ListGroupItem className="text-muted">Use locations to indicate cities in which you are looking for opportunities.</ListGroupItem>}
      </ListGroup>
    </Card>
  );
}
