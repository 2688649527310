import React, { createContext, ReactNode, useContext, useState, MouseEventHandler } from "react";

interface Settings {
  showInput?: boolean,
  title?: ReactNode,
  label?: ReactNode,
}

interface Confirmation extends Settings {
  message?: string,
  onClick: (value: string) => void,
}

type ContextType = [Confirmation | undefined, (callback: (Confirmation | undefined)) => void];
const Context = createContext<ContextType>([undefined, () => { }]);

type Callback<T> = (value: string, arg: T) => void

export function useConfirmation() {
  const [, setConfirmation] = useContext(Context);
  function confirm<T>(callback: Callback<T>, message?: string, settings: Settings = {}) {
    return (arg: T) => setConfirmation({
      message,
      ...settings,
      onClick: value => callback(value, arg)
    });
  }
  return confirm;
}

export function useConfirmationContext(): ContextType {
  const [confirmation, setConfirmation] = useContext(Context);
  return [confirmation, setConfirmation];
}

export default (props: { children?: ReactNode }) => {
  const [confirmation, setConfirmation] = useState<Confirmation>();
  return (
    <Context.Provider value={[confirmation, setConfirmation]}>
      {props.children}
    </Context.Provider>
  );
}
