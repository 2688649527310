import React from "react";
import { JobModel, WorkItemModel, LegalResearchModel, WorkItemType } from "../../types/api-graph-types";
import { Fragment as _TaskFragment, isLegalResearchConclusion, isLegalResearchRephrasing, isLegalResearchBackground, isLegalResearchStatute, isLegalResearchPrecedent, InputCard as TaskInputCard, IsValid } from "../../cards/TaskCard";
import { Fragment as _WorkItemFragment } from "../../cards/WorkItemCard";
import CardMarkdownText from "../../components/CardMarkdownText";
import LegalResearchConclusionText, { Fragment as LegalResearchConclusionFragment } from "../../cards/Legal/LegalResearchConclusionText";
import LegalResearchRephrasingText from "../../cards/Legal/LegalResearchRephrasingText";
import LegalResearchPrecedentText from "../../cards/Legal/LegalResearchPrecedentText";
import LegalResearchStatuteText from "../../cards/Legal/LegalResearchStatuteText";
import LegalResearchBackgroundText from "../../cards/Legal/LegalResearchBackgroundText";
import { useDocumentContext } from "../../hooks/DocumentProvider";
import ExternalLink from "../../components/ExternalLink";
import { useTranslation, DateDisplayFormat } from "../../hooks/TranslationProvider";

export const Fragment = {
  workitem: _WorkItemFragment,
  task: LegalResearchConclusionFragment
};

export const DocumentFragment = {
  workitem: _WorkItemFragment,
  task: _TaskFragment
}

export function isLegalResearch(value: JobModel): value is JobModel & { workitem: WorkItemModel & LegalResearchModel } {
  return value.workitem?.details.type === WorkItemType.LegalResearch;
}

export function DocumentText({ value }: { value: JobModel }) {
  const [, , references] = useDocumentContext();
  const [, d] = useTranslation();
  const rephrasing = value.tasks.filter(isLegalResearchRephrasing);
  const conclusion = value.tasks.filter(isLegalResearchConclusion);
  const backgrounds = value.tasks.filter(isLegalResearchBackground).filter(_ => _.details.excerpt);
  const statutes = value.tasks.filter(isLegalResearchStatute).filter(_ => _.details.excerpt);
  const precedents = value.tasks.filter(isLegalResearchPrecedent).filter(_ => _.details.excerpt);
  return (
    <>
      <h2>Research Query</h2>
      <CardMarkdownText justify hyphens source={value.workitem.details.description} />
      <h2>Answer</h2>
      {rephrasing.map((_, i) => <LegalResearchRephrasingText key={i} value={_.details} />)}
      {conclusion.length > 0 && <>
        {conclusion.map((_, i) => <LegalResearchConclusionText key={i} value={_.details} />)}
      </>}
      {precedents.length > 0 && <>
        <h2>Relevant Precedents</h2>
        {precedents.map((_, i) => <LegalResearchPrecedentText key={i} value={_.details} />)}
      </>}
      {statutes.length > 0 && <>
        <h2>Relevant Statutes</h2>
        {statutes.map((_, i) => <LegalResearchStatuteText key={i} value={_.details} />)}
      </>}
      {backgrounds.length > 0 && <>
        <h2>Additional Discussion</h2>
        {backgrounds.map((_, i) => <LegalResearchBackgroundText key={i} value={_.details} />)}
      </>}
      <h2>References</h2>
      <ol className="list-bracket">
        {references.map((_, i) =>
          <li key={i}>
            {_.title && <span className="text-capitalize mr-1">{(_.title || "").toLowerCase()}</span>}
            {_.key && <span className='mr-1'>[{_.key.trim()}]</span>}
            <ExternalLink href={_.href} />
          </li>
        )}
      </ol>
      <h2>Acknowledgements</h2>
      This work is licensed under <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="_blank">CC BY-NC 4.0</a> by HigherKnowledge.
      It is an adaptation of work by {value.worker.name} on {d(value.updatedOn, DateDisplayFormat.DateLong)} used under <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY</a>.
    </>
  );
}

export default ({ value }: { value: JobModel }) => {
  const answer = value.tasks.filter(isLegalResearchConclusion);
  return (
    <>
      <CardMarkdownText justify hyphens clampHeight='9em' source={value.workitem.details.description} />
      {answer.length > 0 && <hr />}
      {answer.map(_ => <LegalResearchConclusionText value={_.details} />)[0]}
    </>
  );
}
