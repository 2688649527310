import React from "react";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import AttachmentCard, { Fragment as AttachmentFragment } from "../cards/AttachmentCard";
import { useQuery } from "../hooks/ApiProvider";
import { useTitle } from "../hooks/TitleHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { AttachmentModel, ResourceState } from "../types/api-graph-types";
import LoadingController from "./LoadingController";
import { useNavigation } from "../hooks/NavigationHook";

const _getAttachment = `query getAttachment($id:ID) {
  attachment(id:$id) {
    id
    kind
    type
    title
    ${AttachmentFragment}
    owner { name }
  }
}`;

interface Result {
  attachment: AttachmentModel
}

export default ({ id }: { id: string }) => {
  const [t, d] = useTranslation();
  const [, go] = useNavigation();
  const [result, updateResult, isLoading] = useQuery<Result>(_getAttachment, { id });
  const value = result?.attachment;
  useTitle(value && (value.title || t(value.type)));
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const update = (attachment: Partial<AttachmentModel>) => {
    updateResult({ attachment });
    if (attachment.state === ResourceState.Removed) {
      go(`/me`);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Breadcrumb>
            <BreadcrumbItem><a href={`/me`}>{value.owner.name}</a></BreadcrumbItem>
            <BreadcrumbItem active>{value.subtitle} {value.title}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <AttachmentCard value={value} onUpdate={update} publicDiscussion showStatus showInput showButtons showDiscussion openDiscussion sideDiscussion="xl" />
        </Col>
      </Row>
    </>
  )
};
