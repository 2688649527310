import React, { createContext, useRef, useContext, ReactNode, RefObject } from "react";
import { UncontrolledTooltip as BaseUncontrolledTooltip, UncontrolledTooltipProps } from "reactstrap";

const Context = createContext<RefObject<HTMLDivElement>>({ current: null });

export function TooltipContext({ children }: { children: ReactNode }) {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Context.Provider value={ref}>
      {children}
    </Context.Provider>
  );
}

export function TooltipTarget({ children }: { children: ReactNode }) {
  const ref = useContext(Context);
  return (
    <div ref={ref}>
      {children}
    </div>
  );
}

export function UncontrolledTooltip({ children, target, ...props }: Partial<UncontrolledTooltipProps>) {
  const ref = useContext(Context);
  return !ref.current ? null : (
    <BaseUncontrolledTooltip target={ref.current} {...props}>
      {children}
    </BaseUncontrolledTooltip>
  );
}
