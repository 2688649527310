import React from "react";
import { ButtonProps, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReadyState } from "../hooks/ApiProvider";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useTrackedEvent } from "../hooks/AppInsightsProvider";

library.add(faCircleNotch);

export default ({ readyState, className, disabled, children, event, onClick: _onClick, ...attrs }: {
  readyState: ReadyState
  event?: string
} & ButtonProps) => {
  const track = useTrackedEvent();
  const isLoading = readyState === ReadyState.Loading;
  const onClick = event ? track(_onClick, event) : _onClick;
  return (
    <Button className={`text-nowrap ${className}`} disabled={disabled || isLoading} onClick={onClick} {...attrs}>
      {isLoading && <FontAwesomeIcon className="mr-2" icon="circle-notch" spin />}
      {children}
    </Button>
  );
}
