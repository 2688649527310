import React, { useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormGroup, { isEmail } from "../components/FormGroup";
import Input from "../components/Input";
import MutationButton from "../components/MutationButton";
import { nextSequence, useMutation, useReadyState } from "../hooks/ApiProvider";
import { preventDefault } from "../hooks/NavigationHook";
import { MessageInputModel } from "../types/api-graph-types";
import { useToasts } from "../hooks/ToastsProvider";

const _sendMyFeedback = `mutation sendMyProductSuggestion($value:MessageInputModel) {
  me { 
    addMessage { 
      change(value:$value)
      send
    } 
  }
}`;

interface OperationResults {
  me: {
    addMessage: {
      send: boolean
    }
  }
};

interface ProductInput {
  supplierEmail: string,
  organizationName: string,
  organizationDescription: string,
  productType: string,
  productDescriptionUrl: string,
  ordersDescription: string
}

const _defaultInput: ProductInput = {
  supplierEmail: "",
  organizationName: "",
  organizationDescription: "",
  productType: "",
  productDescriptionUrl: "",
  ordersDescription: ""
};

const reMargin = /^[ \t]*\| ?/gm;
function _trimMargin(value: string) {
  return value.replace(reMargin, "");
}

export default ({ isOpen, toggle }: { isOpen?: boolean, toggle?: () => void }) => {
  const [readyState, setReadyState] = useReadyState();
  const [, setToast] = useToasts();
  const [input, setInput] = useState(_defaultInput);
  const [mutation] = useMutation<OperationResults>();
  const reset = () => {
    setInput(_defaultInput);
  }
  const updateInput = (props: Partial<ProductInput>) => setInput({ ...input, ...props });
  const send = async () => {
    try {
      const content = _trimMargin(`
        | Contact: ${input.supplierEmail}
        | Type: ${input.productType}
        | URL: ${input.productDescriptionUrl}
        | Organization: ${input.organizationName}
        | ${input.organizationDescription}
        | 
        | Application:
        | ${input.ordersDescription}
        | 
        | {{Me}}
      `);
      await mutation(_sendMyFeedback, {
        value: {
          isDraft: false,
          recipientEmails: "srivalli@higherknowledge.in,recruitments@higherknowledge.in",
          subject: `Suggested opportunity`,
          content
        } as Partial<MessageInputModel>
      }, { setReadyState });
      toggle?.();
      reset();
      setToast({ title: "Thank you!", message: "Thank you for helping make HigherKnowledge better." }, 5000);
    } catch (errors) {
    }
  }
  const cancel = () => {
    toggle?.();
    reset();
  }

  const isValid = (input.supplierEmail && input.supplierEmail.split(",").map(_ => _.trim()).every(isEmail))
    && input.productType
    && input.organizationName;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form>
        <ModalHeader>Suggest an opportunity</ModalHeader>
        <ModalBody>
          <FormGroup label='Opportunity' required>
            <select className="custom-select" value={input.productType} onChange={e => updateInput({ productType: e.currentTarget.value })} >
              <option value=""></option>
              <option value="Internship">Internship</option>
              <option value="Associate 0 to 2">Associate position (0 – 2 year experience)</option>
              <option value="Associate 3 to 5">Associate position (3 – 5 year experience)</option>
              <option value="Associate 6+">Associate position (6+ year experience)</option>
            </select>
          </FormGroup>
          <FormGroup label='Organization name' required>
            <Input type="text" placeholder="Enter the organization name here" value={input.organizationName} onUpdate={organizationName => updateInput({ organizationName })} />
          </FormGroup>
          {false &&
            <FormGroup label='Organization description'>
              <Input type="textarea" rows={3} placeholder="Enter a brief description of the organization here" value={input.organizationDescription} onUpdate={organizationDescription => updateInput({ organizationDescription })} />
            </FormGroup>
          }
          {false &&
            <FormGroup label='Application process'>
              <Input type="textarea" rows={3} placeholder="Enter a brief description of the application process" value={input.organizationDescription} onUpdate={ordersDescription => updateInput({ ordersDescription })} />
            </FormGroup>
          }
          <FormGroup label='Organization email' required>
            <Input type="text" placeholder="Enter the email address applications should be sent to" value={input.supplierEmail} onUpdate={supplierEmail => updateInput({ supplierEmail })} />
          </FormGroup>
          <FormGroup label='Additional details URL'>
            <Input type="text" placeholder="Enter URL for additional details" value={input.productDescriptionUrl} onUpdate={productDescriptionUrl => updateInput({ productDescriptionUrl })} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={preventDefault(cancel)}>Cancel</Button>
          <MutationButton readyState={readyState} disabled={!isValid} color="primary" onClick={send}>Send</MutationButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

