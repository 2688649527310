import { library } from "@fortawesome/fontawesome-svg-core";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { faBug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Collapse, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Modal, ModalBody, ModalFooter, ModalProps, Nav, Navbar, NavbarBrand, NavItem, NavLink, UncontrolledDropdown } from "reactstrap";
import { UserThumbnail } from "../cards/PersonCard";
import Input from "../components/Input";
import { useApiEndpoint } from "../hooks/ApiProvider";
import { useClipboard } from "../hooks/ClipboardHook";
import { useToggle } from "../hooks/CommonHooks";
import { useConfirmationContext } from "../hooks/ConfirmationProvider";
import { useMe, useMyOrganization } from "../hooks/MeProvider";
import { doNothing } from "../hooks/NavigationHook";
import { useSearchContext } from "../hooks/SearchProvider";
import { useAuthorizationToken } from "../hooks/TokenProvider";
import SendFeedbackModal from "../modals/SendFeedbackModal";
import Show from "./Show";

library.add(faSmile, faBug)

function ConfirmationModal({ ...attrs }: ModalProps) {
  const [confirmation, setConfirmation] = useConfirmationContext();
  const [input, setInput] = useState("");
  const cancel = () => setConfirmation(undefined);
  const confirm = () => {
    setConfirmation(undefined);
    confirmation?.onClick(input);
  };
  const showInput = confirmation?.showInput || false;
  const title = confirmation?.title ?? "Confirm";
  const label = confirmation?.label;
  return (
    <Modal isOpen={!!confirmation} {...attrs}>
      <ModalBody>
        {typeof (title) !== "string" ? title : <h3>{title}</h3>}
        {confirmation && confirmation.message}
        {showInput && <Input value={input} onUpdate={setInput} />}
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>Cancel</Button>
        {showInput && <Button color="primary" disabled={!input} onClick={confirm}>{label ?? "Ok"}</Button>}
        {!showInput && <Button color="primary" onClick={confirm}>{label ?? "Confirm"}</Button>}
      </ModalFooter>
    </Modal>
  );
}

function DebuggingDropdown({ }: {}) {
  const [, setClipboard] = useClipboard();
  const [baseUrl] = useApiEndpoint();
  const [token] = useAuthorizationToken();
  const headers = JSON.stringify({ Authorization: `Bearer ${token}` });
  return (
    <UncontrolledDropdown className="d-none d-sm-list-item mr-3" nav inNavbar>
      <DropdownToggle tag={NavLink}><FontAwesomeIcon icon={["fas", "bug"]} /></DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setClipboard(`${baseUrl}/api/graph`)}>Copy endpoint</DropdownItem>
        <DropdownItem onClick={() => setClipboard(headers)}>Copy headers</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

const _logo = (
  <svg height="32px" viewBox="-2 -2 100 100">
    <g stroke="#ffffff" strokeWidth="5" fill="none">
      <path d="M0,100 L0,66 L33,66 L33,33 L66,33 L66,0 L100,0" />
    </g>
  </svg>
);

export default () => {
  const [isOpenNavbar, toggleNavbar] = useToggle();
  const [isOpenMenu, toggleMenu] = useToggle();
  const [isOpenFeedback, toggleFeedback] = useToggle();
  const [me] = useMe();
  const [myOrg] = useMyOrganization();
  const [search, updateSearch] = useSearchContext();
  return (
    <>
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm" dark color="dark" >
          <Container fluid className="align-items-center p-0">
            <NavbarBrand className="text-nowrap" tag={Link} to="/">
              {_logo}
              HigherKnowledge
            </NavbarBrand>
            {me &&
              <Button className="navbar-toggler p-0 b-0" onClick={toggleNavbar}>
                <UserThumbnail className="ml-3" width={36} value={me} />
              </Button>
            }
            {search.isOpen &&
              <Form className="w-100" inline onSubmit={doNothing}>
                <Input className="mx-auto my-sm-0 w-100 w-sm-75 w-md-50" type="search" delay={500} placeholder="Search" value={search.value} onUpdate={value => updateSearch({ value })} />
              </Form>
            }
            <Collapse className="ml-auto d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpenNavbar} navbar>
              <Nav navbar>
                {me?.id === "45bcd3ed-a48a-4615-b4e4-65d67e5e8afe" && <DebuggingDropdown />}
                {me &&
                  <NavItem className="d-none d-sm-list-item">
                    <NavLink id="send-feedback" className="mr-3" href="#" title="Send feedback" onClick={toggleFeedback}>
                      <FontAwesomeIcon style={{ width: 24, height: 24 }} icon={["far", "smile"]} />
                    </NavLink>
                  </NavItem>
                }
                {me &&
                  <Dropdown className="d-none d-sm-list-item" nav inNavbar isOpen={isOpenMenu} toggle={toggleMenu}>
                    <div className="d-flex align-items-center p-0 cursor-pointer nav-link" onClick={toggleMenu}>
                      <div className="text-right">
                        <div>{me.nickname || me.name || me.email || me.phone}</div>
                        {myOrg?.displayName && <div className="small text-nowrap text-truncate" style={{ maxWidth: "10rem" }}>{myOrg.nickname ?? myOrg.displayName}</div>}
                      </div>
                      <UserThumbnail className="ml-3" width={36} value={me} />
                    </div>
                    <DropdownMenu right>
                      <DropdownItem tag={Link} to="/me">Profile</DropdownItem>
                      <Show scope="Site.Admin">
                        <DropdownItem tag={Link} to="/our/administration/integrations/quickbooks">Accounts</DropdownItem>
                        <DropdownItem tag={Link} to="/our/administration/reports">Reports</DropdownItem>
                      </Show>
                      <DropdownItem tag={Link} to="/sign-out">Sign out</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                }
                <NavItem className="d-sm-none"><NavLink tag={Link} to="/me">Profile</NavLink></NavItem>
                <NavItem className="d-sm-none"><NavLink onClick={toggleFeedback}>Send feedback</NavLink></NavItem>
                <NavItem className="d-sm-none"><NavLink tag={Link} to="/sign-out">Sign out</NavLink></NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
      {me && <SendFeedbackModal isOpen={isOpenFeedback} toggle={toggleFeedback} />}
      <ConfirmationModal />
    </>
  );
}
