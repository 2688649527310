import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Row } from "reactstrap";
import ContentPreviewDiv from "../components/ContentPreviewDiv";
import { useQuery } from "../hooks/ApiProvider";
import { useFragment, useNavigation } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { ProductModel } from "../types/api-graph-types";
import LoadingController from "./LoadingController";

const _getProductOrderJob = `query getProductOrderJob($pid:ID! $oid:ID! $jid:ID!) {
  product(id:$pid) {
    details { title subtitle displayTitle }
    order(id:$oid) {
      customer { name }
      job(id:$jid) {
        id      
        pdfUrl
        workitem { 
          details { type } 
        }
      }
    }
  }
}`;

interface Result {
  product: ProductModel
}

const _initialFragment = {
  collection: [] as string[]
};

export default ({ pid, oid, jid }: { pid: string, oid: string, jid: string }) => {
  const [fragment] = useFragment(_initialFragment);
  const [navigate, go] = useNavigation();
  const [t] = useTranslation();
  const [result, , isLoading] = useQuery<Result>(_getProductOrderJob, { pid, oid, jid });
  const product = result?.product;
  const value = result?.product?.order?.job;
  useTitle(product && value && `${t(value.workitem.details.type)} - ${product.order.customer.name} - ${product && product.details.displayTitle}`);
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const collection = fragment.collection;
  const selfIndex = collection?.findIndex(id => value?.id === id) ?? -1;
  const showNext = selfIndex < collection.length - 1;
  const nextId = showNext && collection[selfIndex + 1];
  const showPrev = selfIndex > 0;
  const prevId = showPrev && collection[selfIndex - 1];
  const showPrevNext = showPrev || showNext;
  const hash = window.location.hash;

  return (
    <>
      <Row>
        <Col>
          <Breadcrumb>
            <BreadcrumbItem><a href={`/my/listings/${pid}`}>{product && product.details.displayTitle}</a></BreadcrumbItem>
            <BreadcrumbItem><a href={`/my/listings/${pid}/candidates/${oid}`}>{product && product.order.customer.name}</a></BreadcrumbItem>
            <BreadcrumbItem active>{t(value.workitem.details.type)}</BreadcrumbItem>
            <div className="ml-auto">
              {showPrevNext &&
                <Button color="link" disabled={!showPrev} onClick={navigate(`/my/listings/${pid}/candidates/${oid}/tasks/${prevId}${hash}`)}>
                  <FontAwesomeIcon icon="chevron-left" />
                </Button>
              }
              {showPrevNext && <span>{selfIndex + 1} of {collection.length}</span>}
              {showPrevNext &&
                <Button color="link" disabled={!showNext} onClick={navigate(`/my/listings/${pid}/candidates/${oid}/tasks/${nextId}${hash}`)}>
                  <FontAwesomeIcon icon="chevron-right" />
                </Button>
              }
            </div>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Col>
              <ContentPreviewDiv showAnnotationLayer hideTextLayer type="application/pdf" src={value.pdfUrl} pageClassName="bih-1" />
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  )
};
