import React from "react";
import { Redirect, Route, Switch } from "react-router";
import "./App.scss";
import Layout from "./components/Layout";
import Show from "./components/Show";
import AttachmentController from "./controllers/AttachmentController";
import JobController from "./controllers/JobController";
import JobReviewController from "./controllers/JobReviewController";
import MyBillingPage from "./controllers/MyBillingController";
import MyProfilePage from "./controllers/MyProfileController";
import OrderController from "./controllers/OrderController";
import OrganizationController from "./controllers/OrganizationController";
import PersonController from "./controllers/PersonController";
import PersonJobController from "./controllers/PersonJobController";
import ProductController from "./controllers/ProductController";
import PublicProductController from "./controllers/ProductDisplayController";
import ProductOrderController from "./controllers/ProductOrderController";
import ProductOrderJobController from "./controllers/ProductOrderJobController";
import QuickbooksAdminController from "./controllers/QuickbooksAdminController";
import ReportsAdminController from "./controllers/ReportsAdminController";
import ShareController from "./controllers/ShareController";
import WorkItemController from "./controllers/WorkItemController";
import ApiProvider from "./hooks/ApiProvider";
import AppInsightsProvider from "./hooks/AppInsightsProvider";
import ConfirmationProvider from "./hooks/ConfirmationProvider";
import MyContextProvider from "./hooks/MeProvider";
import PreferencesProvider from "./hooks/PreferencesProvider";
import SearchProvider from "./hooks/SearchProvider";
import ToastsProvider from "./hooks/ToastsProvider";
import AuthenticationProvider, { useClaims } from "./hooks/TokenProvider";
import TranslationProvider from "./hooks/TranslationProvider";
import WindowStateProvider from "./hooks/WindowStateProvider";
import CollegeLandingPage from "./pages/CollegeLandingPage";
import CompanyPage from "./pages/CompanyPage";
import EmployerLandingPage from "./pages/EmployerLandingPage";
import FAQPage from "./pages/FAQPage";
import LegacyRedirect from "./pages/LegacyRedirect";
import MyDashboard, { View } from "./pages/MyDashboard";
import NotFoundPage from "./pages/NotFoundPage";
import PoliciesPage from "./pages/PoliciesPage";
import PricingPage from "./pages/PricingPage";
import PublicHomePage from "./pages/PublicHomePage";
import SignInPage, { Redirect as SignInRedirect } from "./pages/SignInPage";
import SignOutPage from "./pages/SignOutPage";

interface Id { id: string; }
interface Oid { oid: string; }
interface Pid { pid: string; }
interface Tid { tid: string; }
interface Jid { jid: string; }
interface Uid { uid: string; }
interface Key { key: string; }
interface R<T> { match: { params: T; } }

const Routes = () => {
  const [claims] = useClaims();
  return !claims
    ?
    <Switch>
      <Route path="/" exact component={PublicHomePage} />
      <Route path="/our/company" component={CompanyPage} />
      <Route path="/our/policies" component={PoliciesPage} />
      <Route path="/our/pricing" exact component={PricingPage} />
      <Route path="/our/services/for-colleges" component={CollegeLandingPage} />
      <Route path="/our/services/for-employers" component={EmployerLandingPage} />
      <Route path="/sign-in" exact component={SignInPage} />
      <Route path="/sign-up" exact render={() => <SignInPage signUp />} />
      <Route path="/go" component={LegacyRedirect} />
      <Route path="/law" component={LegacyRedirect} />
      <Route component={SignInRedirect} />
    </Switch>
    :
    <Switch>
      <Redirect exact from="/" to="/my/dashboard" />
      <Route path="/me" exact component={MyProfilePage} />
      <Route path="/my/dashboard" exact component={() => <MyDashboard />} />
      <Route path="/my/documents/:id" exact render={(_: R<Id>) => <AttachmentController id={_.match.params.id} />} />
      <Route path="/my/goals" exact component={() => <MyDashboard view={View.Goals} />} />
      <Route path="/my/goals/:id" exact render={(_: R<Id>) => <OrderController id={_.match.params.id} />} />
      <Route path="/my/subscriptions" exact component={() => <MyBillingPage />} />
      <Route path="/my/listings" exact component={() => <MyDashboard view={View.Listings} />} />
      <Route path="/my/listings/:id" exact render={(_: R<Id>) => <ProductController id={_.match.params.id} />} />
      <Route path="/my/listings/:pid/candidates/:oid" exact render={(_: R<Pid & Oid>) => <ProductOrderController pid={_.match.params.pid} oid={_.match.params.oid} />} />
      <Route path="/my/listings/:pid/candidates/:oid/tasks/:jid" exact render={(_: R<Pid & Oid & Jid>) => <ProductOrderJobController pid={_.match.params.pid} oid={_.match.params.oid} jid={_.match.params.jid} />} />
      <Route path="/my/opportunities" exact component={() => <MyDashboard view={View.Opportunities} />} />
      <Route path="/my/opportunities/:id" exact render={(_: R<Id>) => <PublicProductController id={_.match.params.id} />} />
      <Route path="/my/reviews" exact component={() => <MyDashboard view={View.Reviews} />} />
      <Route path="/my/reviews/:id" exact render={(_: R<Id>) => <JobReviewController id={_.match.params.id} />} />
      <Route path="/my/shares/:id" exact render={(_: R<Id>) => <ShareController id={_.match.params.id} />} />
      <Route path="/my/tasks" exact component={() => <MyDashboard view={View.Tasks} />} />
      <Route path="/my/tasks/:id" exact render={(_: R<Id>) => <JobController id={_.match.params.id} />} />
      <Route path="/my/team" exact component={() => <MyDashboard view={View.People} />} />
      <Route path="/my/team/:id" exact render={(_: R<Id>) => <PersonController id={_.match.params.id} />} />
      <Route path="/my/team/:uid/tasks/:jid" exact render={(_: R<Uid & Jid>) => <PersonJobController uid={_.match.params.uid} jid={_.match.params.jid} />} />
      <Route path="/my/teams" exact component={() => <MyDashboard view={View.Teams} />} />
      <Route path="/my/teams/:id" exact render={(_: R<Id>) => <OrganizationController id={_.match.params.id} />} />
      <Route path="/my/workitems" exact component={() => <MyDashboard view={View.WorkItems} />} />
      <Route path="/my/workitems/:id" exact render={(_: R<Id>) => <WorkItemController id={_.match.params.id} />} />
      <Route path="/our/company" component={CompanyPage} />
      <Route path="/our/faq" component={FAQPage} />
      <Route path="/our/policies" component={PoliciesPage} />
      <Route path="/our/pricing" exact component={PricingPage} />
      <Route path="/our/services/for-colleges" component={CollegeLandingPage} />
      <Route path="/our/services/for-employers" component={EmployerLandingPage} />
      <Route path="/sign-in" exact component={SignInPage} />
      <Route path="/sign-out" exact component={SignOutPage} />
      <Route path="/go" component={LegacyRedirect} />
      <Route path="/law" component={LegacyRedirect} />
      <Show scope="Site.Admin">
        <Route path="/our/administration/integrations/quickbooks" exact component={QuickbooksAdminController} />
        <Route path="/our/administration/reports" exact component={ReportsAdminController} />
      </Show>
      <Route component={NotFoundPage} />
    </Switch>;
};

export default () => (
  <WindowStateProvider>
    <ApiProvider>
      <AuthenticationProvider>
        <AppInsightsProvider>
          <MyContextProvider>
            <PreferencesProvider>
              <TranslationProvider>
                <SearchProvider>
                  <ToastsProvider>
                    <ConfirmationProvider>
                      <Layout>
                        <Routes />
                      </Layout>
                    </ConfirmationProvider>
                  </ToastsProvider>
                </SearchProvider>
              </TranslationProvider>
            </PreferencesProvider>
          </MyContextProvider>
        </AppInsightsProvider>
      </AuthenticationProvider>
    </ApiProvider>
  </WindowStateProvider>
);
