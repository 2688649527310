import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faEquals, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Badge, Button, CardBody, CardText, CardTitle, Col, Collapse, CustomInput, Form, Row, Table } from "reactstrap";
import Card from "../components/Card";
import FormGroup from "../components/FormGroup";
import { useToggle } from "../hooks/CommonHooks";
import { getURIComponents, useNavigation } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { useWindowState } from "../hooks/WindowStateProvider";
import { Workflow } from "../pages/MyDashboard";

library.add(faCheck, faTimes, faEquals);

interface Plan {
  recruitingWorkflow: boolean,
  dynamicAssessments: boolean,
  emailCalendarIntegration: boolean,
  smsIntegration: boolean,
  invoiceBilling: boolean,
  advancedFiltering: boolean,
  advancedReporting: boolean,
  apiAccess: boolean,
  perMonth: number,
  perApplicant: number,
  perAssessment: number,
  perMessage: number
}

const _plans: Plan[] = [
  {
    recruitingWorkflow: true,
    dynamicAssessments: true,
    emailCalendarIntegration: true,
    smsIntegration: true,
    advancedFiltering: false,
    advancedReporting: false,
    apiAccess: false,
    invoiceBilling: false,
    perMonth: 0,
    perApplicant: 25,
    perAssessment: 99,
    perMessage: 1
  },
  {
    recruitingWorkflow: true,
    dynamicAssessments: true,
    emailCalendarIntegration: true,
    smsIntegration: true,
    advancedFiltering: true,
    advancedReporting: true,
    apiAccess: false,
    invoiceBilling: false,
    perMonth: 9900,
    perApplicant: 25,
    perAssessment: 75,
    perMessage: 1
  },
  {
    recruitingWorkflow: true,
    dynamicAssessments: true,
    emailCalendarIntegration: true,
    smsIntegration: true,
    advancedFiltering: true,
    advancedReporting: true,
    apiAccess: true,
    invoiceBilling: true,
    perMonth: 49900,
    perApplicant: 25,
    perAssessment: 49,
    perMessage: 1
  }
];

export function PricingCard({ toggle }: { toggle?: () => void }) {
  const [, , n] = useTranslation();
  const [size] = useWindowState();
  const [navigate] = useNavigation();
  const sz = size === "xs" ? "small" : "h3";
  const primary = 1;
  const newListingQuery = getURIComponents({
    scope: "claim:FREETRIAL",
    workflow: Workflow.NewListing
  });
  return (
    <Card>
      <Table responsive className={`table text-center ${sz} mb-0 bb-1`}>
        <thead>
          <tr>
            <th></th>
            <th scope="col">Essentials</th>
            <th scope="col" className="table-info">
              <Badge color="info">Most popular</Badge>
              <br />
              Professional
            </th>
            <th scope="col">Enterprise</th>
          </tr>
          <tr>
            <td className="text-left">Recommended for monthly application volume</td>
            <td>0–30</td>
            <td className="table-info">30–200</td>
            <td>200+</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left">Recruiting workflow management</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>{_.recruitingWorkflow && <FontAwesomeIcon icon="check" />}</td>)}
          </tr>
          <tr>
            <td className="text-left">Email and calendar integration</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>{_.emailCalendarIntegration && <FontAwesomeIcon icon="check" />}</td>)}
          </tr>
          <tr>
            <td className="text-left">Phone, SMS, and WhatsApp integration</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>{_.smsIntegration && <FontAwesomeIcon icon="check" />}</td>)}
          </tr>
          <tr>
            <td className="text-left">Dynamic online assessments</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>{_.dynamicAssessments && <FontAwesomeIcon icon="check" />}</td>)}
          </tr>
          <tr>
            <td className="text-left">Customer support</td>
            <td>Email</td>
            <td className="table-info">Phone</td>
            <td>24/7</td>
          </tr>
          <tr>
            <td className="text-left">Advanced filtering</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>{_.advancedFiltering && <FontAwesomeIcon icon="check" />}</td>)}
          </tr>
          <tr>
            <td className="text-left">Advanced reporting</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>{_.advancedReporting && <FontAwesomeIcon icon="check" />}</td>)}
          </tr>
          <tr>
            <td className="text-left">API access</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>{_.apiAccess && <FontAwesomeIcon icon="check" />}</td>)}
          </tr>
          <tr>
            <td className="text-left">Invoice billing</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>{_.invoiceBilling && <FontAwesomeIcon icon="check" />}</td>)}
          </tr>
          <tr>
            <td className="text-left">Monthly commitment</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>₹{n(_.perMonth)}</td>)}
          </tr>
          <tr>
            <td className="text-left">Per application</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>₹{n(_.perApplicant)}</td>)}
          </tr>
          <tr>
            <td className="text-left">Per online assessment</td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>₹{n(_.perAssessment)}</td>)}
          </tr>
          <tr>
            <td className="text-left">Per metered unit<sup className="small text-muted">1</sup></td>
            {_plans.map((_, i) => <td key={i} className={i === primary ? "table-info" : ""}>₹{n(_.perMessage)}</td>)}
          </tr>
          {size !== "xs" &&
            <tr>
              <td className="text-left">30-day free trial</td>
              <td><Button color="primary" onClick={navigate(`/my/listings?${newListingQuery}`)}>Try for free</Button></td>
              <td className="table-info" onClick={navigate(`/my/listings?${newListingQuery}`)}><Button color="primary">Try for free</Button></td>
              <td><Button color="primary" onClick={navigate(`/my/listings?${newListingQuery}`)}>Try for free</Button></td>
            </tr>
          }
          {size === "xs" &&
            <tr>
              <td className="text-left">30-day free trial</td>
              <td><Button color="primary">Try</Button></td>
              <td className="table-info"><Button color="primary">Try</Button></td>
              <td><Button color="primary">Try</Button></td>
            </tr>
          }
        </tbody>
      </Table>
      <CardBody>
        <CardText>
          <ul className="list-unstyled text-muted small">
            <li><sup>1</sup> Messages are metered at 160 characters, calls at 60 seconds, and data transfer at 10 KB.</li>
            <li><sup>2</sup> Prices are exclusive of taxes.</li>
          </ul>
        </CardText>
        <Button color="primary" onClick={toggle}>Create estimate</Button>
      </CardBody>
    </Card>
  );
}

export function EstimateCard() {
  const [, , n] = useTranslation();
  const [applicants, setApplicants] = useState(10);
  const [assessments, setAssessments] = useState(3);
  const [messages] = useState(0);
  const [plan, setPlan] = useState(0);
  const [size] = useWindowState();
  const hsz = size === "xs" ? "h5" : "h3";
  const fsz = size === "xs" ? "1x" : "lg";
  const msz = size === "xs" ? "sm" : "mr-3";

  const price = _plans[plan];
  const perMonth = price.perMonth;
  const perApplicant = price.perApplicant;
  const perAssessment = price.perAssessment;
  const perMessage = price.perMessage;

  const usage = (applicants * perApplicant) + (applicants * assessments * perAssessment) + (applicants * messages * perMessage);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Your estimate</CardTitle>
        <Form>
          <Row>
            <Col className="d-flex align-items-center">
              <FormGroup className="w-100" label="Applicants per month">
                <CustomInput type="range" value={applicants} onChange={e => setApplicants(parseInt(e.currentTarget.value))} min={0} step={5} max={400} id="applicants" name="customRange" />
              </FormGroup>
              <h1 className="ml-3">{applicants}</h1>
            </Col>
            <Col className="d-flex align-items-center">
              <FormGroup className="w-100" label="Assessments per applicant (average)">
                <CustomInput type="range" value={assessments} onChange={e => setAssessments(parseFloat(e.currentTarget.value))} min={0} step={0.5} max={5} id="applicants" name="customRange" />
              </FormGroup>
              <h1 className="ml-3">{assessments.toFixed(1)}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup label="Plan">
                <CustomInput id="plan-0" type="radio" checked={plan === 0} name="plan" onChange={() => setPlan(0)} label="Essentials" />
                <CustomInput id="plan-1" type="radio" checked={plan === 1} name="plan" onChange={() => setPlan(1)} label="Professional" />
                <CustomInput id="plan-2" type="radio" checked={plan === 2} name="plan" onChange={() => setPlan(2)} label="Enterprise" />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <hr />
      <CardBody className={perMonth >= usage ? "" : "opacity-50"}>
        <Row>
          <Col className="d-flex align-items-baseline">
            <div className="mr-auto text-center">
              <div className={hsz}>₹{n(perMonth)}</div>
              <small>Monthly commitment</small>
            </div>
            <div className={msz}><FontAwesomeIcon size={fsz} icon="equals" /></div>
            <div className="text-center text-nowrap" style={{ width: "7rem" }}>
              <div className={`${hsz} text-right`}>₹{n(perMonth)}</div>
            </div>
          </Col>
        </Row>
      </CardBody>
      <hr />
      <CardBody className={perMonth < usage ? "" : "opacity-50"}>
        <Row>
          <Col className="d-flex align-items-baseline">
            <div className={`${msz} text-center`}>
              <div className={hsz}>{applicants}</div>
              <small>Applicants<br />per month</small>
            </div>
            <div className={msz}>
              <FontAwesomeIcon size={fsz} icon="times" />
            </div>
            <div className="mr-auto text-center">
              <div className={hsz}>₹{perApplicant}</div>
              <small>Per applicant</small>
            </div>
            <div className={msz}><FontAwesomeIcon size={fsz} icon="equals" /></div>
            <div className="text-center text-nowrap" style={{ width: "7rem" }}>
              <div className={`${hsz} text-right`}>₹{n(applicants * perApplicant)}</div>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex align-items-baseline">
            <div className={`${msz} text-center`}>
              <div className={hsz}>{applicants}</div>
              <small>Applicants<br />per month</small>
            </div>
            <div className={msz}>
              <FontAwesomeIcon size={fsz} icon="times" />
            </div>
            <div className={`${msz} text-center`}>
              <div className={hsz}>{assessments}</div>
              <small>Assessments<br />per applicant<br />(average)</small>
            </div>
            <div className={msz}>
              <FontAwesomeIcon size={fsz} icon="times" />
            </div>
            <div className="mr-auto text-center">
              <div className={hsz}>₹{perAssessment}</div>
              <small>Per assessment</small>
            </div>
            <div className={msz}><FontAwesomeIcon size={fsz} icon="equals" /></div>
            <div className="text-center text-nowrap" style={{ width: "7rem" }}>
              <div className={`${hsz} text-right`}>₹{n(applicants * assessments * perAssessment)}</div>
            </div>
          </Col>
        </Row>
      </CardBody>
      <hr />
      <CardBody>
        <Row>
          <Col className="d-flex align-items-baseline">
            <div className="small text-muted w-50">
              This calculator provides only an estimate of your charges and doesn't
              include any taxes that might apply. Your actual charges depend on a variety
              of factors, including your actual usage of HigherKnowledge services.
            </div>
            <div className="ml-auto text-right text-nowrap" style={{ width: "9rem" }}>
              <div className="h1">₹{n(Math.max(perMonth, usage))}</div>
              <small>Estimated cost<br />per month</small>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default () => {
  const [isOpen, toggle] = useToggle();
  useTitle("Pricing");
  return (
    <>
      <Row className="mt-3">
        <Col>
          <PricingCard toggle={toggle} />
        </Col>
      </Row>
      <Collapse isOpen={isOpen}>
        <Row className="mt-3">
          <Col>
            <EstimateCard />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
