import React, { useState } from "react";
import { Col, Row, Alert, Button, Nav, NavItem, NavLink } from "reactstrap";
import TaskCard, { Fragment as TaskFragment } from "../cards/TaskCard";
import WorkItemCard, { Fragment as WorkItemFragment } from "../cards/WorkItemCard";
import { useQuery, useMutation, DeepPartial } from "../hooks/ApiProvider";
import { useTitle } from "../hooks/TitleHook";
import { useTranslation, DateDisplayFormat } from "../hooks/TranslationProvider";
import { JobModel, WorkItemModel, ResourceState, TaskModel, TaskType } from "../types/api-graph-types";
import LegalResearchInputRow, { Fragment as JobFragment, isLegalResearch } from "../cards/Legal/LegalResearchInput";
import moment from "moment";
import { useNavigation, preventDefault } from "../hooks/NavigationHook";
import { useTimer } from "../hooks/TimerHook";
import { useSearch } from "../hooks/SearchProvider";
import CardColumns from "../components/CardColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardButtons, CardButton } from "../components/CardStatus";
import LoadingController from "./LoadingController";

const Fragment = `
  id
  kind
  state
  expiresOn
  actions { submit remove }
  workitem {
    id
    kind
    details { type }
    ${WorkItemFragment} 
  }
  order { 
    id 
    kind
    actions { change submit }
  }
  tasks(states:[PROPOSED,IN_PROGRESS,RESOLVED,COMPLETED]) {
    ${TaskFragment} 
  }
  ${JobFragment}  
`;

const _getJobDiscussion = `query getJobDiscussion($id:ID $type:TaskType $query:String $first:Int $excludeIds:[ID]) {
  job(id:$id) { 
    workitem {  
      tasks(states:[RESOLVED,COMPLETED] types:[$type] query:$query first:$first excludeIds:$excludeIds) {
        ${TaskFragment}
      }
    }
  } 
}`;

const _getJob = `query getJob($id:ID) {
  job(id:$id) { ${Fragment} }
}`;

const _submitJob = `mutation submitJob($id:ID!) {
  job(id:$id) { submit commit { ${Fragment} } } 
}`;

const _removeJob = `mutation removeJob($id:ID!) {
  job(id:$id) { remove commit { order { id kind } } }
}`;

const _skipJob = `mutation skipJob($id:ID! $oid:ID!) {
  job(id:$id) { remove commit { ${Fragment} } }
  order(id:$oid) { job: addJob { start commit { id } } }
}`;

const _createJob = `mutation createJob($oid:ID!) {
  order(id:$oid) { job: addJob { start commit { id } } }
}`;

interface Result {
  job: JobModel
}

interface OperationResults {
  job: { commit: JobModel }
  order: { job: { commit: JobModel } }
};

function Discuss({ value, onUpdate }: {
  value: JobModel,
  onUpdate: (value: DeepPartial<JobModel>) => void
}) {
  const [t] = useTranslation();
  const [vars, setVars] = useState({ first: 12, type: value.tasks.map(_ => _.type)[0] });
  const [search] = useSearch();
  const excludeIds = value.tasks.map(_ => _.id);
  const [result, updateResult, isLoading] = useQuery<Result>(_getJobDiscussion, { id: value.id, query: search, excludeIds, ...vars });
  const workitem = result?.job?.workitem;
  const types = Array.from(value.tasks.reduce((r, _) => r.add(_.type), new Set<TaskType>()));
  const getMore = (e: React.MouseEvent) => setVars({ ...vars, first: e.getModifierState("Shift") ? 1024 : vars.first * 2 });
  const updateTask = (task: TaskModel) => onUpdate({ tasks: [task] });
  const updateRelatedTask = (task: TaskModel) => updateResult({ job: { workitem: { tasks: [task] } } });

  const relatedTasks = workitem?.tasks?.filter(_ => _.type === vars.type) ?? [];
  const showRelated = relatedTasks?.length > 0;

  return (
    <>
      <Row className="mt-3">
        <Col>
          <Nav>
            {types && types.map((_, i) => (
              <NavItem key={i}>
                <NavLink active={_ === vars.type} href="#" onClick={() => setVars({ ...vars, type: _ })}>{t(_)}</NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
      </Row>
      <Row className="mt-3">
        <CardColumns md={2} xl={3}>
          {value.tasks.filter(_ => _.type === vars.type).map(_ =>
            <TaskCard key={_.id} className="mb-3" value={_} onUpdate={updateTask} showAttachments showStatus showDiscussion openDiscussion />
          )}
        </CardColumns>
      </Row>
      {showRelated && <h3 className="mt-3 d-block">Related submissions</h3>}
      {showRelated && relatedTasks &&
        <Row className="mt-3">
          <CardColumns md={2} xl={3}>
            {relatedTasks.map(_ =>
              <TaskCard key={_.id} className="mb-3" value={_} onUpdate={updateRelatedTask} hideRating showAttribution showAttachments showStatus showMenu showDiscussion openDiscussion />
            )}
          </CardColumns>
        </Row>
      }
      <Row className="mt-3">
        <Col>
          {isLoading && <FontAwesomeIcon className="text-muted" icon="circle-notch" spin />}
          {!isLoading && (workitem?.tasks?.length ?? 0) >= vars.first && <a href="#" onClick={preventDefault(getMore)}>See more</a>}
        </Col>
      </Row>
    </>
  );
}

export default ({ id }: { id: string }) => {
  const [t, d] = useTranslation();
  const [, go] = useNavigation();
  const [result, updateResult, isLoading] = useQuery<Result>(_getJob, { id });
  const [mutation] = useMutation<OperationResults>();
  useTimer(result?.job?.expiresOn);

  const value = result?.job;
  useTitle(value && t(value.workitem.details.type));
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const updateJob = (job: JobModel) => updateResult({ job });
  const updateWorkItem = (workitem: WorkItemModel) => updateResult({ job: { workitem } });
  const submitJob = async () => {
    const result = await mutation(_submitJob, { id: value.id });
    updateJob(result.job.commit);
  }
  const removeJob = async () => {
    const result = await mutation(_removeJob, { id: value.id });
    go(`/my/goals/${result.job.commit.order.id}`);
  }
  const skipJob = async () => {
    const result = await mutation(_skipJob, { id, oid: value.order.id })
    updateResult({ job: result.job.commit });
    result.order.job.commit.id
      ? go(`/my/tasks/${result.order.job.commit.id}`)
      : go(`/my/goals/${result.job.commit.order.id}`)
  }
  const nextJob = async () => {
    const result = await mutation(_createJob, { oid: value.order.id });
    go(`/my/tasks/${result.order.job.commit.id}`);
  }

  const isInProgress = value.state === ResourceState.Proposed || value.state === ResourceState.InProgress;
  const isResolved = value.state === ResourceState.Resolved;
  const isComplete = value.state === ResourceState.Resolved || value.state === ResourceState.Completed;
  const isExpiring = isInProgress && value.expiresOn && moment(value.expiresOn).isAfter() && moment(value.expiresOn).subtract(1, "h").isBefore() || false;
  const isExpired = isInProgress && value.expiresOn && moment(value.expiresOn).isBefore() || false;

  const canContinue = isComplete && value.order.actions.change;

  return (
    <>
      {isExpired ? (
        <Alert className="mt-3" color="danger">
          <h5>Time Expired!</h5>
          <p>
            The time limit for completing this task has expired.
            <a className="ml-1" href="#" onClick={removeJob}>Archive</a>
          </p>
        </Alert>
      ) : isExpiring ? (
        <Alert className="mt-3" color="warning">
          <h5>Expiring soon!</h5>
          <p>The time limit for completing this task will expire {d(value?.expiresOn, DateDisplayFormat.TimeUntil)}.</p>
        </Alert>
      ) : isResolved && canContinue ? (
        <Alert className="mt-3" color="primary">
          <h5>Under Review</h5>
          <p>
            Nice work! Your submission is being reviewed.
            In the meantime, feel free to comment on submissions made by other community members.
            <i className="mx-1">Your comments help us assess your teamwork and collaboration skills.</i>
            Remember to respect diverse viewpoints, be constructive, and follow our
            community <a href="/our/policies/code-of-conduct" target="_blank">Code of Conduct</a>.
          </p>
          <p>
            You can also continue on to your next assessment.
            <a className="ml-1" href="#" onClick={preventDefault(nextJob)}>Continue</a>
          </p>
        </Alert>
      ) : null
      }
      <Row className="mt-3">
        <Col>
          <WorkItemCard value={value.workitem} onUpdate={updateWorkItem} showDiscussion={isComplete} openDiscussion >
            <CardButtons>
              {value.actions.remove && <CardButton onClick={skipJob}>Skip</CardButton>}
            </CardButtons>
          </WorkItemCard>
        </Col>
      </Row>
      {isInProgress &&
        <>
          {isLegalResearch(value) && <LegalResearchInputRow disabled={isExpired} value={value} onUpdate={updateJob} onSubmit={submitJob} />}
        </>
      }
      {isComplete && <Discuss value={value} onUpdate={updateJob} />}
    </>
  )
};
