import React, { useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Collapse, CustomInput, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, InputGroupAddon, InputGroupButtonDropdown, InputGroupText, Row } from "reactstrap";
import ProductCard from "../cards/ProductCard";
import { CustomDropdownItem } from "../components/CustomDropdown";
import FormGroup from "../components/FormGroup";
import ValidatingInput from "../components/Input";
import { ProgressStep, ProgressTracker } from "../components/ProgressTracker";
import { byKey, useMutation } from "../hooks/ApiProvider";
import { useToggle, useUpdatableState } from "../hooks/CommonHooks";
import { preventDefault, useNavigation } from "../hooks/NavigationHook";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { getDefaultRule, New as NewProduct, NewInput as NewProductInput } from "../modals/ProductInputModal";
import { AreaType, CityCode, CountryCode, CurrencyCode, IndustryType, MoneyInputModel, OrderStage, OrganizationInputModel, OrganizationModel, ProductDetailsInputModel, ProductDetailsModel, ProductModel, ProductType, ServiceType, ResourceEvent } from "../types/api-graph-types";
import { CityCodes, ServiceTypes } from "../types/common-types";

const _createOrgProduct = `mutation createProduct($org:OrganizationInputModel $details:ProductDetailsInputModel) {
  organization: addOrganization {
    change(value:$org)
    commit { id }
    product: addProduct { 
      details { change(value:$details) }
      commit { id } 
    }
  }
}`;

interface OperationResults {
  organization: {
    commit: OrganizationModel,
    product: {
      commit: ProductModel
    }
  }
  product: {
    commit: ProductModel
  }
}

const _toProductDetailsModel = (value: ProductDetailsInputModel): ProductDetailsModel => ({
  key: value.key,
  manufacturerOrg: {} as OrganizationModel,
  type: value.type,
  subtype: value.subtype,
  title: value.title || "",
  subtitle: value.subtitle || "",
  displayTitle: `${value.title || ""} ${value.subtitle || ""}`.trim(),
  variantType: value.variantType,
  variantTitle: value.variantTitle || "",
  description: value.description || "",
  detailedDescription: value.detailedDescription || "",
  function: value.function,
  industry: value.industry,
  service: value.service || ServiceType.Null,
  areas: value.areas || [],
  languages: value.languages || [],
  duration: value.duration,
  location: {
    country: value.location?.country || CountryCode.Null,
    postalCode: value.location?.postalCode || "",
    region: value.location?.region || "",
    streetAddress: value.location?.streetAddress || "",
    city: value.location?.city || CityCode.Null,
    locality: value.location?.locality || "",
    neighborhood: value.location?.neighborhood || ""
  },
  compensation: {
    currency: CurrencyCode.Null,
    amount: 0,
    variableFraction: 0,
    annualAmount: 0,
    annualIncome: 0,
    period: "P365D",
    isGross: true,
    isPrivate: false,
    ...(value.compensation || {})
  },
  isPrivate: value.isPrivate || false,
  isLocked: value.isLocked || false,
  isUnclaimed: value.isUnclaimed || false,
  restartOn: value.restartOn || "",
  availableOn: value.availableOn || "",
  skipOrgDescription: value.skipOrgDescription || false,
  eduRatingDisplayMax: value.eduRatingDisplayMax || 10,
  requirementsDescription: value.requirementsDescription || "",
  minCustomersGraduationIn: value.minCustomersGraduationIn,
  maxCustomersGraduationIn: value.maxCustomersGraduationIn,
  ordersDescription: value.ordersDescription || "",
  ordersJobsWorkitemsRelatedIds: value.ordersJobsWorkitemsRelatedIds || [],
  ordersJobsWorkitemsByAreas: value.ordersJobsWorkitemsByAreas || false,
  ordersCustomerAttachmentReviews: value.ordersCustomerAttachmentReviews || false,
  ordersJobsCount: value.ordersJobsCount,
  ordersJobsMaxCount: value.ordersJobsMaxCount,
  ordersDueIn: value.ordersDueIn,
  ordersDueOn: value.ordersDueOn || "",
  ordersStages: value.ordersStages || [],
  ordersCustomerExternalIdMessage: value.ordersCustomerExternalIdMessage,
  supplierRecipients: value.supplierRecipients || "",
  supplierOriginAddresses: value.supplierOriginAddresses || "",
  rules: [], // FIXME: value.rules?.map(_toRuleModel).sort(_ruleSort) || [],
  messages: [],
  labels: value.labels || [],
  ordersDiscussionDescription: value.ordersDiscussionDescription || "",
  ordersDiscussionMessages: value.ordersDiscussionMessages || [],
  notificationPreferences: value.notificationPreferences || []
});

const _toOrganizationModel = (value: OrganizationInputModel): OrganizationModel => ({
  name: value.name,
  description: value.description,
  websiteUrl: value.websiteUrl
} as Partial<OrganizationModel> as any);

enum Template {
  Null = "NULL",
  CareerServices = "CAREER_SERVICES",
  TrainingLevelPosition = "TRAINING_LEVEL_POSITION",
  EntryLevelPosition = "ENTRY_LEVEL_POSITION",
  SeniorLevelPosition = "SENIOR_LEVEL_POSITION"
}

const _templates = new Map<Template, ProductDetailsInputModel>([
  [Template.CareerServices, {
    industry: IndustryType.LegalIndustry,
    type: ProductType.EmploymentEntryLevel,
    subtitle: "Campus Recruitment",
    isPrivate: true,
    ordersStages: [],
    notificationPreferences: [ResourceEvent.CustomerUpdatedResume],
    rules: [
      getDefaultRule("added"),
      getDefaultRule("created"),
    ]
  } as Partial<ProductDetailsInputModel> as any],
  [Template.TrainingLevelPosition, {
    industry: IndustryType.LegalIndustry,
    type: ProductType.EmploymentTrainingLevel,
    subtitle: "Internship",
    ordersStages: [OrderStage.Suggested, OrderStage.ScreeningScheduled],
    rules: [
      getDefaultRule("added"),
      getDefaultRule("created"),
      getDefaultRule("screening"),
      getDefaultRule("offered"),
      getDefaultRule("accepted"),
      getDefaultRule("delivered"),
      getDefaultRule("declined"),
      getDefaultRule("withdrawn"),
      getDefaultRule("refused"),
    ]
  } as Partial<ProductDetailsInputModel> as any],
  [Template.EntryLevelPosition, {
    industry: IndustryType.LegalIndustry,
    type: ProductType.EmploymentEntryLevel,
    subtitle: "Associate Position",
    ordersStages: [OrderStage.Suggested, OrderStage.ScreeningScheduled, OrderStage.InterviewScheduled],
    rules: [
      getDefaultRule("added"),
      getDefaultRule("created"),
      getDefaultRule("screening"),
      getDefaultRule("interviewing"),
      getDefaultRule("offered"),
      getDefaultRule("accepted"),
      getDefaultRule("delivered"),
      getDefaultRule("declined"),
      getDefaultRule("withdrawn"),
      getDefaultRule("refused"),
    ]
  } as Partial<ProductDetailsInputModel> as any],
  [Template.SeniorLevelPosition, {
    industry: IndustryType.LegalIndustry,
    type: ProductType.EmploymentSeniorLevel,
    subtitle: "Associate Position",
    ordersStages: [OrderStage.Suggested, OrderStage.ScreeningScheduled, OrderStage.InterviewScheduled],
    rules: [
      getDefaultRule("added"),
      getDefaultRule("created"),
      getDefaultRule("screening"),
      getDefaultRule("interviewing"),
      getDefaultRule("offered"),
      getDefaultRule("accepted"),
      getDefaultRule("delivered"),
      getDefaultRule("declined"),
      getDefaultRule("withdrawn"),
      getDefaultRule("refused"),
    ]
  } as Partial<ProductDetailsInputModel> as any],
]);

export default ({ }: {}) => {
  const [t, d, n] = useTranslation();
  const [step, _setStep] = useState(0);
  const [, go] = useNavigation();
  const [isOpenDetails, toggleDetails] = useToggle();
  const [isOpenAreas, toggleAreas] = useToggle();
  const [isOpenCity, toggleCity] = useToggle();
  const [isOpenCompensationCurrency, toggleCompensationCurrency] = useToggle();
  const [template, _setTemplate] = useState(Template.Null);
  const [input, setInput, updateInput] = useUpdatableState({} as ProductDetailsInputModel);
  const [orgInput, setOrgInput, updateOrgInput] = useUpdatableState({} as OrganizationInputModel);
  const [mutation] = useMutation<OperationResults>();
  const create = async () => {
    const result = await mutation(_createOrgProduct, { org: orgInput, details: input });
    const query = template === Template.CareerServices ? "#show=message&template=campus-recruiting" : `?stages=${OrderStage.Suggested}`;
    const id = result.organization?.product.commit.id ?? result.product?.commit.id;
    go(`/my/listings/${id}${query}`);
  }
  const updateCompensationInput = (compensation: Partial<MoneyInputModel>) => updateInput({ compensation: { ...input.compensation, ...compensation } })
  const setTemplate = (value: Template) => {
    _setTemplate(value);
    setInput({ ...NewProductInput, ..._templates.get(value) ?? {} as ProductDetailsInputModel });
  }
  const toggleArea = (value: AreaType) => {
    const areas = input.areas.includes(value) ? input.areas.filter(v => v !== value) : [...input.areas, value];
    updateInput({ areas });
  }
  const updateLocationInput = (locality: string) => {
    const city = CityCodes.filter((k: string) => t(k) === locality);
    updateInput({
      location: {
        ...input.location,
        locality: city.length ? "" : locality,
        city: city.length ? city[0] as CityCode : CityCode.Other
      }
    });
  }
  const toggleOrdersStages = (value: OrderStage) => {
    const has = input.ordersStages.includes(value);
    updateInput({ ordersStages: has ? input.ordersStages.filter(_ => _ != value) : [...input.ordersStages, value] });
  }
  const setOrgName = (name: string) => {
    updateOrgInput({ name });
    updateInput({ title: name });
  }
  const isValid = [template !== Template.Null, !!orgInput.name, !!input.description];
  const isAllValid = isValid.every(_ => _);
  const setStep = (value: number) => () => _setStep(value);
  const maxStep = isValid.length;
  const isCareerServices = template === Template.CareerServices;
  const functionPrefix = input.function?.replace("_FUNCTION", "");
  return (
    <>
      <ProgressTracker center >
        <ProgressStep onClick={setStep(0)} active={step === 0} complete={step > 0} label="Type" />
        <ProgressStep disabled={!isValid[0]} onClick={setStep(1)} active={step === 1} complete={step > 1} label={isCareerServices ? "College" : "Organization"} />
        <ProgressStep disabled={!isValid[1]} onClick={setStep(2)} active={step === 2} complete={step > 2} label="Details" />
        <ProgressStep disabled={!isValid[2]} onClick={setStep(3)} active={step === 3} label="Review" />
      </ProgressTracker>
      {step === 0 &&
        <Card>
          <CardBody>
            <CardTitle className="w-50" tag="h3">Create a new listing</CardTitle>
            <Form>
              <FormGroup label="Type" required>
                <CustomInput id="t-0" type="radio" checked={template === Template.CareerServices} onChange={() => setTemplate(Template.CareerServices)} label={<>Campus recruiting<span className="ml-1 small text-muted">help students in your college with internships and placements</span></>} />
                <CustomInput id="t-1" type="radio" checked={template === Template.TrainingLevelPosition} onChange={() => setTemplate(Template.TrainingLevelPosition)} label={<>Internship<span className="ml-1 small text-muted">hire for internship positions in your organization</span></>} />
                <CustomInput id="t-2" type="radio" checked={template === Template.EntryLevelPosition} onChange={() => setTemplate(Template.EntryLevelPosition)} label={<>Associate position<span className="ml-1 small text-muted">hire for associate level positions in your organization</span></>} />
                <CustomInput id="t-3" type="radio" checked={template === Template.SeniorLevelPosition} onChange={() => setTemplate(Template.SeniorLevelPosition)} label={<>Senior associate position<span className="ml-1 small text-muted">hire for senior associate level positions in your organization</span></>} />
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      }
      {step === 1 &&
        <Card>
          <CardBody>
            {isCareerServices && <CardTitle className="w-50" tag="h3">Details about your college</CardTitle>}
            {!isCareerServices && <CardTitle className="w-50" tag="h3">Details about your organization</CardTitle>}
            <Form>
              {isCareerServices ?
                <>
                  <FormGroup label="Collete name" required>
                    <ValidatingInput value={orgInput.name ?? ""} onUpdate={setOrgName} placeholder="Enter your college name here" />
                  </FormGroup>
                  <FormGroup label="College description" showPreview>
                    <ValidatingInput type="textarea" rows={5} value={orgInput.description ?? ""} onUpdate={description => updateOrgInput({ description })} placeholder=" Enter your college description here" />
                  </FormGroup>
                  <FormGroup label="College website">
                    <ValidatingInput value={orgInput.websiteUrl ?? ""} onUpdate={websiteUrl => updateOrgInput({ websiteUrl })} placeholder="Enter your college website here" />
                  </FormGroup>
                </> : <>
                  <FormGroup label="Organization name" required>
                    <ValidatingInput value={orgInput.name ?? ""} onUpdate={setOrgName} placeholder="Enter your organization name here" />
                  </FormGroup>
                  <FormGroup label="Organization description" showPreview>
                    <ValidatingInput type="textarea" rows={5} value={orgInput.description ?? ""} onUpdate={description => updateOrgInput({ description })} placeholder=" Enter your organization description here" />
                  </FormGroup>
                  <FormGroup label="Organization website">
                    <ValidatingInput value={orgInput.websiteUrl ?? ""} onUpdate={websiteUrl => updateOrgInput({ websiteUrl })} placeholder="Enter your organization website here" />
                  </FormGroup>
                </>
              }
            </Form>
          </CardBody>
        </Card>
      }
      {step === 2 &&
        <Card>
          <CardBody>
            {isCareerServices && <CardTitle className="w-50" tag="h3">Details about the process</CardTitle>}
            {!isCareerServices && <CardTitle className="w-50" tag="h3">Details about the position</CardTitle>}
            <Form>
              {isCareerServices ?
                <>
                  <FormGroup label="Description" required showPreview>
                    <ValidatingInput type="textarea" rows={5} value={input.description ?? ""} onUpdate={description => updateInput({ description })} placeholder="Enter your campus recruitment committee description here" />
                  </FormGroup>
                </> : <>
                  <FormGroup label="Description" required showPreview>
                    <ValidatingInput type="textarea" rows={5} value={input.description ?? ""} onUpdate={description => updateInput({ description })} placeholder="Enter the job description here" />
                  </FormGroup>
                  {input.type !== ProductType.EmploymentTrainingLevel &&
                    <FormGroup label='Experience'>
                      <select className="custom-select" value={`${input.minCustomersGraduationIn || ""},${input.maxCustomersGraduationIn || ""}`} onChange={e => updateInput({ minCustomersGraduationIn: e.currentTarget.value.split(",")[0], maxCustomersGraduationIn: e.currentTarget.value.split(",")[1] })}>
                        <option value=","></option>
                        {["-P365D,", "-P730D,", "-P730D,-P365D", "-P1095D,-P730D", "-P1825D,-P1095D", ",-P1825D"].map((_, i) =>
                          <option key={i} value={_}>{d(_.split(",")[0], DateDisplayFormat.NegativeYearDurationRange, _.split(",")[1])}</option>
                        )}
                      </select>
                    </FormGroup>
                  }
                  {input.type === ProductType.EmploymentTrainingLevel &&
                    <FormGroup label='Graduation'>
                      <select className="custom-select" value={`${input.minCustomersGraduationIn || ""},${input.maxCustomersGraduationIn || ""}`} onChange={e => updateInput({ minCustomersGraduationIn: e.currentTarget.value.split(",")[0], maxCustomersGraduationIn: e.currentTarget.value.split(",")[1] })}>
                        <option value=","></option>
                        {[",P365D", ",P730D", ",P1095D"].map((_, i) =>
                          <option key={i} value={_}>{d(_.split(",")[0], DateDisplayFormat.YearDurationRangeIn, _.split(",")[1])}</option>
                        )}
                      </select>
                    </FormGroup>
                  }
                  <FormGroup label='Requirements' value={input.requirementsDescription} showPreview>
                    <ValidatingInput type="textarea" rows={5} placeholder="Provide any additional requirements here" onUpdate={requirementsDescription => updateInput({ requirementsDescription })} />
                  </FormGroup>
                  <FormGroup label="Options" className="mt-3">
                    <CustomInput id="phonescreen-switch" type="switch" checked={input.ordersStages.includes(OrderStage.ScreeningScheduled)} onChange={() => toggleOrdersStages(OrderStage.ScreeningScheduled)} label="Phone screen" />
                    <CustomInput id="interview-switch" type="switch" checked={input.ordersStages.includes(OrderStage.InterviewScheduled)} onChange={() => toggleOrdersStages(OrderStage.InterviewScheduled)} label="In-person interview" />
                  </FormGroup>
                  {!isOpenDetails && <a href="#" onClick={preventDefault(toggleDetails)}>See more</a>}
                  <Collapse isOpen={isOpenDetails}>
                    <Row form>
                      <Col>
                        <FormGroup label='Address' maxLength={140} value={input.location.streetAddress ?? ""}>
                          <ValidatingInput type="text" onUpdate={streetAddress => updateInput({ location: { ...input.location, streetAddress } })} />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup label='Neighborhood' value={input.location.neighborhood ?? ""}>
                          <ValidatingInput type="text" maxLength={140} onUpdate={neighborhood => updateInput({ location: { ...input.location, neighborhood } })} />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup label='City'>
                          <InputGroup>
                            <Input value={input.location.city && input.location.city !== CityCode.Other ? t(input.location.city) : input.location.locality ?? ""} onChange={e => updateLocationInput(e.currentTarget.value)} />
                            <Dropdown className="btn-input" isOpen={isOpenCity} toggle={toggleCity}>
                              <DropdownToggle color="input" caret />
                              <DropdownMenu>
                                {CityCodes.sort(byKey(t)).filter(_ => _ && _ != CityCode.Other).map(_ =>
                                  <DropdownItem key={_} onClick={() => updateLocationInput(t(_))}>{t(_)}</DropdownItem>
                                )}
                              </DropdownMenu>
                            </Dropdown>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col sm={4}>
                        <FormGroup label='Industry' required value={input.industry}>
                          <select className="custom-select" value={input.industry} onChange={e => updateInput({ industry: e.currentTarget.value as IndustryType })}>
                            {Object.values(IndustryType).map(k => <option key={k} value={k}>{t(k)}</option>)}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup label='Service'>
                          <select className="custom-select" value={input.service} onChange={e => updateInput({ service: e.currentTarget.value as ServiceType })}>
                            {ServiceTypes.sort(byKey(t)).filter((k: string) => k.startsWith(functionPrefix) || k === ServiceType.Null).map(k => <option key={k} value={k}>{t(k)}</option>)}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup label='Areas'>
                          <Dropdown className="w-100 d-flex btn-input p-1px" isOpen={isOpenAreas} toggle={toggleAreas}>
                            <Button className="w-100 btn-label text-left text-nowrap text-truncate" color="input" onClick={toggleAreas}>
                              <span>
                                {input.areas?.length == 1 && t(input.areas[0])}
                                {input.areas?.length > 1 && `${input.areas.length} areas`}
                              </span>
                            </Button>
                            <DropdownToggle color="input" caret />
                            <DropdownMenu className="columns-2">
                              {Object.values(AreaType)
                                .filter((k: string) => k.startsWith(functionPrefix))
                                .map(k => <CustomDropdownItem key={k} disabled={input.areas?.length >= 3 && !input.areas.includes(k)} checked={input.areas?.includes(k) ?? false} value={k} onClick={() => toggleArea(k)}>{t(k)}</CustomDropdownItem>)}
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col>
                        <FormGroup label='Compensation'>
                          <InputGroup>
                            <InputGroupButtonDropdown addonType="prepend" isOpen={isOpenCompensationCurrency} toggle={toggleCompensationCurrency}>
                              <DropdownToggle color="input" caret>{t(input.compensation.currency)}</DropdownToggle>
                              <DropdownMenu>
                                {[CurrencyCode.Null, CurrencyCode.Inr].map((_, i) =>
                                  <DropdownItem key={i} onClick={() => updateCompensationInput({ currency: _ })}>{t(_)}</DropdownItem>
                                )}
                              </DropdownMenu>
                            </InputGroupButtonDropdown>
                            <Input className="pl-0" value={input.compensation.amount && n(input.compensation.amount) || ""} placeholder="Enter gross compensation" onChange={e => updateCompensationInput({ amount: parseInt(e.currentTarget.value.replace(/[^0-9]/g, "")), currency: input.compensation.currency === CurrencyCode.Null ? CurrencyCode.Inr : input.compensation.currency })} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup label="Variable">
                          <InputGroup>
                            <Input value={input.compensation.amount && input.compensation.variableFraction || ""} disabled={!input.compensation.amount} onChange={e => updateCompensationInput({ variableFraction: parseInt(e.currentTarget.value.replace(/[^0-9]/g, "")) })} />
                            <InputGroupAddon addonType="append"><InputGroupText>%</InputGroupText></InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup label="Period">
                          <select className="custom-select" disabled={!input.compensation.amount} value={input.compensation.amount ? input.compensation.period : ""} onChange={e => updateCompensationInput({ period: e.currentTarget.value })}>
                            <option value=""></option>
                            <option value="P30D">per month</option>
                            <option value="P365D">per year</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Collapse>
                </>
              }
            </Form>
          </CardBody>
        </Card>
      }
      {step === 3 &&
        <ProductCard value={{ ...NewProduct, ...{ details: _toProductDetailsModel(input), supplier: _toOrganizationModel(orgInput) } }} />
      }
      <div className="mt-3">
        {step === maxStep && <Button color="primary" disabled={!isAllValid} onClick={create}>Create</Button>}
        {step !== maxStep && <Button color="primary" disabled={!isValid[step]} onClick={() => _setStep(step + 1)}>Next</Button>}
        {step !== 0 && <Button color="link" onClick={() => _setStep(step - 1)}>Previous</Button>}
      </div>
    </>
  );
}
