import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Collapse, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledDropdown } from "reactstrap";
import { Anonymous, UserThumbnail, UserThumbnailFragment } from "../cards/PersonCard";
import { CardBadge, CardButton, CardButtons } from "../components/CardStatus";
import { CustomDropdownItem } from "../components/CustomDropdown";
import DatalistInput from "../components/DatalistInput";
import FormGroup, { isEmail, isPhone } from "../components/FormGroup";
import MutationButton from "../components/MutationButton";
import { byKey, nextNonce, nextSequence, useMutation, useReadyState } from "../hooks/ApiProvider";
import { useToggle } from "../hooks/CommonHooks";
import { useConfirmation } from "../hooks/ConfirmationProvider";
import { preventDefault } from "../hooks/NavigationHook";
import { useToasts } from "../hooks/ToastsProvider";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { AttachmentInputModel, AttachmentModel, AttachmentType, MessageInputModel, MessageModel, PersonModel, ProductModel, ReferenceDataModel, ReferenceDataType, ResourceEvent, ResourceKind, ResourceRole, ResourceState, ShareInputModel, ShareModel } from "../types/api-graph-types";

library.add(faTimes, faPlus);

export const SubjectFragment = `id kind name email ${UserThumbnailFragment}`;


export const Fragment = `
  id
  kind
  createdOn
  role
  state  
  subject { ${SubjectFragment} }
  subjectProduct { 
    id 
    kind 
    details { title subtitle }
    owner { ${UserThumbnailFragment} }
    organization { logo } 
  }
  events { event notifiedOn } 
`;

const _deprecatedShareMyResource = new Map<ResourceKind, string>([
  [ResourceKind.Product, `mutation deprecatedShareProduct($id:ID! $values:[ShareInputModel]) {
      product(id:$id) { shares { update(values:$values) { ${Fragment} } } }
  }`],
  [ResourceKind.WorkItem, `mutation shareWorkItem($id:ID! $values:[ShareInputModel]) {
      workitem(id:$id) { shares { update(values:$values) { ${Fragment} } } }
  }`]
]);

const _sharesFragment = `shares: addShares(usernames:$usernames role:$role state:$state) { 
  commit { ${Fragment} }
  sendMessages(title:$title url:$url value:$message) @skip(if:$skipMessages)
  upgrade @include(if:$isPrimary)
}`;

const _shareMyResource = new Map<ResourceKind, string>([
  [ResourceKind.Product, `mutation shareProduct($id:ID! $usernames:String $role:ResourceRole $state:ResourceState $message:MessageInputModel $title:String $url:String $isPrimary:Boolean! $skipMessages:Boolean!) {
      resource: product(id:$id) { ${_sharesFragment} }
  }`],
  [ResourceKind.Order, `mutation shareOrder($id:ID! $usernames:String $role:ResourceRole $state:ResourceState $message:MessageInputModel $title:String $url:String $isPrimary:Boolean! $skipMessages:Boolean!) {
      resource: order(id:$id) { ${_sharesFragment} }
  }`],
  [ResourceKind.WorkItem, `mutation shareWorkitem($id:ID! $usernames:String $role:ResourceRole $state:ResourceState $message:MessageInputModel $title:String $url:String $isPrimary:Boolean! $skipMessages:Boolean!) {
      resource: workitem(id:$id) { ${_sharesFragment} }
  }`],
  [ResourceKind.AccountSubscription, `mutation shareAccountSubscription($id:ID! $usernames:String $role:ResourceRole $state:ResourceState $message:MessageInputModel $title:String $url:String $isPrimary:Boolean! $skipMessages:Boolean!) {
      resource: subscription(id:$id) { ${_sharesFragment} }
  }`]
]);

const _saveMessages = new Map<ResourceKind, string>([
  [ResourceKind.Product, `mutation saveProductMessages($id:ID $values:[MessageInputModel]) {
    product(id:$id) {
      details { change(value:{messages:$values}) }
      commit { id details { messages { key name subject content shortContent attachments { key filename url token } } } }
    }
  }`]
]);

interface OperationResults {
  resource: {
    shares: {
      commit: ReadonlyArray<ShareModel>
    }
  }
  product: {
    commit: Readonly<ProductModel>,
    shares: {
      update: ReadonlyArray<ShareModel>
    }
    workitem: {
      shares: {
        update: ReadonlyArray<ShareModel>
      }
    }
  }
};

interface ResourceModel {
  readonly id: string;
  readonly kind: ResourceKind
}

enum _Role {
  Recruiter = "RECRUITER",
  PrimaryOwner = "PRIMARY_OWNER"
}

const _newAttachment = {} as AttachmentInputModel;

function _toAttachmentInput(value: AttachmentModel): AttachmentInputModel {
  return ({
    ..._newAttachment,
    key: value.key || "",
    filename: value.filename || "",
    token: value.token || ""
  });
}

function _toTemplateInput(value: Partial<MessageModel>): MessageInputModel {
  return ({
    key: value.key || "",
    name: value.name || "",
    isDraft: value.isDraft || false,
    bccSender: value.bccSender || false,
    recipientIds: [],
    recipientEmails: "",
    recipientPhones: "",
    recipientUris: [],
    subject: value.subject || "",
    content: value.content || "",
    shortContent: value.shortContent || "",
    attachments: value.attachments?.map(_toAttachmentInput) || []
  });
}

function _toShareInputs(values: ReadonlyArray<ShareModel>) {
  return (values || []).map(_ => ({
    subjectId: _.subject?.id ?? _.subjectProduct?.id,
    subjectKind: _.subject ? ResourceKind.Person : _.subjectProduct ? ResourceKind.Product : ResourceKind.Null,
    role: _.role
  } as ShareInputModel));
}

export function MessageModal({ value, title, url, recruiterUrl, showTemplates, templates = [], onUpdateTemplates, isOpen, toggle }: {
  value: ResourceModel,
  title?: string,
  url: string,
  recruiterUrl?: string,
  showTemplates?: boolean,
  templates?: ReadonlyArray<MessageModel>,
  onUpdateTemplates?: (values: ReadonlyArray<MessageModel>) => void,
  isOpen?: boolean,
  toggle?: () => void
}) {
  const confirmation = useConfirmation();
  const [uid] = useState(nextSequence());
  const [, setToast] = useToasts();
  const [readyState, setReadyState] = useReadyState();
  const [role, setRole] = useState<ResourceRole | _Role>(ResourceRole.Contributor);
  const [usernames, setUsernames] = useState("");
  const [subject, setSubject] = useState(title);
  const [content, setContent] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [attachments, setAttachments] = useState<AttachmentInputModel[]>([]);
  const [isOpenAttachments, toggleAttachments] = useToggle();
  const [mutation] = useMutation<OperationResults>();
  const cancel = () => {
    setRole(ResourceRole.Contributor);
    setUsernames("");
    setSubject(title);
    setContent("");
    setFiles([]);
    setAttachments([]);
    toggleAttachments(false);
    toggle?.();
  }
  const share = async (skipMessages: boolean) => {
    const isPrimary = role === _Role.PrimaryOwner;
    const roleVars = role === _Role.Recruiter ? { state: ResourceState.Proposed, role: ResourceRole.Reader, url: recruiterUrl }
      : role === _Role.PrimaryOwner ? { state: ResourceState.Completed, role: ResourceRole.Owner, url }
        : { state: ResourceState.Completed, role, url };
    const message = {
      subject,
      content,
      attachments: role === _Role.Recruiter ? attachments : []
    } as Partial<MessageInputModel>;
    await mutation(_shareMyResource.get(value.kind) || "", { id: value.id, usernames, title, message, isPrimary, skipMessages, ...roleVars }, { setReadyState, files });
    setToast({ message: "Share added" }, 3000);
    cancel();
  };
  const send = () => share(false);
  const add = () => share(true);
  const updateAttachment = (index: number, props: Partial<AttachmentInputModel>) => {
    let values: ReadonlyArray<AttachmentInputModel>;
    if (index < attachments.length) {
      values = attachments.map((_, i) => i != index ? _ : { ..._, ...props });
    } else {
      values = [...attachments, { ..._newAttachment, ...props }];
    }
    setAttachments(values.filter(_ => !!_.type && _.state !== ResourceState.Removed));
  }
  const addAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filename = e.target.files?.[0]?.name || "";
    if (filename) {
      updateAttachment(attachments.length, { type: AttachmentType.Other, content: filename, filename });
      setFiles([...files, ...Array.from(e.target.files || [])]);
    }
  }
  const openFile = (filename: string) => {
    const reader = new FileReader();
    const file = files.filter(f => f.name === filename)[0];
    if (file) {
      reader.addEventListener("load", () => {
        if (typeof (reader.result) === "string") {
          var a = document.createElement('a');
          a.setAttribute('href', reader.result);
          a.setAttribute('download', filename);
          a.click();
        }
      });
      reader.readAsDataURL(file);
    }
  }
  const changeTemplates = async (values: MessageInputModel[], files?: File[]) => {
    const query = _saveMessages.get(value.kind ?? ResourceKind.Null) ?? "";
    const id = value.id ?? "";
    const results = await mutation(query, { id, values }, { files });
    onUpdateTemplates?.(results.product.commit.details.messages);
  }
  const saveTemplate = async (key: string, name: string) => {
    await changeTemplates([
      { ..._toTemplateInput({ key, name, subject, content }), attachments },
      ...templates.filter(_ => _.key !== key).map(_toTemplateInput)
    ], files);
  }
  const setTemplate = (value: MessageModel) => {
    value.subject && setSubject(value.subject);
    setContent(value.content);
    if (!!value.attachments?.length) {
      setAttachments(value.attachments.map(_toAttachmentInput));
      toggleAttachments(true);
    }
  };
  const removeTemplate = async (key: string) => {
    await changeTemplates(templates.filter(_ => _.key !== key).map(_toTemplateInput));
  };
  const isValid = !!usernames && usernames.split(",").every(isEmail);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Share {title}</ModalHeader>
      <ModalBody>
        <FormGroup label="To" help="Separate multiple emails with commas." value={usernames} emails>
          <Input type="text" placeholder="Enter email addresses" value={usernames} onChange={e => setUsernames(e.currentTarget.value)} />
        </FormGroup>
        <FormGroup label="Role">
          {recruiterUrl && <CustomInput id="recruiter" type="radio" checked={role === _Role.Recruiter} onChange={() => setRole(_Role.Recruiter)} label={<>Recruiter<span className="ml-1 small text-muted">can source candidates from this listing</span></>} />}
          <CustomInput id="reader" type="radio" checked={role === ResourceRole.Reader} onChange={() => setRole(ResourceRole.Reader)} label={<>Reader<span className="ml-1 small text-muted">can view all candidate details and feedback</span></>} />
          <CustomInput id="contributor" type="radio" checked={role === ResourceRole.Contributor} onChange={() => setRole(ResourceRole.Contributor)} label={<>Contributor<span className="ml-1 small text-muted">can additionally add, remove or modify candidates</span></>} />
          <CustomInput id="owner" type="radio" checked={role === ResourceRole.Owner} onChange={() => setRole(ResourceRole.Owner)} label={<>Owner<span className="ml-1 small text-muted">can additionally share or unshare this listing with others</span></>} />
          <CustomInput id="primary-owner" type="radio" checked={role === _Role.PrimaryOwner} onChange={() => setRole(_Role.PrimaryOwner)} label={<>Primary owner<span className="ml-1 small text-muted">has full control and cannot be removed</span></>} />
        </FormGroup>
        <FormGroup label="Subject">
          <Input placeholder="Enter the message subject here" value={subject} onChange={e => setSubject(e.currentTarget.value)} />
        </FormGroup>
        <FormGroup label="Message">
          <Input type="textarea" style={{ height: "10rem" }} placeholder="Enter the message body here" value={content} onChange={e => setContent(e.currentTarget.value)} />
        </FormGroup>
        <Collapse isOpen={role === _Role.Recruiter}>
          <FormGroup>
            <CustomInput id={`${uid}.0`} type="switch" disabled={attachments.length > 0} label="Send an attachment" checked={isOpenAttachments} onChange={toggleAttachments} />
          </FormGroup>
          <Collapse isOpen={isOpenAttachments}>
            {attachments?.length > 0 &&
              <ul className="list-unstyled mb-0">
                {attachments?.map((_, i) =>
                  <li key={i} className="d-flex align-items-baseline">
                    <FontAwesomeIcon className="mr-1" icon="paperclip" />
                    {_.state !== ResourceState.Removed && _.key && <a href={templates.flatMap(_ => _.attachments).filter(a => a.key === _.key)[0]?.url} target="_blank">{_.filename}</a>}
                    {_.state !== ResourceState.Removed && !_.key && <a href="#" onClick={preventDefault(() => openFile(_.filename))} target="_blank">{_.filename}</a>}
                    {_.state === ResourceState.Removed && <del>{_.filename}</del>}
                    <Button color="link" size="sm" className="ml-auto" onClick={() => updateAttachment(i, { state: ResourceState.Removed })}>Remove</Button>
                  </li>
                )}
              </ul>
            }
            <InputGroup>
              <div className='custom-file'>
                <Input type="file" className={`custom-file-input`} onChange={addAttachment} />
                <label className="custom-file-label text-truncate text-nowrap text-muted">Select your file here</label>
              </div>
            </InputGroup>
            {attachments.reduce((size, _) => size + (templates.flatMap(a => a.attachments).filter(a => a.key === _.key)[0]?.length ?? files.filter(f => f.name === _.filename)[0]?.size ?? 0), 0) > 7 * 1024 * 1024 &&
              <div className="mt-2 text-muted small">
                <FontAwesomeIcon size="sm" className="mr-1 align-baseline" icon="exclamation-triangle" />
                Attachments too large to send in an email will be sent via the cloud and remain accessible for thirty days.
              </div>
            }
          </Collapse>
        </Collapse>
      </ModalBody>
      <ModalFooter>
        {showTemplates &&
          <UncontrolledDropdown direction="up">
            <DropdownToggle color="link">Templates</DropdownToggle>
            <DropdownMenu>
              {templates.filter(_ => !!_.subject || !!_.content).map((_, i) =>
                <CustomDropdownItem key={i} showRemove onClick={() => setTemplate(_)} onRemove={confirmation(() => removeTemplate(_.key), `This will remove template "${_.name}".`, { title: "Remove template?", label: "Remove" })}
                  append={<a className="ml-3 text-primary cursor-pointer hover-visible" onClick={preventDefault(confirmation(() => saveTemplate(_.key, _.name), `This will overwrite template "${_.name}".`, { title: "Overwrite template?", label: "Save" }))}><FontAwesomeIcon icon={["far", "save"]} /></a>}>
                  {_.name || _.subject.substr(0, 10)}
                </CustomDropdownItem>
              )}
              {templates.filter(_ => !!_.subject || !!_.content).length > 0 && <DropdownItem divider />}
              <DropdownItem onClick={confirmation(name => saveTemplate(nextNonce(), name), "", { showInput: true, title: <h5>Enter a new template name</h5> })}>Save as new template</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        }
        <Button className="ml-auto" color="link" onClick={cancel}>Cancel</Button>
        <CardButtons readyState={readyState} right>
          <CardButton disabled={!isValid} onClick={send}>Send</CardButton>
          {role !== _Role.Recruiter && <CardButton disabled={!isValid} onClick={add}>Share without sending</CardButton>}
        </CardButtons>
      </ModalFooter>
    </Modal>
  );
}

export function SharesInputTable({ values, owner, onUpdate }: {
  values: ReadonlyArray<ShareModel>,
  owner?: PersonModel,
  onUpdate: (values: ShareInputModel[]) => void
}) {
  const [t, d] = useTranslation();
  const [uid] = useState(nextSequence());
  const [_page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const [role, setRole] = useState(ResourceRole.Reader);
  const [persons] = useState(new Map<string, PersonModel>(values?.map(_ => _.subject).filter(_ => !!_).map(_ => [_.id, _] as [string, PersonModel])));
  const [products] = useState(new Map<string, ProductModel>(values?.map(_ => _.subjectProduct).filter(_ => !!_).map(_ => [_.id, _] as [string, ProductModel])));
  const [input, setInput] = useState<ShareInputModel[]>(_toShareInputs(values).sort(byKey(_ => persons.get(_.subjectId || "")?.name ?? products.get(_.subjectId || "")?.details.title ?? "")));
  const updateInput = (input: ShareInputModel[]) => {
    setInput(input);
    onUpdate(input);
  }
  const updateItem = (props: Partial<ShareInputModel>) => {
    updateInput([
      ...input.map(_ => (_.subjectId && _.subjectId === props.subjectId || false) || (_.subjectExternalId && _.subjectExternalId === props.subjectExternalId || false) ? { ..._, ...props } : _),
      ...(input.findIndex(_ => (_.subjectId && _.subjectId === props.subjectId || false) || (_.subjectExternalId && _.subjectExternalId === props.subjectExternalId || false)) === -1 ? [props as any] : [])
    ]);
  }
  const setPerson = (_value: string | undefined, query: string, data: ReferenceDataModel | undefined) => {
    if (_value) {
      setQuery("");
      data?.person && persons.set(_value, data.person);
      updateItem({ subjectId: _value, role: ResourceRole.Reader });
    } else {
      setQuery(query);
    }
  };

  const isDisabled = !isEmail(query) && !isPhone(query);
  const add = () => {
    updateItem({ subjectExternalId: query, role });
    setQuery("");
  };

  const proposed = values?.filter(_ => _.state === ResourceState.Proposed).map(_ => _.subject?.id) || [];
  const filteredInput = !query ? input : input.filter(_ => (persons.get(_.subjectId)?.name || products.get(_.subjectId)?.details.title || "").toLowerCase().indexOf(query.toLowerCase()) != -1);
  const sortedInput = filteredInput.sort(byKey(_ =>
    _.role === ResourceRole.Owner ? 0
      : _.role === ResourceRole.Contributor ? 1
        : _.role === ResourceRole.Reader && _.subjectKind === ResourceKind.Person && !proposed.includes(_.subjectId) ? 2
          : !proposed.includes(_.subjectId) ? 3
            : 4));
  const lastPage = Math.floor(sortedInput.length / 10);
  const page = Math.min(lastPage, _page);
  const inputPage = sortedInput.slice(page * 10, (page + 1) * 10);

  return (
    <>
      {(query || lastPage > 0) &&
        <Row className="bt-1">
          <Col className="d-flex px-3 py-2">
            <Input value={query} onChange={e => setQuery(e.currentTarget.value)} />
            <div className="text-nowrap">
              <Button color="link" disabled={page === 0} onClick={() => setPage(page - 1)}>
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
              {(page + 1)} of {lastPage + 1}
              <Button color="link" disabled={page === lastPage} onClick={() => setPage(page + 1)}>
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
            </div>
          </Col>
        </Row>
      }
      {owner && page == 0 &&
        <>
          <Row className="bt-1"><Col className="px-3 py-2"><b>Primary owner</b></Col></Row>
          <Row className="bt-1"><Col className="d-flex px-3 py-2">
            <UserThumbnail className="mr-3" width={32} value={owner} />
            <div className="d-flex flex-column">
              <span className="text-capitalize">{owner.name}</span>
              <span className="small text-muted">{owner.email}</span>
            </div>
          </Col></Row>
        </>
      }
      {inputPage.flatMap((_, i) => {
        const value = values.filter(v => _.subjectId === (v.subject?.id ?? v.subjectProduct?.id))[0] || {};
        const person = persons.get(_.subjectId || "");
        const product = products.get(_.subjectId || "");
        const isPerson = _.subjectKind === ResourceKind.Person;
        const isProduct = _.subjectKind === ResourceKind.Product;
        const isRecruiter = isProduct || proposed.includes(_.subjectId);
        const prevIsRecruiter = i > 0 && (inputPage[i - 1].subjectKind === ResourceKind.Product || proposed.includes(inputPage[i - 1].subjectId));
        const showHeader = i == 0
          || inputPage[i - 1].role != _.role
          || isRecruiter !== prevIsRecruiter;
        const notifiedOn = value.events?.filter(e => e.event === ResourceEvent.SupplierProposedShare).map(e => e.notifiedOn)[0] || value.createdOn;
        return [showHeader &&
          <Row key={`h${i}`} className="bt-1">
            <Col className="px-3 py-2">
              {_.role === ResourceRole.Owner && <b>Owners</b>}
              {_.role === ResourceRole.Contributor && <b>Contributors</b>}
              {_.role === ResourceRole.Reader && !isRecruiter && <b>Readers</b>}
              {_.role === ResourceRole.Reader && isRecruiter && <b>Recruiters</b>}
            </Col>
          </Row>,
        <Row key={i} className="bt-1">
          <Col className="d-flex px-3 py-2">
            {isPerson &&
              <>
                <UserThumbnail className="mr-3" width={32} value={person || Anonymous} />
                <div className="d-flex flex-column w-100">
                  <div>
                    {person && <span className="text-capitalize">{person.name}</span>}
                    {isRecruiter && value.state === ResourceState.Proposed &&
                      <CardBadge className="ml-1" size="sm" color="light" value="Pending" />
                    }
                  </div>
                  {person &&
                    <span className="small text-muted">
                      {person.email}
                      {isRecruiter && value.state === ResourceState.Proposed &&
                        <span className="ml-1">invited {d(notifiedOn, DateDisplayFormat.TimeAgo)}</span>
                      }
                    </span>
                  }
                  {!person && <span>{_.subjectExternalId}</span>}
                </div>
              </>
            }
            {isProduct &&
              <>
                {product?.details?.manufacturerOrg?.logo
                  ? <img src={product.details.manufacturerOrg.logo} width={32} height={32} className="mr-3 align-baseline" />
                  : <UserThumbnail className="mr-3" width={32} value={product?.owner || Anonymous} />}
                <div className="d-flex flex-column w-100">
                  <span className="">{product?.details.title}</span>
                  <span className="small text-muted">{product?.details.subtitle}</span>
                </div>
              </>
            }
            {isPerson && !isRecruiter &&
              <CustomInput id={`${uid}.0.${i}`} type="select" style={{ width: "12rem" }} value={_.role} onChange={e => updateItem({ ..._, role: e.currentTarget.value as ResourceRole })}>
                <option value={ResourceRole.Contributor}>{t(ResourceRole.Contributor)}</option>
                <option value={ResourceRole.Reader}>{t(ResourceRole.Reader)}</option>
                <option value={ResourceRole.Owner}>{t(ResourceRole.Owner)}</option>
              </CustomInput>
            }
            <Button size="sm" color="link" onClick={() => updateInput(input.filter(v => v.subjectId != _.subjectId))}>Remove</Button>
          </Col>
        </Row>
        ]
      })}
      {false &&
        <tr>
          <td>
            <DatalistInput id="nav-orgname" types={[ReferenceDataType.Product]} className="form-control" placeholder="Enter email or phone" value={query} onUpdate={setPerson} />
          </td>
          <td>
            <CustomInput id={`${uid}.1`} type="select" value={role} onChange={e => setRole(e.currentTarget.value as ResourceRole)}>
              <option value={ResourceRole.Contributor}>{t(ResourceRole.Contributor)}</option>
              <option value={ResourceRole.Reader}>{t(ResourceRole.Reader)}</option>
              <option value={ResourceRole.Owner}>{t(ResourceRole.Owner)}</option>
            </CustomInput>
          </td>
          <td>
            <Button color="link" disabled={isDisabled} className="w-100" onClick={add}>Add</Button>
          </td>
        </tr>
      }
    </>
  );
}



export default ({ kind, id, title, url, values, isOpen, toggle }: {
  kind: ResourceKind,
  id: string,
  title?: string,
  isOpen?: boolean,
  toggle?: () => void,
  values: ReadonlyArray<ShareModel>,
  url: string
}) => {
  const [t] = useTranslation();
  const [uid] = useState(nextSequence());
  const [query, setQuery] = useState("");
  const [readyState, setReadyState] = useReadyState();
  const [role, setRole] = useState(ResourceRole.Reader);
  const [persons] = useState(new Map<string, PersonModel>(values.filter(_ => _.subject).map(_ => [_.subject.id, _.subject] as [string, PersonModel])));
  const [input, setInput] = useState(_toShareInputs(values));
  const [mutation] = useMutation<OperationResults>();
  const updateInput = (props: Partial<ShareInputModel>) => {
    setInput([
      ...(input.findIndex(_ => (_.subjectId && _.subjectId === props.subjectId || false) || (_.subjectExternalId && _.subjectExternalId === props.subjectExternalId || false)) === -1 ? [props as any] : []),
      ...input.map(_ => (_.subjectId && _.subjectId === props.subjectId || false) || (_.subjectExternalId && _.subjectExternalId === props.subjectExternalId || false) ? { ..._, ...props } : _)
    ]);
  }
  const setPerson = (_value: string | undefined, query: string, data: ReferenceDataModel | undefined) => {
    if (_value) {
      setQuery("");
      data?.person && persons.set(_value, data.person);
      updateInput({ subjectId: _value, role: ResourceRole.Reader, title, url: `${url}?login_hint=${data && data.person?.email}&scope=claim:MELLON` });
    } else {
      setQuery(query);
    }
  };
  const save = async () => {
    await mutation(_deprecatedShareMyResource.get(kind) || "", { id, kind, values: input }, { setReadyState });
    toggle?.();
  };

  const isDisabled = !isEmail(query) && !isPhone(query);
  const add = () => {
    updateInput({ subjectExternalId: query, role, title, url: `${url}?login_hint=${query}&scope=claim:MELLON` });
    setQuery("");
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Share {title}</ModalHeader>
      <ModalBody>
        <Table className="table-flush">
          <thead>
            <tr>
              <th className="w-100">Name</th>
              <th style={{ minWidth: "9rem" }}>Role</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DatalistInput id="nav-orgname" types={[ReferenceDataType.Person]} className="form-control" placeholder="Enter a name, email or phone" value={query} onUpdate={setPerson} />
              </td>
              <td>
                <CustomInput id={`${uid}.0`} type="select" value={role} onChange={e => setRole(e.currentTarget.value as ResourceRole)}>
                  <option value={ResourceRole.Contributor}>{t(ResourceRole.Contributor)}</option>
                  <option value={ResourceRole.Reader}>{t(ResourceRole.Reader)}</option>
                  <option value={ResourceRole.Owner}>{t(ResourceRole.Owner)}</option>
                </CustomInput>
              </td>
              <td>
                <Button color="primary" disabled={isDisabled} className="w-100" onClick={add}>Add</Button>
              </td>
            </tr>
            {input.map((_, i) => {
              const person = persons.get(_.subjectId || "");
              return (
                <tr key={i}>
                  <td className="d-flex">
                    <UserThumbnail className="mr-3" width={32} value={person || { name: _.subjectExternalId || "", picture: "", accentColor: "" }} />
                    <div className="d-flex flex-column">
                      {person && <span className="text-capitalize">{person.name}</span>}
                      {person && <span className="small text-muted">{person.email}</span>}
                      {!person && <span>{_.subjectExternalId}</span>}
                    </div>
                  </td>
                  <td>
                    <CustomInput id={`${uid}.1.${i}`} type="select" value={_.role} onChange={e => updateInput({ ..._, role: e.currentTarget.value as ResourceRole })}>
                      <option value={ResourceRole.Contributor}>{t(ResourceRole.Contributor)}</option>
                      <option value={ResourceRole.Reader}>{t(ResourceRole.Reader)}</option>
                      <option value={ResourceRole.Owner}>{t(ResourceRole.Owner)}</option>
                    </CustomInput>
                  </td>
                  <td>
                    <Button color="link" className="w-100" onClick={() => setInput(input.filter(v => v.subjectId != _.subjectId))}>
                      Remove
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>Cancel</Button>
        <MutationButton readyState={readyState} color="primary" onClick={save}>Save</MutationButton>
      </ModalFooter>
    </Modal>
  );
}

