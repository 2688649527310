import React, { useState } from "react";
import { ListGroup, CardHeader, ListGroupItem, Card, Button, Form, Input } from "reactstrap";
import { PersonModel } from "../../types/api-graph-types";
import { DeepPartial, useMutation, setDelete, useReadyState } from "../../hooks/ApiProvider";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt, faStar } from "@fortawesome/free-regular-svg-icons";
import { UserThumbnail, UserThumbnailFragment } from "../../cards/PersonCard";
import { doNothing } from "../../hooks/NavigationHook";
import { useToggle } from "../../hooks/CommonHooks";
import MutationButton from "../../components/MutationButton";

library.add(faTrashAlt, faStar);

export const Fragment = `
  id
  kind
  name
  email
  ${UserThumbnailFragment}
`;

const _mergeMe = `mutation mergeMe($id:ID! $otp:String) {
  me { merge(id:$id otp:$otp) }
}`

interface OperationResults {
  me: {
    merge: boolean
  }
}

export function ItemRow({ value, onUpdate }: {
  value: PersonModel,
  onUpdate?: (value: DeepPartial<PersonModel>) => void
}) {
  const [readyState, setReadyState] = useReadyState();
  const [isOpen, toggle] = useToggle();
  const [otp, setOtp] = useState("");
  const [mutation] = useMutation<OperationResults>();
  const merge = (otp?: string) => async () => {
    !otp && !isOpen && toggle();
    const result = await mutation(_mergeMe, { id: value.id, otp }, { setReadyState });
    if (result?.me?.merge) {
      onUpdate?.(setDelete(value));
    }
    setOtp("");
  }

  return (
    <ListGroupItem className='d-flex align-items-center'>
      <UserThumbnail className="mr-2" value={value} width={36} />
      <span className="mr-3">{value.name}</span>
      {isOpen &&
        <Form inline className='display-sm-inline' onClick={doNothing}>
          <Input type="text" placeholder="Enter code" value={otp} onChange={e => setOtp(e.target.value.replace(/[^0-9]/, "").substring(0, 6))} bsSize="sm" style={{ width: "10rem" }} />
          <MutationButton readyState={readyState} color="primary" disabled={!otp || otp.length !== 6} onClick={merge(otp)} size="sm">Verify</MutationButton>
          <Button color='link' onClick={merge()} size="sm">Resend</Button>
        </Form>
      }
      {!isOpen && <Button className="ml-auto" color="primary" size="sm" onClick={merge()}>Merge</Button>}
      {isOpen && <Button className="ml-auto" color="link" size="sm" onClick={toggle}>Cancel</Button>}
    </ListGroupItem>
  );
}

export default ({ className, values, onUpdate }: {
  className?: string,
  values: ReadonlyArray<PersonModel>,
  onUpdate: (values: DeepPartial<ReadonlyArray<PersonModel>>) => void,
}) => {
  return values?.length > 0 ? (
    <Card className={className}>
      <CardHeader className="bg-white d-flex align-baseline">
        <h3>Duplicate Accounts</h3>
      </CardHeader>
      <ListGroup flush>
        {values.map((_, i) => <ItemRow key={i} value={_} onUpdate={value => onUpdate([value])} />)}
      </ListGroup>
    </Card>
  ) : null;
}
