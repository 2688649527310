import React, { useEffect } from "react";
import { Col, Row, Alert } from "reactstrap";
import ProductCard, { Fragment as ProductFragment } from "../cards/ProductCard";
import { useQuery } from "../hooks/ApiProvider";
import { useTitle } from "../hooks/TitleHook";
import { ProductModel } from "../types/api-graph-types";
import LoadingController from "./LoadingController";
import { useTranslation, DateDisplayFormat } from "../hooks/TranslationProvider";
import { useNavigation, replaceHistory, useFragment, updateURIComponents } from "../hooks/NavigationHook";

const _getProduct = `query getDisplayProduct($id:ID) {
  product: displayProduct(id:$id) {
    id
    ${ProductFragment({ showVariants: true, showButtons: true, showDiscussion: true })}
    orders: myOrders(states:[PROPOSED,IN_PROGRESS,RESOLVED,REMOVED]) { id kind stage createdOn }
  }
}`;

interface Result {
  product: ProductModel
}

export default ({ id }: { id: string }) => {
  const [, d] = useTranslation();
  const [navigate] = useNavigation();
  const [fragment] = useFragment({ comment_form: "" })
  const [result, updateResult, isLoading] = useQuery<Result>(_getProduct, { id });
  const value = result?.product;
  useEffect(() => {
    const hash = updateURIComponents("#", { comment_form: fragment.comment_form });
    value?.orders?.[0]?.id && replaceHistory(`/my/goals/${value?.orders?.[0]?.id}${hash}`);
  }, [result]);
  useTitle(value && value.details.displayTitle);
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const update = (product: ProductModel) => updateResult({ product });

  return (
    <>
      {value.orders.length > 0 &&
        <Row className="mt-3">
          <Col>
            <Alert color="primary">
              <h5>Applied</h5>
              <p>You applied on {d(value.orders[0].createdOn, DateDisplayFormat.HumanDate)}. <a href="#" onClick={navigate(`/my/goals/${value.orders[0].id}`)}>View</a> </p>
            </Alert>
          </Col>
        </Row>
      }
      <Row className="mt-3">
        <Col>
          <ProductCard value={value} onUpdate={update} showAvailability showDetailedDescription showVariants variantsPath="/my/opportunities" showButtons />
        </Col>
      </Row>
    </>
  )
};
