import React, { ReactNode } from "react";

export default ({ className, children }: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div className={`cell pl-0 ${className || ""}`}>
      {children}
    </div>
  );
}
