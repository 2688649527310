import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Suspense, useEffect, useState } from "react";
import { Button, Col, Collapse, CustomInput, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, InputGroupAddon, InputGroupButtonDropdown, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledButtonDropdown, UncontrolledDropdown } from "reactstrap";
import { Fragment as AttachmentListFragment, List as AttachmentsList } from "../components/CardAttachmentsListGroup";
import { Settings as DiscussionSettings, SettingsMenu as DiscussionSettingsMenu } from "../components/CardDiscussionListGroup";
import { CardBadge, CardButton, CardButtons } from "../components/CardStatus";
import CopyButton from "../components/CopyButton";
import { CustomDropdownItem } from "../components/CustomDropdown";
import DatalistInput from "../components/DatalistInput";
import DragDropZone from "../components/DragDropZone";
import FormGroup, { between, len } from "../components/FormGroup";
import ValidatingInput from "../components/Input";
import MutationButton from "../components/MutationButton";
import Show, { Scopes } from "../components/Show";
import { ApiErrors, byKey, DeepPartial, merge, nextNonce, setDelete, setReplace, toInput as _, useMutation, useReadyState } from "../hooks/ApiProvider";
import { useAttachments } from "../hooks/AttachmentHook";
import { useToggle, useUpdatableState } from "../hooks/CommonHooks";
import { useConfirmation } from "../hooks/ConfirmationProvider";
import { useMe } from "../hooks/MeProvider";
import { doNothing, stopPropagation, useNavigation } from "../hooks/NavigationHook";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { Fragment as SharesFragment, SharesInputTable, SubjectFragment as SharesSubjectFragment } from "../modals/ShareModal";
import { Input as SubscriptionInput } from "../modals/SubscriptionChooserModal";
import { AreaType, AttachmentInputModel, AttachmentModel, AttachmentType, CityCode, CurrencyCode, FunctionType, LabelModel, LanguageCode, LocationInputModel, MeModel, MessageInputModel, MessageModel, MoneyInputModel, OrderStage, ProductDetailsInputModel, ProductDetailsModel, ProductModel, ProductType, ProductVariantType, ReferenceDataModel, ReferenceDataType, ResourceEvent, ResourceKind, RuleActionType, RuleInputModel, RuleModel, RuleType, ServiceType, ShareInputModel } from "../types/api-graph-types";
import { CityCodes, ServiceTypes } from "../types/common-types";

library.add(faExclamationTriangle, faCopy);

export const DetailsFragment = `
  key
  type
  subtype
  title
  subtitle
  displayTitle
  variantType
  variantTitle
  description
  detailedDescription
  function
  industry
  service
  areas
  languages
  duration
  manufacturerOrg { id kind name description logo }
  location { streetAddress neighborhood city locality }
  compensation { currency amount variableFraction period isPrivate isGross }
  isPrivate
  isLocked
  isUnclaimed
  skipOrgDescription
  minCustomersGraduationIn
  maxCustomersGraduationIn
  requirementsDescription
  eduRatingDisplayMax
  availableOn
  ordersDescription
  ordersJobsCount
  ordersJobsMaxCount
  ordersJobsWorkitemsRelatedIds
  ordersJobsWorkitemsByAreas
  ordersCustomerAttachmentReviews
  ordersDueIn
  ordersDueOn 
  ordersStages
  ordersDiscussionDescription
  ordersDiscussionMessages
  ordersCustomerExternalIdMessage
  supplierRecipients
  supplierOriginAddresses
  notificationPreferences
  labels { key color value isArchived }
  rules {
    key
    name
    type 
    action
    isEnabled 
    isArchived
    nextTriggerOn 
    nextTriggerIn 
    eventsTrigger 
    includeDefaultRecipients
    message { 
      subject 
      content 
      shortContent 
      bccSender 
      recipientEmails
    }
    messageMatch { senderEmails content }
    labels { key color value }
    comment { message }
    attachments { key type filename contentUri }
  }
  messages { key subject content attachments { key } }
`;

export const Fragment = `
  id
  kind
  state
  details { ${DetailsFragment} } 
  shares(states:[PROPOSED,COMPLETED]) { ${SharesFragment} }
  attachments { updatedOn ${AttachmentListFragment} }
  policy { role } 
  actions { start stop order link unlink undo remove } 
  owner { ${SharesSubjectFragment} }
  supplier { key id kind description websiteUrl }
  root { details { title } }
  collection { id kind details { title variantType variantTitle } policy { role } }
  subscription { id kind title }
`;

const _updateProductDetails = `mutation updateProductDetails($id:ID! $value:ProductDetailsInputModel $shares:[ShareInputModel] $hasShares:Boolean!) {
  product(id:$id) {
    details { change(value:$value) }
    commit { ${Fragment} }
    shares @include(if:$hasShares) {
      update(values:$shares) { ${SharesFragment} } 
    }
  }
}`;

const _createProduct = `mutation createProduct($details:ProductDetailsInputModel $shares:[ShareInputModel] $hasShares:Boolean!) {
  product: addProduct { 
    details { change(value:$details) }
    commit { ${Fragment} } 
    shares @include(if:$hasShares) {
      update(values:$shares) { ${SharesFragment} } 
    }
  }
}`;

const _linkProduct = `mutation linkProduct($id:ID! $id_to:ID!) {
  product(id:$id) { 
    link(id:$id_to)
    commit { ${Fragment} } 
  }
}`;

const _unlinkProduct = `mutation unlinkProduct($id:ID!) {
  product(id:$id) { 
    unlink
    commit { ${Fragment} } 
  }
}`;

const _copyProduct = `mutation copyProduct($id:ID! $details:ProductDetailsInputModel $subscriptionId:ID) {
  product(id:$id) { 
    copy(subscriptionId:$subscriptionId details:$details) { ${Fragment} } 
  }
}`;

interface OperationResults {
  product: {
    commit: ProductModel
    copy: ProductModel
  }
}

interface OperationErrors {
  product: {
    details: {
      change: ApiErrors<{ value: ProductDetailsInputModel }>
    }
  }
}

// Break dependency cycle using async import. 
// ProductCard depends on ProductInputModal * async depends on ProductChooserModal depends on ProductCard
const ProductChooserModalAsync = React.lazy(() => import("../modals/ProductChooserModal"));
const ProductChooserModal = (props: {
  isOpen?: boolean;
  toggle?: () => void;
  label?: string | undefined;
  onSelect: (id: string, value: ProductModel) => void;
}) => (
    <Suspense fallback={null}>
      <ProductChooserModalAsync {...props} />
    </Suspense>
  );

const _toAttachmentInputModel = (value: AttachmentModel): AttachmentInputModel => ({
  token: _(value.token),
  id: _(value.id),
  key: _(value.key),
  state: _(value.state),
  type: _(value.type),
  title: _(value.title),
  subtitle: _(value.subtitle),
  filename: _(value.filename),
  length: _(value.length),
  mediaType: _(value.mediaType),
  dataUri: _(value.dataUri),
  content: _<string>(),
  contentUri: _(value.contentUri),
  thumbnailUri: _(value.thumbnailUri),
  squareCroppedUri: _(value.squareCroppedUri),
  issuedOn: _(value.issuedOn),
  issuedUntil: _(value.issuedUntil),
  issuerName: _(value.issuerName),
  issuerOrganizationName: _(value.issuerOrganizationName),
  issuerTitle: _(value.issuerTitle),
  issuerEmail: _(value.issuerEmail),
  issuerPhone: _(value.issuerPhone),
  issuerWebsite: _(value.issuerWebsite),
  issuerReferenceId: _(value.issuerReferenceId),
});

const _toRuleInputModel = (value: Partial<RuleModel>): RuleInputModel => ({
  key: value.key || nextNonce(),
  name: _(value.name ?? ""),
  type: _(value.type),
  isEnabled: _(value.isEnabled),
  isArchived: _(value.isArchived),
  nextTriggerIn: _(value.nextTriggerIn),
  nextTriggerOn: _(value.nextTriggerOn),
  eventsTrigger: _(value.eventsTrigger ?? []),
  action: _(value.action),
  includeDefaultRecipients: _(value.includeDefaultRecipients),
  message: {
    key: _(value.message?.key),
    name: _(value.message?.name),
    isDraft: true,
    bccSender: _(value.message?.bccSender),
    subject: _(value.message?.subject ?? ""),
    content: _(value.message?.content ?? ""),
    shortContent: _(value.message?.shortContent ?? ""),
    recipientIds: _<string[]>(),
    recipientEmails: _(value.message?.recipientEmails),
    recipientPhones: _<string>(),
    recipientUris: _<string[]>(),
    attachments: _<AttachmentInputModel[]>()
  },
  messageMatch: {
    senderEmails: _(value.messageMatch?.senderEmails),
    content: _(value.messageMatch?.content),
  },
  labels: _(value.labels ?? []),
  comment: {
    isPrivate: _(value.comment?.isPrivate ?? true),
    message: _(value.comment?.message),
    referencesIds: _<string[]>(),
    visibilityId: _(value.comment?.visibilityId),
    clientUri: _<string>()
  },
  attachments: _(value.attachments?.map(_toAttachmentInputModel) ?? [])
});

const _toMessageInputModel = (value: Partial<MessageModel>): MessageInputModel => ({
  key: _(value.key),
  subject: _(value.subject),
  content: _(value.content),
  shortContent: _(value.shortContent),
  attachments: _(value.attachments?.map(_toAttachmentInputModel) ?? []),
  bccSender: _(value.bccSender),
  isDraft: _(value.isDraft),
  name: _(value.name),
  recipientEmails: _(value.recipientEmails),
  recipientIds: _<string[]>(),
  recipientPhones: _<string>(),
  recipientUris: _<string[]>()
});

const _toProductDetailsInputModel = (value: ProductDetailsModel): ProductDetailsInputModel => ({
  key: _(value.key),
  type: _(value.type),
  subtype: _(value.subtype),
  title: _(value.title),
  subtitle: _(value.subtitle),
  manufacturerOrgId: _(value.manufacturerOrg?.id),
  variantType: _(value.variantType),
  variantTitle: _(value.variantTitle),
  description: _(value.description),
  detailedDescription: _(value.detailedDescription),
  industry: _(value.industry),
  function: _(value.function),
  service: _(value.service),
  areas: _(value.areas ?? []),
  languages: _(value.languages),
  duration: _(value.duration),
  location: {
    country: _(value.location?.country),
    postalCode: _(value.location?.postalCode),
    region: _(value.location?.region),
    streetAddress: _(value.location?.streetAddress),
    city: _(value.location?.city),
    locality: _(value.location?.locality),
    neighborhood: _(value.location?.neighborhood)
  },
  compensation: {
    currency: _(value.compensation?.currency),
    amount: _(value.compensation?.amount),
    variableFraction: _(value.compensation?.variableFraction),
    period: _(value.compensation?.period ?? "P365D"),
    isGross: _(value.compensation?.isGross ?? true),
    isPrivate: _(value.compensation?.isPrivate)
  },
  isPrivate: _(value.isPrivate),
  isLocked: _(value.isLocked),
  isUnclaimed: _(value.isUnclaimed),
  restartOn: _(value.restartOn),
  availableOn: _(value.availableOn),
  skipOrgDescription: _(value.skipOrgDescription),
  requirementsDescription: _(value.requirementsDescription),
  eduRatingDisplayMax: _(value.eduRatingDisplayMax ?? 10),
  minCustomersGraduationIn: _(value.minCustomersGraduationIn),
  maxCustomersGraduationIn: _(value.maxCustomersGraduationIn),
  ordersDescription: _(value.ordersDescription),
  ordersJobsWorkitemsRelatedIds: _(value.ordersJobsWorkitemsRelatedIds ?? []),
  ordersJobsWorkitemsByAreas: _(value.ordersJobsWorkitemsByAreas),
  ordersJobsCount: _(value.ordersJobsCount),
  ordersJobsMaxCount: _(value.ordersJobsMaxCount),
  ordersDueIn: _(value.ordersDueIn),
  ordersDueOn: _(value.ordersDueOn),
  ordersStages: _(value.ordersStages ?? []),
  ordersCustomerExternalIdMessage: _(value.ordersCustomerExternalIdMessage),
  ordersCustomerAttachmentReviews: _(value.ordersCustomerAttachmentReviews),
  supplierRecipients: _(value.supplierRecipients),
  supplierOriginAddresses: _(value.supplierOriginAddresses),
  rules: _(value.rules?.map(_toRuleInputModel).sort(_ruleSort) ?? []),
  labels: _(value.labels ?? []),
  ordersDiscussionDescription: _(value.ordersDiscussionDescription),
  ordersDiscussionMessages: _(value.ordersDiscussionMessages ?? []),
  notificationPreferences: _(value.notificationPreferences ?? []),
  messages: _(value.messages?.map(_toMessageInputModel) ?? [])
});

const _goRegex = /{{#go}}.*?{{\/go}}/g;
const _invalid = /[^a-zA-Z0-9-]/g;

const _shortUrlGrace = (value: string): number => {
  const shortUrlLength = 22; // https://hkgo.in/123456
  const matches = value.match(_goRegex) || [];
  return matches.map(_ => _.length).reduce((a, v) => a + v, 0) - shortUrlLength * matches.length;
}

const _types = new Map<ProductType, Partial<ProductDetailsInputModel>>([
  [ProductType.EmploymentTrainingLevel, { subtitle: "Internship", subtype: ProductType.EmploymentFullTime, duration: "P28D" }],
  [ProductType.EmploymentEntryLevel, { subtitle: "Associate Position", subtype: ProductType.EmploymentFullTime, duration: "P65535D" }],
  [ProductType.EmploymentSeniorLevel, { subtitle: "Senior Associate Position", subtype: ProductType.EmploymentFullTime, duration: "P65535D" }],
  [ProductType.EmploymentManagerLevel, { subtitle: "Partner Position", subtype: ProductType.EmploymentFullTime, duration: "P65535D" }],
  [ProductType.EmploymentVicePresidentLevel, { subtitle: "Managing Partner Position", subtype: ProductType.EmploymentFullTime, duration: "P65535D" }],
  [ProductType.CustomerService, { subtype: ProductType.Null, duration: "P65535D" }],
]);

const _newMessage = {} as MessageInputModel;
const _newAttachment = {} as AttachmentInputModel;
const _newRule = {} as RuleInputModel;

const _ruleCommentSettings: DiscussionSettings = {
  changeIsPrivate: true,
  defaultIsPrivate: true,
  helpPrivate: "People with shared access",
  helpPublic: "Anyone on HigherKnowledge"
}

function _getRuleTemplate(key: string, value?: ProductDetailsInputModel): RuleInputModel | undefined {
  switch (key) {
    case "accepted": return { ..._newRule, name: "Offer accepted", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `Acknowledgement for {{Listing}}`, content: `Dear {{Applicant}},\n\nThank you for confirming you acceptance for the {{Listing}}. Your joining details are below.\n\nStart Date: {{StartDate}}${value?.duration === "P65535D" ? "" : "\nEnd Date: {{EndDate}}"}\nLocation: {{Location}}\n\n{{AdditionalInstructions}}\n\nWe look forward to working with you.\n\nSincerely,\n{{Team}}`, shortContent: "" }, includeDefaultRecipients: true, attachments: [{ ..._newAttachment, type: AttachmentType.OrderStartCalendarEvent }], isEnabled: true };
    case "added": return { ..._newRule, name: "Add applicant", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `Invitation to apply for {{Listing}}`, content: `Dear {{Applicant}},\n\nWe would like you to apply for the {{Listing}}. If you are interested, please update your full name, availability dates, and CV at the link below and follow the instructions to submit your application.\n\n[Apply]({{ApplicationLink}})\n\n{{AdditionalInstructions}}\n\n{{ListingRequirements}}\n\nPlease read the [FAQ](https://www.higherknowledge.in/our/faq) for answers to common questions about the application process.\n\n{{TeamDescription}}\n\n{{TeamWebsite}}\n\n{{ListingDescription}}\n\nSincerely,\n{{Team}}`, shortContent: "" }, isEnabled: true };
    case "allowed": return { ..._newRule, name: "Applicant allowed", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `Application allowed for {{Listing}}`, content: `Dear {{Applicant}},\n\nThank you for your interest in the {{Listing}}. Your application has been allowed. Please update your full name, availability dates, and CV at the link below and follow the instructions to submit your application.\n\n[Apply]({{ApplicationLink}})\n\n{{ListingRequirements}}\n\nPlease read the [FAQ](https://www.higherknowledge.in/our/faq) for answers to common questions about the application process.\n\nSincerely,\n{{Team}}`, shortContent: "" }, isEnabled: true };
    case "created": return { ..._newRule, name: "New applicant", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `Application for {{Listing}}`, content: `Dear {{Applicant}},\n\nThank you for your interest in the {{Listing}}. Please update your full name, availability dates, and CV at the link below and follow the instructions to submit your application.\n\n[Apply]({{ApplicationLink}})\n\n{{ListingRequirements}}\n\nPlease read the [FAQ](https://www.higherknowledge.in/our/faq) for answers to common questions about the application process.\n\nSincerely,\n{{Team}}`, shortContent: "" }, isEnabled: true };
    case "created-submitted": return { ..._newRule, name: "Application created or submitted", action: RuleActionType.MessageProductSupplier, message: { ..._newMessage, subject: `Application for {{Listing}}`, content: `Dear Sir/Madam,\n\nI am interested in applying for the {{Listing}}.\n\nI have uploaded my resume, samples of my work, and my availability dates via HigherKnowledge.\n\n{{button "View résumé" ApplicationLink}}\n\n{{AdditionalIntroductions}}\n\nI hope you will consider my application and give me the opportunity of working with your firm.\n\nSincerely,\n{{Applicant}}`, shortContent: "" }, isEnabled: true };
    case "create-requested": return { ..._newRule, name: "Applicant waiting", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `Application waiting for {{Listing}}`, content: `Dear {{Applicant}},\n\nThank you for your interest in the {{Listing}}. Please update your full name, availability dates, and CV at the link below. We are currently limiting new applications. This information will help us decide to allow your application.\n\n[Apply]({{ApplicationLink}})\n\n{{ListingRequirements}}\n\nPlease read the [FAQ](https://www.higherknowledge.in/our/faq) for answers to common questions about the application process.\n\nSincerely,\n{{Team}}`, shortContent: "" }, includeDefaultRecipients: true, isEnabled: true };
    case "custom-event": return { ..._newRule, action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `About your application for {{Listing}}`, content: `Dear {{Applicant}},\n\nPlease access the link below to update your application.\n\n[Apply]({{ApplicationLink}})\n\n{{AdditionalInstructions}}\n\nSincerely,\n{{Team}}`, shortContent: "Please access {{#go}}{{ApplicationLink}}{{/go}} to update your application." }, isEnabled: true };
    case "declined": return { ..._newRule, name: "Offer declined", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `About your application for {{Listing}}`, content: `Dear {{Applicant}},\n\nThanks again for your interest in the {{Listing}}. We are sorry that you are unable to accept the offer. Do let us know if there is anything we can improve with the offer. Until then, we wish you the best of luck in your endeavours.\n\nSincerely,\n{{Team}}`, shortContent: "" }, includeDefaultRecipients: true, isEnabled: true };
    case "delivered": return { ..._newRule, name: "New-hire onboarding", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `Onboarding instructions for {{Listing}}`, content: `Dear {{Applicant}},\n\nWelcome! We are excited to have you onboard. Please do not hesitate to contact me for anything you need as you settle into your new role.\n\nSincerely,\n{{Team}}`, shortContent: "" }, isEnabled: true };
    case "interviewing": return { ..._newRule, key: "interviewing", name: "Interview", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `Interview for {{Listing}}`, content: `Dear {{Applicant}},\n\nThanks again for your interest in the {{Listing}}. We would like to invite you for an in-person interview. The interview details are provided below.\n\nDate: {{InterviewDate}}\nTime: {{InterviewTime}}\nLocation: {{InterviewLocation}}\n\n{{AdditionalInstructions}}\n\nWe look forward to meeting you.\n\nSincerely,\n{{Team}}`, shortContent: `You have been invited to an in-person interview for your {{Listing}} application on {{InterviewDate}} at {{InterviewTime}} at {{InterviewLocation}}. {{AdditionalInstructions}}` }, includeDefaultRecipients: true, attachments: [{ ..._newAttachment, type: AttachmentType.OrderInterviewCalendarEvent }], isEnabled: true };
    case "message-received": return { ..._newRule, name: "Message received", action: RuleActionType.UpdateOrder, isEnabled: true };
    case "offered": return { ..._newRule, name: "Offer extended", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `About your application for {{Listing}}`, content: `Dear {{Applicant}},\n\nThanks again for your interest in the {{Listing}}. We are delighted to extend you an offer as detailed below. Please confirm your acceptance by {{DeadlineDate}} at the link below.\n\nStart Date: {{StartDate}}${value?.duration === "P65535D" ? "" : "\nEnd Date: {{EndDate}}"}\nLocation: {{Location}}\n\n{{AdditionalInstructions}}\n\n[View]({{ApplicationLink}})\n\nWe look forward to working with you.\n\nSincerely,\n{{Team}}`, shortContent: `Congratulations! You have been extended an offer for the {{Listing}} starting {{StartDate}}. Please confirm acceptance by {{DeadlineDate}}.` }, includeDefaultRecipients: true, isEnabled: true };
    case "refused": return { ..._newRule, name: "Refuse applicant", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `About your application for {{Listing}}`, content: `Dear {{Applicant}},\n\nThanks again for your interest in the {{Listing}}. Due to limited availability, we are unable to extend you an offer at this time. You may apply again in the future by reactivating this application. Until then, we wish you the best of luck in your endeavours.\n\nSincerely,\n{{Team}}`, shortContent: "" }, includeDefaultRecipients: true, isEnabled: true };
    case "screening": return { ..._newRule, key: "screening", name: "Phone screening", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `Phone discussion for {{Listing}}`, content: `Dear {{Applicant}},\n\nThanks again for your interest in the {{Listing}}. We would like to invite you for a phone discussion. The discussion details are included below.\n\nDate: {{ScreeningDate}}\nTime: {{ScreeningTime}}\nConference: {{ScreeningLocation}}\n\n{{AdditionalInstructions}}\n\nWe look forward to speaking with you.\n\nSincerely,\n{{Team}}`, shortContent: `You have been invited to a phone discussion for your {{Listing}} application on {{ScreeningDate}} at {{ScreeningTime}}. Conference {{ScreeningLocation}}. {{AdditionalInstructions}}` }, includeDefaultRecipients: true, attachments: [{ ..._newAttachment, type: AttachmentType.OrderScreeningCalendarEvent }], isEnabled: true };
    case "submitted": return { ..._newRule, name: "Application submitted", action: RuleActionType.MessageProductSupplier, message: { ..._newMessage, subject: `Application for {{Listing}}`, content: `Dear Sir/Madam,\n\nI am interested in applying for the {{Listing}}.\n\nI have uploaded my resume, samples of my work, and my availability dates via HigherKnowledge.\n\n{{button "View résumé" ApplicationLink}}\n\n{{AdditionalIntroductions}}\n\nI hope you will consider my application and give me the opportunity of working with your firm.\n\nSincerely,\n{{Applicant}}`, shortContent: "" }, isEnabled: true };
    case "withdrawn": return { ..._newRule, name: "Withdraw offer", action: RuleActionType.MessageOrderCustomer, message: { ..._newMessage, subject: `About your application for {{Listing}}`, content: `Dear {{Applicant}},\n\nThanks again for your interest in the {{Listing}}. Due to unexpected circumstances, we are unable to continue extending you an offer at this time. If the circumstances change, we will follow-up accordingly. Until then, we wish you the best of luck in your endeavours.\n\nSincerely,\n{{Team}}`, shortContent: "" }, includeDefaultRecipients: true, isEnabled: true };
    default: return undefined;
  }
}

const _rules = new Map<string, Partial<RuleInputModel>>([
  ["accepted", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.CustomerAcceptedOffer], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["added", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.SupplierCreatedOrder], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["allowed", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.SupplierAllowedCreateOrder], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["created", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.CustomerCreatedOrder], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["created-submitted", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.CustomerCreatedOrder, ResourceEvent.CustomerSubmittedOrder], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["create-requested", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.CustomerRequestedCreateOrder], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["custom-event", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.CustomEvent], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["declined", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.CustomerDeclinedOffer], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["delivered", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.SupplierDeliveredOrder], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["interviewing", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.SupplierScheduledInterview, ResourceEvent.SupplierRescheduledInterview], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["message-received", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.MessageReceived], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["offered", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.SupplierCreatedOffer, ResourceEvent.SupplierUpdatedOffer], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["refused", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.SupplierRefusedOrder], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["scheduled", { type: RuleType.Scheduled, eventsTrigger: [] } as Partial<RuleInputModel>],
  ["screening", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.SupplierScheduledScreening, ResourceEvent.SupplierRescheduledScreening], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["shortlisted", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.SupplierShortlistedOrder], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["submitted", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.CustomerSubmittedOrder], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
  ["withdrawn", { type: RuleType.EventTriggered, eventsTrigger: [ResourceEvent.SupplierWithdrewOffer], nextTriggerOn: undefined, nextTriggerIn: undefined } as Partial<RuleInputModel>],
]);

export function getDefaultRule(key: string) {
  return {
    key: nextNonce(),
    ...(_rules.get(key) ?? {}),
    ...(_getRuleTemplate(key) ?? {})
  } as RuleInputModel;
}

const _rulesRank = [
  "scheduled",
  "added",
  "create-requested",
  "allowed",
  "created",
  "message-received",
  "created-submitted",
  "submitted",
  "shortlisted",
  "screening",
  "interviewing",
  "offered",
  "accepted",
  "delivered",
  "declined",
  "refused",
  "withdrawn"
];

function _ruleSort(a: RuleInputModel, b: RuleInputModel): number {
  return _rulesRank.indexOf(_getRuleTemplateKey(a)) < _rulesRank.indexOf(_getRuleTemplateKey(b)) ? -1 : 1;
}

function _getRuleTemplateKey(value: RuleInputModel) {
  return Array.from(_rules)
    .filter(([, _]) =>
      value.type === _.type
      && value.eventsTrigger?.length === _.eventsTrigger?.length
      && value.eventsTrigger?.every(e => _.eventsTrigger?.includes(e)))[0]
    ?.[0] ?? "";
}

export const New: ProductModel = {
  id: "",
  details: {
    areas: [],
    location: {},
    languages: [],
    compensation: {}
  },
  actions: {}
} as DeepPartial<ProductModel> as any;

export const NewInput: ProductDetailsInputModel = {
  function: FunctionType.LegalFunction,
  ordersJobsCount: 0,
  ordersJobsMaxCount: 0,
  isUnclaimed: false,
  isLocked: false,
  isPrivate: false,
  ordersCustomerAttachmentReviews: false,
  areas: [],
  location: {},
  compensation: { currency: CurrencyCode.Inr }
} as DeepPartial<ProductDetailsInputModel> as any;

export const IsValid = (value: ProductDetailsInputModel) =>
  value.title && between(len(value.title), 2, 140)
  && (!value.description || between(len(value.description), 140, 1400))
  && value.type && value.function
  || false;
;

function _toLocation(t: (v: string) => string, value: LocationInputModel) {
  return `${value.streetAddress}, ${value.neighborhood}, ${value.city === CityCode.Other ? value.locality : t(value.city)}`;
}

const _dataRe = /data.[a-z0-9_]+/ig;
function _getPreview(t: (v: string) => string, value: ProductModel, input: ProductDetailsInputModel, me: MeModel | undefined, rule: RuleInputModel) {
  const location = _toLocation(t, input.location) || "";
  const content = rule?.message?.content ?? "";
  const data = content && content.match(_dataRe)?.reduce((data, key) => ({ ...data, [key.substr(5)]: ` *\\[${key.substr(5)}\\]* ` }), {}) || undefined;
  return ({
    Applicant: "Anamika",
    ApplicantName: "Anamika Nayak",
    GoalLink: `https://higherknowledge.in/my/goals/1ed83d90-a387-43c0-99c1-77477e28c1ac`,
    ApplicationLink: `https://higherknowledge.in/my/listings/${value.id}/candidates/1ed83d90-a387-43c0-99c1-77477e28c1ac`,
    StartDate: moment().add(10, "d").format("dddd, MMMM D, YYYY"),
    EndDate: moment().add(moment.duration(input.duration || "P40D")).format("dddd, MMMM D, YYYY"),
    Duration: input.duration && `${moment.duration(input.duration).asWeeks()} weeks` || "4 weeks",
    Location: location,
    Listing: `${input.title || ""} ${input.subtitle || ""}`.trim(),
    ListingDescription: input.description || "",
    ListingRequirements: input.requirementsDescription || "\\[Any additional requirements you enter in the listing will appear here.\\]",
    ListingInstructions: input.ordersDescription || "\\[Any additional instructions you enter in the listing will appear here.\\]",
    ScreeningDate: moment().add(3, "d").format("dddd, MMMM D, YYYY"),
    ScreeningTime: "2:30 PM",
    ScreeningDuration: "30 minutes",
    ScreeningLocation: "Call or WhatsApp +91 98765 43210",
    InterviewDate: moment().add(7, "d").format("dddd, MMMM D, YYYY"),
    InterviewDuration: "2 hours",
    InterviewLocation: location,
    InterviewTime: "10:00 AM",
    AdditionalInstructions: "\\[Any additional instructions you enter for this applicant will appear here.\\]",
    AdditionalIntroductions: "\\[Any additional details the applicant enters will appear here.\\]",
    DeadlineDate: moment().add(1, "d").format("dddd, MMMM D, YYYY"),
    DeadlineTime: "6:00 PM",
    Me: me?.name ?? "",
    Team: value.details.manufacturerOrg?.name ?? me?.name ?? "",
    TeamDescription: input.skipOrgDescription ? "" : value.details.manufacturerOrg?.description ?? "",
    TeamWebsite: value.details.manufacturerOrg?.websiteUrl ?? "",
    data
  });
}

function ToName(value: AttachmentModel | AttachmentInputModel) {
  return value.title ? `${value.title} ${value.subtitle}`.trim()
    : value.filename.split(".")[0];
}

function AttachmentInput({ values, types, onUpdate, product }: {
  values: ReadonlyArray<AttachmentInputModel>,
  types: AttachmentType[],
  product: ProductModel,
  onUpdate: (value: DeepPartial<AttachmentInputModel>, file?: File) => void
}) {
  const [t] = useTranslation();
  const [type, setType] = useState(AttachmentType.Null);
  const addAttachment = (value: string) => {
    if (value === AttachmentType.Other) {
      setType(value);
    } else if (Object.values(AttachmentType).includes(value as AttachmentType)) {
      onUpdate({ key: nextNonce(), type: value as AttachmentType });
    } else if (product.attachments?.some(_ => _.id === value)) {
      onUpdate({
        key: nextNonce(),
        type: AttachmentType.InternalReference,
        contentUri: `hk:attachment:${value}`
      });
    }
  }
  const updateDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filename = e.target.files?.[0]?.name;
    if (filename) {
      onUpdate({ key: nextNonce(), type, filename, content: filename }, e.target.files?.[0]);
      setType(AttachmentType.Null);
    }
  }
  return (
    <InputGroup>
      <select className={`custom-select ${type === AttachmentType.Null ? "text-muted" : ""}`} value={type} onChange={e => addAttachment(e.currentTarget.value as AttachmentType)}>
        <option value={AttachmentType.Null} disabled hidden>Select attachment type</option>
        {types.map((_, i) => <option key={i} value={_}>{t(_)}</option>)}
        {product.attachments
          ?.filter(_ => values.every(a => (a.contentUri || "").indexOf(_.id) == -1))
          .map((_, i) => <option key={i + types.length} value={_.id}>{ToName(_)}</option>)
        }
      </select>
      {type === AttachmentType.Other &&
        <div className='custom-file'>
          <Input type="file" className={`custom-file-input`} onChange={updateDocument} />
          <label className="custom-file-label text-truncate text-nowrap text-muted">Select your file here</label>
        </div>
      }
    </InputGroup>
  );
}

export function CopyModal({ value, isOpen, toggle }: {
  value: ProductModel,
  isOpen?: boolean,
  toggle?: () => void,
}) {
  const [, go] = useNavigation();
  const [me] = useMe();
  const [readyState, setReadyState] = useReadyState();
  const [mutation] = useMutation<OperationResults>();
  const [title, setTitle] = useState(`${value.details.title} (Copy)`);
  const [subtitle, setSubtitle] = useState(value.details.subtitle);
  const [variantTitle, setVariantTitle] = useState(value.details.variantTitle);
  const [subscription, setSubscription] = useState(value.subscription);
  const copy = async (e: React.MouseEvent) => {
    e.persist();
    const result = await mutation(_copyProduct, {
      id: value.id,
      subscriptionId: subscription?.id,
      details: { title, subtitle, variantTitle } as Partial<ProductDetailsInputModel>
    }, { setReadyState });
    const id = result.product.copy.id;
    await mutation(_linkProduct, { id, id_to: value.id }, { setReadyState });
    go(`/my/listings/${id}`, e);
    toggle?.();
  }
  const hasManySubs = (me?.subscriptions.length ?? 0) > 0;
  const isValid = !!title;
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Copy {value.details.displayTitle}</ModalHeader>
      <ModalBody>
        <Form>
          <fieldset>
            <FormGroup label="Title" value={title} maxLength={140}>
              <ValidatingInput type="text" placeholder="Enter your listing title here" onUpdate={setTitle} />
            </FormGroup>
            <FormGroup label="Subtitle" value={subtitle} maxLength={140}>
              <ValidatingInput type="text" placeholder="Enter your listing subtitle title here" onUpdate={setSubtitle} />
            </FormGroup>
            <FormGroup label="Variant title" value={variantTitle} maxLength={140}>
              <ValidatingInput type="text" placeholder="Enter your variant title here" onUpdate={setVariantTitle} />
            </FormGroup>
            {hasManySubs &&
              <FormGroup label="Subscription">
                <SubscriptionInput value={subscription} onUpdate={setSubscription} />
              </FormGroup>
            }
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>Cancel</Button>
        <MutationButton readyState={readyState} color="primary" disabled={!isValid} onClick={copy}>Copy</MutationButton>
      </ModalFooter>
    </Modal>
  );
}

export function CreateLabelModal({ value, onUpdate, isOpen, toggle }: {
  value: ProductModel,
  onUpdate: (value: DeepPartial<ProductModel>, label: LabelModel) => void
  isOpen?: boolean,
  toggle?: () => void,
}) {
  const [readyState, setReadyState] = useReadyState();
  const [mutation] = useMutation<OperationResults>();
  const [input, , updateInput] = useUpdatableState({} as LabelModel);
  const create = async () => {
    const key = nextNonce(4);
    const details = { labels: [...value.details.labels, { ...input, key }] } as Partial<ProductDetailsInputModel>;
    const result = await mutation(_updateProductDetails, { id: value.id, value: details, hasShares: false }, { setReadyState });
    const label = result.product.commit.details.labels.filter(_ => _.key === key)[0];
    onUpdate?.(result.product.commit, label);
    toggle?.();
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} onClick={stopPropagation}>
      <ModalHeader>Create label</ModalHeader>
      <ModalBody>
        <Form>
          <fieldset>
            <InputGroup>
              <UncontrolledButtonDropdown addonType="prepend">
                <DropdownToggle style={{ backgroundColor: input.color || '#f8f9fa', border: 0, minWidth: "2.5rem" }} />
                <DropdownMenu className="p-0">
                  <DropdownItem style={{ backgroundColor: "#f8f9fa", minHeight: "2rem" }} onClick={() => updateInput({ color: "" })} />
                  <DropdownItem style={{ backgroundColor: "#ac59ff", minHeight: "2rem" }} onClick={() => updateInput({ color: "#ac59ff" })} />
                  <DropdownItem style={{ backgroundColor: "#4a95ff", minHeight: "2rem" }} onClick={() => updateInput({ color: "#4a95ff" })} />
                  <DropdownItem style={{ backgroundColor: "#3bbdca", minHeight: "2rem" }} onClick={() => updateInput({ color: "#3bbdca" })} />
                  <DropdownItem style={{ backgroundColor: "#3ac95c", minHeight: "2rem" }} onClick={() => updateInput({ color: "#3ac95c" })} />
                  <DropdownItem style={{ backgroundColor: "#f2c83f", minHeight: "2rem" }} onClick={() => updateInput({ color: "#f2c83f" })} />
                  <DropdownItem style={{ backgroundColor: "#ff7f3b", minHeight: "2rem" }} onClick={() => updateInput({ color: "#ff7f3b" })} />
                  <DropdownItem style={{ backgroundColor: "#f23f3f", minHeight: "2rem" }} onClick={() => updateInput({ color: "#f23f3f" })} />
                  <DropdownItem style={{ backgroundColor: "#ff8aed", minHeight: "2rem" }} onClick={() => updateInput({ color: "#ff8aed" })} />
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              <Input type="text" placeholder="Enter your label here" value={input.value || ""} onChange={e => updateInput({ value: e.currentTarget.value })} />
            </InputGroup>
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>Cancel</Button>
        <MutationButton readyState={readyState} color="primary" onClick={create}>Create</MutationButton>
      </ModalFooter>
    </Modal>
  );
}

enum Tab {
  Details = "DETAILS",
  Requirements = "REQUIREMENTS",
  Rules = "RULES",
  Emails = "EMAILS",
  Labels = "LABELS",
  Comments = "COMMENTS",
  Shares = "SHARES",
  Notifications = "NOTIFICATIONS",
  Descriptions = "DESCRIPTIONS",
  Documents = "DOCUMENTS",
  Additional = "ADDITIONAL"
}

export default ({ value, onUpdate, isOpen, toggle }: {
  value: ProductModel,
  isOpen?: boolean,
  toggle?: () => void,
  onUpdate?: (value: DeepPartial<ProductModel>) => void
}) => {
  const [t, d, n] = useTranslation();
  const confirmOnClick = useConfirmation();
  const [me] = useMe();
  const [tab, setTab] = useState(Tab.Details);
  const { addAttachment } = useAttachments();
  const [files, setFiles] = useState<File[]>([]);
  const [sharesInput, setSharesInput] = useState<ShareInputModel[]>();
  const [input, setInput] = useState(_toProductDetailsInputModel(value.details));
  const [manufacturerOrgName, setManufacturerOrgName] = useState(value.details.manufacturerOrg?.name);
  const [errors, setErrors] = useState<ApiErrors<ProductDetailsInputModel>>({});
  const [isOpenRules, toggleRules] = useToggle();
  const [isOpenAdvanced, toggleAdvanced] = useToggle();
  const [isOpenAreas, toggleAreas] = useToggle();
  const [isOpenLanguages, toggleLanguages] = useToggle();
  const [isOpenCompensationCurrency, toggleCompensationCurrency] = useToggle();
  const [isOpenArchivedLabels, toggleArchivedLabels] = useToggle();
  const [isOpenArchivedRules, toggleArchivedRules] = useToggle();
  const [ruleKey, setRuleKey] = useState("");
  const [isOpenRuleSms, setIsOpenRuleSms] = useState(false);
  const [isOpenRuleLabels, setIsOpenRuleLabels] = useState(false);
  const [isOpenRuleAttachments, setIsOpenRuleAttachments] = useState(false);
  const [isOpenCity, toggleCity] = useToggle();
  const [isOpenProductChooser, toggleProductChooser] = useToggle();
  const [mutation] = useMutation<OperationResults>();
  const updateInput = (props: Partial<ProductDetailsInputModel>) => setInput({ ...input, ...props });
  const updateLabel = (label: DeepPartial<LabelModel>) => updateInput({ labels: merge(input.labels, [label]) });
  const reset = () => {
    const input = _toProductDetailsInputModel(value.details);
    setInput(input);
    setTab(Tab.Details);
    setFiles([]);
    setErrors({});
    setSharesInput(undefined);
    resetRule(input.rules?.[0]);
  }
  const resetRule = (rule?: RuleInputModel) => {
    setRuleKey(rule?.key || nextNonce());
    setIsOpenRuleSms(!!rule?.message?.shortContent || false);
    setIsOpenRuleAttachments((rule?.attachments?.length ?? 0) > 0 || false);
    setIsOpenRuleLabels((rule?.labels?.length ?? 0) > 0 || !!rule?.comment?.message || false);
  }
  useEffect(reset, [value.id]);
  const create = async () => {
    try {
      const hasShares = !!value.actions.share && !!sharesInput;
      const result = await mutation(_createProduct, { details: input, hasShares, shares: sharesInput }, { files });
      onUpdate?.(result.product.commit);
      toggle?.();
    } catch (errors) {
      setErrors(errors && (errors as OperationErrors).product.details.change.value || {});
    }
  }
  const update = async () => {
    try {
      const hasShares = !!value.actions.share && !!sharesInput;
      const result = await mutation(_updateProductDetails, { id: value.id, value: input, hasShares, shares: sharesInput }, { files });
      onUpdate?.({ ...result.product.commit, details: setReplace(result.product.commit.details) });
      toggle?.();
    } catch (errors) {
      setErrors(errors && (errors as OperationErrors).product.details.change.value || {});
    }
  }
  const link = async (id_to: string) => {
    const result = await mutation(_linkProduct, { id: value.id, id_to });
    onUpdate?.(result.product.commit);
  }
  const unlink = async () => {
    const result = await mutation(_unlinkProduct, { id: value.id });
    onUpdate?.(result.product.commit);
  }
  const cancel = () => {
    setInput(_toProductDetailsInputModel(value.details));
    toggle?.();
  }
  const functionPrefix = input.function?.replace("_FUNCTION", "");

  const ruleInput = input.rules.filter(_ => _.key === ruleKey)[0] || _toRuleInputModel({});
  const ruleErrors = errors.rules?.filter(_ => _ && _.key === ruleKey)[0] || {};
  const shortContentMaxLength = 300 + _shortUrlGrace(ruleInput && ruleInput.message?.shortContent || "");
  const preview = _getPreview(t, value, input, me, ruleInput);

  const newRuleInput = () => {
    const rule = _toRuleInputModel({ key: nextNonce(), name: `Rule ${input.rules.length + 1}` });
    setTab(Tab.Rules);
    updateInput({ rules: [...input.rules, rule] });
    resetRule(rule);
    toggleRules(false);
  }
  const delRuleInput = (key: string) => {
    const rules = input.rules.filter(_ => _.key != key);
    const rule = rules[0] || {};
    updateInput({ rules });
    resetRule(rule);
  }
  const setRuleInput = (key: string) => {
    const rule = input.rules.filter(_ => _.key === key)[0] || _toRuleInputModel({ key });
    setTab(Tab.Rules);
    resetRule(rule);
    toggleRules(false);
  }
  const setRuleIsArchived = (key: string, isArchived: boolean) => {
    const rules = input.rules.map(_ => _.key === key ? ({ ..._, isArchived }) : _);
    updateInput({ rules });
  }
  const updateRuleInput = (props: Partial<RuleInputModel>) => {
    const value = { ...ruleInput, ...props, key: ruleKey };
    const rules = [...input.rules.filter(_ => _.key !== ruleKey), value]
      .sort((a, b) => a.name < b.name ? -1 : 1);
    updateInput({ rules });
    setIsOpenRuleAttachments(!!value.attachments?.length);
    setIsOpenRuleSms(!!value.message?.shortContent);
  }
  const updateAttachmentInput = (attachment: DeepPartial<AttachmentInputModel>, file?: File) => {
    updateRuleInput({ attachments: merge(ruleInput.attachments, [attachment]) });
    file && setFiles([...files, file]);
  }
  const attachFile = async (file: File) => {
    const fake = { key: nextNonce(), filename: file.name } as AttachmentModel;
    onUpdate?.({ attachments: [fake] });
    const _attachment = await addAttachment(value.id, ResourceKind.Product, AttachmentType.Other, file, value.policy?.accessToken);
    const attachment = { ..._attachment, key: fake.key };
    onUpdate?.({ attachments: [attachment] });
  }
  const selectFile = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener("change", async () => {
      const file = input.files?.[0];
      file && attachFile(file);
    });
    input.click();
  }
  const updateMessageInput = (props: Partial<MessageInputModel>) => {
    updateRuleInput({ message: { ...ruleInput.message, ...props } });
  }
  const updateManufacturerOrg = (id: string | undefined, name: string, org?: ReferenceDataModel) => {
    if (org?.organization) {
      updateInput({ manufacturerOrgId: id });
      setManufacturerOrgName(org.organization.name)
    }
  }
  const updateLocationInput = (locality: string) => {
    const city = CityCodes.filter((k: string) => t(k) === locality);
    updateInput({
      location: {
        ...input.location,
        locality: city.length ? "" : locality,
        city: city.length ? city[0] as CityCode : CityCode.Other
      }
    });
  }
  const updateCompensationInput = (compensation: Partial<MoneyInputModel>) => updateInput({ compensation: { ...input.compensation, ...compensation } })
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.items
      ? Array.from(e.dataTransfer.items).filter(_ => _.kind === "file").map(_ => _.getAsFile() as File)
      : Array.from(e.dataTransfer.files);
    if (droppedFiles.length > 0) {
      updateRuleInput({
        attachments: [
          ...(ruleInput.attachments || []),
          ...droppedFiles.map(_ => ({ ..._newAttachment, type: AttachmentType.Other, content: _.name, filename: _.name }))
        ]
      });
      setFiles([...files, ...droppedFiles]);
      setIsOpenRuleAttachments(true);
    }
  }
  const toggleOrdersStages = (value: OrderStage) => {
    const has = input.ordersStages.includes(value);
    updateInput({ ordersStages: has ? input.ordersStages.filter(_ => _ != value) : [...input.ordersStages, value] });
  }
  const toggleNotificationPreferences = (value: ResourceEvent) => {
    const has = input.notificationPreferences.includes(value);
    updateInput({ notificationPreferences: has ? input.notificationPreferences.filter(_ => _ != value) : [...input.notificationPreferences, value] });
  }
  const toggleAttachments = () => setIsOpenRuleAttachments(!isOpenRuleAttachments);
  const showDefaultRecipients = ruleInput.action === RuleActionType.MessageOrderCustomer;
  const messageInput = ruleInput.message || {};
  const messageErrors = ruleErrors.message || {};
  const isValid = IsValid(input);
  const hasDefaultRecipients = ruleInput.includeDefaultRecipients;
  const toggleDefaultRecipients = () => {
    updateRuleInput({ includeDefaultRecipients: !hasDefaultRecipients });
  }
  const toggleDynamicWorkItems = () => {
    if (input.ordersJobsWorkitemsRelatedIds?.length) {
      setInput({ ...input, ordersJobsWorkitemsRelatedIds: [] });
    } else {
      setInput({ ...input, ordersJobsWorkitemsRelatedIds: [value.details.manufacturerOrg.id] });
    }
  }
  const toggleArea = (value: AreaType) => {
    const areas = input.areas.includes(value) ? input.areas.filter(v => v !== value) : [...input.areas, value];
    updateInput({ areas });
  }
  const toggleLanguage = (value: LanguageCode) => {
    const languages = input.languages.includes(value) ? input.languages.filter(v => v !== value) : [...input.languages, value];
    updateInput({ languages });
  }

  const ruleTemplateKey = _getRuleTemplateKey(ruleInput);
  const ruleTemplate = _getRuleTemplate(ruleTemplateKey, input);
  const ruleAttachmentTypes = [
    ruleInput.action !== RuleActionType.MessageOrderCustomer && !ruleInput.attachments?.some(_ => _.type === AttachmentType.Resume) && AttachmentType.Resume,
    ruleInput.type === RuleType.Scheduled && !ruleInput.attachments?.some(_ => _.type === AttachmentType.ProductOrdersReport) && AttachmentType.ProductOrdersReport,
    [ResourceEvent.SupplierScheduledScreening, ResourceEvent.SupplierRescheduledScreening].some(_ => ruleInput.eventsTrigger.includes(_)) && !ruleInput.attachments?.some(_ => _.type === AttachmentType.OrderScreeningCalendarEvent) && AttachmentType.OrderScreeningCalendarEvent,
    [ResourceEvent.SupplierScheduledInterview, ResourceEvent.SupplierRescheduledInterview].some(_ => ruleInput.eventsTrigger.includes(_)) && !ruleInput.attachments?.some(_ => _.type === AttachmentType.OrderInterviewCalendarEvent) && AttachmentType.OrderInterviewCalendarEvent,
    [ResourceEvent.CustomerAcceptedOffer].some(_ => ruleInput.eventsTrigger.includes(_)) && !ruleInput.attachments?.some(_ => _.type === AttachmentType.OrderStartCalendarEvent) && AttachmentType.OrderStartCalendarEvent,
    AttachmentType.Other
  ].filter((_): _ is AttachmentType => !!_);
  const activeLabels = input.labels.filter(_ => !_.isArchived);
  const ruleLabels = ruleInput.labels.filter(_ => activeLabels.some(l => l.key === _.key) || _.color || _.value);
  const ruleLabelOptions = activeLabels.filter(_ => !ruleLabels.some(l => l.key === _.key));
  const ruleAttachments = ruleInput.attachments.filter(_ => _.type !== AttachmentType.InternalReference || value.attachments.some(a => _.contentUri?.indexOf(a.id) >= 0));

  const showDuration = input.type === ProductType.EmploymentTrainingLevel
    || input.duration !== "P65535D";
  const showSubtype = input.type === ProductType.EmploymentTrainingLevel
    || input.subtype !== ProductType.Null;
  const showRuleMessage = ruleInput.action === RuleActionType.MessageOrderCustomer
    || ruleInput.action === RuleActionType.MessageProductSupplier
    || ruleInput.action === RuleActionType.MessageOther;
  const classNames = [
    tab === Tab.Descriptions ? "w-max-75" : ""
  ].join(" ");

  return (
    <Modal className={classNames} isOpen={isOpen}>
      <div className='modal-header bb-0 w-100'>
        <FormGroup label="Title" error={errors.title} className="w-100 mb-0" required minLength={2} value={input.title} maxLength={140}>
          <ValidatingInput type="text" bsSize="lg" placeholder="Enter your listing title here" onUpdate={title => updateInput({ title })} />
        </FormGroup>
      </div>
      <ModalBody className="pt-0">
        <Nav>
          <NavItem>
            <NavLink active={tab === Tab.Details} href="#" onClick={() => setTab(Tab.Details)}>Details</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={tab === Tab.Requirements} href="#" onClick={() => setTab(Tab.Requirements)}>Requirements</NavLink>
          </NavItem>
          <Dropdown nav isOpen={isOpenRules} toggle={toggleRules}>
            <DropdownToggle nav caret className={tab === Tab.Rules ? "active" : ""}>Rules</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={newRuleInput}>New rule</DropdownItem>
              {input.rules.length > 0 && <DropdownItem divider />}
              {input.rules.filter(_ => !_.isArchived).map((_, i) => <CustomDropdownItem key={i} active={ruleKey === _.key} onClick={() => setRuleInput(_.key)} message={_.isEnabled ? undefined : "Disabled"} showArchive onArchive={() => setRuleIsArchived(_.key, true)}>{_.name}</CustomDropdownItem>)}
              {input.rules.filter(_ => _.isArchived).length > 0 &&
                <>
                  <CustomDropdownItem isOpen={isOpenArchivedRules} toggle={false} onClick={toggleArchivedRules}>Archived</CustomDropdownItem>
                  <Collapse isOpen={isOpenArchivedRules}>
                    {input.rules.filter(_ => _.isArchived).map((_, i) => <CustomDropdownItem key={i} className="pl-4" active={ruleKey === _.key} onClick={() => setRuleInput(_.key)} showUnarchive onUnarchive={() => setRuleIsArchived(_.key, false)} showRemove onRemove={confirmOnClick(() => delRuleInput(_.key), value.collection.length > 0 ? "Are you sure you want to remove this rule from this and all linked listings?" : "Are you sure you want to remove this rule?")}>{_.name}</CustomDropdownItem>)}
                  </Collapse>
                </>
              }
            </DropdownMenu>
          </Dropdown>
          <Dropdown nav isOpen={isOpenAdvanced} toggle={toggleAdvanced}>
            {[Tab.Details, Tab.Requirements, Tab.Rules].includes(tab) && <DropdownToggle nav caret>Advanced</DropdownToggle>}
            {tab === Tab.Emails && <DropdownToggle nav caret className="active">Email settings</DropdownToggle>}
            {tab === Tab.Labels && <DropdownToggle nav caret className="active">Label settings</DropdownToggle>}
            {tab === Tab.Comments && <DropdownToggle nav caret className="active">Comment settings</DropdownToggle>}
            {tab === Tab.Shares && <DropdownToggle nav caret className="active">Share settings</DropdownToggle>}
            {tab === Tab.Notifications && <DropdownToggle nav caret className="active">Notification settings</DropdownToggle>}
            {tab === Tab.Descriptions && <DropdownToggle nav caret className="active">Description settings</DropdownToggle>}
            {tab === Tab.Documents && <DropdownToggle nav caret className="active">Document settings</DropdownToggle>}
            {tab === Tab.Additional && <DropdownToggle nav caret className="active">Additional settings</DropdownToggle>}
            <DropdownMenu>
              <DropdownItem onClick={() => setTab(Tab.Emails)}>Email settings</DropdownItem>
              <DropdownItem onClick={() => setTab(Tab.Labels)}>Label settings</DropdownItem>
              <DropdownItem onClick={() => setTab(Tab.Comments)}>Comment settings</DropdownItem>
              {value.actions?.share && <DropdownItem onClick={() => setTab(Tab.Shares)}>Share settings</DropdownItem>}
              <DropdownItem onClick={() => setTab(Tab.Notifications)}>Notification settings</DropdownItem>
              <DropdownItem onClick={() => setTab(Tab.Descriptions)}>Description settings</DropdownItem>
              <DropdownItem onClick={() => setTab(Tab.Documents)}>Document settings</DropdownItem>
              <DropdownItem onClick={() => setTab(Tab.Additional)}>Additional settings</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
        <Form className="mt-3">
          <TabContent activeTab={tab}>
            <TabPane tabId={Tab.Details}>
              <fieldset>
                <FormGroup label="Subtitle" error={errors.subtitle} value={input.subtitle} maxLength={140}>
                  <ValidatingInput type="text" placeholder="Enter your listing subtitle here" onUpdate={subtitle => updateInput({ subtitle })} />
                </FormGroup>
                <FormGroup label='Description' error={errors.description} minLength={140} value={input.description} maxLength={1400} showPreview>
                  <Input type="textarea" rows={5} placeholder="Describe your listing here" value={input.description || ""} onChange={e => updateInput({ description: e.currentTarget.value, skipOrgDescription: !e.currentTarget.value ? false : input.skipOrgDescription })} />
                </FormGroup>
                <FormGroup label="Team">
                  <DatalistInput id="orgName" types={[ReferenceDataType.OrganizationName]} className={`form-control ${errors.title && "is-invalid"}`} placeholder="Enter team name here" value={manufacturerOrgName || ""} onUpdate={updateManufacturerOrg} />
                </FormGroup>
                <FormGroup>
                  <CustomInput id="skipOrgDescription-switch" type="switch" checked={input.skipOrgDescription} onChange={() => updateInput({ skipOrgDescription: !input.skipOrgDescription })} label="Hide team description" />
                </FormGroup>
                <Row form>
                  <Col>
                    <FormGroup label='Type' required error={errors.type}>
                      <select className="custom-select" value={input.type} onChange={e => updateInput({ type: e.currentTarget.value as ProductType, ...(_types.get(e.currentTarget.value as ProductType) || {}) })}>
                        <option value=""></option>
                        {Array.from(_types.keys()).filter(_ => _ !== ProductType.CustomerService).map((_, i) => <option key={i} value={_}>{t(_)}</option>)}
                        <Show scope={Scopes.SiteAdmin}>
                          <option value={ProductType.CustomerService}>Customer service</option>
                        </Show>
                      </select>
                    </FormGroup>
                  </Col>
                  {showSubtype &&
                    <Col>
                      <FormGroup label='Subtype' error={errors.subtype}>
                        <select className="custom-select" value={input.subtype} onChange={e => updateInput({ subtype: e.currentTarget.value as ProductType })}>
                          <option value={ProductType.Null}></option>
                          <option value={ProductType.EmploymentFullTime}>Full-time</option>
                          <option value={ProductType.EmploymentPartTime}>Part-time</option>
                          <option value={ProductType.EmploymentTemporary}>Temporary</option>
                          <option value={ProductType.EmploymentRemote}>Remote</option>
                        </select>
                      </FormGroup>
                    </Col>
                  }
                  {showDuration &&
                    <Col>
                      <FormGroup label='Duration' error={errors.duration}>
                        <select className="custom-select" value={input.duration || ""} onChange={e => updateInput({ duration: e.currentTarget.value })}>
                          <option value=""></option>
                          <option value="P28D">4 weeks</option>
                          <option value="P56D">8 weeks</option>
                          <option value="P84D">12 weeks</option>
                          <option value="P65535D">No end date</option>
                        </select>
                      </FormGroup>
                    </Col>
                  }
                </Row>
                <Collapse isOpen={input.subtype !== ProductType.EmploymentRemote}>
                  <FormGroup label='Address' maxLength={140} value={input.location.streetAddress} error={errors?.location?.streetAddress}>
                    <ValidatingInput type="text" onUpdate={streetAddress => updateInput({ location: { ...input.location, streetAddress } })} />
                  </FormGroup>
                  <Row form>
                    <Col>
                      <FormGroup label='Neighborhood' value={input.location.neighborhood} error={errors?.location?.neighborhood}>
                        <ValidatingInput type="text" maxLength={140} onUpdate={neighborhood => updateInput({ location: { ...input.location, neighborhood } })} />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup label='City' error={errors?.location?.locality}>
                        <InputGroup>
                          <Input value={input.location.city && input.location.city !== CityCode.Other ? t(input.location.city) : input.location.locality} onChange={e => updateLocationInput(e.currentTarget.value)} />
                          <Dropdown className="btn-input" isOpen={isOpenCity} toggle={toggleCity}>
                            <DropdownToggle color="input" caret />
                            <DropdownMenu>
                              {CityCodes.sort(byKey(t)).filter(_ => _ && _ != CityCode.Other).map(_ =>
                                <DropdownItem key={_} onClick={() => updateLocationInput(t(_))}>{t(_)}</DropdownItem>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Collapse>
                <Row form>
                  <Col sm={4}>
                    <FormGroup label='Function' error={errors.function} required value={input.function}>
                      <select className="custom-select" value={input.function} onChange={e => updateInput({ function: e.currentTarget.value as FunctionType, service: input.service?.startsWith(e.currentTarget.value.replace("FUNCTION", "")) ? input.service : ServiceType.Null, areas: input.areas.filter(_ => _.startsWith(e.currentTarget.value.replace("FUNCTION", ""))) })}>
                        {Object.values(FunctionType).sort(byKey(t)).map(k => <option key={k} value={k}>{t(k)}</option>)}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup label='Practice' error={errors.service}>
                      <select className="custom-select" value={input.service} onChange={e => updateInput({ service: e.currentTarget.value as ServiceType })}>
                        {ServiceTypes
                          .filter((k: string) => k.startsWith(functionPrefix) || k === ServiceType.Null)
                          .sort(byKey(t))
                          .map(k => <option key={k} value={k}>{t(k)}</option>)}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup label='Focus' errors={errors.areas}>
                      <Dropdown className="w-100 d-flex btn-input p-1px" isOpen={isOpenAreas} toggle={toggleAreas}>
                        <Button className="w-100 btn-label text-left text-nowrap text-truncate" color="input" onClick={toggleAreas}>
                          <span>
                            {input.areas.length == 1 && t(input.areas[0])}
                            {input.areas.length > 1 && `${input.areas.length} focuses`}
                          </span>
                        </Button>
                        <DropdownToggle color="input" caret />
                        <DropdownMenu className="columns-2">
                          {Object.values(AreaType)
                            .filter((k: string) => k.startsWith(functionPrefix))
                            .sort(byKey(t))
                            .map(k => <CustomDropdownItem key={k} disabled={input.areas.length >= 3 && !input.areas.includes(k)} checked={input.areas.includes(k)} value={k} onClick={() => toggleArea(k)}>{t(k)}</CustomDropdownItem>)}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm={6}>
                    <FormGroup label='Compensation' error={errors.compensation?.amount}>
                      <InputGroup>
                        <InputGroupButtonDropdown addonType="prepend" isOpen={isOpenCompensationCurrency} toggle={toggleCompensationCurrency}>
                          <DropdownToggle color="input" caret>{t(input.compensation.currency)}</DropdownToggle>
                          <DropdownMenu>
                            {[CurrencyCode.Null, CurrencyCode.Inr].map((_, i) =>
                              <DropdownItem key={i} onClick={() => updateCompensationInput({ currency: _ })}>{t(_)}</DropdownItem>
                            )}
                          </DropdownMenu>
                        </InputGroupButtonDropdown>
                        <Input className="pl-0" value={input.compensation.amount && n(input.compensation.amount) || ""} placeholder="Enter gross compensation" onChange={e => updateCompensationInput({ amount: parseInt(e.currentTarget.value.replace(/[^0-9]/g, "")), currency: input.compensation.currency === CurrencyCode.Null ? CurrencyCode.Inr : input.compensation.currency })} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup label="Variable" error={errors.compensation?.variableFraction}>
                      <InputGroup>
                        <Input value={input.compensation.amount && input.compensation.variableFraction || ""} disabled={!input.compensation.amount} onChange={e => updateCompensationInput({ variableFraction: parseInt(e.currentTarget.value.replace(/[^0-9]/g, "")) })} />
                        <InputGroupAddon addonType="append"><InputGroupText>%</InputGroupText></InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup label={"\u00A0"} error={errors.compensation?.period}>
                      <select className="custom-select" disabled={!input.compensation.amount} value={input.compensation.amount ? input.compensation.period : ""} onChange={e => updateCompensationInput({ period: e.currentTarget.value })}>
                        <option value=""></option>
                        <option value="P30D">per month</option>
                        <option value="P365D">per year</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <CustomInput id="isCompensationPrivate-switch" type="switch" checked={!input.compensation.amount || input.compensation.isPrivate} disabled={!input.compensation.amount} onChange={() => updateCompensationInput({ isPrivate: !input.compensation.isPrivate })} label="Hide compensation from listing" />
                <CustomInput id="isPrivate-switch" type="switch" checked={input.isPrivate} onChange={() => updateInput({ isPrivate: !input.isPrivate })} label="Hide listing from searches" />
                {value.id && value?.actions?.link && <CustomInput id="hasVariants-switch" type="switch" checked={false} onChange={toggleProductChooser} label="Link with other variants" />}
                {value.id && value?.actions?.unlink && <CustomInput id="hasVariants-switch" type="switch" checked onChange={unlink} label="Link with other variants" />}
                <Collapse isOpen={value.collection?.length > 0}>
                  <Row form className='mt-3'>
                    <Col sm={4}>
                      <FormGroup label='Variant type' error={errors.variantType}>
                        <select className="custom-select" value={input.variantType} onChange={e => updateInput({ variantType: e.currentTarget.value as ProductVariantType })}>
                          {[ProductVariantType.Location, ProductVariantType.ServiceAreas].map((_, i) =>
                            <option key={i} value={_}>{t(_)}</option>
                          )}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm={8}>
                      <FormGroup label='Variant title' value={input.variantTitle} error={errors?.variantTitle}>
                        <ValidatingInput type="text" placeholder="Enter short title for this listing" maxLength={140} onUpdate={variantTitle => updateInput({ variantTitle })} />
                      </FormGroup>
                    </Col>
                  </Row>
                </Collapse>
              </fieldset>
            </TabPane>
            <TabPane tabId={Tab.Emails}>
              <fieldset>
                <FormGroup label='Copy email' help="Updates and notifications will be copied to this address. Separate multiple emails with commas.">
                  <Input type="text" placeholder="Enter recipient email addresses" value={input.supplierRecipients || ""} onChange={e => updateInput({ supplierRecipients: e.currentTarget.value })} />
                </FormGroup>
                {value.supplier?.key &&
                  <>
                    <FormGroup label='Managed email' help="Emails received at this address will create applications under this listing." error={errors.key} invalidCharacters={_invalid} lower value={input.key || ""}
                      addon={<CopyButton size="sm" disabled={!input.key} value={`${input.key}@${value.supplier.key}.higherknowledge.in`} />}
                    >
                      <InputGroup>
                        <ValidatingInput className="text-right pr-0" type="text" onUpdate={key => updateInput({ key })} />
                        <InputGroupAddon addonType="append">
                          <InputGroupText className="pl-0">@{value.supplier.key}.higherknowledge.in</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                    <Collapse isOpen={!!input.key}>
                      <FormGroup label='Forwarded emails' help="Emails forwarded from these addresses will add the original sender to this listing, not the person forwarding the email. Enter @domain to enable any user in domain to forward emails to create invitations. Separate multiple emails and @domains with commas.">
                        <Input type="text" placeholder="Enter sender emails and @domains" value={input.supplierOriginAddresses || ""} onChange={e => updateInput({ supplierOriginAddresses: e.currentTarget.value })} />
                      </FormGroup>
                    </Collapse>
                  </>
                }
              </fieldset>
            </TabPane>
            <TabPane tabId={Tab.Requirements}>
              <fieldset>
                <Row form>
                  <Col>
                    {input.type !== ProductType.EmploymentTrainingLevel &&
                      <FormGroup label='Experience' error={errors.minCustomersGraduationIn}>
                        <select className="custom-select" value={`${input.minCustomersGraduationIn || ""},${input.maxCustomersGraduationIn || ""}`} onChange={e => updateInput({ minCustomersGraduationIn: e.currentTarget.value.split(",")[0], maxCustomersGraduationIn: e.currentTarget.value.split(",")[1] })}>
                          <option value=","></option>
                          {[",P365D"].map((_, i) =>
                            <option key={i} value={_}>graduating {d(_.split(",")[0], DateDisplayFormat.YearDurationRangeIn, _.split(",")[1])}</option>
                          )}
                          {["-P365D,", "-P730D,", "-P730D,-P365D", "-P1095D,-P730D", "-P1825D,-P1095D", ",-P1825D"].map((_, i) =>
                            <option key={i + 1} value={_}>{d(_.split(",")[0], DateDisplayFormat.NegativeYearDurationRange, _.split(",")[1])}</option>
                          )}
                        </select>
                      </FormGroup>
                    }
                    {input.type === ProductType.EmploymentTrainingLevel &&
                      <FormGroup label='Graduation' error={errors.minCustomersGraduationIn}>
                        <select className="custom-select" value={`${input.minCustomersGraduationIn || ""},${input.maxCustomersGraduationIn || ""}`} onChange={e => updateInput({ minCustomersGraduationIn: e.currentTarget.value.split(",")[0], maxCustomersGraduationIn: e.currentTarget.value.split(",")[1] })}>
                          <option value=","></option>
                          {[",P365D", ",P730D", ",P1095D"].map((_, i) =>
                            <option key={i} value={_}>{d(_.split(",")[0], DateDisplayFormat.YearDurationRangeIn, _.split(",")[1])}</option>
                          )}
                        </select>
                      </FormGroup>
                    }
                  </Col>
                  <Col>
                    <FormGroup label='Language' errors={errors.areas}>
                      <Dropdown className="w-100 d-flex btn-input p-1px" isOpen={isOpenLanguages} toggle={toggleLanguages}>
                        <Button className="w-100 btn-label text-left text-nowrap text-truncate" color="input" onClick={toggleLanguages}>
                          <span>
                            {input.languages.length == 1 && t(input.languages[0])}
                            {input.languages.length == 2 && input.languages.map(t).join(", ")}
                            {input.languages.length > 2 && `${input.languages.length} languages`}
                          </span>
                        </Button>
                        <DropdownToggle color="input" caret />
                        <DropdownMenu className="columns-2">
                          {Object.values(LanguageCode)
                            .filter(k => k !== LanguageCode.Null)
                            .sort(byKey(t))
                            .map(k => <CustomDropdownItem key={k} disabled={input.languages.length >= 3 && !input.languages.includes(k)} checked={input.languages.includes(k)} value={k} onClick={() => toggleLanguage(k)}>{t(k)}</CustomDropdownItem>)}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup label='Requirements' value={input.requirementsDescription} error={errors.requirementsDescription} showPreview>
                  <ValidatingInput type="textarea" rows={5} placeholder="Provide any additional requirements here" onUpdate={requirementsDescription => updateInput({ requirementsDescription })} />
                </FormGroup>
                <FormGroup label='Instructions' value={input.ordersDescription} error={errors.ordersDescription} showPreview>
                  <ValidatingInput type="textarea" rows={5} placeholder="Provide any additional instructions here" onUpdate={ordersDescription => updateInput({ ordersDescription })} />
                </FormGroup>
                <Row form>
                  <Col sm={6}>
                    <FormGroup label='Due in' error={errors.ordersDueIn}>
                      <select className="custom-select" value={input.ordersDueIn || ""} onChange={e => updateInput({ ordersDueIn: e.currentTarget.value, ordersDueOn: undefined })}>
                        <option value=""></option>
                        <option value="P3D">3 days</option>
                        <option value="P5D">5 days</option>
                        <option value="P10D">10 days</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup label='Due on' error={errors.ordersDueOn}>
                      <Input type="date" value={input.ordersDueOn && moment(input.ordersDueOn).format("YYYY-MM-DD") || ""} onChange={e => updateInput({ ordersDueOn: e.currentTarget.value ? e.currentTarget.value : undefined, ordersDueIn: "" })} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup label="Options" className="mt-3">
                  <CustomInput id="assessment-switch" type="switch" checked={input.ordersJobsMaxCount !== 0} onChange={() => updateInput({ ordersJobsMaxCount: input.ordersJobsMaxCount === 0 ? -1 : 0, ordersJobsCount: input.ordersJobsMaxCount === 0 ? 3 : 0 })} label="Online assessments" />
                  <CustomInput id="phonescreen-switch" type="switch" checked={input.ordersStages.includes(OrderStage.ScreeningScheduled)} onChange={() => toggleOrdersStages(OrderStage.ScreeningScheduled)} label="Phone screen" />
                  <CustomInput id="interview-switch" type="switch" checked={input.ordersStages.includes(OrderStage.InterviewScheduled)} onChange={() => toggleOrdersStages(OrderStage.InterviewScheduled)} label="Interview" />
                </FormGroup>
                <Collapse isOpen={input.ordersJobsMaxCount !== 0}>
                  <>
                    <FormGroup label='Assessments' error={errors.ordersJobsCount} minValue={0} value={`${input.ordersJobsCount}`}>
                      <Input type="number" min={0} value={input.ordersJobsCount || ""} onChange={e => updateInput({ ordersJobsCount: Math.max(0, parseInt(e.currentTarget.value)) })} />
                    </FormGroup>
                    <CustomInput id="dynamic-workitems-switch" type="switch" checked={!input.ordersJobsWorkitemsRelatedIds?.length} onChange={toggleDynamicWorkItems} label="Dynamic assessments" />
                    <Collapse isOpen={!!input.ordersJobsWorkitemsRelatedIds?.length}>
                      <div className="text-muted small mb-3">
                        <FontAwesomeIcon className="mr-1 text-warning" icon="exclamation-triangle" />
                        Static assessments make it easy for applicants to discuss and copy solutions from other applicants.
                        HigherKnowledge strongly recommends dynamic assessments.
                    </div>
                    </Collapse>
                    <CustomInput id="byAreas-switch" type="switch" checked={!input.ordersJobsWorkitemsByAreas} onChange={() => updateInput({ ordersJobsWorkitemsByAreas: !input.ordersJobsWorkitemsByAreas })} label="Broad assessments" />
                  </>
                </Collapse>
              </fieldset>
            </TabPane>
            <TabPane tabId={Tab.Rules}>
              <fieldset>
                <FormGroup label='Name' error={ruleErrors.name} maxLength={140} length={ruleInput.name?.length}>
                  <Input type="text" placeholder="Enter the name for this rule" value={ruleInput.name} onChange={e => updateRuleInput({ name: e.currentTarget.value.substring(0, 140) })} />
                </FormGroup>
                <FormGroup label='Rule'>
                  <select className="custom-select" value={ruleTemplateKey} onChange={e => updateRuleInput(_rules.get(e.currentTarget.value) || {})}>
                    <option></option>
                    <option value="scheduled">On a schedule</option>
                    <option value="message-received">When a message is received</option>
                    <option value="added">When I add an applicant</option>
                    {(ruleTemplateKey === "create-requested" || input.isLocked) && <option value="create-requested">When an applicant is placed in the waiting list</option>}
                    {(ruleTemplateKey === "allowed" || input.isLocked) && <option value="allowed">When an applicant in the waiting list is allowed</option>}
                    <option value="created">When an applicant applies directly</option>
                    <option value="submitted">When an applicant submits their application</option>
                    <option value="created-submitted">When an application is created or submitted</option>
                    <option value="custom-event">When I trigger this action</option>
                    <option value="shortlisted">When I save an applicant to the shortlist</option>
                    {(ruleTemplateKey === "screening" || input.ordersStages.includes(OrderStage.ScreeningScheduled)) && <option value="screening">When I schedule a phone screening</option>}
                    {(ruleTemplateKey === "interviewing" || input.ordersStages.includes(OrderStage.InterviewScheduled)) && <option value="interviewing">When I schedule an interview</option>}
                    <option value="refused">When I refuse an applicant</option>
                    <option value="offered">When I extend an offer</option>
                    <option value="withdrawn">When I withdraw an offer</option>
                    <option value="accepted">When an applicant accepts the offer</option>
                    <option value="declined">When an applicant declines the offer</option>
                    <option value="delivered">When a new-hire is onboarded</option>
                  </select>
                </FormGroup>
                <Collapse isOpen={ruleInput.eventsTrigger.includes(ResourceEvent.MessageReceived)}>
                  <FormGroup label='From' help="Enter sender email address, or use @domain for all senders in that domain. Separate multiple emails and @domains with commas.">
                    <Input type="text" placeholder="Enter sender email addresses or @domains" value={ruleInput.messageMatch.senderEmails || ""} onChange={e => updateRuleInput({ messageMatch: { ...ruleInput.messageMatch, senderEmails: e.currentTarget.value } })} />
                  </FormGroup>
                  <FormGroup label='Containing' help="Enter each word or phrase match on a separate line.">
                    <Input type="textarea" rows={3} placeholder="Enter words or phrases to match" value={ruleInput.messageMatch.content || ""} onChange={e => updateRuleInput({ messageMatch: { ...ruleInput.messageMatch, content: e.currentTarget.value } })} />
                  </FormGroup>
                </Collapse>
                <FormGroup label="Action" addon={ruleTemplate && <a className="small text-primary" onClick={() => updateRuleInput(ruleTemplate)}>Reset</a>}>
                  <select className="custom-select" value={ruleInput.action || ""} onChange={e => updateRuleInput({ action: e.currentTarget.value as RuleActionType })}>
                    <option value=""></option>
                    <option value={RuleActionType.UpdateOrder}>Update the application</option>
                    <option value={RuleActionType.MessageOrderCustomer}>Inform the applicant</option>
                    <option value={RuleActionType.MessageProductSupplier}>Inform me</option>
                    <option value={RuleActionType.MessageOther}>Inform an email address</option>
                  </select>
                </FormGroup>
                <Collapse isOpen={ruleInput.type === RuleType.Scheduled}>
                  <Row form>
                    <Col sm={6}>
                      <FormGroup label='Next date' error={ruleErrors.nextTriggerOn}>
                        <Input type="date" value={ruleInput.nextTriggerOn && moment(ruleInput.nextTriggerOn).format("YYYY-MM-DD") || ""} onChange={e => updateRuleInput({ nextTriggerOn: e.currentTarget.value })} />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup label='Repeat' error={ruleErrors.nextTriggerIn}>
                        <select className="custom-select" value={ruleInput.nextTriggerIn || ""} onChange={e => updateRuleInput({ nextTriggerIn: e.currentTarget.value })}>
                          <option value="">Do not repeat</option>
                          <option value="P7D">Every week</option>
                          <option value="P15D">Every two weeks</option>
                          <option value="P30D">Every month</option>
                          <option value="P1Y">Every year</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </Collapse>
                <Collapse isOpen={ruleInput.action === RuleActionType.MessageOther}>
                  <FormGroup label='To' help="Separate multiple emails with commas.">
                    <Input type="text" placeholder="Enter recipient email addresses" value={messageInput.recipientEmails || ""} onChange={e => updateMessageInput({ recipientEmails: e.currentTarget.value })} />
                  </FormGroup>
                </Collapse>
                <Collapse isOpen={showRuleMessage}>
                  <FormGroup label='Subject' error={messageErrors.subject} required minLength={7} maxLength={140} value={messageInput.subject}>
                    <ValidatingInput type="text" onUpdate={subject => updateMessageInput({ subject })} />
                  </FormGroup>
                  <div onDrop={handleDrop} onDragOver={doNothing}>
                    <FormGroup label='Message' error={messageErrors.content} required value={messageInput.content} showPreview preview={preview}>
                      <ValidatingInput type="textarea" rows={10} onUpdate={content => updateMessageInput({ content })} />
                    </FormGroup>
                    <FormGroup>
                      <CustomInput id='add-attachment' type="switch" disabled={ruleAttachments.length > 0} label="Send an attachment" checked={isOpenRuleAttachments} onChange={toggleAttachments} />
                    </FormGroup>
                    <Collapse isOpen={isOpenRuleAttachments}>
                      <div>
                        {ruleAttachments.length > 0 &&
                          <ul className="list-unstyled mb-0">
                            {ruleAttachments.map((_, i) =>
                              <li key={i} className="d-flex">
                                <FontAwesomeIcon className="mr-2" icon="paperclip" />
                                {_.type === AttachmentType.Other ? <span>{ToName(_)}</span>
                                  : _.type === AttachmentType.InternalReference ? <span>{ToName(value.attachments.filter(a => (_.contentUri || "").indexOf(a.id) >= 0)[0])}</span>
                                    : <span>{t(_.type)}</span>}
                                <Button color="link" size="sm" className="ml-auto" onClick={() => updateAttachmentInput(setDelete(_))}>Remove</Button>
                              </li>
                            )}
                          </ul>
                        }
                        <AttachmentInput values={ruleAttachments} product={value} types={ruleAttachmentTypes} onUpdate={updateAttachmentInput} />
                      </div>
                    </Collapse>
                  </div>
                  <Collapse isOpen={showDefaultRecipients}>
                    <FormGroup className="mt-3">
                      <CustomInput id='enable-default-recipients' type="switch" label="Send my team a copy" checked={hasDefaultRecipients || false} onChange={toggleDefaultRecipients} />
                    </FormGroup>
                  </Collapse>
                  <FormGroup>
                    <CustomInput id='select-sms' disabled={isOpenRuleSms && !!messageInput.shortContent} type="switch" label="Send a text message" checked={isOpenRuleSms} onChange={() => setIsOpenRuleSms(!isOpenRuleSms)} />
                  </FormGroup>
                  <Collapse isOpen={isOpenRuleSms}>
                    <FormGroup label='Message' error={messageErrors.shortContent} maxLength={shortContentMaxLength} value={messageInput.shortContent} length={messageInput.shortContent.length} showPreview preview={preview}>
                      <Input type="textarea" rows={3} placeholder="" value={messageInput.shortContent} onChange={e => updateMessageInput({ shortContent: e.currentTarget.value.substring(0, shortContentMaxLength) })} />
                    </FormGroup>
                  </Collapse>
                </Collapse>
                <FormGroup>
                  <CustomInput id='select-add-label' type="switch" label="Add labels or a comment" checked={isOpenRuleLabels} onChange={() => setIsOpenRuleLabels(!isOpenRuleLabels)} />
                </FormGroup>
                <Collapse isOpen={isOpenRuleLabels}>
                  {activeLabels.length > 0 &&
                    <FormGroup label='Labels' >
                      <div className="p-2 lh-1 bg-input w-100">
                        {ruleLabels.map((_, i) => <CardBadge key={i} className="cursor-pointer" size="inline" style={{ backgroundColor: _.color || input.labels.filter(l => l.key === _.key)[0]?.color || '#f8f9fa', minWidth: "1.75rem" }} value={_.value || input.labels.filter(l => l.key === _.key)[0]?.value || '\u00A0'} remove onClick={() => updateRuleInput({ labels: ruleInput.labels.filter(l => l.key !== _.key) })} />)}
                        {ruleLabelOptions.length > 0 &&
                          <UncontrolledDropdown tag="span" className="lh-1">
                            <DropdownToggle tag="span" className="mr-1 badge badge-white cursor-pointer">
                              <FontAwesomeIcon icon="plus" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="px-2" style={{ minWidth: "300px" }}>
                                {ruleLabelOptions.map((_, i) => <CardBadge key={i} className="cursor-pointer" size="inline" value={_.value} style={{ backgroundColor: _.color || '#f8f9fa', minWidth: "1.75rem" }} onClick={() => updateRuleInput({ labels: [...ruleInput.labels, { key: _.key } as LabelModel] })} />)}
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        }
                      </div>
                    </FormGroup>
                  }
                  <FormGroup label='Comment'>
                    <InputGroup>
                      <Input type="text" placeholder="Enter comment text" value={ruleInput.comment?.message || ""} onChange={e => updateRuleInput({ comment: { ...ruleInput.comment, message: e.currentTarget.value } })} />
                      <DiscussionSettingsMenu settings={_ruleCommentSettings} input compact value={ruleInput.comment} onUpdate={comment => updateRuleInput({ comment: { ...ruleInput.comment, ...comment } })} />
                    </InputGroup>
                  </FormGroup>
                </Collapse>
                <FormGroup>
                  <CustomInput id='enable-rule' type="switch" label="Enable this rule" checked={ruleInput.isEnabled || false} onChange={() => updateRuleInput({ isEnabled: !ruleInput.isEnabled })} />
                </FormGroup>
              </fieldset>
            </TabPane>
            <TabPane tabId={Tab.Labels} >
              <fieldset>
                {!isOpenArchivedLabels &&
                  <FormGroup className="mt-3">
                    {input.labels.filter(_ => !_.isArchived).map((_, i) =>
                      <InputGroup key={i} className="mt-1">
                        <UncontrolledButtonDropdown addonType="prepend">
                          <DropdownToggle style={{ backgroundColor: _.color || '#f8f9fa', border: 0, minWidth: "2.5rem" }} />
                          <DropdownMenu className="p-0">
                            <DropdownItem style={{ backgroundColor: "#f8f9fa", minHeight: "2rem" }} onClick={() => updateLabel({ ..._, color: "" })} />
                            <DropdownItem style={{ backgroundColor: "#ac59ff", minHeight: "2rem" }} onClick={() => updateLabel({ ..._, color: "#ac59ff" })} />
                            <DropdownItem style={{ backgroundColor: "#4a95ff", minHeight: "2rem" }} onClick={() => updateLabel({ ..._, color: "#4a95ff" })} />
                            <DropdownItem style={{ backgroundColor: "#3bbdca", minHeight: "2rem" }} onClick={() => updateLabel({ ..._, color: "#3bbdca" })} />
                            <DropdownItem style={{ backgroundColor: "#3ac95c", minHeight: "2rem" }} onClick={() => updateLabel({ ..._, color: "#3ac95c" })} />
                            <DropdownItem style={{ backgroundColor: "#f2c83f", minHeight: "2rem" }} onClick={() => updateLabel({ ..._, color: "#f2c83f" })} />
                            <DropdownItem style={{ backgroundColor: "#ff7f3b", minHeight: "2rem" }} onClick={() => updateLabel({ ..._, color: "#ff7f3b" })} />
                            <DropdownItem style={{ backgroundColor: "#f23f3f", minHeight: "2rem" }} onClick={() => updateLabel({ ..._, color: "#f23f3f" })} />
                            <DropdownItem style={{ backgroundColor: "#ff8aed", minHeight: "2rem" }} onClick={() => updateLabel({ ..._, color: "#ff8aed" })} />
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <Input type="text" placeholder="Enter your label here" value={_.value || ""} onChange={e => updateLabel({ ..._, value: e.currentTarget.value })} />
                        <InputGroupAddon addonType="append">
                          {value.details.labels.some(l => l.key === _.key)
                            ? <Button className="b-0" size="sm" color="link" onClick={() => updateLabel({ ..._, isArchived: true })}>Archive</Button>
                            : <Button className="b-0" size="sm" color="link" onClick={() => updateInput({ labels: input.labels.filter(__ => __.key !== _.key) })}>Remove</Button>}
                        </InputGroupAddon>
                      </InputGroup>
                    )}
                    <div className="hide-not-first-child text-muted pl-3">No labels configured. Click Add to add a new label.</div>
                  </FormGroup>
                }
                {isOpenArchivedLabels &&
                  <FormGroup className="mt-3">
                    {input.labels.filter(_ => _.isArchived).map((_, i) =>
                      <InputGroup key={i} className="mt-1">
                        <Button onClick={doNothing} style={{ backgroundColor: _.color || '#f8f9fa', border: 0, minWidth: "2.5rem" }} />
                        <Input disabled type="text" placeholder="Enter your label here" value={_.value || ""} onChange={e => updateLabel({ ..._, value: e.currentTarget.value })} />
                        <InputGroupAddon addonType="append">
                          <CardButtons color="link" size="sm">
                            <CardButton className="b-0" onClick={() => updateLabel({ ..._, isArchived: false })}>Unarchive</CardButton>
                            <CardButton className="b-0" onClick={confirmOnClick(() => updateInput({ labels: input.labels.filter(__ => __.key !== _.key) }), "Are you sure you want to permanently remove this label?")}>Remove</CardButton>
                          </CardButtons>
                        </InputGroupAddon>
                      </InputGroup>
                    )}
                    <div className="hide-not-first-child text-muted pl-3">No archived labels.</div>
                  </FormGroup>
                }
                <Nav>
                  {(isOpenArchivedLabels || input.labels.filter(_ => _.isArchived).length > 0) &&
                    <>
                      <NavItem><NavLink active={!isOpenArchivedLabels} href="#" onClick={() => toggleArchivedLabels(false)}>Active</NavLink></NavItem>
                      <NavItem><NavLink active={isOpenArchivedLabels} href="#" onClick={() => toggleArchivedLabels(true)}>Archived</NavLink></NavItem>
                    </>
                  }
                  {!isOpenArchivedLabels &&
                    <NavItem className="ml-auto">
                      <NavLink href="#" onClick={() => updateLabel({ key: nextNonce(4) })}>Add</NavLink>
                    </NavItem>
                  }
                </Nav>
              </fieldset>
            </TabPane>
            <TabPane tabId={Tab.Comments}>
              <fieldset>
                <FormGroup label='Reviewer instructions' error={errors.ordersDiscussionDescription} value={input.ordersDiscussionDescription} showPreview>
                  <Input type="textarea" rows={10} placeholder="Provide any instructions for reviewers here" value={input.ordersDiscussionDescription || ""} onChange={e => updateInput({ ordersDiscussionDescription: e.currentTarget.value })} />
                </FormGroup>
                <FormGroup label="Suggested comments">
                  {input.ordersDiscussionMessages.map((_, i) =>
                    <InputGroup key={i} className="mt-1">
                      <Input type="text" placeholder="Enter your comment here" value={_ || ""} onChange={e => updateInput({ ordersDiscussionMessages: input.ordersDiscussionMessages.map((_, j) => j === i ? e.currentTarget.value : _) })} />
                      <InputGroupAddon addonType="append">
                        <Button className="b-0" size="sm" color="link" onClick={() => updateInput({ ordersDiscussionMessages: input.ordersDiscussionMessages.filter((_, j) => j !== i) })}>Remove</Button>
                      </InputGroupAddon>
                    </InputGroup>
                  )}
                  {input.ordersDiscussionMessages.length === 0 && <div className="text-muted">No suggested comments configured.</div>}
                </FormGroup>
                <Nav>
                  <NavItem className="ml-auto">
                    <NavLink href="#" onClick={() => updateInput({ ordersDiscussionMessages: [...input.ordersDiscussionMessages, ""] })}>Add</NavLink>
                  </NavItem>
                </Nav>
              </fieldset>
            </TabPane>
            <TabPane tabId={Tab.Shares}>
              <SharesInputTable owner={value.owner} values={value.shares} onUpdate={setSharesInput} />
            </TabPane>
            <TabPane tabId={Tab.Notifications}>
              <fieldset>
                <FormGroup label="Notification">
                  <CustomInput id="notify-customer-resume-switch" type="switch" checked={input.notificationPreferences.includes(ResourceEvent.CustomerUpdatedResume)} onChange={() => toggleNotificationPreferences(ResourceEvent.CustomerUpdatedResume)} label="When an applicant updates their résumé" />
                </FormGroup>
              </fieldset>
            </TabPane>
            <TabPane tabId={Tab.Descriptions}>
              <fieldset>
                <FormGroup label='Additional details' error={errors.detailedDescription} value={input.detailedDescription} showPreview>
                  <Input type="textarea" rows={10} placeholder="Provide any additional description here" value={input.detailedDescription || ""} onChange={e => updateInput({ detailedDescription: e.currentTarget.value })} />
                </FormGroup>
              </fieldset>
            </TabPane>
            <TabPane tabId={Tab.Documents}>
              <fieldset>
                <DragDropZone onDrop={attachFile}>
                  <AttachmentsList className="mb-0" values={value.attachments} onUpdate={attachments => onUpdate?.({ attachments })} showButtons />
                  <Nav>
                    <NavItem className="ml-auto">
                      <NavLink href="#" onClick={selectFile}>Add</NavLink>
                    </NavItem>
                  </Nav>
                </DragDropZone>
              </fieldset>
            </TabPane>
            <TabPane tabId={Tab.Additional}>
              <fieldset>
                <FormGroup label="Reference id message">
                  <Input type="text" placeholder="Enter message for entering reference id here" value={input.ordersCustomerExternalIdMessage || ""} onChange={e => updateInput({ ordersCustomerExternalIdMessage: e.currentTarget.value })} />
                </FormGroup>
                <FormGroup label="Normalize CGPA">
                  <CustomInput type="select" id="eduRatingDisplayMax" value={input.eduRatingDisplayMax || 10} name="customSelect" onChange={e => updateInput({ eduRatingDisplayMax: parseInt(e.currentTarget.value) })}>
                    <option value="4">4 point scale</option>
                    <option value="7">7 point scale</option>
                    <option value="8">8 point scale</option>
                    <option value="10">10 point scale</option>
                    <option value="100">100 point scale</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <CustomInput id="suggested-switch" type="switch" checked={input.ordersStages.includes(OrderStage.Suggested)} onChange={() => toggleOrdersStages(OrderStage.Suggested)} label="Enable candidate sourcing via HigherKnowledge" />
                  <CustomInput id="isLocked-switch" type="switch" checked={input.isLocked} onChange={() => updateInput({ isLocked: !input.isLocked })} label="Place new applicants in a waiting list" />
                  <CustomInput id="resume-review-switch" type="switch" checked={input.ordersCustomerAttachmentReviews} onChange={() => updateInput({ ordersCustomerAttachmentReviews: !input.ordersCustomerAttachmentReviews })} label="Provide feedback on candidate résumés" />
                  <Show scope="Site.Admin">
                    <CustomInput id="isunclaimed-switch" type="switch" checked={!input.isUnclaimed} onChange={() => updateInput({ isUnclaimed: !input.isUnclaimed })} label="Claimed by listing owner" />
                  </Show>
                </FormGroup>
              </fieldset>
            </TabPane>
          </TabContent>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>Cancel</Button>
        {!value.id && <Button color="primary" disabled={!isValid} onClick={create}>Post</Button>}
        {value.id && <Button color="primary" disabled={!isValid} onClick={update}>Update</Button>}
        <ProductChooserModal isOpen={isOpenProductChooser} toggle={toggleProductChooser} label="Link" onSelect={link} />
      </ModalFooter>
    </Modal>
  );
}
