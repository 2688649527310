import React, { createContext, ReactNode, useContext, useState } from "react";

export enum DocumentType {
  Draft = "DRAFT",
  Review = "REVIEW",
  Document = "DOCUMENT"
}

interface Reference {
  href: string
  key?: string
  title?: string
}

type ContextType = [DocumentType, (reference: Reference) => number, ReadonlyArray<Reference>];
const initialContext: any = undefined;
const Context = createContext<ContextType>(initialContext);

export const useDocumentContext = () => useContext(Context) || [];

export default ({ view, children }: { view: DocumentType, children?: ReactNode }) => {
  const [references, setReferences] = useState<ReadonlyArray<Reference>>([]);
  const addReference = (r: Reference) => {
    const index = references.findIndex(_ => (_.key || _.href) === (r.key || r.href));
    if (index === -1) {
      const nextReferences = Array.from(references);
      setReferences(nextReferences);
      return 1 + nextReferences.unshift(r);
    } else {
      return 1 + index;
    }
  }
  return (
    <Context.Provider value={[view, addReference, references]}>
      {children}
    </Context.Provider>
  );
}
