import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCircleNotch, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, CardText, Collapse, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupButtonDropdown, ListGroup, ListGroupItem, ListGroupItemProps } from "reactstrap";
import { CardButton, CardButtons } from "../components/CardStatus";
import { canPreview, Fragment as ContentPreviewFragment, Modal as ContentPreviewModal } from "../components/ContentPreviewDiv";
import FormGroup from "../components/FormGroup";
import { byKey, DeepPartial, nextNonce, setDelete } from "../hooks/ApiProvider";
import { isMaybeResume, useAttachments } from "../hooks/AttachmentHook";
import { useToggle } from "../hooks/CommonHooks";
import { useConfirmation } from "../hooks/ConfirmationProvider";
import { useIcons } from "../hooks/IconizeHook";
import { preventDefault, useNavigation } from "../hooks/NavigationHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { AttachmentModel, AttachmentType, ResourceKind, ResourceState } from "../types/api-graph-types";
import { CustomDropdownItem } from "./CustomDropdown";

library.add(faPaperclip, faCircleNotch, faTrashAlt);

export const Fragment = `
  id
  kind
  title
  subtitle
  filename
  length
  ${ContentPreviewFragment}
  policy { accessToken }
  actions { download change remove }
`;

interface ResourceModel {
  readonly id: string,
  readonly kind: ResourceKind,
  readonly attachments: ReadonlyArray<AttachmentModel>,
  readonly policy: {
    readonly accessToken: string
  }
}

export function Item({ value }: {
  value: AttachmentModel,
  onUpdate?: (value: DeepPartial<AttachmentModel>) => void
}) {
  const [navigate] = useNavigation();
  const [isOpen, toggle] = useToggle();
  return (
    <ListGroupItem className="d-flex align-items-center">
      <div className="mr-auto">
        {value.title ? `${value.title || ""} ${value.subtitle || ""}` : value.filename}
      </div>
      <CardButtons>
        {canPreview(value) && <CardButton onClick={toggle}>View</CardButton>}
        <CardButton icon="download" onClick={navigate(value.url, true)}>Download</CardButton>
      </CardButtons>
      {canPreview(value) && <ContentPreviewModal value={value} isOpen={isOpen} toggle={toggle} />}
    </ListGroupItem>
  );
}

const _types = [
  AttachmentType.EmploymentCertificate,
  AttachmentType.RecommendationLetter,
  AttachmentType.EducationalTranscript,
  AttachmentType.GraduationCertificate,
  AttachmentType.Resume,
]
const _otherTypes = [
  AttachmentType.OtherCertificate,
  AttachmentType.Other,
]

const _kb = 1024;
const _mb = 1024 * _kb;
const _gb = 1024 * _mb;
export function formatBytes(value: number) {
  return value >= _gb ? `${Math.floor(value / _gb)}G`
    : value >= _mb ? `${Math.floor(value / _mb)}M`
      : value >= _kb ? `${Math.floor(value / _kb)}K`
        : value >= 0 ? `${Math.floor(value)}B`
          : "";
}

function ListItem({ value, values, onUpdate, showButtons }: {
  value: AttachmentModel,
  values?: ReadonlyArray<AttachmentModel>,
  onUpdate?: (values: DeepPartial<AttachmentModel>) => void;
  showButtons?: boolean;
}) {
  const [navigate] = useNavigation();
  const confirm = useConfirmation();
  const { removeAttachment, updateAttachment } = useAttachments();
  const [isOpen, toggle] = useToggle();
  const remove = async () => {
    const result = await removeAttachment(value.id, value.policy?.accessToken);
    onUpdate?.(setDelete({ id: value.id, ...result }));
  }
  const update = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener("change", async () => {
      const file = input.files?.[0];
      if (file) {
        const result = await updateAttachment(value.id, file, value.policy?.accessToken);
        onUpdate?.(result);
      }
    });
    input.click();
  }
  const showPreview = canPreview(value);
  const actions = value.actions || {};
  const isUploading = !value.id;
  return (
    <li className="d-flex list-unstyled align-items-baseline">
      {isUploading && <FontAwesomeIcon className="mr-2" spin icon="circle-notch" />}
      {!isUploading && <FontAwesomeIcon className="mr-2" icon="paperclip" />}
      <a href="#" onClick={preventDefault(showPreview ? toggle : navigate(value.url, true))}>
        {value.title ? `${value.title || ""} ${value.subtitle || ""}` : value.filename}
      </a>
      {value.length > 0 && <span className="text-muted ml-2 small">{formatBytes(value.length)}</span>}
      {showButtons &&
        <CardButtons right className="ml-auto" color="link" size="sm">
          {actions.change && <CardButton onClick={update}>Update</CardButton>}
          {actions.remove && <CardButton icon={["far", "trash-alt"]} onClick={confirm(remove, "Are you sure you want to remove this document?")}>Remove</CardButton>}
        </CardButtons>
      }
      {showPreview && <ContentPreviewModal value={value} values={values} isOpen={isOpen} toggle={toggle} />}
    </li>
  );
}

export function InputText({ value, label, help, onUpdate }: {
  value: ResourceModel,
  onUpdate?: (value: DeepPartial<ResourceModel>) => void
  label?: string,
  help?: string,
}) {
  const confirm = useConfirmation();
  const [t] = useTranslation();
  const [i] = useIcons();
  const [isOpenType, toggleType] = useToggle();
  const [type, setType] = useState<AttachmentType>();
  const [file, setFile] = useState<File>();
  const { addAttachment, removeAttachment } = useAttachments();
  const update = (partial: DeepPartial<ResourceModel>) => onUpdate?.({ id: value.id, ...partial });
  const updateFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    file && setFile(file);
    file && isMaybeResume(file.name) && setType(AttachmentType.Resume);
  }
  const add = async () => {
    if (file) {
      const fake = { key: nextNonce(), filename: file.name } as AttachmentModel;
      update({ attachments: [fake] });
      setType(undefined);
      setFile(undefined);
      const attachment = await addAttachment(value.id, value.kind, type || AttachmentType.Other, file, value.policy?.accessToken);
      update({ attachments: [{ ...attachment, key: fake.key }] });
    }
  }
  const remove = (value: AttachmentModel) => async () => {
    const attachment = value.id ? await removeAttachment(value.id, value.policy?.accessToken) : setDelete(value);
    update({ attachments: [attachment] })
  }
  const attachments = value.attachments;
  return (
    <CardText tag="div">
      <FormGroup label={label || "Attachments"} help={help}>
        {attachments?.length > 0 &&
          <ul className="list-unstyled mb-0">
            {attachments?.map((_, i) =>
              <li key={i} className="d-flex">
                <FontAwesomeIcon className="mr-1" icon="paperclip" />
                {_.state !== ResourceState.Removed && <span>{_.filename}</span>}
                {_.state === ResourceState.Removed && <del>{_.filename}</del>}
                {_.id && <Button color="link" size="sm" className="ml-auto" onClick={confirm(remove(_), "Are you sure you want to remove this document?")}>Remove</Button>}
                {!_.id && <FontAwesomeIcon size="sm" className="ml-auto" icon="circle-notch" spin />}
              </li>
            )}
          </ul>
        }
        <InputGroup className="w-100">
          <InputGroupButtonDropdown addonType="prepend" isOpen={isOpenType} toggle={toggleType}>
            <DropdownToggle color="input" caret><FontAwesomeIcon icon="paperclip" /></DropdownToggle>
            <DropdownMenu>
              {_types.sort(byKey(t)).map((_, i) =>
                <CustomDropdownItem toggle={false} checked={_ === type} key={i} onClick={() => setType(_)}>{t(_)}</CustomDropdownItem>
              )}
              {_otherTypes.map((_, i) =>
                <CustomDropdownItem toggle={false} checked={_ === type} key={i} onClick={() => setType(_)}>{t(_)}</CustomDropdownItem>
              )}
            </DropdownMenu>
          </InputGroupButtonDropdown>
          <div className='custom-file'>
            <Input type="file" className={`custom-file-input`} onChange={updateFile} />
            <label className={`custom-file-label text-truncate text-nowrap ${file ? "" : "text-muted"}`}>
              {file?.name || "Select your file here"}
            </label>
          </div>
          <Button color={!file ? "input" : "primary"} disabled={!file} onClick={add}>Add</Button>
        </InputGroup>
      </FormGroup>
    </CardText>
  );
}


export function List({ values, className, onUpdate, showButtons, ...attrs }: {
  values: ReadonlyArray<AttachmentModel>;
  onUpdate?: (values: ReadonlyArray<DeepPartial<AttachmentModel>>) => void;
  showButtons?: boolean;
} & React.HTMLAttributes<HTMLUListElement>) {
  return values && (
    <ul className={`pl-0 ${className}`} {...attrs}>
      {values.filter(_ => _.state !== ResourceState.Removed).map((_, i) =>
        <ListItem key={i} value={_} values={values} onUpdate={value => onUpdate?.([value])} showButtons={showButtons} />
      )}
    </ul>
  ) || null;
}

export default ({ isOpen, values, onUpdate, ...attrs }: {
  isOpen?: boolean,
  values: ReadonlyArray<AttachmentModel>;
  onUpdate?: (values: ReadonlyArray<DeepPartial<AttachmentModel>>) => void;
} & ListGroupItemProps) => {
  return (
    <Collapse isOpen={isOpen}>
      <ListGroup className="bt-1" flush {...attrs}>
        {values && values.filter(_ => _.state !== ResourceState.Removed).map((_, i) =>
          <Item key={i} value={_} onUpdate={value => onUpdate?.([value])} />
        )}
      </ListGroup>
    </Collapse>
  );
}
