import { createBrowserHistory } from "history";
import LZString from "lz-string";
import QueryString from "query-string";
import { SyntheticEvent } from "react";
import { useAuthorizationToken } from "../hooks/TokenProvider";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href")!;

function parseUrl(url: string) {
  var a = document.createElement('a');
  a.href = url;
  const { protocol, host, pathname, search, hash } = a;
  return { protocol, host, pathname, search, hash };
}

function isExternal(url: string) {
  const baseHost = window.location.host.toLowerCase();
  const { host } = parseUrl(url);
  return baseHost != host.toLowerCase();
}

function toAbsolute(url: string) {
  const base = window.location;
  return url.startsWith("/") ? `${base.protocol}//${base.host}${url}` : url;
}

function toRelative(url: string) {
  const { pathname, search, hash } = parseUrl(url);
  return url.startsWith("/") ? url : `${pathname}${search}${hash}`;
}

export const history = createBrowserHistory({ basename: baseUrl });
function _navigate(url: string, force?: boolean) {
  if (isExternal(url) || force) {
    const tab = window.open(toAbsolute(url), "_blank");
    tab?.focus();
  } else {
    history.push(toRelative(url));
  }
}

export function replaceHistory(url: string) {
  history.replace(url);
}

export const useNavigation = (): [
  (href: string | undefined, force?: boolean) => ((e: React.MouseEvent) => void) | undefined,
  (href: string, e?: React.MouseEvent, force?: boolean) => void
] => {
  const [token] = useAuthorizationToken();
  const navigate = (href: string | undefined, _force?: boolean) => !href ? undefined : (e?: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    const force = _force || e?.getModifierState("Control") || false;
    _navigate(href, force);
    //if (href.startsWith("/") && force && href.indexOf("access_token") == -1) {
    //  const hash = href.indexOf("#") === -1 ? "#" : "&";
    //  _navigate(`${href}${hash}access_token=${token}`, force);
    //} else {
    //  _navigate(href, force);
    //}
  };
  const go = (href: string, e?: React.MouseEvent, force?: boolean) => navigate(href, force)?.(e);
  return [navigate, go];
}

export const locationBase = `${window.location.protocol}//${window.location.host}`

export function preventDefault<T, E>(action?: (e: SyntheticEvent<T, E>) => void) {
  return (e: SyntheticEvent<T, E>) => {
    action?.(e);
    e.preventDefault();
    e.stopPropagation();
  }
};

export function stopPropagation<T, E>(e: SyntheticEvent<T, E>) {
  e.stopPropagation();
};

export function doNothing<T, E>(e: SyntheticEvent<T, E>) {
  e.preventDefault();
  e.stopPropagation();
};

function _parse(x: string, initialValue: any): any {
  const value = { ...initialValue };
  const query = QueryString.parse(x, { decode: true, arrayFormat: "none" });
  for (var k in value) {
    const it = typeof (value[k]);
    const v = query[k] as string | number | (string | number)[] | null | undefined;
    const vt = typeof (v);
    if (v !== undefined && v !== null) {
      if (it === vt) { // string<-string, number<-number, []<-[]
        value[k] = v;
      } else if (it === "object") { // []<-string, []<-number
        if (vt === "string" && v === "") {
          value[k] = [];
        } else if (vt === "string") {
          value[k] = (v as string).split(",");
        } else if (vt === "number") {
          value[k] = [v as number];
        }
      } else if (it === "string") { // string<-[], string<-number
        if (vt === "object") {
          value[k] = (v as (string | number)[]).join(",");
        } else if (vt === "number") {
          value[k] = `${v}`;
        }
      } else if (it === "number") {
        if (vt === "object") {
          value[k] = +((v as (string | number)[])[0]);
        } else if (vt === "string") {
          value[k] = +(v as string);
        }
      }
    }
  }
  return value;
}

export function compress(value: string) {
  return '0' + LZString.compressToBase64(value).replace('+', '-').replace('/', '_');
  //return encodeURIComponent(value);
}

export function decompress(value: string) {
  return value.startsWith('0') ? LZString.decompressFromBase64(value.substr(1).replace('-', '+').replace('_', '/'))
    : decodeURIComponent(value);
}

function _obfuscate(value: string) {
  return value ? `_=${compress(value)}` : value;
}

function _deobfuscate(value: string) {
  return value.startsWith("?_=") || value.startsWith("#_=") ? decompress(value.substr(3))
    : value.startsWith("_=") ? decompress(value.substr(2))
      : value
}

export function download(dataUri: string, filename: string) {
  var el = document.createElement("a");
  el.setAttribute("href", dataUri);
  el.setAttribute("download", filename);
  el.style.display = "none";
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}

export function getURIComponents<T>(value: T, obfuscate: boolean = true) {
  const next = Object.entries({ ...value }).filter(([k, v]) => !!v).reduce((a, [k, v]) => Object.assign(a, { [k]: v }), {} as T);
  const result = QueryString.stringify(next, { encode: true, arrayFormat: "comma" });
  return obfuscate ? _obfuscate(result) : result;
}

export function parseURIComponents<T>(value: string, initialValue: T) {
  return _parse(_deobfuscate(value), initialValue) as T;
}

export function updateURIComponents<T>(value: string, props: T) {
  const parsed = QueryString.parse(_deobfuscate(value), { decode: true, arrayFormat: "none" });
  const next = Object.entries({ ...parsed, ...props }).filter(([k, v]) => !!v).reduce((a, [k, v]) => Object.assign(a, { [k]: v }), {} as T);
  const result = QueryString.stringify(next, { encode: true, arrayFormat: "comma" });
  return !result ? "" : value.startsWith("?") ? `?${result}` : value.startsWith("#") ? `#${result}` : result;
}

export function useQueryString<T>(initialValue: T): [T, (value: T, obfuscate?: boolean) => void, (partial: Partial<T>, obfuscate?: boolean) => void] {
  const queryString = _deobfuscate(window.location.search);
  const value = _parse(queryString, initialValue) as T;
  const stringify = (value: T, obfuscate: boolean) => {
    const queryString = _deobfuscate(window.location.search); // No capture
    const query = QueryString.parse(queryString, { decode: true, arrayFormat: "none" });
    const nextQuery = Object.entries({ ...query, ...value }).filter(([k, v]) => !!v).reduce((a, [k, v]) => Object.assign(a, { [k]: v }), {} as T);
    const search = QueryString.stringify(nextQuery, { encode: true, arrayFormat: "comma" });
    return `${window.location.pathname}?${obfuscate ? _obfuscate(search) : search}${window.location.hash}`;
  }
  const setValue = (value: T, obfuscate: boolean = true) => _navigate(stringify(value, obfuscate));
  const updateValue = (partial: Partial<T>, obfuscate: boolean = true) => setValue({ ...value, ...partial }, obfuscate);
  return [value, setValue, updateValue];
}

export function useFragment<T>(initialValue: T): [T, (value: T, obfuscate?: boolean) => void, (partial: Partial<T>, obfuscate?: boolean) => void] {
  const fragmentString = _deobfuscate(window.location.hash);
  const value = _parse(fragmentString, initialValue) as T;
  const stringify = (value: T, obfuscate: boolean) => {
    const fragmentString = _deobfuscate(window.location.hash); // No capture
    const fragment = QueryString.parse(fragmentString, { decode: true, arrayFormat: "none" });
    const nextFragment = Object.entries({ ...fragment, ...value }).filter(([k, v]) => !!v).reduce((a, [k, v]) => Object.assign(a, { [k]: v }), {} as T);
    const hash = QueryString.stringify(nextFragment, { encode: true, arrayFormat: "comma" });
    return `${window.location.pathname}${window.location.search}#${obfuscate ? _obfuscate(hash) : hash}`;
  }
  const setValue = (value: T, obfuscate: boolean = true) => _navigate(stringify(value, obfuscate));
  const updateValue = (partial: Partial<T>, obfuscate: boolean = true) => setValue({ ...value, ...partial }, obfuscate);
  return [value, setValue, updateValue];
}
