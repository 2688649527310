import React from "react";
import ExternalLink from "../components/ExternalLink";
import { useDocumentContext, DocumentType } from "../hooks/DocumentProvider";

export default ({ title, citeKey, href }: {
  citeKey?: string,
  title?: string,
  href: string
}) => {
  const [documentType, addReference] = useDocumentContext();
  const inline = documentType !== DocumentType.Document;
  return inline ? (
    <footer className='blockquote-footer'>
      {citeKey}
      {" "}
      <ExternalLink href={href} />
    </footer>
  ) : <span className="ml-1">[{addReference({ title, href, key: citeKey })}]</span>;
}
