import React, { DragEventHandler, ReactNode, useState } from "react";

export default ({ className, children, onDrop }: {
  className?: string,
  onDrop: (value: File) => void
  children: ReactNode
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [timeout, setTimeout] = useState<number>();
  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    !isDragging && setIsDragging(true);
    timeout && window.clearTimeout(timeout);
    setTimeout(window.setTimeout(() => setIsDragging(false), 100));
    e.preventDefault();
  }
  const handle = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i];
        if (item.kind === "file") {
          const file = item.getAsFile();
          file && onDrop(file);
        }
      }
    } else if (e.dataTransfer.files) {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        file && onDrop(file);
      }
    }
  }
  const classNames = [className, isDragging ? "drag" : ""].join(" ");
  return (
    <div className={classNames} onDrop={handle} onDragOver={dragOver}>
      {children}
    </div>
  );
}
