import React from "react";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import Card from "../components/Card";
import ContentPreviewDiv from "../components/ContentPreviewDiv";
import { useQuery } from "../hooks/ApiProvider";
import { useTitle } from "../hooks/TitleHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { PersonModel } from "../types/api-graph-types";
import LoadingController from "./LoadingController";

const _getPersonJob = `query getPersonJob($uid:ID! $jid:ID!) {
  person(id:$uid) {
    name
    job(id:$jid) {
      workitem { details { type } }
      pdfUrl
    }
  }
}`;

interface Result {
  person: PersonModel
}

export default ({ uid, jid }: { uid: string, jid: string }) => {
  const [t] = useTranslation();
  const [result, , isLoading] = useQuery<Result>(_getPersonJob, { uid, jid });
  const value = result?.person?.job;
  useTitle(result && value && `${t(value.workitem.details.type)} - ${result.person.name}`);
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  return (
    <>
      <Row>
        <Col>
          <Breadcrumb>
            <BreadcrumbItem><a href={`/my/team/${uid}`}>{result && result.person.name}</a></BreadcrumbItem>
            <BreadcrumbItem active>{t(value.workitem.details.type)}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Col>
              <ContentPreviewDiv showAnnotationLayer hideTextLayer type="application/pdf" src={value.pdfUrl} pageClassName="bih-1" />
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  )
};
