import React, { MouseEventHandler, ReactNode, useContext, createContext } from "react";
import { TooltipContext, TooltipTarget, UncontrolledTooltip } from "./Tooltip";

const Context = createContext({ tooltipLabels: false });

export function ProgressTracker({ vertical, tooltipLabels = false, center, className, children }: {
  vertical?: boolean,
  center?: boolean,
  tooltipLabels?: boolean,
  className?: string,
  children?: ReactNode
}) {
  const classNames = [
    "progress-tracker",
    vertical ? "progress-tracker--vertical" : "",
    center ? "progress-tracker--center" : ""
  ].join(" ");
  return (
    <Context.Provider value={{ tooltipLabels }}>
      <div className={`progress-tracker-wrapper ${className}`}>
        <ul className={classNames}>
          {children}
        </ul>
      </div>
    </Context.Provider>
  );
}

export function ProgressStep({ label, disabled, complete, active, onClick, className, children }: {
  disabled?: boolean,
  label?: string,
  className?: string,
  complete?: boolean,
  active?: boolean,
  onClick?: MouseEventHandler<{}>,
  children?: ReactNode
}) {
  const { tooltipLabels } = useContext(Context);
  const tooltip = tooltipLabels ? label : undefined;
  const classNames = [
    "progress-step",
    className || "",
    complete ? " is-complete" : "",
    disabled ? " is-disabled" : "",
    active ? " is-active" : "",
    !!onClick && !disabled ? " cursor-pointer" : "",
  ].join(" ");
  return (
    <li className={classNames} onClick={disabled ? undefined : onClick}>
      {!tooltip ? <div className="progress-marker" /> :
        <TooltipContext>
          <TooltipTarget><div className="progress-marker" /></TooltipTarget>
          <UncontrolledTooltip>{tooltip}</UncontrolledTooltip>
        </TooltipContext>
      }
      <div className="progress-text">
        {label && !tooltipLabels && <h5 className="text-nowrap">{label}</h5>}
        {children}
      </div>
    </li>
  );
}
