import { library } from "@fortawesome/fontawesome-svg-core";
import { faAmazon, faFacebook, faGoogle, faLinkedin, faMicrosoft, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardText, CardTitle, Col, Form, FormGroup, Input, Row } from "reactstrap";
import SignInForm, { View as SignInView } from "../components/SignInForm";
import { useNavigation } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { useAuthorizationToken } from "../hooks/TokenProvider";

library.add(
  faFacebook,
  faLinkedin,
  faTwitter,
  faGoogle,
  faAmazon,
  faMicrosoft,
  faShieldAlt
);

function _formatCode(value: string) {
  return value.length === 16 ? `${value.substr(0, 4)} ${value.substr(4, 4)} ${value.substr(8, 4)} ${value.substr(12, 4)}`
    : value;
}

export default () => {
  const [signInView, setSignInView] = useState<SignInView>(SignInView.Message);
  const [navigate] = useNavigation();
  const [, setToken] = useAuthorizationToken();
  const [code, setCode] = useState("");
  useTitle("");
  return (
    <Row className="mt-3">
      <Col sm="6" lg="8">
        <h1>HigherKnowledge</h1>
        Do more with your time.
      </Col>
      <Col sm="6" lg="4">
        <Card>
          <CardBody>
            <CardTitle tag="h3">Sign in</CardTitle>
            <CardText>
              <Button tag={Link} style={{ backgroundColor: "#3b5998", border: 0 }} to='/sign-in?login_hint=facebook'><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "facebook"]} /></Button>
              <Button tag={Link} style={{ backgroundColor: "#0077b5", border: 0 }} to='/sign-in?login_hint=linkedin'><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "linkedin"]} /></Button>
              <Button tag={Link} style={{ backgroundColor: "#1da1f2", border: 0 }} to='/sign-in?login_hint=twitter'><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "twitter"]} /></Button>
              {/*<Button tag={Link} style={{ backgroundColor: '#833ab4', border: 0 }} to='/sign-in?login_hint=instagram'><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={['fab', 'instagram']} /></Button>*/}
              <Button tag={Link} style={{ backgroundColor: "#ea4335", border: 0 }} to='/sign-in?login_hint=google'><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "google"]} /></Button>
              <Button tag={Link} style={{ backgroundColor: "#ff9900", border: 0 }} to='/sign-in?login_hint=amazon'><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "amazon"]} /></Button>
              <Button tag={Link} style={{ backgroundColor: "#00a1f1", border: 0 }} to='/sign-in?login_hint=microsoft'><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "microsoft"]} /></Button>
              <Button tag={Link} style={{ backgroundColor: "#676767", border: 0 }} to='#' onClick={() => setSignInView(SignInView.Authenticator)}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fas", "shield-alt"]} /></Button>
              {/*<Button tag={Link} style={{ backgroundColor: "#8a90c7", border: 0 }} to='#' onClick={() => toggle(true)}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["far", "envelope"]} /></Button>*/}
              {/*<Button tag={Link} style={{ backgroundColor: "#a4c639", border: 0 }} to='#' onClick={() => toggle(true)}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fas", "mobile-alt"]} /></Button>*/}
            </CardText>
            <SignInForm view={signInView} onSignIn={setToken} />
          </CardBody>
        </Card>
        {false &&
          <Card className="mt-3">
            <CardBody>
              <CardTitle tag="h3">Register</CardTitle>
              <CardText>
                If you received an invitation code, please enter it below. Otherwise enter <code>GUEST</code>.
            </CardText>
              <Form>
                <FormGroup>
                  <Input value={_formatCode(code)} placeholder="Invitation code" spellCheck={false} onChange={e => setCode(e.target.value.toUpperCase().replace(/[^A-Z0-9-]/g, ""))} />
                </FormGroup>
                <Button color="primary" onClick={navigate(`/go/${code}` /*FIXME: Replace with `/sign-in?scope=claim:${code}#error=login_required`*/)}>
                  Register
              </Button>
              </Form>
            </CardBody>
          </Card>
        }
      </Col>
    </Row>
  );
}
