import React from "react";
import { Card, CardBody } from "reactstrap";
import CardMarkdownText from "../components/CardMarkdownText";
import { useQuery } from "../hooks/ApiProvider";

const _getFile = `query getFile($namespace:String $name:String) {
  file(namespace:$namespace name:$name)
}`;

interface Result {
  file: string
}

export const Namespaces = {
  Policies: "policies",
  Company: "company",
  FAQ: "faq"
}

export const Policies = {
  TermsOfUse: "terms",
  Privacy: "privacy",
  CookiesNotice: "cookies-notice",
  Trademark: "trademark",
  CodeOfConduct: "code-of-conduct",
  Refunds: "refunds",
}

export const Company = {
  Mission: "mission",
  Address: "address"
}

export const FAQ = {
  General: "general",
  Account: "account",
  Assessments: "assessments",
  Placements: "placements",
  PlacementsCommittees: "placements-committees",
  PlacementsStudents: "placements-students",
}

export default ({ namespace, name, className }: {
  namespace: string,
  name: string,
  className?: string
}) => {
  const [result] = useQuery<Result>(_getFile, { namespace, name });
  const value = result?.file;
  return !value ? null : (
    <Card id={name} className={className}>
      <CardBody>
        <CardMarkdownText>{value}</CardMarkdownText>
      </CardBody>
    </Card>
  );
}

