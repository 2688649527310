import React, { ReactNode, useState } from "react";
import { Button, ButtonProps, Tooltip } from "reactstrap";
import { useClipboard } from "../hooks/ClipboardHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { nextSequence } from "../hooks/ApiProvider";

library.add(faCopy);

export default ({ id, color, size, onClick, icon = ["far", "copy"], value, title, children, ...attrs }: {
  value: string,
  title?: string,
  icon?: IconProp
} & ButtonProps) => {
  const [_id] = useState(nextSequence());
  const [isOpen, setIsOpen] = useState(false);
  const [clipboard, setClipboard] = useClipboard();
  const handleClick = (e: React.MouseEvent<any>) => {
    setClipboard(value);
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), 1000);
    onClick?.(e);
  }
  return (
    <Button id={id || _id} color={color || "link"} onClick={handleClick} {...attrs}>
      {children || <FontAwesomeIcon icon={icon} />}
      <Tooltip trigger="click" target={id || _id} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>{title || "Copied to clipboard"}</Tooltip>
    </Button>
  );
}
