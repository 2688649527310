import React, { createContext, ReactNode, useContext, useState } from "react";
import { useClaims } from "../hooks/TokenProvider";

type Preferences = { [key: string]: any };
type ContextType = [Preferences, (next: Preferences) => void];
const Context = createContext<ContextType>([{}, () => { }]);

export const Preference = {
  MyHomePageView: "MyHomePage.View",
  MyHomePageFunctions: "MyHomePage.Functions",
  ProductChooserQuery: "ProductChooser.Query",
  SubscriptionChooserQuery: "SubscriptionChooser.Query"
}

export function usePreference<T>(name: string, defaultValue?: T): [T, (value: T) => void] {
  const [preferences, setPreferences] = useContext(Context);
  const preference = preferences[name] !== undefined ? preferences[name] : defaultValue;
  const setPreference = (value: T) => setPreferences({ ...preferences, [name]: value });
  return [preference, setPreference];
}

export default (props: { children?: ReactNode }) => {
  const [claims] = useClaims();
  const key = `preferences.${claims?.sub || "guest"}`;
  const initialPreferences = JSON.parse(window.localStorage.getItem(key) || "{}") as { [key: string]: any };
  const [preferences, setPreferences] = useState(initialPreferences);
  const persistPreferences = (value: Preferences) => {
    setPreferences(value);
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  return (
    <Context.Provider value={[preferences, persistPreferences]}>
      {props.children}
    </Context.Provider>
  );
}
