import { CityCode, ServiceType } from "./api-graph-types";

export enum PaymentTargetType {
  Null = "NULL",
  Ifsc = "IFSC",
  Iban = "IBAN",
  Ach = "ACH",
  Upi = "UPI",
  Bitcoin = "BITCOIN",
  Ilp = "ILP",
}

const _deprecatedCityCodes = [
  CityCode.Blr,
  CityCode.Del,
  CityCode.Bom,
  CityCode.Maa,
  CityCode.Pnq,
  CityCode.Hyd,
  CityCode.Ccu,
  CityCode.IndiaNoida,
  CityCode.IndiaGurugram
];
export const CityCodes = Object.values(CityCode).filter(v => !_deprecatedCityCodes.includes(v));



const _deprecatedServiceTypes = [
  ServiceType.LegalServiceInHouse
];
export const ServiceTypes = Object.values(ServiceType).filter(v => !_deprecatedServiceTypes.includes(v));
