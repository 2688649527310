import React, { useEffect, useState } from "react";
import InputRange from "react-input-range";
import { NumberDisplayFormat, useTranslation } from "../hooks/TranslationProvider";

export function MoneyRangeInput({ value: _value, max: _max, onUpdate }: {
  value: string;
  max: number;
  onUpdate: (value: string) => void;
}) {
  const [t, , n] = useTranslation();
  const parts = _value.split(":");
  const currency = parts.length > 1 ? parts[0] : "INR";
  const minmax = (parts.length > 1 ? parts[1] : parts[0]).split("-");
  const min = isNaN(parseInt(minmax[0])) ? 0 : parseInt(minmax[0]) / 12;
  const max = isNaN(parseInt(minmax[1])) ? _max : parseInt(minmax[1]) / 12;
  const [value, setValue] = useState({ min, max });
  const reset = () => {
    setValue({ min, max });
  };
  const formatLabel = (value: number, type: string) => {
    return type !== "value" ? ""
      : value === _max ? `${n(value, NumberDisplayFormat.Human, undefined, t(currency))}+`
        : n(value, NumberDisplayFormat.Human, undefined, t(currency));
  };
  useEffect(reset, [min, max]);
  const isFull = value.min === 0 && value.max === _max;
  const update = () => setTimeout(() => onUpdate(value.max === _max ? value.min === 0 ? "" : `${currency}:${value.min * 12}` : `${currency}:${value.min * 12}-${value.max * 12}`), 300);
  return (
    <div style={{ padding: value.min === 0 ? "0.5rem 1.5rem 0.5rem 2rem" : "0.5rem 1.5rem" }} className={isFull ? "text-muted" : "input-range-primary"}>
      <InputRange minValue={0} maxValue={_max} step={5000} value={value} onChange={value => typeof (value) === "object" && setValue(value)} onChangeComplete={update} formatLabel={formatLabel} />
    </div>
  );
}
