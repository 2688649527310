import { useToasts } from "./ToastsProvider";

interface Options {
  onFulfilled?: () => void;
  hideToast?: boolean;
}

export function useClipboard(): [string, (value: string, options?: Options) => void] {
  const [, setToast] = useToasts();
  const value = "";
  const setValue = (value: string, options?: Options) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value).then(() => {
        options?.hideToast || setToast({ message: "Copied to clipboard" }, 5000);
        options?.onFulfilled?.();
      });
    } else {
      try {
        const textarea = document.createElement("textarea");
        textarea.value = value;
        textarea.select();
        document.execCommand("copy");
        options?.hideToast || setToast({ message: "Copied to clipboard" }, 5000);
        options?.onFulfilled?.();
      } catch {
      }
    }
  }
  return [value, setValue];
}
