import { library } from "@fortawesome/fontawesome-svg-core";
import { faAmazon, faFacebook, faGoogle, faLinkedin, faMicrosoft, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMobileAlt, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardText, CardTitle, Col, Collapse, Row, UncontrolledAlert } from "reactstrap";
import SignInForm, { View as SignInView } from "../components/SignInForm";
import LoadingController from "../controllers/LoadingController";
import { asType, OAuth2TokenError, useOAuth2 } from "../hooks/ApiProvider";
import { getURIComponents, replaceHistory, updateURIComponents, useFragment, useNavigation, useQueryString, parseURIComponents } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { useAuthorizationToken } from "../hooks/TokenProvider";
import { useTranslation } from "../hooks/TranslationProvider";

library.add(faFacebook, faLinkedin, faTwitter, faGoogle, faAmazon, faMicrosoft, faShieldAlt, faEnvelope, faMobileAlt);

const _errorLoginRequired = "https://higherknowledge.in/docs/error-login-required";
const _errorToDetails = new Map<string, string>([
  ["https://higherknowledge.in/our/docs/error-unauthorized-client", "You are not authorized to sign-in on this website."],
  ["https://higherknowledge.in/our/docs/error-invalid-code", ""],
  ["https://higherknowledge.in/our/docs/error-expired-code", ""],
  ["https://higherknowledge.in/our/docs/error-login-required", "You must first sign-in below before you can use this code."],
  ["https://higherknowledge.in/our/docs/error-access-denied", "Access was denied preventing the sign-in. Please try again or use a different sign-in provider."],
  ["https://higherknowledge.in/our/docs/error-temporarily-unavailable", "A temporary error prevented the sign-in. Please try again or use a different sign-in provider."],
  ["login_required", "You must first sign-in below before you can use this code."],
  ["server_error", "An internal error prevented the sign-in. Please try again or use a different sign-in provider."],
  ["invalid_request", "An internal error prevented the sign-in. Please try again or use a different sign-in provider."],
  ["access_denied", "Access was denied preventing the sign-in. Please try again or use a different sign-in provider."],
  ["temporarily_unavailable", "A temporary error prevented the sign-in. Please try again or use a different sign-in provider."],
  ["", "An error prevented the sign-in. Please try again or use a different sign-in provider."]
]);
const _storage = new Map<string, "SESSION" | "COOKIE" | "LOCAL">([
  ["session", "SESSION"],
  ["cookie", "COOKIE"],
  ["local", "LOCAL"],
]);

const _socialLogins = ["facebook", "linkedin", "google", "amazon", "microsoft", "twitter", "quickbooks"];

interface Query {
  code: string,
  login_hint: string,
  scope: string,
  state: string
}

interface Fragment {
  access_token: string,
  refresh_token: string,
  token_type: string,
  expires_in: string,
  storage: string,
  error: string,
  error_uri: string,
  error_description: string
}

interface State {
  redirect_uri?: string
}

const _initialQuery: Query = { code: "", login_hint: "", scope: "", state: "" };
const _initialFragment: Fragment = { access_token: "", refresh_token: "", token_type: "", expires_in: "", storage: "", error: "", error_uri: "", error_description: "" };
const _initialState: State = { redirect_uri: "" };

export function Redirect() {
  const [query] = useQueryString(_initialQuery);
  const [, go] = useNavigation();
  const { code, login_hint, scope } = query;
  const { protocol, host, pathname, hash } = window.location;
  const search = updateURIComponents(window.location.search || "?", { code: "", login_hint: "", scope: "" })
  const state = _encodeState({
    redirect_uri: `${protocol}//${host}${pathname}${search}${hash}`
  });
  const signinParams = getURIComponents({ code, login_hint, scope, state }, false);
  useEffect(() => replaceHistory(`/sign-in?${signinParams}`), [window.location.pathname]);
  return null;
}

function _encodeState(value: State) {
  return getURIComponents(value);
}

function _decodeState(value: string) {
  return parseURIComponents(value, _initialState)
}

export default ({ signUp = false }: {
  signUp?: boolean
}) => {
  const [query, setQuery, updateQuery] = useQueryString(_initialQuery);
  const [fragment, setFragment, updateFragment] = useFragment(_initialFragment);
  const { code, login_hint, scope, state: _state } = query;
  const { access_token, refresh_token, storage, expires_in, error, error_uri } = fragment;
  const state = _decodeState(_state);

  const [signInView, setSignInView] = useState(login_hint && !_socialLogins.includes(login_hint) ? SignInView.Message : undefined);
  const [, _setToken] = useAuthorizationToken();
  const [authorize, exchange] = useOAuth2();
  const [t] = useTranslation();
  const [, navigate] = useNavigation();
  useTitle("Sign-in");

  const cleanLocation = `${window.location.pathname}${updateURIComponents(window.location.search, _initialQuery)}${updateURIComponents(window.location.hash, _initialFragment)}`;

  const setToken = (accessToken: string, expiresIn: string, refreshToken: string, storage?: "SESSION" | "COOKIE" | "LOCAL") => {
    replaceHistory(cleanLocation);
    _setToken(accessToken, expiresIn, refreshToken, storage);
    if (state.redirect_uri) {
      navigate(state.redirect_uri);
    } else if (window.location.pathname === "/sign-in") {
      navigate("/");
    }
  }

  // Authorization flow
  useEffect(() => {
    if (code && typeof (code) === "string") {
      (async () => {
        try {
          const response = await exchange({ grantType: "authorization_code", code, scope });
          setToken(response.access_token, response.expires_in, response.refresh_token);
        } catch (error) {
          if (asType<OAuth2TokenError>(error)) {
            updateFragment({ error: error.error, error_uri: error.error_uri, error_description: error.error_description }, false);
            if (error.error === "login_required" || error.error_uri === _errorLoginRequired) {
              updateQuery({ code: "", scope: `claim:${code}` }, false);
            } else {
              updateQuery({ code: "" }, false);
            }
          }
        }
      })();
    }
  }, [code]);

  // Implicit flow
  useEffect(() => {
    if (access_token) {
      setToken(access_token, expires_in, refresh_token, _storage.get(storage));
    }
  }, [access_token])

  // Request authorization
  useEffect(() => {
    if (_socialLogins.includes(login_hint) && !error) {
      const { protocol, host, pathname } = window.location;
      const nextState = _encodeState({
        ...state,
        redirect_uri: state.redirect_uri || (pathname != "/sign-in" ? cleanLocation : undefined)
      });
      const redirect_uri = `${protocol}//${host}/sign-in`;
      authorize({ login_hint, redirect_uri, scope, state: nextState });
    }
  }, [window.location.href]);

  if ((code || access_token || _socialLogins.includes(login_hint)) && !error) {
    return <LoadingController isLoading />;
  }

  const signIn = (login_hint: string) => {
    updateQuery({ login_hint }, false);
    updateFragment({ error: "", error_uri: "", error_description: "" }, false);
  }

  const expandSocial = !login_hint;
  const isSignIn = !signUp;
  const actionLabel = isSignIn ? "Sign in " : "Sign up ";

  return (
    <>
      {(error_uri || error) && _errorToDetails.get(error_uri || error || "") &&
        <Row>
          <UncontrolledAlert color="danger" className="w-100">
            {_errorToDetails.get(error_uri || error || "")}
          </UncontrolledAlert>
        </Row>
      }
      <Row className="mt-3">
        <Col sm="8" md="4" className="offset-sm-2 offset-md-4">
          <Card>
            <CardBody>
              <CardTitle tag="h3">{actionLabel}</CardTitle>
              {expandSocial ?
                <CardText>
                  <Button block style={{ backgroundColor: "#3b5998", border: 0, textAlign: "left" }} onClick={() => signIn("facebook")}>
                    <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={["fab", "facebook"]} />
                    {actionLabel} with Facebook
                  </Button>
                  <Button block style={{ backgroundColor: "#0077b5", border: 0, textAlign: "left" }} onClick={() => signIn("linkedin")}>
                    <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={["fab", "linkedin"]} />
                    {actionLabel} with LinkedIn
                  </Button>
                  <Button block style={{ backgroundColor: "#1da1f2", border: 0, textAlign: "left" }} onClick={() => signIn("twitter")}>
                    <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={["fab", "twitter"]} />
                    {actionLabel} with Twitter
                  </Button>
                  {/*
                  <Button block tag={Link} style={{ backgroundColor: '#833ab4', border: 0, textAlign: 'left' }} to='/sign-in?login_hint=instagram'>
                    <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={['fab', 'instagram']} />
                    Sign in with Instagram
                  </Button>
                  */}
                  <Button block style={{ backgroundColor: "#ea4335", border: 0, textAlign: "left" }} onClick={() => signIn("google")}>
                    <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={["fab", "google"]} />
                    {actionLabel} with Google
                  </Button>
                  <Button block style={{ backgroundColor: "#ff9900", border: 0, textAlign: "left" }} onClick={() => signIn("amazon")}>
                    <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={["fab", "amazon"]} />
                    {actionLabel} with Amazon
                  </Button>
                  <Button block style={{ backgroundColor: "#00a1f1", border: 0, textAlign: "left" }} onClick={() => signIn("microsoft")}>
                    <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={["fab", "microsoft"]} />
                    {actionLabel} with Microsoft
                  </Button>
                  {isSignIn &&
                    <Button block style={{ backgroundColor: "#676767", border: 0, textAlign: "left" }} onClick={() => setSignInView(SignInView.Authenticator)}>
                      <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={["fas", "shield-alt"]} />
                      {actionLabel} with Authenticator
                    </Button>
                  }
                  <Button block style={{ backgroundColor: "#8a90c7", border: 0, textAlign: "left" }} onClick={() => setSignInView(SignInView.Message)}>
                    <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={["far", "envelope"]} />
                    {actionLabel} with Email
                  </Button>
                  <Button block style={{ backgroundColor: "#a4c639", border: 0, textAlign: "left" }} onClick={() => setSignInView(SignInView.Message)}>
                    <FontAwesomeIcon fixedWidth className='mr-3' size='lg' icon={["fas", "mobile-alt"]} />
                    {actionLabel} with SMS
                  </Button>
                </CardText> :
                <CardText>
                  <Button style={{ backgroundColor: "#3b5998", border: 0 }} onClick={() => signIn('facebook')}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "facebook"]} /></Button>
                  <Button style={{ backgroundColor: "#0077b5", border: 0 }} onClick={() => signIn('linkedin')}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "linkedin"]} /></Button>
                  <Button style={{ backgroundColor: "#1da1f2", border: 0 }} onClick={() => signIn('twitter')}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "twitter"]} /></Button>
                  {/*<Button style={{ backgroundColor: '#833ab4', border: 0 }} onClick={()=>signIn('instagram')}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={['fab', 'instagram']} /></Button>*/}
                  <Button style={{ backgroundColor: "#ea4335", border: 0 }} onClick={() => signIn('google')}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "google"]} /></Button>
                  <Button style={{ backgroundColor: "#ff9900", border: 0 }} onClick={() => signIn('amazon')}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "amazon"]} /></Button>
                  <Button style={{ backgroundColor: "#00a1f1", border: 0 }} onClick={() => signIn('microsoft')}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fab", "microsoft"]} /></Button>
                  {isSignIn && <Button style={{ backgroundColor: "#676767", border: 0 }} to='#' onClick={() => setSignInView(SignInView.Authenticator)}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fas", "shield-alt"]} /></Button>}
                  {/*<Button tag={Link} style={{ backgroundColor: "#8a90c7", border: 0 }} to='#' onClick={() => setIsOpen(true)}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["far", "envelope"]} /></Button>*/}
                  {/*<Button tag={Link} style={{ backgroundColor: "#a4c639", border: 0 }} to='#' onClick={() => setIsOpen(true)}><FontAwesomeIcon style={{ width: 16, height: 16 }} icon={["fas", "mobile-alt"]} /></Button>*/}
                </CardText>
              }
              <Collapse isOpen={!!signInView}>
                <SignInForm signUp={signUp} view={signInView} scope={scope} onSignIn={setToken} />
              </Collapse>
              <CardText className="mt-2 small text-muted">
                By signing {isSignIn ? " in " : " up "} you agree to HigherKnowledge's
                {" "}
                <a href="/our/policies/terms" target="_blank">Terms of Use</a>
                {" and "}
                <a href="/our/policies/privacy" target="_blank">Privacy Policy</a>.
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
