import React, { useState, useEffect } from "react";
import { InputProps, Popover, PopoverBody, ListGroup, ListGroupItem } from "reactstrap";
import { useQuery, nextSequence } from "../hooks/ApiProvider";
import { ReferenceDataModel, ReferenceDataType } from "../types/api-graph-types";
import { UserThumbnailFragment, UserThumbnail } from "../cards/PersonCard";
import DebouncedInput from "../components/Input";
import * as Popper from "popper.js";

const _getReferenceData = `query getReferenceData($types:[ReferenceDataType] $query:String $exclude:[String]) {
  reference(types:$types query:$query exclude:$exclude) {
    value
    title
    organization { id kind logo name unitName displayName nickname }
    person { id kind name email phone ${UserThumbnailFragment} }
    product { id kind details { title subtitle manufacturerOrg { logo } } }
  }
}`;

interface Result {
  reference: ReadonlyArray<ReferenceDataModel>
}

function ReferencePopover({ id, container, query, types, exclude, placement, onSelect }: {
  id: string,
  types: ReferenceDataType[],
  query: string,
  container?: string,
  placement?: Popper.Placement,
  exclude?: ReadonlyArray<string>,
  onSelect: (value: string | undefined, label: string, model: ReferenceDataModel | undefined) => void
}) {
  const [result] = useQuery<Result>(_getReferenceData, { types, query, exclude });
  return result?.reference.length ? (
    <Popover target={id} container={container} isOpen placement={placement || "bottom-start"}>
      <PopoverBody className="p-0">
        <ListGroup flush>
          {result.reference.map((_, i) =>
            <ListGroupItem key={i} tag="button" className="d-flex" action onClick={() => onSelect(_.value, _.title, _)}>
              {_.person ? <>
                <UserThumbnail className="mr-2" width={32} value={_.person} />
                <div>
                  <div className="text-capitalize">{_.person.name}</div>
                  <div className="small text-muted">{_.person.email}</div>
                </div>
              </> : _.organization ? <>
                {_.organization.logo && <img src={_.organization.logo} width={32} height={32} className="mr-3 align-baseline" />}
                <div style={{ minWidth: "150px" }}>
                  {_.organization.unitName && <div className="small text-muted">{_.organization.name}</div>}
                  <div>{_.organization.unitName ?? _.organization.name}</div>
                </div>
              </> : _.product ? <>
                {_.product.details.manufacturerOrg?.logo && <img src={_.product.details.manufacturerOrg.logo} width={32} height={32} className="mr-3 align-baseline" />}
                <div>
                  <div className="small text-muted">{_.product.details.subtitle}</div>
                  <div>{_.product.details.title}</div>
                </div>
              </> : _.title
              }
            </ListGroupItem>
          )}
        </ListGroup>
      </PopoverBody>
    </Popover>
  ) : null;
}

export default ({ value, types, exclude, placement, container, onUpdate, ...attrs }: {
  value: string,
  types: ReferenceDataType[],
  exclude?: ReadonlyArray<string>,
  models?: ReadonlyArray<ReferenceDataModel>,
  placement?: Popper.Placement,
  container?: string,
  onUpdate: (value: string | undefined, label: string, model: ReferenceDataModel | undefined) => void
} & InputProps) => {
  const [id] = useState(nextSequence());
  const [query, setQuery] = useState("");
  useEffect(() => {
    if (!value) {
      setQuery("");
    }
  }, [value])
  const update = (value: string) => {
    onUpdate(undefined, value, undefined);
    setQuery(value);
  }
  const select = (value: string, label: string, model: ReferenceDataModel) => {
    onUpdate(value, label, model);
    setQuery("");
  }
  return <>
    <DebouncedInput {...attrs} type="text" id={id} value={value} delay={300} onUpdate={update} />
    {query && <ReferencePopover onSelect={select} {...{ id, query, types, container, placement, exclude }} />}
  </>;
};
