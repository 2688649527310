import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, CustomInput, Form, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { UserThumbnail, UserThumbnailFragment } from "../cards/PersonCard";
import DatalistInput from "../components/DatalistInput";
import FormGroup from "../components/FormGroup";
import MutationButton from "../components/MutationButton";
import { DeepPartial, nextNonce, setDelete, useReadyState, nextSequence } from "../hooks/ApiProvider";
import { useUpdatableState } from "../hooks/CommonHooks";
import { useReviewOperations } from "../hooks/DiscussionHook";
import { useMe } from "../hooks/MeProvider";
import { preventDefault } from "../hooks/NavigationHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { AttachmentInputModel, AttachmentModel, AttachmentType, ReferenceDataModel, ReferenceDataType, ResourceKind, ResourceState, ReviewInputModel, ReviewModel, ReviewOption, ReviewType } from "../types/api-graph-types";

library.add(faTimes, faPlus);

export const Fragment = `
  id
  kind
  type
  rating
  isPrivate
  message
  like
  love
  options
  author { ${UserThumbnailFragment} }
  attachments { id kind type state filename }
`;

export type ReviewCriteria = [number, number, ReviewOption, number, string];

const _starFull: IconProp = ["fas", "star"];
const _starHalf: IconProp = ["fas", "star-half-alt"];
const _starEmpty: IconProp = ["far", "star"];

function _toAttachmentInputModel(value: AttachmentModel) {
  return {
    id: value.id,
    type: value.type || AttachmentType.Null,
    state: value.state,
    filename: value.filename || "",
  } as Partial<AttachmentInputModel>;
}

function _toReviewInput(value: ReviewModel | undefined, defaultIsPrivate: boolean) {
  return {
    type: value?.type || ReviewType.Null,
    state: value?.state || ResourceState.Proposed,
    isPrivate: value ? value.isPrivate : defaultIsPrivate,
    visibilityId: value?.visibilityId,
    message: value?.message || "",
    options: value?.options || [],
    rating: value?.rating || 0,
    clientUri: "",
    attachments: (value?.attachments || []).map(_toAttachmentInputModel)
  } as ReviewInputModel;
}

export default ({ objectId, objectKind, criteria, value, title, isOpen, toggle, defaultPrivate = false, attachmentTypes, showDiscussioner, onUpdate }: {
  value?: ReviewModel,
  isOpen?: boolean,
  toggle?: () => void,
  defaultPrivate?: boolean,
  objectId: string,
  objectKind: ResourceKind,
  title: string,
  showDiscussioner?: boolean,
  attachmentTypes?: ReadonlyArray<AttachmentType>,
  criteria?: ReadonlyArray<ReviewCriteria>
  onUpdate?: (value: DeepPartial<ReviewModel>) => void
}) => {
  const [t] = useTranslation();
  const [uid] = useState(nextSequence());
  const [me] = useMe();
  const [readyState, setReadyState] = useReadyState();
  const [query, setQuery] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [input, setInput, updateInput] = useUpdatableState(_toReviewInput(value, defaultPrivate));
  const [author, setAuthor] = useState(value?.author || me);
  const { addReview, updateReview } = useReviewOperations();
  useEffect(() => setInput(_toReviewInput(value, defaultPrivate)), [value])
  const setPerson = (_value: string | undefined, query: string, data: ReferenceDataModel | undefined) => {
    if (_value) {
      setQuery("");
      data && setAuthor(data.person);
      //updateInput({ authorId: _value });
    } else {
      setQuery(query);
    }
  };
  const setRating = (rating: number) => updateInput({ rating });
  const post = async () => {
    const result = await addReview(objectId, objectKind, { ...input, state: ResourceState.Resolved }, setReadyState, files);
    onUpdate?.(result);
    toggle?.();
  };
  const update = async () => {
    const result = await updateReview(objectId, objectKind, { ...input, state: ResourceState.Resolved }, setReadyState, files);
    onUpdate?.(result);
    toggle?.();
  };
  const cancel = toggle;
  const hasCriteria = (value: ReviewCriteria) => {
    return value[2] !== ReviewOption.Null ? options.has(value[2])
      : !!value[4] ? input.message.indexOf(value[4]) >= 0
        : false;
  }
  const toggleCriteria = (value: ReviewCriteria) => () => {
    const nextOptions = value[2] === ReviewOption.Null ? Array.from(options)
      : options.has(value[2]) ? Array.from(options).filter(_ => _ !== value[2])
        : Array.from(options).concat([value[2]]);
    const nextMessage = !value[4] ? input.message
      : input.message.indexOf(value[4]) >= 0 ? input.message.replace(`${value[4]}.`, "").replace(value[4], "").trim()
        : `${input.message.replace(/\.*$/, "")}${input.message ? "." : ""} ${value[4]}`.trim();
    updateInput({ options: nextOptions, message: nextMessage });
  }
  const updateAttachment = (attachment: DeepPartial<AttachmentInputModel>) => updateInput({ attachments: [attachment] });
  const addAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filename = e.target.files?.[0]?.name || "";
    if (filename) {
      updateAttachment({
        key: nextNonce(),
        type: AttachmentType.ReferenceLetter,
        content: filename,
        filename
      });
      setFiles([...files, ...Array.from(e.target.files || [])]);
    }
  }
  const delAttachment = (value: AttachmentInputModel) => () => {
    if (value.state) {
      updateAttachment({ ...value, state: ResourceState.Removed });
    } else {
      updateAttachment(setDelete(value));
      setFiles(files.filter(_ => _.name !== value.filename));
    }
  };
  const rating = input.rating || 0;
  const options = new Set(input.options || []);
  const activeCriteria = (criteria || []).filter(_ => hasCriteria(_) || (rating >= _[0] && rating <= _[1]));
  const hasAttachments = input.attachments?.length > 0;
  const onSubmit = value?.id ? update : post;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Review {title}</ModalHeader>
      <ModalBody>
        <Form onSubmit={preventDefault(onSubmit)} className="w-100">
          {showDiscussioner &&
            <FormGroup label="Reviewer">
              <div className="d-flex bg-input p-2">
                {author && <UserThumbnail className="mr-2" width={36} value={author} />}
                <DatalistInput className="p-0" types={[ReferenceDataType.Person]} placeholder="Enter a name, email or phone" value={query || author?.name || ""} onUpdate={setPerson} />
              </div>
            </FormGroup>
          }
          <FormGroup label="Rating" className="mt-3">
            <div className="d-flex hover-container">
              <ButtonGroup size="lg" className="mr-auto">
                <Button title="Poor" className="m-0 p-0" color="link" onClick={() => setRating(2)}><FontAwesomeIcon fixedWidth icon={rating > 1 ? _starFull : rating > 0 ? _starHalf : _starEmpty} /></Button>
                <Button title="Fair" className="m-0 p-0" color="link" onClick={() => setRating(4)}><FontAwesomeIcon fixedWidth icon={rating > 3 ? _starFull : rating > 2 ? _starHalf : _starEmpty} /></Button>
                <Button title="Average" className="m-0 p-0" color="link" onClick={() => setRating(6)}><FontAwesomeIcon fixedWidth icon={rating > 5 ? _starFull : rating > 4 ? _starHalf : _starEmpty} /></Button>
                <Button title="Good" className="m-0 p-0" color="link" onClick={() => setRating(8)}><FontAwesomeIcon fixedWidth icon={rating > 7 ? _starFull : rating > 6 ? _starHalf : _starEmpty} /></Button>
                <Button title="Excellent" className="m-0 p-0" color="link" onClick={() => setRating(10)}><FontAwesomeIcon fixedWidth icon={rating > 9 ? _starFull : rating > 8 ? _starHalf : _starEmpty} /></Button>
              </ButtonGroup>
            </div>
          </FormGroup>
          {activeCriteria.length > 0 &&
            <FormGroup>
              {activeCriteria.map((_, i) => <CustomInput key={i} id={`${uid}.0.${i}`} type="checkbox" label={_[4] || t(_[2])} checked={hasCriteria(_)} onChange={toggleCriteria(_)} />)}
            </FormGroup>
          }
          <FormGroup label="Comments">
            <Input type="textarea" rows={5} className="w-100" placeholder="Enter your review here" value={input.message} onChange={e => updateInput({ message: e.target.value })} />
          </FormGroup>
          <FormGroup>
            <CustomInput id="isprivate-switch" type="switch" checked={input.isPrivate} onChange={() => updateInput({ isPrivate: !input.isPrivate })} label="Keep this review private" />
          </FormGroup>
          <FormGroup label="Attachments">
            {hasAttachments &&
              <ul className="list-unstyled">
                {input.attachments && input.attachments.map((_, i) =>
                  <li key={i} className="d-flex">
                    <FontAwesomeIcon className="mr-1" icon="paperclip" />
                    {_.state !== ResourceState.Removed && <span>{_.filename}</span>}
                    {_.state === ResourceState.Removed && <del>{_.filename}</del>}
                    <Button color="link" size="sm" className="ml-auto" onClick={delAttachment(_)}>Remove</Button>
                  </li>
                )}
              </ul>
            }
            <InputGroup>
              <div className='custom-file'>
                <Input type="file" className={`custom-file-input`} onChange={addAttachment} />
                <label className="custom-file-label text-truncate text-nowrap text-muted">Select your file here</label>
              </div>
            </InputGroup>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>Cancel</Button>
        {value?.id && <MutationButton readyState={readyState} disabled={!input.rating} color="primary" onClick={update}>Update</MutationButton>}
        {!value?.id && <MutationButton readyState={readyState} disabled={!input.rating} color="primary" onClick={post}>Post</MutationButton>}
      </ModalFooter>
    </Modal>
  );
}

