import React from "react";
import { CardText, Row, Col } from "reactstrap";
import { LegalResearchRephrasingModel, ReviewOption, TaskModel, TaskType, AreaType } from "../../types/api-graph-types";
import FormGroup, { len, trunc, between } from "../../components/FormGroup";
import Input from "../../components/Input";
import { useTranslation } from "../../hooks/TranslationProvider";
import { useMutation, ApiErrors } from "../../hooks/ApiProvider";
import Highlight from "../../components/Highlight";

export const Fragment = `... on LegalResearchRephrasingModel { 
  query
  area
  jurisdiction
}`;

const _updateTaskDetails = `mutation updateLegalResearchRephrasingTaskDetails($id:ID! $value:LegalResearchRephrasingInputModel) {
  task(id:$id) {
    details { ... on LegalResearchRephrasingOperations { change(value:$value) } }  
    commit { details { ${Fragment} } }
  }
}`;

interface OperationResults {
  task: {
    commit: LegalResearchRephrasingModel
  }
};

const ToInput = (value: LegalResearchRephrasingModel) => ({
  area: value.area || "",
  jurisdiction: value.jurisdiction || "",
  query: value.query || ""
} as any);

export const CommentOptions: [number, number, ReviewOption, number, string][] = [
  [1, 2, ReviewOption.Empty, 0, ""],
  [1, 2, ReviewOption.Spam, -1, ""],
  [1, 2, ReviewOption.Offensive, -1, ""],
  [1, 2, ReviewOption.Plagiarised, -1, ""],
  [3, 6, ReviewOption.LegalResearchRephrasingQueryPoor, 0, ""],
  [3, 8, ReviewOption.LegalResearchRephrasingAreaPoor, 0, ""],
  [3, 8, ReviewOption.LegalResearchRephrasingJurisdictionPoor, 0, ""],
  [7, 10, ReviewOption.LegalResearchRephrasingQueryExcellent, 1, ""],
  [3, 8, ReviewOption.LanguagePoor, 0, ""],
  [9, 10, ReviewOption.LanguageExcellent, 1, ""],
];

export const Is = (value: TaskModel): value is TaskModel & { details: LegalResearchRephrasingModel } =>
  value.type === TaskType.LegalResearchRephrasing

export const IsValid = (value: LegalResearchRephrasingModel) =>
  value.area
  && value.jurisdiction && len(value.query) <= 140
  && value.query && between(len(value.query), 14, 140)
  || false;

export function InputText({ id, help, value, onUpdate }: {
  value: LegalResearchRephrasingModel,
  id: string,
  help?: boolean,
  onUpdate: (value: LegalResearchRephrasingModel) => void
}) {
  const [t] = useTranslation();
  const input = ToInput(value || {});
  const [mutation] = useMutation<OperationResults>();
  const update = async (partial: Partial<LegalResearchRephrasingModel>) => {
    const value = { ...input, ...partial };
    onUpdate(value);
    await mutation(_updateTaskDetails, { id, value }, { debounce: 5000 });
  }
  return (
    <CardText tag='div'>
      <Row form>
        <Col>
          <FormGroup label="Area" openHelp={help} help="What area of law does the core legal issue fall under? If multiple apply, select the most specific one." required value={input.area}>
            <select className="custom-select" value={input.area} onChange={e => update({ area: e.currentTarget.value })}>
              {Object.values(AreaType)
                .filter((k: string) => k.startsWith("LEGAL_") || k === AreaType.Null)
                .map(k => <option key={k} value={t(k)}>{t(k)}</option>)}
            </select>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup label="Jurisdiction" openHelp={help} help="What country or state has jurisdiction over this matter? If multiple apply, enter the most specific one." required maxLength={140} value={input.jurisdiction}>
            <Input type="text" onUpdate={jurisdiction => update({ jurisdiction })} />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup label="Issue" openHelp={help} help="In your own words rephrase the core legal issue raised by the question. Limit to 15 words. Do not copy-paste from the question. Ignore non-relevant facts in the question." required minLength={14} maxLength={140} value={input.query}>
        <Input type="textarea" onUpdate={query => update({ query })} />
      </FormGroup>
    </CardText>
  );
}

export default ({ value }: { value: LegalResearchRephrasingModel }) => {
  return (
    <CardText tag='div'>
      <ul className='list-unstyled'>
        <li><b>Area</b> {value.area}</li>
        <li><b>Jurisdiction</b> {value.jurisdiction}</li>
      </ul>
      <Highlight value={value.query} />
    </CardText>
  );
}
