import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Button, CardBody, CardBodyProps, CardHeader, CustomInput } from "reactstrap";
import LegalResearchBackgroundText, { CommentOptions as LegalResearchBackgroundCommentOptions, Fragment as LegalResearchBackgroundFragment, InputText as LegalResearchBackgroundInputText, Is as IsLegalResearchBackground, IsValid as IsValidLegalResearchBackground } from "../cards/Legal/LegalResearchBackgroundText";
import LegalResearchConclusionText, { CommentOptions as LegalResearchConclusionCommentOptions, Fragment as LegalResearchConclusionFragment, InputText as LegalResearchConclusionInputText, Is as IsLegalResearchConclusion, IsValid as IsValidLegalResearchConclusion } from "../cards/Legal/LegalResearchConclusionText";
import LegalResearchPrecedentText, { CommentOptions as LegalResearchPrecedentCommentOptions, Fragment as LegalResearchPrecedentFragment, InputText as LegalResearchPrecedentInputText, Is as IsLegalResearchPrecedent, IsValid as IsValidLegalResearchPrecedent } from "../cards/Legal/LegalResearchPrecedentText";
import LegalResearchRephrasingText, { CommentOptions as LegalResearchRephrasingCommentOptions, Fragment as LegalResearchRephrasingFragment, InputText as LegalResearchRephrasingInputText, Is as IsLegalResearchRephrasing, IsValid as IsValidLegalResearchRephrasing } from "../cards/Legal/LegalResearchRephrasingText";
import LegalResearchStatuteText, { CommentOptions as LegalResearchStatuteCommentOptions, Fragment as LegalResearchStatuteFragment, InputText as LegalResearchStatuteInputText, Is as IsLegalResearchStatute, IsValid as IsValidLegalResearchStatute } from "../cards/Legal/LegalResearchStatuteText";
import Card, { CardAttributes, CardAttributionAttributes, CardBodyAttributes } from "../components/Card";
import { Fragment as AttachmentFragment, InputText as AttachmentsInputText, List as CardAttachmentsList } from "../components/CardAttachmentsListGroup";
import CardAttributionBody, { CardFlagButton, CardLikeButton, CardLoveButton, CardSelectButton, Fragment as AttributionFragment } from "../components/CardAttributionBody";
import CardDiscussionListGroup, { Fragment as DiscussionFragment } from "../components/CardDiscussionListGroup";
import { CardDiscussionSummaryStatusItems, CardStatus, CardStatusMenu } from "../components/CardStatus";
import { DeepPartial, nextSequence } from "../hooks/ApiProvider";
import { useCardState } from "../hooks/CardStateHook";
import { useToggle } from "../hooks/CommonHooks";
import { usePreference } from "../hooks/PreferencesProvider";
import { useTranslation } from "../hooks/TranslationProvider";
import { ITaskDetailsModel, ResourceKind, TaskModel } from "../types/api-graph-types";

export const Fragment = `
  id
  kind
  state
  type
  updatedOn
  sequence
  details {
    ${LegalResearchRephrasingFragment}
    ${LegalResearchBackgroundFragment}
    ${LegalResearchStatuteFragment}
    ${LegalResearchPrecedentFragment}
    ${LegalResearchConclusionFragment}
  }
  attachments { ${AttachmentFragment} }
  policy { role }
  worker { ${AttributionFragment} }
  reviewsSummary { ratingAverage authors { name } }
  ${DiscussionFragment}
`;

export const isLegalResearchRephrasing = IsLegalResearchRephrasing;
export const isLegalResearchBackground = IsLegalResearchBackground;
export const isLegalResearchStatute = IsLegalResearchStatute;
export const isLegalResearchPrecedent = IsLegalResearchPrecedent;
export const isLegalResearchConclusion = IsLegalResearchConclusion;

function _getOptions(value: TaskModel) {
  if (isLegalResearchRephrasing(value)) return LegalResearchRephrasingCommentOptions;
  else if (isLegalResearchBackground(value)) return LegalResearchBackgroundCommentOptions;
  else if (isLegalResearchStatute(value)) return LegalResearchStatuteCommentOptions;
  else if (isLegalResearchPrecedent(value)) return LegalResearchPrecedentCommentOptions;
  else if (isLegalResearchConclusion(value)) return LegalResearchConclusionCommentOptions;
  else return [];
}

export function IsValid(value: TaskModel) {
  if (isLegalResearchRephrasing(value)) return IsValidLegalResearchRephrasing(value.details);
  else if (isLegalResearchBackground(value)) return IsValidLegalResearchBackground(value.details);
  else if (isLegalResearchStatute(value)) return IsValidLegalResearchStatute(value.details);
  else if (isLegalResearchPrecedent(value)) return IsValidLegalResearchPrecedent(value.details);
  else if (isLegalResearchConclusion(value)) return IsValidLegalResearchConclusion(value.details);
  else return true;
}

export function InputCard({ value, disabled, showAttachments, onUpdate, onRemove, onAdd, onSkip, onNext, children }: {
  value: TaskModel,
  disabled?: boolean,
  showAttachments?: boolean,
  onUpdate: (value: DeepPartial<TaskModel>) => void,
  onRemove?: (value: DeepPartial<TaskModel>) => void,
  onAdd?: () => void,
  onSkip?: () => void,
  onNext?: () => void,
  children?: ReactNode
}) {
  const [t] = useTranslation();
  const [instructionsId] = useState(nextSequence());
  const [showHelp, setShowInstructions] = usePreference(`SHOW_HELP_${value.type}`, true);
  const updateDetails = async (details: ITaskDetailsModel) => onUpdate({ id: value.id, details });
  return (
    <Card className="mt-3">
      <CardHeader className="bg-white d-flex align-items-center">
        <h3>{t(value.type)}</h3>
        <CustomInput className="ml-auto" type="switch" checked={showHelp} onChange={() => setShowInstructions(!showHelp)} id={instructionsId} label="Instructions" />
      </CardHeader>
      <CardBody>
        {isLegalResearchRephrasing(value) && <LegalResearchRephrasingInputText id={value.id} value={value.details} help={showHelp} onUpdate={updateDetails} />}
        {isLegalResearchBackground(value) && <LegalResearchBackgroundInputText id={value.id} value={value.details} help={showHelp} onUpdate={updateDetails} />}
        {isLegalResearchStatute(value) && <LegalResearchStatuteInputText id={value.id} value={value.details} help={showHelp} onUpdate={updateDetails} />}
        {isLegalResearchPrecedent(value) && <LegalResearchPrecedentInputText id={value.id} value={value.details} help={showHelp} onUpdate={updateDetails} />}
        {isLegalResearchConclusion(value) && <LegalResearchConclusionInputText id={value.id} value={value.details} help={showHelp} onUpdate={updateDetails} />}
        {showAttachments && <AttachmentsInputText value={value} onUpdate={onUpdate} help="Optionally attach any additional documents that you feel might be useful." />}
      </CardBody>
      <CardBody className="card-buttons-right">
        {onRemove && <Button color="link" disabled={disabled} onClick={() => onRemove(value)}>Remove</Button>}
        {onAdd && <Button color="link" disabled={disabled} onClick={onAdd}>Add another</Button>}
        {onSkip && <Button color="link" disabled={disabled} onClick={onSkip}>Skip</Button>}
        {onNext && <Button disabled={!IsValid(value) || disabled} color="primary" onClick={onNext}>Next</Button>}
      </CardBody>
      {children}
    </Card>
  );
}

function Header({ value, appendHeader }: { value: TaskModel, appendHeader?: ReactNode }) {
  const [t] = useTranslation();
  return (
    <CardHeader className="bg-white d-flex" tag="h3">
      {t(value.type)}
      {appendHeader}
    </CardHeader>
  );
}

function Attribution({ value, selected, onUpdate, onSelect }: CardAttributionAttributes<TaskModel>) {
  const props = { objectId: value.id, objectKind: ResourceKind.Task };
  return (
    <CardAttributionBody value={value.worker} at={value.updatedOn}>
      <CardLoveButton value={value.review} onUpdate={review => onUpdate?.({ id: value.id, review, reviewsSummary: review.relatedSummary })} {...props} />
      <CardLikeButton value={value.review} onUpdate={review => onUpdate?.({ id: value.id, review, reviewsSummary: review.relatedSummary })} {...props} />
      {false && <CardFlagButton value={value.review} onUpdate={review => onUpdate?.({ id: value.id, review, reviewsSummary: review.relatedSummary })} {...props} />}
      {onSelect && <CardSelectButton selected={selected} onSelect={onSelect} />}
    </CardAttributionBody>
  );
}

export function Text({ value, showAttachments }: { value: TaskModel, showAttachments?: boolean }) {
  return (
    <>
      {isLegalResearchConclusion(value) && <LegalResearchConclusionText value={value.details} />}
      {isLegalResearchPrecedent(value) && <LegalResearchPrecedentText value={value.details} />}
      {isLegalResearchRephrasing(value) && <LegalResearchRephrasingText value={value.details} />}
      {isLegalResearchStatute(value) && <LegalResearchStatuteText value={value.details} />}
      {isLegalResearchBackground(value) && <LegalResearchBackgroundText value={value.details} />}
      {showAttachments && <CardAttachmentsList values={value.attachments} />}
    </>
  );
}

function Body({ value, className, showAttachments, showStatus, hideRating, showMenu, onUpdate, onClick, children, toggleDiscussion, ...attrs }: CardBodyAttributes<TaskModel> & CardBodyProps) {
  const [isOpenMenu, toggleMenu] = useToggle();
  return (
    <CardBody className={`hover-container ${className}`} onClick={onClick || toggleMenu} {...attrs}>
      <Text value={value} showAttachments={showAttachments} />
      {showStatus &&
        <CardStatus>
          <CardDiscussionSummaryStatusItems hideRating={hideRating} review={value.review} reviews={value.reviewsSummary} comments={value.commentsSummary} toggleDiscussion={toggleDiscussion} />
          {showMenu &&
            <CardStatusMenu isOpen={isOpenMenu} toggle={toggleMenu}>
            </CardStatusMenu>
          }
        </CardStatus>
      }
      {children}
    </CardBody>
  );
}

export default ({ value, className, showAttribution, showHeader, showStatus, showMenu, showAttachments, showDiscussion, showReview, showInput, showShare, openDiscussion, openReview, openInput, openShare, hideRating, selected, onSelect, href, onClick, onUpdate, onSelectText, appendHeader, children }: CardAttributes<TaskModel>) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isOpenDiscussion, toggleDiscussion } = useCardState({ showDiscussion, showInput, showShare, openDiscussion, openInput, openShare });
  useEffect(() => {
    const element = ref.current;
    if (element && onSelectText) {
      const handleSelect = () => {
        element.removeEventListener("mouseup", handleSelect);
        onSelectText((document.getSelection() || "").toString().trim());
      };
      const handleSelectStart = () => {
        element.addEventListener("mouseup", handleSelect);
      };
      element.addEventListener("selectstart", handleSelectStart);
      return () => element.removeEventListener("selectstart", handleSelectStart);
    }
  }, [ref.current]);
  const classNames = [
    className,
    value.reviewsSummary.ratingAverage > 0 && value.reviewsSummary.ratingAverage < 5 ? "opacity-50" : "",
    href || onClick ? "hover-shadow" : "",
    onSelectText ? "select-text" : ""
  ].join(" ");
  return (
    <Card className={classNames}>
      <div ref={ref}>
        {showHeader && <Header {...{ value, appendHeader }} />}
        {showAttribution && <Attribution {...{ value, onUpdate, onSelect, selected }} />}
        <Body value={value} {...{ href, onUpdate, onClick, showAttachments, showMenu, showStatus, hideRating, toggleDiscussion }} >
          {children}
        </Body>
        {showDiscussion && <CardDiscussionListGroup forcePublic value={value} criteria={_getOptions(value)} onUpdate={onUpdate} showReview={showReview} openReview={openReview} isOpen={isOpenDiscussion} />}
      </div>
    </Card>
  );
}
