import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "../hooks/TranslationProvider";
import { preventDefault } from "../hooks/NavigationHook";
import { useToggle } from "../hooks/CommonHooks";
import { useWindowState } from "../hooks/WindowStateProvider";

export default ({ children, height, className }: { className?: string, children: ReactNode, height: string | number }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useToggle();
  const [hasOverflow, setHasOverflow] = useState(false);
  const [size] = useWindowState();
  const style: CSSProperties = isOpen ? { maxHeight: ref.current?.scrollHeight, overflow: "hidden" } : { maxHeight: height, overflow: "hidden" };
  useEffect(() => checkOverflow(), [size]);
  const checkOverflow = () => {
    if (ref.current) {
      const next = ref.current.scrollHeight > ref.current.clientHeight;
      if (next !== hasOverflow) {
        setHasOverflow(next);
      }
    }
  }
  return (
    <div className={`clamp ${className} mb-0`}>
      <div ref={ref} className='clamp-body' onTransitionEnd={checkOverflow} style={style}>
        {children}
      </div>
      {hasOverflow && !isOpen && <a href='#' className='clamp-toggle' onClick={preventDefault(toggle)}>See more</a>}
    </div>
  );
};
