import React, { useRef, useState, useEffect } from "react";
import ReactCrop, { Crop } from 'react-image-crop';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Form, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { UserThumbnail } from "../../cards/PersonCard";
import { CardAttributes } from "../../components/Card";
import { CardButton, CardButtons } from "../../components/CardStatus";
import { Group as ColorButtonGroup } from "../../components/ColorButton";
import FormGroup from "../../components/FormGroup";
import MutationButton from "../../components/MutationButton";
import PhoneNumberSpan from "../../components/PhoneNumberSpan";
import { ApiErrors, useMutation, useReadyState } from "../../hooks/ApiProvider";
import { useCardState } from "../../hooks/CardStateHook";
import { useUpdatableState } from "../../hooks/CommonHooks";
import { AttachmentInputModel, AttachmentType, GenderType, MeModel, PersonInputModel, ResourceState } from "../../types/api-graph-types";

export const Fragment = `
  name
  title
  nickname
  gender
  email
  phone
  accentColor
  picture
`;

const _updateMe = `mutation updateMe($value:PersonInputModel $changePhoto:Boolean! $photo:AttachmentInputModel) {
  me {
    change(value:$value)
    commit { ${Fragment} }
    photo @include(if:$changePhoto) {
      change(value:$photo)
      commit { id }
    }
  }
}`

interface OperationResults {
  me: {
    commit: MeModel
  }
}

interface OperationErrors {
  me: {
    change: ApiErrors<{ value: PersonInputModel }>
  }
}

const _toPersonInputModel = (value: MeModel): PersonInputModel => ({
  name: value.name,
  nickname: value.nickname,
  gender: value.gender,
  accentColor: value.accentColor,
  title: value.title,
  picture: value.picture,
  incomePreference: value.incomePreference,
  contactsConfirmedOn: value.contactsConfirmedOn,
  termsAgreedOn: value.termsAgreedOn
});

function InputModal({ value, isOpen, onUpdate, toggle }: {
  value: MeModel,
  isOpen?: boolean,
  toggle?: () => void
  onUpdate?: (value: MeModel) => void
}) {
  const [readyState, setReadyState] = useReadyState();
  const [input, setInput, updateInput] = useUpdatableState(_toPersonInputModel(value));
  const [photo, setPhoto] = useState<File>();
  const [photoData, setPhotoData] = useState<string>();
  const [croppedPhoto, setCroppedPhoto] = useState<string>();
  const [crop, setCrop] = useState({ aspect: 1 / 1, unit: "%", width: 50 } as Crop);
  const cropImage = useRef<HTMLImageElement>();
  const [errors, setErrors] = useState<ApiErrors<PersonInputModel>>({});
  const [mutation] = useMutation<OperationResults>();
  const reset = () => {
    setInput(_toPersonInputModel(value));
    setPhoto(undefined);
    setPhotoData(undefined);
    setCroppedPhoto(undefined);
  }
  useEffect(reset, [value]);
  const update = async () => {
    try {
      const result = await mutation(_updateMe, {
        value: input,
        changePhoto: !!photo?.name,
        photo: {
          type: AttachmentType.Photo,
          state: ResourceState.Resolved,
          squareCroppedUri: croppedPhoto,
          content: photo?.name,
          title: "Photo",
          subtitle: input.name
        } as Partial<AttachmentInputModel>,
      }, { setReadyState, files: photo ? [photo] : undefined });
      onUpdate?.(result.me.commit);
      toggle?.();
    } catch (errors) {
      setErrors((errors as OperationErrors).me.change.value || {});
    }
  }
  const cancel = () => {
    reset();
    toggle?.();
  }
  const updatePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]?.name) {
      const file = e.target.files?.[0];
      const reader = new FileReader();
      setPhoto(file);
      setCroppedPhoto(undefined);
      reader.addEventListener('load', () => setPhotoData(reader.result as string));
      reader.readAsDataURL(file);
    }
  }
  const updatePicture = (crop: Crop) => {
    if (cropImage.current && crop.width && crop.height) {
      const image = cropImage.current;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const cropped = document.createElement("canvas");
      cropped.width = 1024;
      cropped.height = 1024;
      const croppedCtx = cropped.getContext("2d");
      if (croppedCtx) {
        croppedCtx.drawImage(image, (crop.x ?? 0) * scaleX, (crop.y ?? 0) * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, cropped.width, cropped.height);
        setCroppedPhoto(cropped.toDataURL("image/jpeg"));
      }

      const thumbnail = document.createElement("canvas");
      thumbnail.width = 128; // crop.width;
      thumbnail.height = 128; // crop.height;
      const thumbnailCtx = thumbnail.getContext('2d');
      if (thumbnailCtx) {
        thumbnailCtx.drawImage(image, (crop.x ?? 0) * scaleX, (crop.y ?? 0) * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, thumbnail.width, thumbnail.height);
        const picture = thumbnail.toDataURL("image/jpeg");
        updateInput({ picture });
      }
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className='modal-header w-100'>
        <FormGroup label="Full name" className="w-100" error={errors.name}>
          <Input type="text" className="form-control-lg w-100" placeholder="Enter your full name here" value={input.name || ""} onChange={e => updateInput({ name: e.currentTarget.value })} />
        </FormGroup>
      </div>
      <ModalBody>
        <Form>
          <fieldset>
            <Row form>
              <Col>
                <FormGroup label='First name' error={errors.nickname}>
                  <Input type="text" className="form-control" placeholder="Enter your first name here" value={input.nickname || ""} onChange={e => updateInput({ nickname: e.currentTarget.value })} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup label='Gender pronoun' error={errors.gender}>
                  <select className="custom-select" value={input.gender} onChange={e => updateInput({ gender: e.currentTarget.value as GenderType })}>
                    <option value={GenderType.Null}>{input.nickname || "The user"} is highly knowledgeable</option>
                    <option value={GenderType.Female}>She is highly knowledgeable</option>
                    <option value={GenderType.Male}>He is highly knowledgeable</option>
                    {false && <option value={GenderType.Other}>They are highly knowledgeable</option>}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup label='Accent color' error={errors.accentColor}>
              <ColorButtonGroup value={input.accentColor} onUpdate={accentColor => updateInput({ accentColor })} />
            </FormGroup>
            <FormGroup label='Photo' error={errors.picture}>
              <InputGroup>
                {input.picture &&
                  <InputGroupAddon addonType="prepend" className="bg-input">
                    <img className="rounded-circle" width={36} height={36} src={input.picture} />
                  </InputGroupAddon>
                }
                <div className="custom-file">
                  <Input type="file" accept="image/*" className="custom-file-input" onChange={updatePhoto} />
                  <label className={`custom-file-label text-truncate text-nowrap ${!photo?.name && "text-muted"}`}>
                    {photo?.name || "Select your photo here"}
                  </label>
                </div>
              </InputGroup>
              {photoData && <ReactCrop src={photoData} crop={crop} circularCrop onImageLoaded={img => cropImage.current = img} onChange={setCrop} onComplete={updatePicture} />}
            </FormGroup>
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>Cancel</Button>
        <MutationButton readyState={readyState} color="primary" onClick={update}>Update</MutationButton>
      </ModalFooter>
    </Modal>
  );
}

function Body({ value, toggleInput }: {
  value: MeModel,
  toggleInput?: () => void
}) {
  const classNames = [
    toggleInput ? "cursor-pointer hover-shadow" : ""
  ].join(" ");
  return (
    <CardBody className={classNames} onClick={toggleInput}>
      <div className="d-flex">
        <UserThumbnail className="mr-2" value={value} width={64} />
        <div>
          <CardTitle tag="h1">{value.name}</CardTitle>
          {value.title && <CardSubtitle tag="h5">{value.title}</CardSubtitle>}
        </div>
      </div>
      <ul className='list-unstyled mt-3'>
        {value.email && <li><b>Email</b> {value.email}</li>}
        {value.phone && <li><b>Phone</b> <PhoneNumberSpan value={value.phone} /></li>}
      </ul>
      <CardButtons>
        <CardButton onClick={toggleInput}>Edit</CardButton>
      </CardButtons>
    </CardBody>
  );
}

export default ({ value, onUpdate, showInput, className }: CardAttributes<MeModel>) => {
  const { isOpenInput, toggleInput } = useCardState({ showInput });
  return (
    <>
      <Card>
        <Body value={value} {...{ toggleInput }} />
      </Card>
      {showInput && <InputModal value={value} onUpdate={onUpdate} isOpen={isOpenInput} toggle={toggleInput} />}
    </>
  );
}
