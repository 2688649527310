import React, { createContext, ReactNode, useContext, useState, useEffect } from "react";
import { useQueryString } from "./NavigationHook";
import { nextSequence } from "./ApiProvider";

interface State {
  value: string,
  isOpen?: boolean,
}

interface QueryString {
  q: string
}

type ContextType = [State, (value: Partial<State>) => void];
const initialContext: any = undefined;
const Context = createContext<ContextType>(initialContext);

export function useSearchContext() {
  return useContext(Context);
}

export function useSearch(): [string, (value: string) => void] {
  const [id] = useState(nextSequence());
  const [context, setContext] = useSearchContext();
  const value = context.value;
  useEffect(() => {
    setContext({ isOpen: true });
    return () => setContext({ isOpen: false });
  }, [id]);
  const setValue = (value: string) => setContext({ isOpen: true, value });
  return [value, setValue];
}

export default ({ children }: { children?: ReactNode }) => {
  const [query, , updateQuery] = useQueryString<QueryString>({ q: "" });
  const [isOpen, setIsOpen] = useState(false);
  const value: State = { value: query.q, isOpen };
  const updateValue = (next: Partial<State>) => {
    next.isOpen !== undefined && setIsOpen(next.isOpen);
    next.value !== undefined && query.q !== next.value && updateQuery({ q: next.value });
  }
  return (
    <Context.Provider value={[value, updateValue]}>
      {children}
    </Context.Provider>
  );
}
