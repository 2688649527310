import React, { Suspense } from "react";

export function toWhatsapp(value: string) {
  return `https://wa.me/${value.replace(/[^0-9]/g, "")}`;
}

const PhoneNumberSpan = React.lazy(async () => {
  const phonenumber = await import("libphonenumber-js");
  return {
    default: ({ value, className }: { value: string, className?: string }) => (
      <span className={`text-nowrap ${className}`}>
        {phonenumber.formatIncompletePhoneNumber(value, "IN")}
      </span>
    )
  };
});

export default ({ value, className }: { value: string, className?: string }) => (
  <Suspense fallback={value}>
    <PhoneNumberSpan className={className} value={value} />
  </Suspense>
);
