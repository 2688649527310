import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Card, CardHeader, Col, Collapse, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, InputGroupAddon, InputGroupButtonDropdown, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown, Dropdown } from "reactstrap";
import { preventOverflow } from "../../components/CardStatus";
import DatalistInput from "../../components/DatalistInput";
import FormGroup from "../../components/FormGroup";
import { ApiErrors, DeepPartial, nextSequence, setDelete, useMutation, byKey } from "../../hooks/ApiProvider";
import { useToggle, useUpdatableState } from "../../hooks/CommonHooks";
import { useIcons } from "../../hooks/IconizeHook";
import { preventDefault } from "../../hooks/NavigationHook";
import { DateDisplayFormat, toLocalDate, toUtcString, useTranslation } from "../../hooks/TranslationProvider";
import { BoundedValueInputModel, CurrencyCode, MembershipInputModel, MembershipModel, MembershipType, MoneyInputModel, OrganizationType, PersonInputModel, ReferenceDataModel, ReferenceDataType, MeModel, CountryCode, CityCode, LocationInputModel } from "../../types/api-graph-types";
import { CityCodes } from "../../types/common-types";

library.add(faTrashAlt);

export const Fragment = `
  id
  kind
  type
  organization { id kind name }
  organizationName
  title
  focus
  role
  startOn
  endOn
  location { city locality }
  income { currency amount }
  cgpa { value maxValue }
  rank { value maxValue }
  description
`;

const _updateMembership = `mutation updateMembership($id:ID $value:MembershipInputModel $person:PersonInputModel $updatePerson:Boolean!) {
  me @include(if:$updatePerson) {
    change(value:$person)
    commit { title }
  }
  membership(id:$id) {
    change(value:$value)
    commit { ${Fragment} }
  }
}`

const _removeMembership = `mutation removeMembership($id:ID) {
  membership(id:$id) {
    remove
    commit { ${Fragment} }
  }
}`

const _addMembership = `mutation addMembership($type:MembershipType $value:MembershipInputModel $person:PersonInputModel $updatePerson:Boolean!) {
  me {
    change(value:$person) @include(if:$updatePerson)
    commit @include(if:$updatePerson) { id kind title }
    membership: addMembership(type:$type) {
      change(value:$value)
      commit { ${Fragment} }
    }
  }
}`

interface OperationResults {
  membership: {
    commit: MembershipModel
  },
  me: {
    commit: MeModel,
    membership: {
      commit: MembershipModel
    }
  }
}

interface OperationErrors {
  membership: {
    change: ApiErrors<{ value: MembershipInputModel }>
  }
  me: {
    membership: {
      change: ApiErrors<{ value: MembershipInputModel }>
    }
  }
}

const _toMembershipInputModel = (value: Partial<MembershipModel>) => ({
  title: value.title || "",
  focus: value.focus || "",
  organizationId: value.organization?.id || undefined,
  organizationName: value.organization?.name || value.organizationName || "",
  organizationNickname: value.organization?.nickname || value.organizationNickname || "",
  startOn: value.startOn,
  endOn: value.endOn,
  location: {
    country: value.location?.country || CountryCode.Null,
    postalCode: value.location?.postalCode || "",
    region: value.location?.region || "",
    streetAddress: value.location?.streetAddress || "",
    city: value.location?.city || CityCode.Null,
    locality: value.location?.locality || "",
    neighborhood: value.location?.neighborhood || ""
  },
  income: {
    currency: value.income?.currency || CurrencyCode.Inr,
    amount: value.income?.amount || undefined
  },
  cgpa: {
    value: value.cgpa?.value || undefined,
    maxValue: value.cgpa?.maxValue || undefined
  },
  rank: {
    value: value.rank?.value || undefined,
    maxValue: value.rank?.maxValue || undefined
  },
  description: value.description || ""
} as MembershipInputModel);

function _toLocation(t: (v: string) => string, value: LocationInputModel) {
  return `${value.streetAddress}, ${value.neighborhood}, ${value.city === CityCode.Other ? value.locality : t(value.city)}`;
}

function _formatNumber(value: number): string {
  return value.toLocaleString("en");
}

function InputModal({ isOpen, view, value, onUpdate, toggle }: {
  isOpen?: boolean,
  view: MembershipType,
  value: MembershipModel,
  onUpdate?: (value: DeepPartial<MembershipModel>) => void
  toggle?: () => void
}) {
  const [t, d] = useTranslation();
  const [uid] = useState(nextSequence());
  const [isOpenCurrency, toggleCurrency] = useToggle();
  const [isOpenTitle, toggleTitle] = useToggle();
  const [input, setInput, updateInput] = useUpdatableState(_toMembershipInputModel(value));
  const [titleOrg, setTitleOrg] = useState(value.organization?.nickname || value.organizationNickname || value.organization?.name || value.organizationName);
  const [title, setTitle] = useState("");
  const [mutation] = useMutation<OperationResults>();
  const [errors, setErrors] = useState<ApiErrors<MembershipInputModel>>({});
  useEffect(() => setInput(_toMembershipInputModel(value)), [value])
  useEffect(() => {
    setTitle(view === MembershipType.Student ? `${titleOrg || ""} '${(moment(input.endOn).local().format("YY"))}`
      : `${input.title || ""} at ${titleOrg || ""}`);
  }, [titleOrg, input.title, input.endOn])

  const updateIncome = (income: DeepPartial<MoneyInputModel>) => updateInput({ income });
  const updateCGPA = (cgpa: DeepPartial<BoundedValueInputModel>) => updateInput({ cgpa });
  const updateRank = (rank: DeepPartial<BoundedValueInputModel>) => updateInput({ rank });
  const updateLocationInput = (locality: string) => {
    const city = CityCodes.filter((k: string) => t(k) === locality);
    updateInput({
      location: {
        ...input.location,
        locality: city.length ? "" : locality,
        city: city.length ? city[0] as CityCode : CityCode.Other
      }
    });
  }
  const update = async () => {
    try {
      const result = await mutation(_updateMembership, {
        id: value.id,
        value: input,
        updatePerson: isOpenTitle,
        person: { title } as PersonInputModel
      });
      onUpdate?.(result.membership.commit)
      toggle?.();
    } catch (errors) {
      setErrors((errors as OperationErrors).me?.membership?.change?.value || {});
    }
  }
  const create = async () => {
    try {
      const result = await mutation(_addMembership, {
        type: view,
        value: input,
        updatePerson: isOpenTitle,
        person: { title } as PersonInputModel
      });
      onUpdate?.(result.me.membership.commit)
      toggle?.();
    } catch (errors) {
      setErrors((errors as OperationErrors).me?.membership?.change?.value || {});
    }
  }
  const updateOrganization = (id: string | undefined, name: string, org?: ReferenceDataModel) => {
    updateInput({ organizationId: id, organizationName: name, organizationNickname: org?.organization?.nickname });
    setTitleOrg(org?.organization?.nickname || org?.organization?.name || name);
  }

  const isEducational = view === MembershipType.Student;
  const isInternship = view === MembershipType.Intern;
  const isEmployee = view === MembershipType.Employee;
  const isNew = !value.id;
  const showCompensation = isEmployee;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        {isEducational ? isNew ? "Add education" : "Edit education"
          : isInternship ? isNew ? "Add internship experience" : "Edit internship experience"
            : isEmployee ? isNew ? "Add work experience" : "Edit work experience"
              : isNew ? "Add experience" : "Edit experience"}
      </ModalHeader>
      <ModalBody>
        <Form>
          <fieldset>
            {isEducational &&
              <FormGroup label="Institution" error={errors.organizationName}>
                <DatalistInput id="orgName" types={[ReferenceDataType.OrganizationName]} className={`form-control ${errors.title && "is-invalid"}`} placeholder="Enter institution name here" value={input.organizationName || ""} onUpdate={updateOrganization} />
              </FormGroup>
            }
            {!isEducational &&
              <FormGroup label="Organization" error={errors.organizationName}>
                <DatalistInput id="orgName" types={[ReferenceDataType.OrganizationName]} className={`form-control ${errors.title && "is-invalid"}`} placeholder="Enter organizaion name here" value={input.organizationName || ""} onUpdate={updateOrganization} />
              </FormGroup>
            }
            <FormGroup label='City' error={errors?.location?.locality}>
              <InputGroup>
                <Input value={input.location.city && input.location.city !== CityCode.Other ? t(input.location.city) : input.location.locality} placeholder="Select or enter the city here" onChange={e => updateLocationInput(e.currentTarget.value)} />
                <UncontrolledDropdown className="btn-input">
                  <DropdownToggle color="input" caret />
                  <DropdownMenu>
                    {CityCodes.sort(byKey(t)).filter(_ => _ && _ != CityCode.Other).map(_ =>
                      <DropdownItem key={_} onClick={() => updateLocationInput(t(_))}>{t(_)}</DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </InputGroup>
            </FormGroup>
            {isEducational &&
              <Row form>
                <Col>
                  <FormGroup label="Degree" error={errors.title}>
                    <Input type="text" className={`form-control ${errors.title && "is-invalid"}`} placeholder="Enter degree here" value={input.title} onChange={e => updateInput({ title: e.currentTarget.value })} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup label="Major" error={errors.focus}>
                    <Input type="text" className={`form-control ${errors.title && "is-invalid"}`} placeholder="Enter your major here" value={input.focus} onChange={e => updateInput({ focus: e.currentTarget.value })} />
                  </FormGroup>
                </Col>
              </Row>
            }
            {!isEducational &&
              <Row form>
                <Col>
                  <FormGroup label="Title" error={errors.title}>
                    <Input type="text" className={`form-control ${errors.title && "is-invalid"}`} placeholder="Enter your title here" value={input.title} onChange={e => updateInput({ title: e.currentTarget.value })} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup label="Focus" error={errors.focus}>
                    <Input type="text" className={`form-control ${errors.title && "is-invalid"}`} placeholder="Enter your focus area here" value={input.focus} onChange={e => updateInput({ focus: e.currentTarget.value })} />
                  </FormGroup>
                </Col>
              </Row>
            }
            <Row form>
              <Col>
                <FormGroup label='From' error={errors.startOn}>
                  <DatePicker className="form-control" selected={toLocalDate(input.startOn)} showMonthYearPicker dateFormat="MMMM yyyy" onChange={v => updateInput({ startOn: toUtcString(v) || undefined })} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup label='Until' error={errors.endOn}>
                  <DatePicker className="form-control" selected={toLocalDate(input.endOn)} showMonthYearPicker dateFormat="MMMM yyyy" onChange={v => updateInput({ endOn: toUtcString(v) || undefined })} />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <CustomInput id={uid} className="text-nowrap" type="switch" label="Update my headline" checked={isOpenTitle} onChange={toggleTitle} />
              <Collapse isOpen={isOpenTitle}>
                <Input className="mt-2" type="text" placeholder="Enter your headline here" value={title} onChange={e => setTitle(e.currentTarget.value)} />
              </Collapse>
            </FormGroup>
            {isEducational &&
              <Row form>
                <Col>
                  <FormGroup label='CGPA or percentage' error={errors.cgpa && errors.cgpa.value}>
                    <InputGroup>
                      <Input className="text-center" type="number" value={input.cgpa?.value || ""} onChange={e => updateCGPA({ value: parseFloat(e.currentTarget.value), maxValue: parseFloat(e.currentTarget.value) > 10 ? 100 : input.cgpa?.maxValue })} onBlur={() => input.cgpa?.value && updateCGPA({ value: parseFloat(input.cgpa.value.toFixed(2)) })} />
                      <InputGroupAddon addonType="append" >/</InputGroupAddon>
                      <Input className="text-center" type="number" value={input.cgpa?.maxValue || ""} onChange={e => updateCGPA({ maxValue: parseFloat(e.currentTarget.value) })} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup label='Class rank' error={errors.rank && errors.rank.value}>
                    <InputGroup>
                      <Input className="text-center" type="number" value={input.rank?.value || ""} onChange={e => updateRank({ value: parseFloat(e.currentTarget.value) })} onBlur={() => input.rank?.value && updateRank({ value: Math.round(input.rank.value) })} />
                      <InputGroupAddon addonType="append" >/</InputGroupAddon>
                      <Input className="text-center" type="number" value={input.rank?.maxValue || ""} onChange={e => updateRank({ maxValue: parseFloat(e.currentTarget.value) })} onBlur={() => input.rank?.maxValue && updateRank({ maxValue: Math.round(input.rank.maxValue) })} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            }
            {showCompensation &&
              <>
                <FormGroup label="Annual income" error={errors.income && errors.income.amount} help="We use this to avoid recommending opportunities to you below your past income level. We will not disclose this information to anyone without your explicit permission.">
                  <InputGroup>
                    <InputGroupButtonDropdown addonType="prepend" isOpen={isOpenCurrency} toggle={toggleCurrency}>
                      <DropdownToggle color="input" caret>{t(input.income.currency)}</DropdownToggle>
                      <DropdownMenu>
                        {Object.values(CurrencyCode).map((_, i) =>
                          <DropdownItem key={i} onClick={() => updateIncome({ currency: _ })}>{t(_)}</DropdownItem>
                        )}
                      </DropdownMenu>
                    </InputGroupButtonDropdown>
                    <Input value={input.income && input.income.amount && _formatNumber(input.income.amount) || ""} placeholder="Enter your approximate annual income before taxes here" onChange={e => updateIncome({ amount: parseInt(e.currentTarget.value.replace(/[^0-9]/g, "")) })} />
                  </InputGroup>
                </FormGroup>
              </>
            }
            <FormGroup label='Description' value={input.description} error={errors.description} showPreview>
              <Input type="textarea" rows={6} placeholder={isEducational ? "Describe your key achievements here" : "Describe your key achievements and responsibilities here"} value={input.description} onChange={e => updateInput({ description: e.currentTarget.value })} />
            </FormGroup>
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>Cancel</Button>
        {value.id && <Button color="primary" onClick={update}>Update</Button>}
        {!value.id && <Button color="primary" onClick={create}>Add</Button>}
      </ModalFooter>
    </Modal>
  );
}

export function ItemRow({ value, readonly, onUpdate }: {
  value: MembershipModel,
  readonly?: boolean,
  onUpdate?: (value: DeepPartial<MembershipModel>) => void
}) {
  const [, d] = useTranslation();
  const [i] = useIcons();
  const [isOpen, toggle] = useToggle();
  const [mutation] = useMutation<OperationResults>();
  const remove = async () => {
    const result = await mutation(_removeMembership, { id: value.id });
    onUpdate?.(setDelete(value));
  }
  const classNames = [
    "hover-container d-flex align-items-baseline",
    readonly ? "" : "cursor-pointer"
  ].join(" ");
  return (
    <>
      <ListGroupItem className={classNames} onClick={toggle}>
        <FontAwesomeIcon fixedWidth size='lg' className='mr-1' icon={i(value.type === MembershipType.Student ? OrganizationType.Public : OrganizationType.Private)} />
        <div>
          <span className="mr-3 d-block d-sm-inline">
            {value.organization && value.organization.name || value.organizationName}
          </span>
          <span className="small text-muted mr-3 d-block d-sm-inline">
            {value.title}
            {(value.startOn || value.endOn) &&
              <span className="ml-1">({d(value.startOn, DateDisplayFormat.YearMonthRange, value.endOn)})</span>
            }
          </span>
        </div>
        {!readonly && <Button className="d-none d-sm-block hover-visible" color="link" size="sm" onClick={preventDefault(toggle)}>Edit</Button>}
        {!readonly && <Button className="d-none d-sm-block ml-auto hover-visible" color="link" size="sm" onClick={preventDefault(remove)}>Remove</Button>}
        {!readonly && <Button className="d-sm-none ml-auto text-muted" color="link" size="sm" onClick={preventDefault(remove)}><FontAwesomeIcon icon={["far", "trash-alt"]} /></Button>}
      </ListGroupItem>
      {!readonly && <InputModal view={value.type} value={value} isOpen={isOpen} toggle={toggle} onUpdate={onUpdate} />}
    </>
  );
}

export default ({ className, values, onUpdate }: {
  className?: string,
  values: ReadonlyArray<MembershipModel>,
  onUpdate?: (value: DeepPartial<ReadonlyArray<MembershipModel>>) => void
}) => {
  const [inputView, setInputView] = useState<MembershipType>(MembershipType.Null);
  return (
    <>
      <Card className={className}>
        <CardHeader className="bg-white d-flex align-baseline">
          <h3>Experience</h3>
          <UncontrolledDropdown className="ml-auto">
            <DropdownToggle color="link" caret>Add</DropdownToggle>
            <DropdownMenu modifiers={preventOverflow}>
              <DropdownItem onClick={() => setInputView(MembershipType.Student)}>Education</DropdownItem>
              <DropdownItem onClick={() => setInputView(MembershipType.Intern)}>Internship experience</DropdownItem>
              <DropdownItem onClick={() => setInputView(MembershipType.Employee)}>Work experience</DropdownItem>
              <DropdownItem className="mt-3" header>Other experience</DropdownItem>
              <DropdownItem onClick={() => setInputView(MembershipType.Volunteer)}>Volunteer experience</DropdownItem>
              <DropdownItem onClick={() => setInputView(MembershipType.Volunteer)}>Leadership experience</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardHeader>
        <ListGroup flush>
          {values.map((v, i) => <ItemRow key={i} value={v} onUpdate={value => onUpdate?.([value])} />)}
          {values.length === 0 && <ListGroupItem className="text-muted">Use experience to highlight your past accomplishments.</ListGroupItem>}
        </ListGroup>
      </Card>
      <InputModal isOpen={inputView !== MembershipType.Null} toggle={() => setInputView(MembershipType.Null)} view={inputView} value={{} as MembershipModel} onUpdate={value => onUpdate?.([value])} />
    </>
  );
}
