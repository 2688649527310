import React, { useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, CardHeader, Col, CustomInput, Row } from "reactstrap";
import TaskCard, { Fragment as TaskFragment, InputCard as TaskInputCard, isLegalResearchBackground, isLegalResearchConclusion, isLegalResearchPrecedent, isLegalResearchRephrasing, isLegalResearchStatute, IsValid } from "../../cards/TaskCard";
import { ProgressStep, ProgressTracker } from "../../components/ProgressTracker";
import { DeepPartial, setDelete, useMutation } from "../../hooks/ApiProvider";
import { useToggle } from "../../hooks/CommonHooks";
import { useConfirmation } from "../../hooks/ConfirmationProvider";
import { preventDefault } from "../../hooks/NavigationHook";
import { useWindowState } from "../../hooks/WindowStateProvider";
import StaticContentModal, { Policies } from "../../modals/StaticContentModal";
import { JobModel, LegalResearchModel, TaskModel, TaskType, WorkItemModel, WorkItemType } from "../../types/api-graph-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faInfoCircle);

export const Fragment = `
  expiresOn
`;

const _createTask = `mutation createTask($jobId:ID! $type:TaskType) {
  job(id:$jobId) {
    task: addTask(type:$type) {
      commit { ${TaskFragment} }
    } 
  } 
}`;

const _removeTask = `mutation removeTask($id:ID!) {
  task(id:$id) { remove commit { id } }
}`;

interface OperationResults {
  job: {
    task: {
      commit: TaskModel
    }
  }
  task: {
    commit: TaskModel
  }
};

export function isLegalResearch(value: JobModel): value is JobModel & { workitem: WorkItemModel & LegalResearchModel } {
  return value.workitem.details.type === WorkItemType.LegalResearch;
}

export default ({ value, disabled, onUpdate, onSubmit }: {
  value: JobModel,
  disabled?: boolean,
  onUpdate?: (value: DeepPartial<JobModel>) => void,
  onSubmit: () => void
}) => {
  const confirm = useConfirmation();
  const [size] = useWindowState();
  const tasks = value.tasks || [];
  const [mutation] = useMutation<OperationResults>();
  const [isTermsAccepted, toggleTermsAccepted] = useToggle();
  const [isOpenTerms, toggleTerms] = useToggle();
  const rephrasing = tasks.filter(isLegalResearchRephrasing);
  const backgrounds = tasks.filter(isLegalResearchBackground);
  const statutes = tasks.filter(isLegalResearchStatute);
  const precedents = tasks.filter(isLegalResearchPrecedent);
  const conclusion = tasks.filter(isLegalResearchConclusion);

  const isCompleteInstructions = !!isTermsAccepted;
  const isCompleteRephrasing = isCompleteInstructions && rephrasing.length == 1 && rephrasing.every(IsValid);
  const isCompleteBackground = isCompleteRephrasing && backgrounds.length > 0 && backgrounds.every(IsValid);
  const isCompleteStatute = isCompleteBackground && statutes.length > 0 && statutes.every(IsValid);
  const isCompletePrecedent = isCompleteStatute && precedents.length > 0 && precedents.every(IsValid);
  const isCompleteConclusion = isCompletePrecedent && conclusion.length == 1 && conclusion.every(IsValid);

  const [step, setStep] = useState(0);
  const step0 = () => setStep(0);
  const step1 = () => setStep(1);
  const step2 = () => setStep(2);
  const step3 = () => setStep(3);
  const step4 = () => setStep(4);
  const step5 = () => setStep(5);
  const step6 = () => setStep(6);

  const updateTasks = (tasks: DeepPartial<TaskModel[]>) => onUpdate?.({ tasks });
  const updateTask = (value: TaskModel) => updateTasks([value]);
  const addTask = async (type: TaskType) => {
    const result = await mutation(_createTask, { jobId: value.id, type });
    updateTask(result.job.task.commit);
  }
  const removeTask = async (value: TaskModel) => {
    await mutation(_removeTask, { id: value.id });
    updateTasks([setDelete(value)]);
  }
  const addRephrasing = async () => addTask(TaskType.LegalResearchRephrasing);
  const addBackground = async () => addTask(TaskType.LegalResearchBackground);
  const addStatute = async () => addTask(TaskType.LegalResearchStatute);
  const addPrecedent = async () => addTask(TaskType.LegalResearchPrecedent);
  const addConclusion = async () => addTask(TaskType.LegalResearchConclusion);
  const isWide = size === "lg" || size === "xl";

  useEffect(() => {
    step === 1 && rephrasing.length === 0 && addRephrasing();
    step === 2 && backgrounds.length === 0 && addBackground();
    step === 3 && statutes.length === 0 && addStatute();
    step === 4 && precedents.length === 0 && addPrecedent();
    step === 5 && conclusion.length === 0 && addConclusion();
  }, [step])

  return (
    <Row>
      <Col className="mt-3" lg={3}>
        <Card>
          <CardBody>
            <ProgressTracker vertical={isWide} center={!isWide}>
              <ProgressStep onClick={step0} active={step == 0} complete={isCompleteInstructions || disabled} label="Instructions" />
              <ProgressStep disabled={!isCompleteInstructions || disabled} onClick={step1} active={step == 1} complete={isCompleteRephrasing} label="Issue" />
              <ProgressStep disabled={!isCompleteRephrasing || disabled} onClick={step2} active={step == 2} complete={isCompleteBackground} label="Background" />
              <ProgressStep disabled={!isCompleteBackground || disabled} onClick={step3} active={step == 3} complete={isCompleteStatute} label="Statute" />
              <ProgressStep disabled={!isCompleteStatute || disabled} onClick={step4} active={step == 4} complete={isCompletePrecedent} label="Precedent" />
              <ProgressStep disabled={!isCompletePrecedent || disabled} onClick={step5} active={step == 5} complete={isCompleteConclusion} label="Conclusion" />
              <ProgressStep disabled={!isCompleteConclusion || disabled} onClick={step6} active={step == 6} label="Review" />
            </ProgressTracker>
          </CardBody>
        </Card>
      </Col>
      <Col lg={9}>
        {step === 0 &&
          <Card className="mt-3">
            <CardHeader className="bg-white" tag="h3">Instructions</CardHeader>
            <CardBody>
              <ul>
                <li>This assessment is designed to take around one to two hours. You may complete it at your own leisure.</li>
                <li>You must complete all sections before you can submit your response.</li>
                <li>You will receive the next assessment (if any) after you complete this assessment.</li>
                <li>If you are stuck on this assessment, you may skip it after waiting for one day.</li>
              </ul>
              <CustomInput id="terms" type="checkbox" disabled={disabled} onChange={toggleTermsAccepted} checked={isTermsAccepted} label={<>
                I accept the <a href="#" onClick={preventDefault(toggleTerms)}>Terms of Use</a>
              </>} />
            </CardBody>
            <CardBody className="card-buttons-right">
              <Button color="primary" disabled={!isCompleteInstructions || disabled} onClick={step1}>Start</Button>
            </CardBody>
            <StaticContentModal className="w-max-75" name={Policies.TermsOfUse} isOpen={isOpenTerms} showClose toggle={toggleTerms} onAccept={isTermsAccepted ? toggleTerms : toggleTermsAccepted} onDecline={isTermsAccepted ? toggleTermsAccepted : toggleTerms} />
          </Card>
        }
        {step === 1 && rephrasing.map((_, i) => <TaskInputCard key={i} value={_} disabled={disabled} onUpdate={updateTask} onRemove={rephrasing.length > 1 ? removeTask : undefined} onNext={step2} />)}
        {step === 2 && backgrounds.map((_, i) => <TaskInputCard key={i} value={_} disabled={disabled} onUpdate={updateTask} onRemove={backgrounds.length != 1 ? removeTask : undefined} onAdd={_.id && i === backgrounds.length - 1 && backgrounds.length < 3 ? addBackground : undefined} onNext={i === backgrounds.length - 1 ? step3 : undefined} />)}
        {step === 3 && statutes.map((_, i) => <TaskInputCard key={i} value={_} disabled={disabled} onUpdate={updateTask} onRemove={statutes.length != 1 ? removeTask : undefined} onAdd={_.id && i === statutes.length - 1 && statutes.length < 3 ? addStatute : undefined} onNext={i === statutes.length - 1 ? step4 : undefined} />)}
        {step === 4 && precedents.map((_, i) => <TaskInputCard key={i} value={_} disabled={disabled} showAttachments onUpdate={updateTask} onRemove={precedents.length != 1 ? removeTask : undefined} onAdd={_.id && i === precedents.length - 1 && precedents.length < 3 ? addPrecedent : undefined} onNext={i === precedents.length - 1 ? step5 : undefined} />)}
        {step === 5 && conclusion.map((_, i) => <TaskInputCard key={i} value={_} disabled={disabled} onUpdate={updateTask} onRemove={rephrasing.length > 1 ? removeTask : undefined} onNext={step6} />)}
        {step === 6 &&
          <>
            <Alert className="mt-3" color="primary">
              <FontAwesomeIcon icon="info-circle" />
              {" "}
              <strong>Almost done!</strong>
              {" "}
              Please review the draft below and make any edits as needed. Once you are satisfied, click Submit to
              complete your submission. You will not be able to make any further edits once you complete your submission.
            </Alert>
            {rephrasing.map((_, i) => <TaskCard key={i} className="mt-3" value={_} showHeader onClick={step1} />)}
            {backgrounds.map((_, i) => <TaskCard key={i} className="mt-3" value={_} showHeader onClick={step2} />)}
            {statutes.map((_, i) => <TaskCard key={i} className="mt-3" value={_} showHeader onClick={step3} />)}
            {precedents.map((_, i) => <TaskCard key={i} className="mt-3" value={_} showHeader showAttachments onClick={step4} />)}
            {conclusion.map((_, i) => <TaskCard key={i} className="mt-3" value={_} showHeader onClick={step5} />)}
            <Button className="mt-3" color="primary" disabled={disabled} onClick={confirm(onSubmit, "You are about to complete your submission. You will not be able to make any further edits. You can confirm, or cancel to continue editing.")}>Submit</Button>
          </>
        }
      </Col>
    </Row>
  );
}
