import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import { QRCode } from "react-qr-svg";
import { Button, Card, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Popover, PopoverBody, Row, UncontrolledDropdown } from "reactstrap";
import { preventOverflow } from "../../components/CardStatus";
import FormGroup, { between, isEmail, isPhone, len } from "../../components/FormGroup";
import ValidatingInput from "../../components/Input";
import MutationButton from "../../components/MutationButton";
import PhoneNumberSpan from "../../components/PhoneNumberSpan";
import Show from "../../components/Show";
import { ApiErrors, DeepPartial, nextBase32Nonce, nextNonce, nextSequence, setDelete, useMutation, useReadyState } from "../../hooks/ApiProvider";
import { useToggle } from "../../hooks/CommonHooks";
import { useIcons } from "../../hooks/IconizeHook";
import { doNothing, preventDefault } from "../../hooks/NavigationHook";
import { useTranslation } from "../../hooks/TranslationProvider";
import { CityCode, CountryCode, ErrorType, ExternalAccountInputModel, ExternalAccountModel, ExternalAccountOption, ExternalAccountType, LocationModel, PersonModel } from "../../types/api-graph-types";
import { PaymentTargetType } from "../../types/common-types";
import { TooltipContext, TooltipTarget, UncontrolledTooltip } from "../../components/Tooltip";

library.add(faTrashAlt, faEdit);

export const Fragment = `
  key
  type
  value
  uri
  externalEmail
  options
  isPrimary
  isVerified
  verificationError
  verificationHelp
  verificationRequestedOn
`;

const _updateMyContactInfo = `mutation updateMyContactInfo($key:String $value:ExternalAccountInputModel) {
  me {
    changeContactInfo(key:$key value:$value)
    commit { email phone contactInformation { ${Fragment} } }
  }
}`

const _removeMyContactInfo = `mutation removeMyContactInfo($key:String) {
  me {
    removeContactInfo(key:$key)
    commit { contactInformation { ${Fragment} } }
  }
}`

interface OperationResults {
  me: {
    commit: PersonModel
  }
}

interface OperationErrors {
  me: {
    changeContactInfo: ApiErrors<{ value: ExternalAccountInputModel }>
  }
}

const _newAddress: LocationModel = {
  country: CountryCode.In,
  postalCode: "",
  region: "",
  locality: "",
  city: CityCode.Other,
  neighborhood: "",
  streetAddress: ""
}

interface PaymentTargetModel {
  readonly type: PaymentTargetType;
  readonly routingCode: string;
  readonly accountNumber: string;
  readonly receiverName: string;
}

const _newPaymentTarget: PaymentTargetModel = {
  type: PaymentTargetType.Null,
  routingCode: "",
  accountNumber: "",
  receiverName: ""
};

const _toContactInformationInputModel = (value: Partial<ExternalAccountModel>): ExternalAccountInputModel => ({
  type: value.type || ExternalAccountType.Null,
  value: value.value || "",
  uri: value.uri || "",
  options: value.options || [],
  isPrimary: value.isPrimary || false,
  verificationCode: ""
});

const _e = encodeURIComponent;
const _d = decodeURIComponent;
const _contactInfoTypes = [
  ExternalAccountType.Email,
  ExternalAccountType.Phone,
  ExternalAccountType.Mail,
];
const _connectedAccountTypes = [
  ExternalAccountType.Amazon,
  ExternalAccountType.Facebook,
  ExternalAccountType.Google,
  ExternalAccountType.Instagram,
  ExternalAccountType.LinkedIn,
  ExternalAccountType.Microsoft,
  ExternalAccountType.QuickBooks,
  ExternalAccountType.Twitter,
  ExternalAccountType.Authenticator,
  ExternalAccountType.ClientApp
];
const _paymentAccountTypes = [
  ExternalAccountType.Payment
];

const _toLocation = (value: string): LocationModel => {
  const [, country, postalCode, region, locality, city, neighborhood, streetAddress] = value.split(":").map(_d);
  return {
    country: country as CountryCode || CountryCode.Null,
    postalCode: postalCode || "",
    region: region || "",
    locality: locality || "",
    city: city as CityCode || CityCode.Null,
    neighborhood: neighborhood || "",
    streetAddress: streetAddress || ""
  };
}

const _fromLocation = (value: LocationModel): string => (
  `addr:${_e(value.country)}:${_e(value.postalCode)}:${_e(value.region)}:${_e(value.locality)}:${_e(value.city)}:${_e(value.neighborhood)}:${_e(value.streetAddress)}`
);

const _fromPaymentTarget = (value: PaymentTargetModel): string => {
  return value.type === PaymentTargetType.Ifsc ? `payto://ifsc/${_e(value.routingCode)}/${_e(value.accountNumber)}?receiver-name=${_e(value.receiverName)}`
    : value.type === PaymentTargetType.Upi ? `payto://upi/${_e(value.accountNumber)}?receiver-name=${_e(value.receiverName)}`
      : `payto://void?receiver-name=${_e(value.receiverName)}`;
}

const toIFSCPaymentTarget = ({ path, params }: { path: string, params: QueryString.ParsedQuery }): PaymentTargetModel => {
  const segments = path.split("/").map(_d);
  const receiver = params["receiver-name"] as string || "";
  return {
    type: PaymentTargetType.Ifsc,
    routingCode: segments[1] || "",
    accountNumber: segments[2] || "",
    receiverName: receiver
  };
}

const toUPIPaymentTarget = ({ path, params }: { path: string, params: QueryString.ParsedQuery }): PaymentTargetModel => {
  const segments = path.split("/").map(_d);
  const receiver = params["receiver-name"] as string || "";
  return {
    type: PaymentTargetType.Upi,
    routingCode: "",
    accountNumber: segments[1] || "",
    receiverName: receiver
  };
}

const toVoidPaymentTarget = ({ params }: { params: QueryString.ParsedQuery }): PaymentTargetModel => {
  const receiver = params["receiver-name"] as string || "";
  return {
    type: PaymentTargetType.Null,
    routingCode: "",
    accountNumber: "",
    receiverName: receiver
  };
}

const _uriRegex = /^([a-z]+):\/\/([^/?]+)(\/[^?]*)?(\?[^#]*)?$/;
const _toPaymentTarget = (value: string): PaymentTargetModel => {
  const [_, protocol, host, path, search] = value.match(_uriRegex) || [];
  const params = QueryString.parse(search || "", { decode: true, arrayFormat: "none" });
  const val = { path, params };
  return protocol !== "payto" ? _newPaymentTarget
    : host == "ifsc" ? toIFSCPaymentTarget(val)
      : host == "upi" ? toUPIPaymentTarget(val)
        : toVoidPaymentTarget(val);
}

function hasOptions(value: ExternalAccountType) {
  return value === ExternalAccountType.Phone
    || value === ExternalAccountType.Email
    || value === ExternalAccountType.Mail
    || value === ExternalAccountType.Facebook;
}

function _isValidPaymentTarget(value: PaymentTargetModel) {
  if (value.type === PaymentTargetType.Ifsc) {
    return len(value.routingCode) === 11
      && value.routingCode[4] === "0"
      && between(len(value.accountNumber), 9, 18);
  } else if (value.type === PaymentTargetType.Upi) {
    return len(value.accountNumber) > 3 && value.accountNumber.split("@").length == 2;
  } else {
    return true;
  }
}

function _isValid(value: ExternalAccountInputModel) {
  if (value.type === ExternalAccountType.Email) {
    return isEmail(value.value);
  } else if (value.type === ExternalAccountType.Phone) {
    return isPhone(value.value);
  } else if (value.type === ExternalAccountType.Mail) {
    const location = _toLocation(value.uri);
    return (location.city !== CityCode.Null || between(len(location.locality), 3, 70))
      && between(len(location.region), 2, 70)
      && between(len(location.postalCode), 2, 14);
  } else if (value.type === ExternalAccountType.Payment) {
    const paymentTarget = _toPaymentTarget(value.uri);
    return len(paymentTarget.receiverName) > 0 && _isValidPaymentTarget(paymentTarget);
  } else if (value.type == ExternalAccountType.Authenticator) {
    return len(value.verificationCode) == 6;
  } else {
    return true;
  }
}


function InputModal({ isOpen, value, onUpdate, toggle }: {
  isOpen?: boolean,
  value: Partial<ExternalAccountModel>,
  onUpdate?: (value: DeepPartial<ExternalAccountModel>, values: DeepPartial<ReadonlyArray<ExternalAccountModel>>) => void,
  toggle?: () => void
}) {
  const [t] = useTranslation();
  const [key] = useState(value.key || nextNonce());
  const [readyState, setReadyState] = useReadyState();
  const [input, setInput] = useState(_toContactInformationInputModel(value || {}));
  const [errors, setErrors] = useState<ApiErrors<ExternalAccountInputModel>>({});
  const [mutation] = useMutation<OperationResults>();
  useEffect(() => setInput(_toContactInformationInputModel(value || {})), [value]);
  const change = async () => {
    try {
      const result = await mutation(_updateMyContactInfo, { key, value: input }, { setReadyState });
      const resultValue = result.me.commit.contactInformation.filter(_ => _.key === key)[0];
      onUpdate?.(resultValue, result.me.commit.contactInformation);
      if (input.type === ExternalAccountType.Authenticator && !resultValue.isVerified) {
        setErrors({ verificationCode: ErrorType.InvalidVerificationCode });
        return;
      }
      toggle?.();
    } catch (errors) {
      setErrors((errors as OperationErrors)?.me?.changeContactInfo.value || {});
    }
  }
  const updateInput = (props: Partial<ExternalAccountInputModel>) => setInput({ ...input, ...props });
  const toggleOption = (option: ExternalAccountOption) => {
    const prevOptions = input.options || [];
    const options = prevOptions.includes(option)
      ? prevOptions.filter(o => o !== option)
      : prevOptions.concat([option]);
    updateInput({ options });
  }

  const options = input.options || [];
  const address = input.uri?.startsWith("addr:") && _toLocation(input.uri) || _newAddress;
  const updateAddress = (partial: Partial<LocationModel>) => updateInput({ uri: _fromLocation({ ...address, ...partial }) });
  const paymentTarget = input.uri?.startsWith("payto:") && _toPaymentTarget(input.uri) || _newPaymentTarget;
  const updatePaymentTarget = (partial: Partial<PaymentTargetModel>) => updateInput({ uri: _fromPaymentTarget({ ...paymentTarget, ...partial }) });
  const canEdit = !value.isVerified;
  const title = value.externalEmail?.startsWith("mailto:") ? value.externalEmail.substring(7) : value.value;
  const isContactInfo = _contactInfoTypes.includes(input.type);
  const isOnlineAccount = _connectedAccountTypes.includes(input.type);
  const isPaymentAccount = _paymentAccountTypes.includes(input.type);
  const isValid = _isValid(input);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {isContactInfo && <ModalHeader>Contact information</ModalHeader>}
      {isOnlineAccount && <ModalHeader>{t(input.type)} {title}</ModalHeader>}
      {isPaymentAccount && <ModalHeader>Payment account</ModalHeader>}
      <ModalBody>
        <Form>
          <fieldset>
            {input.type === ExternalAccountType.Email &&
              <FormGroup label='Email' error={errors.value}>
                <Input type="email" disabled={!canEdit} placeholder="Enter your email address here" value={input.value} onChange={e => updateInput({ value: e.currentTarget.value, uri: undefined })} />
              </FormGroup>
            }
            {input.type === ExternalAccountType.Phone &&
              <FormGroup label='Phone' error={errors.value}>
                <Input type="tel" disabled={!canEdit} placeholder="Enter your phone number here" value={input.value} onChange={e => updateInput({ value: e.currentTarget.value, uri: undefined })} />
              </FormGroup>
            }
            {input.type === ExternalAccountType.Mail &&
              <>
                <FormGroup label='Address' error={errors.value}>
                  <Input type="textarea" disabled={!canEdit} placeholder="Enter your street adderess here" value={address.streetAddress} onChange={e => updateAddress({ streetAddress: e.currentTarget.value })} />
                </FormGroup>
                <FormGroup label='Neighborhood' error={errors.value}>
                  <Input type="text" disabled={!canEdit} placeholder="Optionally enter landmark or neighborhood here" value={address.neighborhood} onChange={e => updateAddress({ neighborhood: e.currentTarget.value })} />
                </FormGroup>
                <Row form>
                  <Col>
                    <FormGroup label='City' required error={errors.value}>
                      <Input type="text" disabled={!canEdit} value={address.locality} onChange={e => updateAddress({ locality: e.currentTarget.value })} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup label='State' required error={errors.value}>
                      <Input type="text" disabled={!canEdit} value={address.region} onChange={e => updateAddress({ region: e.currentTarget.value })} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup label='Postal Code' required error={errors.value}>
                      <Input type="text" disabled={!canEdit} value={address.postalCode} onChange={e => updateAddress({ postalCode: e.currentTarget.value })} />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            }
            {input.type === ExternalAccountType.Payment &&
              <>
                <FormGroup label='Name' required value={paymentTarget.receiverName} error={errors.value}>
                  <Input type="text" disabled={!canEdit} placeholder="Enter the account holder name here" value={paymentTarget.receiverName} onChange={e => updatePaymentTarget({ receiverName: e.currentTarget.value })} />
                </FormGroup>
                {false &&
                  <FormGroup label='Type' error={errors.value}>
                    <select className="custom-select" disabled={!canEdit} value={paymentTarget.type} onChange={e => updatePaymentTarget({ ..._newPaymentTarget, receiverName: paymentTarget.receiverName, type: e.currentTarget.value as PaymentTargetType })}>
                      {[PaymentTargetType.Null, PaymentTargetType.Ifsc, PaymentTargetType.Upi].map((_, i) => <option key={i} value={_}>{t(_)}</option>)}
                    </select>
                  </FormGroup>
                }
                {paymentTarget.type === PaymentTargetType.Ifsc &&
                  <Row form>
                    <Col sm={4}>
                      <FormGroup label='IFSC code' required alphanumeric hideMessage value={paymentTarget.routingCode} minLength={11} maxLength={11} error={errors.value}>
                        <ValidatingInput type="text" spellCheck="false" disabled={!canEdit} placeholder="Enter IFSC code here" onUpdate={routingCode => updatePaymentTarget({ routingCode: routingCode.toUpperCase() })} />
                      </FormGroup>
                    </Col>
                    <Col sm={8}>
                      <FormGroup label='Account number' required hideMessage minLength={9} maxLength={18} value={paymentTarget.accountNumber} number error={errors.value}>
                        <ValidatingInput type="text" spellCheck="false" disabled={!canEdit} placeholder="Enter the account number here" onUpdate={accountNumber => updatePaymentTarget({ accountNumber })} />
                      </FormGroup>
                    </Col>
                  </Row>
                }
                {paymentTarget.type === PaymentTargetType.Upi &&
                  <FormGroup label='UPI address' required hideMessage value={paymentTarget.accountNumber} error={errors.value} help="Open your UPI app such as GooglePay, Paytm, PhonePe, etc. to find your UPI address.">
                    <ValidatingInput type="text" spellCheck="false" disabled={!canEdit} placeholder="Enter the UPI address here" onUpdate={accountNumber => updatePaymentTarget({ accountNumber })} />
                  </FormGroup>
                }
              </>
            }
            {input.type === ExternalAccountType.Authenticator &&
              <>
                <div className="w-75 mx-auto mt-3">
                  <QRCode value={input.uri} />
                </div>
                <FormGroup className="mt-3" label='Authenticator code' required maxLength={6} hideMessage value={input.verificationCode} error={errors.verificationCode} help="Open your authenticator app such as Google Authenticator, Microsoft Authenticator or Authy, and add an account by scanning the QR code above. Enter the code shown in your authenticator app to verify.">
                  <ValidatingInput type="text" pattern="[0-9]*" inputMode="numeric" spellCheck="false" disabled={!canEdit} placeholder="Enter the authenticator code here" onUpdate={verificationCode => updateInput({ verificationCode })} />
                </FormGroup>
              </>
            }
            <FormGroup>
              {input.type === ExternalAccountType.Email &&
                <div className="custom-control custom-switch" onClick={() => toggleOption(ExternalAccountOption.EmailRecommendations)}>
                  <input type="radio" className="custom-control-input" readOnly checked={options.includes(ExternalAccountOption.EmailRecommendations)} />
                  <label className="custom-control-label">Send me recommended opportunities</label>
                </div>
              }
              {input.type === ExternalAccountType.Phone &&
                <div className="custom-control custom-switch" onClick={() => toggleOption(ExternalAccountOption.PhoneRecommendations)}>
                  <input type="radio" className="custom-control-input" readOnly checked={options.includes(ExternalAccountOption.PhoneRecommendations)} />
                  <label className="custom-control-label">Send me recommended opportunities</label>
                </div>
              }
              {input.type === ExternalAccountType.Phone &&
                <div className="custom-control custom-switch" onClick={() => toggleOption(ExternalAccountOption.PhoneSms)}>
                  <input type="radio" className="custom-control-input" readOnly checked={options.includes(ExternalAccountOption.PhoneSms)} />
                  <label className="custom-control-label">SMS</label>
                </div>
              }
              {input.type === ExternalAccountType.Phone &&
                <div className="custom-control custom-switch" onClick={() => toggleOption(ExternalAccountOption.PhoneWhatsApp)}>
                  <input type="radio" className="custom-control-input" readOnly checked={options.includes(ExternalAccountOption.PhoneWhatsApp)} />
                  <label className="custom-control-label">WhatsApp</label>
                </div>
              }
              {input.type === ExternalAccountType.Facebook &&
                <div className="custom-control custom-switch" onClick={() => toggleOption(ExternalAccountOption.FacebookMessenger)}>
                  <input type="radio" className="custom-control-input" readOnly checked={options.includes(ExternalAccountOption.FacebookMessenger)} />
                  <label className="custom-control-label">Facebook Messenger</label>
                </div>
              }
              {input.type === ExternalAccountType.Mail &&
                <div className="custom-control custom-switch" onClick={() => toggleOption(ExternalAccountOption.MailRecommendations)}>
                  <input type="radio" className="custom-control-input" readOnly checked={options.includes(ExternalAccountOption.MailRecommendations)} />
                  <label className="custom-control-label">Send me recommended opportunities</label>
                </div>
              }
            </FormGroup>
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>Cancel</Button>
        {!value.key && <MutationButton readyState={readyState} color="primary" disabled={!isValid} onClick={change}>Add</MutationButton>}
        {value.key && <MutationButton readyState={readyState} color="primary" disabled={!isValid} onClick={change}>Update</MutationButton>}
      </ModalFooter>
    </Modal>
  );
}

const _showOptions = new Set<ExternalAccountOption>([
  ExternalAccountOption.FacebookMessenger,
  ExternalAccountOption.PhoneSms,
  ExternalAccountOption.PhoneWhatsApp
]);

function ItemRow({ value, hidePrimary, readonly, onUpdate }: {
  value: ExternalAccountModel,
  hidePrimary?: boolean,
  readonly?: boolean,
  onUpdate?: (value: DeepPartial<ExternalAccountModel>, values: DeepPartial<ReadonlyArray<ExternalAccountModel>>) => void,
}) {
  const [t] = useTranslation();
  const [i] = useIcons();
  const [isOpen, toggle] = useToggle();
  const [isOpenHelp, toggleHelp] = useToggle();
  const [readyState, setReadyState] = useReadyState();
  const [uid] = useState(nextSequence());
  const [mutation] = useMutation<OperationResults>();
  const [otp, setOtp] = useState("");
  const change = async (input: Partial<ExternalAccountInputModel>) => {
    try {
      const result = await mutation(_updateMyContactInfo, { key: value.key, value: input }, { setReadyState });
      onUpdate?.(result.me.commit.contactInformation.filter(_ => _.key === value.key)[0], result.me.commit.contactInformation);
    } catch (errors) {
    }
  }
  const remove = async () => {
    const result = await mutation(_removeMyContactInfo, { key: value.key });
    onUpdate?.(setDelete(value), result.me.commit.contactInformation);
  }
  const verify = (otp?: string) => async () => {
    await change({ verificationCode: otp });
    setOtp("");
  }
  const isVerificationRequested = !!value.verificationRequestedOn;

  return (
    <ListGroupItem className="hover-container d-flex flex-column flex-sm-row align-items-sm-center hover-container cursor-pointer" onClick={toggle}>
      <div className="w-100 w-sm-auto d-flex align-items-center">
        <FontAwesomeIcon fixedWidth size='lg' className='mr-1' icon={i(value.type)} />
        {value.type === ExternalAccountType.Phone ? <PhoneNumberSpan className="mr-2" value={value.value} />
          : value.externalEmail?.startsWith("mailto:") ? <span className="mr-2">{value.externalEmail.split(':')[1]}</span>
            : value.value?.trim() ? <span className="mr-2">{value.value}</span>
              : <span className="mr-2">{t(value.type)}</span>}
        {value.options.filter(o => _showOptions.has(o)).map((o, j) => <FontAwesomeIcon key={j} className='mx-2' icon={i(o)} />)}
        {value.isPrimary && !hidePrimary
          ? <span className='px-2 text-muted small'>Primary</span>
          : !readonly && value.isVerified && !hidePrimary ? <Button size="sm" color="link" className='hover-visible' onClick={preventDefault(() => change({ isPrimary: true }))}>Primary</Button>
            : null
        }
        {!readonly && !value.isPrimary && <Button className="d-sm-none ml-auto text-muted" color="link" size="sm" onClick={preventDefault(remove)}><FontAwesomeIcon icon={["far", "trash-alt"]} /></Button>}
      </div>
      {!readonly && !value.isVerified && (value.type == ExternalAccountType.Email || value.type === ExternalAccountType.Phone) &&
        <Form inline className='display-sm-inline' onClick={doNothing}>
          <Input type="text" inputMode="numeric" pattern="[0-9]*" placeholder="Enter code" value={otp} onChange={e => setOtp(e.target.value.replace(/[^0-9]/, "").substring(0, 6))} bsSize="sm" style={{ width: "10rem" }} />
          <MutationButton readyState={readyState} color="primary" disabled={!otp || otp.length !== 6} onClick={verify(otp)} size="sm">Verify</MutationButton>
          <Button color='link' onClick={verify()} size="sm">Resend</Button>
        </Form>
      }
      {!readonly && !value.isVerified && value.type == ExternalAccountType.Payment &&
        <Form inline className='display-sm-inline' onClick={doNothing}>
          {!isVerificationRequested && <Button color='link' onClick={verify()} size="sm">Verify</Button>}
          {isVerificationRequested && <Input type="text" placeholder="Enter OTP or payment reference" value={otp} onChange={e => setOtp(e.target.value)} bsSize="sm" style={{ width: "14rem" }} />}
          {isVerificationRequested && <MutationButton readyState={readyState} color="primary" disabled={!otp} onClick={verify(otp)} size="sm">Verify</MutationButton>}
          {isVerificationRequested &&
            <TooltipContext>
              <TooltipTarget>
                <Button id={`${uid}.0`} color="link" size="sm" onClick={toggleHelp}>Help</Button>
              </TooltipTarget>
              <UncontrolledTooltip placement="bottom">
                Check your account statement for a small verification payment made to this account.
                Enter the payment reference number, or the OTP from the transaction remarks to verify.
              </UncontrolledTooltip>
            </TooltipContext>
          }
          {value.verificationError && <span className="ml-2 small text-muted">{t(value.verificationError)}</span>}
          {value.verificationHelp && <span className="ml-2 small text-muted">{value.verificationHelp}</span>}
        </Form>
      }
      {hasOptions(value.type) && <Button className="d-none d-sm-block hover-visible" color="link" size="sm" onClick={preventDefault(toggle)}>Edit</Button>}
      {!readonly && !value.isPrimary && <Button className="d-none d-sm-block ml-auto hover-visible" color="link" size="sm" onClick={preventDefault(remove)}>Remove</Button>}
      <InputModal {...{ isOpen, toggle, value, onUpdate }} />
    </ListGroupItem>
  );
}

export default ({ className, values, onUpdate }: {
  className?: string,
  values: ReadonlyArray<ExternalAccountModel>,
  onUpdate: (values: DeepPartial<ReadonlyArray<ExternalAccountModel>>) => void
}) => {
  const [externalAccount, setExternalAccount] = useState<Partial<ExternalAccountModel> | undefined>(undefined);
  const connectedAccounts = values.filter(v => _connectedAccountTypes.includes(v.type));
  const paymentAccounts = values.filter(v => _paymentAccountTypes.includes(v.type));
  const authenticatorAccounts = values.filter(v => v.type === ExternalAccountType.Authenticator);
  const primaryEmail = values.filter(_ => _.isVerified && _.isPrimary && _.type === ExternalAccountType.Email)[0]?.value;
  const addAuthenticator = () => {
    const secret = nextBase32Nonce();
    const uri = `otpauth://totp/HigherKnowledge:${primaryEmail}?secret=${secret}&issuer=HigherKnowledge`;
    setExternalAccount({ type: ExternalAccountType.Authenticator, value: primaryEmail, uri });
  }

  return (
    <>
      <Card className={className}>
        <CardHeader className="bg-white d-flex align-baseline">
          <h3>Contact information</h3>
          <UncontrolledDropdown className="ml-auto">
            <DropdownToggle color="link" caret>Add</DropdownToggle>
            <DropdownMenu modifiers={preventOverflow}>
              <DropdownItem onClick={() => setExternalAccount({ type: ExternalAccountType.Email })}>Email</DropdownItem>
              <DropdownItem onClick={() => setExternalAccount({ type: ExternalAccountType.Phone, options: [ExternalAccountOption.PhoneSms, ExternalAccountOption.PhoneWhatsApp] })}>Phone</DropdownItem>
              <DropdownItem onClick={() => setExternalAccount({ type: ExternalAccountType.Mail })}>Address</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardHeader>
        <ListGroup flush>
          {values.filter(v => v.type === ExternalAccountType.Email).map((v, i) => <ItemRow key={i} value={v} onUpdate={(value, values) => onUpdate([...values, value])} />)}
          {values.filter(v => v.type === ExternalAccountType.Phone).map((v, i) => <ItemRow key={i} value={v} onUpdate={(value, values) => onUpdate([...values, value])} />)}
          {values.filter(v => v.type === ExternalAccountType.Mail).map((v, i) => <ItemRow key={i} value={v} onUpdate={(value, values) => onUpdate([...values, value])} />)}
        </ListGroup>
      </Card>
      <Card className="mt-3">
        <CardHeader className="bg-white d-flex align-baseline">
          <h3>Connected accounts</h3>
          {!authenticatorAccounts.length && primaryEmail &&
            <UncontrolledDropdown className="ml-auto">
              <DropdownToggle color="link" caret>Add</DropdownToggle>
              <DropdownMenu modifiers={preventOverflow}>
                <DropdownItem disabled={!primaryEmail} onClick={addAuthenticator}>Authenticator</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
          {false &&
            <UncontrolledDropdown className="ml-auto">
              <DropdownToggle color="link" caret>Add</DropdownToggle>
              <DropdownMenu modifiers={preventOverflow}>
                <DropdownItem>Amazon</DropdownItem>
                <DropdownItem>Facebook</DropdownItem>
                <DropdownItem>Google</DropdownItem>
                <DropdownItem>Instagram</DropdownItem>
                <DropdownItem>LinkedIn</DropdownItem>
                <DropdownItem>Microsoft</DropdownItem>
                <DropdownItem>Quickbooks</DropdownItem>
                <DropdownItem>Twitter</DropdownItem>
                <DropdownItem disabled={!primaryEmail} onClick={addAuthenticator}>Authenticator</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        </CardHeader>
        <ListGroup flush>
          {connectedAccounts.map((v, i) => <ItemRow key={i} value={v} hidePrimary onUpdate={value => onUpdate([value])} />)}
          {connectedAccounts.length === 0 && <ListGroupItem className="text-muted">Connect a social account to sign-in more easily.</ListGroupItem>}
        </ListGroup>
      </Card>
      <Show scopes={["Wallet.Withdraw"]}>
        <Card className="mt-3">
          <CardHeader className="bg-white d-flex align-baseline">
            <h3>Payment accounts</h3>
            <UncontrolledDropdown className="ml-auto">
              <DropdownToggle color="link" caret>Add</DropdownToggle>
              <DropdownMenu modifiers={preventOverflow}>
                <DropdownItem onClick={() => setExternalAccount({ type: ExternalAccountType.Payment, uri: "payto://ifsc/" })}>Bank account</DropdownItem>
                <DropdownItem onClick={() => setExternalAccount({ type: ExternalAccountType.Payment, uri: "payto://upi/" })}>UPI address</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </CardHeader>
          <ListGroup flush>
            {paymentAccounts.map((v, i) => <ItemRow key={i} value={v} onUpdate={(value, values) => onUpdate([...values, value])} />)}
            {paymentAccounts.length === 0 && <ListGroupItem className="text-muted">Add a payment account to receive payments.</ListGroupItem>}
          </ListGroup>
        </Card>
      </Show>
      <InputModal isOpen={!!externalAccount} toggle={() => setExternalAccount(undefined)} value={externalAccount || {}} onUpdate={(value, values) => onUpdate?.([...values, value])} />
    </>
  );
}
