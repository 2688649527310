import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import FormGroup from "../components/FormGroup";
import { ApiErrors, useMutation } from "../hooks/ApiProvider";
import { useNavigation } from "../hooks/NavigationHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { AreaType, DateTime, FunctionType, IndustryType, IWorkItemDetailsModel, WorkItemModel, WorkItemType } from "../types/api-graph-types";

export const DetailsFragment = `
  type
  area
  function
  title
  description
  dueOn
  jobsDueOn
  jobsCount
`;

export const Fragment = `
  id
  kind
  state
  details { ${DetailsFragment} }
  policy { role } 
  jobsSummary { positiveCount }
`;

const _createWorkItem = `mutation createWorkItem($type:WorkItemType $details:LegalResearchInputModel) {
  workitem: addWorkitem(type:$type) {
    details { ... on LegalResearchOperations { change(value:$details) } }
    commit { ${Fragment} }
  }
}`;

const _updateWorkItemDetails = `mutation updateWorkItemDetails($id:ID $value:LegalResearchInputModel) {
  workitem(id:$id) { 
    details { ... on LegalResearchOperations { change(value:$value) } }
    commit { ${Fragment} }
  }
}`;

interface OperationResults {
  workitem: {
    commit: WorkItemModel
  }
}

interface OperationErrors {
  workitem: {
    details: {
      change: ApiErrors<{ value: LegalResearchInputModel }>
    }
  }
}

interface LegalResearchInputModel {
  readonly industry: IndustryType,
  readonly function: FunctionType,
  readonly area: AreaType,
  readonly title: string,
  readonly description: string,
  readonly dueOn: DateTime,
  readonly jobsCount: number,
  readonly jobsDueOn: DateTime
}

export const New: WorkItemModel = {
  id: "",
  details: {
  } as any
} as any;

const _functions = [FunctionType.LegalFunction];
const _types = new Map<WorkItemType, Partial<LegalResearchInputModel>>([
  [WorkItemType.LegalResearch, { function: FunctionType.LegalFunction }],
]);

const _toWorkItemDetailsInputModel = (value: IWorkItemDetailsModel): LegalResearchInputModel => ({
  area: value.area || AreaType.Null,
  industry: value.industry || IndustryType.Null,
  function: value.function || FunctionType.Null,
  title: value.title || "",
  description: value.description || "",
  dueOn: value.dueOn || "",
  jobsCount: value.jobsCount || 5,
  jobsDueOn: value.jobsDueOn || ""
});

export default ({ value, isOpen, toggle, onUpdate }: {
  value: WorkItemModel,
  isOpen?: boolean,
  toggle?: () => void,
  onUpdate?: (value: WorkItemModel) => void,
}) => {
  const [t, d] = useTranslation();
  const [, go] = useNavigation();
  const [type, setType] = useState(value.details.type);
  const [input, setInput] = useState(_toWorkItemDetailsInputModel(value.details || {}));
  const [errors, setErrors] = useState<ApiErrors<LegalResearchInputModel>>({});
  const updateInput = (props: Partial<LegalResearchInputModel>) => setInput({ ...input, ...props });
  const [mutation] = useMutation<OperationResults>();
  const update = async () => {
    try {
      const result = await mutation(_updateWorkItemDetails, { id: value.id, value: input });
      onUpdate?.(result.workitem.commit);
      toggle?.();
    } catch (errors) {
      setErrors((errors as OperationErrors).workitem.details.change.value || {});
    }
  }
  const create = async () => {
    try {
      const result = await mutation(_createWorkItem, { type, details: input });
      onUpdate?.(result.workitem.commit);
      toggle?.();
      go(`/my/workitems/${result.workitem.commit.id}`);
    } catch (errors) {
      setErrors((errors as OperationErrors).workitem.details.change.value || {});
    }
  }
  const cancel = () => {
    toggle?.();
  }
  const updateType = (value: WorkItemType) => {
    setType(value);
    updateInput(_types.get(value) || {});
  }
  const updateFunction = (value: FunctionType) => {
    const prefix = value.replace("FUNCTION", "");
    updateInput({
      function: value,
      area: input.area?.startsWith(prefix) ? input.area : AreaType.Null
    });
    if (!type?.startsWith(prefix)) {
      setType(WorkItemType.Null);
    }
  }
  const updateDueOn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    updateInput({
      dueOn: value ? moment(value).toISOString() : undefined,
      jobsDueOn: value ? moment(value).add(-1, "days").toISOString() : undefined
    });
  }
  const functionPrefix = input.function.replace("FUNCTION", "");
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className='modal-header bb-0 w-100'>
        <FormGroup className="w-100 mb-0" error={errors.title}>
          <Input type="text" className="form-control form-control-lg" id="name" placeholder="Enter your work item title here" value={input.title || ""} onChange={e => updateInput({ title: e.currentTarget.value })} />
        </FormGroup>
      </div>
      <ModalBody>
        <Form>
          <fieldset>
            <Row form>
              <Col>
                <FormGroup label='Function' error={errors.function} required value={input.function}>
                  <select className="custom-select" value={input.function} onChange={e => updateFunction(e.currentTarget.value as FunctionType)}>
                    <option value=""></option>
                    {_functions.map(k => <option key={k} value={k}>{t(k)}</option>)}
                  </select>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup label='Task' required>
                  <select className="custom-select" value={type} onChange={e => updateType(e.currentTarget.value as WorkItemType)}>
                    <option value=""></option>
                    {Array.from(_types.keys()).filter(k => k.startsWith(functionPrefix)).map((_, i) => <option key={i} value={_}>{t(_)}</option>)}
                  </select>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup label='Area' error={errors.area}>
                  <select className="custom-select" value={input.area || ""} onChange={e => updateInput({ area: e.currentTarget.value as AreaType })}>
                    {Object.values(AreaType).filter((k: string) => k.startsWith(functionPrefix) || k === AreaType.Null).map(k => <option key={k} value={k}>{t(k)}</option>)}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup label='Description' error={errors.description} value={input.description} showPreview>
              <Input type="textarea" rows={5} className="form-control" placeholder="Describe your listing here" value={input.description || ""} onChange={e => updateInput({ description: e.currentTarget.value })} />
            </FormGroup>
            <Row form>
              <Col>
                <FormGroup label='Responses' error={errors.jobsCount}>
                  <Input type="number" min={1} className="form-control" value={input.jobsCount} onChange={e => updateInput({ jobsCount: Math.max(1, parseInt(e.currentTarget.value)) })} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup label='Due on' error={errors.dueOn}>
                  <Input type="date" min={moment().format("YYYY-MM-DD")} className="form-control" value={input.dueOn ? moment(input.dueOn).format("YYYY-MM-DD") : ""} onChange={updateDueOn} />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>Cancel</Button>
        {value.id && <Button color="primary" onClick={update}>Update</Button>}
        {!value.id && <Button color="primary" onClick={create}>Post</Button>}
      </ModalFooter>
    </Modal>
  );
}

