import React from "react";
import { Route, Switch } from "react-router";
import { Col, Row, Nav, NavItem, NavLink } from "reactstrap";
import StaticContentCard, { FAQ, Namespaces } from "../cards/StaticContentCard";
import { useNavigation } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";

interface Key { key: string; }
interface R<T> { match: { params: T; } }

const _pages = [
  { name: FAQ.General, title: "General" },
  { name: FAQ.Account, title: "Account" },
  { name: FAQ.Assessments, title: "Assessments" },
  { name: FAQ.Placements, title: "Placements" },
  { name: FAQ.PlacementsCommittees, title: "For committees", level: 2 },
  { name: FAQ.PlacementsStudents, title: "For students", level: 2 },
];

function Content({ name }: { name: string }) {
  const page = _pages.filter(_ => _.name === name)[0];
  useTitle(`${page?.title}`);
  return (
    <StaticContentCard namespace={Namespaces.FAQ} name={name} />
  );
}

export default () => {
  const path = window.location.pathname;
  const [navigate] = useNavigation();
  return (
    <Row className="mt-3">
      <Col lg={3}>
        <Nav className='flex-md-column sticky-top'>
          {_pages.map((_, i) => {
            const href = `/our/faq/${_.name}`;
            const classNames = [
              _.level === 2 ? "ml-4" : ""
            ].join(" ");
            return (
              <NavItem key={i} className='mr-1'>
                <NavLink active={path === href} href={href} className={classNames} onClick={navigate(href)}>{_.title}</NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </Col>
      <Col lg={9}>
        <Switch>
          <Route exact path="/our/faq/:key" render={(_: R<Key>) => <Content name={_.match.params.key} />} />
        </Switch>
      </Col>
    </Row>
  );
}
