import React, { ReactNode } from "react";
import { useClaims, Scopes as _Scopes } from "../hooks/TokenProvider";

export const Scopes = _Scopes;

export function useScopes(): [(scope: string | string[]) => boolean] {
  const [, _scopes] = useClaims();
  const hasScope = (scope: string | string[]) => {
    return !_scopes ? false
      : typeof (scope) === "string" ? _scopes.has(scope)
        : scope.some(_ => _scopes.has(_));
  }
  return [hasScope];
}

export default ({ when, unless, scope, scopes, children }: {
  when?: boolean,
  unless?: boolean,
  scopes?: string[],
  scope?: string
  children: ReactNode
}) => {
  const [, _scopes] = useClaims();
  const show = when
    || (unless !== undefined && !unless)
    || (_scopes && scopes && scopes.some(_ => _scopes.has(_)))
    || (_scopes && scope && _scopes.has(scope))
    || false;
  return show ? <>{children}</> : null;
}
