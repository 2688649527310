import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Alert, Button, Col, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, InputGroupButtonDropdown, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { formatBytes, Fragment as AttachmentFragment, InputText as AttachmentsInputText } from "../components/CardAttachmentsListGroup";
import { Markdown } from "../components/CardMarkdownText";
import { canPreview, Modal as ContentPreviewModal } from "../components/ContentPreviewDiv";
import DatalistInput from "../components/DatalistInput";
import FormGroup from "../components/FormGroup";
import ValidatingInput from "../components/Input";
import MutationButton from "../components/MutationButton";
import { ApiErrors, nextSequence, useMutation, useReadyState } from "../hooks/ApiProvider";
import { useToggle } from "../hooks/CommonHooks";
import { useMe } from "../hooks/MeProvider";
import { preventDefault, useNavigation } from "../hooks/NavigationHook";
import { DateDisplayFormat, toLocalDate, toUtcString, useTranslation } from "../hooks/TranslationProvider";
import { AttachmentInputModel, AttachmentModel, CurrencyCode, MembershipInputModel, MembershipModel, MeModel, MoneyInputModel, MoneyModel, OrderDetailsInputModel, OrderModel, PersonInputModel, ReferenceDataType, ResourceState, CountryCode, CityCode } from "../types/api-graph-types";

export const DetailsFragment = `
  startOn
  startIn
  endOn
  location
  screeningOn
  screeningDuration
  screeningLocation
  interviewOn
  interviewDuration
  interviewLocation
  supplierDescription
  customerName
  customerNickname
  customerDescription
  customerExternalId
  labels { key color value }
  customerCompensation { currency amount period isPrivate }
`;

export const Fragment = `
  id
  kind
  state
  stage
  startedOn
  restartOn
  expiresOn
  isExpired
  details { ${DetailsFragment} }
  attachments { ${AttachmentFragment} }
  product { 
    details { 
      type
      duration 
      compensation { currency amount }
      ordersDescription 
      ordersCustomerExternalIdMessage
    } 
    organization { name } 
  }
  actions {
    accept
    cancel
    change
    decline
    deliver
    interview
    offer
    refuse
    remove
    retract
    return
    review
    screen
    shortlist
    start
    submit
    undo
    uninterview
    unscreen
    unshortlist
    withdraw
  }
  policy { role }
`

const _updateOrder = `mutation updateOrder($id:ID! $value:OrderDetailsInputModel $me:PersonInputModel $myIncomePreference:PersonInputModel $changeMe:Boolean! $resume:AttachmentInputModel $changeResume:Boolean! $changeMyIncomePreference:Boolean! $membership:MembershipInputModel $addMembership:Boolean! $fetchMe:Boolean!) {
  me { 
    membership: addMembership(type:STUDENT) @include(if:$addMembership) {
      change(value:$membership)
      commit { id }
    }
    resume @include(if:$changeResume) {
      change(value:$resume)
      commit { id }
    }
    changeMe: change(value:$me) @include(if:$changeMe) 
    changeMyIncomePreference: change(value:$myIncomePreference) @include(if:$changeMyIncomePreference) 
    commit @include(if:$fetchMe) { 
      name
      eduAffiliation { organization { id kind name } organizationName endOn }
      resume { updatedOn filename length mediaType url pdfUrl } 
    }
  }  
  order(id:$id) {
    details { change(value:$value) } 
    commit { id details { ${DetailsFragment} } } 
  }
}`;

interface Result {
  me: MeModel
}

interface OperationResults {
  me: {
    commit: MeModel
    membership: {
      commit: MembershipModel
    }
    resume: {
      commit: AttachmentModel
    }
  }
  order: {
    commit: OrderModel
  }
}

interface OperationErrors {
  order: {
    details: {
      change: ApiErrors<{ id: string, value: OrderDetailsInputModel }>
    }
  }
}

const _newMembership: MembershipInputModel = {
  organizationId: "",
  organizationName: "",
  organizationNickname: "",
  title: "",
  focus: "",
  startOn: "",
  endOn: "",
  isEducational: false,
  isPrimary: false,
  income: {
    amount: 0,
    currency: CurrencyCode.Null,
    isGross: false,
    isPrivate: false,
    period: "",
    variableFraction: 0
  },
  location: {
    country: CountryCode.Null,
    postalCode: "",
    region: "",
    streetAddress: "",
    city: CityCode.Null,
    locality: "",
    neighborhood: ""
  },
  cgpa: { value: 0, maxValue: 0 },
  rank: { value: 0, maxValue: 0 },
  description: ""
};

function _toMoneyInputModel(value: MoneyModel | undefined): MoneyInputModel {
  return {
    currency: value?.currency ?? CurrencyCode.Null,
    amount: value?.amount ?? 0,
    variableFraction: value?.variableFraction ?? 0,
    period: value?.period ?? "P365D",
    isGross: value?.isGross ?? true,
    isPrivate: value?.isPrivate ?? false
  };
};

const _toOrderDetailsInputModel = (value: OrderModel | undefined): OrderDetailsInputModel => ({
  startOn: value?.details?.startOn ?? "",
  startIn: value?.details?.startIn ?? "",
  endOn: value?.details?.endOn ?? "",
  location: value?.details?.location || "",
  screeningOn: value?.details?.screeningOn ?? "",
  screeningDuration: value?.details?.screeningDuration || "",
  screeningLocation: value?.details?.screeningLocation || "",
  interviewOn: value?.details?.interviewOn ?? "",
  interviewDuration: value?.details?.interviewDuration || "",
  interviewLocation: value?.details?.interviewLocation || "",
  offerExpiresOn: value?.details?.offerExpiresOn ?? "",
  customerName: value?.details?.customerName || value?.customer?.name || "",
  customerNickname: "",
  customerExternalId: value?.details?.customerExternalId || "",
  supplierDescription: value?.details?.supplierDescription || "",
  customerDescription: value?.details?.customerDescription || "",
  customerCompensation: _toMoneyInputModel(value?.details?.customerCompensation),
  labels: value?.details?.labels || [],
  addLabels: [],
  removeLabels: []
});

export default ({ value, title, onUpdate, isOpen, toggle }: {
  value: OrderModel,
  isOpen?: boolean,
  toggle?: () => void,
  title?: string,
  onUpdate?: (value: OrderModel) => void
}) => {
  const [t, , n] = useTranslation();
  const [uid] = useState(nextSequence());
  const [readyState, setReadyState] = useReadyState();
  const [me, updateMe] = useMe();
  const [, d] = useTranslation();
  const [navigate] = useNavigation();
  const [inputResume, toggleInputResume] = useToggle();
  const [previewResume, togglePreviewResume] = useToggle();
  const [isOpenStartIn, toggleStartIn] = useToggle(!!value.details.startIn);
  const [isOpenCompensationCurrency, toggleCompensationCurrency] = useToggle();
  const [isOpenCompensationPeriod, toggleCompensationPeriod] = useToggle();
  const [input, setInput] = useState(_toOrderDetailsInputModel(value));
  const [eduInput, setEduInput] = useState<MembershipInputModel>({ ..._newMembership });
  const [resume, setResume] = useState<File>();
  const [errors, setErrors] = useState<ApiErrors<OrderDetailsInputModel>>({});
  const [mutation] = useMutation<OperationResults>();
  const updateInput = (props: Partial<OrderDetailsInputModel>) => setInput({ ...input, ...props });
  const updateEduInput = (props: Partial<MembershipInputModel>) => setEduInput({ ...eduInput, ...props });
  const change = async () => {
    try {
      const changeMyIncomePreference = input.customerCompensation.amount > 0;
      const changeMe = !!input.customerName;
      const changeResume = !!resume?.name;
      const addMembership = !!eduInput.organizationName || !!eduInput.organizationId;
      const fetchMe = changeMe || changeResume || addMembership;
      const result = await mutation(_updateOrder, {
        id: value.id,
        value: { ...input, customerName: "" } as Partial<OrderDetailsInputModel>,
        me: { name: input.customerName || me?.name } as Partial<PersonInputModel>,
        myIncomePreference: { incomePreference: { value: input.customerCompensation } } as Partial<PersonInputModel>,
        resume: { state: ResourceState.Resolved, content: resume?.name, title: "Résumé", subtitle: me?.name } as Partial<AttachmentInputModel>,
        membership: eduInput as Partial<MembershipInputModel>,
        changeMe, changeMyIncomePreference, changeResume, addMembership, fetchMe
      }, { setReadyState, files: resume ? [resume] : undefined });
      fetchMe && updateMe(result.me.commit);
      onUpdate?.(result.order.commit);
      cancel();
    } catch (errors) {
      setErrors(errors && (errors as OperationErrors).order.details.change.value || {});
    }
  }
  const cancel = () => {
    setInput(_toOrderDetailsInputModel(value));
    setEduInput({ ..._newMembership });
    setResume(undefined);
    toggle?.();
  }
  const reset = () => {
    toggleStartIn(!!value.details.startIn);
  }
  const updateResume = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]?.name) {
      setResume(Array.from(e.target.files || [])[0]);
    }
  }
  const updateCompensationInput = (compensation: Partial<MoneyInputModel>) => {
    updateInput({ customerCompensation: { ...input.customerCompensation, ...compensation } })
  }
  useEffect(reset, [value]);

  const now = new Date();
  const startOn = toLocalDate(input.startOn);
  const endOn = toLocalDate(input.endOn);
  const curResume = me?.resume;

  const showResume = !me?.resume?.updatedOn || moment(me?.resume?.updatedOn).add(6, "M").isBefore();
  const showEndOn = value.product.details.duration && value.product.details.duration != "P65535D" || false;
  const showStartIn = !showEndOn;
  const showCompensation = value.product.details.compensation?.amount > 0;
  const showExternalId = !!value.product.details.ordersCustomerExternalIdMessage;

  const isValid = (me?.name || input.customerName)
    && (me?.eduAffiliation?.endOn || (eduInput.organizationName && eduInput.endOn))
    && (input.startOn || input.startIn);

  return (
    <Modal isOpen={isOpen} toggle={cancel}>
      <ModalHeader>Update {title}</ModalHeader>
      <ModalBody>
        <Form>
          <fieldset>
            {!me?.name &&
              <FormGroup label='Name' required>
                <Input value={input.customerName || ""} onChange={e => updateInput({ customerName: e.currentTarget.value })} />
              </FormGroup>
            }
            {!me?.eduAffiliation?.endOn &&
              <Row form>
                <Col>
                  <FormGroup label="Institution" required>
                    <DatalistInput types={[ReferenceDataType.OrganizationName]} placeholder="Enter most recent educational institution here" value={eduInput.organizationName || ""} onUpdate={(organizationId, organizationName) => updateEduInput({ organizationId, organizationName })} />
                  </FormGroup>
                </Col>
                <Col xs={4}>
                  <FormGroup label='Graduation' required>
                    <DatePicker className="form-control" selected={toLocalDate(eduInput.endOn)} showMonthYearPicker dateFormat="MMMM yyyy" onChange={v => updateEduInput({ endOn: toUtcString(v) || undefined })} />
                  </FormGroup>
                </Col>
              </Row>
            }
            {showResume &&
              <FormGroup label='Résumé'>
                {!inputResume && curResume ? (
                  <div className="d-flex bg-input p-2 align-items-center">
                    <FontAwesomeIcon icon="paperclip" className="mr-1" />
                    <a href="#" onClick={preventDefault(canPreview(curResume) ? togglePreviewResume : navigate(curResume.url, true))}>{curResume.filename}</a>
                    {curResume.length > 0 && <span className="ml-1 small">({formatBytes(curResume.length)})</span>}
                    <span className="text-muted small ml-1">updated {d(curResume.updatedOn, DateDisplayFormat.TimeAgo)}</span>
                    <a href="#" className="ml-auto" onClick={preventDefault(toggleInputResume)}>Update</a>
                    <ContentPreviewModal value={curResume} isOpen={previewResume} toggle={togglePreviewResume} />
                  </div>
                ) : (
                    <div className='custom-file'>
                      <Input type="file" className="custom-file-input" onChange={updateResume} />
                      <label className={`custom-file-label text-truncate text-nowrap ${!resume?.name && "text-muted"}`}>
                        {resume?.name || "Select your file here"}
                      </label>
                    </div>
                  )}
              </FormGroup>
            }
            {showExternalId &&
              <FormGroup label='Reference Id' value={input.customerExternalId} help={value.product.details.ordersCustomerExternalIdMessage}>
                <ValidatingInput placeholder={value.product.details.ordersCustomerExternalIdMessage.split('.')[0]} onUpdate={customerExternalId => updateInput({ customerExternalId })} />
              </FormGroup>
            }
            <Row form>
              <Col>
                <FormGroup label={isOpenStartIn ? 'Notice period' : 'Available from'} required
                  addon={showStartIn && <CustomInput id={`${uid}.0`} className="ml-3 col-form-label-sm" bsSize="sm" type="switch" label="Currently working" checked={isOpenStartIn} onChange={toggleStartIn} />}>
                  {!isOpenStartIn && <DatePicker className="form-control" selected={startOn} selectsStart startDate={startOn} endDate={endOn} dateFormat="MMMM d, yyyy" minDate={now} onChange={v => updateInput({ startOn: toUtcString(v) ?? "" })} />}
                  {isOpenStartIn &&
                    <select className={`custom-select ${!input.startIn && "text-muted"}`} value={input.startIn ?? ""} onChange={e => updateInput({ startIn: e.currentTarget.value })}>
                      <option value="" hidden>Enter your notice period here</option>
                      <option value=""></option>
                      <option value="P7D">1 week</option>
                      <option value="P14D">2 weeks</option>
                      <option value="P30D">1 month</option>
                      <option value="P60D">2 months</option>
                      <option value="P90D">3 months</option>
                    </select>
                  }
                </FormGroup>
              </Col>
              {showEndOn &&
                <Col>
                  <FormGroup label='Available until'>
                    <DatePicker className="form-control" selected={endOn} selectsEnd startDate={startOn} endDate={endOn} dateFormat="MMMM d, yyyy" minDate={now} onChange={v => updateInput({ endOn: toUtcString(v) ?? "" })} />
                  </FormGroup>
                </Col>
              }
            </Row>
            {showCompensation &&
              <FormGroup label="Consideration" help="Enter your minimum acceptible compensation before taxes. If you have other offers, enter your best offer. You will NOT be considered for opportunities below this amount. Private entries are used for filtering but are not displayed." addon={<CustomInput id="comp" className="small" bsSize="sm" type="switch" label="Private" checked={input.customerCompensation.isPrivate} onChange={() => updateCompensationInput({ isPrivate: !input.customerCompensation.isPrivate })} />}>
                <InputGroup>
                  <InputGroupButtonDropdown addonType="prepend" isOpen={isOpenCompensationCurrency} toggle={toggleCompensationCurrency}>
                    <DropdownToggle color="input">{t(input.customerCompensation.currency)}</DropdownToggle>
                    <DropdownMenu>
                      {[CurrencyCode.Null, CurrencyCode.Inr].map((_, i) =>
                        <DropdownItem key={i} onClick={() => updateCompensationInput({ currency: _ })}>{t(_)}</DropdownItem>
                      )}
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                  <Input className="pl-0" value={input.customerCompensation.amount && n(input.customerCompensation.amount) || ""} placeholder="Enter minimum gross compensation" onChange={e => updateCompensationInput({ amount: parseInt(e.currentTarget.value.replace(/[^0-9]/g, "")), currency: input.customerCompensation.currency === CurrencyCode.Null ? CurrencyCode.Inr : input.customerCompensation.currency })} />
                  <InputGroupButtonDropdown addonType="append" isOpen={isOpenCompensationPeriod} toggle={toggleCompensationPeriod}>
                    <DropdownToggle color="input" caret>{input.customerCompensation.period === "P30D" ? "per month" : input.customerCompensation.period === "P365D" ? "per year" : moment.duration(input.customerCompensation.period)}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => updateCompensationInput({ period: "P30D" })}>per month</DropdownItem>
                      <DropdownItem onClick={() => updateCompensationInput({ period: "P365D" })}>per year</DropdownItem>
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                </InputGroup>
              </FormGroup>
            }
            {value.product?.details?.ordersDescription &&
              <Alert color="primary" className="mt-3">
                <h5>Additional instructions</h5>
                <Markdown source={value.product.details.ordersDescription} />
              </Alert>
            }
            <FormGroup label='Additional details' maxLength={14000} value={input.customerDescription} showPreview help={`Introduce yourself here. Tell ${value.product?.details?.manufacturerOrg?.name ?? "us"} a little about yourself, what you love about this opportunity, and how you found out about it.`}>
              <ValidatingInput type="textarea" rows={5} placeholder="Provide any additional details here" onUpdate={customerDescription => updateInput({ customerDescription })} />
            </FormGroup>
            <AttachmentsInputText value={value} onUpdate={onUpdate} label="Additional documents" help="Attach additional documents here if requested. Do not attach your résumé here." />
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>Cancel</Button>
        <MutationButton readyState={readyState} disabled={!isValid} color="primary" onClick={change}>Save</MutationButton>
      </ModalFooter>
    </Modal>
  );
}

