import moment from "moment";
import * as Popper from "popper.js";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { CustomInput, InputGroupAddon, InputGroupText } from "reactstrap";
import { CustomDropdownItem } from "../components/CustomDropdown";
import { nextSequence } from "../hooks/ApiProvider";
import { toLocalDate, toUtcString, toStartOfDay, toEndOfDay } from "../hooks/TranslationProvider";

const _isNewFY = moment().isAfter(moment().startOf("year").add(3, "month"));

const _lastPeriod = [
  ["week/-P7D/week/P0D", "Last week"],
  ["month/-P1M/month/P0D", "Last month"],
  ["month/-P3M/month/P0D", "Last three months"],
  ["quarter/-P3M/quarter/P0D", "Last quarter"],
  [_isNewFY ? "year/-P9M/year/P3M" : "year/-P21M/year/-P9M", "Last financial year"],
  ["year/-P12M/year/P0D", "Last year"]
];

const _thisPeriod = [
  ["week/P0D/day/P1D", "This week to date"],
  ["month/P0D/day/P1D", "This month to date"],
  ["quarter/P0D/day/P1D", "This quarter to date"],
  [_isNewFY ? "year/P3M/day/P1D" : "year/-P9M/day/P1D", "This financial year to date"],
  ["year/P0D/day/P1D", "This year to date"]
];

const _preventOverflow: Popper.Modifiers = {
  preventOverflow: {
    enabled: true,
    boundariesElement: "viewport",
    padding: 35
  }
};

function _periodDates(value: string) {
  const parts = value.split("/");
  if (parts.length == 4) {
    const since = moment().startOf(parts[0] as any).add(moment.duration(parts[1])).format("YYYY-MM-DD");
    const until = moment().startOf(parts[2] as any).add(moment.duration(parts[3])).format("YYYY-MM-DD");
    return `${since}/${until}`;
  } else {
    return "/";
  }
}

export function MenuItems({ className, value, onUpdate }: {
  className?: string;
  value: string;
  onUpdate: (value: string) => void;
}) {
  const [period, setPeriod] = useState<string>();
  const updatePeriod = (value: string) => {
    onUpdate(_periodDates(value));
    setPeriod(value);
  };
  const now = new Date();
  const values = value.split("/");
  const since = toLocalDate(values[0]);
  const until = values[1] && toLocalDate(moment(values[1]).add(-1, "s").toISOString()) || null;

  return (
    <>
      <CustomDropdownItem header>Last preiod</CustomDropdownItem>
      {_lastPeriod.map(([value, label]) => <CustomDropdownItem key={value} checked={period === value} onClick={() => updatePeriod(value)}>{label}</CustomDropdownItem>)}
      <CustomDropdownItem header>This period</CustomDropdownItem>
      {_thisPeriod.map(([value, label]) => <CustomDropdownItem key={value} checked={period === value} onClick={() => updatePeriod(value)}>{label}</CustomDropdownItem>)}
      <CustomDropdownItem header>Custom period</CustomDropdownItem>
      <CustomDropdownItem toggle={false}>
        <DatePicker className="form-control" selected={since} selectsStart startDate={since} endDate={until} dateFormat="yyyy-LL-dd" onChange={v => onUpdate(`${toUtcString(toStartOfDay(v))}/${values[1] || ""}`)} />
      </CustomDropdownItem>
      <CustomDropdownItem toggle={false}>
        <DatePicker className="form-control" selected={until} selectsEnd startDate={since} endDate={until} dateFormat="yyyy-LL-dd" minDate={now} onChange={v => onUpdate(`${values[0] || ""}/${toUtcString(toEndOfDay(v))}`)} />
      </CustomDropdownItem>
    </>
  );
}

export default ({ className, value, onUpdate }: {
  className?: string;
  value: string;
  onUpdate: (value: string) => void;
}) => {
  const [uid] = useState(nextSequence());
  const [period, setPeriod] = useState<string>();
  const updatePeriod = (value: string) => {
    onUpdate(_periodDates(value));
    setPeriod(value);
  };
  const update = (value: string) => {
    onUpdate(value);
    setPeriod("custom");
  }
  const now = new Date();
  const values = value.split("/");
  const since = toLocalDate(values[0]);
  const until = values[1] && toLocalDate(moment(values[1]).add(-1, "s").toISOString()) || null;

  return (
    <div className={`${className} d-flex b-1i`}>
      <CustomInput id={uid} className="form-control" type="select" value={period} onChange={e => updatePeriod(e.currentTarget.value)}>
        <option value=""></option>
        <option value="custom">Custom</option>
        <option disabled></option>
        {_lastPeriod.map(([value, label], i) => <option key={value} value={value}>{label}</option>)}
        <option disabled></option>
        {_thisPeriod.map(([value, label], i) => <option key={value} value={value}>{label}</option>)}
      </CustomInput>
      <DatePicker className="form-control w-7e pr-0 text-right" selected={since} selectsStart startDate={since} endDate={until} dateFormat="yyyy-LL-dd" onChange={v => update(`${toUtcString(toStartOfDay(v))}/${values[1] || ""}`)} />
      {since && until && <InputGroupAddon addonType="append"><InputGroupText className="py-0">–</InputGroupText></InputGroupAddon>}
      <DatePicker className="form-control w-7e pl-0" selected={until} selectsEnd startDate={since} endDate={until} dateFormat="yyyy-LL-dd" maxDate={now} onChange={v => update(`${values[0] || ""}/${toUtcString(toEndOfDay(v))}`)} popperModifiers={_preventOverflow} />
    </div>
  );
}
