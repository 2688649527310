import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Col, Collapse, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, ModalFooter, Row, Table, UncontrolledDropdown } from "reactstrap";
import { Fragment as AttachmentFragment, InputText as AttachmentsInputText } from "../components/CardAttachmentsListGroup";
import { CardBadge } from "../components/CardStatus";
import { CustomDropdownItem } from "../components/CustomDropdown";
import DatalistInput from "../components/DatalistInput";
import FormGroup, { isEmail, isPhone, isUrl } from "../components/FormGroup";
import ValidatingInput from "../components/Input";
import LoadingProgress from "../components/LoadingProgress";
import MutationButton from "../components/MutationButton";
import { DeepPartial, delUndefined, nextSequence, setDelete, setReplace, useMutation, useReadyState } from "../hooks/ApiProvider";
import { useToggle, useUpdatableState } from "../hooks/CommonHooks";
import { useNavigation, download } from "../hooks/NavigationHook";
import { FormatEnum, toLocalDate, toUtcString, useTranslation } from "../hooks/TranslationProvider";
import { CreateLabelModal } from "../modals/ProductInputModal";
import { Fragment as _PreviewFragment, PreviewModalBody } from "../modals/SendMessageModal";
import { CityCode, CurrencyCode, DateTime, LabelModel, LocationModel, MapEntry, MembershipInputModel, MessageModel, MoneyInputModel, MoneyModel, OrderDetailsInputModel, OrderModel, ProductModel, ReferenceDataType, ResourceRole, ProductType, OrderStage } from "../types/api-graph-types";
import { useConfirmation } from "../hooks/ConfirmationProvider";

library.add(faPlus, faFilter);

export const DetailsFragment = `
  startOn
  endOn
  location
  screeningOn
  screeningDuration
  screeningLocation
  interviewOn
  interviewDuration
  interviewLocation
  supplierDescription
  offerExpiresOn
  customerName
  customerNickname
  customerDescription
  customerExternalId
  labels { key color value }
`;

export const Fragment = `
  id
  kind
  state
  stage
  startedOn
  restartOn
  expiresOn
  isExpired
  details { ${DetailsFragment} }
  attachments { ${AttachmentFragment} }
  actions {
    allow
    accept
    cancel
    change
    decline
    deliver
    interview
    offer
    refuse
    remove
    retract
    return
    review
    screen
    shortlist
    start
    submit
    undo
    uninterview
    unscreen
    unshortlist
    withdraw
  }
  policy { role accessToken }
`

export const PreviewFragment = _PreviewFragment;

function _fieldArgs(args: string[]) {
  const a = args.filter(_ => !!_);
  return a.length > 0 ? `(${a.join(" ")})` : "";
}

function _updateProductOrdersQuery(action: string, hasDetails?: boolean, hasExpiresOn?: boolean, hasKey?: boolean, hasData?: boolean) {
  const name = action.split(" ").map(v => v[0].toUpperCase() + v.substr(1).toLowerCase()).join("");
  const fieldArgs = _fieldArgs([
    hasDetails ? "details:$value" : "",
    hasExpiresOn ? "expiresOn:$expiresOn" : "",
    hasKey ? "key:$key" : "",
    hasData ? "data:$data" : ""
  ]);
  return `mutation supplier${name}Orders(
    $pid:ID!
    $ids:[ID!]
    $preview:Boolean!
    ${hasDetails ? " $value:OrderDetailsInputModel" : ""}
    ${hasExpiresOn ? " $expiresOn:DateTime" : ""}
    ${hasKey ? " $key:String" : ""}
    ${hasData ? " $data:[MapEntry]" : ""}
  ) {
    product(id:$pid) {
      orders(ids:$ids) {
        ${action} ${fieldArgs}
        preview @include(if:$preview) { ${PreviewFragment} } 
        commit @skip(if:$preview) { ${Fragment} } 
      } 
      commit { ${_productUpdateFragment} }
    }
  }`;
}

interface OrdersActions {
  accept: string,
  add: string,
  allow: string,
  addShortlist: string,
  change: string,
  copy: string,
  decline: string,
  deliver: string,
  event: string,
  interview: string,
  merge: string,
  move: string,
  offer: string,
  refuse: string,
  remove: string,
  return: string,
  screen: string,
  shortlist: string,
  undo: string,
  uninterview: string,
  unscreen: string,
  unshortlist: string,
  withdraw: string,
}

const _populateOrders = `mutation supplierPopulateOrders($pid:ID! $values:[OrderPopulateInputModel] $labels:[String] $preview:Boolean!) {
  product(id:$pid) {
    orders: populateOrders(values:$values) {
      count
      details { addLabels(keys:$labels) }
      preview @include(if:$preview) { ${PreviewFragment} } 
      commit @skip(if:$preview) { id } 
    } 
  }
}`

const _productUpdateFragment = `
  ordersSummary { 
    requestedCount
    stoppedCount 
    startedCount 
    submittedCount 
    shortlistedCount 
    screeningScheduledCount 
    interviewScheduledCount 
    offeredCount 
    offerExpiredCount 
    acceptedCount 
    deliveredCount 
    declinedCount
    deliveredCount
    returnedCount 
    removedCount
  }
`

export const updateProductOrdersQuery: OrdersActions = {
  add: `mutation supplierAddOrders($pid:ID! $usernames:String $tokens:[String] $preview:Boolean! $value:OrderDetailsInputModel) {
    product(id:$pid) {
      orders: addOrders(usernames:$usernames tokens:$tokens) {
        details { change(value:$value) }
        preview @include(if:$preview) { ${PreviewFragment} } 
        commit @skip(if:$preview) { ${Fragment} customer { name } } 
      }
      commit { ${_productUpdateFragment} }
    }
  }`,
  addShortlist: `mutation supplierAddAndShortlistOrders($pid:ID! $usernames:String $tokens:[String] $preview:Boolean! $value:OrderDetailsInputModel) {
    product(id:$pid) {
      orders: addOrders(usernames:$usernames tokens:$tokens) {
        details { change(value:$value) }
        shortlist
        preview @include(if:$preview) { ${PreviewFragment} } 
        commit @skip(if:$preview) { ${Fragment} customer { name } } 
      } 
      commit { ${_productUpdateFragment} }
    }
  }`,
  move: `mutation supplierMoveOrders($pid:ID! $ids:[ID!] $pid_to:ID!) {
    product(id:$pid) {
      orders(ids:$ids) {
        move(productId:$pid_to) {
          id
        }
      } 
      commit { ${_productUpdateFragment} }
    } 
  }`,
  copy: `mutation supplierCopyOrders($pid:ID! $ids:[ID!] $pid_to:ID!) {
    product(id:$pid) {
      orders(ids:$ids) {
        copy(productId:$pid_to) {
          id
        }
      } 
      commit { ${_productUpdateFragment} }
    } 
  }`,
  merge: `mutation supplierMergeOrders($pid:ID! $ids:[ID!]) {
    product(id:$pid) {
      orders(ids:$ids) {
        merge { ${Fragment} }
      } 
      commit { ${_productUpdateFragment} }
    } 
  }`,
  allow: _updateProductOrdersQuery("allow"),
  accept: _updateProductOrdersQuery("accept"),
  change: _updateProductOrdersQuery("change", true, true),
  decline: _updateProductOrdersQuery("decline"),
  deliver: _updateProductOrdersQuery("deliver"),
  interview: _updateProductOrdersQuery("interview", true),
  offer: _updateProductOrdersQuery("offer", true, false, false, true),
  refuse: _updateProductOrdersQuery("refuse remove"),
  remove: _updateProductOrdersQuery("remove"),
  event: _updateProductOrdersQuery("event", false, false, true, true),
  return: _updateProductOrdersQuery("return"),
  screen: _updateProductOrdersQuery("screen", true),
  shortlist: _updateProductOrdersQuery("shortlist"),
  undo: _updateProductOrdersQuery("undo"),
  uninterview: _updateProductOrdersQuery("uninterview"),
  unscreen: _updateProductOrdersQuery("unscreen"),
  unshortlist: _updateProductOrdersQuery("unshortlist"),
  withdraw: _updateProductOrdersQuery("withdraw"),
}

export type View = keyof (OrdersActions);

interface OperationResults {
  product: {
    orders: {
      count: number
      preview: MessageModel[]
      commit: OrderModel[]
    }
    commit: ProductModel
  }
}

interface Record {
  [key: string]: any,
  __skip__: boolean,
  __index__: number
}

const _newLabel: LabelModel = {} as Partial<LabelModel> as any;
const _inputKey = "__input__";

function _toLocation(t: FormatEnum, value: LocationModel) {
  return value ? [value.streetAddress, value.neighborhood, value.city === CityCode.Other ? value.locality : t(value.city), `${value.region || ""} ${value.postalCode || ""}`.trim()].filter(_ => !!_).join(", ") : null;
}

function _toMoneyInputModel(value: MoneyModel | undefined): MoneyInputModel {
  return {
    currency: value?.currency ?? CurrencyCode.Null,
    amount: value?.amount ?? 0,
    variableFraction: value?.variableFraction ?? 0,
    period: value?.period ?? "P365D",
    isGross: value?.isGross ?? true,
    isPrivate: value?.isPrivate ?? false
  };
};

const _toOrderDetailsInputModel = (value: OrderModel | undefined, product: ProductModel, t: FormatEnum): OrderDetailsInputModel => ({
  startOn: value?.details?.startOn ?? "",
  startIn: value?.details?.startIn ?? "",
  endOn: value?.details?.endOn ?? "",
  location: value?.details?.location || _toLocation(t, product.details.location) || "",
  screeningOn: value?.details?.screeningOn ?? "",
  screeningDuration: value?.details?.screeningDuration || "",
  screeningLocation: value?.details?.screeningLocation || "",
  interviewOn: value?.details?.interviewOn ?? "",
  interviewDuration: value?.details?.interviewDuration || "",
  interviewLocation: value?.details?.interviewLocation || _toLocation(t, product.details.location) || "",
  offerExpiresOn: value?.details?.offerExpiresOn ?? "",
  customerName: value?.details?.customerName || value?.customer?.name || "",
  customerNickname: "",
  customerExternalId: value?.details?.customerExternalId || "",
  supplierDescription: value?.details?.supplierDescription || "",
  customerDescription: value?.details?.customerDescription || "",
  customerCompensation: _toMoneyInputModel(value?.details?.customerCompensation),
  labels: value?.details?.labels || [],
  addLabels: [],
  removeLabels: []
});

function _keyToLabel(value: string) {
  return value.replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(" ").map((_, i) => i == 0 ? _ : _.toLowerCase()).join(" ");
}

interface ColumnFilters {
  company: boolean;
}

export function ImportModal({ product, onUpdateProduct, isOpen, toggle }: {
  product: ProductModel,
  onUpdateProduct?: (value: DeepPartial<ProductModel>) => void,
  isOpen: boolean,
  toggle?: () => void
}) {
  const [, go] = useNavigation();
  const [uid] = useState(nextSequence());
  const [step, setStep] = useState(0);
  const [page, setPage] = useState(0);
  const [readyState, setReadyState] = useReadyState();
  const [isLoading, setIsLoading] = useState(false);
  const [filename, setFilename] = useState<string>();
  const [error, setError] = useState<string>();
  const [_input, setInput] = useState([] as Record[]);
  const [keys, setKeys] = useState<string[]>([]);
  const [count, setCount] = useState(0);
  const [previews, setPreviews] = useState<ReadonlyArray<MessageModel>>([]);
  const [labels, setLabels] = useState([] as LabelModel[]);
  const [nameKey, setNameKey] = useState("Name");
  const [emailKey, setEmailKey] = useState("Email");
  const [phoneKey, setPhoneKey] = useState("Phone");
  const [email2Key, setEmail2Key] = useState("Email 2");
  const [phone2Key, setPhone2Key] = useState("Phone 2");
  const [externalIdKey, setExternalIdKey] = useState("Reference Id");
  const [showCollege, setShowCollege] = useToggle();
  const [showCompany, setShowCompany] = useToggle();
  const [showAdditionalContacts, setShowAdditionalContacts] = useToggle();
  const [showComments, setShowComments] = useToggle();
  const [showScores, setShowScores] = useToggle();
  const [collegeKey, setCollegeKey] = useState("College");
  const [graduationKey, setGraduationKey] = useState("Graduation");
  const [gpaKey, setGPAKey] = useState("GPA");
  const [rankKey, setRankKey] = useState("Rank");
  const [companyKey, setCompanyKey] = useState("Company");
  const [titleKey, setTitleKey] = useState("Title");
  const [websiteKey, setWebsiteKey] = useState("Website");
  const [cityKey, setCityKey] = useState("City");
  const [commentKey, setCommentKey] = useState("Comment");
  const [communicationScoreKey, setCommunicationScoreKey] = useState("Communication Score");
  const [eduInput, , updateEduInput] = useUpdatableState<MembershipInputModel>({} as any);
  const [filters, , updateFilters] = useUpdatableState<ColumnFilters>({ company: false });
  const [isOpenCreateLabel, toggleCreateLabel] = useToggle();
  const [mutation] = useMutation<OperationResults>();
  const setFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]?.name) {
      const file = e.target.files?.[0];
      const reader = new FileReader();
      const filename = file.name.toLowerCase();
      if (filename.endsWith(".xlsx") || filename.endsWith(".xls")) {
        reader.addEventListener('load', () => parseExcel(reader.result as ArrayBuffer));
        reader.readAsBinaryString(file);
        setIsLoading(true);
        setError(undefined);
      } else {
        setError("Not supported");
      }
      setFilename(file.name);
      setKeys([]);
    }
  }
  const parseExcel = async (data: ArrayBuffer) => {
    const xlsx = await import("xlsx");
    const workbook = xlsx.read(data, { type: "binary" });
    const values = [] as any[];
    const keySet = new Set<string>();
    workbook.SheetNames.forEach(sheetName => {
      var rows = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName], { blankrows: false, raw: false });
      values.push(...rows);
      rows.flatMap(Object.keys).reduce((keys, key) => keys.add(key), keySet);
    });
    const keys = Array.from(keySet);
    values.forEach((_, i) => _.__index__ = i);
    setInput(values);
    setKeys(keys);
    setPage(0);
    setNameKey(keys.filter(_ => _.toLowerCase().indexOf("name") !== -1)[0] ?? "");
    setEmailKey(keys.filter(_ => _.toLowerCase().indexOf("email") !== -1)[0] ?? "");
    setPhoneKey(keys.filter(_ => _.toLowerCase().indexOf("phone") !== -1 || _.toLowerCase().indexOf("mobile") !== -1)[0] ?? "");
    setExternalIdKey(keys.filter(_ => _.toLowerCase().indexOf("reference id") !== -1)[0] ?? "");

    setEmail2Key(keys.filter(_ => _.toLowerCase().indexOf("email 2") !== -1 || _.toLowerCase().indexOf("alternate email") !== -1 || _.toLowerCase().indexOf("secondary email") !== -1)[0] ?? "");
    setPhone2Key(keys.filter(_ => _.toLowerCase().indexOf("phone 2") !== -1 || _.toLowerCase().indexOf("alternate phone") !== -1 || _.toLowerCase().indexOf("secondary phone") !== -1)[0] ?? "");

    setCollegeKey(keys.filter(_ => _.toLowerCase().indexOf("college") !== -1)[0] ?? "");
    setGraduationKey(keys.filter(_ => _.toLowerCase().indexOf("graduation") !== -1 || _.toLowerCase().indexOf("batch") !== -1)[0] ?? "");
    setGPAKey(keys.filter(_ => _.toLowerCase().indexOf("gpa") !== -1)[0] ?? "");
    setRankKey(keys.filter(_ => _.toLowerCase().indexOf("rank") !== -1)[0] ?? "");

    setCompanyKey(keys.filter(_ => _.toLowerCase().indexOf("company") !== -1)[0] ?? "");
    setTitleKey(keys.filter(_ => _.toLowerCase().indexOf("title") !== -1 || _.toLowerCase().indexOf("position") !== -1)[0] ?? "");
    setWebsiteKey(keys.filter(_ => _.toLowerCase().indexOf("website") !== -1 || _.toLowerCase().indexOf("url") !== -1)[0] ?? "");
    setCityKey(keys.filter(_ => _.toLowerCase().indexOf("city") !== -1 || _.toLowerCase().indexOf("location") !== -1)[0] ?? "");

    setCommentKey(keys.filter(_ => _.toLowerCase().indexOf("comment") !== -1 || _.toLowerCase().indexOf("remarks") !== -1)[0] ?? "");
    setCommunicationScoreKey(keys.filter(_ => _.toLowerCase().indexOf("communication score") !== -1)[0] ?? "");

    setIsLoading(false);
  }
  const predicate = (value: Record): boolean => {
    return (!filters.company || !!value[companyKey]);
  }
  const updateInputItem = (index: number, key: string, value: any) => {
    const next = _input.map(_ => _.__index__ !== index ? _ : ({ ..._, [key]: value }));
    setInput(next);
  }
  const toggleSkip = (index: number) => () => {
    updateInputItem(index, "__skip__", !_input.filter(_ => _.__index__ === index)[0].__skip__);
  }
  const skipAll = () => {
    const next = _input.map(_ => !predicate(_) ? _ : ({ ..._, __skip__: true } as Record));
    setInput(next);
  }
  const includeAll = () => {
    const next = _input.map(_ => !predicate(_) ? _ : ({ ..._, __skip__: false } as Record));
    setInput(next);
  }
  const addLabel = (key: string) => {
    setLabels([...labels.filter(_ => _.key !== key), { ..._newLabel, key }]);
  }
  const removeLabel = (key: string) => {
    setLabels([...labels.filter(_ => _.key !== key)]);
  }
  const updatedProductLabel = (product: DeepPartial<ProductModel>, label: LabelModel) => {
    onUpdateProduct?.(product);
    addLabel(label.key);
  }
  const importData = async (preview: boolean) => {
    const values = input.filter(_ => !_.__skip__).map(_ => delUndefined({
      id: _["HigherKnowledge Id"] || undefined,
      name: nameKey && `${_[nameKey] || ""}` || undefined,
      email: emailKey && `${_[emailKey] || ""}` || undefined,
      phone: phoneKey && `${_[phoneKey] || ""}` || undefined,
      email2: !showAdditionalContacts ? undefined : email2Key && `${_[email2Key] || ""}` || undefined,
      phone2: !showAdditionalContacts ? undefined : phone2Key && `${_[phone2Key] || ""}` || undefined,
      externalId: !showReferenceId ? undefined : externalIdKey && `${_[externalIdKey] || ""}` || undefined,
      college: !importCollege ? undefined : collegeKey === _inputKey ? eduInput.organizationName : collegeKey && `${_[collegeKey] || ""}` || undefined,
      collegeId: !importCollege ? undefined : collegeKey === _inputKey ? eduInput.organizationId : undefined,
      graduation: !importCollege ? undefined : graduationKey === _inputKey ? eduInput.endOn : graduationKey && _[graduationKey] && moment(`${_[graduationKey]}`).isValid() && `${moment(`${_[graduationKey]}`).format("YYYY-MM-DD")}` || undefined,
      gpa: !importCollege ? undefined : gpaKey && `${_[gpaKey] || ""}` || undefined,
      rank: !importCollege ? undefined : rankKey && `${_[rankKey] || ""}` || undefined,
      company: !importCompany ? undefined : companyKey && `${_[companyKey] || ""}` || undefined,
      title: !importCompany ? undefined : titleKey && `${_[titleKey] || ""}` || undefined,
      companyWebsite: !importCompany ? undefined : websiteKey && `${_[websiteKey] || ""}` || undefined,
      companyLocation: !importCompany ? undefined : cityKey && `${_[cityKey] || ""}` || undefined,
      comment: !importComments ? undefined : commentKey && `${_[commentKey] || ""}` || undefined,
      communicationScore: !importScores ? undefined : communicationScoreKey && `${_[communicationScoreKey] || ""}` || undefined,
    }));
    const l = labels.map(_ => _.key);
    const result = await mutation(_populateOrders, { pid: product.id, values, labels: l, preview }, { setReadyState });
    if (preview) {
      setCount(result.product.orders.count);
      setPreviews(result.product.orders.preview);
      setStep(2);
    } else {
      const collection = result.product.orders.commit.map(o => o.id).join(",");
      cancel();
      go(`/my/listings/${product.id}/candidates/${result.product.orders.commit[0].id}#collection=${collection}`);
    }
  }
  const updateName = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, nameKey, e.currentTarget.value);
  const updateEmail = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, emailKey, e.currentTarget.value);
  const updateEmail2 = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, email2Key, e.currentTarget.value);
  const updatePhone = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, phoneKey, e.currentTarget.value);
  const updatePhone2 = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, phone2Key, e.currentTarget.value);
  const updateExternalId = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, externalIdKey, e.currentTarget.value);
  const updateCollege = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, collegeKey, e.currentTarget.value);
  const updateGraduation = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, graduationKey, e.currentTarget.value);
  const updateGPA = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, gpaKey, e.currentTarget.value);
  const updateRank = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, rankKey, e.currentTarget.value);
  const updateCompany = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, companyKey, e.currentTarget.value);
  const updateTitle = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, titleKey, e.currentTarget.value);
  const updateWebsite = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, websiteKey, e.currentTarget.value);
  const updateCity = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, cityKey, e.currentTarget.value);
  const updateCommunicationScore = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, communicationScoreKey, e.currentTarget.value);
  const updateComment = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => updateInputItem(index, commentKey, e.currentTarget.value);
  const cancel = () => {
    toggle?.();
    setStep(0);
    setPage(0);
    setKeys([]);
    setInput([]);
    setFilename(undefined);
    setError(undefined);
  }

  const importCollege = showCollege && !!collegeKey;
  const importCompany = showCompany && !!companyKey;
  const importComments = showComments && !!commentKey;
  const importScores = showScores && !!communicationScoreKey;

  const pageSize = 15;
  const labelOptions = product.details.labels.filter(_ => !labels.some(l => l.key === _.key));
  const isProdctContributor = product.policy.role === ResourceRole.Owner || product.policy.role === ResourceRole.Contributor;
  const input = _input.filter(predicate);
  const maxPage = Math.floor(input.length / pageSize);
  const inputPage = input.slice(page * pageSize, (page + 1) * pageSize);
  const countIncluded = input.filter(_ => !_.__skip__).length;
  const isAllIncluded = input.every(_ => !_.__skip__);
  const isValid = (!emailKey || input.every(_ => _.__skip__ || !_[emailKey] || isEmail(_[emailKey])))
    && (!phoneKey || input.every(_ => _.__skip__ || !_[phoneKey] || isPhone(_[phoneKey])))
    && (!showAdditionalContacts || !email2Key || input.every(_ => _.__skip__ || !_[email2Key] || isEmail(_[email2Key])))
    && (!showAdditionalContacts || !phone2Key || input.every(_ => _.__skip__ || !_[phone2Key] || isPhone(_[phone2Key])))
    && (!importCollege || !collegeKey || !graduationKey || input.every(_ => _.__skip__ || !_[graduationKey] || moment(`${_[graduationKey]}`).isValid()))
    && (collegeKey !== _inputKey || eduInput.organizationId)
    && (graduationKey !== _inputKey || !!eduInput.endOn)
    && (!importCompany || !websiteKey || input.every(_ => _.__skip__ || !_[websiteKey] || isUrl(_[websiteKey])))
    && countIncluded > 0;
  const showReferenceId = !!product.details.ordersCustomerExternalIdMessage;

  return (
    <Modal className={step === 1 ? "w-max-90" : ""} isOpen={isOpen}>
      <div className="modal-header d-flex flex-row align-items-baseline">
        <h5 className="modal-title">Import {step === 1 ? countIncluded : ""} applicants</h5>
        {step === 1 && maxPage > 0 &&
          <div className="ml-auto d-flex align-items-baseline">
            <Button className="py-0" color="link" disabled={page === 0} onClick={() => setPage(page - 1)}><FontAwesomeIcon icon="chevron-left" /></Button>
            <span>{page * pageSize + 1} &ndash; {Math.min(page * pageSize + pageSize, input.length)} of {input.length}</span>
            <Button className="py-0" color="link" disabled={page === maxPage} onClick={() => setPage(page + 1)}><FontAwesomeIcon icon="chevron-right" /></Button>
          </div>
        }
      </div>
      {step === 0 &&
        <ModalBody className="pt-0">
          <LoadingProgress flush show={isLoading} />
          <Form className="mt-3">
            <FormGroup label="File" feedback={error} help="Upload an Excel file containing name, email, phone number and education information of applicants.">
              <CustomInput type="file" id={uid} name="file" className="text-nowrap text-truncate" label={<Label className={filename ? "" : "text-muted"}>{filename || "Select your file here"}</Label>} onChange={setFile} />
            </FormGroup>
            {keys.length > 0 &&
              <>
                <Row form>
                  <Col xs={3} className="pt-2 font-weight-bold">Field</Col>
                  <Col className="pt-2 font-weight-bold">Column in file</Col>
                </Row>
                {showReferenceId &&
                  <Row form>
                    <Col xs={3} className="pt-2">Reference Id</Col>
                    <Col>
                      <CustomInput id={`${uid}.0`} type="select" value={externalIdKey} onChange={e => setExternalIdKey(e.currentTarget.value)}>
                        <option value={""} />
                        {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                      </CustomInput>
                    </Col>
                  </Row>
                }
                <Row form>
                  <Col xs={3} className="pt-2">Name</Col>
                  <Col>
                    <CustomInput id={`${uid}.1`} type="select" value={nameKey} onChange={e => setNameKey(e.currentTarget.value)}>
                      <option value={""} />
                      {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                    </CustomInput>
                  </Col>
                </Row>
                <Row form>
                  <Col xs={3} className="pt-2">Email</Col>
                  <Col>
                    <CustomInput id={`${uid}.2`} label="Email" type="select" value={emailKey} onChange={e => setEmailKey(e.currentTarget.value)}>
                      <option value={""} />
                      {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                    </CustomInput>
                  </Col>
                </Row>
                <Row form>
                  <Col xs={3} className="pt-2">Phone</Col>
                  <Col>
                    <CustomInput id={`${uid}.3`} type="select" value={phoneKey} onChange={e => setPhoneKey(e.currentTarget.value)}>
                      <option value={""} />
                      {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                    </CustomInput>
                  </Col>
                </Row>
                <CustomInput id="s-altcontacts" type="switch" className="mt-3" checked={showAdditionalContacts} onChange={() => setShowAdditionalContacts(!showAdditionalContacts)} label="Import additional contact information" />
                <Collapse isOpen={showAdditionalContacts} className="mt-1">
                  <Row form>
                    <Col xs={3} className="pt-2">Email 2</Col>
                    <Col>
                      <CustomInput id={`${uid}.4`} type="select" value={email2Key} onChange={e => setEmail2Key(e.currentTarget.value)}>
                        <option value={""} />
                        {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                      </CustomInput>
                    </Col>
                  </Row>
                  <Row form>
                    <Col xs={3} className="pt-2">Phone 2</Col>
                    <Col>
                      <CustomInput id={`${uid}.5`} type="select" value={phone2Key} onChange={e => setPhone2Key(e.currentTarget.value)}>
                        <option value={""} />
                        {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                      </CustomInput>
                    </Col>
                  </Row>
                </Collapse>
                <CustomInput id="s-college" type="switch" className="mt-3" checked={showCollege} onChange={() => setShowCollege(!showCollege)} label="Import education information" />
                <Collapse isOpen={showCollege} className="mt-1">
                  <Row form>
                    <Col xs={3} className="pt-2">College</Col>
                    <Col>
                      <CustomInput id={`${uid}.6`} type="select" value={collegeKey} onChange={e => setCollegeKey(e.currentTarget.value)}>
                        <option value={""} />
                        {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                        <option value={_inputKey}>Enter separately</option>
                      </CustomInput>
                    </Col>
                  </Row>
                  <Collapse isOpen={importCollege}>
                    <Row form>
                      <Col xs={3} className="pt-2 pl-3">Graduation</Col>
                      <Col>
                        <CustomInput id={`${uid}.7`} type="select" value={graduationKey} onChange={e => setGraduationKey(e.currentTarget.value)}>
                          <option value={""} />
                          {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                          <option value={_inputKey}>Enter separately</option>
                        </CustomInput>
                      </Col>
                    </Row>
                    <Row form>
                      <Col xs={3} className="pt-2 pl-3">GPA</Col>
                      <Col>
                        <CustomInput id={`${uid}.8`} type="select" value={gpaKey} onChange={e => setGPAKey(e.currentTarget.value)}>
                          <option value={""} />
                          {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                        </CustomInput>
                      </Col>
                    </Row>
                    <Row form>
                      <Col xs={3} className="pt-2 pl-3">Rank</Col>
                      <Col>
                        <CustomInput id={`${uid}.9`} type="select" value={rankKey} onChange={e => setRankKey(e.currentTarget.value)}>
                          <option value={""} />
                          {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                        </CustomInput>
                      </Col>
                    </Row>
                  </Collapse>
                </Collapse>
                <CustomInput id="s-company" type="switch" className="mt-3" checked={showCompany} onChange={() => setShowCompany(!showCompany)} label="Import company information" />
                <Collapse isOpen={showCompany} className="mt-1">
                  <Row form>
                    <Col xs={3} className="pt-2">Company</Col>
                    <Col>
                      <CustomInput id={`${uid}.10`} type="select" value={companyKey} onChange={e => setCompanyKey(e.currentTarget.value)}>
                        <option value={""} />
                        {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                      </CustomInput>
                    </Col>
                  </Row>
                  <Collapse isOpen={!!companyKey}>
                    <Row form>
                      <Col xs={3} className="pt-2 pl-3">Title</Col>
                      <Col>
                        <CustomInput id={`${uid}.11`} type="select" value={titleKey} onChange={e => setTitleKey(e.currentTarget.value)}>
                          <option value={""} />
                          {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                        </CustomInput>
                      </Col>
                    </Row>
                    <Row form>
                      <Col xs={3} className="pt-2 pl-3">Location</Col>
                      <Col>
                        <CustomInput id={`${uid}.12`} type="select" value={cityKey} onChange={e => setCityKey(e.currentTarget.value)}>
                          <option value={""} />
                          {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                        </CustomInput>
                      </Col>
                    </Row>
                    <Row form>
                      <Col xs={3} className="pt-2 pl-3">Website</Col>
                      <Col>
                        <CustomInput id={`${uid}.13`} type="select" value={websiteKey} onChange={e => setWebsiteKey(e.currentTarget.value)}>
                          <option value={""} />
                          {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                        </CustomInput>
                      </Col>
                    </Row>
                  </Collapse>
                </Collapse>
                {product.details.ordersStages.includes(OrderStage.ScreeningScheduled) &&
                  <>
                    <CustomInput id="s-scores" type="switch" className="mt-3" checked={showScores} onChange={() => setShowScores(!showScores)} label="Import scores" />
                    <Collapse isOpen={showScores} className="mt-1">
                      <Row form>
                        <Col xs={3} className="pt-2">Communication</Col>
                        <Col>
                          <CustomInput id={`${uid}.15`} type="select" value={communicationScoreKey} onChange={e => setCommunicationScoreKey(e.currentTarget.value)}>
                            <option value={""} />
                            {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                          </CustomInput>
                        </Col>
                      </Row>
                    </Collapse>
                  </>
                }
                <CustomInput id="s-comments" type="switch" className="mt-3" checked={showComments} onChange={() => setShowComments(!showComments)} label="Import comments" />
                <Collapse isOpen={showComments} className="mt-1">
                  <Row form>
                    <Col xs={3} className="pt-2">Comments</Col>
                    <Col>
                      <CustomInput id={`${uid}.14`} type="select" value={commentKey} onChange={e => setCommentKey(e.currentTarget.value)}>
                        <option value={""} />
                        {keys.map((_, i) => <option key={i} value={_}>Column {i + 1}: {_}</option>)}
                      </CustomInput>
                    </Col>
                  </Row>
                </Collapse>
              </>
            }
          </Form>
        </ModalBody >
      }
      {
        step === 1 &&
        <Form>
          <fieldset className="w-100">
            <Table className="w-100 bb-1">
              <thead>
                <tr>
                  <th className="p-0 bb-0 br-1 align-middle text-center">
                    <Button className="px-3" color="link" onClick={isAllIncluded ? skipAll : includeAll}>
                      <FontAwesomeIcon size="lg" icon={["far", isAllIncluded ? "check-square" : "square"]} />
                    </Button>
                  </th>
                  {externalIdKey && <th className="bb-0 br-1">Reference Id</th> || null}
                  {nameKey && <th className="bb-0 br-1">Name</th> || null}
                  {emailKey && <th className="bb-0 br-1">Email</th> || null}
                  {phoneKey && <th className="bb-0 br-1">Phone</th> || null}
                  {showAdditionalContacts && email2Key && <th className="bb-0 br-1">Email 2</th> || null}
                  {showAdditionalContacts && phone2Key && <th className="bb-0 br-1">Phone 2</th> || null}
                  {importCollege && collegeKey !== _inputKey && <th className="bb-0 br-1"> College</th> || null}
                  {importCollege && graduationKey && graduationKey !== _inputKey && <th className="bb-0 br-1">Graduation</th> || null}
                  {importCollege && gpaKey && <th className="bb-0 br-1">GPA</th> || null}
                  {importCollege && rankKey && <th className="bb-0 br-1">Rank</th> || null}
                  {importCompany &&
                    <th className="hover-container bb-0 br-1 d-flex">
                      Company
                      <UncontrolledDropdown>
                        <DropdownToggle size="sm" color="link" className={`ml-2 p-0 text-secondary ${filters.company ? "" : "opacity-15"}`}><FontAwesomeIcon icon="filter" /></DropdownToggle>
                        <DropdownMenu>
                          <CustomDropdownItem checked={filters.company} onClick={() => updateFilters({ company: !filters.company })}>Hide blanks</CustomDropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </th> || null
                  }
                  {importCompany && titleKey && <th className="bb-0 br-1">Title</th>}
                  {importCompany && cityKey && <th className="bb-0 br-1">Location</th>}
                  {importCompany && websiteKey && <th className="bb-0 br-1">Website</th>}
                  {importScores && communicationScoreKey && <th className="bb-0 br-1">Communication</th>}
                  {importComments && commentKey && <th className="bb-0 br-1">Comment</th>}
                </tr>
              </thead>
              <tbody>
                {inputPage.map((_, i) =>
                  <tr key={i}>
                    <td className="p-0 br-1 align-middle text-center">
                      <Button className="px-3" color="link" onClick={toggleSkip(_.__index__)}>
                        <FontAwesomeIcon size="lg" icon={["far", !_.__skip__ ? "check-square" : "square"]} />
                      </Button>
                    </td>
                    {externalIdKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[externalIdKey] || ""} onChange={updateExternalId(_.__index__)} />
                      </td> || null
                    }
                    {nameKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[nameKey] || ""} onChange={updateName(_.__index__)} />
                      </td> || null
                    }
                    {emailKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input invalid={!!_[emailKey] && !isEmail(_[emailKey])} value={_[emailKey] || ""} onChange={updateEmail(_.__index__)} />
                      </td> || null
                    }
                    {phoneKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input invalid={!!_[phoneKey] && !isPhone(_[phoneKey])} value={_[phoneKey] || ""} onChange={updatePhone(_.__index__)} />
                      </td> || null
                    }
                    {showAdditionalContacts && email2Key &&
                      <td className="p-0 br-1 bg-input">
                        <Input invalid={!!_[email2Key] && !isEmail(_[email2Key])} value={_[email2Key] || ""} onChange={updateEmail2(_.__index__)} />
                      </td> || null
                    }
                    {showAdditionalContacts && phone2Key &&
                      <td className="p-0 br-1 bg-input">
                        <Input invalid={!!_[phone2Key] && !isPhone(_[phone2Key])} value={_[phone2Key] || ""} onChange={updatePhone2(_.__index__)} />
                      </td> || null
                    }
                    {importCollege && collegeKey !== _inputKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[collegeKey] || ""} onChange={updateCollege(_.__index__)} />
                      </td> || null
                    }
                    {importCollege && graduationKey && graduationKey !== _inputKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input invalid={!!_[graduationKey] && !moment(`${_[graduationKey]}`).isValid()} value={_[graduationKey] || ""} onChange={updateGraduation(_.__index__)} />
                      </td> || null
                    }
                    {importCollege && gpaKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[gpaKey] || ""} onChange={updateGPA(_.__index__)} />
                      </td> || null
                    }
                    {importCollege && rankKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[rankKey] || ""} onChange={updateRank(_.__index__)} />
                      </td> || null
                    }
                    {importCompany && companyKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[companyKey] || ""} onChange={updateCompany(_.__index__)} />
                      </td> || null
                    }
                    {importCompany && titleKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[titleKey] || ""} onChange={updateTitle(_.__index__)} />
                      </td> || null
                    }
                    {importCompany && cityKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[cityKey] || ""} onChange={updateCity(_.__index__)} />
                      </td> || null
                    }
                    {importCompany && websiteKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input invalid={!!_[websiteKey] && !isUrl(_[websiteKey])} value={_[websiteKey] || ""} onChange={updateWebsite(_.__index__)} />
                      </td> || null
                    }
                    {importScores && communicationScoreKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[communicationScoreKey] || ""} onChange={updateCommunicationScore(_.__index__)} />
                      </td> || null
                    }
                    {importComments && commentKey &&
                      <td className="p-0 br-1 bg-input">
                        <Input value={_[commentKey] || ""} onChange={updateComment(_.__index__)} />
                      </td> || null
                    }
                  </tr>
                )}
              </tbody>
            </Table>
            <ModalBody className="pt-0">
              {(collegeKey === _inputKey || graduationKey === _inputKey) &&
                <Row form>
                  {collegeKey === _inputKey &&
                    <Col>
                      <FormGroup label="Institution" required>
                        <DatalistInput types={[ReferenceDataType.OrganizationName]} placeholder="Enter college name here" value={eduInput.organizationName || ""} onUpdate={(organizationId, organizationName) => updateEduInput({ organizationId, organizationName })} />
                      </FormGroup>
                    </Col>
                  }
                  {graduationKey === _inputKey &&
                    <Col>
                      <FormGroup label='Graduation' required>
                        <DatePicker className="form-control" selected={toLocalDate(eduInput.endOn)} showMonthYearPicker dateFormat="MMMM yyyy" onChange={v => updateEduInput({ endOn: toUtcString(v) || undefined })} />
                      </FormGroup>
                    </Col>
                  }
                </Row>
              }
              <FormGroup label='Labels'>
                <div className="p-2 lh-1 bg-input w-100">
                  {product.details.labels.filter(_ => labels.some(l => l.key === _.key)).map((_, i) => <CardBadge key={i} className="cursor-pointer" size="inline" value={_.value} style={{ backgroundColor: _.color || '#f8f9fa', minWidth: "1.75rem" }} remove onClick={() => removeLabel(_.key)} />)}
                  {labelOptions.length > 0 &&
                    <UncontrolledDropdown tag="span" className="lh-1">
                      <DropdownToggle tag="span" className="mr-1 badge badge-white cursor-pointer">
                        <FontAwesomeIcon icon="plus" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem tag="div" className="no-hover">
                          <div className="d-flex flex-wrap" style={{ minWidth: "300px" }}>
                            {labelOptions.map((_, i) => <CardBadge key={i} className="cursor-pointer" size="sm" value={_.value} style={{ backgroundColor: _.color || '#f8f9fa', minWidth: "1.75rem" }} onClick={() => addLabel(_.key)} />)}
                            {isProdctContributor &&
                              <Button className="mr-1 mt-1 b-0 p-0 badge badge-white" color="link" size="sm" onClick={toggleCreateLabel}>
                                <FontAwesomeIcon icon="plus" />
                              </Button>
                            }
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                  {labelOptions.length === 0 && isProdctContributor &&
                    <span className="mr-1 badge badge-white cursor-pointer" onClick={toggleCreateLabel}>
                      <FontAwesomeIcon icon="plus" />
                    </span>
                  }
                </div>
              </FormGroup>
            </ModalBody>
          </fieldset>
        </Form>
      }
      {step === 2 && previews.length > 0 && <PreviewModalBody values={previews} emptyHelp="No messages will be sent. Update listing rules to automatically send updates." />}
      {step === 2 && !previews.length &&
        <ModalBody>
          <strong>{count}</strong> applicants will be imported. Do you want to continue?
        </ModalBody>
      }
      {isProdctContributor && <CreateLabelModal isOpen={isOpenCreateLabel} toggle={toggleCreateLabel} value={product} onUpdate={updatedProductLabel} />}
      <ModalFooter>
        {step === 1 && <Button color="link" onClick={() => setStep(0)}>Back</Button>}
        {step === 2 && <Button color="link" onClick={() => setStep(1)}>Back</Button>}
        <Button className="ml-auto" color="link" onClick={cancel}>Cancel</Button>
        {step === 0 && <Button color="primary" onClick={() => setStep(1)}>Next</Button>}
        {step === 1 && <MutationButton readyState={readyState} disabled={!isValid} color="primary" onClick={() => importData(true)}>Next</MutationButton>}
        {step === 2 && <MutationButton readyState={readyState} disabled={!isValid} color="primary" onClick={() => importData(false)}>Import</MutationButton>}
      </ModalFooter>
    </Modal >
  );
}

export default ({ values, view, title: _title, product: _product, previews: _previews, onSubmit, eventKey, showPreview: _showPreview, onUpdate, onUpdateProduct, isOpen, toggle }: {
  values: ReadonlyArray<OrderModel>,
  view: View,
  title?: string,
  product: ProductModel,
  isOpen?: boolean,
  toggle?: () => void,
  eventKey?: string,
  showPreview?: boolean,
  previews?: ReadonlyArray<MessageModel>,
  onUpdate?: (values: ReadonlyArray<DeepPartial<OrderModel> & { id: string }>) => void,
  onUpdateProduct?: (value: DeepPartial<ProductModel>) => void,
  onSubmit?: (view: View, id: string | undefined, details: OrderDetailsInputModel, expiresOn: DateTime) => void,
}) => {
  const value = values.length == 1 ? values[0] : undefined;
  const product = _product || values[0].product;
  const confirmation = useConfirmation();
  const [t] = useTranslation();
  const [, go] = useNavigation();
  const [uid] = useState(nextSequence());
  const [usernames, setUsernames] = useState("");
  const [readyState, setReadyState] = useReadyState();
  const [isOpenCreateLabel, toggleCreateLabel] = useToggle();
  const [isOpenPreview, setIsOpenPreview] = useState((_previews?.length ?? 0) > 0 && (_previews?.[0]?.requiredFields?.length ?? 0) == 0);
  const [previews, setPreviews] = useState<ReadonlyArray<MessageModel>>(_previews || []);
  const [input, setInput] = useState(_toOrderDetailsInputModel(value, product, t));
  const [data, setData, updateData] = useUpdatableState<ReadonlyArray<MapEntry>>([]);
  const [expiresOn, setExpiresOn] = useState<string | undefined | null>(value?.expiresOn);
  const reset = () => {
    setInput(_toOrderDetailsInputModel(value, product, t));
    setIsOpenPreview((_previews?.length ?? 0) > 0 && (_previews?.[0]?.requiredFields?.length ?? 0) == 0);
    setExpiresOn(value?.expiresOn);
    setPreviews(_previews || []);
  }
  useEffect(reset, [value?.id, view, _previews]);
  const [mutation] = useMutation<OperationResults>();
  const updateInput = (props: Partial<OrderDetailsInputModel>) => setInput({ ...input, ...props });
  const change = async () => {
    const query = updateProductOrdersQuery[view];
    const ids = values.map(_ => _.id);
    const tokens = values.map(_ => _.policy?.addToken).filter(_ => _);
    const { id: pid } = product;
    const result = await mutation(query, { ids, tokens, pid, usernames, value: input, expiresOn, key: eventKey, data, preview: false }, { setReadyState })
    if (view === "add" || view === "addShortlist") {
      // Update product before deleting order in case product was nested inside order.
      onUpdateProduct?.(result.product.commit);
      onUpdate?.(values.map(_ => ({ id: _.id, ...setDelete(_) })));
      const collection = result.product.orders.commit.map(_ => _.id).join(",");
      const hash = `#collection=${collection}&up=${encodeURI(`${window.location.pathname}${window.location.search}`)}`;
      go(window.location.pathname + `/candidates/${result.product.orders.commit[0].id}` + hash)
    } else {
      onUpdateProduct?.(result.product.commit);
      onUpdate?.(result.product.orders.commit.map(o => ({ ...o, details: setReplace(o.details) } as OrderModel)));
    }
    toggle?.();
  }
  const addLabel = (key: string) => {
    updateInput({
      addLabels: [...input.addLabels, { ..._newLabel, key }],
      removeLabels: input.removeLabels.filter(_ => _.key !== key)
    });
  }
  const removeLabel = (key: string) => {
    updateInput({
      addLabels: input.addLabels.filter(_ => _.key !== key),
      removeLabels: [...input.removeLabels, { ..._newLabel, key }]
    });
  }
  const updatedProductLabel = (product: DeepPartial<ProductModel>, label: LabelModel) => {
    onUpdateProduct?.(product);
    addLabel(label.key);
  }
  const loadPreview = async () => {
    const query = updateProductOrdersQuery[view];
    const ids = values.map(_ => _.id);
    const tokens = values.map(_ => _.policy?.addToken).filter(_ => _);
    const { id: pid } = product;
    const result = await mutation(query, { ids, tokens, pid, usernames, value: input, expiresOn, key: eventKey, data, preview: true })
    setPreviews(result.product.orders.preview)
  }
  const cancel = () => {
    reset();
    toggle?.();
  }
  const togglePreview = async () => {
    !isOpenPreview && await loadPreview();
    setIsOpenPreview(!isOpenPreview);
  }
  const saveData = (name: string) => {
    const json = window.btoa(JSON.stringify({ data }));
    const dataUri = `data:application/json;base64,${json}`;
    download(dataUri, `${name}.json`);
  }
  const loadData = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener("change", async () => {
      const file = input.files?.[0];
      if (file) {
        const json = await file.text();
        const value = JSON.parse(json) as { data: MapEntry[] };
        const data = value.data?.filter(v => requiredFields.some(f => f.key === v.key)) ?? [];
        setData(data);
      }
    });
    input.click();
  }

  const now = new Date();
  const startOn = toLocalDate(input.startOn);
  const endOn = toLocalDate(input.endOn);

  const productLabels = product.details.labels?.filter(_ => !_.isArchived) ?? [];
  const requiredFields = previews?.[0]?.requiredFields;

  const hasLocation = product.details.subtype !== ProductType.EmploymentRemote || !!input.location;
  const showEndOn = product.details.duration !== "P65535D";
  const showMailRecipient = view === "add" && (values?.length ?? 0) === 0;
  const hasAssessments = product.details.ordersJobsMaxCount !== 0;
  const showStartOn = view === "offer" || view === "change";
  const showOffer = view === "offer";
  const showLocation = view === "offer" && hasLocation;
  const showScreening = view === "screen";
  const showInterview = view === "interview";
  const showChange = view === "change";
  const showName = values?.length === 1;
  const showAttachments = values?.length === 1 && view === "change";
  const showExternalId = values?.length === 1 && !!product.details.ordersCustomerExternalIdMessage;
  const showLabels = productLabels.length > 0 && view !== "event";
  const showInstructions = view === "add" || view === "addShortlist" || view === "screen" || view === "interview" || view === "offer";
  const showFields = (requiredFields?.length ?? 0) > 0;
  const showInput = showEndOn || showMailRecipient || showStartOn || showOffer || showLocation || showScreening || showInterview || showChange || showInstructions || showFields;
  const isRecipientsValid = usernames && usernames.split(",").every(r => isEmail(r) || isPhone(r)) || false;
  const showPreview = _showPreview && ["add", "addShortlist", "screen", "interview", "offer", "event"].includes(view as string);
  const recipientNames = values.length == 1 ? value?.details?.customerName ?? value?.customer?.name
    : `${values.length} applicants`;
  const title = _title ?? (
    view === "add" ? `Add applicants`
      : view === "addShortlist" ? `Save applicants`
        : view === "screen" ? `Screen ${recipientNames}`
          : view === "interview" ? `Interview ${recipientNames}`
            : view === "offer" ? `Offer ${recipientNames}`
              : view === "change" ? `Update ${recipientNames}`
                : `Message ${recipientNames}`
  );
  const isValid = (!showMailRecipient || isRecipientsValid);
  const isChange = view === "change";

  const labelsAdded = productLabels.map(_ => {
    const didAdd = input.addLabels.some(a => a.key == _.key);
    const didRemove = input.removeLabels.some(a => a.key == _.key);
    const hasAll = values.length > 0 && values.every(o => o.details.labels.some(l => l.key === _.key));
    return [_, (didAdd || hasAll) && !didRemove] as [LabelModel, boolean];
  });
  const labels = labelsAdded.filter(_ => _[1]).map(_ => _[0]);
  const labelOptions = labelsAdded.filter(_ => !_[1]).map(_ => _[0]);
  const isProdctContributor = product.policy.role === ResourceRole.Owner || product.policy.role === ResourceRole.Contributor;

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-header d-flex flex-row align-items-baseline">
        <h5 className="mb-0 mr-auto">{title}</h5>
        {showPreview && showInput && <CustomInput id={uid} className="ml-3 text-nowrap" type="switch" disabled={!isOpenPreview && !isValid} label="Preview" checked={isOpenPreview} onChange={togglePreview} />}
      </div>
      {!isOpenPreview &&
        <ModalBody>
          <Form>
            <fieldset>
              {showMailRecipient &&
                <Row form>
                  <Col>
                    <FormGroup label='Applicant name' maxLength={140} value={input.customerName}>
                      <ValidatingInput placeholder="Enter applicant name here" onUpdate={customerName => updateInput({ customerName })} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup label='Applicant email'>
                      <Input value={usernames} placeholder="Enter applicant email here" onChange={e => setUsernames(e.currentTarget.value)} />
                    </FormGroup>
                  </Col>
                </Row>
              }
              {showName &&
                <FormGroup label='Applicant name' maxLength={140} value={input.customerName}>
                  <ValidatingInput placeholder="Enter applicant name here" onUpdate={customerName => updateInput({ customerName })} />
                </FormGroup>
              }
              {showExternalId &&
                <FormGroup label='Reference Id' value={input.customerExternalId}>
                  <ValidatingInput onUpdate={customerExternalId => updateInput({ customerExternalId })} />
                </FormGroup>
              }
              {showStartOn &&
                <Row form>
                  <Col>
                    <FormGroup label='Start date'>
                      <DatePicker className="form-control" selected={startOn} selectsStart startDate={startOn} endDate={endOn} dateFormat="MMMM d, yyyy" minDate={now} onChange={v => updateInput({ startOn: toUtcString(v) ?? "" })} />
                    </FormGroup>
                  </Col>
                  {showEndOn &&
                    <Col>
                      <FormGroup label='End date'>
                        <DatePicker className="form-control" selected={endOn} selectsEnd startDate={startOn} endDate={endOn} dateFormat="MMMM d, yyyy" minDate={now} onChange={v => updateInput({ endOn: toUtcString(v) ?? "" })} />
                      </FormGroup>
                    </Col>
                  }
                </Row>
              }
              {showLocation &&
                <FormGroup label='Location' maxLength={280} value={input.location}>
                  <ValidatingInput onUpdate={location => updateInput({ location })} />
                </FormGroup>
              }
              {showScreening &&
                <>
                  <Row form>
                    <Col xs={8}>
                      <FormGroup label='Screening date'>
                        <DatePicker className="form-control" selected={toLocalDate(input.screeningOn)} dateFormat="MMMM d, yyyy 'at' h:mm aa" minDate={now} showTimeInput onChange={v => updateInput({ screeningOn: toUtcString(v) ?? "" })} />
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup label='Duration'>
                        <select className="custom-select" value={input.screeningDuration || ""} onChange={e => updateInput({ screeningDuration: e.currentTarget.value })}>
                          <option value=""></option>
                          <option value="PT15M">15 minutes</option>
                          <option value="PT30M">30 minutes</option>
                          <option value="PT1H">1 hour</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup label='Conference' maxLength={140} value={input.screeningLocation}>
                    <ValidatingInput placeholder="Enter phone or video conference details here" onUpdate={screeningLocation => updateInput({ screeningLocation })} />
                  </FormGroup>
                </>
              }
              {showInterview &&
                <>
                  <Row form>
                    <Col xs={8}>
                      <FormGroup label='Interview date'>
                        <DatePicker className="form-control" selected={toLocalDate(input.interviewOn)} dateFormat="MMMM d, yyyy 'at' h:mm aa" minDate={now} showTimeInput onChange={v => updateInput({ interviewOn: toUtcString(v) ?? "" })} />
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup label='Duration'>
                        <select className="custom-select" value={input.interviewDuration || ""} onChange={e => updateInput({ interviewDuration: e.currentTarget.value })}>
                          <option value=""></option>
                          <option value="PT30M">30 minutes</option>
                          <option value="PT1H">1 hour</option>
                          <option value="PT2H">2 hours</option>
                          <option value="PT4H">4 hours</option>
                          <option value="P1D">All day</option>
                          <option value="P2D">2 days</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup label='Location' maxLength={280} value={input.interviewLocation}>
                    <ValidatingInput placeholder="Enter interview location here" onUpdate={interviewLocation => updateInput({ interviewLocation })} />
                  </FormGroup>
                </>
              }
              {showOffer &&
                <FormGroup label='Offer expires'>
                  <DatePicker className="form-control" selected={toLocalDate(input.offerExpiresOn)} dateFormat="MMMM d, yyyy" minDate={now} onChange={v => updateInput({ offerExpiresOn: toUtcString(v) ?? "" })} />
                </FormGroup>
              }
              {showChange && hasAssessments &&
                <FormGroup label='Assessments due'>
                  <DatePicker className="form-control" selected={toLocalDate(expiresOn)} dateFormat="MMMM d, yyyy 'at' h:mm aa" minDate={now} showTimeInput onChange={v => setExpiresOn(toUtcString(v))} />
                </FormGroup>
              }
              {showInstructions &&
                <FormGroup label='Instructions' maxLength={700} value={input.supplierDescription}>
                  <ValidatingInput type="textarea" rows={3} placeholder="Provide any additional instructions here" onUpdate={supplierDescription => updateInput({ supplierDescription })} />
                </FormGroup>
              }
              {showAttachments && value &&
                <AttachmentsInputText value={value} onUpdate={_ => onUpdate?.([{ ..._, id: value.id }])} label="Additional documents" />
              }
              {showLabels &&
                <FormGroup label='Labels'>
                  <div className="p-2 lh-1 bg-input w-100">
                    {labels.map((_, i) => <CardBadge key={i} className="cursor-pointer" size="inline" value={_.value} style={{ backgroundColor: _.color || '#f8f9fa', minWidth: "1.75rem" }} remove onClick={() => removeLabel(_.key)} />)}
                    {labelOptions.length > 0 &&
                      <UncontrolledDropdown tag="span" className="lh-1">
                        <DropdownToggle tag="span" className="mr-1 badge badge-white cursor-pointer">
                          <FontAwesomeIcon icon="plus" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="div" className="no-hover">
                            <div className="d-flex flex-wrap" style={{ minWidth: "300px" }}>
                              {labelOptions.map((_, i) => <CardBadge key={i} className="cursor-pointer" size="sm" value={_.value} style={{ backgroundColor: _.color || '#f8f9fa', minWidth: "1.75rem" }} onClick={() => addLabel(_.key)} />)}
                              {isProdctContributor &&
                                <Button className="mr-1 mt-1 b-0 p-0 badge badge-white" color="link" size="sm" onClick={toggleCreateLabel}>
                                  <FontAwesomeIcon icon="plus" />
                                </Button>
                              }
                            </div>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    }
                  </div>
                </FormGroup>
              }
              {showFields && requiredFields?.map((_, i) =>
                <FormGroup key={i} label={_keyToLabel(_.key)} help={_.help}>
                  <ValidatingInput placeholder={_.placeholder} value={data.filter(d => d.key === _.key)[0]?.value || ""} onUpdate={value => updateData([{ key: _.key, value }])} />
                </FormGroup>
              )}
            </fieldset>
          </Form>
        </ModalBody>
      }
      {isProdctContributor && <CreateLabelModal isOpen={isOpenCreateLabel} toggle={toggleCreateLabel} value={product} onUpdate={updatedProductLabel} />}
      {isOpenPreview && <PreviewModalBody values={previews} emptyHelp="No messages will be sent. Update listing rules to automatically send updates." />}
      <ModalFooter>
        {showFields && requiredFields?.length > 0 &&
          <UncontrolledDropdown direction="up">
            <DropdownToggle color="link">Templates</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={loadData}>Load data from file</DropdownItem>
              <DropdownItem onClick={confirmation(saveData, "", { showInput: true, title: <h5>Enter file name</h5> })}>Save data to file</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        }
        <Button className="ml-auto" color="link" onClick={cancel}>Cancel</Button>
        <MutationButton disabled={!isValid} readyState={readyState} color="primary" onClick={change}>
          {isChange ? "Update" : "Send"}
        </MutationButton>
      </ModalFooter>
    </Modal>
  );
}
