import React, { useState, useEffect } from "react";
import { Col, Row, Button, Nav, NavItem, NavLink } from "reactstrap";
import TaskCard, { Fragment as TaskFragment } from "../cards/TaskCard";
import WorkItemCard, { Fragment as WorkItemFragment } from "../cards/WorkItemCard";
import { useQuery, DeepPartial, useReadyState, ReadyState } from "../hooks/ApiProvider";
import { useTitle } from "../hooks/TitleHook";
import { useTranslation, DateDisplayFormat } from "../hooks/TranslationProvider";
import { JobModel, WorkItemModel, TaskModel, TaskType, ResourceState, ResourceKind, ReviewInputModel } from "../types/api-graph-types";
import { Fragment as JobFragment } from "../cards/Legal/LegalResearchInput";
import { preventDefault } from "../hooks/NavigationHook";
import { useSearch } from "../hooks/SearchProvider";
import CardColumns from "../components/CardColumns";
import { UserThumbnail, UserThumbnailFragment } from "../cards/PersonCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { CardButtons, CardButton } from "../components/CardStatus";
import { faCheckCircle, faTimesCircle, faCircle } from "@fortawesome/free-regular-svg-icons";
import { useReviewOperations } from "../hooks/DiscussionHook";
import LoadingController from "./LoadingController";

library.add(faCheck, faTimes, faCheckCircle, faTimesCircle, faCircle);

const Fragment = `
  id
  kind
  state
  expiresOn
  actions { submit }
  workitem {
    id
    kind
    details { type }
    ${WorkItemFragment} 
  }
  actions {
    discuss
    review
  }
  tasks(states:[PROPOSED,IN_PROGRESS,RESOLVED,COMPLETED]) {
    ${TaskFragment} 
  }
  review {
    id
    kind
    rating
    approved
    rejected
    updatedOn
    author { ${UserThumbnailFragment} }
  }
  ${JobFragment}  
`;

const _getReviewJobDiscussion = `query getReviewJobDiscussion($id:ID $type:TaskType $query:String $first:Int $excludeIds:[ID]) {
  reviewJob(id:$id) { 
    workitem {  
      tasks(states:[RESOLVED,COMPLETED] types:[$type] query:$query first:$first excludeIds:$excludeIds) {
        ${TaskFragment}
      }
    }
  }
}`;

const _getReviewJob = `query getReviewJob($id:ID) {
  reviewJob(id:$id) { ${Fragment} }
}`;

interface Result {
  reviewJob: JobModel
}

function Discuss({ value, onUpdate }: {
  value: JobModel,
  onUpdate: (value: DeepPartial<JobModel>) => void
}) {
  const [t, d] = useTranslation();
  const [vars, setVars] = useState({ first: 12, type: TaskType.Null });
  const [readyState, setReadyState] = useReadyState();
  const [search] = useSearch();
  const excludeIds = value.tasks.map(_ => _.id);
  const [result, updateResult, isLoading] = useQuery<Result>(_getReviewJobDiscussion, { id: value.id, query: search, excludeIds, ...vars });
  const { updateReview: _updateReview, removeReview: _removeReview } = useReviewOperations();
  useTitle("Review");
  useEffect(() => {
    const timer = setTimeout(() => updateReview({}), 30000);
    return () => clearTimeout(timer);
  }, [value.id]);

  const workitem = result?.reviewJob?.workitem;
  const types = Array.from(value.tasks.reduce((r, _) => r.add(_.type), new Set<TaskType>()));
  const getMore = (e: React.MouseEvent) => setVars({ ...vars, first: e.getModifierState("Shift") ? 1024 : vars.first * 2 });
  const updateTask = (task: TaskModel) => onUpdate({ tasks: [task] });
  const updateRelatedTask = (task: TaskModel) => updateResult({ reviewJob: { workitem: { tasks: [task] } } });
  const removeJobReview = value.review?.id && (async () => {
    const result = await _removeReview(value.review.id);
    onUpdate?.({ review: result });
  }) || undefined;
  const updateReview = async (input: Partial<ReviewInputModel>, setReadyState?: (value: ReadyState) => void) => {
    const result = await _updateReview(value.id, ResourceKind.Job, input, setReadyState);
    onUpdate?.({ review: result });
  }

  const acceptJob = () => updateReview({ state: ResourceState.Resolved, rating: 9 }, setReadyState);
  const rejectJob = () => updateReview({ state: ResourceState.Resolved, rating: 5 }, setReadyState);

  const relatedTasks = workitem?.tasks?.filter(_ => _.type === vars.type) ?? [];
  const showRelated = relatedTasks?.length > 0;
  const isOverview = vars.type === TaskType.Null;
  const isDisabled = value.tasks.filter(_ => !(_.review && _.review.rating)).length != 0;

  return (
    <>
      <Row className="mt-3">
        <Col>
          <Nav>
            <NavItem>
              <NavLink active={isOverview} href="#" onClick={() => setVars({ ...vars, type: TaskType.Null })}>Overview</NavLink>
            </NavItem>
            {types && types.map((_, i) => (
              <NavItem key={i}>
                <NavLink active={_ === vars.type} href="#" onClick={() => setVars({ ...vars, type: _ })}>{t(_)}</NavLink>
              </NavItem>
            ))}
            <NavItem className="ml-auto d-flex align-items-center">
              {!value.review || !value.review.rating ? null
                : value.review.approved ? <UserThumbnail className="mr-3" icon="check" iconColor="success" width={32} value={value.review.author} title={`You approved ${d(value.review.updatedOn, DateDisplayFormat.TimeAgo)}`} />
                  : value.review.rejected ? <UserThumbnail className="mr-3" icon="times" iconColor="warning" width={32} value={value.review.author} title={`You rejected ${d(value.review.updatedOn, DateDisplayFormat.TimeAgo)}`} />
                    : <UserThumbnail className="mr-3" width={32} value={value.review.author} title={`You reviewed ${d(value.review.updatedOn, DateDisplayFormat.TimeAgo)}`} />
              }
              <CardButtons readyState={readyState}>
                <CardButton icon={["far", "check-circle"]} disabled={isDisabled} onClick={acceptJob}>Approve</CardButton>
                <CardButton icon={["far", "check-circle"]} disabled={isDisabled} onClick={acceptJob}>Approve</CardButton>
                <CardButton icon={["far", "times-circle"]} disabled={isDisabled} onClick={rejectJob}>Reject</CardButton>
                <CardButton icon={["far", "circle"]} disabled={!removeJobReview} onClick={removeJobReview}>Reset</CardButton>
              </CardButtons>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row className="mt-3">
        <CardColumns>
          {value.tasks.filter(_ => isOverview || _.type === vars.type).map(_ => {
            const appendHeader = <Button className="ml-auto" color="link" onClick={() => setVars({ ...vars, type: _.type })}>See related</Button>;
            return <TaskCard key={_.id} value={_} onUpdate={updateTask} hideRating={!value.review} showHeader={isOverview} appendHeader={appendHeader} showAttachments showStatus showDiscussion showReview openDiscussion openReview />;
          })}
        </CardColumns>
      </Row>
      {showRelated && <h3 className="mt-3">Related submissions</h3>}
      {showRelated && relatedTasks &&
        <Row className="mt-3">
          <CardColumns md={2} xl={3}>
            {relatedTasks.map(_ =>
              <TaskCard key={_.id} className="mb-3" value={_} onUpdate={updateRelatedTask} showAttribution showAttachments showStatus showMenu showDiscussion openDiscussion />
            )}
          </CardColumns>
        </Row>
      }
      <Row className="mt-3">
        <Col>
          {isLoading && <FontAwesomeIcon className="text-muted" icon="circle-notch" spin />}
          {!isLoading && (workitem?.tasks.length ?? 0) >= vars.first && <a href="#" onClick={preventDefault(getMore)}>See more</a>}
        </Col>
      </Row>
    </>
  );
}

export default ({ id }: { id: string }) => {
  const [t] = useTranslation();
  const [result, updateResult, isLoading] = useQuery<Result>(_getReviewJob, { id });
  const value = result?.reviewJob;
  useTitle(value && t(value.workitem.details.type));
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const updateWorkItem = (workitem: DeepPartial<WorkItemModel>) => updateResult({ reviewJob: { workitem } });
  const updateJob = (reviewJob: DeepPartial<JobModel>) => updateResult({ reviewJob });

  return (
    <>
      <Row className="mt-3">
        <Col>
          <WorkItemCard value={value.workitem} onUpdate={updateWorkItem} showStatus showDiscussion openDiscussion={value.workitem.commentsSummary && value.workitem.commentsSummary.completedCount > 0} />
        </Col>
      </Row>
      <Discuss value={value} onUpdate={updateJob} />
    </>
  )
};
