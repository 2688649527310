import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, CustomInput, Form, Label, Row } from "reactstrap";
import FormGroup, { isEmail, isPhone } from "../../components/FormGroup";
import Input from "../../components/Input";
import MutationButton from "../../components/MutationButton";
import PhoneNumberSpan from "../../components/PhoneNumberSpan";
import { DeepPartial, nextBase32Nonce, nextSequence, ReadyState, setDelete, useMutation, useReadyState } from "../../hooks/ApiProvider";
import { useToggle } from "../../hooks/CommonHooks";
import { useIcons } from "../../hooks/IconizeHook";
import { useMe } from "../../hooks/MeProvider";
import { useNavigation } from "../../hooks/NavigationHook";
import { useAuthorizationToken } from "../../hooks/TokenProvider";
import { ExternalAccountInputModel, ExternalAccountModel, ExternalAccountOption, ExternalAccountType, MeModel, PersonInputModel } from "../../types/api-graph-types";

library.add(faUser, faInfoCircle);

const _changeMe = `mutation changeMe($value:PersonInputModel) {
  me { 
    change(value:$value)
    commit { id name nickname contactsConfirmedOn }
  }
}`;

const _updateMyContactInfo = `mutation updateMyContactInfo($key:String $value:ExternalAccountInputModel) {
  me {
    changeContactInfo(key:$key value:$value)
    commit { email phone contactInformation { key type value isPrimary isVerified verificationRequestedOn } }
  }
}`

const _removeMyContactInfo = `mutation removeMyContactInfo($key:String) {
  me {
    removeContactInfo(key:$key)
    commit { email phone contactInformation { key value isPrimary } }
  }
}`

const _addMySubscription = `mutation addMySubscription($planId:ID!) {
  me {
    subscription: addSubscription(planId:$planId) { complete commit { id } }
    commit {
      subscriptions { id kind title plan { id title } }
      access_token
    }
  }
}`

interface OperationResult {
  me: {
    commit: MeModel
  }
}

function NameForm({ value, isOpen: _isOpen, onUpdate }: {
  value?: string,
  isOpen?: boolean,
  onUpdate?: (value: string) => void
}) {
  const [uid] = useState(nextSequence());
  const [isOpen, toggle] = useToggle(_isOpen);
  const [readyState, setReadyState] = useReadyState();
  const [name, setName] = useState(value ?? "");
  const [mutation] = useMutation<OperationResult>();
  useEffect(() => setName(value ?? ""), [value]);
  const change = async () => {
    await mutation(_changeMe, { value: { name, nickname: "" } }, { setReadyState });
    onUpdate?.(name);
    toggle();
  }
  return (
    <Form inline className="hover-container">
      <FormGroup>
        <Label for={uid} className="mr-1"><b>Full name</b></Label>
        {!isOpen ?
          <>
            <span>{value}</span>
            <Button size="sm" color="link" onClick={toggle} className="p-0 b-0 align-baseline ml-3 hover-visible">Edit</Button>
          </> : <>
            <Input id={uid} placeholder="Enter your full name here" bsSize="sm" value={name} onUpdate={setName} />
            <MutationButton readyState={readyState} size="sm" color="primary" onClick={change}>Update</MutationButton>
            <Button disabled={!value} size="sm" color="link" onClick={toggle}>Cancel</Button>
          </>
        }
      </FormGroup>
    </Form>
  );
}

function ContactInformationForm({ value, onUpdate }: {
  value: ExternalAccountModel,
  onUpdate?: (value: DeepPartial<ExternalAccountModel>, values: ReadonlyArray<DeepPartial<ExternalAccountModel>>) => void
}) {
  const [i] = useIcons();
  const [mutation] = useMutation<OperationResult>();
  const [readyVerify, setReadyVerify] = useReadyState();
  const [readyResend, setReadyResend] = useReadyState();
  const [otp, setOtp] = useState("");
  const setPrimary = async () => {
    const result = await mutation(_updateMyContactInfo, { key: value.key, value: { isPrimary: true } });
    const next = result.me.commit.contactInformation.filter(_ => _.key === value.key)[0];
    onUpdate?.(next, result.me.commit.contactInformation);
  }
  const remove = async () => {
    const result = await mutation(_removeMyContactInfo, { key: value.key });
    onUpdate?.(setDelete(value), [setDelete(value), ...result.me.commit.contactInformation]);
  }
  const verify = async () => {
    const result = await mutation(_updateMyContactInfo, { key: value.key, value: { verificationCode: otp } as ExternalAccountInputModel }, { setReadyState: setReadyVerify });
    const next = result.me.commit.contactInformation.filter(_ => _.key === value.key)[0];
    onUpdate?.(next, result.me.commit.contactInformation);
  }
  const resend = async () => {
    const result = await mutation(_updateMyContactInfo, { key: value.key, value: { verificationCode: "" } as ExternalAccountInputModel }, { setReadyState: setReadyResend });
    const next = result.me.commit.contactInformation.filter(_ => _.key === value.key)[0];
    onUpdate?.(next, result.me.commit.contactInformation);
  }
  return (
    <Form inline className="hover-container">
      <FormGroup>
        <Label className="mr-1"><FontAwesomeIcon fixedWidth icon={i(value.type)} /></Label>
        {value.type === ExternalAccountType.Phone && <PhoneNumberSpan value={value.value} />}
        {value.type !== ExternalAccountType.Phone && <span> {value.value}</span>}
        {value.isPrimary && <span className="ml-3 small text-muted">Primary</span>}
        {!value.isVerified && !value.verificationRequestedOn &&
          <>
            <MutationButton className="ml-3" readyState={readyResend} size="sm" color="link" onClick={resend}>Verify</MutationButton>
          </>
        }
        {!value.isVerified && value.verificationRequestedOn &&
          <>
            <Input type="text" inputMode="numeric" pattern="[0-9]*" placeholder="Enter code" className="ml-3" bsSize="sm" value={otp} onUpdate={setOtp} />
            <MutationButton readyState={readyVerify} size="sm" color="primary" onClick={verify}>Verify</MutationButton>
            <MutationButton readyState={readyResend} size="sm" color="link" onClick={resend}>Resend</MutationButton>
          </>
        }
        {value.isVerified && !value.isPrimary && <Button size="sm" color="link" className="hover-visible p-0 b-0 align-baseline ml-3" onClick={setPrimary}>Primary</Button>}
        {!value.isPrimary && <Button size="sm" color="link" className="hover-visible p-0 b-0 align-baseline ml-3" onClick={remove}>Remove</Button>}
      </FormGroup>
    </Form>
  );
}

function AddContactInformationForm({ onUpdate }: {
  onUpdate?: (value: DeepPartial<ExternalAccountModel>, values: ReadonlyArray<ExternalAccountModel>) => void
}) {
  const [i] = useIcons();
  const [uid] = useState(nextSequence());
  const [readyState, setReadyState] = useReadyState();
  const [isOpen, toggle] = useToggle();
  const [value, setValue] = useState("");
  const [mutation] = useMutation<OperationResult>();
  const add = async () => {
    const key = nextBase32Nonce(8);
    const options = type === ExternalAccountType.Phone ? [ExternalAccountOption.PhoneSms, ExternalAccountOption.PhoneWhatsApp] : [];
    const result = await mutation(_updateMyContactInfo, { key, value: { type, value, options } }, { setReadyState });
    const next = result.me.commit.contactInformation.filter(_ => _.key === key)[0];
    onUpdate?.(next, result.me.commit.contactInformation);
    cancel();
  }
  const cancel = () => {
    setValue("");
    toggle();
  }
  const type = isEmail(value) ? ExternalAccountType.Email : isPhone(value) ? ExternalAccountType.Phone : undefined;
  const isValid = !!type;
  return (
    <Form inline>
      <FormGroup>
        {!isOpen && <Button size="sm" color="link" className="p-0 b-0 align-baseline" onClick={toggle}>Add</Button>}
        {isOpen &&
          <>
            <Label for={uid} className="mr-1"><FontAwesomeIcon fixedWidth icon={i(type, "user")} /></Label>
            <Input autoFocus id={uid} placeholder="Enter phone or email here" bsSize="sm" value={value} onUpdate={setValue} />
            <MutationButton readyState={readyState} disabled={!isValid} size="sm" color="primary" onClick={add}>Add</MutationButton>
            <Button size="sm" color="link" onClick={cancel}>Cancel</Button>
          </>
        }
      </FormGroup>
    </Form>
  );
}

function MergeAlert({ }: {}) {
  const [navigate] = useNavigation();
  return (
    <Alert color="primary">
      <h5>Duplicate account</h5>
      <p>You may have multiple accounts. Please check your <a href="#" onClick={navigate("/me")}>profile</a> to merge them.</p>
    </Alert>
  );
}

function ProfileInputAlert({ }: {}) {
  const [me, updateMe] = useMe();
  const [mutation] = useMutation<OperationResult>();
  const [readyConfirm, setReadyConfirm] = useReadyState();
  const [readySkip, setReadySkip] = useReadyState();
  const setConfirmedOn = async (contactsConfirmedOn: string, setReadyState: (value: ReadyState) => void) => {
    const result = await mutation(_changeMe, { value: { contactsConfirmedOn } as PersonInputModel }, { setReadyState });
    updateMe(result.me.commit);
  }
  const confirm = () => setConfirmedOn(moment().utc().toISOString(), setReadyConfirm);
  const skip = () => setConfirmedOn(moment().utc().add(-5, "months").toISOString(), setReadySkip);
  const openName = (me?.name ?? "").split(" ").length === 1; // No space
  return (
    <Alert color="primary">
      <p>
        <FontAwesomeIcon className="mr-1" icon="info-circle" />
        Please confirm your profile information below. Add a verified
        phone numer or a secondary email address to ensure you can recover
        your account if you lose access to your primary email or phone.
      </p>
      <NameForm value={openName ? "" : me?.name} onUpdate={name => updateMe({ name, nickname: "" })} isOpen={openName} />
      {me?.contactInformation?.filter(_ => _.type === ExternalAccountType.Email)
        .map((_, i) => <ContactInformationForm key={i} value={_} onUpdate={(_, contactInformation) => updateMe({ contactInformation })} />)}
      {me?.contactInformation?.filter(_ => _.type === ExternalAccountType.Phone)
        .map((_, i) => <ContactInformationForm key={i} value={_} onUpdate={(_, contactInformation) => updateMe({ contactInformation })} />)}
      <AddContactInformationForm onUpdate={(_, contactInformation) => updateMe({ contactInformation })} />
      <div className="mt-3">
        <MutationButton readyState={readyConfirm} color="primary" onClick={confirm}>Confirm</MutationButton>
        <MutationButton readyState={readySkip} color="link" onClick={skip}>Skip</MutationButton>
      </div>
    </Alert>
  );
}

function SubscriptionAlert({ }: {}) {
  const [uid] = useState(nextSequence());
  const [me, setMe] = useMe();
  const [, setToken] = useAuthorizationToken();
  const [planId, setPlanId] = useState("141ee3dc-c6e6-401a-a13e-33dfcf6c2f5a");
  const [mutation] = useMutation<OperationResult>();
  const [readyState, setReadyState] = useReadyState();
  const confirm = async () => {
    const result = await mutation(_addMySubscription, { planId }, { setReadyState });
    setMe({ subscriptions: result.me.commit.subscriptions });
    setToken(result.me.commit.access_token);
  }
  return (
    <Alert color="primary">
      <p>
        <FontAwesomeIcon className="mr-1" icon="info-circle" />
        Select your current role to get the most out of HigherKnowledge.
      </p>
      <Form inline className="hover-container">
        <FormGroup>
          <CustomInput id={uid} type="select" value={planId} onChange={e => setPlanId(e.currentTarget.value)}>
            <option value="141ee3dc-c6e6-401a-a13e-33dfcf6c2f5a">I am a student</option>
            <option value="112bcb77-e0f2-43e8-b44e-238aa4063662">I am an associate</option>
            <option value="88d00d95-69a0-4b04-b5b5-9e42a38d5dfd">I am an HR specialist</option>
            <option value="19813552-2877-4c72-b77f-487751f1d8d3">I am a partner</option>
          </CustomInput>
        </FormGroup>
      </Form>
      <div className="mt-3">
        <MutationButton readyState={readyState} color="primary" onClick={confirm}>Confirm</MutationButton>
      </div>
    </Alert>
  );
}

export default ({ }: {}) => {
  const [me] = useMe();
  const showMerge = (me?.alternates?.length ?? 0) > 0;
  const showProfileInput = !!me && (!me.contactsConfirmedOn || moment(me.contactsConfirmedOn).add(6, "months").isBefore());
  const showSubscriptionInput = !!me && (me.subscriptions?.length ?? 0) === 0;
  const show = showMerge ? "merge" : showProfileInput ? "profile" : showSubscriptionInput ? "subscription" : null;
  return show && (
    <Row className="mt-3">
      <Col>
        {show === "merge" && <MergeAlert />}
        {show === "profile" && <ProfileInputAlert />}
        {show === "subscription" && <SubscriptionAlert />}
      </Col>
    </Row>
  );
}
