import { library } from "@fortawesome/fontawesome-svg-core";
import { faArchive, faCheck, faInbox, faPencilAlt, faPlay, faTimes, faMobileAlt, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Alert, CardBody, CardBodyProps, CardHeader, CardSubtitle, CardTitle, Collapse, Nav, NavItem, NavLink } from "reactstrap";
import { IsEmployment, ProductDescriptionCollapsibleText, ProductDetailsFragment, ProductKeyFeaturesText, ProductTitleText } from "../cards/ProductCard";
import Card, { CardAttributes, CardBodyAttributes, CardButtonsAttributes } from "../components/Card";
import { Fragment as AttachmentFragment, List as CardAttachmentsList } from "../components/CardAttachmentsListGroup";
import CardDiscussionListGroup, { Fragment as DiscussionFragment } from "../components/CardDiscussionListGroup";
import Markdown from "../components/CardMarkdownText";
import { CardButton, CardButtons, CardDiscussionSummaryStatusItems, CardDueStatusItem, CardStatus, CardStatusDetail, CardTasksStatusItem } from "../components/CardStatus";
import { DeepPartial, setDelete, setReplace, useMutation, useReadyState, setSwap } from "../hooks/ApiProvider";
import { useCardState } from "../hooks/CardStateHook";
import { useToggle } from "../hooks/CommonHooks";
import { useConfirmation } from "../hooks/ConfirmationProvider";
import { useMe } from "../hooks/MeProvider";
import { preventDefault, useNavigation } from "../hooks/NavigationHook";
import { DateDisplayFormat, NumberDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import OrderInputModal, { DetailsFragment as OrderDetailsInputFragment } from "../modals/OrderInputModal";
import OrdersInputModal, { updateProductOrdersQuery, View as OrderInputView } from "../modals/OrdersInputModal";
import { JobModel, MessageModel, MoneyModel, OrderModel, OrderStage, ProductModel, ResourceEvent, ResourceRole, ResourceState, RuleType } from "../types/api-graph-types";
import { faThumbsUp, faSave } from "@fortawesome/free-regular-svg-icons";
import { ProgressStep, ProgressTracker } from "../components/ProgressTracker";

library.add(faPlay, faPencilAlt, faCheck, faTimes, faArchive, faInbox, faThumbsUp, faMobileAlt, faSave, faUserTie);

export const Fragment = `
  id
  kind
  state
  stage
  createdOn
  startedOn
  restartOn
  expiresOn
  isExpired
  details {
    startOn
    startIn
    endOn
    customerCompensation { currency amount period isPrivate }
    supplierDescription
    screeningOn
    screeningDuration
    screeningLocation
    interviewOn
    interviewDuration
    interviewLocation
    offerExpiresOn
    ${OrderDetailsInputFragment}
  }
  ${DiscussionFragment}
  attachments { ${AttachmentFragment} }
  product {
    id
    kind
    state
    owner { name picture accentColor }
    organization { id kind name description logo }
    details {
      type
      title
      subtitle
      displayTitle
      duration
      ${ProductDetailsFragment}
      ordersStages
      ordersDescription
      ordersJobsCount
      ordersJobsMaxCount
      ordersDueIn
      ordersDueOn
      ordersCustomerExternalIdMessage
      rules { key name isEnabled type eventsTrigger }
    }
    collection { id kind details { title subtitle displayTitle variantType variantTitle } }
    policy { role }
  }
  customer { name }
  jobsSummary {
    positiveCount
    neutralCount
    negativeCount
    resolvedCount
    completedCount
  }
  actions {
    allow
    accept
    add
    cancel
    change
    decline
    deliver
    event
    interview
    move
    offer
    refuse
    remove
    retract
    return
    review
    screen
    shortlist
    start
    submit
    undo
    uninterview
    unscreen
    unshortlist
    withdraw
  }
  policy { role addToken }
`;

const _createJob = `mutation createJob($id:ID!) {
  order(id:$id) { job: addJob { start commit { id } } }
}`;

const _startOrder = `mutation startOrder($id:ID! $canStartJob:Boolean!) {
  order(id:$id) {
    start commit { ${Fragment} } 
    job: addJob @include(if:$canStartJob) { start commit { id } }
  }
}`;

const _moveOrder = `mutation removeOrder($id:ID! $pid:ID!) {
  order(id:$id) { move(id:$pid) commit { ${Fragment} } }
}`;

const _removeOrder = `mutation removeOrder($id:ID!) {
  order(id:$id) { remove commit { ${Fragment} } }
}`;

const _undoOrder = `mutation undoOrder($id:ID!) {
  order(id:$id) { undo commit { ${Fragment} } }
}`;

const _submitOrder = `mutation submitOrder($id:ID!) {
  order(id:$id) { submit commit { ${Fragment} } }
}`;

const _acceptOrder = `mutation acceptOrder($id:ID!) {
  order(id:$id) { accept commit { ${Fragment} } }
}`;

const _declineOrder = `mutation declineOrder($id:ID!) {
  order(id:$id) { decline commit { ${Fragment} } }
}`;

const _cancelOrder = `mutation cancelOrder($id:ID!) {
  order(id:$id) { cancel commit { ${Fragment} } }
}`;

interface OperationResults {
  order: {
    commit: OrderModel
    job: {
      commit: JobModel
    }
  }
  product: {
    orders: {
      preview: ReadonlyArray<MessageModel>
      commit: ReadonlyArray<OrderModel>
    }
    commit: ProductModel
  }
};

export function OrderStartListItem({ startOn, endOn, startIn, icon, toggleInput }: {
  startOn?: string,
  endOn?: string,
  startIn?: string,
  toggleInput?: () => void,
  icon?: boolean
}) {
  const [, d] = useTranslation();
  const hasStartIn = !!startIn;
  const hasStartOnOrEndOn = !!startOn || !!endOn;
  return (
    <li onClick={preventDefault(toggleInput)}>
      {icon && <FontAwesomeIcon fixedWidth icon={["far", "calendar-alt"]} />}
      {!icon && <b>Availability</b>}
      {hasStartIn && <span className="ml-1">{d(startIn, DateDisplayFormat.HumanDuration)} notice</span>}
      {!hasStartIn && hasStartOnOrEndOn && <span className="ml-1">{d(startOn, DateDisplayFormat.HumanDateRange, endOn)}</span>}
      {toggleInput && <a className={`ml-1 btn btn-link p-0 b-0 align-baseline ${(hasStartIn || hasStartOnOrEndOn) ? "hover-visible" : ""}`} href="#">Edit</a>}
    </li>
  ) || <></>;
};

export function OrderCustomerDescriptionListItem({ value, toggleInput }: {
  value: string,
  toggleInput?: () => void,
}) {
  const hasDescription = !!value;
  return (hasDescription || toggleInput) ? (
    <li onClick={preventDefault(toggleInput)}>
      <b className="mr-1">Additional details</b>
      {hasDescription && <Markdown clampHeight="9em">{value}</Markdown>}
      {toggleInput && <a className={`btn btn-link p-0 b-0 align-baseline ${hasDescription ? "hover-visible" : ""}`} href="#">Edit</a>}
    </li>
  ) : <></>;
};

export function OrderCustomerExternalIdItem({ value, label }: {
  value: string,
  label?: string
}) {
  const hasExternalId = !!value && !!label;
  return hasExternalId ? (
    <li>
      <b className="mr-1">Reference Id</b>
      {value}
    </li>
  ) : <></>;
};

export function OrderCustomerCompensationListItem({ role, value, period, toggleInput }: {
  value: MoneyModel,
  role: ResourceRole,
  period?: "P30D" | "P365D",
  toggleInput?: () => void
}) {
  const [t, , n] = useTranslation();
  const monthly = (period ?? value?.period) === "P30D";
  const yearly = (period ?? value?.period) === "P365D";
  const f = monthly ? 30 / moment.duration(value?.period ?? "P30D").asDays()
    : yearly ? 365 / moment.duration(value?.period ?? "P30D").asDays()
      : 1;
  const minAmount = (value?.amount ?? 0) * f;
  const variableFraction = value?.variableFraction ?? 20;
  const maxAmount = value?.amount && value.amount * f * (100 + variableFraction) / 100;
  const hide = value?.isPrivate && [ResourceRole.ProductOwner, ResourceRole.ProductContributor, ResourceRole.ProductReader].includes(role);
  return value?.amount && !hide && (
    <li onClick={preventDefault(toggleInput)}>
      <b className="mr-1">Expecting</b>
      {n(minAmount, variableFraction ? NumberDisplayFormat.HumanRange : NumberDisplayFormat.Human, maxAmount, t(value.currency))}+
      {monthly ? " per month" : yearly ? " per year" : " per pay period"}
      {value.isPrivate && <FontAwesomeIcon className="ml-1 small text-muted hover-visible" icon={["far", "eye-slash"]} />}
      {toggleInput && <a className="btn btn-link ml-1 p-0 b-0 align-baseline hover-visible" href="#">Edit</a>}
    </li>
  ) || null;
};

function Notice({ value, className, showButtons, onUpdate }: {
  value: OrderModel,
  className?: string,
  showButtons?: boolean,
  onUpdate?: (value: OrderModel) => void
}) {
  const [navigate, go] = useNavigation();
  const [t, d] = useTranslation();
  const { id, actions } = value;
  const [mutation] = useMutation<OperationResults>();
  const removeOrder = async () => {
    const result = await mutation(_removeOrder, { id })
    onUpdate?.(result.order.commit);
  }
  const { ordersJobsCount, ordersJobsMaxCount, ordersDescription } = value.product.details;
  const { supplierDescription, screeningOn, screeningDuration, screeningLocation,
    interviewOn, interviewDuration, interviewLocation, offerExpiresOn,
    startOn, endOn, location } = value.details;
  const expiresOn = value.expiresOn ? moment(value.expiresOn) : undefined;
  const isEmployment = IsEmployment(value.product);
  const hasRequiredJobs = ordersJobsMaxCount !== 0;
  const hasInstructions = ordersDescription || (hasRequiredJobs && ordersJobsCount > 0);
  const classNames = className;
  return value.product.state != ResourceState.InProgress && [ResourceState.Proposed, ResourceState.InProgress].includes(value.state) ? (
    <Alert className={`text-justify text-hyphens ${classNames}`} color="warning">
      <h5>Unavailable</h5>
      <p>
        This listing is not currently available. Your current progress has been saved if the listing becomes available in the future.
          {showButtons && actions.remove && <a className="ml-1" href="#" onClick={preventDefault(removeOrder)}>Archive</a>}
      </p>
    </Alert>
  ) : value.stage === OrderStage.Requested ? (
    <Alert className={`text-justify text-hyphens ${classNames}`} color="primary">
      <h5>Waiting</h5>
      <p>
        This listing is currently limiting new applications. You will be informed
        when the listing owner allows your application.
        {isEmployment && <> In the meantime, you may complete your <a href="#" onClick={navigate("/me")}>profile</a>, and mention your availability and any additional details below.</>}
      </p>
    </Alert>
  ) : (value.stage === OrderStage.Stopped || value.stage === OrderStage.Started) && hasInstructions ? (
    <Alert className={`text-justify text-hyphens ${classNames}`} color="primary">
      <h5>Instructions</h5>
      {isEmployment && ordersJobsCount === 0 && <p>Complete your <a href="#" onClick={navigate("/me")}>profile</a> for full consideration. Mention your availability and any additional details below.</p>}
      {isEmployment && ordersJobsCount === 1 && <p>Complete your <a href="#" onClick={navigate("/me")}>profile</a> and at least {ordersJobsCount} assessment{expiresOn ? ` by ${expiresOn.format("MMMM D")}` : ""} for full consideration. Mention your availability and any additional details below. Completing more assessments help improve your chances.</p>}
      {isEmployment && ordersJobsCount > 1 && <p>Complete your <a href="#" onClick={navigate("/me")}>profile</a> and at least {ordersJobsCount} assessments{expiresOn ? ` by ${expiresOn.format("MMMM D")}` : ""} for full consideration. Mention your availability and any additional details below. Completing more assessments help improve your chances.</p>}
      {ordersDescription && <Markdown clampHeight="5em">{ordersDescription}</Markdown>}
    </Alert>
  ) : value.stage === OrderStage.Submitted ? (
    <Alert className={classNames} color="primary">
      <h5>Submitted</h5>
      {!hasRequiredJobs && <p>Your application has been submitted.</p>}
      {hasRequiredJobs && ordersJobsCount === 0 && <p>Your application has been submitted. You may optionally complete assessments to improve your chances of being shortlisted.</p>}
      {hasRequiredJobs && ordersJobsCount > 0 && <p>Your application has been submitted. Completing additional assessments may improve your chances of being shortlisted.</p>}
    </Alert>
  ) : value.stage === OrderStage.Shortlisted ? (
    <Alert className={classNames} color="primary">
      <h5>Shortlisted</h5>
      <p>
        Your application is currently shortlisted.
          {ordersJobsCount > 0 && <span className="ml-1">Completing additional assessments may further improve your chances of getting an offer.</span>}
      </p>
    </Alert>
  ) : value.stage === OrderStage.ScreeningScheduled ? (
    <Alert className={classNames} color="primary">
      <h5>Screening scheduled</h5>
      <p>Good news! Your application is selected for a phone screen.</p>
      <ul className="list-unstyled">
        <li><b>Date</b> {d(screeningOn, DateDisplayFormat.HumanDate)}</li>
        <li><b>Time</b> {d(screeningOn, DateDisplayFormat.HumanTimeRange, screeningDuration)}</li>
        {screeningLocation && <li><b>Conference</b> {screeningLocation}</li>}
      </ul>
      {supplierDescription && <Markdown>{supplierDescription}</Markdown>}
    </Alert>
  ) : value.stage === OrderStage.InterviewScheduled ? (
    <Alert className={classNames} color="primary">
      <h5>Interview scheduled</h5>
      <p>Good news! Your application is selected for an interview.</p>
      <ul className="list-unstyled">
        <li><b>Date</b> {d(interviewOn, DateDisplayFormat.HumanDate)}</li>
        <li><b>Time</b> {d(interviewOn, DateDisplayFormat.HumanTimeRange, interviewDuration)}</li>
        {interviewLocation && <li><b>Location</b> {interviewLocation}</li>}
      </ul>
      {supplierDescription && <Markdown>{supplierDescription}</Markdown>}
    </Alert>
  ) : value.stage === OrderStage.Offered ? (
    <Alert className={classNames} color="primary">
      <h5>Offered</h5>
      <p>Congratulations! You have been offered a position. Please check the offer details below and accept or decline the offer by {d(offerExpiresOn, DateDisplayFormat.HumanTime)} on {d(offerExpiresOn, DateDisplayFormat.HumanDate)}.</p>
      <ul className="list-unstyled">
        {!endOn && <li><b>Start</b> {d(startOn, DateDisplayFormat.HumanDate)}</li>}
        {endOn && <li><b>Dates</b> {d(startOn, DateDisplayFormat.HumanDateRange, endOn)}</li>}
        {location && <li><b>Location</b> {location}</li>}
      </ul>
      {supplierDescription && <Markdown>{supplierDescription}</Markdown>}
    </Alert>
  ) : value.stage === OrderStage.Accepted ? (
    <Alert className={classNames} color="success">
      <h5>Hired!</h5>
      <p>You have accepted the offer below.</p>
      <ul className="list-unstyled">
        {!endOn && <li><b>Start</b> {d(startOn, DateDisplayFormat.HumanDate)}</li>}
        {endOn && <li><b>Dates</b> {d(startOn, DateDisplayFormat.HumanDateRange, endOn)}</li>}
        {location && <li><b>Location</b> {location}</li>}
      </ul>
      {supplierDescription && <Markdown>{supplierDescription}</Markdown>}
    </Alert>
  ) : value.stage === OrderStage.Delivered ? (
    <Alert className={classNames} color="success">
      <h5>Joined</h5>
      <p>Welcome! Here are some helpful resources to help you get settled.</p>
      {supplierDescription && <Markdown>{supplierDescription}</Markdown>}
    </Alert>
  ) : value.stage === OrderStage.OfferExpired ? (
    <Alert className={classNames} color="warning">
      <h5>Offer expired</h5>
      <p>
        Your offer below has expired. Please contact the listing provider directly to request an extension.
          {showButtons && actions.remove && <a className="ml-1" href="#" onClick={preventDefault(removeOrder)}>Archive</a>}
      </p>
      <ul className="list-unstyled">
        {!endOn && <li><b>Start</b> {d(startOn, DateDisplayFormat.HumanDate)}</li>}
        {endOn && <li><b>Dates</b> {d(startOn, DateDisplayFormat.HumanDateRange, endOn)}</li>}
        {location && <li><b>Location</b> {location}</li>}
      </ul>
      {supplierDescription && <Markdown>{supplierDescription}</Markdown>}
    </Alert>
  ) : value.stage === OrderStage.Declined ? (
    <Alert className={classNames} color="warning">
      <h5>Declined</h5>
      <p>
        You have declined the offer.
        {showButtons && actions.remove && <a className="ml-1" href="#" onClick={preventDefault(removeOrder)}>Archive</a>}
      </p>
      <ul className="list-unstyled">
        {!endOn && <li><b>Start</b> {d(startOn, DateDisplayFormat.HumanDate)}</li>}
        {endOn && <li><b>Dates</b> {d(startOn, DateDisplayFormat.HumanDateRange, endOn)}</li>}
        {location && <li><b>Location</b> {location}</li>}
      </ul>
    </Alert>
  ) : (value.stage === OrderStage.Refused || value.stage === OrderStage.Removed) ? (
    <Alert className={classNames} color="warning">
      <h5>Declined</h5>
      <p>
        Your application is not longer being considered.
        You may reactivate this application after {d(value.restartOn, DateDisplayFormat.HumanDate)}.
        {showButtons && actions.remove && <a className="ml-1" href="#" onClick={preventDefault(removeOrder)}>Archive</a>}
      </p>
    </Alert>
  ) : value.isExpired && value.restartOn ? (
    <Alert className={`text-justify text-hyphens ${classNames}`} color="warning">
      <h5>Expired</h5>
      <p>
        The time allotted for completing this goal has expired. You may restart this goal after {moment(value.restartOn).format("MMMM D")}.
        {showButtons && actions.remove && <a className="ml-1" href="#" onClick={preventDefault(removeOrder)}>Archive</a>}
      </p>
    </Alert>
  ) : value.isExpired ? (
    <Alert className={`text-justify text-hyphens ${classNames}`} color="warning">
      <h5>Expired</h5>
      <p>
        The time allotted for completing this goal has expired.
          {showButtons && actions.remove && <a className="ml-1" href="#" onClick={preventDefault(removeOrder)}>Archive</a>}
      </p>
    </Alert>
  ) : null;
}

export function OrderDetailsText({ value, product: _product, compensationPeriod, toggleInput }: {
  value: OrderModel,
  product?: ProductModel,
  toggleInput?: () => void,
  compensationPeriod?: "P30D" | "P365D"
}) {
  const product = _product ?? value.product;
  const isEmployment = IsEmployment(product);
  const showCustomerComensation = isEmployment;
  const showStart = isEmployment;
  const showCustomerDescription = isEmployment;
  const showExternalId = value.details.customerExternalId && product?.details.ordersCustomerExternalIdMessage;
  return (
    <>
      {value.details &&
        <ul className='list-unstyled'>
          {showExternalId && <OrderCustomerExternalIdItem value={value.details.customerExternalId} label={product?.details.ordersCustomerExternalIdMessage} />}
          {showCustomerComensation && <OrderCustomerCompensationListItem role={value.policy.role} period={compensationPeriod} value={value.details.customerCompensation} toggleInput={toggleInput} />}
          {showStart && <OrderStartListItem startOn={value.details.startOn} startIn={value.details.startIn} endOn={value.details.endOn} toggleInput={toggleInput} />}
          {showCustomerDescription && <OrderCustomerDescriptionListItem value={value.details.customerDescription} toggleInput={toggleInput} />}
        </ul>
      }
    </>
  );
}

export function Status({ value, toggleDiscussion }: {
  value: OrderModel,
  toggleDiscussion?: () => void
}) {
  const [isOpenJobsSummary, toggleJobsSummary] = useToggle();
  const showDue = value.expiresOn && (value.stage === OrderStage.Stopped || value.stage === OrderStage.Started) && !value.expiresOn.startsWith("9999");
  return <>
    <CardStatus>
      {showDue && <CardDueStatusItem value={value.expiresOn} />}
      <CardTasksStatusItem value={value.jobsSummary.resolvedCount + value.jobsSummary.completedCount} max={value.product.details.ordersJobsCount} toggle={toggleJobsSummary} />
      <CardDiscussionSummaryStatusItems hideRating reviews={value.reviewsSummary} comments={value.commentsSummary} toggleDiscussion={toggleDiscussion} />
    </CardStatus>
    <Collapse isOpen={isOpenJobsSummary}>
      <CardStatus>
        <CardStatusDetail value={value.jobsSummary.positiveCount + value.jobsSummary.neutralCount} valueClassName="strong" label="Approved" />
        <CardStatusDetail value={value.jobsSummary.negativeCount} valueClassName="strong" label="Needs Imp." />
        <CardStatusDetail value={value.jobsSummary.resolvedCount} valueClassName="strong" label="Under Review" />
      </CardStatus>
    </Collapse>
  </>;
}

const _initialInputProps = {
  isOpen: false,
  eventKey: undefined as string | undefined,
  view: "add" as OrderInputView,
  previews: undefined as ReadonlyArray<MessageModel> | undefined
}

export function Variants({ value, onUpdate }: { value: OrderModel, onUpdate?: (value: DeepPartial<OrderModel>) => void }) {
  const confirm = useConfirmation();
  const [mutation] = useMutation<OperationResults>();
  const move = async (pid: string) => {
    const result = await mutation(_moveOrder, { id: value.id, pid });
    onUpdate?.({ product: setReplace(result.order.commit.product) });
  }
  return value?.product?.collection?.length > 0 ? (
    <Nav className="mt-3">
      {value?.product?.collection?.map((_, i) => {
        const isActive = _.id === value.product.id;
        return (
          <NavItem key={i}>
            <NavLink active={isActive} href="#" onClick={isActive ? undefined : confirm(() => move(_.id), `You are about to switch your application to ${_.details.displayTitle}. You will no longer be considered for ${value.product.details.displayTitle}. You can confirm, or cancel to stay in consideration.`)} >
              {_.details?.variantTitle ?? _.details?.title}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  ) : null;
}

export function Buttons({ value, className, toggleInput, fullWidth, prependChildren, product: _product, onUpdate, onUpdateProduct, children }: CardButtonsAttributes<OrderModel> & {
  product?: ProductModel,
  onUpdateProduct?: (value: DeepPartial<ProductModel>) => void,
  prependChildren?: boolean,
  fullWidth?: boolean
}) {
  const [me] = useMe();
  const product = _product || value.product;
  const [, go] = useNavigation();
  const [readyState, setReadyState] = useReadyState();
  const [inputProps, setInputProps] = useState(_initialInputProps);
  const [mutation] = useMutation<OperationResults>();
  const { id, actions, stage, policy } = value;
  const { id: pid, details: productDetails } = product;
  const { role } = value.policy;
  const change = (query: string, vars?: any) => async () => {
    const result = await mutation(query, { ...(vars || {}), id }, { setReadyState })
    onUpdate?.(result.order.commit);
    return result;
  }
  const supplierPreviewOrChange = (view: OrderInputView, key?: string) => async () => {
    const query = updateProductOrdersQuery[view];
    const previewResult = await mutation(query, { ids: [id], tokens: [policy?.addToken], pid, key, preview: true })
    const previews = previewResult.product.orders.preview;
    if (previews.length > 0) {
      setInputProps({ view, eventKey: key, isOpen: true, previews });
    } else {
      const result = await mutation(query, { ids: [id], tokens: [policy?.addToken], pid, key, preview: false }, { setReadyState })
      if (view === "add" || view === "addShortlist") {
        onUpdate?.({ id, ...setSwap(value, { ...result.product.orders.commit[0], customer: value.customer }) });
        onUpdateProduct?.(result.product.commit);
        //go(window.location.pathname + `/candidates/${result.product.orders.commit[0].id}`)
      } else {
        onUpdate?.(result.product.orders.commit[0]);
        onUpdateProduct?.(result.product.commit);
      }
    }
  }
  const start = async () => {
    const result = await change(_startOrder, { canStartJob })();
    if (canStartJob) {
      go(`/my/tasks/${result.order.job.commit.id}`)
    } else {
      toggleInput?.();
    }
  }
  const nextJob = async () => {
    const result = await mutation(_createJob, { id }, { setReadyState });
    if (canStartJob) {
      go(`/my/tasks/${result.order.job.commit.id}`);
    } else {
      toggleInput?.();
    }
  }
  const updateProduct = (product: DeepPartial<ProductModel>) => {
    onUpdateProduct?.(product);
    onUpdate?.({ id: value.id, product });
  }

  const hasName = me?.name;
  const hasEduOrg = me?.eduAffiliation?.organizationName || me?.eduAffiliation?.organization?.name;
  const hasEduEndOn = me?.eduAffiliation?.endOn;
  const hasResume = me?.resume;
  const hasAvailability = value.details.startOn || value.details.startIn;
  const canStartJob = hasName && hasEduOrg && hasEduEndOn && productDetails.ordersJobsMaxCount !== 0 || false;
  const canSubmit = hasName && hasEduOrg && hasEduEndOn && hasResume && hasAvailability;

  const customerStart = start;
  const customerContinue = nextJob;
  const customerRemove = change(_removeOrder);
  const customerUndo = change(_undoOrder);
  const customerSubmit = canSubmit ? change(_submitOrder) : toggleInput;
  const customerAccept = change(_acceptOrder);
  const customerDecline = change(_declineOrder);
  const customerCancel = change(_cancelOrder);
  const supplierRemove = supplierPreviewOrChange("remove");
  const supplierAccept = supplierPreviewOrChange("accept");
  const supplierDecline = supplierPreviewOrChange("decline");
  const supplierRefuseArchive = supplierPreviewOrChange("refuse");
  const supplierAddShortlist = supplierPreviewOrChange("addShortlist");
  const supplierAllow = supplierPreviewOrChange("allow");
  const supplierShortlist = supplierPreviewOrChange("shortlist");
  const supplierWithdraw = supplierPreviewOrChange("withdraw");
  const supplierUnshortlist = supplierPreviewOrChange("unshortlist");
  const supplierUninterview = supplierPreviewOrChange("uninterview");
  const supplierUnscreen = supplierPreviewOrChange("unscreen");
  const supplierUndo = supplierPreviewOrChange("undo");
  const supplierDeliver = supplierPreviewOrChange("deliver");
  const supplierReturn = supplierPreviewOrChange("return");
  const supplierEvent = (key: string) => supplierPreviewOrChange("event", key);
  const openInput = (view: OrderInputView) => () => setInputProps({ isOpen: true, view, eventKey: undefined, previews: undefined })

  const showScreen = productDetails.ordersStages.includes(OrderStage.ScreeningScheduled);
  const showInterview = productDetails.ordersStages.includes(OrderStage.InterviewScheduled);
  const isSupplier = role === ResourceRole.ProductOwner || role === ResourceRole.ProductContributor || role === ResourceRole.ProductReader;
  const isScreeningScheduled = stage === OrderStage.ScreeningScheduled;
  const isInterviewScheduled = stage === OrderStage.InterviewScheduled;
  const isOffered = stage === OrderStage.Offered || stage === OrderStage.Accepted;
  const isProductUnavailable = product.state != ResourceState.InProgress && [ResourceState.Proposed, ResourceState.InProgress].includes(value.state);

  const undo = isSupplier ? supplierUndo : customerUndo;

  // HACK: Fix buttons for non employment order types:
  const isEmployment = IsEmployment(product);
  const showCustomer = isEmployment;

  return (
    <>
      <CardButtons readyState={readyState} className={className || "mt-3"} showIcons fullWidth={fullWidth}>
        {prependChildren && children}

        {showCustomer && actions.submit && !isProductUnavailable && <CardButton onClick={customerSubmit}>Submit</CardButton>}
        {showCustomer && actions.start && !isProductUnavailable && <CardButton icon="play" onClick={customerStart}>Start</CardButton>}
        {showCustomer && actions.accept && !isSupplier && <CardButton icon="check" onClick={customerAccept} confirm confirmMessage="You are about to accept the offer. Cancellation penalties may apply if you later change your mind. Are you sure you want to accept the offer?">Accept</CardButton>}
        {showCustomer && actions.decline && !isSupplier && <CardButton icon="times" onClick={customerDecline} confirm confirmMessage="You are about to decline the offer. This action cannot be undone. Are you sure?">Decline</CardButton>}
        {showCustomer && actions.change && !isSupplier && !isProductUnavailable && canStartJob && <CardButton icon="play" onClick={customerContinue}>Continue</CardButton>}
        {actions.change && !isSupplier && <CardButton icon="pencil-alt" onClick={toggleInput}>Edit</CardButton>}

        {actions.add && isSupplier && <CardButton event="SHORTLIST Applicant" icon={["far", "save"]} onClick={supplierAddShortlist}>Save</CardButton>}
        {actions.allow && isSupplier && <CardButton icon={["far", "thumbs-up"]} onClick={supplierAllow}>Allow</CardButton>}
        {actions.shortlist && isSupplier && <CardButton event="SHORTLIST Applicant" icon={["far", "save"]} onClick={supplierShortlist}>Save</CardButton>}
        {actions.screen && showScreen && !isScreeningScheduled && <CardButton event="SCREEN Applicant" icon="mobile-alt" onClick={openInput("screen")}>Screen</CardButton>}
        {actions.interview && showInterview && !isInterviewScheduled && <CardButton event="INTERVIEW Applicant" icon="user-tie" onClick={openInput("interview")}>Interview</CardButton>}
        {actions.offer && !isOffered && <CardButton event="OFFER Applicant" icon="check" onClick={openInput("offer")}>Offer</CardButton>}
        {actions.accept && isSupplier && <CardButton icon="check" onClick={supplierAccept}>Accepted</CardButton>}
        {actions.change && isSupplier && <CardButton icon="pencil-alt" onClick={openInput("change")}>Edit</CardButton>}

        {actions.deliver && <CardButton onClick={supplierDeliver}>Onboard</CardButton>}
        {actions.return && <CardButton onClick={supplierReturn}>Offboard</CardButton>}

        {actions.review && false && <CardButton onClick={() => { }}>Review</CardButton>}

        {!prependChildren && children}

        {actions.decline && isSupplier && <CardButton icon="times" onClick={supplierDecline}>Declined</CardButton>}

        {actions.screen && showScreen && isScreeningScheduled && <CardButton onClick={openInput("screen")}>Reschedule screening</CardButton>}
        {actions.interview && showInterview && isInterviewScheduled && <CardButton onClick={openInput("interview")}>Reschedule interview</CardButton>}
        {actions.offer && isOffered && <CardButton onClick={openInput("offer")}>Modify offer</CardButton>}

        {actions.undo && stage === OrderStage.Accepted && <CardButton onClick={undo}>Undo accept</CardButton>}
        {actions.undo && stage === OrderStage.Declined && <CardButton onClick={undo}>Undo decline</CardButton>}
        {actions.undo && stage === OrderStage.Delivered && <CardButton onClick={undo}>Undo onboard</CardButton>}
        {actions.undo && stage === OrderStage.Cancelled && <CardButton onClick={undo}>Undo cancel</CardButton>}
        {actions.undo && stage === OrderStage.Refused && <CardButton onClick={undo}>Undo decline</CardButton>}
        {actions.undo && value.stage === OrderStage.Removed && <CardButton icon="inbox" onClick={undo}>Unarchive</CardButton>}

        {actions.unshortlist && <CardButton onClick={supplierUnshortlist}>Remove from shortlist</CardButton>}
        {actions.unscreen && <CardButton onClick={supplierUnscreen}>Cancel screening</CardButton>}
        {actions.uninterview && <CardButton onClick={supplierUninterview}>Cancel interview</CardButton>}
        {actions.withdraw && <CardButton onClick={supplierWithdraw}>Withdraw offer</CardButton>}
        {showCustomer && actions.cancel && false && <CardButton onClick={customerCancel}>Cancel acceptance</CardButton>}

        {actions.refuse && <CardButton icon="archive" onClick={supplierRefuseArchive}>Archive</CardButton>}
        {false && actions.remove && isSupplier && <CardButton icon="archive" onClick={supplierRemove}>Archive</CardButton>}
        {showCustomer && actions.remove && !isSupplier && <CardButton icon="archive" onClick={customerRemove} confirm confirmMessage="Archiving this application will remove it from consideration. You can confirm, or cancel to stay in consideration.">Archive</CardButton>}

        {actions.event && product.details.rules?.filter(_ => _.isEnabled && !_.isArchived && _.type === RuleType.EventTriggered && _.eventsTrigger.includes(ResourceEvent.CustomEvent))
          .map((_, i) => <CardButton prependDivider={i == 0} key={i} className="text-truncate" onClick={supplierEvent(_.key)}>{_.name}</CardButton>)}
      </CardButtons>
      {isSupplier && <OrdersInputModal values={[value]} product={product} onUpdate={values => onUpdate?.(values[0])} onUpdateProduct={updateProduct} toggle={() => setInputProps(_initialInputProps)} showPreview {...inputProps} />}
    </>
  );
}

const _steps = new Map<OrderStage, number>([
  [OrderStage.Started, 0],
  [OrderStage.Stopped, 0],
  [OrderStage.Submitted, 1],
  [OrderStage.Shortlisted, 2],
  [OrderStage.ScreeningScheduled, 3],
  [OrderStage.InterviewScheduled, 4],
  [OrderStage.Offered, 5],
  [OrderStage.Declined, 5],
  [OrderStage.Delivered, 6]
]);
function Progress({ value, showProgressLabels = false }: {
  value: OrderModel,
  showProgressLabels?: boolean
}) {
  const stages = value.product?.details.ordersStages ?? [];
  const hasScreening = stages.includes(OrderStage.ScreeningScheduled);
  const hasInterview = stages.includes(OrderStage.InterviewScheduled);
  const step = _steps.get(value.stage) ?? -1;
  return step !== -1 ? (
    <ProgressTracker className="mt-3" center tooltipLabels={!showProgressLabels}>
      <ProgressStep disabled active={step == 0} complete={step > 0} label="Created" />
      <ProgressStep disabled active={step == 1} complete={step > 1} label="Submitted" />
      <ProgressStep disabled active={step == 2} complete={step > 2} label="Shortlisted" />
      {hasScreening && <ProgressStep disabled active={step == 3} complete={step > 3} label="Screening" />}
      {hasInterview && <ProgressStep disabled active={step == 4} complete={step > 4} label="Interviewing" />}
      <ProgressStep disabled active={step == 5} complete={step > 5} label="Offered" />
      <ProgressStep disabled active={step == 6} complete={step > 6} label="Hired" />
    </ProgressTracker>
  ) : null;
}

export function Body({ value, href, className, onClick, onUpdate, showProgressLabels, showDetailedDescription, showAttachments, showVariants, showNotice, showStatus, showMenu, showButtons, openDescription, toggleInput, toggleDiscussion, children, ...attrs }: CardBodyAttributes<OrderModel> & CardBodyProps & {
  showVariants?: boolean,
  showDetailedDescription?: boolean,
  showProgressLabels?: boolean,
  openDescription?: boolean
}) {
  const [t] = useTranslation();
  const [navigate] = useNavigation();
  const classNames = [
    className,
    "hover-container",
    (href || onClick) ? "hover-shadow cursor-pointer" : ""
  ].join(" ");
  return (
    <CardBody className={classNames} onClick={onClick || navigate(href)} {...attrs}>
      <ProductTitleText value={value.product} />
      <ProductKeyFeaturesText value={value.product.details} />
      <ProductDescriptionCollapsibleText value={value.product} showDetailedDescription={showDetailedDescription} isOpen={openDescription} />
      {showVariants && value.actions.move && <Variants value={value} onUpdate={onUpdate} />}
      <Progress {...{ value, showProgressLabels }} />
      {showNotice && <Notice className="mt-3" value={value} onUpdate={onUpdate} showButtons={!showButtons} />}
      <OrderDetailsText value={value} toggleInput={toggleInput} />
      {showAttachments && <CardAttachmentsList values={value.attachments} />}
      {children}
      {showStatus && <Status {...{ value, toggleDiscussion }} />}
      {showButtons && <Buttons {...{ value, onUpdate, toggleInput }} />}
    </CardBody>
  );
}

export default ({ value, href, className, onUpdate, onClick, hideRemoved, showProgressLabels, showDetailedDescription, showHeader, showAttachments, showVariants, showNotice, showStatus, showMenu, showButtons, showInput, showDiscussion, showShare, openInput, openDiscussion, openShare, openDescription, children }: CardAttributes<OrderModel> & {
  showVariants?: boolean,
  showDetailedDescription?: boolean,
  showProgressLabels?: boolean,
  openDescription?: boolean
}) => {
  const [t] = useTranslation();
  const { isOpenInput, toggleInput, isOpenDiscussion, toggleDiscussion } = useCardState({ showDiscussion, showInput, showShare, openDiscussion, openInput, openShare });
  const classNames = [
    className,
    value.state === ResourceState.Removed && hideRemoved ? "fade-out" : "",
  ].join(" ");
  return (
    <>
      <Card className={classNames}>
        {showHeader && <CardHeader className="bg-white" tag="h3">{t(value.product.details.type)}</CardHeader>}
        <Body {...{ value, href, onClick, onUpdate, showDetailedDescription, showProgressLabels, showVariants, showNotice, showAttachments, showStatus, showMenu, showButtons, openDescription, toggleInput, toggleDiscussion }}>
          {children}
        </Body>
        {showDiscussion && <CardDiscussionListGroup forcePublic value={value} onUpdate={onUpdate} isOpen={isOpenDiscussion} />}
      </Card>
      {showInput && <OrderInputModal isOpen={isOpenInput} toggle={toggleInput} value={value} onUpdate={onUpdate} title={value.product.details.displayTitle} />}
    </>
  );
}
