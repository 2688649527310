import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigation, preventDefault } from "../hooks/NavigationHook";

library.add(
  faSearch
);

function getUrl(query: string) {
  return `https://www.google.com/search?q=${query}`;
}

export default ({ query }: { query: string }) =>{
  const [navigate] = useNavigation();
  const url = getUrl(query);
  return (
    !query ? null :
      <>
        <a target='_blank' rel="noopener noreferrer" className='mr-1' href={url} onClick={navigate(url)}>
          <FontAwesomeIcon size='sm' icon={["fas", "search"]} />
        </a>
        {query}
      </>
  );
}
