import { library } from "@fortawesome/fontawesome-svg-core";
import { faArchive, faCheck, faInbox, faPause, faPencilAlt, faPlay, faShare } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Alert, CardBody, CardBodyProps, CardHeader, CardSubtitle, CardTitle, Collapse } from "reactstrap";
import Card, { CardAttributes, CardBodyAttributes, CardButtonsAttributes } from "../components/Card";
import { CardSelectButton } from "../components/CardAttributionBody";
import CardDiscussionListGroup, { Fragment as DiscussionFragment } from "../components/CardDiscussionListGroup";
import CardMarkdownText from "../components/CardMarkdownText";
import { CardButton, CardButtons, CardDiscussionSummaryStatusItems, CardStatus, CardStatusDetail, CardStatusGroup, CardTasksStatusItem } from "../components/CardStatus";
import { useMutation } from "../hooks/ApiProvider";
import { useCardState } from "../hooks/CardStateHook";
import { useToggle } from "../hooks/CommonHooks";
import { locationBase, useNavigation } from "../hooks/NavigationHook";
import { useTranslation } from "../hooks/TranslationProvider";
import ShareModal, { Fragment as ShareInputFragment, MessageModal as ShareMessageModal } from "../modals/ShareModal";
import WorkItemDetailsInputModal, { DetailsFragment as DetailsInputFragment } from "../modals/WorkItemInputModal";
import { ResourceKind, ResourceState, WorkItemModel, AreaType, WorkItemType } from "../types/api-graph-types";

library.add(
  faArchive,
  faPause,
  faShare,
  faCheck,
  faPlay,
  faInbox,
  faPencilAlt
);

export const Fragment = `
  id
  kind
  state
  details {
    type
    title
    description
    jobsCount
    dueOn
    ${DetailsInputFragment}
  }
  actions {
    start
    change
    share
    complete
    stop
    undo
    remove
  }
  policy { role }
  jobsSummary {
    positiveCount
    neutralCount
    negativeCount
    resolvedCount
    completedCount
  }
  shares { ${ShareInputFragment} }
  ${DiscussionFragment}
`;

const _startWorkItem = `mutation startWorkItem($id:ID!) {
  workitem(id:$id) { start commit { ${Fragment} } }
}`;

const _stopWorkItem = `mutation stopWorkItem($id:ID!) {
  workitem(id:$id) { stop commit { ${Fragment} } }
}`;

const _removeWorkItem = `mutation removeWorkItem($id:ID!) {
  workitem(id:$id) { remove commit { ${Fragment} } }
}`;

const _undoWorkItem = `mutation undoWorkItem($id:ID!) {
  workitem(id:$id) { undo commit { ${Fragment} } }
}`;

interface OperationResults {
  workitem: {
    commit: WorkItemModel
  }
};

export async function ToExcel(values: WorkItemModel[]) {
  const xlsx = await import("xlsx");
  const data = [[
    "Type",
    "Area",
    "Title",
    "Description",
    "Id"
  ]];

  values.forEach(_ => {
    data.push([
      _.details.type,
      _.details.area,
      _.details.title,
      _.details.description,
      _.id
    ]);
  });

  const enums = [[
    "Type",
    "Area",
  ]];
  const types = Object.values(WorkItemType);
  const areas = Object.values(AreaType);
  [...Array(100)].forEach((_, i) => {
    enums.push([
      types[i] || "",
      areas[i] || ""
    ])
  });

  const wb = xlsx.utils.book_new();
  const ws1 = xlsx.utils.aoa_to_sheet(data);
  const ws2 = xlsx.utils.aoa_to_sheet(enums);
  xlsx.utils.book_append_sheet(wb, ws1, "WorkItems");
  xlsx.utils.book_append_sheet(wb, ws2, "Values");
  xlsx.writeFile(wb, "WorkItems.xlsx");
}

function Text({ value }: { value: WorkItemModel }) {
  const [t] = useTranslation();
  return (
    <>
      <CardSubtitle tag='h5'>{t(value.details.type)}</CardSubtitle>
      {value.details.title && <CardTitle tag="h4">{value.details.title}</CardTitle>}
      <CardMarkdownText justify hyphens source={value.details.description} />
    </>
  );
}

export function Notice({ value, className }: { value: WorkItemModel, className?: string }) {
  return value.state === ResourceState.Proposed ? (
    <Alert className={className} color="primary">
      <h5>Paused</h5>
      <p>This workitem is currently paused.</p>
    </Alert>
  ) : null;
}

function Status({ value, toggleDiscussion }: {
  value: WorkItemModel,
  toggleDiscussion?: () => void
}) {
  const [isOpenJobsSummary, toggleJobsSummary] = useToggle();
  return (
    <>
      <CardStatus>
        <CardStatusGroup>
          <CardTasksStatusItem value={value.jobsSummary.positiveCount + value.jobsSummary.neutralCount} max={value.details.jobsCount} toggle={toggleJobsSummary} />
        </CardStatusGroup>
        <CardDiscussionSummaryStatusItems reviews={value.reviewsSummary} comments={value.commentsSummary} toggleDiscussion={toggleDiscussion} />
      </CardStatus>
      <Collapse isOpen={isOpenJobsSummary}>
        <CardStatus>
          <CardStatusDetail value={value.jobsSummary.positiveCount + value.jobsSummary.neutralCount} valueClassName="strong" label="Approved" />
          {value.jobsSummary.negativeCount > 0 && <CardStatusDetail value={value.jobsSummary.negativeCount} valueClassName="strong" label="Rejected" />}
          {value.jobsSummary.resolvedCount > 0 && <CardStatusDetail value={value.jobsSummary.resolvedCount} valueClassName="strong" label="Under Review" />}
        </CardStatus>
      </Collapse>
    </>
  );
}

function Buttons({ value, onUpdate, toggleInput, toggleShare }: CardButtonsAttributes<WorkItemModel>) {
  const [mutation] = useMutation<OperationResults>();
  const { id, actions } = value;
  const startWorkItem = async () => {
    const result = await mutation(_startWorkItem, { id });
    onUpdate?.(result.workitem.commit);
  };
  const stopWorkItem = async () => {
    const result = await mutation(_stopWorkItem, { id });
    onUpdate?.(result.workitem.commit);
  };
  const removeWorkItem = async () => {
    const result = await mutation(_removeWorkItem, { id });
    onUpdate?.(result.workitem.commit);
  };
  const undoWorkItem = async () => {
    const result = await mutation(_undoWorkItem, { id });
    onUpdate?.(result.workitem.commit);
  };
  return (
    <CardButtons className="mt-3">
      {actions.start && <CardButton icon="play" onClick={startWorkItem}>Start</CardButton>}
      {actions.change && toggleInput && <CardButton icon="pencil-alt" onClick={toggleInput}>Edit</CardButton>}
      {actions.share && toggleShare && <CardButton icon="share" onClick={toggleShare}>Share</CardButton>}
      {actions.stop && <CardButton icon="pause" onClick={stopWorkItem}>Pause</CardButton>}
      {actions.remove && <CardButton icon="archive" onClick={removeWorkItem}>Archive</CardButton>}
      {actions.undo && value.state == ResourceState.Removed && <CardButton icon="inbox" onClick={undoWorkItem}>Unarchive</CardButton>}
    </CardButtons>
  );
}

function Body({ value, href, onSelect, selected, showSelect, showNotice, showStatus, showMenu, showButtons, toggleInput, toggleDiscussion, toggleShare, onUpdate, onClick, className, children, ...attrs }: CardBodyAttributes<WorkItemModel> & CardBodyProps) {
  const [navigate] = useNavigation();
  const classNames = [
    className,
    onClick || href || toggleInput ? "hover-shadow hover-container cursor-pointer" : ""
  ].join(" ");
  return (
    <CardBody className={classNames} onClick={onClick || navigate(href) || toggleInput || undefined} {...attrs}>
      {showSelect && <CardSelectButton className="btn-float" {...{ selected, onSelect }} />}
      <Text value={value} />
      {children}
      {showNotice && <Notice value={value} />}
      {showStatus && <Status {...{ value, toggleDiscussion }} />}
      {showButtons && <Buttons {...{ value, onUpdate, toggleInput, toggleShare }} />}
    </CardBody>
  );
}

export default ({ value, href, onSelect, selected, showHeader, showAttribution, showSelect, showNotice, showStatus, showMenu, showDiscussion, showInput, showShare, openDiscussion, openInput, openShare, showButtons, onClick, onUpdate, children }: CardAttributes<WorkItemModel>) => {
  const [t] = useTranslation();
  const { isOpenInput, isOpenDiscussion, isOpenShare, toggleInput, toggleDiscussion, toggleShare } = useCardState({ showDiscussion, showInput, showShare, openDiscussion, openInput, openShare })
  return (
    <>
      <Card>
        {showHeader && <CardHeader className="bg-white" tag="h4">{t(value.details.type)}</CardHeader>}
        <Body {...{ value, href, selected, onUpdate, onClick, onSelect, showSelect, showNotice, showStatus, showMenu, showButtons, toggleInput, toggleDiscussion, toggleShare }} >
          {children}
        </Body>
        {showDiscussion && <CardDiscussionListGroup forcePublic value={value} onUpdate={onUpdate} isOpen={isOpenDiscussion} />}
      </Card>
      {showInput && <WorkItemDetailsInputModal value={value} onUpdate={onUpdate} isOpen={isOpenInput} toggle={toggleInput} />}
      {showShare && <ShareMessageModal value={value} title={value.details.title} url={`${locationBase}/my/workitems/${value.id}`} isOpen={isOpenShare} toggle={toggleShare} />}
    </>
  );
}
