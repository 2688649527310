import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Col, Row, Modal, ModalBody, ModalFooter, Button, CustomInput } from "reactstrap";
import JobCard, { Fragment as JobFragment } from "../cards/JobCard";
import OrderCard, { Fragment as OrderFragment } from "../cards/OrderCard";
import CardColumns from "../components/CardColumns";
import { setReplace, useMutation, useQuery, nextSequence, useReadyState } from "../hooks/ApiProvider";
import { preventDefault, useFragment, useNavigation, useQueryString, decompress } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { JobModel, OrderModel, ResourceState, ResourceEvent, ResourceKind } from "../types/api-graph-types";
import LoadingController from "./LoadingController";
import { useEvent } from "../hooks/EventsHook";
import { useAsyncEffect } from "../hooks/CommonHooks";
import { useCommentOperations } from "../hooks/DiscussionHook";
import FormGroup from "../components/FormGroup";
import MutationButton from "../components/MutationButton";
import moment from "moment";
import { DeepPartial } from "redux";

library.add(faInfoCircle);

const _getOrder = `query getOrder($id:ID) {
  order(id:$id) {
    product { 
      details { type title subtitle displayTitle } 
      orders: myOrders(states:[PROPOSED,IN_PROGRESS,RESOLVED]) { id kind stage createdOn ${OrderFragment} }
    }
    state
    ${OrderFragment}
    jobs(states:[PROPOSED,IN_PROGRESS,RESOLVED,COMPLETED]) {
      ${JobFragment}
    }
  }
}`;

const _mergeOrder = `mutation mergeOrder($id:ID! $oid:ID!) {
  order(id:$id) { 
    merge(id:$oid) {
      product {
        orders: myOrders(states:[PROPOSED,IN_PROGRESS,RESOLVED]) { id kind stage createdOn ${OrderFragment} }
      }
    }
  }
}`

const _applyOrderAction = `mutation applyOrderAction($id:ID! $action:String!) {
  order(id:$id) { 
    apply(action:$action)
    commit { ${OrderFragment} }
  }
}`

interface Result {
  order: OrderModel
}

interface OperationResults {
  order: {
    merge: OrderModel
    commit: OrderModel
  }
}

const _initialQuery = {
  action: ""
}

const _initialFragment = {
  show: "",
  comment_form: ""
}

interface CommentForm {
  q: ReadonlyArray<{
    q: string,
    o: string[],
    m: 0 | 1
  }>
  x: string
}

function CommentFormModal({ isOpen, toggle, value, onUpdate, compressedForm }: {
  isOpen?: boolean,
  toggle?: () => void,
  value: OrderModel,
  onUpdate: (value: DeepPartial<OrderModel>) => void
  compressedForm: string
}) {
  const [uid] = useState(nextSequence());
  const { addComment } = useCommentOperations();
  const [readyState, setReadyState] = useReadyState();
  const [responses, setResponses] = useState<string[]>([]);
  const submit = async () => {
    const message = form?.q?.map((_, i) => {
      const prefix = `${i}.`;
      const selected = responses.filter(r => r.startsWith(prefix));
      const selectedOptions = _.o?.map((o, j) => selected.includes(`${i}.${j}`) ? o : "").filter(o => !!o).join(" ");
      return !!selectedOptions ? `${_.q} ${selectedOptions}` : "";
    }).filter(m => !!m).join(" ");
    if (message) {
      const comment = await addComment(value.id, ResourceKind.Order, { message }, setReadyState);
      onUpdate?.({ comments: [comment] });
    }
    toggle?.();
  }
  const formJson = decompress(compressedForm)
  const form = formJson.startsWith('{') && JSON.parse(formJson) as CommentForm || undefined;
  const isExpired = form && form.x && moment().utc().isAfter(moment(form.x)) || false;

  return !!form ? (
    <Modal isOpen={isOpen}>
      {!isExpired ?
        <ModalBody>
          {form.q?.map((_, i) =>
            <FormGroup key={i}>
              <h3>{_.q}</h3>
              {_.o?.map((o, j) => {
                const prefix = `${i}.`;
                const key = `${i}.${j}`;
                const checked = responses.some(r => r === key);
                const multi = _.m === 1;
                const toggle = () => setResponses(!multi ? [...responses.filter(r => !r.startsWith(prefix)), key] : checked ? responses.filter(r => r !== key) : [...responses, key]);
                return (
                  <CustomInput id={`${uid}.0.${key}`} type={multi ? "checkbox" : "radio"} label={o} checked={checked} onClick={toggle} />
                )
              })}
            </FormGroup>
          )}
        </ModalBody>
        :
        <ModalBody>
          The link you clicked is no longer accepting responses. Try contacting the
          owner of this listing if you think this is a mistake.
        </ModalBody>
      }
      <ModalFooter>
        <Button color="link" onClick={toggle}>Cancel</Button>
        <MutationButton readyState={readyState} disabled={isExpired || responses.length == 0} color="primary" onClick={submit}>Submit</MutationButton>
      </ModalFooter>
    </Modal>
  ) : null;
}

export default ({ id }: { id: string }) => {
  const [query, setQuery, updateQuery] = useQueryString(_initialQuery);
  const [fragment, setFragment, updateFragment] = useFragment(_initialFragment);
  const [t, d] = useTranslation();
  const [navigate] = useNavigation();
  const [notice, setNotice] = useState<string>();
  const [result, updateResult, isLoading] = useQuery<Result>(_getOrder, { id });
  const [mutation] = useMutation<OperationResults>();
  const value = result?.order;
  useTitle(value && value.product.details.displayTitle);
  useEvent(ResourceEvent.CustomerViewedOrder, value);
  useAsyncEffect(async () => {
    const { action } = query;
    if (action) {
      const result = await mutation(_applyOrderAction, { id, action });
      updateResult({ order: result.order.commit });
      setNotice("Thanks! Your response has been recorded.");
      updateQuery({ action: "" });
    }
  }, [query.action]);

  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }
  const merge = async (oid: string) => {
    const result = await mutation(_mergeOrder, { id, oid });
    updateResult({ order: { product: setReplace(result.order.merge.product) } });
  }

  const update = (order: Partial<OrderModel>) => updateResult({ order });
  const updateJob = (job: Partial<JobModel>) => updateResult({ order: { jobs: [job] } });
  const otherIncomplete = value.product.orders.filter(_ => _.id !== value.id);
  const isIncomplete = value.state === ResourceState.Proposed || value.state === ResourceState.InProgress || value.state === ResourceState.Resolved;
  const openInput = fragment.show === "input";
  const openCommentForm = !!fragment.comment_form;

  return (
    <>
      {!!notice ?
        <Row className="mt-3">
          <Col>
            <Alert color="primary" isOpen={!!notice} toggle={() => setNotice(undefined)}>
              <a className="close" href="#" onClick={() => setNotice(undefined)}></a>
              <p>
                <FontAwesomeIcon icon="info-circle" />
                {" "}
                {notice}
              </p>
            </Alert>
          </Col>
        </Row>
        : isIncomplete && otherIncomplete.length > 0 &&
        <Row className="mt-3">
          <Col>
            {otherIncomplete.map((_, i) =>
              <Alert key={i} color="primary" >
                <h5>Duplicate application</h5>
                <p>You also applied on {d(_.createdOn, DateDisplayFormat.HumanDate)}. <a href="#" onClick={navigate(`/my/goals/${_.id}`)}>View</a> | <a href="#" onClick={preventDefault(() => merge(_.id))}>Merge</a></p>
              </Alert>
            )}
          </Col>
        </Row>
      }
      <Row className="mt-3">
        <Col>
          <OrderCard value={value} onUpdate={update} showDetailedDescription showProgressLabels showAttachments showVariants showDiscussion showNotice showStatus showButtons showInput openDescription openDiscussion openInput={openInput} />
        </Col>
      </Row>
      {openCommentForm && <CommentFormModal isOpen={openCommentForm} toggle={() => updateFragment({ comment_form: undefined })} compressedForm={fragment.comment_form} value={value} onUpdate={update} />}
      <Row className="mt-3">
        <CardColumns md={2} xl={3}>
          {value.jobs.map((_, i) => <JobCard key={i} href={`/my/tasks/${_.id}`} value={_} onUpdate={updateJob} showStatus showButtons />)}
        </CardColumns>
      </Row>
    </>
  )
};
