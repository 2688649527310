import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { MouseEvent, useEffect, useState } from "react";
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Nav, NavItem, NavLink, Row, UncontrolledButtonDropdown, UncontrolledDropdown } from "reactstrap";
import { ColumnProps } from "reactstrap/lib/Col";
import JobCard, { Fragment as JobFragment } from "../cards/JobCard";
import ProfileInputCard from "../cards/MyProfile/ProfileInputCard";
import OrderCard, { Fragment as OrderFragment } from "../cards/OrderCard";
import OrganizationCard, { Fragment as OrganizationFragment } from "../cards/OrganizationCard";
import PersonCard, { ExcelFragment as PersonsExcelFragment, Fragment as PersonFragment, ToExcel as PersonsToExcel } from "../cards/PersonCard";
import ProductCard, { Fragment as ProductFragment } from "../cards/ProductCard";
import WorkItemCard, { Fragment as WorkItemFragment, ToExcel as WorkItemsToExcel } from "../cards/WorkItemCard";
import CardColumns from "../components/CardColumns";
import { CustomDropdown, CustomDropdownItem } from "../components/CustomDropdown";
import FormGroup from "../components/FormGroup";
import { MoneyRangeInput } from "../components/MoneyRangeInput";
import Show from "../components/Show";
import LoadingController from "../controllers/LoadingController";
import { DeepPartial, useMutation, useQuery } from "../hooks/ApiProvider";
import { useToggle } from "../hooks/CommonHooks";
import { useMe } from "../hooks/MeProvider";
import { download, preventDefault, useNavigation, useQueryString } from "../hooks/NavigationHook";
import { Preference, usePreference } from "../hooks/PreferencesProvider";
import { useSearch } from "../hooks/SearchProvider";
import { useTitle } from "../hooks/TitleHook";
import { Scopes, useClaims } from "../hooks/TokenProvider";
import { DateDisplayFormat, NumberDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import OrganizationInputModal, { New as NewOrganization } from "../modals/OrganizationInputModal";
import { ImportModal as OrganizationsImportModal } from "../modals/OrganizationsInputModal";
import ProductInputModal, { New as NewProduct } from "../modals/ProductInputModal";
import { ImportModal as ProductsImportModal } from "../modals/ProductsInputModal";
import SubscriptionChooserModal from "../modals/SubscriptionChooserModal";
import SuggestProductModal from "../modals/SuggestProductModal";
import WorkItemInputModal, { New as NewWorkItem } from "../modals/WorkItemInputModal";
import { AreaType, CityCode, FunctionType, JobModel, OrderModel, OrganizationModel, PersonModel, ProductModel, ProductType, ResourcesSummaryModel, ResourceState, ReviewType, ServiceType, WorkItemModel } from "../types/api-graph-types";
import { CityCodes, ServiceTypes } from "../types/common-types";
import NewProductWorkflow from "../workflows/NewProductWorkflow";

library.add(faCircleNotch);

export enum View {
  Opportunities = "opportunities",
  Goals = "goals",
  Tasks = "tasks",
  Listings = "listings",
  WorkItems = "workitems",
  Reviews = "review",
  People = "people",
  Teams = "teams"
}

const _query = [
  `query getMyOrders($states:[ResourceState] $query:String $interval:String $first:Int) { 
     me { orders(states:$states interval:$interval query:$query first:$first) { id ${OrderFragment} } } 
  }`,
  `query getMyJobs($states:[ResourceState] $query:String $interval:String $first:Int) { 
     me { jobs(states:$states interval:$interval query:$query first:$first) { id ${JobFragment} } } 
  }`,
  `query getMyProducts($states:[ResourceState] $query:String $functions:[FunctionType] $interval:String $first:Int) { 
     me { products(states:$states interval:$interval query:$query functions:$functions first:$first) { id ${ProductFragment({ showStatus: true })} } } 
  }`,
  `query getMyWorkItems($states:[ResourceState] $query:String $interval:String $first:Int) { 
     me { workitems(states:$states interval:$interval query:$query first:$first) { id ${WorkItemFragment} } } 
  }`,
  `query getPersons($query:String $first:Int $graduationInterval:String $cities:[CityCode]) { 
     persons(states:[COMPLETED] query:$query first:$first graduationInterval:$graduationInterval cities:$cities) { id ${PersonsExcelFragment} ${PersonFragment} } 
  }`,
  `query getMyOrganizations($query:String $first:Int) { 
     me { organizations(query:$query first:$first) { id ${OrganizationFragment} } }
  }`,
  `query getProducts($query:String $interval:String $first:Int $cities:[CityCode] $functions:[FunctionType] $services:[ServiceType] $areas:[AreaType] $productTypes:[ProductType]) { 
     products: displayProducts(states:[IN_PROGRESS] deliveryInterval:$interval query:$query first:$first types:$productTypes cities:$cities functions:$functions services:$services areas:$areas) { id ${ProductFragment({ showButtons: true })} }
  }`,
  `query getReviews($query:String $first:Int $reviewTypes:[ReviewType] $isJobReviews:Boolean!) { 
    reviewJobsSummary @include(if:$isJobReviews) { resolvedCount }
    reviewJobs(query:$query first:$first) @include(if:$isJobReviews) { id ${JobFragment} } 
    reviews(query:$query types:$reviewTypes first:$first) @skip(if:$isJobReviews) { id kind }
  }`,
];

const _moveProducts = `mutation moveProducts($ids:[ID!] $subscriptionId:ID) {
  products(ids:$ids) {
    move(subscriptionId:$subscriptionId) { id ${ProductFragment()} }
  }
}`;

const _exportProducts = `mutation exportMyProducts($states:[ResourceState] $query:String $functions:[FunctionType] $interval:String $first:Int) { 
  me {
    products(states:$states interval:$interval query:$query functions:$functions first:$first) {
      export {
        dataUri
      }
    } 
  }
}`;

const _exportOrganizations = `mutation exportMyOrganizations($query:String $first:Int) { 
  me {
    organizations(query:$query first:$first) {
      export {
        dataUri
      }
    } 
  }
}`;

const _moveWorkItems = `mutation moveWorkItems($ids:[ID!] $subscriptionId:ID) {
  workitems(ids:$ids) {
    move(subscriptionId:$subscriptionId) { id ${WorkItemFragment}  }
  }
}`;

interface Result {
  me: {
    jobs: JobModel[],
    orders: OrderModel[],
    products: ProductModel[],
    workitems: WorkItemModel[],
    organizations: OrganizationModel[],
  }
  persons: PersonModel[],
  products: ProductModel[]
  reviewJobs: JobModel[],
  reviewJobsSummary: ResourcesSummaryModel
}

interface OperationResult {
  me: {
    products: {
      export: {
        dataUri: string
      }
    }
    organizations: {
      export: {
        dataUri: string
      }
    }
  }
  products: {
    move: ProductModel[]
  }
  workitems: {
    move: WorkItemModel[]
  }
}

enum States {
  Complete = "I",
  Archived = "A",
  Active = ""
}

export enum Workflow {
  Null = "",
  NewListing = "NEW_LISTING"
}

interface Query {
  graduationOn: string;
  amount: string;
  cities: CityCode[];
  topics: (ServiceType | AreaType)[];
  productTypes: ProductType[];
  reviewTypes: ReviewType[];
  states: States;
  during: string;
  workflow: Workflow;
}

const _views = [View.Goals, View.Tasks, View.Listings, View.WorkItems, View.People, View.Teams, View.Opportunities, View.Reviews];
const _titles = new Map<View, string>([
  [View.Goals, "Goals"],
  [View.Tasks, "Tasks"],
  [View.Listings, "Listings"],
  [View.WorkItems, "Work Items"],
  [View.Reviews, "Reviews"],
  [View.People, "People"],
  [View.Teams, "Teams"],
  [View.Opportunities, "Opportunities"]
]);
const _activeStates = [ResourceState.Proposed, ResourceState.InProgress, ResourceState.Resolved];
const _completedStates = [ResourceState.Completed];
const _archivedStates = [ResourceState.Removed];
const _initialQueryString: Query = {
  graduationOn: "",
  amount: "",
  cities: [],
  topics: [],
  productTypes: [],
  reviewTypes: [],
  states: States.Active,
  during: "",
  workflow: Workflow.Null
};
const _initialVars = {
  first: 24
};

const _filterCities = [CityCode.InBlr, CityCode.InBom, CityCode.InDel];
const _filterServices = [ServiceType.LegalServiceLitigation, ServiceType.LegalServiceTransactional, ServiceType.LegalServiceInHouseCounsel, ServiceType.AccountingServiceCorporateFinance, ServiceType.AccountingServiceTax];
const _filterFunctions = [FunctionType.LegalFunction, FunctionType.BusinessDevelopmentFunction, FunctionType.AccountingFunction, FunctionType.SoftwareEngineeringFunction];

function _getDefaultView(scopes?: Set<string>): View {
  if (!scopes) return View.Goals;
  else if (scopes.has(Scopes.ProductEnumerate)) return View.Listings;
  else if (scopes.has(Scopes.JobReviewAll)) return View.Reviews;
  else if (scopes.has(Scopes.PersonEnumerateAll)) return View.People;
  else if (scopes.has(Scopes.WorkItemEnumerate)) return View.WorkItems;
  else if (scopes.has(Scopes.OrderEnumerate)) return View.Goals;
  else if (scopes.has(Scopes.ProductEnumeratePublic)) return View.Opportunities;
  else if (scopes.has(Scopes.JobEnumerate)) return View.Tasks;
  else return View.Goals;
}

function SideNavigationCol({ view, ...attrs }: {
  md: ColumnProps,
  view: View
}) {
  const [navigate] = useNavigation();
  return (
    <Col className="mt-3" {...attrs}>
      <Nav className='flex-md-column sticky-top'>
        <Show scopes={[Scopes.ProductEnumeratePublic]}>
          <NavItem className='mr-1'>
            <NavLink active={view === View.Opportunities} href="#" onClick={navigate("/my/opportunities")}>
              {_titles.get(View.Opportunities)}
            </NavLink>
          </NavItem>
        </Show>
        <Show scopes={[Scopes.OrderEnumerate]}>
          <NavItem className='mr-1'>
            <NavLink active={view === View.Goals} href="#" onClick={navigate("/my/goals")}>
              {_titles.get(View.Goals)}
            </NavLink>
          </NavItem>
        </Show>
        <Show scopes={[Scopes.JobEnumerate]}>
          <NavItem className='mr-1'>
            <NavLink active={view === View.Tasks} href="#" onClick={navigate("/my/tasks")}>
              {_titles.get(View.Tasks)}
            </NavLink>
          </NavItem>
        </Show>
        <Show scopes={[Scopes.ProductEnumerate]}>
          <NavItem className='mr-1'>
            <NavLink active={view === View.Listings} href="#" onClick={navigate("/my/listings")}>
              {_titles.get(View.Listings)}
            </NavLink>
          </NavItem>
        </Show>
        <Show scopes={[Scopes.WorkItemEnumerate]}>
          <NavItem className='mr-1'>
            <NavLink active={view === View.WorkItems} href="#" onClick={navigate("/my/workitems")}>
              {_titles.get(View.WorkItems)}
            </NavLink>
          </NavItem>
        </Show>
        <Show scopes={[Scopes.JobReviewAll]}>
          <NavItem className='mr-1'>
            <NavLink active={view === View.Reviews} href="#" onClick={navigate("/my/reviews")}>
              {_titles.get(View.Reviews)}
            </NavLink>
          </NavItem>
        </Show>
        <Show scopes={[Scopes.PersonEnumerateAll]}>
          <NavItem className='mr-1'>
            <NavLink active={view === View.People} href="#" onClick={navigate("/my/team")}>
              {_titles.get(View.People)}
            </NavLink>
          </NavItem>
        </Show>
        <Show scopes={[Scopes.OrganizationEnumerateAll]}>
          <NavItem className='mr-1'>
            <NavLink active={view === View.Teams} href="#" onClick={navigate("/my/teams")}>
              {_titles.get(View.Teams)}
            </NavLink>
          </NavItem>
        </Show>
      </Nav>
    </ Col>
  );
}

function WorkflowRow({ view, query, updateQuery }: {
  view: View,
  query: Query,
  updateQuery: (value: Partial<Query>) => void
}) {
  const workflow = query.workflow;
  return (
    <Row className="mt-3">
      <Col>
        {workflow === Workflow.NewListing && <NewProductWorkflow />}
      </Col>
    </Row>
  );
}

function ContentRow({ view, query, setLoading, updateQuery }: {
  query: Query,
  updateQuery: (value: Partial<Query>) => void
  setLoading: (value: boolean) => void,
  view: View
}) {
  const viewIndex = _views.indexOf(view);
  const [search, setSearch] = useSearch();
  const [navigate] = useNavigation();
  const [t, d, n] = useTranslation();
  const [isOpenNewProduct, toggleNewProduct] = useToggle();
  const [isOpenNewWorkItem, toggleNewWorkItem] = useToggle();
  const [isOpenNewOrganization, toggleNewOrganization] = useToggle();
  const [isOpenProductSubscriptionChooser, toggleProductSubscriptionChooser] = useToggle();
  const [isOpenWorkItemSubscriptionChooser, toggleWorkItemSubscriptionChooser] = useToggle();
  const [isOpenSuggestProduct, toggleSuggestProduct] = useToggle();
  const [isOpenImportProducts, toggleImportProducts] = useToggle();
  const [isOpenImportOrganizations, toggleImportOrganizations] = useToggle();
  const [functions, setFunctions] = usePreference<FunctionType[]>(Preference.MyHomePageFunctions, [FunctionType.LegalFunction]);
  const [me] = useMe();
  const [vars, setVars] = useState(_initialVars);
  const [selection, setSelection] = useState(new Set<string>());
  const [startOn, endOn] = query.during.split("/", 2);
  const hasAvailabilityFilter = !!startOn;
  const hasGraduationOnFilter = !!query.graduationOn;
  const hasInterestsFilter = query.topics?.length > 0;
  const hasCitiesFilter = query.cities?.length > 0;
  const hasCompensationFilter = !!query.amount;
  const [graduationOnAfter, graduationOnBefore] = query.graduationOn.split("/", 2).map(_ => moment().add(moment.duration(_)).format("YYYY-MM-DD"));
  const queryVariables = {
    ...vars,
    query: search,
    graduationInterval: hasGraduationOnFilter ? `${graduationOnAfter}/${graduationOnBefore}` : null,
    cities: query.cities,
    states: query.states === States.Complete ? _completedStates : query.states === States.Archived ? _archivedStates : _activeStates,
    interval: !query.during ? null
      : query.during.indexOf("/") > 0 ? query.during
        : query.during == "RECENT" ? `P1Y/${moment().format("YYYY-MM-DD")}`
          : `${query.during}-01-01/P1Y`,
    services: ServiceTypes.filter(_ => query.topics.includes(_)),
    areas: Object.values(AreaType).filter(_ => query.topics.includes(_)),
    functions: functions,
    productTypes: query.productTypes,
    reviewTypes: query.reviewTypes,
    isJobReviews: query.reviewTypes?.length == 0 || query.reviewTypes[0] === ReviewType.JobReview
  };
  const [result, updateResult, isLoading] = useQuery<Result>(_query[viewIndex], queryVariables);

  useEffect(() => setSelection(new Set(ids.filter(id => selection.has(id)))), [result]);
  useEffect(() => setLoading(isLoading), [isLoading]);

  const ids = result && [
    () => result.me?.orders?.map(_ => _.id),
    () => result.me?.jobs?.map(_ => _.id),
    () => result.me?.products?.map(_ => _.id),
    () => result.me?.workitems?.map(_ => _.id),
    () => result.persons?.map(_ => _.id),
    () => result.me?.organizations?.map(_ => _.id),
    () => result.products?.map(_ => _.id),
    () => result.reviewJobs?.map(_ => _.id)
  ][viewIndex]() || [];
  const count = ids.length;
  const [mutation] = useMutation<OperationResult>();

  const getMore = (e: MouseEvent) => setVars({ ...vars, first: e.getModifierState("Shift") ? 1024 : vars.first * 2 });
  const setGraduationOnFilter = (graduationOn: string) => updateQuery({ graduationOn });
  const setLocationFilter = (cities: CityCode[]) => updateQuery({ cities });
  const toggleLocationFilter = (e: React.MouseEvent, city: CityCode) => setLocationFilter(e.getModifierState("Control") ? [...query.cities, city] : [city]);
  const setTopicsFilter = (topics: (ServiceType | AreaType)[]) => updateQuery({ topics });
  const toggleTopicsFilter = (e: React.MouseEvent, topic: ServiceType | AreaType) => setTopicsFilter(e.getModifierState("Control") ? [...query.topics, topic] : [topic]);
  const setFunctionsFilter = (functions: FunctionType[]) => {
    setFunctions(functions);
    updateQuery({ topics: query.topics.filter(v => functions.map(f => f.replace("_FUNCTION", "")).some(p => v.startsWith(p))) });
  }
  const toggleFunctionsFilter = (e: React.MouseEvent, func: FunctionType) => setFunctionsFilter(e.getModifierState("Control") ? [...functions.filter(_ => _ !== func), func] : [func]);
  const setAvailabilityFilter = (startOn: string, endOn?: string) => {
    endOn = endOn || moment(startOn).add(moment.duration("P28D")).format("YYYY-MM-DD");
    updateQuery({ during: `${startOn}/${endOn}` });
  }
  const clearFilters = () => {
    setSearch("");
    updateQuery(_initialQueryString);
    setVars(_initialVars);
  }
  const inFunction = (value: AreaType | ServiceType) => functions.map(f => f.replace("_FUNCTION", "")).some(prefix => value.startsWith(prefix));

  const updateOrder = (order: DeepPartial<OrderModel>) => updateResult({ me: { orders: [order] } });
  const updateJob = (job: DeepPartial<JobModel>) => updateResult({ me: { jobs: [job] } });
  const updateWorkItem = (workitem: DeepPartial<WorkItemModel>) => updateResult({ me: { workitems: [workitem] } });
  const updateProduct = (product: DeepPartial<ProductModel>) => updateResult({ me: { products: [product] } });
  const updatePerson = (person: DeepPartial<PersonModel>) => updateResult({ persons: [person] });
  const updateOrganization = (organization: DeepPartial<OrganizationModel>) => updateResult({ me: { organizations: [organization] } });
  const updateReviewJob = (job: DeepPartial<JobModel>) => updateResult({ reviewJobs: [job] });
  const select = (id: string) => () => {
    const next = new Set(selection);
    next.has(id) ? next.delete(id) : next.add(id);
    setSelection(next);
  }
  const moveProductsToSubscription = async (subscriptionId: string) => {
    const ids = Array.from(selection);
    const result = await mutation(_moveProducts, { ids, subscriptionId })
    updateResult({ me: { products: result.products.move } })
  }
  const exportProducts = async () => {
    const result = await mutation(_exportProducts, { ...queryVariables, first: 1000 }, {});
    download(result.me.products.export.dataUri, `Listings.xlsx`);
  }
  const exportOrganizations = async () => {
    const result = await mutation(_exportOrganizations, { ...queryVariables, first: 1000 }, {});
    download(result.me.organizations.export.dataUri, `Teams.xlsx`);
  }
  const moveWorkItemsToSubscription = async (subscriptionId: string) => {
    const ids = Array.from(selection);
    const result = await mutation(_moveWorkItems, { ids, subscriptionId })
    updateResult({ me: { workitems: result.workitems.move } })
  }

  const services = ServiceTypes.filter(inFunction).filter(_ => (_ !== ServiceType.Null) && (_filterServices.includes(_) || query.topics.includes(_) || me?.topicPreferences.some(p => p.value.service === _)));
  const areas = Object.values(AreaType).filter(inFunction).filter(_ => (_ !== AreaType.Null) && (query.topics.includes(_) || me?.topicPreferences.some(p => p.value.area === _)));

  const hasManySubs = (me?.subscriptions.length ?? 0) > 1;
  const showLocationFilterMenu = view === View.People || view === View.Opportunities;
  const showLocationFilterNav = showLocationFilterMenu && hasCitiesFilter;
  const showTopicsFilterMenu = view === View.Opportunities && (services.length > 0 || areas.length > 0);
  const showTopicsFilterNav = showTopicsFilterMenu && hasInterestsFilter;
  const showFunctionsFilterNav = view === View.Opportunities || view === View.Listings;
  const showGraduationFilterMenu = view === View.People;
  const showAvailabilityFilterMenu = view === View.Opportunities;
  const showAvailabilityFilterNav = showAvailabilityFilterMenu && hasAvailabilityFilter;
  const showGraduationFilterNav = showGraduationFilterMenu && hasGraduationOnFilter;
  const showCompensationFilterMenu = false; // tab === View.Opportunities;
  const showCompensationFilterNav = showCompensationFilterMenu && hasCompensationFilter;
  const showStatesFilterNav = view === View.Goals || view === View.Tasks || view === View.Listings || view === View.WorkItems;
  const showStatesFilterMenu = view === View.Goals || view === View.Tasks || view === View.Listings || view === View.WorkItems;
  const showUpdatedOnFilterMenu = view === View.Goals || view === View.Tasks || view === View.Listings || view === View.WorkItems;
  const showAdditionalFilterMenu = false;
  const showFiltersMenu = showStatesFilterMenu || showLocationFilterMenu || showGraduationFilterMenu || showUpdatedOnFilterMenu || showCompensationFilterMenu || showAdditionalFilterMenu;
  const showActions = view == View.Listings || view === View.WorkItems || view === View.People || view === View.Teams;
  const hideRemoved = query.states !== States.Archived;
  const hasFilters = hasInterestsFilter || hasGraduationOnFilter || hasCitiesFilter;

  return (
    <>
      <Row className="mt-3">
        <Col>
          <Nav>
            <Show scope="Site.Admin">
              {showFunctionsFilterNav &&
                <UncontrolledDropdown nav>
                  <DropdownToggle className="pl-0 mr-1" nav caret>
                    {functions.length === 0 ? "All"
                      : functions.length === 1 ? t(functions[0])
                        : `${functions.length} functions`}
                  </DropdownToggle>
                  <DropdownMenu>
                    {functions.length === 0 && <CustomDropdownItem checked={functions.length === 0} onClick={e => setFunctionsFilter([])}>All</CustomDropdownItem>}
                    {Object.values(FunctionType).filter(_ => (_ !== FunctionType.Null) && (_filterFunctions.includes(_) || me?.topicPreferences.some(p => p.value.function === _))).map((_, i) =>
                      <CustomDropdownItem key={i} checked={functions.includes(_)} onClick={e => toggleFunctionsFilter(e, _)}>{t(_)}</CustomDropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            </Show>
            {view === View.Opportunities &&
              <>
                <NavItem className='mr-1'><NavLink active={query.productTypes[0] === ProductType.EmploymentTrainingLevel} href="#" onClick={() => updateQuery({ productTypes: [ProductType.EmploymentTrainingLevel] })}>Internships</NavLink></NavItem>
                <NavItem className='mr-3'><NavLink active={query.productTypes[0] === ProductType.EmploymentEntryLevel} href="#" onClick={() => updateQuery({ productTypes: [ProductType.EmploymentEntryLevel] })}>Associates</NavLink></NavItem>
              </>
            }
            {view === View.Reviews &&
              <>
                <NavItem className='mr-1'>
                  <NavLink active={query.reviewTypes?.length === 0 || query.reviewTypes?.[0] === ReviewType.JobReview} href="#" onClick={() => updateQuery({ reviewTypes: [ReviewType.JobReview] })}>
                    Tasks
                    {result && result.reviewJobsSummary?.resolvedCount > 0 &&
                      <Badge pill color="light" className="ml-1">{result.reviewJobsSummary.resolvedCount}</Badge>
                    }
                  </NavLink>
                </NavItem>
                <Show scope="Site.Admin">
                  <NavItem className='mr-1'><NavLink active={query.reviewTypes?.[0] === ReviewType.AttachmentReview} href="#" onClick={() => updateQuery({ reviewTypes: [ReviewType.AttachmentReview] })}>Attachments</NavLink></NavItem>
                </Show>
              </>
            }
            {showStatesFilterNav &&
              <>
                <NavItem className="mr-1"><NavLink active={query.states === States.Active} href="#" onClick={() => updateQuery({ states: States.Active })}>Active</NavLink></NavItem>
                <NavItem className="mr-1"><NavLink active={query.states === States.Complete} href="#" onClick={() => updateQuery({ states: States.Complete })}>Completed</NavLink></NavItem>
                {query.states === States.Archived && <NavItem className="mr-1"><NavLink active={query.states === States.Archived} href="#" onClick={() => updateQuery({ states: States.Archived })}>Archived</NavLink></NavItem>}
              </>
            }
            {showCompensationFilterNav &&
              <NavItem className='mr-1'>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="primary">
                    {n(parseInt(query.amount.split(":")[1].split("-")[0]) / 12, NumberDisplayFormat.HumanRange, parseInt(query.amount.split(":")[1].split("-")[1]) / 12, t(query.amount.split(":")[0]))} per month
                    </DropdownToggle>
                  <DropdownMenu style={{ width: 300 }}>
                    <CustomDropdownItem header clear onClear={() => updateQuery({ amount: "" })}>Compensation</CustomDropdownItem>
                    <MoneyRangeInput max={150000} value={query.amount} onUpdate={amount => updateQuery({ amount })} />
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </NavItem>
            }
            {showGraduationFilterNav &&
              <NavItem className='mr-1'>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="primary">
                    {d(graduationOnAfter, DateDisplayFormat.YearMonthRange, graduationOnBefore)}
                  </DropdownToggle>
                  <DropdownMenu>
                    <CustomDropdownItem header clear={hasGraduationOnFilter} onClear={() => setGraduationOnFilter("")}>Graduation</CustomDropdownItem>
                    <CustomDropdownItem checked={query.graduationOn === "P-30M/P0M"} onClick={() => setGraduationOnFilter("P-30M/P0M")}>Last 2 years</CustomDropdownItem>
                    <CustomDropdownItem checked={query.graduationOn === "P-42M/P-18M"} onClick={() => setGraduationOnFilter("P-42M/P-18M")}>2 to 3 years ago</CustomDropdownItem>
                    <CustomDropdownItem checked={query.graduationOn === "P-66M/P-30M"} onClick={() => setGraduationOnFilter("P-66M/P-30M")}>3 to 5 years ago</CustomDropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </NavItem>
            }
            {showAvailabilityFilterNav &&
              <NavItem className='mr-1'>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="primary">
                    {d(startOn, DateDisplayFormat.MonthDayRange, endOn)}
                  </DropdownToggle>
                  <DropdownMenu>
                    <CustomDropdownItem header clear onClear={() => setAvailabilityFilter("")}>Availability</CustomDropdownItem>
                    <DropdownItem onClick={() => setAvailabilityFilter(moment().format("YYYY-MM-DD"))}>Now</DropdownItem>
                    <DropdownItem onClick={() => setAvailabilityFilter(moment().add(1, "M").format("YYYY-MM-01"))}>Next month</DropdownItem>
                    <DropdownItem onClick={() => setAvailabilityFilter(moment().add(2, "M").format("YYYY-MM-01"))}>Month after next</DropdownItem>
                    <DropdownItem divider />
                    <Form>
                      <fieldset>
                        <Row form>
                          <Col>
                            <FormGroup label='Start'>
                              <Input type="date" className="form-control" value={startOn} onChange={e => setAvailabilityFilter(e.currentTarget.value)} />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup label='End'>
                              <Input type="date" className="form-control" value={endOn} onChange={e => setAvailabilityFilter(startOn, e.currentTarget.value)} />
                            </FormGroup>
                          </Col>
                        </Row>
                      </fieldset>
                    </Form>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </NavItem>
            }
            {showLocationFilterNav &&
              <NavItem className='mr-1'>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="primary">
                    {query.cities.length == 1 ? t(query.cities[0]) : `${query.cities.length} locations`}
                  </DropdownToggle>
                  <DropdownMenu>
                    <CustomDropdownItem header clear={query.cities && query.cities.length > 0} onClear={() => setLocationFilter([])}>
                      Location
                      </CustomDropdownItem>
                    {CityCodes.filter(_ => (_ !== CityCode.Null && _ !== CityCode.Other) && (_filterCities.includes(_) || query.cities.includes(_) || me?.locationPreferences.some(p => p.value.city === _))).map((_, i) =>
                      <CustomDropdownItem key={i} checked={query.cities.includes(_)} onClick={e => toggleLocationFilter(e, _)}>{t(_)}</CustomDropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </NavItem>
            }
            {showTopicsFilterNav &&
              <>
                <NavItem className='mr-1'>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle color="primary">
                      {query.topics.length == 1 ? t(query.topics[0]) : `${query.topics.length} focuses`}
                    </DropdownToggle>
                    <DropdownMenu>
                      <CustomDropdownItem header clear={query.topics && query.topics.length > 0} onClear={() => setTopicsFilter([])}>Focus</CustomDropdownItem>
                      {services.map((_, i) => <CustomDropdownItem key={i} checked={query.topics.includes(_)} onClick={e => toggleTopicsFilter(e, _)}>{t(_)}</CustomDropdownItem>)}
                      {areas.map((_, i) => <CustomDropdownItem key={i} checked={query.topics.includes(_)} onClick={e => toggleTopicsFilter(e, _)}>{t(_)}</CustomDropdownItem>)}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </NavItem>
              </>
            }
            {showFiltersMenu &&
              <CustomDropdown className="ml-auto" label='Filters'>
                {showUpdatedOnFilterMenu &&
                  <>
                    <CustomDropdownItem header>During</CustomDropdownItem>
                    <CustomDropdownItem checked={query.during === "RECENT"} onClick={() => updateQuery({ during: "RECENT" })}>Past year</CustomDropdownItem>
                    {[1, 2, 3, 4].map(i =>
                      <CustomDropdownItem key={i} checked={query.during === `${moment().year() - i}`} onClick={() => updateQuery({ ...query, during: `${moment().year() - i}` })}>{moment().year() - i}</CustomDropdownItem>
                    )}
                  </>
                }
                {showGraduationFilterMenu &&
                  <>
                    <CustomDropdownItem header>Graduation</CustomDropdownItem>
                    <CustomDropdownItem onClick={() => setGraduationOnFilter("P-30M/P0M")}>Last 2 years</CustomDropdownItem>
                    <CustomDropdownItem onClick={() => setGraduationOnFilter("P-42M/P-18M")}>2 to 3 years ago</CustomDropdownItem>
                    <CustomDropdownItem onClick={() => setGraduationOnFilter("P-66M/P-30M")}>3 to 5 years ago</CustomDropdownItem>
                  </>
                }
                {showAvailabilityFilterMenu &&
                  <>
                    <CustomDropdownItem header>Availability</CustomDropdownItem>
                    <CustomDropdownItem onClick={() => setAvailabilityFilter(moment().format("YYYY-MM-DD"))}>Now</CustomDropdownItem>
                    <CustomDropdownItem onClick={() => setAvailabilityFilter(moment().add(1, "M").format("YYYY-MM-01"))}>Next month</CustomDropdownItem>
                    <CustomDropdownItem onClick={() => setAvailabilityFilter(moment().add(2, "M").format("YYYY-MM-01"))}>Month after next</CustomDropdownItem>
                    <Form>
                      <fieldset>
                        <FormGroup className="mb-0">
                          <Input type="date" className="form-control" value={startOn} onChange={e => setAvailabilityFilter(e.currentTarget.value)} />
                        </FormGroup>
                      </fieldset>
                    </Form>
                  </>
                }
                {showLocationFilterMenu &&
                  <>
                    <CustomDropdownItem header clear={query.cities && query.cities.length > 0} onClear={() => setLocationFilter([])}>
                      Location
                      </CustomDropdownItem>
                    {CityCodes.filter(_ => (_ !== CityCode.Null && _ !== CityCode.Other) && (_filterCities.includes(_) || query.cities.includes(_) || me?.locationPreferences.some(p => p.value.city === _))).map((_, i) =>
                      <CustomDropdownItem key={i} checked={query.cities.includes(_)} onClick={e => toggleLocationFilter(e, _)}>{t(_)}</CustomDropdownItem>
                    )}
                  </>
                }
                {showTopicsFilterMenu &&
                  <>
                    <CustomDropdownItem header clear={query.topics && query.topics.length > 0} onClear={() => setTopicsFilter([])}>Focus</CustomDropdownItem>
                    {services.map((_, i) => <CustomDropdownItem key={i} checked={query.topics.includes(_)} onClick={e => toggleTopicsFilter(e, _)}>{t(_)}</CustomDropdownItem>)}
                    {areas.map((_, i) => <CustomDropdownItem key={i} checked={query.topics.includes(_)} onClick={e => toggleTopicsFilter(e, _)}>{t(_)}</CustomDropdownItem>)}
                  </>
                }
                {showStatesFilterMenu &&
                  <>
                    {false && <CustomDropdownItem header>Status</CustomDropdownItem>}
                    {false && <CustomDropdownItem checked={query.states === States.Active} onClick={() => updateQuery({ states: States.Active })}>Active</CustomDropdownItem>}
                    {false && <CustomDropdownItem checked={query.states === States.Complete} onClick={() => updateQuery({ states: States.Complete })}>Completed</CustomDropdownItem>}
                    <CustomDropdownItem checked={query.states === States.Archived} onClick={() => updateQuery({ states: States.Archived })}>Archived</CustomDropdownItem>
                  </>
                }
                {showCompensationFilterMenu &&
                  <>
                    <CustomDropdownItem header>Compensation</CustomDropdownItem>
                    <MoneyRangeInput max={150000} value={query.amount} onUpdate={amount => updateQuery({ amount })} />
                  </>
                }
                {showAdditionalFilterMenu &&
                  <>
                    <CustomDropdownItem header>Additional filters</CustomDropdownItem>
                  </>
                }
                {(showLocationFilterMenu || showTopicsFilterMenu) &&
                  <DropdownItem className="mt-3 text-muted small" onClick={navigate("/me")}>To see additional city and interest<br />filters add them to your <a href="#" onClick={navigate("/me")}>profile</a>.</DropdownItem>
                }
              </CustomDropdown>
            }
            {showActions &&
              <CustomDropdown className={!showFiltersMenu ? "ml-auto" : ""} label='Actions'>
                {view === View.Listings && <CustomDropdownItem onClick={toggleNewProduct}>New listing</CustomDropdownItem>}
                {view === View.WorkItems && <CustomDropdownItem onClick={toggleNewWorkItem}>New work item</CustomDropdownItem>}
                {view === View.Teams && <CustomDropdownItem onClick={toggleNewOrganization}>New team</CustomDropdownItem>}
                <CustomDropdownItem divider />
                {view === View.Listings && <CustomDropdownItem onClick={() => result && exportProducts()}>Export to Excel</CustomDropdownItem>}
                {view === View.People && <CustomDropdownItem onClick={() => result && PersonsToExcel(result.persons, t)}>Export to Excel</CustomDropdownItem>}
                {view === View.Teams && <CustomDropdownItem onClick={() => result && exportOrganizations()}>Export to Excel</CustomDropdownItem>}
                {view === View.WorkItems && <CustomDropdownItem onClick={() => result && WorkItemsToExcel(result.me.workitems)}>Export to Excel</CustomDropdownItem>}
                {view === View.Listings && hasManySubs && <CustomDropdownItem onClick={toggleProductSubscriptionChooser}>Move to another subscription</CustomDropdownItem>}
                {view === View.WorkItems && hasManySubs && <CustomDropdownItem onClick={toggleWorkItemSubscriptionChooser}>Move to another subscription</CustomDropdownItem>}
                <Show scope="Site.Admin">
                  {view === View.Listings && <CustomDropdownItem onClick={toggleImportProducts}>Import listings</CustomDropdownItem>}
                  {view === View.Teams && <CustomDropdownItem onClick={toggleImportOrganizations}>Import teams</CustomDropdownItem>}
                </Show>
              </CustomDropdown>
            }
            {view === View.Opportunities &&
              <Button color="primary" onClick={toggleSuggestProduct}>Suggest</Button>
            }
            <Show scope="Site.Admin">
              {view === View.Listings && <ProductsImportModal isOpen={isOpenImportProducts} toggle={toggleImportProducts} onUpdate={() => { }} />}
              {view === View.Teams && <OrganizationsImportModal isOpen={isOpenImportOrganizations} toggle={toggleImportOrganizations} onUpdate={() => { }} />}
            </Show>
          </Nav>
        </Col>
      </Row>
      {view === View.Opportunities && <SuggestProductModal isOpen={isOpenSuggestProduct} toggle={toggleSuggestProduct} />}
      {view === View.Listings && <ProductInputModal value={NewProduct} onUpdate={updateProduct} isOpen={isOpenNewProduct} toggle={toggleNewProduct} />}
      {view === View.Listings && <SubscriptionChooserModal label="Move" isOpen={isOpenProductSubscriptionChooser} toggle={toggleProductSubscriptionChooser} onSelect={moveProductsToSubscription} />}
      {view === View.WorkItems && <WorkItemInputModal value={NewWorkItem} onUpdate={updateWorkItem} isOpen={isOpenNewWorkItem} toggle={toggleNewWorkItem} />}
      {view === View.WorkItems && <SubscriptionChooserModal label="Move" isOpen={isOpenWorkItemSubscriptionChooser} toggle={toggleWorkItemSubscriptionChooser} onSelect={moveWorkItemsToSubscription} />}
      {view === View.Teams && <OrganizationInputModal value={NewOrganization} onUpdate={updateOrganization} isOpen={isOpenNewOrganization} toggle={toggleNewOrganization} />}
      {result && count > 0 &&
        <Row className="mt-3">
          <CardColumns lg={2} xl={3}>
            {view === View.Opportunities && result.products.map((_, i) => <ProductCard key={i} href={`/my/opportunities/${_.id}`} value={_} hideRemoved={hideRemoved} showAvailability showStatus showButtons={_.actions.order} />)}
            {view === View.Goals && result.me.orders.map((_, i) => <OrderCard key={i} href={`/my/goals/${_.id}`} value={_} onUpdate={updateOrder} hideRemoved={hideRemoved} showNotice showAttachments showStatus showButtons showInput />)}
            {view === View.Tasks && result.me.jobs.map((_, i) => <JobCard key={i} href={`/my/tasks/${_.id}`} value={_} onUpdate={updateJob} hideRemoved={hideRemoved} showNotice showStatus />)}
            {view === View.Listings && result.me.products.map((_, i) => <ProductCard key={i} href={`/my/listings/${_.id}`} value={_} onUpdate={updateProduct} onSelect={select(_.id)} hideRemoved={hideRemoved} showSelect selected={selection.has(_.id)} showAvailability showNotice showStatus />)}
            {view === View.WorkItems && result.me.workitems.map((_, i) => <WorkItemCard key={i} href={`/my/workitems/${_.id}`} value={_} onUpdate={updateWorkItem} onSelect={select(_.id)} hideRemoved={hideRemoved} showSelect selected={selection.has(_.id)} showNotice showStatus />)}
            {view === View.People && result.persons.map((_, i) => <PersonCard key={i} href={`/my/team/${_.id}`} value={_} onUpdate={updatePerson} hideRemoved={hideRemoved} showStatus showMenu />)}
            {view === View.Teams && result.me.organizations.map((_, i) => <OrganizationCard key={i} href={`/my/teams/${_.id}`} value={_} onUpdate={updateOrganization} hideRemoved={hideRemoved} showStatus />)}
            {view === View.Reviews && result.reviewJobs.map((_, i) => <JobCard key={i} href={`/my/reviews/${_.id}`} value={_} onUpdate={updateReviewJob} hideRemoved={hideRemoved} showNotice showStatus />)}
          </CardColumns>
        </Row>
      }
      {result &&
        <Row className="mt-3">
          <Col>
            {!count &&
              <span>
                No matching results.
              {hasFilters && <a className="ml-1" href="#" onClick={preventDefault(clearFilters)}>View all</a>}
              </span>
            }
            {count >= vars.first && <a href="#" onClick={preventDefault(getMore)}>See more</a>}
            {isLoading && <FontAwesomeIcon className="text-muted" icon="circle-notch" spin />}
          </Col>
        </Row>
      }
    </>
  );
}

export default ({ view: _view }: { view?: View }) => {
  const [, scopes] = useClaims();
  const [isLoading, setLoading] = useState(false);
  const [lastView, setLastView] = usePreference(Preference.MyHomePageView, _getDefaultView(scopes));
  const [query, , updateQuery] = useQueryString(_initialQueryString);

  const view = _view || lastView || _getDefaultView(scopes);
  useTitle(_titles.get(view));
  useEffect(() => setLastView(view), [view]);

  const showWorkflow = query.workflow !== Workflow.Null;
  const showContent = !showWorkflow;

  return (
    <>
      <LoadingController isLoading={isLoading} />
      <Row>
        <SideNavigationCol md={2} view={view} />
        <Col md={10}>
          <ProfileInputCard />
          {showWorkflow && <WorkflowRow view={view} query={query} updateQuery={updateQuery} />}
          {showContent && <ContentRow view={view} setLoading={setLoading} query={query} updateQuery={updateQuery} />}
        </Col>
      </Row>
    </>
  );
};
