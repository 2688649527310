import React, { createContext, ReactNode, useContext, useState } from "react";
import { nextSequence } from "../hooks/ApiProvider";

export interface Toast {
  readonly id: string;
  readonly title: string;
  readonly message: string;
  readonly expireAt?: number;
}

type ContextType = [Toast | undefined, (value: Partial<Toast>, expireInMs?: number) => void];
const initialContext: any = undefined;
const Context = createContext<ContextType>(initialContext);

export function useToasts() {
  return useContext(Context);
}

export default (props: { children?: ReactNode }) => {
  const [toasts, setToasts] = useState([] as Toast[]);
  const setToast = (value: Partial<Toast>, expireInMs?: number) => {
    if (expireInMs === 0) {
      setToasts(toasts.filter(_ => _.id != value.id));
    } else {
      const toast = {
        ...(toasts.filter(_ => value.id && _.id === value.id)[0] || {}),
        ...value,
        id: value.id || nextSequence(),
        message: value.message || "",
        expireAt: expireInMs ? (new Date().valueOf() + expireInMs) : value.expireAt
      } as Toast;
      setToasts([toast, ...toasts.filter(_ => _.id != value.id)]);
    }
  }
  return (
    <Context.Provider value={[toasts[0], setToast]}>
      {props.children}
    </Context.Provider>
  );
}
