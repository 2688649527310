import { useMutation } from '../hooks/ApiProvider';
import { EventModel, ResourceEvent, ResourceKind, ResourcePolicyModel } from '../types/api-graph-types';
import { useEffect } from 'react';

const _trackEvent = `
  event(type:$type) {
    track
    commit { id }
  }
`;

const _addEvent = new Map<ResourceKind, string>([
  [ResourceKind.Order, `mutation addOrderEvent($id:ID $token:String $type:ResourceEvent) {
    resource: order(id:$id accessToken:$token) { ${_trackEvent} }
  }`]
]);

interface Resource {
  kind: ResourceKind,
  id: string,
  policy: ResourcePolicyModel
}

interface OperationResults {
  event: EventModel
}

export function useEvent(type: ResourceEvent, resource: Resource | undefined) {
  const [mutation] = useMutation<OperationResults>();
  useEffect(() => {
    if (resource?.id) {
      const { kind, id } = resource;
      const token = resource.policy?.accessToken;
      mutation(_addEvent.get(kind) ?? "", { type, id, token });
    }
  }, [type, resource?.id]);
}
