import React, { ReactNode } from "react";
import { CardColumns, CardColumnsProps, Col } from "reactstrap";
import { useWindowState } from "../hooks/WindowStateProvider";

export default ({ xs, sm, md, lg, xl, className, children, ...attrs }: {
  xs?: number,
  sm?: number,
  md?: number,
  lg?: number,
  xl?: number,
} & CardColumnsProps) => {
  const [size] = useWindowState();
  /*
  const classNames = [
    className,
    xs ? `cols-xs-${xs}` : "",
    sm ? `cols-sm-${sm}` : "",
    md ? `cols-md-${md}` : "",
    lg ? `cols-lg-${lg}` : "",
    xl ? `cols-xl-${xl}` : "",
  ].join(" ");
  */
  const cols = size === "xs" ? xs || 1
    : size === "sm" ? sm || xs || 1
      : size === "md" ? md || sm || xs || 1
        : size === "lg" ? lg || md || sm || xs || 1
          : size === "xl" ? xl || lg || md || sm || xs || 1
            : 1;
  const span = Math.max(1, Math.floor(12 / cols));
  const items = React.Children.toArray(children);
  return (
    <>
      {items
        .reduce<ReactNode[][]>((result, item, i) => {
          result[i % cols].push(item);
          return result;
        }, [...Array(cols)].map(_ => [] as ReactNode[]))
        .map((_, i) => <Col xs={span} key={i} className={`card-column ${className || ""}`}>{_}</Col>)}
    </>
  );
}
