import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { ReactNode, SVGProps, useState } from "react";
import { CardBody, CardBodyProps, CardText, CardTitle, Collapse, Button } from "reactstrap";
import Card, { CardAttributes, CardBodyAttributes, CardButtonsAttributes } from "../components/Card";
import { CardButton, CardButtons, CardRatingHistogram, CardRatingStatusItem, CardStatus, CardStatusDetail, CardTasksStatusItem } from "../components/CardStatus";
import PhoneNumberSpan, { toWhatsapp } from "../components/PhoneNumberSpan";
import { nextSequence, useMutation } from "../hooks/ApiProvider";
import { useCardState } from "../hooks/CardStateHook";
import { useToggle } from "../hooks/CommonHooks";
import { useIcons } from "../hooks/IconizeHook";
import { stopPropagation, useNavigation } from "../hooks/NavigationHook";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { AccessTokenModel, AreaType, CityCode, ExternalAccountType, LocationPreferenceOption, PersonModel, ResourceKind, ServiceType, TopicPreferenceOption } from "../types/api-graph-types";

library.add(faLinkedinIn);

export const UserThumbnailFragment = `
  id
  kind
  name
  picture
  accentColor
`;

export const ExcelFragment = `
  id kind name email phone 
  organizationName organization { name }
  graduationOrganizationName graduationOrganization { name }
  graduationOn
  resume { url }
  locationPreferences { value { city locality } options }
  languagePreferences { value options }
  topicPreferences { value { service area } options }
  incomePreference { value { currency annualAmount } }
`;

export const Fragment = `
  name
  email
  phone
  title
  ${UserThumbnailFragment}
  resume { url filename updatedOn }
  affiliation { organization { displayName } organizationName title }
  eduAffiliation { organization { displayName nickname } organizationName endOn cgpa { value maxValue} rank { value maxValue } }
  ratingAverage ratingCount
  jobsSummary { 
    count
    resolvedPercent
    positiveCount
    neutralCount
    negativeCount
    resolvedCount
    completedCount
    removedCount
    ratingHistogram
    ratingCount
  } 
  actions { change impersonate }
`;

const _impersonatePerson = `mutation impersonatePerson($id:ID) {
  person(id:$id) { impersonate { token_type access_token expires_in } }
}`;

interface OperationResults {
  person: {
    impersonate: AccessTokenModel
  }
}

const _color = "#a0d36a", _light = "#f8f9fa", _dark = "#373a3c";

function _isLight(color: string) {
  const hex = +("0x" + color.slice(1).replace(color.length < 5 && /./g || "", "$&$&"));
  const r = hex >> 16;
  const g = hex >> 8 & 255;
  const b = hex & 255;
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return hsp > 127.5;
}

function toLinkedIn(value: string) {
  return `https://www.linkedin.com/search/results/all/?keywords=${encodeURIComponent(value)}`;
}

export const Anonymous = {
  nickname: "",
  name: "",
  picture: "",
  accentColor: ""
};

export async function ToExcel(values: PersonModel[], t: (e: string) => string) {
  const xlsx = await import("xlsx");
  const data = [[
    "UpdatedOn",
    "Name",
    "Email",
    "Phone",
    "Current Organization",
    "Service Areas",
    "Cities",
    "Current Compensation",
    "Expected Compensation",
    "Graduation College",
    "Graduation Year",
    "Overall Score",
    "Assessments Completed",
    // 'Languages',
    "Resume"
  ]];

  values.forEach(_ => {
    const overall = _.ratingAverage * 10;
    data.push([
      _.resume && _.resume.updatedOn && moment(_.resume.updatedOn).format("YYYY-MM-DD") || "",
      _.name,
      _.email,
      _.phone,
      _.organization && _.organization.name || _.organizationName || "",
      _.topicPreferences
        .filter(_ => !_.options.includes(TopicPreferenceOption.Dislike))
        .map(_ => [
          _.value.service !== ServiceType.Null ? t(_.value.service) : "",
          _.value.area !== AreaType.Null ? t(_.value.area) : ""
        ].filter(_ => !!_).join(", ")).join(", "),
      _.locationPreferences
        .filter(_ => !_.options.includes(LocationPreferenceOption.Dislike))
        .map(_ => _.value.city === CityCode.Other ? _.value.locality : t(_.value.city)).join(", "),
      "",
      _.incomePreference && _.incomePreference.value && `${_.incomePreference.value.annualAmount}`,
      _.graduationOrganization && _.graduationOrganization.name || _.graduationOrganizationName || "",
      _.graduationOn && moment(_.graduationOn).format("YYYY") || "",
      `${overall || "0"}`,
      `${_.ratingCount || "0"}`,
      // _.languagePreferences.map(_ => t(_.value)).join(", "),
      _.resume && _.resume.url || ""
    ]);
  });

  const wb = xlsx.utils.book_new();
  const ws = xlsx.utils.aoa_to_sheet(data);
  xlsx.utils.book_append_sheet(wb, ws, "Sheet 1");
  xlsx.writeFile(wb, "People.xlsx");
}

export function UserThumbnail({ value, title, width, icon, iconColor, iconPlacement = "bottom-right", ...attrs }: {
  value: { picture: string, name: string, accentColor?: string },
  title?: string,
  icon?: IconProp,
  iconPlacement?: "bottom-right"
  iconColor?: "primary" | "secondary" | "success" | "warning" | "danger" | "dark" | "light",
  width: number
} & SVGProps<SVGSVGElement>) {
  const [uid] = useState(nextSequence());
  const [showImage, toggleImage] = useToggle(true);
  const iconX = iconPlacement === "bottom-right" ? width * 3 / 4 + 2 : 0;
  const iconY = iconPlacement === "bottom-right" ? width * 3 / 4 + 2 : 0;
  return (
    <svg style={{ minWidth: `${width}px` }} width={width} height={width} {...attrs}>
      {title && <title>{title}</title>}
      <clipPath id={`${uid}.0`}>
        <circle cx={width / 2} cy={width / 2} r={width / 2} />
      </clipPath>
      <g clipPath={`url(#${uid}.0)`}>
        <rect fill={value?.accentColor || _color} x={0} y={0} width={width} height={width} />
        <text fill={_isLight(value?.accentColor || _color) ? _dark : _light}
          fontSize={width / 2} fontWeight={400}
          textAnchor="middle" x={width / 2} y={width / 2 + width / 6}>
          {(value?.name || "").trim().substr(0, 1).toUpperCase()}
        </text>
        {showImage && value?.picture && <image x={0} y={0} width={width} height={width} href={value.picture} onError={toggleImage} />}
      </g>
      {icon &&
        <g>
          <circle cx={iconX} cy={iconY} r={width / 4} fill="white" />
          <circle cx={iconX} cy={iconY} r={width / 4 - 2} className={`fill-${iconColor || "primary"}`} />
          <FontAwesomeIcon fixedWidth icon={icon} transform="shrink-12 right-5 down-5" color="white" />
        </g>
      }
    </svg>
  );
}

function PersonTableCell({ value, link }: { value: PersonModel, link?: string }) {
  const [i] = useIcons();
  return (
    <tr className="hover-container">
      <td>
        <UserThumbnail width={32} value={value} />
        <span style={{ textTransform: "capitalize" }}>
          {value.name}
        </span>
        {value.organization &&
          <span className='ml-1 text-muted small'>
            {value.organization.nickname || value.organization.displayName}
          </span>
        }
        <span className="hover-visible">
          {value.email &&
            <a href={`mailto:${value.email}`} onClick={stopPropagation}>
              <FontAwesomeIcon fixedWidth className='ml-2' icon={i(ExternalAccountType.Email)} />
            </a>
          }
          {value.phone &&
            <a href={`tel:${value.phone}`} onClick={stopPropagation}>
              <FontAwesomeIcon fixedWidth className='ml-2' icon={i(ExternalAccountType.Phone)} />
            </a>
          }
        </span>
      </td>
    </tr>
  );
}

export function Text({ value, prependTitle, children }: {
  value: PersonModel,
  prependTitle?: ReactNode
  children?: ReactNode
}) {
  const [, d] = useTranslation();
  const edu = value.eduAffiliation;
  const eduEnd = edu && d(edu.endOn, DateDisplayFormat.YearShort);
  const eduOrg = value.eduAffiliation?.organization;
  const eduRating = edu && [
    edu.cgpa?.value > 0
      ? edu.cgpa.maxValue === 100
        ? `Percentage: ${edu.cgpa.value.toFixed(0)}%`
        : !edu.cgpa.maxValue || edu.cgpa.maxValue === 10 ? `CGPA: ${edu.cgpa.value.toFixed(1)}`
          : `CGPA: ${edu.cgpa.value.toFixed(1)}/${edu.cgpa.maxValue}`
      : "",
    edu.rank?.value > 0
      ? edu.rank.maxValue > 0
        ? `Rank: ${edu.rank.value}/${edu.rank.maxValue}`
        : `Rank: ${edu.rank.value}`
      : "",
  ].join(" ").trim() || undefined;

  return (<>
    <div className="d-flex">
      <UserThumbnail className="mr-2" width={48} value={value} />
      <div>
        {prependTitle}
        <CardTitle tag='h3'>{value.name}</CardTitle>
      </div>
    </div>
    <CardText tag='ul' className='list-unstyled'>
      {value.email &&
        <li className="d-flex align-items-baseline hover-container">
          <b className='mr-1'>Email</b>
          <a className='text-body' href={`mailto:${value.email}`}>
            {value.email}
          </a>
          <Button size="sm" color="link" target="whatsapp" className="hover-visible ml-2 py-0" href={toLinkedIn(value.name)}>
            <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
          </Button>
        </li>
      }
      {value.phone &&
        <li className="d-flex align-items-baseline hover-container">
          <b className='mr-1'>Phone</b>
          <a className='text-body' href={`tel:${value.phone}`}>
            <PhoneNumberSpan value={value.phone} />
          </a>
          <Button size="sm" color="link" target="whatsapp" className="hover-visible ml-2 py-0" href={toWhatsapp(value.phone)}>
            <FontAwesomeIcon icon={['fab', 'whatsapp']} />
          </Button>
        </li>
      }
      {value.affiliation && value.affiliation.title &&
        <li>
          <b>Position</b> {[value.affiliation.title, value.affiliation.organization?.displayName || value.affiliation.organizationName].join(", ")}
        </li>
      }
      {value.affiliation && !value.affiliation.title &&
        <li>
          <b>Company</b> {value.affiliation.organization?.displayName || value.affiliation.organizationName}
        </li>
      }
      {edu &&
        <li>
          <b className="mr-1">Education</b>
          {eduOrg?.nickname && <abbr title={eduOrg.displayName}>{eduOrg.nickname} {eduEnd}</abbr>}
          {eduOrg?.displayName && !eduOrg?.nickname && <span>{eduOrg.displayName} {eduEnd}</span>}
          {!eduOrg && <span>{edu.organizationName} {eduEnd}</span>}
          {eduRating && <span className="ml-1 small">({eduRating})</span>}
        </li>
      }
      {children}
    </CardText>
  </>);
}

function Status({ value, showMenu }: { value: PersonModel; showMenu?: boolean }) {
  const [isopenDiscussionsSummary, toggleReviewsSummary] = useToggle();
  const [isOpenJobsSummary, toggleJobsSummary] = useToggle();
  return (
    <>
      <CardStatus>
        <CardRatingStatusItem value={value.ratingAverage} count={value.jobsSummary.completedCount} toggle={toggleReviewsSummary} />
        <CardTasksStatusItem value={`${value.jobsSummary.resolvedPercent}%`} toggle={toggleJobsSummary} />
      </CardStatus>
      <Collapse isOpen={isOpenJobsSummary}>
        <CardStatus>
          <CardStatusDetail value={value.jobsSummary.positiveCount + value.jobsSummary.neutralCount} valueClassName="strong" label="Approved" />
          {value.jobsSummary.negativeCount > 0 && <CardStatusDetail value={value.jobsSummary.negativeCount} valueClassName="strong" label="Unsatisfactory" />}
          {value.jobsSummary.resolvedCount > 0 && <CardStatusDetail value={value.jobsSummary.resolvedCount} valueClassName="strong" label="Under Review" />}
          {value.jobsSummary.removedCount > 0 && < CardStatusDetail value={value.jobsSummary.removedCount} valueClassName="strong" label="Skipped" />}
        </CardStatus>
      </Collapse>
      <Collapse isOpen={isopenDiscussionsSummary}>
        <CardRatingHistogram value={value.jobsSummary.ratingHistogram} max={value.jobsSummary.ratingCount} />
      </Collapse>
    </>
  );
}

function Buttons({ value, toggleInput }: CardButtonsAttributes<PersonModel>) {
  const [, go] = useNavigation();
  const [mutation] = useMutation<OperationResults>();
  const impersonate = async () => {
    const result = await mutation(_impersonatePerson, { id: value.id });
    const { token_type, access_token, expires_in } = result.person.impersonate;
    go(`/sign-in#token_type=${token_type}&access_token=${access_token}&expires_in=${expires_in}&storage=session`, undefined, true);
  }
  const { actions } = value;
  return (
    <CardButtons className="mt-3">
      {actions.change && toggleInput && <CardButton onClick={toggleInput}>Edit</CardButton>}
      {actions.impersonate && <CardButton onClick={impersonate}>View as</CardButton>}
    </CardButtons>
  );
}

export function Body({ value, href, onClick, onUpdate, className, toggleInput, showStatus, showButtons, showMenu, children, ...attrs }: CardBodyAttributes<PersonModel> & CardBodyProps & {
  hideResume?: boolean
}) {
  const [navigate] = useNavigation();
  return (
    <CardBody className={`hover-container ${className}`} onClick={onClick || navigate(href) || undefined} {...attrs}>
      <Text value={value} />
      {children}
      {showStatus && <Status {...{ value, showMenu }} />}
      {showButtons && <Buttons {...{ value, onUpdate, toggleInput }} />}
    </CardBody>
  );
}

export default ({ value, href, children, onClick, onUpdate, showStatus, showMenu, showButtons, showDiscussion, openDiscussion }: CardAttributes<PersonModel> & {
  hideResume?: boolean
}) => {
  const { toggleInput } = useCardState({ showDiscussion, openDiscussion });
  return (
    <>
      <Card className={(href || onClick) ? "hover-shadow" : ""}>
        <Body className={(href || onClick) ? "cursor-pointer" : ""} {...{ value, href, onClick, onUpdate, showStatus, showMenu, showButtons, toggleInput }} >
          {children}
        </Body>
      </Card>
    </>
  );
}
