async function screenshot() {
  const { default: html2canvas } = await import("html2canvas");
  const canvas = await html2canvas(document.getElementById("root") || document.body, {
    proxy: "/api/content/proxy"
  });
  const image = canvas.toDataURL("image/png");
  return image;
}

export function useScreenshot(): [() => Promise<string>] {
  return [screenshot];
}
