import { SeverityLevel } from '@microsoft/applicationinsights-web';
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useAppInsights } from "../hooks/AppInsightsProvider";
import { useAuthorizationToken, getRefreshToken, useClaims } from "../hooks/TokenProvider";
import { ErrorType, ResourceRole } from "../types/api-graph-types";

const _clientId = "B96C5C45-B76F-48D2-9975-0F0F84842CAA";
const _baseUrls = new Map<string, string>([
  ["localhost:3000", "https://localhost:5001"],
  ["localhost:7071", "https://localhost:7071"],
  ["dev.higherknowledge.in:3000", "https://dev.higherknowledge.in:5001"],
  ["dev.higherknowledge.in:7071", "https://dev.higherknowledge.in:7071"]
]);
const _baseUrl = _baseUrls.get(window.location.host) || `https://${window.location.host}`;

interface GraphQlError {
  message: string;
  location: { line: number, column: number }[];
  path?: string[];
  extensions?: {
    code?: ErrorType
    data?: {
      error?: ErrorType
    }
  }
}

interface GraphQlResult<T> {
  data: T;
  errors: GraphQlError[];
}

interface OAuth2TokenResponse {
  token_type: string;
  access_token: string;
  expires_in: string;
  refresh_token: string;
  scope: string;
}

interface OAuth2TokenRequest {
  grantType: "password" | "authorization_code",
  code?: string,
  scope?: string,
  username?: string,
  password?: string,
  clientId?: string,
  redirect_uri?: string
}

export interface OAuth2TokenError {
  error: "invalid_request" | "invalid_client" | "invalid_grant" | "unauthorized_client" | "unsupported_grant_type" | "invalid_scope" | "login_required";
  error_description: string;
  error_uri: string;
}

export interface QueryOptions {
  requireToken?: boolean
}

export interface ResultProperties {
  index: number
}

export interface MutationOptions {
  setReadyState?: (value: ReadyState) => void,
  debounce?: number,
  files?: ReadonlyArray<File>,
}

export enum ReadyState {
  Complete = "COMPLETE",
  Loading = "LOADING",
  Error = "ERROR",
}

export type ApiErrors<T> = {
  [P in keyof T]?: T[P] extends object ? ApiErrors<T[P]>
  : T[P] extends (infer C)[] ? [ApiErrors<C>]
  : T[P] extends ReadonlyArray<infer C> ? [ApiErrors<C>]
  : ErrorType;
};

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]>
  : T[P] extends (infer C)[] ? [DeepPartial<C>]
  : T[P] extends ReadonlyArray<infer C> ? [DeepPartial<C>]
  : T[P];
};

interface DeepPartialMergeOptions {
  __delete?: boolean
  __replace?: boolean
  __swap?: any
};

interface IHasPolicy {
  policy: {
    role: ResourceRole
  }
}

const _initialContext: any = undefined;
const Context = createContext<[string]>(_initialContext);
const _jsonContent = "application/json; charset=UTF-8";
const _formContent = "application/x-www-form-urlencoded; charset=UTF-8";
const _operationsKey = "operations";
var _sequence = 1;

export function hasFlag<T>(values: T[], value: T): boolean {
  return values.includes(value);
};

export function canRead(value: IHasPolicy) {
  return value.policy?.role === ResourceRole.Reader || value.policy?.role === ResourceRole.Contributor || value.policy?.role === ResourceRole.Owner;
}

export function cannotRead(value: IHasPolicy) {
  return !canRead(value);
}

export function byKey<T>(keySelector: (((value: T) => number) | ((value: T) => string)), descending: boolean = false) {
  return (first: T, second: T) => {
    const firstKey = keySelector(first);
    const secondKey = keySelector(second);
    if (typeof (firstKey) === "number" && typeof (secondKey) === "number") {
      return descending
        ? secondKey - firstKey
        : firstKey - secondKey;
    } else if (typeof (firstKey) === "string" && typeof (secondKey) === "string") {
      return descending
        ? firstKey > secondKey ? -1 : firstKey < secondKey ? 1 : 0
        : firstKey < secondKey ? -1 : firstKey > secondKey ? 1 : 0;
    } else {
      return 0;
    }
  }
}

export function setFlag<T>(values: T[], value: T): T[] {
  if (values.includes(value)) {
    return values;
  }
  const result = Array.from(values);
  result.push(value);
  return result;
}

export function toggleFlag<T>(values: T[], value: T): T[] {
  return values.includes(value)
    ? values.filter(v => v !== value)
    : setFlag(values, value);
}

export function setDelete<T>(value: T): DeepPartial<T> {
  return { ...value, __delete: true };
}

export function setReplace<T>(value: T): DeepPartial<T> {
  return { ...value, __replace: true };
}

export function setSwap<T>(value: T, replacement: T): DeepPartial<T> {
  return { ...value, __swap: replacement };
}

export function toInput<T>(value?: T | undefined): T {
  return value === undefined ? undefined as any : value;
}

export function merge<T>(value: T | undefined, partial: DeepPartial<T>, replace?: boolean): T {
  if (Array.isArray(value) && Array.isArray(partial) && !replace) {
    //console.log("MERGE ARRAY !REPLACE", value, partial);
    let result = partial.length > 0 && typeof (partial[0]) === "object" ? [...value] : partial;
    partial.forEach((p, i) => {
      if (typeof (p) === "object") {
        const i0 = p.id ? result.findIndex(_ => _?.id === p.id) : undefined;
        const i1 = p.key ? result.findIndex(_ => _?.key === p.key) : undefined;
        if (i0 !== undefined && i0 !== -1) {
          result[i0] = merge(result[i0], p);
        } else if (i1 !== undefined && i1 !== -1) {
          result[i1] = merge(result[i1], p);
        } else {
          result.push(merge({}, p));
        }
      } else {
        result[i] = p;
      }
    });
    return result.filter(_ => _ !== undefined) as any;
  } else if (Array.isArray(value) && Array.isArray(partial) && replace) {
    //console.log("MERGE ARRAY REPLACE", value, partial);
    let result = partial;
    partial.forEach((p, i) => {
      if (typeof (p) === "object") {
        const i0 = p.id ? value.findIndex(_ => _.id === p.id) : undefined;
        const i1 = p.key ? value.findIndex(_ => _.key === p.key) : undefined;
        if (i0 !== undefined && i0 !== -1) {
          result[i] = merge(value[i0], p);
        } else if (i1 !== undefined && i1 !== -1) {
          result[i] = merge(value[i1], p);
        } else {
          result[i] = merge({}, p);
        }
      }
    });
    return result.filter(_ => _ !== undefined) as any;
  } else if (typeof (partial) === "object" && partial !== null && (partial as DeepPartialMergeOptions).__delete) {
    //console.log("MERGE OBJECT DELETE", value, partial);
    return undefined as any as T;
  } else if (typeof (partial) === "object" && partial !== null && (partial as DeepPartialMergeOptions).__swap) {
    //console.log("MERGE OBJECT SWAP", value, partial);
    return (partial as DeepPartialMergeOptions).__swap as any as T;
  } else if (typeof (value) === "object" && value !== null && typeof (partial) === "object" && partial !== null) {
    //console.log("MERGE OBJECT", value, partial);
    const result = Object.keys(partial).filter(k => !k.startsWith("__")).reduce((_: any, k) => {
      _[k] = merge(_[k], (partial as any)[k], (partial as DeepPartialMergeOptions).__replace);
      if (_[k] === undefined) {
        delete _[k];
      }
      return _;
    }, { ...value } as T);
    return result;
  } else if (partial === undefined) {
    return value as T;
  } else {
    return partial as T;
  }
}

function toErrors<T>(errors: GraphQlError[]): T {
  var result: any = {};
  errors.forEach(error => {
    if (error.path) {
      var value = result;
      for (var i = 0; i < error.path.length; i++) {
        if (i === error.path.length - 1) {
          value[error.path[i]] = error.extensions?.data?.error || ErrorType.Invalid;
        } else {
          value[error.path[i]] = {};
          value = value[error.path[i]];
        }
      }
    }
  });
  return result;
};

export function includeIf(when: boolean | string | undefined, fragment: string) {
  return !when ? ""
    : when === true ? fragment.replace("@@", "")
      : fragment.replace("@@", `@include(if:${when})`);
}

export enum Route {
  OrganizationLogo = "ORGANIZATION_LOGO"
}

export function useApiEndpoint(route?: Route) {
  const [baseUrl] = useContext(Context)
  switch (route) {
    case Route.OrganizationLogo: return [`${baseUrl}/api/content/organizations/{id}/logo/latest/logo.png`];
    default: return [baseUrl];
  }
}

async function _refreshToken(baseUrl: string, refreshToken: string | undefined): Promise<OAuth2TokenResponse | undefined> {
  if (refreshToken) {
    const data = new URLSearchParams();
    data.append("grant_type", "refresh_token");
    data.append("client_id", _clientId);
    data.append("refresh_token", refreshToken);
    const response = await fetch(`${baseUrl}/api/oauth2/token`, {
      method: "POST",
      headers: {
        "Content-Type": _formContent,
        "Accept": _jsonContent
      },
      body: data.toString()
    });
    if (response && response.status === 200) {
      const result: OAuth2TokenResponse & OAuth2TokenError = await response.json();
      if (!result.error) {
        return result;
      }
    }
  }
  return undefined;
}

export function useQuery<T>(query: string, variables?: object, options?: QueryOptions): [T | undefined, (partial: DeepPartial<T>) => void, boolean, GraphQlError[] | undefined, ResultProperties | undefined] {
  const appInsights = useAppInsights();
  const [token, setToken, tokenRefreshBy] = useAuthorizationToken();
  const [readyState, setReadyState] = useReadyState();
  const valueRef = useRef<T>();
  const [value, setValue] = useState<T>();
  const [properties, setProperties] = useState<ResultProperties>();
  const [errors, setErrors] = useState<GraphQlError[]>();
  const [baseUrl] = useContext(Context);
  const content = JSON.stringify({ query, variables });
  useEffect(() => {
    if (!query || (options?.requireToken && !token)) {
      return;
    }

    let cancelled = false;
    (async () => {
      setReadyState(ReadyState.Loading);

      // Refresh token (if expiring soon)
      if (token && tokenRefreshBy && tokenRefreshBy < new Date()) {
        const tokenResponse = await _refreshToken(baseUrl, getRefreshToken(token));
        if (tokenResponse?.access_token) {
          setToken(tokenResponse.access_token, tokenResponse.expires_in, tokenResponse.refresh_token);
          return; // setToken will trigger re-fetch since effect depends on token
        }
      }

      var response;
      try {
        response = query && await fetch(`${baseUrl}/api/graph`, {
          method: "POST",
          headers: [
            ["Authorization", `Bearer ${token}`],
            ["Content-Type", _jsonContent],
            ["Accept", _jsonContent]
          ],
          body: content
        }) || undefined;
      } catch (e) {
        console.error(e);
      }
      if (cancelled) {
        // Ignore.
      } else if (response && response.status === 200) {
        const result: GraphQlResult<T> = await response.json();
        if (!cancelled) {
          if (result.errors?.length > 0) {
            setValue(undefined);
            setErrors(result.errors);
            setReadyState(ReadyState.Error);
            result.errors.forEach(e => console.error(e.message, e));
            result.errors.forEach(e => appInsights.trackTrace({
              severityLevel: SeverityLevel.Error,
              message: e.message,
              properties: { ["error"]: e }
            }));
            if (result.errors.filter(e => e.extensions?.code === ErrorType.Unauthenticated).length > 0) {
              setToken("");
            }
          } else {
            setValue(result.data);
            setErrors(undefined);
            setReadyState(ReadyState.Complete);
          }
          setProperties({
            index: (properties?.index ?? 0) + 1
          });
        }
      } else {
        setValue(undefined);
        setErrors(undefined);
        setProperties(undefined);
        setReadyState(ReadyState.Error);
        if (response && response.status === 401) {
          setToken("");
        }
      }
    })();
    return () => { cancelled = true; }
  }, [token, content, baseUrl]);
  valueRef.current = value;
  const isLoading = readyState === ReadyState.Loading;
  const mergeValue = (partial: DeepPartial<T>) => setValue(merge(valueRef.current ?? value, partial));
  return [value, mergeValue, isLoading, errors, properties];
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function useReadyState(initialValue?: ReadyState) {
  return useState(initialValue ?? ReadyState.Complete);
}

export function useMutation<TResult>(): [(query: string, variables: object, options?: MutationOptions) => Promise<TResult>] {
  const appInsights = useAppInsights();
  const [token, setToken, tokenRefreshBy] = useAuthorizationToken();
  const [baseUrl] = useContext(Context);
  const sequenceRef = useRef<string>();
  async function mutate(query: string, variables: object, options?: MutationOptions): Promise<TResult> {
    // Debounce
    if (options && options.debounce) {
      const seq = nextSequence();
      sequenceRef.current = seq;
      await delay(options.debounce);
      if (sequenceRef.current != seq) {
        return undefined as any;
        //throw new Error("Operation cancelled");
      }
    }

    options?.setReadyState?.(ReadyState.Loading);

    // Refresh token (if expiring soon)
    var tokenResponse: OAuth2TokenResponse | undefined;
    if (token && tokenRefreshBy && tokenRefreshBy < new Date()) {
      tokenResponse = await _refreshToken(baseUrl, getRefreshToken(token));
      if (tokenResponse?.access_token) {
        setToken(tokenResponse.access_token, tokenResponse.expires_in, tokenResponse.refresh_token);
      }
    }

    const operations = JSON.stringify({ query, variables });
    const [headers, body] = !options?.files?.length
      ? [{ "Content-Type": _jsonContent } as HeadersInit, operations]
      : [{} as HeadersInit, (() => {
        const data = new FormData();
        data.append(_operationsKey, new Blob([operations], { type: _jsonContent }));
        for (var i = 0; i < options.files.length; i++) {
          data.append(`${i}`, options.files[i], options.files[i].name);
        }
        return data;
      })()];
    var response;
    try {
      response = await fetch(`${baseUrl}/api/graph`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${tokenResponse?.access_token || token}`,
          "Accept": _jsonContent,
          ...headers
        },
        body: body
      });
      options?.setReadyState?.(ReadyState.Complete);
    } catch (e) {
      options?.setReadyState?.(ReadyState.Error);
    }
    //window.clearTimeout(timer);
    if (response && response.status === 200) {
      const result: GraphQlResult<TResult> = await response.json();
      if (result.errors && result.errors.length) {
        result.errors.forEach(e => console.error(e.message, e));
        result.errors.forEach(e => appInsights.trackTrace({
          severityLevel: SeverityLevel.Error,
          message: e.message,
          properties: { ["error"]: e }
        }));
        if (result.errors.filter(e => e.extensions?.code === ErrorType.Unauthenticated).length > 0) {
          setToken("");
        }
        throw toErrors(result.errors);
      }
      return result.data;
    } else {
      if (response && response.status === 401) {
        setToken("");
      }
      throw response;
    }
  }
  return [mutate];
}

export function useRoute(template: string) {
  const [claims] = useClaims();
  const [accessToken] = useAuthorizationToken();
  const [baseUrl] = useContext(Context);
  const path = template
    .replace("{me}", claims?.sub ?? "{me}")
    .replace("{access_token}", accessToken ?? "{access_token}");
  return `${baseUrl}${path}`;
}

export function nextNonce(length: number = 8) {
  return [...Array(length)].map(i => (~~(Math.random() * 36)).toString(36)).join("");
}

const _base32Chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
export function nextBase32Nonce(length: number = 16) {
  return [...Array(length)].map(i => _base32Chars[(~~(Math.random() * 32))]).join("");
}

export function nextSequence() {
  return `S${++_sequence}`;
}

export function nextUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function delUndefined(obj: any) {
  Object.keys(obj).forEach(key => obj[key] === undefined ? delete obj[key] : {});
  return obj;
}

export function useOAuth2(): [
  (request: { login_hint: string, redirect_uri: string, scope?: string, state?: string }) => void,
  (request: OAuth2TokenRequest) => Promise<OAuth2TokenResponse>
] {
  const [_baseUrl] = useContext(Context);
  const baseUrl = _baseUrl.replace("://localhost", "://dev.higherknowledge.in");
  function authorize({ login_hint, redirect_uri, scope, state }: {
    login_hint: string,
    redirect_uri: string,
    scope?: string,
    state?: string,
  }) {
    const scopeParameter = scope ? `&scope=${encodeURIComponent(scope)}` : "";
    const stateParameter = state ? `&state=${encodeURIComponent(state)}` : "";
    const nonce = nextNonce();
    const url = `${baseUrl}/api/oauth2/authorize?login_hint=${encodeURIComponent(login_hint)}&response_type=code&client_id=${encodeURIComponent(_clientId)}&redirect_uri=${encodeURIComponent(redirect_uri)}${scopeParameter}${stateParameter}&nonce=${encodeURIComponent(nonce)}`;
    const useGet = false;
    if (useGet) {
      window.location.href = url;
    } else {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = url;
      document.body.append(form);
      form.submit();
    }
  }
  async function exchange(request: OAuth2TokenRequest): Promise<OAuth2TokenResponse> {
    const data = new URLSearchParams();
    data.append("grant_type", request.grantType);
    data.append("client_id", _clientId);
    if (request.grantType === "password") {
      request.username && data.append("username", request.username);
      request.password && data.append("password", request.password);
      request.redirect_uri && data.append("redirect_uri", request.redirect_uri);
    } else if (request.grantType === "authorization_code") {
      request.code && data.append("code", request.code);
    }
    request.scope && data.append("scope", request.scope);
    const response = await fetch(`${baseUrl}/api/oauth2/token`, {
      method: "POST",
      headers: {
        "Content-Type": _formContent,
        "Accept": _jsonContent
      },
      body: data.toString()
    });
    const result: OAuth2TokenResponse & OAuth2TokenError = await response.json();
    if (result.error) {
      throw result;
    }
    return result as OAuth2TokenResponse;
  }
  return [authorize, exchange];
}

export function asType<T>(value: any, test?: (value: any) => boolean): value is T {
  return test ? test(value) : true;
}

export default ({ children }: { children?: ReactNode }) => {
  const [baseUrl] = useState(_baseUrl);
  return (
    <Context.Provider value={[baseUrl]}>
      {children}
    </Context.Provider>
  );
}
