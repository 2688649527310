import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEvent } from "react";
import { CardText, Col, Row } from "reactstrap";
import AttachmentCard, { Fragment as AttachmentFragment } from "../cards/AttachmentCard";
import JobCard, { Fragment as JobFragment } from "../cards/JobCard";
import { Badges as LanguageBadges, Fragment as LanguagesFragment } from "../cards/MyProfile/LanguagesCard";
import { Badges as LocationBadges, Fragment as LocationsFragment } from "../cards/MyProfile/LocationsCard";
import { Fragment as MembershipFragment } from "../cards/MyProfile/MembershipsCard";
import { Badges as TopicBadges, Fragment as TopicsFragment } from "../cards/MyProfile/TopicsCard";
import PersonCard, { Fragment as PersonFragment } from "../cards/PersonCard";
import SubscriptionCard, { Fragment as SubscriptionFragment } from "../cards/SubscriptionCard";
import { Fragment as AttachmentsFragment, List as AttachmentsList } from "../components/CardAttachmentsListGroup";
import CardColumns from "../components/CardColumns";
import { DeepPartial, useQuery } from "../hooks/ApiProvider";
import { useUpdatableState } from "../hooks/CommonHooks";
import { preventDefault } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { Fragment as SubscriptionInputFragment } from "../modals/SubscriptionInputModal";
import { AccountSubscriptionModel, AttachmentModel, AttachmentType, JobModel, PersonModel } from "../types/api-graph-types";
import LoadingController from "./LoadingController";

const _getPerson = `query getPerson($id:ID! $first:Int) {
  person(id:$id) {
    ${PersonFragment}
    resume { ${AttachmentFragment} }
    attachments { type ${AttachmentsFragment} }
    memberships { ${MembershipFragment} }
    topicPreferences { ${TopicsFragment} }
    locationPreferences { ${LocationsFragment} }
    languagePreferences { ${LanguagesFragment} }
    jobs(first:$first states:[RESOLVED,COMPLETED]) { ${JobFragment} }
    subscriptions(states:[COMPLETED] first:$first) { id ${SubscriptionFragment} ${SubscriptionInputFragment} }
  }
}`;

interface Result {
  person: PersonModel
}

const _initialVars = { first: 6 };

export default ({ id }: { id: string }) => {
  const [vars, setVars, updateVars] = useUpdatableState(_initialVars);
  const [result, updateResult, isLoading] = useQuery<Result>(_getPerson, { id, ...vars });
  const value = result?.person;
  useTitle(value && value.name);
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const getMore = (e: MouseEvent) => updateVars({ first: e.getModifierState("Shift") ? 1024 : vars.first * 2 });
  const update = (person: DeepPartial<PersonModel>) => updateResult({ person });
  const updateJob = (job: DeepPartial<JobModel>) => update({ jobs: [job] });
  const updateResume = (resume: DeepPartial<AttachmentModel>) => update({ resume });
  const updateSubscription = (subscription: AccountSubscriptionModel) => update({ subscriptions: [subscription] });
  const clearFilters = () => setVars(_initialVars);
  const attachments = value.attachments.filter(_ => _.type !== AttachmentType.Resume && _.type !== AttachmentType.Photo);

  return (
    <>
      <Row className="mt-3">
        <Col>
          <PersonCard value={value} showStatus showButtons>
            <CardText>
              <LocationBadges values={value.locationPreferences} />
              <TopicBadges values={value.topicPreferences} />
              <LanguageBadges values={value.languagePreferences} />
            </CardText>
            {attachments.length > 0 && <AttachmentsList className="mt-3" values={attachments} />}
          </PersonCard>
        </Col>
      </Row>
      <Row className="mt-3">
        <CardColumns md={2} xl={3}>
          {value.jobs.map((_, i) => <JobCard key={i} href={window.location.pathname + `/tasks/${_.id}`} value={_} onUpdate={updateJob} showStatus />)}
        </CardColumns>
      </Row>
      {(isLoading || value.jobs.length > 0) &&
        <Row className="mt-3">
          <Col>
            {isLoading && <FontAwesomeIcon className="text-muted" icon="circle-notch" spin />}
            {!isLoading && value.jobs.length >= vars.first && <a href="#" onClick={preventDefault(getMore)}>See more</a>}
          </Col>
        </Row>
      }
      {value.resume &&
        <Row className="mt-3">
          <Col>
            <AttachmentCard value={value.resume} onUpdate={updateResume} showStatus showButtons showDiscussion showReview />
          </Col>
        </Row>
      }
      {value.subscriptions &&
        <Row>
          <Col>
            {value.subscriptions.map((_, i) => <SubscriptionCard key={i} className="mt-3" href={`/my/subscriptions/${_.id}`} value={_} onUpdate={updateSubscription} showButtons showStatus showTransactions showInput />)}
          </Col>
        </Row>
      }
    </>
  )
};

