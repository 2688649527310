import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faChevronLeft, faChevronRight, faCircle, faDownload, faLanguage, faMinus, faPlus, faStar, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardSubtitle, CardText, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Row, UncontrolledDropdown } from "reactstrap";
import AttachmentCard, { Fragment as AttachmentFragment } from "../cards/AttachmentCard";
import JobCard, { Fragment as JobFragment } from "../cards/JobCard";
import { Badges as LanguageBadges, Fragment as LanguagesFragment } from "../cards/MyProfile/LanguagesCard";
import { Badges as LocationBadges, Fragment as LocationsFragment } from "../cards/MyProfile/LocationsCard";
import { Badges as TopicBadges, Fragment as TopicsFragment } from "../cards/MyProfile/TopicsCard";
import { Buttons as OrderButtons, Fragment as OrderFragment, OrderDetailsText } from "../cards/OrderCard";
import { Fragment as PersonFragment, Text as PersonText, UserThumbnail, UserThumbnailFragment } from "../cards/PersonCard";
import ReviewCard, { Fragment as ReviewFragment } from "../cards/ReviewCard";
import { Fragment as AttachmentListFragment, List as AttachmentsList } from "../components/CardAttachmentsListGroup";
import CardColumns from "../components/CardColumns";
import CardDiscussionListGroup, { CommentsFragment as CommentFragment } from "../components/CardDiscussionListGroup";
import CardEventsList, { Fragment as EventListFragment } from "../components/CardEventsList";
import { CardBadge, CardButton, CardButtons, CardCommentsSummaryStatusItem, CardRatingHistogram, CardRatingStatusItem, CardStatus, CardStatusDetail, CardTasksStatusItem, CommentsSummaryFragment } from "../components/CardStatus";
import DragDropZone from "../components/DragDropZone";
import LoadingButton from "../components/LoadingButton";
import { DeepPartial, merge, nextNonce, setReplace, setSwap, useMutation, useQuery, byKey } from "../hooks/ApiProvider";
import { isMaybeResume, useAttachments } from "../hooks/AttachmentHook";
import { useCardState } from "../hooks/CardStateHook";
import { useToggle, useUpdatableState } from "../hooks/CommonHooks";
import { useEvent } from "../hooks/EventsHook";
import { locationBase, updateURIComponents, useFragment, useNavigation, doNothing } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { CreateLabelModal } from "../modals/ProductInputModal";
import ReviewInputModal, { ReviewCriteria } from "../modals/ReviewInputModal";
import { MessageModal as ShareMessageModal } from "../modals/ShareModal";
import { AccessTokenModel, AttachmentModel, AttachmentType, EventModel, JobModel, LabelModel, OrderModel, OrderStage, PersonModel, ProductModel, ResourceEvent, ResourceKind, ResourceRole, ReviewModel, ReviewOption } from "../types/api-graph-types";
import LoadingController from "./LoadingController";

library.add(faCheck, faMinus, faCircle, faDownload, faLanguage, faPlus, faStar, faChevronLeft, faChevronRight, faUserSecret);

const _relatedFragment = `
  id 
  kind 
  stage 
  details {
    screeningOn
    interviewOn
    offerExpiresOn
    startOn
    endOn
  }
  product { 
    id 
    kind    
    details { title subtitle displayTitle }
    owner { ${UserThumbnailFragment} }
    organization { id kind name logo } 
  }
`

const _getProductOrder = `query getProductOrder($pid:ID $oid:ID $first:Int $fetchMore:Boolean! $fetchAfter:String $fetchFirst:Int) {
  product(id:$pid) {
    id
    kind
    details { 
      title 
      subtitle 
      displayTitle 
      ordersJobsCount 
      ordersJobsMaxCount 
      ordersDiscussionDescription
      ordersDiscussionMessages 
      ordersCustomerAttachmentReviews
      messages { 
        key
        name
        subject
        content
        shortContent
        attachments { key filename length token url }
      }
    }
    order(id:$oid) {
      id
      kind
      ${OrderFragment}
      ratingAverage
      ratingCount
      details {
        customerName
        labels { key color value }
      }
      product {
        id
        kind
        details { 
          ordersJobsWorkitemsByAreas
          ordersDiscussionMessages
          labels { key color value isArchived }
        }
        actions { share }
        policy { role }
      }
      customer {
        ${PersonFragment}
        resume { updatedOn ${AttachmentFragment} }
        attachments(types:[PHOTO,GRADUATION_CERTIFICATE,EMPLOYMENT_CERTIFICATE,RECOMMENDATION_LETTER]) { ${AttachmentListFragment} }
        topicPreferences { ${TopicsFragment} }
        locationPreferences { ${LocationsFragment} }
        languagePreferences { ${LanguagesFragment} }
        reviews { ${ReviewFragment} }
        jobsSummary { count resolvedPercent positiveCount neutralCount negativeCount resolvedCount completedCount removedCount ratingHistogram ratingCount }
        jobs(first:$first states:[COMPLETED] excludeRelatedIds:[$oid]) { ${JobFragment} }
      }
      jobsSummary { count resolvedPercent positiveCount neutralCount negativeCount proposedCount inprogressCount resolvedCount completedCount removedCount ratingHistogram ratingCount }
      jobs(first:$first states:[RESOLVED,COMPLETED]) { ${JobFragment} }
      reviews { ${ReviewFragment} }
      review { ${ReviewFragment} }
      comments { ${CommentFragment} }
      commentsSummary { ${CommentsSummaryFragment} }
      attachments { updatedOn type ${AttachmentListFragment} }
      events(first:10) { ${EventListFragment} }
      collection(stages:[OFFERED,ACCEPTED]) { ${_relatedFragment} }
      children { ${_relatedFragment} }
      policy { accessToken }
    }
    orders(first:$fetchFirst after:$fetchAfter) @include(if:$fetchMore) { id cursor }
  }
}`;

const _changeLabels = `mutation changeLabels($pid:ID $oid:ID $details:OrderDetailsInputModel) {
  product(id:$pid) {
    orders(ids:[$oid]) {
      details { change(value:$details) }
      commit { id details { labels { key color value } } }
    }
  }
}`

const _impersonatePerson = `mutation impersonatePerson($id:ID) {
  person(id:$id) { impersonate { token_type access_token expires_in } }
}`;

interface Result {
  product: {
    order: OrderModel,
    orders: ReadonlyArray<OrderModel>
  } & ProductModel
}

interface OperationResults {
  product: {
    orders: {
      commit: OrderModel[]
    }
  }
  person: {
    impersonate: AccessTokenModel
  }
}

const _stageColors = new Map<OrderStage, string>([
  [OrderStage.Removed, "light"],
  [OrderStage.Refused, "light"],
  [OrderStage.Requested, "light"],
  [OrderStage.Submitted, "light"],
  [OrderStage.Shortlisted, "dark"],
  [OrderStage.ScreeningScheduled, "dark"],
  [OrderStage.InterviewScheduled, "dark"],
  [OrderStage.Offered, "success"],
  [OrderStage.Accepted, "success"],
  [OrderStage.Delivered, "success"],
  [OrderStage.OfferExpired, "warning"],
  [OrderStage.Declined, "warning"],
]);

function BreadCrumbs({ product, value, up }: {
  value: OrderModel;
  product?: ProductModel;
  up?: string
}) {
  return (
    <Breadcrumb>
      {product && <BreadcrumbItem><a href={up}>{product.details.displayTitle}</a></BreadcrumbItem>}
      <BreadcrumbItem active>{value.details.customerName || value.customer.name}</BreadcrumbItem>
    </Breadcrumb>
  );
}

function OrderRow({ value, onUpdate }: {
  value: OrderModel;
  onUpdate: (order: DeepPartial<OrderModel>) => void;
}) {
  const [t, d] = useTranslation();
  return (
    <ListGroupItem className="d-flex align-items-center">
      {value.product.details.manufacturerOrg?.logo
        ? <img src={value.product.details.manufacturerOrg.logo} width={32} height={32} className="mr-3 align-baseline" />
        : <UserThumbnail width={32} className="mr-3" value={value.product.owner} />
      }
      <div className="w-100">
        <span className="mr-1">{value.product.details.displayTitle}</span>
        <Badge className="mr-1" color={_stageColors.get(value.stage)}>{t(value.stage)}</Badge>
        {value.stage === OrderStage.ScreeningScheduled && <span className="small text-muted mr-1">{d(value.details.screeningOn, DateDisplayFormat.HumanDateTime)}</span>}
        {value.stage === OrderStage.InterviewScheduled && <span className="small text-muted mr-1">{d(value.details.interviewOn, DateDisplayFormat.HumanDateTime)}</span>}
        {value.stage === OrderStage.Offered && value.details.offerExpiresOn && <span className="small text-muted mr-1">expires {d(value.details.offerExpiresOn, DateDisplayFormat.HumanDate)}</span>}
        {value.stage === OrderStage.Accepted && <span className="small text-muted mr-1">starting {d(value.details.startOn, DateDisplayFormat.HumanDateRange, value.details.endOn)}</span>}
      </div>
    </ListGroupItem>
  );
}

function OrderCard({ value, product: _product, onUpdate, onUpdateProduct }: {
  value: OrderModel;
  product: ProductModel;
  onUpdate: (order: DeepPartial<OrderModel>) => void;
  onUpdateProduct: (product: DeepPartial<ProductModel>) => void;
}) {
  const [t] = useTranslation();
  const [, go] = useNavigation();
  const { addAttachment } = useAttachments();
  const [isOpenJobsSummary, toggleJobsSummary] = useToggle();
  const [isopenDiscussionsSummary, toggleReviewsSummary] = useToggle();
  const [isopenShare, toggleShare] = useToggle();
  const [isopenReview, toggleReview] = useToggle();
  const { isOpenDiscussion, toggleDiscussion } = useCardState({ showDiscussion: true, openDiscussion: true });
  const [mutation] = useMutation<OperationResults>();
  const product = value.product ?? _product;
  const impersonate = async () => {
    const result = await mutation(_impersonatePerson, { id: value.customer.id });
    const { token_type, access_token, expires_in } = result.person.impersonate;
    go(`/sign-in#token_type=${token_type}&access_token=${access_token}&expires_in=${expires_in}&storage=session`, undefined, true);
  }
  const attachFile = async (file: File) => {
    const fake = { key: nextNonce(), filename: file.name } as AttachmentModel;
    onUpdate({ attachments: [fake] });
    const isResume = isMaybeResume(file.name);
    const _attachment = await addAttachment(value.id, ResourceKind.Order, isResume ? AttachmentType.Resume : AttachmentType.Other, file, value.policy?.accessToken);
    const attachment = { ..._attachment, key: fake.key };
    onUpdate({
      attachments: [attachment],
      ...(isResume ? { resume: attachment } : {})
    });
  }
  const updateChild = (child: DeepPartial<OrderModel>) => onUpdate({ children: [child] });
  const updateRelated = (related: DeepPartial<OrderModel>) => onUpdate({ collection: [related] });
  const customerName = value.details.customerName || value.customer.name;
  const reviewCriteria = product?.details.ordersDiscussionMessages?.map(_ => [1, 10, ReviewOption.Null, 0, _] as ReviewCriteria);
  const related = value.collection?.filter(_ => _.id !== value.id);
  const hasChildren = value.children?.length > 0;
  const hasRelated = related?.length > 0;
  const showRating = product?.details.ordersJobsMaxCount !== 0;
  const showReview = value.actions.review;
  return (
    <Card>
      <DragDropZone onDrop={attachFile}>
        <CardBody className="drag-shadow">
          <PersonText value={{ ...value.customer, name: customerName }}
            prependTitle={_stageColors.get(value.stage) && <CardSubtitle tag="h5"><Badge color={_stageColors.get(value.stage)}>{t(value.stage)}</Badge></CardSubtitle>} />
          <LabelsText value={value} onUpdate={onUpdate} />
          <OrderDetailsText compensationPeriod="P30D" value={value} product={product} />
          <AttachmentsList className="mb-0" values={[...value.attachments, ...value.customer.attachments]} />
          <CardStatus>
            {showRating && value.ratingAverage > 0 && <CardRatingStatusItem value={value.ratingAverage} color count={value.jobsSummary.completedCount} toggle={toggleReviewsSummary} />}
            {showReview && <CardCommentsSummaryStatusItem value={value.commentsSummary} toggle={toggleDiscussion} />}
            {showRating && <CardTasksStatusItem value={`${value.jobsSummary.resolvedPercent}%`} toggle={toggleJobsSummary} />}
          </CardStatus>
          <Collapse isOpen={isOpenJobsSummary}>
            <CardStatus>
              <CardStatusDetail value={value.jobsSummary.positiveCount + value.jobsSummary.neutralCount} valueClassName="strong" label="Approved" />
              {value.jobsSummary.negativeCount > 0 && <CardStatusDetail value={value.jobsSummary.negativeCount} valueClassName="strong" label="Unsatisfactory" />}
              {value.jobsSummary.resolvedCount > 0 && <CardStatusDetail value={value.jobsSummary.resolvedCount} valueClassName="strong" label="Under Review" />}
              {(value.jobsSummary.removedCount + value.jobsSummary.proposedCount + value.jobsSummary.inprogressCount) > 0 && <CardStatusDetail value={value.jobsSummary.removedCount + value.jobsSummary.proposedCount + value.jobsSummary.inprogressCount} valueClassName="strong" label="Incomplete" />}
            </CardStatus>
          </Collapse>
          <Collapse isOpen={isopenDiscussionsSummary}>
            <CardRatingHistogram value={value.jobsSummary.ratingHistogram} max={value.jobsSummary.ratingCount} />
          </Collapse>
          <OrderButtons {...{ value, onUpdate }}>
            {product.actions.share && <CardButton icon="share" onClick={toggleShare}>Share</CardButton>}
            {showReview && <CardButton icon={["fas", "star"]} onClick={toggleReview}>Review</CardButton>}
            {value.customer.actions.impersonate && <CardButton icon="user-secret" onClick={impersonate}>View as</CardButton>}
          </OrderButtons>
        </CardBody>
      </DragDropZone>
      {hasChildren &&
        <ListGroup flush className="bt-1">
          {value.children.map(_ => <OrderRow key={_.id} value={_} onUpdate={updateChild} />)}
        </ListGroup>
      }
      {hasRelated && false &&
        <ListGroup flush className="bt-1">
          {related.map(_ => <OrderRow key={_.id} value={_} onUpdate={updateRelated} />)}
        </ListGroup>
      }

      {showReview && <CardDiscussionListGroup value={value} onUpdate={onUpdate} help={_product.details.ordersDiscussionDescription} helpPrivate="Only specific people within the team" helpPublic={`Anyone including ${customerName}`} visibilityPrivate={product.id} showReview isOpen={isOpenDiscussion} />}
      <ShareMessageModal value={_product} isOpen={isopenShare} toggle={toggleShare}
        title={`${customerName}'s application`}
        url={`${locationBase}/my/listings/${_product.id}/candidates/${value.id}?scope=claim:MELLON`}
        recruiterUrl={`${locationBase}/my/shares/{{Id}}?scope=claim:MELLON#token={{AccessToken}}&collection=${value.id}`}
        showTemplates templates={_product.details.messages} onUpdateTemplates={messages => onUpdateProduct?.({ details: setReplace({ messages }) })}
      />
      <ReviewInputModal onUpdate={review => onUpdate({ id: value.id, reviews: [review] })} criteria={reviewCriteria} defaultPrivate isOpen={isopenReview} toggle={toggleReview} objectId={value.id} objectKind={ResourceKind.Order} title={customerName} />
    </Card >
  );
}

function LabelsText({ value, onUpdate }: {
  value: OrderModel,
  onUpdate: (order: DeepPartial<OrderModel>) => void
}) {
  const [isOpenCreate, toggleCreate] = useToggle();
  const [mutation] = useMutation<OperationResults>();
  const addLabel = async (key: string) => {
    const details = { labels: merge(value.details.labels, [{ key }]) };
    const result = await mutation(_changeLabels, { pid: value.product.id, oid: value.id, details })
    onUpdate({ details: setReplace(result.product.orders.commit[0].details) });
  }
  const removeLabel = async (key: string) => {
    const details = { labels: value.details.labels.filter(_ => _.key !== key) };
    const result = await mutation(_changeLabels, { pid: value.product.id, oid: value.id, details })
    onUpdate({ details: setReplace(result.product.orders.commit[0].details) });
  }
  const updatedProductLabel = async (product: DeepPartial<ProductModel>, label: LabelModel) => {
    onUpdate({ product });
    addLabel(label.key);
  }
  const productLabels = new Map<string, LabelModel>(value.product.details.labels.filter(_ => !_.isArchived).map(_ => [_.key, _]));
  const labels = value.details.labels.filter(_ => productLabels.has(_.key) || _.color || _.value);
  const options = value.product.details.labels.filter(_ => !_.isArchived && !value.details.labels.some(l => l.key === _.key));
  const showLabels = (labels.length + options.length) > 0;
  const isProdctContributor = value.product.policy.role === ResourceRole.Owner || value.product.policy.role === ResourceRole.Contributor;
  const count =
    value.customer.locationPreferences.length
    + value.customer.topicPreferences.length
    + value.customer.languagePreferences.length
    + labels.length
    + (options.length > 0 ? 1 : 0);
  return count > 0 ? (
    <CardText tag="ul" className="list-unstyled">
      {value.customer.topicPreferences.length > 0 && <li><b className="mr-1">Interests</b><TopicBadges size="inline" values={value.customer.topicPreferences} /></li>}
      {value.customer.locationPreferences.length > 0 && <li><b className="mr-1">Locations</b><LocationBadges size="inline" values={value.customer.locationPreferences} /></li>}
      {value.customer.languagePreferences.length > 0 && <li><b className="mr-1">Languages</b><LanguageBadges size="inline" values={value.customer.languagePreferences} /></li>}
      {showLabels &&
        <li>
          <b className="mr-1">Labels</b>
          {labels.map((_, i) => <CardBadge className="cursor-pointer" size="inline" style={{ backgroundColor: _.color || productLabels.get(_.key)?.color || '#f8f9fa', minWidth: "1.75rem" }} key={i} value={_.value || productLabels.get(_.key)?.value || '\u00A0'} remove onClick={() => removeLabel(_.key)} />)}
          {options.length > 0 &&
            <UncontrolledDropdown tag="span">
              <DropdownToggle tag="span" className="mr-1 badge badge-white cursor-pointer">
                <FontAwesomeIcon icon="plus" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag="div" className="no-hover">
                  <div className="d-flex flex-wrap" style={{ minWidth: "300px" }}>
                    {options.map((_, i) => <CardBadge add className="cursor-pointer" size="sm" style={{ backgroundColor: _.color || '#f8f9fa', minWidth: "1.75rem" }} key={i} value={_.value || '\u00A0'} onClick={() => addLabel(_.key)} />)}
                    {isProdctContributor &&
                      <Button className="mr-1 mt-1 b-0 p-0 badge badge-white" color="link" size="sm" onClick={toggleCreate}>
                        <FontAwesomeIcon icon="plus" />
                      </Button>
                    }
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        </li>
      }
      {isProdctContributor && <CreateLabelModal isOpen={isOpenCreate} toggle={toggleCreate} value={value.product} onUpdate={updatedProductLabel} />}
    </CardText>
  ) : null;
}

function ResumeCard({ value, product, onUpdate }: {
  value: AttachmentModel,
  product: ProductModel,
  onUpdate?: (value: DeepPartial<AttachmentModel>) => void
}) {
  const [, d] = useTranslation();
  const reviewCriteria = product?.details.ordersDiscussionMessages?.map(_ => [1, 10, ReviewOption.Null, 0, _] as ReviewCriteria);
  return (
    <AttachmentCard value={value} onUpdate={onUpdate} privateDiscussion={product.id} sideDiscussion="xl" showButtons showDiscussion={product.details.ordersCustomerAttachmentReviews} showReview openDiscussion openReview appendDiscussionCriteria={reviewCriteria} >
      <CardText className="small text-muted">
        Last updated {d(value.updatedOn, DateDisplayFormat.TimeAgo)}
      </CardText>
    </AttachmentCard>
  );
}

function ActivityCard({ values }: { values: ReadonlyArray<EventModel> }) {
  const [isOpen, toggle] = useToggle();
  return (
    <div>
      {!isOpen && <span className="small text-muted" onClick={toggle}>See activity</span>}
      <Collapse isOpen={isOpen}>
        <CardEventsList values={values} />
      </Collapse>
    </div>
  );
}

const _initialFragment = {
  collection: [] as string[],
  up: "",
  after: ""
};

export default ({ pid, oid }: { pid: string, oid: string }) => {
  const [fragment] = useFragment(_initialFragment);
  const [navigate, go] = useNavigation();
  const [vars, setVars, updateVars] = useUpdatableState({ first: 6 });
  const moreVars = {
    fetchMore: !!fragment.after && (fragment.collection[fragment.collection.length - 1] === oid),
    fetchAfter: fragment.after,
    fetchFirst: fragment.collection.length + 10
  };
  const [result, updateResult, isLoading] = useQuery<Result>(_getProductOrder, { ...vars, ...moreVars, pid, oid });
  const [mutation] = useMutation<OperationResults>();
  const value = result?.product?.order;
  const product = result?.product;
  const moreOrders = product?.orders || [];
  const collection = moreOrders.length ? moreOrders.map(_ => _.id) : fragment.collection;
  const selfIndex = collection?.findIndex(id => value?.id === id) ?? -1;
  const showNext = selfIndex < collection.length - 1;
  const nextId = showNext && collection[selfIndex + 1];
  const showPrev = selfIndex > 0;
  const prevId = showPrev && collection[selfIndex - 1];
  const [nextResult] = useQuery<Result>(nextId ? _getProductOrder : "", { ...vars, ...moreVars, pid, oid: nextId });
  useEvent(ResourceEvent.SupplierViewedOrder, value);
  useTitle(value && `${value.customer.name} - ${product && product.details.displayTitle}`);
  if (!result || !value || !product) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const updateOrder = (order: DeepPartial<OrderModel>) => updateResult({ product: { order } });
  const updateProduct = (product: DeepPartial<ProductModel>) => updateResult({ product });
  const updateJob = (job: DeepPartial<JobModel>) => updateResult({ product: { order: { jobs: [job] } } });
  const updateCustomer = (customer: DeepPartial<PersonModel>) => updateResult({ product: { order: { customer } } });
  const updateCustomerJob = (job: DeepPartial<JobModel>) => updateResult({ product: { order: { customer: { jobs: [job] } } } });
  const updateCustomerReview = (review: DeepPartial<ReviewModel>) => updateResult({ product: { order: { customer: { reviews: [review] } } } });
  const updateResume = (resume: DeepPartial<AttachmentModel>) => resume.id === value.customer.resume?.id ? updateCustomer({ resume }) : updateOrder({ attachments: [resume] });
  const getMore = () => updateVars({ first: vars.first * 2 });
  const navigateNext = () => {
    nextResult && updateResult({ product: { order: setSwap(result.product.order, nextResult.product.order) } });
    go(`/my/listings/${product?.id}/candidates/${nextId}${hash}`);
  }

  const resume = [value.customer.resume, ...(value.attachments || []).filter(_ => _.type === AttachmentType.Resume)].filter(_ => !!_?.updatedOn).sort(byKey(_ => _.updatedOn, true))[0];
  const showJobs = product?.details.ordersJobsMaxCount !== 0;
  const hasReviews = value.customer.reviews.length > 0;
  const hasJobs = value.jobs.length > 0 || value.customer.jobs.length > 0;
  const hasResume = !!resume;
  const hasActivity = value.events?.length > 0;
  const showPrevNext = showPrev || showNext;
  const hash = moreOrders.length ? updateURIComponents(window.location.hash, {
    collection,
    up: fragment.up,
    after: moreOrders.length >= moreVars.fetchFirst ? moreOrders[moreOrders.length - 1].cursor : ""
  }) : window.location.hash;
  const showOtherJobs = !value.product.details.ordersJobsWorkitemsByAreas;
  const jobsCollection = [...value.jobs.map(_ => _.id), ...(showOtherJobs ? value.customer.jobs.map(_ => _.id) : [])];
  const jobsHash = jobsCollection.length ? updateURIComponents("#", {
    collection: jobsCollection
  }) : "";

  return (
    <>
      <Row className="mt-3">
        <Col className="d-flex align-items-baseline">
          <BreadCrumbs product={product} value={value} up={fragment.up || `/my/listings/${product?.id}`} />
          <div className="ml-auto">
            {showPrevNext &&
              <Button color="link" disabled={!showPrev} onClick={navigate(`/my/listings/${product?.id}/candidates/${prevId}${hash}`)}>
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
            }
            {product && false && <Button color="primary" onClick={navigate(`/my/listings/${product?.id}`)}>Compare</Button>}
            {showPrevNext && <span>{selfIndex + 1} of {collection.length}{fragment.after && showNext ? "+" : ""}</span>}
            {showPrevNext &&
              <Button color="link" disabled={!showNext} onClick={navigateNext}>
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <OrderCard value={value} product={product} onUpdate={updateOrder} onUpdateProduct={updateProduct} />
        </Col>
      </Row>
      {hasReviews &&
        <Row className="mt-3">
          <CardColumns md={2} xl={3}>
            {value.customer.reviews.map((_, i) => <ReviewCard key={i} objectId={value.id} objectKind={ResourceKind.Order} title={value.details.customerName || value.customer.name} value={_} onUpdate={updateCustomerReview} showAttribution showStatus showMenu showButtons showDiscussion showInput showAttachments openDiscussion={_.comments && _.comments.length > 0} />)}
          </CardColumns>
        </Row>
      }
      {showJobs && hasJobs &&
        <>
          <Row className="mt-3">
            <CardColumns md={2} xl={3}>
              {value.jobs.map((_, i) =>
                <JobCard key={i} href={window.location.pathname + `/tasks/${_.id}${jobsHash}`} value={_} onUpdate={updateJob} showStatus colorRating>
                  <CardButtons className="mt-3">
                    <CardButton onClick={navigate(window.location.pathname + `/tasks/${_.id}${jobsHash}`)}>View</CardButton>
                  </CardButtons>
                </JobCard>
              )}
              {showOtherJobs && value.customer.jobs.filter((_, i) => (i + value.jobs.length) < vars.first).map((_, i) =>
                <JobCard key={i} href={window.location.pathname + `/tasks/${_.id}${jobsHash}`} value={_} onUpdate={updateCustomerJob} showStatus colorRating>
                  <CardButtons className="mt-3">
                    <CardButton onClick={navigate(window.location.pathname + `/tasks/${_.id}${jobsHash}`)}>View</CardButton>
                  </CardButtons>
                </JobCard>
              )}
            </CardColumns>
          </Row>
          <Row>
            <Col>
              <LoadingButton loading={isLoading} count={value.jobs.length + value.customer.jobs.length} limit={vars.first} onClick={getMore} />
            </Col>
          </Row>
        </>
      }
      {hasResume &&
        <Row className="mt-3">
          <Col>
            <ResumeCard value={resume} product={product} onUpdate={updateResume} />
          </Col>
        </Row>
      }
      {hasActivity &&
        <Row className="mt-3">
          <Col>
            <ActivityCard values={value.events} />
          </Col>
        </Row>
      }
    </>
  )
}

