import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { CardAttributes, CardBodyAttributes, CardButtonsAttributes } from "../components/Card";
import CardDiscussionListGroup, { Fragment as DiscussionFragment, ReviewCriteria } from "../components/CardDiscussionListGroup";
import { CardButton, CardButtons, CardDiscussionSummaryStatusItems, CardStatus } from "../components/CardStatus";
import ContentPreviewDiv, { canPreview, Fragment as ContentPreviewFragment } from "../components/ContentPreviewDiv";
import { useCardState } from "../hooks/CardStateHook";
import { useNavigation } from "../hooks/NavigationHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { Size, sizeToMinWidth, useWindowState } from "../hooks/WindowStateProvider";
import { AttachmentModel, ReviewOption } from "../types/api-graph-types";
import AttachmentInputModal, { Fragment as AttachmentInputFragment } from "../modals/AttachmentInputModal";
import { useMutation, setDelete } from "../hooks/ApiProvider";
import { useConfirmation } from "../hooks/ConfirmationProvider";

export const Fragment = `
  id
  kind
  type
  filename
  length
  ${ContentPreviewFragment}
  ${DiscussionFragment}
  ${AttachmentInputFragment}
  owner { name }
  actions { download change remove }
`

const _removeAttachment = `mutation removeAttachment($id:ID) {
  attachment(id:$id) {
    remove
    commit { ${Fragment} }
  }
}`

interface OperationResults {
  attachment: {
    commit: AttachmentModel
  }
}

export const _criteria: ReviewCriteria[] = [
  [1, 2, ReviewOption.Spam, -1, ""],
  [1, 2, ReviewOption.Offensive, -1, ""],
  [1, 2, ReviewOption.LanguagePoor, -1, "Contains excessive spelling, grammar, or formatting errors."],
  [3, 4, ReviewOption.Null, 0, "Contact information is missing email or phone."],
  [3, 4, ReviewOption.Null, 0, "Education section is missing."],
  [3, 4, ReviewOption.Null, 0, "Work experience section is missing."],
  [5, 6, ReviewOption.Null, 0, "Education section is missing either full institution name without abbreviations, or dates or duration of study."],
  [5, 6, ReviewOption.Null, 0, "Education section is missing either percentage score, cumulative GPA including maximum attainable, or percentile or rank including class size."],
  [5, 6, ReviewOption.Null, 0, "Work experience section is missing either full organization name without abbreviations, position or role at the organization, dates or duration of employment, or location of the workplace."],
  [5, 6, ReviewOption.Null, 0, "Work experience section is missing details like key achievements or responsibilities."],
  [5, 6, ReviewOption.Null, 0, "Work experience section is not listed in most recent to oldest order."],
  [5, 6, ReviewOption.Null, 0, "Work experience section contains excessive details."],
  [5, 6, ReviewOption.Null, 0, "Résumé is too long exceeding two pages."],
  [7, 8, ReviewOption.AttachmentResumeContactEmailPoor, 0, "Email address is unprofessional."],
  [7, 8, ReviewOption.Null, 0, "Objective section is too long or too general."],
  [7, 8, ReviewOption.Null, 0, "Contains information not relevant for employment such as personal hobbies, date of birth, or marital status."],
  [7, 8, ReviewOption.Null, 0, "Skills section is missing or does not include language proficiency level."],
  [7, 8, ReviewOption.Null, 0, "References section is missing or does not include email address of references."],
  [7, 8, ReviewOption.Null, 0, "Declaration section is unnecessary."],
  [9, 10, ReviewOption.Null, +1, "Excellent job limiting to one page while including all important details!"],
  [9, 10, ReviewOption.Null, +1, "Concise and specific work experience details."],
];

function Status({ value, toggleDiscussion }: {
  value: AttachmentModel,
  toggleDiscussion?: () => void
}) {
  return (
    <CardStatus>
      <CardDiscussionSummaryStatusItems reviews={value.reviewsSummary} comments={value.commentsSummary} toggleDiscussion={toggleDiscussion} />
    </CardStatus>
  );
}

export function Buttons({ value, onUpdate, toggleInput }: CardButtonsAttributes<AttachmentModel>) {
  const [navigate] = useNavigation();
  const confirm = useConfirmation();
  const { id, actions } = value;
  const [mutation] = useMutation<OperationResults>();
  const removeAttachment = async () => {
    const result = await mutation(_removeAttachment, { id })
    onUpdate?.({ id, ...setDelete(result.attachment.commit) });
  }
  return (
    <CardButtons className="mt-3">
      {actions.change && toggleInput && <CardButton icon="pencil-alt" onClick={toggleInput}>Edit</CardButton>}
      {actions.download && <CardButton icon="download" onClick={navigate(value.url, true)}>Download</CardButton>}
      {actions.remove && <CardButton icon="archive" onClick={confirm(removeAttachment, "Are you sure you want to archive this document?")}>Archive</CardButton>}
    </CardButtons>
  );
}

export function Body({ value, onUpdate, href, onClick, toggleInput, showStatus, showButtons, toggleDiscussion, children }: CardBodyAttributes<AttachmentModel>) {
  const [navigate] = useNavigation();
  const handleClick = onClick || navigate(href) || toggleInput || undefined;
  return (
    <>
      <Col>
        {canPreview(value) && <ContentPreviewDiv value={value} pageClassName="bih-1" />}
      </Col>
      <CardBody>
        {children}
        {showStatus && <Status {...{ value, toggleDiscussion }} />}
        {showButtons && <Buttons {...{ value, onUpdate, toggleInput }} />}
      </CardBody>
    </>
  );
}

export default ({ value, sideDiscussion: _sideDiscussion, privateDiscussion, href, onClick, showInput, publicDiscussion, onUpdate, showHeader, showStatus, showButtons, showDiscussion, showReview, openDiscussion, openReview, appendDiscussionCriteria, children }: CardAttributes<AttachmentModel> & {
  sideDiscussion?: Size,
  appendDiscussionCriteria?: ReviewCriteria[]
}) => {
  const [size] = useWindowState();
  const [t] = useTranslation();
  const { isOpenInput, toggleInput, isOpenDiscussion, toggleDiscussion } = useCardState({ showInput, showDiscussion, openDiscussion })
  const sideDiscussion = _sideDiscussion && sizeToMinWidth(size) >= sizeToMinWidth(_sideDiscussion);
  const layoutKey = `l-${sideDiscussion ? "1" : "0"}`; // Force re-render
  const criteria = [...appendDiscussionCriteria || [], ..._criteria];
  return (
    <Card>
      <Row key={layoutKey} noGutters>
        <Col xs={sideDiscussion ? 8 : undefined}>
          {showHeader && <CardHeader className="bg-white" tag="h3">{t(value.type)}</CardHeader>}
          <Body value={value} {...{ href, onUpdate, onClick, toggleInput, showStatus, showButtons, toggleDiscussion }} >
            {children}
          </Body>
          {showDiscussion && !sideDiscussion && <CardDiscussionListGroup value={value} onUpdate={onUpdate} helpPrivate={`Only ${value.owner?.name || "document owner"}`} helpPublic="Anyone on HigherKnowledge" visibilityPrivate={privateDiscussion} forcePublic={publicDiscussion} criteria={criteria} showReview={showReview} openReview={openReview} isOpen={isOpenDiscussion} />}
        </Col>
        {showDiscussion && sideDiscussion &&
          <Col xs={4}>
            <CardDiscussionListGroup right value={value} onUpdate={onUpdate} helpPrivate={`Only ${value.owner?.name || "document owner"}`} helpPublic="Anyone on HigherKnowledge" visibilityPrivate={privateDiscussion} forcePublic={publicDiscussion} criteria={criteria} showReview={showReview} openReview={openReview} isOpen={isOpenDiscussion} />
          </Col>
        }
        {showInput && <AttachmentInputModal value={value} onUpdate={onUpdate} isOpen={isOpenInput} toggle={toggleInput} />}
      </Row>
    </Card>
  );
}
