import React, { createContext, ReactNode, useContext, useState, useEffect } from "react";
import { ClaimsModel } from "../types/api-graph-types";

type StorageType = "SESSION" | "COOKIE" | "LOCAL";
type ContextType = [ClaimsModel | undefined, Set<string> | undefined, Date | undefined, string | undefined, (access_token: string, expires_in?: string, refresh_token?: string, storage?: StorageType) => void];
const initialContext: any = undefined;
const Context = createContext<ContextType>(initialContext);

export function useClaims(): [ClaimsModel | undefined, Set<string> | undefined] {
  const [claims, scopes] = useContext(Context);
  return [claims, scopes];
}

export function useAuthorizationToken(): [string | undefined, (access_token: string, expires_in?: string, refresh_token?: string, storage?: StorageType) => void, Date | undefined] {
  const [, , refreshBy, accessToken, setToken] = useContext(Context);
  return [accessToken, setToken, refreshBy];
}

export const Scopes = {
  ProductEnumerateAll: "Product.Enumerate.All",
  ProductEnumeratePublic: "Product.Enumerate.Public",
  OrderEnumerate: "Order.Enumerate",
  JobEnumerate: "Job.Enumerate",
  ProductEnumerate: "Product.Enumerate",
  WorkItemEnumerate: "WorkItem.Enumerate",
  JobReviewAll: "Job.Review.All",
  PersonEnumerateAll: "Person.Enumerate.All",
  OrganizationEnumerateAll: "Organization.Enumerate.All",
  SiteAdmin: "Site.Admin"
};

function getClaims(accessToken: string): ClaimsModel {
  return accessToken && accessToken.includes(".ey") && JSON.parse(window.atob(accessToken.split(".")[1].replace("-", "+").replace("_", "/"))) || null;
}

function getAccessTokenLifetime(token: string): string | null {
  const exp = getClaims(token)?.exp;
  const now = new Date().getTime() / 1000;
  return exp && `${exp - now}` || null;
}

function setCookie(name: string, value: string, maxAge: string) {
  document.cookie = `${name}=${value};max-age=${maxAge};path=/;secure;samesite`
}

function getCookie(name: string) {
  const key = `${name}=`;
  return document.cookie.split(';').filter(_ => _.indexOf(key) === 0).map(_ => _.substring(key.length))[0];
}

export function getRefreshToken(accessToken: string) {
  const localToken = window.localStorage.getItem("access_token");
  const sessionToken = window.sessionStorage.getItem("access_token");
  return localToken === accessToken ? window.localStorage.getItem("refresh_token") || undefined
    : sessionToken === accessToken ? window.sessionStorage.getItem("refresh_token") || undefined
      : undefined;
}

export default (props: { children?: ReactNode }) => {
  const sessionToken = window.sessionStorage.getItem("access_token");
  const cookieToken = getCookie("access_token");
  const localToken = window.localStorage.getItem("access_token");
  const initialToken = sessionToken || cookieToken || localToken || undefined;
  const [token, setToken] = useState(initialToken);
  useEffect(() => {
    const updateToken = (ev: StorageEvent) => {
      if (ev.key === "access_token" && ev.oldValue === token && ev.newValue !== token) {
        setToken(ev.newValue || "");
      }
    }
    window.addEventListener("storage", updateToken);
    return () => window.removeEventListener("storage", updateToken);
  })
  const persistToken = (accessToken: string, expiresIn?: string, refreshToken?: string, storage?: StorageType) => {
    setToken(accessToken);
    if (!accessToken || !expiresIn) {
      clearToken(storage);
    } else if (storage === "SESSION") {
      window.sessionStorage.setItem("access_token", accessToken);
      refreshToken && window.sessionStorage.setItem("refresh_token", refreshToken);
    } else if (storage === "COOKIE") {
      setCookie("access_token", accessToken, expiresIn ?? getAccessTokenLifetime(accessToken) ?? "0");
    } else { // storage === LOCAL or not specified
      window.localStorage.setItem("access_token", accessToken);
      refreshToken && window.localStorage.setItem("refresh_token", refreshToken);
    }
  }
  const clearToken = (storage?: StorageType) => {
    if (storage === "LOCAL" || token === localToken) {
      window.localStorage.removeItem("refresh_token");
      window.localStorage.removeItem("access_token");
    }
    if (storage === "SESSION" || token === sessionToken) {
      window.sessionStorage.removeItem("refresh_token");
      window.sessionStorage.removeItem("access_token");
    }
    if (storage === "COOKIE" || token === cookieToken) {
      setCookie("access_token", "", "0");
    }
  }
  const claims = token && getClaims(token) || undefined;
  const scopes = claims && new Set(claims.scope?.split(" ")) || undefined;
  const refreshBy = claims?.exp ? new Date((claims.exp - 120) * 1000) : undefined;
  const value: ContextType = [claims, scopes, refreshBy, token, persistToken];
  return (
    <Context.Provider key={claims?.sub} value={value}>
      {props.children}
    </Context.Provider>
  );
}
