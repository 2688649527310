import { library } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigation } from "../hooks/NavigationHook";

library.add(faExternalLinkAlt);

export function getHostname(url: string) {
  const a = document.createElement("a");
  a.href = url;
  const hostname = a.hostname;
  return hostname.startsWith("www.") ? hostname.substr(4) : hostname;
};

export default ({ href, title, className }: { href: string, title?: string, className?: string }) => {
  const [navigate] = useNavigation();
  return href && (
    <a target='_blank' rel="noopener noreferrer" className={className} title={href} href={href} onClick={navigate(href)}>
      {title || getHostname(href)}
      <FontAwesomeIcon size='sm' className='ml-1' icon={["fas", "external-link-alt"]} />
    </a>
  ) || null;
}
