import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { nextNonce } from "../hooks/ApiProvider";
import { useAppInsights } from "../hooks/AppInsightsProvider";
import { useClaims } from "../hooks/TokenProvider";

export default () => {
  const [claims] = useClaims();
  const insights = useAppInsights();
  useEffect(() => {
    insights.trackDependencyData({
      id: `|${insights.context.telemetryTrace.traceID}.${nextNonce()}`,
      type: "Render",
      name: window.location.href,
      success: false,
      responseCode: 404,
      properties: {
        AuthScope: claims?.scope
      }
    })
  }, [window.location.pathname]);
  return (
    <Row>
      <Col>
        <h1>Not Found</h1>
        <p>Oops! We could not find the page you were looking for. It either does not exist, or you do not have sufficient access.</p>
      </Col>
    </Row>
  );
}
