import React, { useEffect, useState, useRef } from "react";
import { Progress } from "reactstrap";
import { useInterval } from "../hooks/CommonHooks";

function Spacer({ className = "", flush }: {
  flush?: boolean,
  className?: string
}) {
  const classNames = [
    "progress-bar-thin",
    className,
    flush ? "progress-bar-flush" : ""
  ].join(" ");
  return <div className={classNames} />
}

function Bar({ className = "", flush }: {
  className?: string,
  flush?: boolean
}) {
  const [value, setValue] = useState(0);
  useInterval(() => setValue(value + (100 - value) / 2), 300);
  const classNames = [
    "progress-bar-thin",
    className,
    flush ? "progress-bar-flush" : ""
  ].join(" ");
  return <Progress className={classNames} value={value} />;
}

export default ({ show, className = "", flush }: {
  show?: boolean,
  className?: string,
  flush?: boolean
}) => {
  return show
    ? <Bar className={className} flush={flush} />
    : <Spacer className={className} flush={flush} />;
}
