import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row, Carousel, CarouselItem, CarouselIndicators, CarouselCaption } from "reactstrap";
import { useNavigation } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import Image from '../images/for-colleges-2.svg';
import { useApiEndpoint, Route } from "../hooks/ApiProvider";

function HeroRow() {
  const [navigate] = useNavigation();
  return (
    <Row>
      <Card className="w-100 b-0 text-white" color="dark">
        <CardBody>
          <Row className="align-items-center">
            <Col sm={8} md={7} lg={8}>
              <h1>HigherKnowledge for your placements</h1>
              <h3>We help your students find the best opportunities.</h3>
              <div className="mt-3 d-flex">
                <Button className="mr-2" color="primary" onClick={navigate("/sign-up?scope=claim:MELLON")}>
                  <h4 className="mb-0">Start for free</h4>
                </Button>
                <Button className="mr-2" color="light" outline onClick={navigate("/sign-in")}>
                  <h4 className="mb-0">Sign in</h4>
                </Button>
              </div>
            </Col>
            <Col className="d-none d-sm-block text-center" sm={4} md={5} lg={4}>
              <img width="70%" src={Image} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
}

function FeaturesRow() {
  return (
    <Row style={{ marginTop: "5em" }}>
      <Col md={4} className="mt-3">
        <FontAwesomeIcon size="2x" icon="circle" />
        <h3>Duis a vulputate metus.</h3>
        <div className="text-justify">
          Fusce sagittis sed tortor quis ornare.Suspendisse at erat placerat, auctor nibh a, imperdiet felis.Phasellus tellus turpis, dignissim vel pretium nec, lobortis eu dolor.
        </div>
      </Col>
      <Col md={4} className="mt-3">
        <FontAwesomeIcon size="2x" icon="circle" />
        <h3>Duis a vulputate metus.</h3>
        <div className="text-justify">
          Fusce sagittis sed tortor quis ornare.Suspendisse at erat placerat, auctor nibh a, imperdiet felis.Phasellus tellus turpis, dignissim vel pretium nec, lobortis eu dolor.
        </div>
      </Col>
      <Col md={4} className="mt-3">
        <FontAwesomeIcon size="2x" icon="circle" />
        <h3>Duis a vulputate metus.</h3>
        <div className="text-justify">
          Fusce sagittis sed tortor quis ornare.Suspendisse at erat placerat, auctor nibh a, imperdiet felis.Phasellus tellus turpis, dignissim vel pretium nec, lobortis eu dolor.
          </div>
      </Col>
    </Row>
  );
}

function BrandsRow() {
  const [logoRoute] = useApiEndpoint(Route.OrganizationLogo);
  const items = [
    { id: "f421c7e1-230d-4b2b-b90d-b26a63113200", title: "J. Sagar Associates" },
    { id: "16e50e62-b2f3-4774-8495-182acd4721a6", title: "Hidayatullah National Law University" },
    { id: "db96ddf8-8075-4132-a286-2c67dda9f476", title: "National University of Advanced Legal Studies (NUALS)" },
    { id: "ef941590-30d2-4fe7-b803-9136a9accc7b", title: "Bharucha & Partners" },
    { id: "ed43dbbd-60f9-4e3d-8f5b-fa1171f4a0fb", title: "Maharashtra National Law University, Aurangabad (MNLU-A)" },
    { id: "433cdacb-e296-497d-95a6-a87e1770928d", title: "Alliance University, School of Law" },
    { id: "93eb6659-6023-4a18-8e54-5b8e77bfd521", title: "Phoenix Legal" },
    { id: "d6403c0b-0788-4043-b35e-725e5ef39939", title: "Dr. Ram Manohar Lohia National Law University" },
    { id: "012c4e60-dcfe-41d5-b240-e26da1811696", title: "K.L.E. Society's Law College" },
    { id: "57effae3-af15-4d36-9ce0-a0f4d204a2e1", title: "Trilegal" },
    { id: "1b8036f2-31c0-4f23-942b-64227db0f509", title: "Symbiosis Law School, Pune" },
    { id: "5a0b4e43-c452-4f99-8311-c19087e254a3", title: "Singh & Associates" },
  ]
  return (
    <Row className="bg-white" style={{ marginTop: "5em" }}>
      {items.map((_, i) =>
        <Col key={i} md={1} className="p-2">
          <img src={logoRoute.replace('{id}', _.id)} title={_.title} width={48} height={48} className="mr-2 align-baseline" />
        </Col>
      )}
    </Row>
  );
}

function TestimonialsRow() {
  const [index, setIndex] = useState(0);
  const items = [
    { quote: "Aliquam ex lorem, blandit nec dapibus sed, rutrum sit amet ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Lorem ipsum dolor sit amet, consectetur.", author: "Moni S." },
    { quote: "Curabitur lobortis lobortis eros vestibulum sagittis. Ut gravida, est eu imperdiet lobortis, nisi orci efficitur lacus, sed luctus magna sapien at magna. Nullam lacinia sit amet enim pharetra malesuada. In.", author: "Moni S." },
    { quote: "Vivamus ultricies at tortor ut pretium. Donec sed auctor lectus. Vestibulum tincidunt auctor lectus, eu finibus libero. Nulla ultrices quis ligula non convallis. Duis a gravida ligula. Donec eleifend vehicula.", author: "Moni S." }
  ];
  const next = () => setIndex((index + 1) % items.length);
  const prev = () => setIndex((index - 1) % items.length);
  return (
    <Row style={{ marginTop: "5em" }}>
      <Col md={6} className="mx-auto">
        <Carousel activeIndex={index} next={next} previous={prev} interval={false}>
          <CarouselIndicators className="carousel-indicators-dark" items={items} activeIndex={index} onClickHandler={setIndex} />
          {items.map((_, i) =>
            <CarouselItem key={i}>
              <div className="d-flex" style={{ minHeight: "9em" }}>
                <FontAwesomeIcon className="mr-2" size="2x" icon="circle" />
                <div className="d-flex flex-column">
                  <blockquote className="text-justify">{_.quote}</blockquote>
                  <span className="ml-auto">&mdash; {_.author}</span>
                </div>
              </div>
            </CarouselItem>
          )}
        </Carousel>
      </Col>
    </Row>
  );
}

function PricingRow() {
  return (
    <Row style={{ marginTop: "5em" }}>
      <Col md={4} className="ml-auto mt-3">
        <h3>Essentials</h3>
        <h5>Free! <del className="small text-muted">₹99</del></h5>
        <h6 className="text-muted">INR / student</h6>
        <Button color="primary">Start for free</Button>
        <ul className="mt-2">
          <li>Placement workflow management</li>
          <li>Email and WhatsApp integration</li>
          <li>Résumé maker for students</li>
          <li>Professional résumé review <small className="text-muted">extra charge</small></li>
          <li>Online assessments <small className="text-muted">extra charge</small></li>
          <li>Standard email support</li>
        </ul>
      </Col>
      <Col md={4} className="mr-auto mt-3">
        <h3>Professional</h3>
        <h5>₹4900 <del className="small text-muted">₹9900</del></h5>
        <h6 className="text-muted">INR / month</h6>
        <Button outline>Start free trial</Button>
        <ul className="mt-2">
          <li>… everything in Essentials plus</li>
          <li>Professional résumé review <small className="text-muted">free!</small></li>
          <li>Early notification of opportunities</li>
          <li>Referrals to partner workplaces</li>
          <li>Advanced reporting</li>
          <li>Standard phone support</li>
        </ul>
      </Col>
    </Row>
  );
}

export default () => {
  useTitle("HigherKnowledge for Colleges");
  return (
    <>
      <HeroRow />
      <FeaturesRow />
      <BrandsRow />
      <TestimonialsRow />
      <PricingRow />
    </>
  );
}
