import { library } from "@fortawesome/fontawesome-svg-core";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faArchive, faInbox, faPlay } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import React from "react";
import { Alert, CardBody, CardBodyProps, CardHeader, CardSubtitle } from "reactstrap";
import LegalResearchText, { Fragment as LegalResearchFragment, isLegalResearch } from "../cards/Legal/LegalResearchText";
import Card, { CardAttributes, CardBodyAttributes, CardButtonsAttributes } from "../components/Card";
import { CardButton, CardButtons, CardRatingStatusItem, CardStatus } from "../components/CardStatus";
import { useMutation } from "../hooks/ApiProvider";
import { useCardState } from "../hooks/CardStateHook";
import { preventDefault, useNavigation } from "../hooks/NavigationHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { JobModel, ResourceState } from "../types/api-graph-types";

library.add(faArchive, faPlay, faCommentAlt, faInbox)

export const Fragment = `
  id
  kind
  state
  expiresOn
  workitem {
    details {
      type
      description
    }
  }
  tasks { 
    id
    kind
    type
    details {
      ${LegalResearchFragment.task}
    }
  }
  order { id kind }
  ratingAverage
  actions {
    start
    continue
    submit
    discuss
    review
    remove
    undo
  }
  policy { role }
`;

const _startJob = `mutation startJob($id:ID!) {
  job(id:$id) { start commit { ${Fragment} } }
}`;

const _continueJob = `mutation continueJob($id:ID!) {
  job(id:$id) { continue commit { ${Fragment} } }
}`;

const _removeJob = `mutation removeJob($id:ID!) {
  job(id:$id) { remove commit { ${Fragment} } }
}`;

const _skipJob = `mutation skipJob($id:ID! $oid:ID!) {
  job(id:$id) { remove commit { ${Fragment} } }
  order(id:$oid) { job: addJob { commit { id } } }
}`;

const _undoJob = `mutation undoJob($id:ID!) {
  job(id:$id) { undo commit { ${Fragment} } }
}`;

interface OperationResults {
  job: { commit: JobModel }
  order: { job: { commit: JobModel } }
};

function Text({ value }: { value: JobModel }) {
  const [t] = useTranslation();
  return (
    <>
      {value.workitem && value.workitem.details && <CardSubtitle tag='h5'>{t(value.workitem.details.type)}</CardSubtitle>}
      {isLegalResearch(value) && <LegalResearchText value={value} />}
    </>
  );
}

function Notice({ value, className, onUpdate }: {
  value: JobModel,
  className?: string,
  onUpdate?: (value: JobModel) => void
}) {
  const [, go] = useNavigation();
  const { id, actions } = value;
  const [mutation] = useMutation<OperationResults>();
  const removeJob = async () => {
    const result = await mutation(_removeJob, { id })
    onUpdate?.(result.job.commit);
  }
  const skipJob = async () => {
    const result = await mutation(_skipJob, { id, oid: value.order.id })
    onUpdate?.(result.job.commit);
    result.order.job.commit.id && go(`/my/tasks/${result.order.job.commit.id}`)
  }
  return value.state === ResourceState.Completed && value.ratingAverage >= 7 ? (
    <Alert className={className} color="success">
      <h5>Approved</h5>
      <p>Awesome! Your submission has been approved.</p>
    </Alert>
  ) : value.state === ResourceState.Completed && value.ratingAverage && value.ratingAverage < 7 ? (
    <Alert className={className} color="warning">
      <h5>Needs Improvement</h5>
      <p>There are some aspects to your submission which could be improved. See detailed comments on how to improve your next submission.</p>
    </Alert>
  ) : (value.state === ResourceState.Proposed || value.state === ResourceState.InProgress) && (value.expiresOn && moment(value.expiresOn).isBefore()) ? (
    <Alert className={className} color="warning">
      <h5>Expired</h5>
      <p>The time limit for completing this assessment has expired. {actions.remove && <a className="ml-1" href="#" onClick={preventDefault(removeJob)}>Archive</a>}</p>
    </Alert>
  ) : value.state === ResourceState.Resolved && value.actions.discuss ? (
    <Alert className={className} color="primary">
      <h5>Under Review</h5>
      <p>Your submission is being reviewed. In the meantime, you may discuss your submission with others.</p>
    </Alert>
  ) : (value.state === ResourceState.Proposed || value.state === ResourceState.InProgress) && actions.remove ? (
    <Alert className={className} color="light">
      <h5>Skip?</h5>
      <p>You can still complete this assessment. If you want, however, you may skip to the next assessment. <a className="ml-1" href="#" onClick={preventDefault(skipJob)}>Skip</a></p>
    </Alert>
  ) : null;
}

export function Status({ value, colorRating }: {
  value: JobModel,
  colorRating?: boolean
}) {
  return (
    <CardStatus>
      <CardRatingStatusItem value={value.ratingAverage} color={colorRating} />
    </CardStatus>
  );
}

function Buttons({ value, onUpdate }: CardButtonsAttributes<JobModel>) {
  const [navigate, go] = useNavigation();
  const { id, actions } = value;
  const [mutation] = useMutation<OperationResults>();
  const startJob = async () => {
    const result = await mutation(_startJob, { id })
    onUpdate?.(result.job.commit);
    go(`/my/tasks/${value.id}`);
  }
  const continueJob = async () => {
    const result = await mutation(_continueJob, { id })
    onUpdate?.(result.job.commit);
    go(`/my/tasks/${value.id}`);
  }
  const removeJob = async () => {
    const result = await mutation(_removeJob, { id })
    onUpdate?.(result.job.commit);
  }
  const undoJob = async () => {
    const result = await mutation(_undoJob, { id })
    onUpdate?.(result.job.commit);
  }
  return (
    <CardButtons className="mt-3">
      {actions.start && <CardButton icon="play" onClick={startJob}>Start</CardButton>}
      {actions.continue && !actions.start && <CardButton icon="play" onClick={continueJob}>Continue</CardButton>}
      {actions.discuss && <CardButton icon={["far", "comment-alt"]} onClick={navigate(`/my/tasks/${value.id}`)}>Discuss</CardButton>}
      {actions.review && <CardButton icon={["far", "comment-alt"]} onClick={navigate(`/my/reviews/${value.id}`)}>Review</CardButton>}
      {actions.remove && <CardButton icon="archive" onClick={removeJob}>Archive</CardButton>}
      {actions.undo && value.state === ResourceState.Removed && <CardButton icon="inbox" onClick={undoJob}>Unarchive</CardButton>}
    </CardButtons>
  );
}

function Body({ value, href, onUpdate, onClick, className, showNotice, showStatus, colorRating, showMenu, showButtons, children, ...attrs }: & CardBodyAttributes<JobModel> & CardBodyProps & {
  colorRating?: boolean
}) {
  const [navigate] = useNavigation();
  const hasStatus = !!value.ratingAverage;
  return (
    <CardBody className={`hover-container ${className}`} onClick={onClick || navigate(href)} {...attrs}>
      <Text value={value} />
      {showNotice && <Notice className="mt-3" value={value} onUpdate={onUpdate} />}
      {showStatus && hasStatus && <Status value={value} colorRating={colorRating} />}
      {showButtons && <Buttons {...{ value, onUpdate }} />}
      {children}
    </CardBody>
  );
}

export default ({ value, href, className, onClick, onUpdate, hideRemoved, showHeader, showNotice, showStatus, colorRating, showMenu, showButtons, showInput, showDiscussion, showShare, openInput, openDiscussion, openShare, children }: CardAttributes<JobModel> & {
  colorRating?: boolean
}) => {
  const [t] = useTranslation();
  const { } = useCardState({ showDiscussion, showInput, showShare, openDiscussion, openInput, openShare });
  const classNames = [
    className,
    (href || onClick) ? "hover-shadow" : "",
    value.state === ResourceState.Removed && hideRemoved ? "fade-out" : ""
  ].join(" ");
  return (
    <Card className={classNames}>
      {showHeader && <CardHeader className="bg-white" tag="h3">{t(value.workitem.details.type)}</CardHeader>}
      <Body className={(href || onClick) ? "cursor-pointer" : ""} {...{ value, href, onUpdate, onClick, showNotice, showStatus, colorRating, showMenu, showButtons }} >
        {children}
      </Body>
    </Card>
  );
}
