import React, { MouseEvent, useState, lazy, Suspense, useEffect } from "react";
import { Col, Nav, NavItem, NavLink, Row, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { CustomDropdown, CustomDropdownItem } from "../components/CustomDropdown";
import CardColumns from "../components/CardColumns";
import TaskCard, { Fragment as TaskFragment } from "../cards/TaskCard";
import WorkItemCard, { Fragment as WorkItemFragment } from "../cards/WorkItemCard";
import { Fragment as WorkItemInputFragment } from "../modals/WorkItemInputModal";
import { useQuery } from "../hooks/ApiProvider";
import { useTitle } from "../hooks/TitleHook";
import { useTranslation } from "../hooks/TranslationProvider";
import { TaskType, WorkItemModel, TaskModel } from "../types/api-graph-types";
import { useFragment, preventDefault } from "../hooks/NavigationHook";
import { useSearch } from "../hooks/SearchProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingController from "./LoadingController";

const WorkItemDocument = lazy(() => import("../documents/WorkItemDocument"));

const _getWorkItem = `query getWorkItem($id:ID $first:Int $query:String $ratingAverage:Int) {
  workitem(id:$id) {
    ${WorkItemFragment}
    ${WorkItemInputFragment}
    tasks(states:[RESOLVED,COMPLETED] query:$query first:$first ratingAverage:$ratingAverage) {
      ${TaskFragment}
    }
  }
}`;

interface Result {
  workitem: WorkItemModel
}

enum View {
  Issues = "issues",
  Backgrounds = "backgrounds",
  Statutes = "statutes",
  Precedents = "precedents",
  Conclusions = "conclusions",
}

const _types = new Map<TaskType, [number, View]>([
  [TaskType.LegalResearchRephrasing, [0, View.Issues]],
  [TaskType.LegalResearchBackground, [1, View.Backgrounds]],
  [TaskType.LegalResearchStatute, [2, View.Statutes]],
  [TaskType.LegalResearchPrecedent, [3, View.Precedents]],
  [TaskType.LegalResearchConclusion, [4, View.Conclusions]],
]);
const _typeCompare = (a: TaskType, b: TaskType) => (_types.get(a) || [0])[0] - (_types.get(b) || [0])[0];
const _initialVars = { first: 24, ratingAverage: 0 };
//const _initialQueryString = {}

export default ({ id }: { id: string }) => {
  //const [queryString, setQueryString] = useQueryString(_initialQueryString);
  const [fragment, setFragment] = useFragment({
    show: View.Precedents
  });
  const [t] = useTranslation();
  const [isPdfRendering, setIsPdfRendering] = useState(false);
  const [search, setSearch] = useSearch();
  const [vars, setVars] = useState(_initialVars);
  const [selection, setSelection] = useState(new Set<string>());
  const [result, updateResult, isLoading] = useQuery<Result>(_getWorkItem, { id, query: search, ...vars });
  const value = result?.workitem;
  useTitle(value && t(value.details.type));
  useEffect(() => {
    setSelection(new Set(result && result.workitem.tasks.map(_ => _.id).filter(_ => selection.has(_))));
  }, [result]);
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  const show = fragment.show;
  const hasRatingFilter = vars.ratingAverage > 0;
  const taskTypes = Array.from(value.tasks.reduce((a, _) => a.add(_.type), new Set<TaskType>())).sort(_typeCompare);
  const getMore = (e: MouseEvent) => setVars({ ...vars, first: e.getModifierState("Shift") ? 1024 : vars.first * 2 });
  const getLabel = (type: TaskType) => (_types.get(type) || [undefined, View.Precedents])[1];
  const toggleSelection = (id: string) => () => {
    selection.has(id) ? selection.delete(id) : selection.add(id);
    setSelection(new Set(selection));
  }
  const clearFilters = () => {
    setSearch("");
    //setQueryString(_initialQueryString);
    setVars(_initialVars);
  }
  const updateTasks = (tasks: ReadonlyArray<TaskModel>) => updateResult({ workitem: { tasks } });
  const updateTask = (task: TaskModel) => updateTasks([task]);
  const update = (workitem: WorkItemModel) => updateResult({ workitem });

  return (
    <>
      <Row className="mt-3">
        <Col>
          <WorkItemCard value={value} onUpdate={update} showNotice showStatus showMenu showButtons showDiscussion showInput />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Nav>
            {taskTypes && taskTypes.map((_, i) => (
              <NavItem key={i}>
                <NavLink href="#" onClick={() => setFragment({ ...fragment, show: getLabel(_) })} active={show === getLabel(_)}>
                  {t(_)}
                </NavLink>
              </NavItem>
            ))}
            {hasRatingFilter &&
              <NavItem className='mr-1'>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="primary">
                    {vars.ratingAverage / 2}+ stars
                      </DropdownToggle>
                  <DropdownMenu>
                    <CustomDropdownItem checked={vars.ratingAverage === 9} onClick={() => setVars({ ...vars, ratingAverage: 9 })}>Excellent</CustomDropdownItem>
                    <CustomDropdownItem checked={vars.ratingAverage === 7} onClick={() => setVars({ ...vars, ratingAverage: 7 })}>Good</CustomDropdownItem>
                    <CustomDropdownItem checked={vars.ratingAverage === 0} onClick={() => setVars({ ...vars, ratingAverage: 0 })}>All</CustomDropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </NavItem>
            }
            <CustomDropdown className="ml-auto" label="Filters">
              <CustomDropdownItem header>Rating</CustomDropdownItem>
              <CustomDropdownItem checked={vars.ratingAverage === 9} onClick={() => setVars({ ...vars, ratingAverage: 9 })}>Excellent</CustomDropdownItem>
              <CustomDropdownItem checked={vars.ratingAverage === 7} onClick={() => setVars({ ...vars, ratingAverage: 7 })}>Good</CustomDropdownItem>
              <CustomDropdownItem checked={vars.ratingAverage === 0} onClick={() => setVars({ ...vars, ratingAverage: 0 })}>All</CustomDropdownItem>
            </CustomDropdown>
            <CustomDropdown label="Actions">
              <CustomDropdownItem onClick={() => setIsPdfRendering(!isPdfRendering)}>Export to PDF</CustomDropdownItem>
            </CustomDropdown>
          </Nav>
          {isPdfRendering &&
            <Suspense fallback={null}>
              <WorkItemDocument value={value} taskIds={selection} download onDownload={() => setIsPdfRendering(false)} />
            </Suspense>
          }
        </Col>
      </Row>
      <Row className="mt-3">
        <CardColumns md={2} xl={3}>
          {value.tasks.filter(_ => getLabel(_.type) === show).map((_, i) =>
            <TaskCard key={i} value={_} selected={selection.has(_.id)} onSelect={toggleSelection(_.id)} onUpdate={updateTask} showAttribution showAttachments showStatus showMenu showDiscussion openDiscussion />
          )}
        </CardColumns>
      </Row>
      <Row className="mt-3">
        <Col>
          {isLoading && <FontAwesomeIcon className="text-muted" icon="circle-notch" spin />}
          {!isLoading && !value.tasks.length && <span>No matching results. <a href="#" onClick={preventDefault(clearFilters)}>View all</a></span>}
          {!isLoading && value.tasks.length >= vars.first && <a href="#" onClick={preventDefault(getMore)}>See more</a>}
        </Col>
      </Row>
    </>
  )
};
