import React, { useState, useEffect } from "react";
import { Input, InputProps } from "reactstrap";
import { useValidation, trunc } from "./FormGroup";

const _notDigit = /[^0-9]/g;
const _notAlphaNumeric = /[^A-Za-z0-9]/g;

export default ({ value, delay, spellCheck, onUpdate, onChange, onBlur, onPaste, onKeyDown, ...attrs }: {
  delay?: number,
  onUpdate: (value: string) => void
} & InputProps) => {
  const v = useValidation();
  const initialValue = value !== undefined ? value : v.value;
  const [current, setCurrent] = useState(initialValue as string || "");
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    // Reset if value props changes.
    if (current != initialValue) {
      setCurrent(initialValue as string || "");
    }
  }, [initialValue])
  const update = (value: string, noDelay?: boolean) => {
    timer && window.clearTimeout(timer);
    if (onUpdate && value !== initialValue) {
      if (delay && !noDelay) {
        setTimer(window.setTimeout(() => onUpdate(value), delay));
      } else {
        onUpdate(value);
      }
    }
  }
  const validate = (value: string) => {
    var result = value;
    if (v) {
      result = v.maxLength ? trunc(value, v.maxLength) : result;
      result = v.number ? result.replace(_notDigit, "") : result;
      result = v.alphanumeric ? result.replace(_notAlphaNumeric, "") : result;
      result = v.lower ? result.toLowerCase() : result;
      result = v.invalidCharacters ? result.replace(v.invalidCharacters, "") : result;
    }
    return result;
  }
  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = validate(e.currentTarget.value);
    setCurrent(value);
    onChange?.(e);
    update(value);
  }
  const blur = (e: React.FocusEvent<HTMLInputElement>) => {
    update(validate(e.currentTarget.value), true);
    onBlur?.(e);
  }
  const paste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    onPaste?.(e);
    update(validate(e.currentTarget.value), true);
  }
  const keydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (e.keyCode === 13 /* Enter */) {
      update(validate(e.currentTarget.value), true);
    }
  }
  const doClear = (e: React.MouseEvent<HTMLInputElement>) => {
    setCurrent("");
    update("");
  }
  return <Input {...attrs} spellCheck={spellCheck} value={current} onChange={change} onBlur={blur} onPaste={paste} onKeyDown={keydown} />;
}
