import React from "react";
import { DropdownItem, ListGroup, CardHeader, UncontrolledDropdown, ListGroupItem, DropdownToggle, DropdownMenu, Card, Row, Col, Button } from "reactstrap";
import { LanguagePreferenceModel, LanguageCode, LanguagePreferenceInputModel, LanguagePreferenceOption, PersonModel } from "../../types/api-graph-types";
import { useTranslation } from "../../hooks/TranslationProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeepPartial, useMutation, setDelete } from "../../hooks/ApiProvider";
import { preventOverflow, CardStatus, CardBadge, CardBadges } from "../../components/CardStatus";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt, faStar } from "@fortawesome/free-regular-svg-icons";

library.add(faTrashAlt, faStar);

export const Fragment = `
  key
  value 
  options  
`;

const _updateMyLanguagePreference = `mutation updateMyLanguagePreference($key:String $value:LanguagePreferenceInputModel) {
  me {
    changeLanguagePreference(key:$key value:$value)
    commit { languagePreferences { ${Fragment} } }
  }
}`

const _removeMyLanguagePreference = `mutation removeMyLanguagePreference($key:String) {
  me {
    removeLanguagePreference(key:$key)
    commit { languagePreferences { ${Fragment} } }
  }
}`

interface OperationResults {
  me: {
    commit: PersonModel
  }
}

const { Speak, Fluent, Read, Write } = LanguagePreferenceOption;

export function ItemRow({ value, readonly, onUpdate }: {
  value: LanguagePreferenceModel,
  readonly?: boolean,
  onUpdate?: (value: DeepPartial<LanguagePreferenceModel>) => void
}) {
  const [t] = useTranslation();
  const options = value.options;
  const verbalLevel = options.includes(Fluent) ? Fluent : options.includes(Speak) ? Speak : "";
  const writtenLevel = options.includes(Write) ? Write : options.includes(Read) ? Read : "";
  const [mutation] = useMutation<OperationResults>();
  const remove = async () => {
    const result = await mutation(_removeMyLanguagePreference, { key: value.key });
    onUpdate?.(setDelete(value));
  }
  const setOptions = async (remove: LanguagePreferenceOption[], add: LanguagePreferenceOption[]) => {
    const result = await mutation(_updateMyLanguagePreference, {
      key: value.key,
      value: {
        value: value.value,
        options: [...options.filter(_ => !remove.includes(_)), ...add]
      }
    });
    onUpdate?.(result.me.commit.languagePreferences.filter(p => p.key === value.key)[0])
  };

  return (
    <ListGroupItem className='hover-container align-baseline'>
      <Row>
        <Col xs={3} className="text-nowrap">
          <FontAwesomeIcon fixedWidth size='lg' className='mr-1' icon={["fas", "language"]} />
          {t(value.value)}
        </Col>
        <Col xs={3} className="d-flex">
          {readonly && <small className="text-muted">{t(verbalLevel) || "Understand"}</small>}
          {!readonly &&
            <UncontrolledDropdown>
              <DropdownToggle color='white' className='p-0 btn-sm text-muted text-left hover-caret-visible' caret>
                {t(verbalLevel) || "Understand"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setOptions([Speak, Fluent], [])}>Understand</DropdownItem>
                <DropdownItem onClick={() => setOptions([Fluent], [Speak])}>{t(Speak)}</DropdownItem>
                <DropdownItem onClick={() => setOptions([], [Speak, Fluent])}>{t(Fluent)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        </Col>
        <Col xs={6} className="d-flex">
          {readonly && <small className="text-muted">{t(writtenLevel) || "Cannot read or write"}</small>}
          {!readonly &&
            <UncontrolledDropdown>
              <DropdownToggle color='white' className='p-0 btn-sm text-muted text-left hover-caret-visible' caret>
                {t(writtenLevel) || <span className="d-none d-sm-inline hover-visible">Cannot read or write</span>}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setOptions([Read, Write], [])}>Cannot read or write</DropdownItem>
                <DropdownItem onClick={() => setOptions([Write], [Read])}>{t(Read)}</DropdownItem>
                <DropdownItem onClick={() => setOptions([], [Read, Write])}>{t(Write)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
          {!readonly && <Button className="d-none d-sm-block ml-auto hover-visible" color="link" size="sm" onClick={remove}>Remove</Button>}
          {!readonly && <Button className="d-sm-none ml-auto text-muted" color="link" size="sm" onClick={remove}><FontAwesomeIcon icon={["far", "trash-alt"]} /></Button>}
        </Col>
      </Row>
    </ListGroupItem>
  );
}

export function Badges({ values, size }: {
  values: ReadonlyArray<LanguagePreferenceModel>,
  size?: string
}) {
  const [t] = useTranslation();
  return (
    <>
      {values.map((_, i) => <CardBadge key={i} value={t(_.value)} size={size}
        icon={_.options.includes(LanguagePreferenceOption.Fluent) ? ["far", "star"] : undefined}
        title={_.options.includes(LanguagePreferenceOption.Fluent) ? "Fluent" : undefined}
      />)}
    </>
  );
}

const _languages = [
  LanguageCode.Bn,
  LanguageCode.En,
  LanguageCode.Fr,
  LanguageCode.De,
  LanguageCode.Gu,
  LanguageCode.Hi,
  LanguageCode.Ja,
  LanguageCode.Kn,
  LanguageCode.Ml,
  LanguageCode.Mr,
  LanguageCode.Pa,
  LanguageCode.Es,
  LanguageCode.Ta,
  LanguageCode.Te,
  LanguageCode.Ur,
];

export default ({ className, values, onUpdate }: {
  className?: string,
  values: ReadonlyArray<LanguagePreferenceModel>,
  onUpdate: (values: DeepPartial<ReadonlyArray<LanguagePreferenceModel>>) => void,
}) => {
  const [t] = useTranslation();
  const addValues = _languages.filter(_ => values.findIndex(__ => __.value === _) === -1);
  const [mutation] = useMutation<OperationResults>();
  const update = (value: DeepPartial<LanguagePreferenceModel>) => onUpdate([value]);
  const addLanguage = async (value: LanguageCode) => {
    const result = await mutation(_updateMyLanguagePreference, { value: { value } as LanguagePreferenceInputModel });
    onUpdate?.(result.me.commit.languagePreferences);
  }
  return (
    <Card className={className}>
      <CardHeader className="bg-white d-flex align-baseline">
        <h3>Languages</h3>
        <UncontrolledDropdown className="ml-auto">
          <DropdownToggle color="link" caret>Add</DropdownToggle>
          <DropdownMenu modifiers={preventOverflow}>
            {addValues.map((_, i) =>
              <DropdownItem key={i} onClick={() => addLanguage(_)}>{t(_)}</DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      <ListGroup flush>
        {values.map((_, i) => <ItemRow key={i} value={_} onUpdate={update} />)}
        {values.length === 0 && <ListGroupItem className="text-muted">Use languages to indicate your proficiency in various languages.</ListGroupItem>}
      </ListGroup>
    </Card>
  );
}
