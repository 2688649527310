import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCalendarAlt, faFile, faFileArchive, faFileAudio, faFileCode, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord, faSave } from "@fortawesome/free-regular-svg-icons";
import { faLink, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardText, Col, Collapse, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, Modal, ModalBody, ModalFooter, Row, Tooltip, UncontrolledDropdown } from "reactstrap";
import { UserThumbnail } from "../cards/PersonCard";
import { CardButton, CardButtons } from "../components/CardStatus";
import { CustomDropdownItem } from "../components/CustomDropdown";
import FormGroup, { isEmail, isPhone } from "../components/FormGroup";
import { ApiErrors, nextNonce, nextSequence, useMutation, useReadyState } from "../hooks/ApiProvider";
import { useClipboard } from "../hooks/ClipboardHook";
import { useToggle } from "../hooks/CommonHooks";
import { useConfirmation } from "../hooks/ConfirmationProvider";
import { doNothing, download, preventDefault, stopPropagation } from "../hooks/NavigationHook";
import { useToasts } from "../hooks/ToastsProvider";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { AttachmentInputModel, AttachmentModel, AttachmentType, Maybe, MessageAddressType, MessageInputModel, MessageModel, PersonModel, ProductModel, ResourceKind, ResourceState } from "../types/api-graph-types";

library.add(faWhatsapp, faFacebook, faLinkedin, faLink, faPaperclip, faFile, faFilePdf, faFileWord, faFileVideo, faFilePowerpoint, faFileImage, faFileExcel, faFileCode, faFileAudio, faFileArchive, faCalendarAlt, faSave);

export const RecipientFragment = `
  type
  name
  picture
  email
  phone
  accentColor
`;

export const Fragment = `
  recipients { ${RecipientFragment} }
  sender { ${RecipientFragment} }
  subject
  content
  shortContent
  attachments { filename length mediaType dataUri }
  bccSender
  createdOn
  requiredData
  requiredFields { key value placeholder help }
`;

const _previewMyMessage = `mutation previewMyMessage($value:MessageInputModel) {
  me {
    message: addMessage {
      change(value:$value)
      preview { ${Fragment} }
    } 
  }
}`;

const _sendMyMessage = `mutation sendMyMessage($value:MessageInputModel) {
  me {
    message: addMessage {
      change(value:$value)
      send
    } 
  }
}`;

const _saveMessages = new Map<ResourceKind, string>([
  [ResourceKind.Product, `mutation saveProductMessages($id:ID $values:[MessageInputModel]) {
    product(id:$id) {
      details { change(value:{messages:$values}) }
      commit { id details { messages { key name subject content shortContent attachments { key filename url token } } } }
    }
  }`]
]);

interface OperationResults {
  me: {
    message: {
      preview: ReadonlyArray<MessageModel>
      send: boolean
    }
  },
  product: {
    commit: ProductModel
  }
};

interface ResourceModel {
  readonly id: Maybe<string>;
  readonly kind: Maybe<ResourceKind>;
};

const _1kb = 1024;
const _1mb = 1024 * _1kb;
const _goRegex = /{{#go}}.*?{{\/go}}/g;
const _shortUrlGrace = (value: string): number => {
  const shortUrlLength = 22; // https://hkgo.in/123456
  const matches = value.match(_goRegex) || [];
  return matches.map(_ => _.length).reduce((a, v) => a + v, 0) - shortUrlLength * matches.length;
}

const _resize = "d5370bb3-2324-472d-a49d-8626a6056901";
interface Message {
  action: string
  id: string,
  height: number
};

const _childPadding = 1;
const _childHandlers = new Map<string, (height: number) => void>();
window.addEventListener("message", e => {
  if (e.data && typeof (e.data) === "string" && e.data.indexOf(_resize) != -1) {
    const msg = window.JSON.parse(e.data) as Message;
    const handler = _childHandlers.get(msg.id);
    handler?.(msg.height + 1);
  }
});

function injectScripts(id: string, html: string) {
  const origin = window.location.origin;
  return html.replace("</head>", `
        <style>
          table:not([style]) { 
            width: 100%;
            table-layout: fixed; 
          }

          table:not([style]) > tbody > tr > td,
          table:not([style]) > tr > td { 
            overflow-x: hidden; 
            text-overflow: ellipsis; 
          }
        </style>
      </head>
    `)
    .replace("</body>", `
        <script>
          function _resize() {
            let message = {action:"${_resize}",id:"${id}",height:document.body.firstElementChild.scrollHeight};
            parent.postMessage(window.JSON.stringify(message), "${origin}");
          }
          function _setBase() {
            let base = document.createElement('base');
            base.target = '_blank';
            document.getElementsByTagName('head')[0].appendChild(base);
          }
          _setBase();
          setInterval(_resize, 100);
        </script>
      </body>
    `);
}

const _mediaToIcon = new Map<string, IconProp>([
  ["application/pdf", ["far", "file-pdf"]],
  ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", ["far", "file-word"]],
  ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ["far", "file-excel"]],
  ["application/vnd.openxmlformats-officedocument.presentationml.presentation", ["far", "file-powerpoint"]],
  ["application/x-zip-compressed", ["far", "file-archive"]],
  ["text/calendar", ["far", "calendar-alt"]],
]);

const _mediaToType = new Map<string, string>([
  ["application/pdf", "PDF"]
]);

function _toIcon(media: string): IconProp {
  return (_mediaToIcon.get(media) as IconProp) || ["far", "file"];
}

function _toFiletype(media: string, filename: string) {
  var tokens = filename.toUpperCase().split(".");
  return _mediaToType.get(media) || (tokens.length > 1 ? tokens[tokens.length - 1] : "");
}

function _toSize(length: number) {
  return length > _1mb ? `${Math.round(length / _1mb)} MB`
    : length > _1kb ? `${Math.round(length / _1kb)} KB`
      : `${length} B`;
}

function _formatName(name: string, email?: string, phone?: string) {
  return name ? name
    : email ? email
      : phone ? phone
        : "";
}

function PreviewMessageCards({ value }: { value: MessageModel }) {
  const [, d] = useTranslation();
  const [id] = useState(nextNonce());
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    _childHandlers.set(id, (height: number) => {
      if (iframeRef.current) {
        iframeRef.current.style.height = `${height}px`
      }
    });
    return () => { _childHandlers.delete(id); }
  }, [id]);
  const mto = value.recipients.filter(_ => _.type === MessageAddressType.MergeTo)[0];
  const cc = value.recipients.filter(_ => _.type === MessageAddressType.Copy);
  const bcc = value.recipients.filter(_ => _.type === MessageAddressType.BlindCopy);
  const ato = value.recipients.filter(_ => _.type === MessageAddressType.AdditionalTo);
  const attachmentsTotalLength = value.attachments.map(_ => _.length).reduce((a, b) => a + b, 0);
  const to = [mto, ...ato];
  const hasEmail = to.some(_ => _.email);

  return (
    <>
      {hasEmail &&
        <Card className="mt-3">
          <CardBody className="p-2">
            <div className="w-100 d-flex flex-row mt-2">
              <div className="">
                <UserThumbnail className="mr-3" width={32} value={mto} />
              </div>
              <div className="w-100 d-flex flex-column">
                <div className="d-flex flex-row">
                  <div>
                    <span>{to.filter(_ => _.email).map(_ => _formatName(_.name, _.email)).filter(n => !!n).join(", ")}</span>
                  </div>
                  <span className="ml-auto small text-muted">{d(value.createdOn, DateDisplayFormat.HumanDate)}</span>
                </div>
                <div className="d-flex flex-row small text-muted">
                  <div>
                    <span>From {_formatName(value.sender.name, value.sender.email)}</span>
                    {cc.length > 0 && <span>, Cc {cc.map(_ => _formatName(_.name, _.email)).join(", ")}</span>}
                    {bcc.length > 0 && <span>, Bcc {bcc.map(_ => _formatName(_.name, _.email)).join(", ")}</span>}
                  </div>
                </div>
              </div>
            </div>
            {value.attachments.length > 0 &&
              <div className="mt-2 w-100 d-flex flex-column">
                <div className="w-100 d-flex flex-row scrollx-0">
                  {value.attachments.map((_, i) =>
                    <Button key={i} color="white" className="w-auto d-flex flex-row align-items-center text-left mr-2 py-1 px-2 b-1 hover-container" onClick={() => download(_.dataUri, _.filename)}>
                      <FontAwesomeIcon className="mr-2" size="lg" icon={_toIcon(_.mediaType)} />
                      <div className="w-auto" style={{ minWidth: "5rem", maxWidth: "8rem" }}>
                        <div className="text-truncate">{_.filename}</div>
                        <div className="px-0 small text-muted" style={{ marginTop: "-2px" }}>
                          {_toFiletype(_.mediaType, _.filename)} - {_toSize(_.length)}
                        </div>
                      </div>
                    </Button>
                  )}
                </div>
                {value.attachments.length > 1 &&
                  <div className="text-muted small m-2">
                    <FontAwesomeIcon className="mr-1" icon={["fas", "paperclip"]} />
                    {value.attachments.length} attachments ({_toSize(attachmentsTotalLength)})
                  </div>
                }
              </div>
            }
          </CardBody>
          <iframe ref={iframeRef} width="100%" frameBorder={0} sandbox="allow-scripts allow-popups allow-popups-to-escape-sandbox" title="Message"
            src={`data:text/html;charset=UTF-8,${encodeURIComponent(injectScripts(id, value.content))}`} />
        </Card>
      }
      {value.shortContent && to.filter(_ => _.phone).map((_, i) =>
        <Card key={i} className="mt-3 ml-auto w-80 bubble-handle-bottom-right">
          <CardBody className="px-3 py-1">
            <div className="w-100 d-flex flex-row">
              <span className="small text-muted" title={_.phone}>{_formatName(_.name, undefined, _.phone)}</span>
            </div>
          </CardBody>
          <CardBody className="pt-1 px-3">
            <CardText>{value.shortContent}</CardText>
          </CardBody>
        </Card>
      )}
    </>
  );
}

const _defaultEmptyHelp = "No messages will be sent."
export function PreviewModalBody({ values, emptyHelp = _defaultEmptyHelp }: {
  values: ReadonlyArray<MessageModel>,
  emptyHelp?: string
}) {
  const [index, setIndex] = useState(0);
  const showPager = values.length > 1;
  const value = values[index];
  return (
    <ModalBody className="bg-light">
      <Row>
        <Col className="d-flex align-items-center">
          <h5 className="strong mb-0">{value?.subject}</h5>
          {showPager &&
            <div className="ml-auto text-nowrap">
              <Button color="link" disabled={index === 0} onClick={() => setIndex(index - 1)}>
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
              {(index + 1)} of {values.length}
              <Button color="link" disabled={index === values.length - 1} onClick={() => setIndex(index + 1)}>
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
            </div>
          }
        </Col>
      </Row>
      {value && <PreviewMessageCards value={value} />}
      {!values.length && <div>{emptyHelp}</div>}
    </ModalBody>
  );
}

const _newAttachment = {} as AttachmentInputModel;

function _toAttachmentInput(value: AttachmentModel): AttachmentInputModel {
  return ({
    ..._newAttachment,
    key: value.key || "",
    filename: value.filename || "",
    token: value.token || ""
  });
}

const _toMessageInput = (recipients: PersonModel[], subject?: string, contentMarkdown?: string, contentShort?: string, attachments?: ReadonlyArray<AttachmentModel>): MessageInputModel => ({
  key: "",
  name: "",
  isDraft: true,
  bccSender: true,
  recipientIds: recipients.map(_ => _.id),
  recipientEmails: "",
  recipientPhones: "",
  recipientUris: [],
  subject: subject || "",
  content: contentMarkdown || "",
  shortContent: contentShort || "",
  attachments: attachments?.map(_toAttachmentInput) || []
});

const _toTemplateInput = (value: MessageModel): MessageInputModel => ({
  key: value.key,
  name: value.name,
  isDraft: value.isDraft,
  bccSender: value.bccSender,
  recipientIds: [],
  recipientEmails: "",
  recipientPhones: "",
  recipientUris: [],
  subject: value.subject || "",
  content: value.content || "",
  shortContent: value.shortContent || "",
  attachments: value.attachments?.map(_toAttachmentInput) || []
});

const _extractTemplate = (input: MessageInputModel, data: Map<string, string | undefined>): MessageInputModel => ({
  key: input.key,
  name: input.name,
  isDraft: true,
  bccSender: true,
  recipientIds: [],
  recipientEmails: "",
  recipientPhones: "",
  recipientUris: [],
  subject: Array.from(data).reduce((value, item) => value?.replace(item[1] ?? item[0], item[0]), input.subject),
  content: Array.from(data).reduce((value, item) => value?.replace(item[1] ?? item[0], item[0]), input.content),
  shortContent: Array.from(data).reduce((value, item) => value?.replace(item[1] ?? item[0], item[0]), input.shortContent),
  attachments: input.attachments
})

const _renderTemplate = (template: MessageInputModel, data: Map<string, string | undefined>): MessageInputModel => ({
  ...template,
  subject: Array.from(data).reduce((value, item) => value?.replace(item[0], item[1] ?? item[0]), template.subject),
  content: Array.from(data).reduce((value, item) => value?.replace(item[0], item[1] ?? item[0]), template.content),
  shortContent: Array.from(data).reduce((value, item) => value?.replace(item[0], item[1] ?? item[0]), template.shortContent),
  attachments: template.attachments
})

export default ({ isOpen, to = [], title = "Send message", subject, content, shortContent, link, resource, showTemplates, templates = [], onUpdateTemplates, toggle }: {
  isOpen?: boolean,
  to?: PersonModel[],
  title?: string,
  subject?: string,
  content?: string,
  shortContent?: string,
  resource?: ResourceModel,
  link?: string,
  linkTitle?: string,
  showTemplates?: boolean,
  templates?: ReadonlyArray<MessageModel>,
  onUpdateTemplates?: (values: ReadonlyArray<MessageModel>) => void,
  toggle?: () => void
}) => {
  const confirmation = useConfirmation();
  const [, setToast] = useToasts();
  const [readyState, setReadyState] = useReadyState();
  const [switchId] = useState(nextSequence());
  const [files, setFiles] = useState<File[]>([]);
  const [input, setInput] = useState(_toMessageInput(to, subject, content, shortContent));
  const [isOpenSms, setIsOpenSms] = useState(!!shortContent);
  const [isOpenAttachments, setIsOpenAttachments] = useState(input.attachments.length > 0 || false);
  const [isOpenPreview, setIsOpenPreview] = useToggle();
  const [, setClipboard] = useClipboard();
  const [isOpenCopiedTooltip, toggleCopiedTooltip] = useToggle();
  const [previews, setPreviews] = useState<ReadonlyArray<MessageModel>>([]);
  const [errors, setErrors] = useState<ApiErrors<MessageInputModel>>({});
  const [mutation] = useMutation<OperationResults>();
  useEffect(() => {
    setInput(_toMessageInput(to, subject, content, shortContent));
    setPreviews([]);
    setIsOpenPreview(false);
    setIsOpenSms(!!shortContent);
    setIsOpenAttachments(input.attachments.length > 0 || false);
  }, [isOpen])
  const updateInput = (props: Partial<MessageInputModel>) => setInput({ ...input, ...props });
  const updateAttachment = (index: number, props: Partial<AttachmentInputModel>) => {
    let values: ReadonlyArray<AttachmentInputModel>;
    if (index < input.attachments.length) {
      values = input.attachments.map((_, i) => i != index ? _ : { ..._, ...props });
    } else {
      values = [...input.attachments, { ..._newAttachment, ...props }];
    }
    updateInput({ attachments: values.filter(_ => !!_.type && _.state !== ResourceState.Removed) });
  }
  const addAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filename = e.target.files?.[0]?.name || "";
    if (filename) {
      updateAttachment(input.attachments.length, { type: AttachmentType.Other, content: filename, filename });
      setFiles([...files, ...Array.from(e.target.files || [])]);
    }
  }
  const openFile = (filename: string) => {
    const reader = new FileReader();
    const file = files.filter(f => f.name === filename)[0];
    if (file) {
      reader.addEventListener("load", () => {
        if (typeof (reader.result) === "string") {
          var a = document.createElement('a');
          a.setAttribute('href', reader.result);
          a.setAttribute('download', filename);
          a.click();
        }
      });
      reader.readAsDataURL(file);
    }
  }
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.items
      ? Array.from(e.dataTransfer.items).filter(_ => _.kind === "file").map(_ => _.getAsFile() as File)
      : Array.from(e.dataTransfer.files);
    if (droppedFiles.length > 0) {
      updateInput({
        attachments: [
          ...input.attachments,
          ...droppedFiles.map(_ => ({ ..._newAttachment, type: AttachmentType.Other, content: _.name, filename: _.name }))
        ]
      });
      setFiles([...files, ...droppedFiles]);
      setIsOpenAttachments(true);
    }
  }
  const send = async () => {
    try {
      await mutation(_sendMyMessage, { value: { ...input, isDraft: false } }, { files, setReadyState });
      setToast({ message: "Message sent" }, 3000);
      toggle?.();
    } catch (errors) {
      setErrors(errors && errors.op.me.message.send.value || {});
      setIsOpenPreview(false);
    }
  }
  const setTemplate = (message: MessageModel) => {
    const data = new Map<string, string | undefined>([["{{Link}}", link]]);
    updateInput(_renderTemplate(_toMessageInput(to, message.subject, message.content, message.shortContent, message.attachments), data));
    setIsOpenSms(!!message.shortContent);
    setIsOpenAttachments(!!message.attachments?.length);
  }
  const loadPreview = async () => {
    try {
      const results = await mutation(_previewMyMessage, { value: { ...input, isDraft: true } }, { files });
      setPreviews(results.me.message.preview);
      setIsOpenPreview(true);
    } catch (errors) {
      setErrors(errors && errors.op.me.message.send.value || {});
    }
  }
  const togglePreview = async () => {
    !isOpenPreview && loadPreview();
    isOpenPreview && setIsOpenPreview(!isOpenPreview);
    isOpenPreview && setPreviews([]);
  }
  const copyToClipboard = (value: string) => {
    setClipboard(value);
    toggleCopiedTooltip(true);
    setTimeout(() => toggleCopiedTooltip(false), 3000);
  }
  const changeTemplates = async (values: MessageInputModel[], files?: File[]) => {
    const query = _saveMessages.get(resource?.kind ?? ResourceKind.Null) ?? "";
    const id = resource?.id ?? "";
    const results = await mutation(query, { id, values }, { files });
    onUpdateTemplates?.(results.product.commit.details.messages);
  }
  const saveTemplate = async (key: string, name: string) => {
    const data = new Map<string, string | undefined>([["{{Link}}", link]]);
    await changeTemplates([
      { ..._extractTemplate(input, data), key, name },
      ...templates.filter(_ => _.key !== key).map(_toTemplateInput)
    ], files);
  }
  const removeTemplate = async (key: string) => {
    await changeTemplates(templates.filter(_ => _.key !== key).map(_toTemplateInput));
  }
  const toggleAttachments = () => setIsOpenAttachments(!isOpenAttachments);
  const shortContentMaxLength = 300 + _shortUrlGrace(input.shortContent);
  const isValid = (!input.recipientEmails || input.recipientEmails.split(",").every(isEmail))
    && (!input.recipientPhones || input.recipientPhones.split(",").every(isPhone))
    && (to.length > 0 || !!input.recipientEmails || !!input.recipientPhones);

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClick={stopPropagation}>
      <div className="modal-header d-flex flex-row align-items-baseline">
        <h5 className="mb-0">{title}</h5>
        <CustomInput disabled={!isValid && !isOpenPreview} id={switchId} className="ml-3" type="switch" label="Preview" checked={isOpenPreview} onChange={togglePreview} />
      </div>
      {!isOpenPreview &&
        <ModalBody>
          <Form>
            <fieldset>
              <FormGroup label="To" help={!to.length && "Separate multiple emails with commas." || undefined}>
                {to.map((_, i) => <UserThumbnail key={i} title={_.name} className="mr-1" width={32} value={_} />)}
                {!to.length && <Input value={input.recipientEmails} onChange={e => updateInput({ recipientEmails: e.currentTarget.value })} />}
              </FormGroup>
              <FormGroup label='Subject' error={errors.subject} maxLength={140} length={input.subject.length}>
                <Input type="text" className="form-control" placeholder="Enter the message subject here" value={input.subject} onChange={e => updateInput({ subject: e.currentTarget.value.substring(0, 140) })} />
              </FormGroup>
              <div onDrop={handleDrop} onDragOver={doNothing}>
                <FormGroup label='Message' error={errors.content}>
                  <Input type="textarea" style={{ height: "10rem" }} className="form-control" placeholder="Enter the message body here" value={input.content} onChange={e => updateInput({ content: e.currentTarget.value })} />
                </FormGroup>
                <FormGroup>
                  <CustomInput id='add-attachment' type="switch" disabled={input.attachments.length > 0} label="Send an attachment" checked={isOpenAttachments} onChange={toggleAttachments} />
                </FormGroup>
                <Collapse isOpen={isOpenAttachments}>
                  {input.attachments?.length > 0 &&
                    <ul className="list-unstyled mb-0">
                      {input.attachments?.map((_, i) =>
                        <li key={i} className="d-flex align-items-baseline">
                          <FontAwesomeIcon className="mr-1" icon="paperclip" />
                          {_.state !== ResourceState.Removed && _.key && <a href={templates.flatMap(_ => _.attachments).filter(a => a.key === _.key)[0]?.url} target="_blank">{_.filename}</a>}
                          {_.state !== ResourceState.Removed && !_.key && <a href="#" onClick={preventDefault(() => openFile(_.filename))} target="_blank">{_.filename}</a>}
                          {_.state === ResourceState.Removed && <del>{_.filename}</del>}
                          <Button color="link" size="sm" className="ml-auto" onClick={() => updateAttachment(i, { state: ResourceState.Removed })}>Remove</Button>
                        </li>
                      )}
                    </ul>
                  }
                  <InputGroup>
                    <div className='custom-file'>
                      <Input type="file" className={`custom-file-input`} onChange={addAttachment} />
                      <label className="custom-file-label text-truncate text-nowrap text-muted">Select your file here</label>
                    </div>
                  </InputGroup>
                  {input.attachments.reduce((size, _) => size + (templates.flatMap(a => a.attachments).filter(a => a.key === _.key)[0]?.length ?? files.filter(f => f.name === _.filename)[0]?.size ?? 0), 0) > 7 * 1024 * 1024 &&
                    <div className="mt-2 text-muted small">
                      <FontAwesomeIcon size="sm" className="mr-1 align-baseline" icon="exclamation-triangle" />
                      Attachments too large to send in an email will be sent via the cloud and remain accessible for thirty days.
                    </div>
                  }
                </Collapse>
              </div>
              <FormGroup className="mt-3">
                <CustomInput id='cc-sender' type="switch" label="Send me a copy" checked={input.bccSender} onChange={() => updateInput({ bccSender: !input.bccSender })} />
              </FormGroup>
              {to.length > 0 &&
                <FormGroup>
                  <CustomInput id='select-sms' type="switch" disabled={!!input.shortContent} label="Send a text message" checked={isOpenSms} onChange={() => setIsOpenSms(!isOpenSms)} />
                </FormGroup>
              }
              <Collapse isOpen={isOpenSms}>
                <FormGroup label='Message' error={errors.shortContent} maxLength={shortContentMaxLength} length={input.shortContent.length}>
                  <Input type="textarea" rows={3} className="form-control" placeholder="Enter the text message here" value={input.shortContent} onChange={e => updateInput({ shortContent: e.currentTarget.value.substring(0, shortContentMaxLength) })} />
                </FormGroup>
              </Collapse>
            </fieldset>
          </Form>
        </ModalBody>
      }
      {isOpenPreview && <PreviewModalBody values={previews} />}
      <ModalFooter>
        {!isOpenPreview && link &&
          <UncontrolledDropdown direction="up">
            <DropdownToggle id="send-link" color="link">
              <FontAwesomeIcon className="m-1" icon="link" />
              <Tooltip target="send-link" isOpen={isOpenCopiedTooltip}>Copied to clipboard</Tooltip>
            </DropdownToggle>
            <DropdownMenu>
              <CustomDropdownItem icon={["far", "copy"]} onClick={() => copyToClipboard(link)}>Copy to clipboard</CustomDropdownItem>
              <CustomDropdownItem icon={["fab", "whatsapp"]} href={`https://api.whatsapp.com/send?text=${encodeURIComponent(link)}&lang=en`} target="_blank">Share via WhatsApp</CustomDropdownItem>
              <CustomDropdownItem icon={["fab", "facebook"]} href={`http://www.facebook.com/sharer.php?u=${encodeURIComponent(link)}`} target="_blank">Share via Facebook</CustomDropdownItem>
              <CustomDropdownItem icon={["fab", "linkedin"]} href={`http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(link)}`} target="_blank">Share via LinkedIn</CustomDropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        }
        {!isOpenPreview && showTemplates &&
          <UncontrolledDropdown direction="up">
            <DropdownToggle color="link">Templates</DropdownToggle>
            <DropdownMenu>
              {templates.map((_, i) =>
                <CustomDropdownItem key={i} showRemove onClick={() => setTemplate(_)} onRemove={confirmation(() => removeTemplate(_.key), `This will remove template "${_.name}".`, { title: "Remove template?", label: "Remove" })}
                  append={<a className="ml-3 text-primary cursor-pointer hover-visible" onClick={preventDefault(confirmation(() => saveTemplate(_.key, _.name), `This will overwrite template "${_.name}".`, { title: "Overwrite template?", label: "Save" }))}><FontAwesomeIcon icon={["far", "save"]} /></a>}>
                  {_.name || _.subject.substr(0, 10)}
                </CustomDropdownItem>
              )}
              {templates.length > 0 && <DropdownItem divider />}
              <DropdownItem onClick={confirmation(name => saveTemplate(nextNonce(), name), "", { showInput: true, title: <h5>Enter a new template name</h5> })}>Save as new template</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        }
        <Button className="ml-auto" color="link" onClick={toggle}>Cancel</Button>
        <CardButtons readyState={readyState}>
          <CardButton disabled={!isValid} onClick={send}>Send</CardButton>
        </CardButtons>
      </ModalFooter>
    </Modal>
  );
}

