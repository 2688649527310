import React, { createContext, ReactNode, useContext } from "react";
import { DeepPartial, useQuery } from "../hooks/ApiProvider";
import { MeModel, OrganizationModel, SettingsModel } from "../types/api-graph-types";

type ContextType = [
  MeModel | undefined, (value: DeepPartial<MeModel>) => void,
  OrganizationModel | undefined, (value: DeepPartial<OrganizationModel>) => void,
  SettingsModel | undefined
];
const initialContext: any = undefined;
const Context = createContext<ContextType>(initialContext);

const _getMyContext = `query getMyContext {
  settings {
    razorpayClientId
  }
  me { 
    id 
    kind
    name 
    email
    phone
    nickname
    accentColor
    picture
    contactsConfirmedOn
    termsAgreedOn
    contactInformation { key type value isPrimary isVerified verificationRequestedOn }
    eduAffiliation { organization { id kind name displayName } organizationName endOn }
    resume { updatedOn filename length mediaType url pdfUrl } 
    alternates { id kind }
    organizations { id kind name displayName }
    locationPreferences { key value { city } options }
    topicPreferences { key value { industry service area } options }
    incomePreference { value { currency annualAmount } }
    wallet { balance { key currency amount } }
    subscriptions { id kind title plan { id title } }
  }
  myOrganization { 
    id 
    kind
    name
    displayName
    nickname
    policy { role }
  }
}`;

interface Result {
  settings: SettingsModel
  me: MeModel;
  myOrganization: OrganizationModel;
}

export function useMe(): [MeModel | undefined, (value: DeepPartial<MeModel>) => void] {
  const [me, updateMe] = useContext(Context);
  return [me, updateMe];
}

export function useMyOrganization(): [OrganizationModel | undefined, (value: DeepPartial<OrganizationModel>) => void] {
  const [, , myOrganization, updateMyOrganization] = useContext(Context);
  return [myOrganization, updateMyOrganization];
}

export function useSettings(): [SettingsModel | undefined] {
  const [, , , , settings] = useContext(Context);
  return [settings];
}

export default (props: { children?: ReactNode }) => {
  const [result, updateResult] = useQuery<Result>(_getMyContext, {}, { requireToken: true });
  const me = result?.me;
  const myOrganization = result?.myOrganization;
  const settings = result?.settings;
  const updateMe = (me: DeepPartial<MeModel>) => updateResult({ me });
  const updateMyOrganization = (myOrganization: DeepPartial<OrganizationModel>) => updateResult({ myOrganization });
  return (
    <Context.Provider value={[me, updateMe, myOrganization, updateMyOrganization, settings]}>
      {props.children}
    </Context.Provider>
  );
}
