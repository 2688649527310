import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Progress } from "reactstrap";
import { TooltipContext, TooltipTarget, UncontrolledTooltip } from "./Tooltip";

library.add(faCheck);

function CheckedItem({ label, checked }: {
  label: ReactNode,
  checked?: boolean
}) {
  return (
    <div className="d-flex align-items-baseline">
      <span className="mr-3 text-nowrap">{label}</span>
      {checked && <FontAwesomeIcon className="ml-auto" icon="check" />}
    </div>
  );
}

export default ({ values }: {
  values: [ReactNode, boolean][];
}) => {
  const count = values.reduce((a, v) => a += v[1] ? 1 : 0, 0);
  const step = 100 / Math.min(1, values.length);
  return (
    <TooltipContext>
      <TooltipTarget>
        <Progress multi>
          {values.map((_, i) => <Progress key={i} className="br-1l" bar value={step} color={count > i ? "primary" : "light"} />)}
        </Progress>
      </TooltipTarget>
      <UncontrolledTooltip className="d-flex flex-column" placement="bottom">
        {values.map((_, i) => <CheckedItem key={i} label={_[0]} checked={_[1]} />)}
      </UncontrolledTooltip>
    </TooltipContext>
  );
}
