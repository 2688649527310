import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ListGroup, ListGroupItem } from "reactstrap";
import { useQuery } from "../hooks/ApiProvider";
import ValidatingInput from "../components/Input";
import { ProductModel, ResourceRole } from "../types/api-graph-types";
import { Text as ProductText, Fragment as ProductFragment } from "../cards/ProductCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { usePreference, Preference } from "../hooks/PreferencesProvider";

library.add(faCheckSquare);

const _getProducts = `query getMyProducts($query:String $roles:[ResourceRole]) {
  me {
    products(query:$query first:5 roles:$roles) {
      id
      ${ProductFragment()}
    }
  }
}`;

interface Result {
  me: {
    products: ProductModel[]
  }
}

export default ({ isOpen, toggle, label, roles, onSelect }: {
  isOpen?: boolean,
  roles?: ResourceRole[],
  toggle?: () => void,
  label?: string,
  onSelect: (id: string, value: ProductModel) => void
}) => {
  const [query, setQuery] = usePreference<string>(Preference.ProductChooserQuery);
  const [value, setValue] = useState<ProductModel>();
  const [result] = useQuery<Result>(isOpen ? _getProducts : "", { query, roles });
  const reset = () => setValue(undefined);
  useEffect(reset, [isOpen]);
  const select = () => {
    value && onSelect(value.id, value);
    toggle?.();
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ListGroup flush>
        <ListGroupItem>
          <ValidatingInput type="search" delay={300} value={query} onUpdate={setQuery} />
        </ListGroupItem>
        {result?.me?.products?.map((_, i) => (
          <ListGroupItem key={i} className={value?.id === _.id ? "shadow" : value ? "opacity-50" : undefined} onClick={() => setValue(_)}>
            <Button color="link" className={`btn-float ${value?.id === _.id ? "" : "invisible"}`}><FontAwesomeIcon icon={["far", "check-square"]} /></Button>
            <ProductText value={_} hideDescription hideRequirements />
          </ListGroupItem>
        ))}
      </ListGroup>
      <ModalFooter>
        <Button color="link" onClick={toggle}>Cancel</Button>
        <Button color="primary" disabled={!value} onClick={select}>{label ?? "Select"}</Button>
      </ModalFooter>
    </Modal>
  );
}

