import { AttachmentModel, AttachmentType, ResourceKind } from "../types/api-graph-types";
import { DeepPartial, setDelete, useMutation } from "./ApiProvider";

const Fragment = `
  updatedOn
  filename
  length
  mediaType 
  url 
  pdfUrl
  policy { accessToken }
  actions { download change remove }
`;

const _addAttachmentMutation = `
  attachment: addAttachment {
    change(value:$value)
    commit { id ${Fragment} } 
  }
`

const _addAttachment = new Map<ResourceKind, string>([
  [ResourceKind.Task, `mutation addAttachment($oid:ID! $value:AttachmentInputModel) {
    resource: task(id:$oid) { ${_addAttachmentMutation} }
  }`],
  [ResourceKind.Order, `mutation addAttachment($oid:ID! $value:AttachmentInputModel $accessToken:String) {
    resource: order(id:$oid accessToken:$accessToken) { ${_addAttachmentMutation} }
  }`],
  [ResourceKind.Product, `mutation addAttachment($oid:ID! $value:AttachmentInputModel $accessToken:String) {
    resource: product(id:$oid accessToken:$accessToken) { ${_addAttachmentMutation} }
  }`]
]);

const _updateAttachment = `mutation updateAttachment($id:ID! $value:AttachmentInputModel $accessToken:String) {
  attachment(id:$id accessToken:$accessToken) { 
    change(value:$value)
    commit { id state ${Fragment} }
  }
}`;

const _removeAttachment = `mutation removeAttachment($id:ID! $accessToken:String) {
  attachment(id:$id accessToken:$accessToken) { 
    remove
    commit { id state ${Fragment} }
  }
}`;

interface OperationResults {
  resource: {
    attachment: {
      commit: AttachmentModel
    }
  }
  attachment: {
    commit: AttachmentModel;
  }
}

const _resumeRegex = /(resume|CV|C\\.V|Curriculum Vitae|Profile)/i;
export function isMaybeResume(filename: string) {
  return !!filename.match(_resumeRegex);
}

export function useAttachments(): {
  addAttachment: (objectId: string, objectKind: ResourceKind, type: AttachmentType, file: File, accessToken?: string) => Promise<DeepPartial<AttachmentModel>>,
  updateAttachment: (id: string, file: File, accessToken?: string) => Promise<DeepPartial<AttachmentModel>>
  removeAttachment: (id: string, accessToken?: string) => Promise<DeepPartial<AttachmentModel>>
} {
  const [mutation] = useMutation<OperationResults>();
  const addAttachment = async (oid: string, okind: ResourceKind, type: AttachmentType, file: File, accessToken?: string) => {
    const value = { type: type, content: file.name, filename: file.name };
    const files = [file];
    const result = await mutation(_addAttachment.get(okind) || "", { oid, value, accessToken }, { files });
    return result.resource.attachment.commit;
  }
  const updateAttachment = async (id: string, file: File, accessToken?: string) => {
    const files = [file];
    const value = { content: file.name, filename: file.name };
    const result = await mutation(_updateAttachment, { id, value, accessToken }, { files });
    return result.attachment.commit;
  };
  const removeAttachment = async (id: string, accessToken?: string) => {
    const result = await mutation(_removeAttachment, { id, accessToken });
    return setDelete(result.attachment.commit);
  };
  return { addAttachment, updateAttachment, removeAttachment };
}
