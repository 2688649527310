import { library } from "@fortawesome/fontawesome-svg-core";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Card, CardBody, CardTitle, Col, DropdownItem, Form, ListGroup, ListGroupItem, Row } from "reactstrap";
import { CardButton, CardButtons } from "../components/CardStatus";
import { CustomDropdown } from "../components/CustomDropdown";
import MutationButton from "../components/MutationButton";
import { DeepPartial, ReadyState, useMutation, useQuery, useReadyState } from "../hooks/ApiProvider";
import { download, useNavigation } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { NumberDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { AccountTransactionModel, AttachmentModel } from "../types/api-graph-types";
import PeriodInput from "../components/PeriodInput";

library.add(faSync);

const _transactionFragment = `
  id
  kind
  description
  currency
  amount
  references {
    quickbooksVendorId
    quickbooksCustomerId
  }
`;

const _getTransactions = `mutation exportQuickbooksTransactions($account:QuickbooksAccount $since:DateTime $until:DateTime) {
  administration {
    quickbooks {
      transactions(account:$account) {
        export(since:$since until:$until) {
          dataUri
        }
      }
    }
  }
}`;

const _getBalances = `mutation exportQuickbooksBalances($type:QuickbooksEntity) {
  administration {
    quickbooks {
      balances(type:$type) {
        export { ${_transactionFragment} }
      }
    }
  }
}`;

const _matchVendors = `mutation matchQuickbooksEntities($type:QuickbooksEntity) {
  administration {
    quickbooks {
      entities(type:$type) { match }
      balances(type:$type) {
        export { ${_transactionFragment} }
      }
    }
  }
}`;

const _payVendor = `mutation payQuickbooksVendor($id:String $limit:Int) {
  administration {
    quickbooks {
      vendor(id:$id) {
        pay(limit:$limit) { ${_transactionFragment} }
        balance { ${_transactionFragment} }
      }
    }
  }
}`;

interface Result {
  administration: {
    quickbooks: {
      balances: {
        export: ReadonlyArray<AccountTransactionModel>
      }
    }
  }
}

interface OperationResult {
  administration: {
    quickbooks: {
      transactions: {
        export: AttachmentModel
      },
      vendor: {
        pay: AccountTransactionModel
        balance: AccountTransactionModel
      }
    }
  }
}

enum QuickbooksAccount {
  RazorpayX = "RAZORPAY_X",
  Razorpay = "RAZORPAY",
}

enum QuickbooksEntity {
  Vendor = "VENDOR",
  Customer = "CUSTOMER"
}

const _filenames = new Map<QuickbooksAccount, string>([
  [QuickbooksAccount.Razorpay, "Razorpay.csv"],
  [QuickbooksAccount.RazorpayX, "RazorpayX.csv"],
]);

interface Vars {
  since?: string | null,
  until?: string | null
}

const _initialVars: Vars = {
  since: undefined,
  until: undefined,
};

function TransactionsCard({ }: {}) {
  const [mutation] = useMutation<OperationResult>();
  const [, navigate] = useNavigation();
  const [r0, setR0] = useReadyState();
  const [r1, setR1] = useReadyState();
  const [vars, setVars] = useState(_initialVars);

  const exportTransactions = (account: QuickbooksAccount, setReadyState: (value: ReadyState) => void) => async () => {
    const result = await mutation(_getTransactions, { ...vars, account }, { setReadyState });
    download(result.administration.quickbooks.transactions.export.dataUri, _filenames.get(account) ?? "transactions.csv");
    navigate("https://c17.qbo.intuit.com/app/bankfileupload");
  }
  const updateVars = (partial: Partial<Vars>) => setVars({ ...vars, ...partial });


  return (
    <Card>
      <CardBody>
        <Form inline>
          <CardTitle className="d-flex w-100 mb-0">
            <h3 className="mb-0">Transactions</h3>
            <PeriodInput className="ml-auto" value={`${vars.since || ""}/${vars.until || ""}`} onUpdate={value => updateVars({ since: value.split("/")[0], until: value.split("/")[1] })} />
          </CardTitle>
        </Form>
      </CardBody>
      <ListGroup flush className="bt-1">
        <ListGroupItem className="d-flex align-items-center">
          <div>Razorpay Payments</div>
          <MutationButton className="ml-auto" readyState={r1} onClick={exportTransactions(QuickbooksAccount.Razorpay, setR1)} color="primary">Export</MutationButton>
        </ListGroupItem>
        <ListGroupItem className="d-flex align-items-center">
          <div>RazorpayX Payouts</div>
          <MutationButton className="ml-auto" readyState={r0} onClick={exportTransactions(QuickbooksAccount.RazorpayX, setR0)} color="primary">Export</MutationButton>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}

function VendorBalanceItem({ value, onUpdate }: {
  value: AccountTransactionModel,
  onUpdate?: (value: AccountTransactionModel) => void
}) {
  const [, , n] = useTranslation();
  const [readyState, setReadyState] = useReadyState();
  const [mutation] = useMutation<OperationResult>();
  const pay = async () => {
    const result = await mutation(_payVendor, {
      id: value.references.quickbooksVendorId,
      limit: amount
    }, { setReadyState });
    onUpdate?.(result.administration.quickbooks.vendor.balance);
  }

  const amount = value.amount;

  return (
    <ListGroupItem className="d-flex align-items-center">
      <div className="w-50">{value.description}</div>
      {amount != 0 && <div className="w-25 text-right">{n(amount / 100, NumberDisplayFormat.Currency, undefined, "₹")}</div>}
      {amount == 0 && <div className="w-25 text-right">Paid</div>}
      <CardButtons className="ml-auto" readyState={readyState} >
        {amount > 0 && <CardButton onClick={pay}>Pay</CardButton>}
      </CardButtons>
    </ListGroupItem>
  );
}


function VendorBalancesCard({ }: {}) {
  const [vars, setVars] = useState({ ts: 0, type: QuickbooksEntity.Vendor });
  const [result, updateResult, isLoading] = useQuery<Result>(_getBalances, vars);
  const [mutation] = useMutation<OperationResult>();
  const updateBalance = (balance: DeepPartial<AccountTransactionModel>) => updateResult({ administration: { quickbooks: { balances: { export: [balance] } } } })
  const match = async () => {
    const result = await mutation(_matchVendors, { type: QuickbooksEntity.Vendor });
  }
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-0 w-100 d-flex align-items-center">
          <h3 className="mb-0">Vendors</h3>
          <CustomDropdown className="ml-auto" nav={false} color="link" label="Actions" spin={isLoading}>
            <DropdownItem onClick={() => setVars({ ...vars, ts: vars.ts + 1 })}>Refresh balances</DropdownItem>
            {/*<DropdownItem onClick={match}>Match</DropdownItem>*/}
          </CustomDropdown>
        </CardTitle>
      </CardBody>
      <ListGroup flush className="bt-1">
        {result?.administration.quickbooks.balances.export?.map((_, i) =>
          <VendorBalanceItem key={i} value={_} onUpdate={updateBalance} />)}
      </ListGroup>
    </Card>
  );
}

function CustomerBalanceItem({ value }: { value: AccountTransactionModel }) {
  const [readyState, setReadyState] = useReadyState();
  const [, , n] = useTranslation();
  return (
    <ListGroupItem className="d-flex align-items-center">
      <div className="w-50">{value.description}</div>
      <div className="w-25 text-right">{n(value.amount / 100, NumberDisplayFormat.Currency, undefined, "₹")}</div>
      <CardButtons className="ml-auto" readyState={readyState} >
        <CardButton onClick={() => { }}>Invoice</CardButton>
      </CardButtons>
    </ListGroupItem>
  );
}


function CustomerBalancesCard({ }: {}) {
  const [vars, setVars] = useState({ ts: 0, type: QuickbooksEntity.Customer });
  const [result, updateResult, isLoading] = useQuery<Result>(_getBalances, vars);
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-0 w-100 d-flex align-items-center">
          <h3 className="mb-0">Customers</h3>
          <CustomDropdown className="ml-auto" nav={false} color="link" label="Actions" spin={isLoading}>
            <DropdownItem onClick={() => setVars({ ...vars, ts: vars.ts + 1 })}>Refresh balances</DropdownItem>
          </CustomDropdown>
        </CardTitle>
      </CardBody>
      <ListGroup flush className="bt-1">
        {result?.administration.quickbooks.balances.export?.map((_, i) => <CustomerBalanceItem key={i} value={_} />)}
      </ListGroup>
    </Card>
  );
}

export default () => {
  useTitle("QuickBooks")
  return (
    <>
      <Row className="mt-3" >
        <Col>
          <TransactionsCard />
        </Col>
      </Row>
      <Row className="mt-3" >
        <Col lg={6}>
          <CustomerBalancesCard />
        </Col>
        <Col lg={6}>
          <VendorBalancesCard />
        </Col>
      </Row>
    </>
  );
};
