import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import { faAmazon, faFacebook, faFacebookMessenger, faGoogle, faInstagram, faLinkedin, faMicrosoft, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faBuilding as farBuilding, faCircle, faClipboard, faCommentAlt, faEnvelope, faFileAlt as farFileAlt, faFrown, faHeart, faIdBadge as farIdBadge, faIdCard, faMoneyBillAlt, faSmile, faThumbsDown, faThumbsUp, faUserCircle, faFileAlt, faWindowMaximize, faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faArchive, faBook, faBookmark, faBookOpen, faBriefcase, faBroadcastTower, faBuilding as fasBuilding, faCalculator, faCertificate, faChalkboard, faCheckCircle, faComments, faDownload, faEllipsisH, faEnvelopeOpenText, faFileAlt as fasFileAlt, faFirstAid, faGavel, faHome, faIdBadge as fasIdBadge, faIdCardAlt, faLanguage, faLaptop, faMobileAlt, faPlus, faPrint, faSearch, faShieldAlt, faThumbtack, faUniversity, faUserGraduate, faUsers, faUserTie, faGraduationCap, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { AttachmentType, ExternalAccountOption, ExternalAccountType, IndustryType, LocationPreferenceOption, OrganizationType, ProductType, ResourceRole, ServiceType, TaskType, TopicPreferenceOption, WorkItemType } from "../types/api-graph-types";

library.add(
  faAmazon,
  faArchive,
  faBook,
  faBook,
  faBookmark,
  faBookOpen,
  faBriefcase,
  faBroadcastTower,
  faCalculator,
  faCertificate,
  faChalkboard,
  faCheckCircle,
  faCircle,
  faClipboard,
  faCreditCard,
  faCommentAlt,
  faComments,
  faDownload,
  faEllipsisH,
  faEnvelope,
  faEnvelopeOpenText,
  faFacebook,
  faFacebookMessenger,
  faFileAlt,
  faFirstAid,
  faFrown,
  faGavel,
  faGoogle,
  faGraduationCap,
  faHeart,
  faHome,
  faIdCard,
  faIdCardAlt,
  faInstagram,
  faLanguage,
  faLaptop,
  faLinkedin,
  faMicrosoft,
  faMobileAlt,
  faMoneyBillAlt,
  faPaperclip,
  faPlus,
  faPrint,
  farBuilding,
  farFileAlt,
  farIdBadge,
  fasBuilding,
  faSearch,
  fasFileAlt,
  faShieldAlt,
  fasIdBadge,
  faSmile,
  faThumbsDown,
  faThumbsUp,
  faThumbtack,
  faTwitter,
  faUniversity,
  faUserCircle,
  faUserGraduate,
  faUsers,
  faUserTie,
  faWhatsapp,
  faWindowMaximize
);

const _icons = new Map<string, IconProp>([
  [ExternalAccountOption.FacebookMessenger, ["fab", "facebook-messenger"]],
  [ExternalAccountOption.PhoneSms, ["far", "comment-alt"]],
  [ExternalAccountOption.PhoneWhatsApp, ["fab", "whatsapp"]],
  [ExternalAccountType.Amazon, ["fab", "amazon"]],
  [ExternalAccountType.Authenticator, ["fas", "shield-alt"]],
  [ExternalAccountType.Email, ["far", "envelope"]],
  [ExternalAccountType.Facebook, ["fab", "facebook"]],
  [ExternalAccountType.Google, ["fab", "google"]],
  [ExternalAccountType.Instagram, ["fab", "instagram"]],
  [ExternalAccountType.LinkedIn, ["fab", "linkedin"]],
  [ExternalAccountType.Mail, ["fas", "map-marker-alt"]],
  [ExternalAccountType.Microsoft, ["fab", "microsoft"]],
  [ExternalAccountType.Payment, ["far", "money-bill-alt"]],
  [ExternalAccountType.Phone, ["fas", "mobile-alt"]],
  [ExternalAccountType.QuickBooks, ["fas", "calculator"]],
  [ExternalAccountType.Twitter, ["fab", "twitter"]],
  [ExternalAccountType.ClientApp, ["far", "window-maximize"]],
  [AttachmentType.Resume, ["far", "file-alt"]],
  [AttachmentType.GraduationCertificate, ["fas", "graduation-cap"]],
  [AttachmentType.ProfessionalLicense, ["far", "id-card"]],
  [AttachmentType.Photo, ["far", "user-circle"]],
  [AttachmentType.EmploymentCertificate, ["fas", "certificate"]],
  [AttachmentType.OtherCertificate, ["fas", "certificate"]],
  [AttachmentType.CourseCertificate, ["fas", "certificate"]],
  [AttachmentType.Publication, ["far", "file-alt"]],
  [AttachmentType.Other, ["fas", "paperclip"]],
  [AttachmentType.ReferenceLetter, ["far", "file-alt"]],
  [AttachmentType.RecommendationLetter, ["far", "file-alt"]],
  [AttachmentType.EducationalTranscript, ["fas", "print"]],
  [AttachmentType.TaxpayerIdentification, ["far", "id-card"]],
  [AttachmentType.BusinessCard, ["far", "credit-card"]],
  [WorkItemType.LegalResearch, ["fas", "book"]],
  [ProductType.EmploymentTrainingLevel, ["fas", "user-graduate"]],
  [ProductType.EmploymentEntryLevel, ["fas", "user-tie"]],
  [TaskType.LegalResearchRephrasing, ["fas", "bookmark"]],
  [TaskType.LegalResearchBackground, ["fas", "comments"]],
  [TaskType.LegalResearchStatute, ["fas", "book-open"]],
  [TaskType.LegalResearchPrecedent, ["fas", "gavel"]],
  [TaskType.LegalResearchConclusion, ["fas", "file-alt"]],
  [IndustryType.LegalIndustry, ["fas", "gavel"]],
  [IndustryType.AccountingIndustry, ["fas", "calculator"]],
  [IndustryType.EducationIndustry, ["fas", "chalkboard"]],
  [IndustryType.HealthcareIndustry, ["fas", "first-aid"]],
  [IndustryType.MediaIndustry, ["fas", "broadcast-tower"]],
  [IndustryType.InformationTechnologyIndustry, ["fas", "laptop"]],
  [ServiceType.LegalServiceFullService, ["fas", "briefcase"]],
  [ServiceType.LegalServiceCorporateTransactional, ["fas", "briefcase"]],
  [ServiceType.LegalServiceInHouseCounsel, ["fas", "building"]],
  [ServiceType.LegalServiceCivilLitigation, ["fas", "briefcase"]],
  [ServiceType.LegalServiceTransactional, ["fas", "briefcase"]],
  [ServiceType.LegalServiceOutsourcing, ["fas", "users"]],
  [ServiceType.LegalServiceJudicial, ["fas", "gavel"]],
  [ServiceType.LegalServiceResearch, ["fas", "search"]],
  [ServiceType.LegalServiceCriminalLitigation, ["fas", "briefcase"]],
  [ServiceType.LegalServiceLitigation, ["fas", "briefcase"]],
  [OrganizationType.Private, ["far", "building"]],
  [OrganizationType.Public, ["fas", "university"]],
  [ResourceRole.Owner, ["fas", "id-badge"]],
  [ResourceRole.Contributor, ["far", "id-badge"]],
  [ResourceRole.Reader, ["far", "id-badge"]],
  [ResourceRole.Guest, ["fas", "id-card-alt"]],
  [LocationPreferenceOption.Love, ["far", "heart"]],
  [LocationPreferenceOption.Like, ["far", "thumbs-up"]],
  [LocationPreferenceOption.Dislike, ["far", "thumbs-down"]],
  [OrganizationType.Private, ["far", "building"]],
  [OrganizationType.Public, ["fas", "university"]],
  [OrganizationType.Voluntary, ["fas", "hands-helping"]],
  [TopicPreferenceOption.Love, ["far", "heart"]],
  [TopicPreferenceOption.Like, ["far", "thumbs-up"]],
  [TopicPreferenceOption.Dislike, ["far", "thumbs-down"]],
]);

function iconize(key: string | undefined, defaultIcon?: IconProp): IconProp {
  return (_icons.get(key ?? "") as IconProp) ?? defaultIcon ?? ["far", "circle"];
}

export function useIcons() {
  return [iconize];
}
