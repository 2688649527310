import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Card, CardHeader, CardSubtitle, CardTitle, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import { UserThumbnail, UserThumbnailFragment } from "../cards/PersonCard";
import { CardAttributes, CardBodyAttributes, CardButtonsAttributes } from "../components/Card";
import { CardButton, CardButtons } from "../components/CardStatus";
import { useMutation } from "../hooks/ApiProvider";
import { useCardState } from "../hooks/CardStateHook";
import { useToggle } from "../hooks/CommonHooks";
import { locationBase, preventDefault } from "../hooks/NavigationHook";
import { NumberDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { MessageModal as ShareMessageModal } from "../modals/ShareModal";
import SubscriptionInputModal, { Fragment as SubscriptionInputFragment } from "../modals/SubscriptionInputModal";
import { AccountPlanModel, AccountSkuModel, AccountSubscriptionModel, AccountTransactionsSummaryModel } from "../types/api-graph-types";

library.add(faTrashAlt, faUserGraduate);

export const Fragment = `
  title
  state
  startsOn
  renewsOn
  expiresOn
  balance { key currency amount }
  plan {
    title
    description
    currency
    next { id }
    nextDescription
    discountAmount
    subscriptionPrice { id rate taxRate }
    prices { id title description rate }
  }
  transactionsSummary {
    sku { title description unit }
    currency
    amount
    tax
    quantity
    blendedRate
    transactionsSummary { description amount tax quantity }
  }
  customer { ${UserThumbnailFragment} } 
  actions { upgrade change share remove }
  shares(states:[COMPLETED]) { 
    subject { ${UserThumbnailFragment} }
  }
  ${SubscriptionInputFragment}
`;

interface OperationResults {
}

function _pad(n: number) {
  return n == 0 ? "\u00a0\u00a0\u00a0" : n < 10 ? `.0${n}` : `.${n}`;
}

function ItemRow({ value, summary }: {
  value: AccountSkuModel,
  summary?: AccountTransactionsSummaryModel,
}) {
  const [t, , n] = useTranslation();
  const [isOpen, toggle] = useToggle();
  const classNames = "hover-container d-flex align-items-baseline";
  const amount = summary && summary.amount > 0 && (summary.amount - summary.tax) || 0;
  const showDetails = summary && (summary.transactionsSummary?.length > 0);
  return (
    <ListGroupItem>
      <div className={classNames}>
        <div className="w-50">
          {value.title}
          <span className="ml-1 small text-muted">{value.description}</span>
          {showDetails && <a className="ml-1 small" href="#" onClick={preventDefault(toggle)}>Details</a>}
        </div>
        <div className="text-right w-25">
          {summary && summary.quantity > 0 && <span>{summary.quantity} {summary.sku.unit}</span>}
        </div>
        <div className="text-right w-25">
          {summary && !summary.amount && <span><strong>Free!</strong></span>}
          {summary && summary.amount > 0 && <span>{n(Math.floor(amount / 100), NumberDisplayFormat.Currency, undefined, t(summary.currency))}</span>}
        </div>
      </div>
      {showDetails &&
        <Collapse isOpen={isOpen} toggle={toggle}>
          {summary?.transactionsSummary.map((_, i) =>
            <div key={i} className={classNames}>
              <div className="ml-3 w-50 small">{_.description}</div>
              <div className="text-right w-25 small">{_.quantity} {summary.sku.unit}</div>
              <div className="text-right w-25 small">
                {!_.amount && <span>Free!</span>}
                {_.amount > 0 && <span>{n(Math.floor((_.amount - _.tax) / 100), NumberDisplayFormat.Currency, undefined, t(summary.currency))}</span>}
              </div>
            </div>
          )}
        </Collapse>
      }
    </ListGroupItem>
  );
}

function TaxRow({ values }: {
  values: ReadonlyArray<AccountTransactionsSummaryModel>,
}) {
  const [t, , n] = useTranslation();
  const classNames = "hover-container d-flex align-items-baseline";
  const currency = values[0].currency;
  const amount = values.reduce((a, _) => a + (_.tax || 0), 0);
  return (
    <ListGroupItem className={classNames}>
      <div className="w-50">Taxes</div>
      <div className="text-right w-25"></div>
      <div className="text-right w-25">
        <span>{n(Math.floor(amount / 100), NumberDisplayFormat.Currency, undefined, t(currency))}</span>
      </div>
    </ListGroupItem>
  );
}

function DiscountRow({ values, plan }: {
  values: ReadonlyArray<AccountTransactionsSummaryModel>,
  plan: AccountPlanModel
}) {
  const [t, d, n] = useTranslation();
  const classNames = "hover-container d-flex align-items-baseline";
  const currency = values[0].currency;
  const amount = values.reduce((a, _) => a + (_.amount || 0), 0);
  const subscriptionAmount = (plan.subscriptionPrice?.rate ?? 0) * (1 + (plan.subscriptionPrice?.taxRate ?? 0) / 1000);
  const discount = Math.min(plan.discountAmount, amount - subscriptionAmount);
  return (
    <ListGroupItem className={classNames}>
      <div className="w-50">Discounts</div>
      <div className="text-right w-25"></div>
      <div className="text-right w-25">
        <span>{n(Math.floor(-discount / 100), NumberDisplayFormat.Currency, undefined, t(currency))}</span>
      </div>
    </ListGroupItem>
  );
}

function TotalRow({ values, plan }: {
  values: ReadonlyArray<AccountTransactionsSummaryModel>,
  plan: AccountPlanModel
}) {
  const [t, d, n] = useTranslation();
  const classNames = "hover-container d-flex align-items-baseline";
  const currency = values[0].currency;
  const amount = values.reduce((a, _) => a + (_.amount || 0), 0);
  const subscriptionAmount = (plan.subscriptionPrice?.rate ?? 0) * (1 + (plan.subscriptionPrice?.taxRate ?? 0) / 1000);
  const discount = Math.min(plan.discountAmount, amount - subscriptionAmount);
  return (
    <ListGroupItem className={classNames}>
      <div className="w-50">
        <strong>Estimated Total</strong>
        <span className="ml-1 small text-muted">Invoiced total will be displayed once an invoice is issued.</span>
      </div>
      <div className="text-right w-25"></div>
      <div className="text-right w-25">
        <span><strong>{n(Math.floor((amount - discount) / 100), NumberDisplayFormat.Currency, undefined, t(currency))}</strong></span>
      </div>
    </ListGroupItem>
  );
}

export function Buttons({ value, className, toggleInput, toggleShare, onUpdate }: CardButtonsAttributes<AccountSubscriptionModel>) {
  const [mutation] = useMutation<OperationResults>();
  const { id, actions } = value;
  const showUpgrade = actions.upgrade && value.plan.next;
  const removeSubscription = async () => {
    //const result = await mutation(_removeProduct, { id })
    //onUpdate?.(result.product.commit);
  }
  const upgradeSubscription = async () => {
    //const result = await mutation(_removeProduct, { id })
    //onUpdate?.(result.product.commit);
  }
  return (
    <CardButtons className={className || "mt-3"}>
      {showUpgrade && false && <CardButton onClick={upgradeSubscription}>Upgrade</CardButton>}
      {actions.change && toggleInput && <CardButton icon="pencil-alt" onClick={toggleInput}>Edit</CardButton>}
      {actions.share && toggleShare && <CardButton icon="share" onClick={toggleShare}>Share</CardButton>}
    </CardButtons>
  )
}

function Body({ value, onUpdate, showTransactions: _showTransactions, toggleInput, toggleShare, showButtons }: CardBodyAttributes<AccountSubscriptionModel> & {
  showTransactions?: boolean
}) {
  const showTransactions = _showTransactions && value.transactionsSummary?.length > 0;
  return (
    <>
      <CardHeader className={`bg-white d-flex align-items-center ${showTransactions ? "" : "b-0"}`}>
        <div>
          {value.title && <CardSubtitle tag='h5'>{value.plan.title}</CardSubtitle>}
          <CardTitle tag='h3' className="mb-0">{value.title ?? value.plan.title}</CardTitle>
          <span className="text-muted small">{value.plan.description}</span>
        </div>
        {showButtons && <Buttons className="ml-auto" {...{ value, onUpdate, toggleInput, toggleShare }} />}
      </CardHeader>
      {showTransactions &&
        <ListGroup flush>
          {value.transactionsSummary.map((_, i) => <ItemRow key={i} value={_.sku} summary={_} />)}
          <TaxRow values={value.transactionsSummary} />
          <DiscountRow values={value.transactionsSummary} plan={value.plan} />
          <TotalRow values={value.transactionsSummary} plan={value.plan} />
        </ListGroup>
      }
    </>
  );
}

function SharesListGroup({ value }: { value: AccountSubscriptionModel }) {
  return (
    <ListGroup className="bt-1" flush>
      <ListGroupItem className="d-flex align-items-start">
        <UserThumbnail className="mr-3" width={32} value={value.customer} title={value.customer.name} />
        {value.shares.map((_, i) => <UserThumbnail key={i} className="mr-3" width={32} value={_.subject} title={_.subject.name} />)}
      </ListGroupItem>
    </ListGroup>
  );
}

export default ({ value, href, className, showTransactions, showButtons, showInput, showShare, onUpdate }: CardAttributes<AccountSubscriptionModel> & {
  showTransactions?: boolean
}) => {
  const hasShares = value.shares?.length > 0;
  const { isOpenInput, toggleInput, isOpenShare, toggleShare } = useCardState({ showInput, showShare });
  return (
    <>
      <Card className={className}>
        <Body {...{ value, showTransactions, showButtons, toggleInput, toggleShare }} />
        {showShare && hasShares && <SharesListGroup value={value} />}
      </Card>
      {showInput && <SubscriptionInputModal value={value} onUpdate={onUpdate} isOpen={isOpenInput} toggle={toggleInput} />}
      {showShare && <ShareMessageModal value={value} title={value.title} isOpen={isOpenShare} toggle={toggleShare} url={locationBase} />}
    </>
  );
}
