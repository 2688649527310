import React from "react";
import { Container, Navbar } from "reactstrap";
import { useNavigation } from "../hooks/NavigationHook";

export default () => {
  const [navigate] = useNavigation();
  return (
    <>
      <footer className="mt-auto">
        <Navbar>
          <Container fluid className="align-items-center p-3">
            <div className="mx-auto small">
              <a className="text-muted ml-3" href="/our/company/mission" onClick={navigate("/our/company/mission")}>About</a>
              <a className="text-muted ml-3" href="/our/policies/privacy" onClick={navigate("/our/policies/privacy")}>Privacy</a>
              <a className="text-muted ml-3" href="/our/policies" onClick={navigate("/our/policies")}>Policies</a>
              <a className="text-muted ml-3" href="/our/policies/terms" onClick={navigate("/our/policies/terms")}>Terms</a>
              <a className="text-muted ml-3" href="/our/faq" onClick={navigate("/our/faq")}>FAQ</a>
              <a className="text-muted ml-3" href="/our/company/address" onClick={navigate("/our/company/address")}>Contact</a>
            </div>
          </Container>
        </Navbar>
      </footer>
    </>
  );
}
