import { CommentModel, ReviewOption, ReviewInputModel, ReviewModel, ResourceKind, ResourceState, CommentInputModel } from "../types/api-graph-types";
import { useMutation, setReplace, DeepPartial, setDelete, useReadyState, ReadyState } from "../hooks/ApiProvider";

export const CommentFragment = `
  id
  kind
  state
  message
  isEdited
  isPrivate
  createdOn
  updatedOn
  author { name picture accentColor }
  actions { change remove }
  reviewsSummary { likeCount didLike }
`;

export const ReviewFragment = `
  id
  kind
  rating
  message
  isEdited
  isPrivate
  like
  love
  approved
  rejected
  options
  author { name picture accentColor }
  attachments { id kind state filename length mediaType url pdfUrl }
  actions { change remove undo }
  reviewsSummary { likeCount didLike }
`;

const _updateCommentFragment = `
  ${CommentFragment} 
  rootCommentsSummary { completedCount }
`

const _removeCommentFragment = `
  id 
  kind
  state 
  actions { change remove }
  rootCommentsSummary { completedCount }
`;

const _addCommentMutation = `
  comment: addComment {
    change(value:$value)
    commit { ${_updateCommentFragment} } 
  }
`

const _addComment = new Map<ResourceKind, string>([
  [ResourceKind.Task, `mutation addTaskComment($oid:ID! $value:CommentInputModel) {
    resource: reviewTask(id:$oid) { ${_addCommentMutation} } 
   }`],
  [ResourceKind.Comment, `mutation addCommentReply($oid:ID! $value:CommentInputModel) {
    resource: reviewComment(id:$oid) { ${_addCommentMutation} } 
   }`],
  [ResourceKind.WorkItem, `mutation addWorkitemComment($oid:ID! $value:CommentInputModel) {
    resource: reviewWorkitem(id:$oid) { ${_addCommentMutation} } 
   }`],
  [ResourceKind.Order, `mutation addOrderComment($oid:ID! $value:CommentInputModel) {
    resource: reviewOrder(id:$oid) { ${_addCommentMutation} } 
   }`],
  [ResourceKind.Attachment, `mutation addAttachmentComment($oid:ID! $value:CommentInputModel) {
    resource: reviewAttachment(id:$oid) { ${_addCommentMutation} } 
   }`],
  [ResourceKind.Review, `mutation addReviewComment($oid:ID! $value:CommentInputModel) {
    resource: reviewReview(id:$oid) { ${_addCommentMutation} } 
   }`],
]);

const _updateComment = `mutation updateComment($id:ID! $value:CommentInputModel $accessToken:String) {
  comment(id:$id accessToken:$accessToken) {
    change(value:$value)
    commit { ${_updateCommentFragment} } 
  } 
}`;

const _removeComment = `mutation removeComment($id:ID! $accessToken:String) {
  comment(id:$id accessToken:$accessToken) {
    remove
    commit { ${_removeCommentFragment} } 
  } 
}`;

const _updateReviewFragment = `
  ${ReviewFragment} 
  relatedSummary { ratingCount ratingAverage likeCount loveCount didLike didLove }
`

const _removeReviewFragment = `
  id
`;

const _addReviewMutation = `
  review: addReview {
    change(value:$value)
    commit { ${_updateReviewFragment} } 
  }
`

const _updateReviewMutation = `
  review {
    change(value:$value) @include(if: $hasChange)
    toggleOption(value:$option) @include(if: $hasToggle)
    commit { ${_updateReviewFragment} } 
  }
`

const _addReview = new Map<ResourceKind, string>([
  [ResourceKind.Job, `mutation addJobReview($oid:ID! $value:ReviewInputModel) {
    resource: reviewJob(id:$oid) { ${_addReviewMutation} } 
   }`],
  [ResourceKind.Task, `mutation addTaskReview($oid:ID! $value:ReviewInputModel) {
    resource: reviewTask(id:$oid) { ${_addReviewMutation} } 
   }`],
  [ResourceKind.WorkItem, `mutation addWorkitemReview($oid:ID! $value:ReviewInputModel) {
    resource: reviewWorkitem(id:$oid) { ${_addReviewMutation} } 
   }`],
  [ResourceKind.Order, `mutation addOrderReview($oid:ID! $value:ReviewInputModel) {
    resource: reviewOrder(id:$oid) { ${_addReviewMutation} } 
   }`],
  [ResourceKind.Attachment, `mutation addAttachmentReview($oid:ID! $value:ReviewInputModel) {
    resource: reviewAttachment(id:$oid) { ${_addReviewMutation} } 
   }`],
  [ResourceKind.Comment, `mutation addCommentReview($oid:ID! $value:ReviewInputModel) {
    resource: reviewComment(id:$oid) { ${_addReviewMutation} } 
   }`],
  [ResourceKind.Review, `mutation addReviewReview($oid:ID! $value:ReviewInputModel) {
    resource: reviewReview(id:$oid) { ${_addReviewMutation} } 
   }`],
]);

const _updateReview = new Map<ResourceKind, string>([
  [ResourceKind.Job, `mutation updateJobReview($oid:ID! $value:ReviewInputModel $option:ReviewOption $hasToggle:Boolean! $hasChange:Boolean!) {
    resource: reviewJob(id:$oid) { ${_updateReviewMutation} } 
   }`],
  [ResourceKind.Task, `mutation updateTaskReview($oid:ID! $value:ReviewInputModel $option:ReviewOption $hasToggle:Boolean! $hasChange:Boolean!) {
    resource: reviewTask(id:$oid) { ${_updateReviewMutation} } 
   }`],
  [ResourceKind.WorkItem, `mutation updateWorkitemReview($oid:ID! $value:ReviewInputModel $option:ReviewOption $hasToggle:Boolean! $hasChange:Boolean!) {
    resource: reviewWorkitem(id:$oid) { ${_updateReviewMutation} } 
   }`],
  [ResourceKind.Order, `mutation updateOrderReview($oid:ID! $value:ReviewInputModel $option:ReviewOption $hasToggle:Boolean! $hasChange:Boolean!) {
    resource: reviewOrder(id:$oid) { ${_updateReviewMutation} } 
   }`],
  [ResourceKind.Attachment, `mutation updateAttachmentReview($oid:ID! $value:ReviewInputModel $option:ReviewOption $hasToggle:Boolean! $hasChange:Boolean!) {
    resource: reviewAttachment(id:$oid) { ${_updateReviewMutation} } 
   }`],
  [ResourceKind.Comment, `mutation updateCommentReview($oid:ID! $value:ReviewInputModel $option:ReviewOption $hasToggle:Boolean! $hasChange:Boolean!) {
    resource: reviewComment(id:$oid) { ${_updateReviewMutation} } 
   }`],
  [ResourceKind.Review, `mutation updateReviewReview($oid:ID! $value:ReviewInputModel $option:ReviewOption $hasToggle:Boolean! $hasChange:Boolean!) {
    resource: reviewReview(id:$oid) { ${_updateReviewMutation} } 
   }`],
]);

const _removeReview = `mutation removeReview($id:ID! $accessToken:String) {
  review(id:$id accessToken:$accessToken) {
    remove
    commit { ${_removeReviewFragment} } 
  } 
}`;

interface OperationResults {
  comment: { commit: CommentModel }
  review: { commit: ReviewModel }
  resource: {
    comment: { commit: CommentModel }
    review: { commit: ReviewModel }
  }
};

export const useCommentOperations = (): {
  addComment: (objectId: string, objectKind: ResourceKind, input: Partial<CommentInputModel>, setReadyState?: (value: ReadyState) => void) => Promise<DeepPartial<CommentModel>>,
  updateComment: (id: string, input: Partial<CommentInputModel>, setReadyState?: (value: ReadyState) => void, accessToken?: string) => Promise<DeepPartial<CommentModel>>,
  removeComment: (id: string, accessToken?: string) => Promise<DeepPartial<CommentModel>>,
  readyState: ReadyState
} => {
  const [readyState, _setReadyState] = useReadyState();
  const [mutation] = useMutation<OperationResults>();
  const addComment = async (objectId: string, objectKind: ResourceKind, input: Partial<CommentInputModel>, setReadyState?: (value: ReadyState) => void) => {
    const clientUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const value = { ...input, clientUri: input.clientUri || clientUri };
    const result = await mutation(_addComment.get(objectKind) || "", { oid: objectId, value }, { setReadyState: setReadyState || _setReadyState });
    return result.resource.comment.commit;
  }
  const updateComment = async (id: string, input: Partial<CommentInputModel>, setReadyState?: (value: ReadyState) => void, accessToken?: string) => {
    const clientUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const value = { ...input, clientUri: input.clientUri || clientUri };
    const result = await mutation(_updateComment, { id, value, accessToken }, { setReadyState: setReadyState || _setReadyState });
    return result.comment.commit;
  }
  const removeComment = async (id: string, accessToken?: string) => {
    const result = await mutation(_removeComment, { id, accessToken });
    return setDelete(result.comment.commit);
  }
  return { addComment, updateComment, removeComment, readyState };
}

export const useReviewOperations = (): {
  addReview: (objectId: string, objectKind: ResourceKind, input: Partial<ReviewInputModel>, setReadyState?: (value: ReadyState) => void, files?: File[]) => Promise<DeepPartial<ReviewModel>>,
  updateReview: (objectId: string, objectKind: ResourceKind, input: Partial<ReviewInputModel>, setReadyState?: (value: ReadyState) => void, files?: File[]) => Promise<DeepPartial<ReviewModel>>,
  toggleReviewOption: (objectId: string, objectKind: ResourceKind, input: ReviewOption, setReadyState?: (value: ReadyState) => void) => Promise<DeepPartial<ReviewModel>>,
  removeReview: (id: string, accessToken?: string) => Promise<DeepPartial<ReviewModel>>,
  readyState: ReadyState
} => {
  const [readyState, _setReadyState] = useReadyState();
  const [mutation] = useMutation<OperationResults>();
  const addReview = async (objectId: string, objectKind: ResourceKind, input: Partial<ReviewInputModel>, setReadyState?: (value: ReadyState) => void, files?: File[]) => {
    const clientUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const value = { ...input, clientUri: input.clientUri || clientUri };
    const result = await mutation(_addReview.get(objectKind) || "", { oid: objectId, value, hasChange: true, hasToggle: false }, { setReadyState: setReadyState || _setReadyState, files });
    return setReplace(result.resource.review.commit);
  }
  const updateReview = async (objectId: string, objectKind: ResourceKind, input: Partial<ReviewInputModel>, setReadyState?: (value: ReadyState) => void, files?: File[]) => {
    const clientUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const value = { ...input, clientUri: input.clientUri || clientUri };
    const result = await mutation(_updateReview.get(objectKind) || "", { oid: objectId, value, hasChange: true, hasToggle: false }, { setReadyState: setReadyState || _setReadyState, files });
    return setReplace(result.resource.review.commit);
  }
  const toggleReviewOption = async (objectId: string, objectKind: ResourceKind, input: ReviewOption, setReadyState?: (value: ReadyState) => void) => {
    const result = await mutation(_updateReview.get(objectKind) || "", { oid: objectId, value: { state: ResourceState.Resolved }, option: input, hasChange: true, hasToggle: true }, { setReadyState: setReadyState || _setReadyState });
    return setReplace(result.resource.review.commit);
  }
  const removeReview = async (id: string, accessToken?: string) => {
    const result = await mutation(_removeReview, { id, accessToken });
    return setDelete(result.review.commit);
  }
  return { addReview, updateReview, toggleReviewOption, removeReview, readyState }
}
