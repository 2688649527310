import React, { createContext, useContext, useState, ReactNode } from "react";
import { FormGroup, FormGroupProps, Label, Collapse, Button, TabContent, TabPane, CustomInput } from "reactstrap";
import { useTranslation } from "../hooks/TranslationProvider";
import { ErrorType } from "../types/api-graph-types";
import { Markdown } from "./CardMarkdownText";
import Handlebars from "handlebars";
import { nextSequence, ApiErrors } from "../hooks/ApiProvider";
import { useToggle } from "../hooks/CommonHooks";

export interface Validation {
  value?: string,
  number?: boolean,
  alphanumeric?: boolean,
  lower?: boolean,
  maxLength?: number,
  invalidCharacters?: RegExp
}

const Context = createContext<Validation>({});

const _urlRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
const _emailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
const _phoneRegex = /^\+?[0-9]{5,20}$/;
const _phonePunctRegex = /[() -]/g;

const _handebars = Handlebars.create();
_handebars.registerHelper("go", _ => `https://hkgo.in/000000`);
_handebars.registerHelper("button", label => `[${label}](https://hkgo.in/000000#__button_primary)`);
_handebars.registerHelper("comment-button", label => `[${label}](https://hkgo.in/000000#__button_primary)`);
_handebars.registerHelper("comment-buttons", function () {
  const result = [];
  for (let i = 0; i < arguments.length && typeof (arguments[i]) === "string"; i += 2) {
    result.push(`[${arguments[i]}](https://hkgo.in/000000#__button_${i === 0 ? "primary" : "light"})`);
  }
  return result.join(" ");
});
_handebars.registerHelper("comment-link", _ => `https://hkgo.in/000000`);

export const len = (value: string) => value ? value.length : 0;
export const between = (value: number, minValue: number, maxValue: number) => !isNaN(value) && value >= minValue && value <= maxValue;
export const trunc = (value: string, length: number) => len(value) < length ? value : value.substring(0, length);
export const isUrl = (value: string) => value?.trim().match(_urlRegex);
export const isEmail = (value: string) => value?.trim().match(_emailRegex) || false;
export const isPhone = (value: string) => (typeof (value) === "string" ? value : `${value}`)?.replace(_phonePunctRegex, "").match(_phoneRegex) || false;
export function isArrayEqual<T>(value: T[], expected: T[]): boolean {
  return Array.isArray(value) && Array.isArray(expected) && value.length === expected.length && expected.every((v, i) => value[i] === v);
}

export const useValidation = () => useContext(Context);

function _compile(value: string | undefined, preview: any) {
  try {
    return _handebars.compile(value || "")(preview || {})
  } catch (e) {
    return `${e}`
  }
}

export default ({ className, ref, label, value, addon, feedback, required, url, phone, phones, email, emails, number, alphanumeric, lower, invalidCharacters, length, minLength, maxLength, minValue, maxValue, help, openHelp, error, showPreview, preview, hideMessage, children, style, ...attrs }: {
  label?: string,
  required?: boolean,
  number?: boolean,
  alphanumeric?: boolean,
  addon?: ReactNode,
  value?: string,
  openHelp?: boolean,
  help?: string,
  length?: number,
  lower?: boolean,
  invalidCharacters?: RegExp,
  feedback?: string,
  maxLength?: number,
  minLength?: number,
  minValue?: number,
  maxValue?: number,
  showPreview?: boolean,
  hideMessage?: boolean,
  preview?: Object,
  email?: boolean,
  emails?: boolean,
  phone?: boolean,
  phones?: boolean,
  url?: boolean,
  error?: ErrorType,
  errors?: ReadonlyArray<ErrorType | undefined>,
} & FormGroupProps) => {
  const [t] = useTranslation();
  const [switchId] = useState(nextSequence());
  const [isOpenPreview, togglePreview] = useToggle();
  const curLength = length !== undefined ? length : len(value as string);
  //const requiredMessage = required && curLength == 0 ? "*" : "";
  const lengthMessage = !curLength ? ""
    : (minLength && curLength < minLength) ? `Too short` // `${minLength} characters minimum`
      : (maxLength && curLength > maxLength) ? `${curLength - maxLength} characters too long`
        : (maxLength && curLength > maxLength - 20) ? `${maxLength - curLength} characters left`
          : "";
  const valueMessage = !value ? ""
    : (minValue || maxValue) && isNaN(parseInt(value)) ? "Not valid"
      : minValue && parseInt(value) < minValue ? "Too low"
        : maxValue && parseInt(value) > maxValue ? "Too high"
          : url && !isUrl(value) ? "Not valid"
            : email && !isEmail(value) ? "Not valid"
              : emails && !value.split(",").every(isEmail) ? "Not valid"
                : phone && !isPhone(value) ? "Not valid"
                  : phones && !value.split(",").every(isPhone) ? "Not valid"
                    : "";
  const message = feedback || lengthMessage || valueMessage;
  const hasMessage = !hideMessage && !!message;
  const showLabel = label || hasMessage || showPreview || !!addon;
  return (
    <FormGroup style={style} className={`${error ? "has-danger" : ""} ${className}`} {...attrs} >
      {showLabel &&
        <div className="d-flex align-items-baseline">
          <Label className='small mr-auto'>
            {label || '\u00A0'}
            {required && <span className="text-warning">*</span>}
          </Label>
          {hasMessage && <small className="ml-3 text-muted">{message}</small>}
          {showPreview && <CustomInput id={switchId} disabled={!value && !isOpenPreview} className="ml-3 col-form-label-sm" type="switch" bsSize="sm" label="Preview" checked={isOpenPreview} onChange={togglePreview} />}
          {addon}
        </div>
      }
      {showPreview ?
        <TabContent activeTab={isOpenPreview ? "preview" : "edit"}>
          <TabPane tabId="edit">
            <Context.Provider value={{ value, maxLength, alphanumeric, number, lower, invalidCharacters }}>
              {children}
            </Context.Provider>
          </TabPane>
          <TabPane tabId="preview">
            <Markdown className="b-1 p-3 h-auto h-min-6" source={_compile(value, preview)} />
          </TabPane>
        </TabContent>
        :
        <Context.Provider value={{ value, maxLength, alphanumeric, number, lower, invalidCharacters }}>
          {children}
        </Context.Provider>
      }
      {error && <div className="invalid-feedback">{Array.isArray(error) ? error.map(t).join(", ") : typeof (error) === "string" ? t(error) : ""}</div>}
      {help &&
        <Collapse isOpen={openHelp || openHelp === undefined}>
          <small className="form-text text-muted text-justify text-hyphens">{help}</small>
        </Collapse>
      }
    </FormGroup>
  );
}
