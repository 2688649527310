import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { CardSubtitle, CardText, CardTitle, Col, Row } from "reactstrap";
import CiteLink from "../../components/CiteLink";
import { getHostname } from "../../components/ExternalLink";
import FormGroup, { between, isUrl, len } from "../../components/FormGroup";
import Highlight from "../../components/Highlight";
import Input from "../../components/Input";
import SearchLink from "../../components/SearchLink";
import { useMutation } from "../../hooks/ApiProvider";
import { DocumentType, useDocumentContext } from "../../hooks/DocumentProvider";
import { LegalResearchPrecedentInputModel, LegalResearchPrecedentModel, ReviewOption, TaskModel, TaskType } from "../../types/api-graph-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faExclamationTriangle);

export const Fragment = `... on LegalResearchPrecedentModel { 
  searchQuery
  title
  court
  strength
  year
  facts
  excerpt
  url
  citation
  status
}`;

const _updateTaskDetails = `mutation updateLegalResearchPrecedentTaskDetails($id:ID! $value:LegalResearchPrecedentInputModel) {
  task(id:$id) { 
    details { ... on LegalResearchPrecedentOperations { change(value:$value) } } 
    commit { details { ${Fragment} } }
  }
}`;

interface OperationResults {
  task: {
    commit: LegalResearchPrecedentModel
  }
};

const _courts = [
  "Supreme Court of India",

  // High courts
  "Allahabad High Court",
  "Andhra Pradesh High Court",
  "Bombay High Court",
  "Calcutta High Court",
  "Chhattisgarh High Court",
  "Delhi High Court",
  "Gauhati High Court",
  "Gujarat High Court",
  "Himachal Pradesh High Court",
  "Jammu and Kashmir High Court",
  "Jharkhand High Court",
  "Karnataka High Court",
  "Kerala High Court",
  "Madhya Pradesh High Court",
  "Madras High Court",
  "Manipur High Court",
  "Meghalaya High Court",
  "Orissa High Court",
  "Patna High Court",
  "Punjab and Haryana High Court",
  "Rajasthan High Court",
  "Sikkim High Court",
  "Telangana High Court",
  "Tripura High Court",
  "Uttarakhand High Court",

  // Quasi-judicial bodies
  "Central Information Commission",
  "Customs, Excise and Service Tax Appellate Tribunal",
  "Income Tax Appellate Tribunal",
  "Intellectual Property Appellate Tribunal",
  "National Company Law Tribunal",
  "Competition Commission of India",
  "National Consumer Disputes Redressal Commission",
  "National Human Rights Commission",
];

const _strengths = [
  "Single Bench",
  "Division Bench",
  "Division Bench (3)",
  "Full Bench",
  "Full Bench (5)",
  "Constitution Bench",
  "Constitution Bench (7)"
];

const _inaccessibleHostnames = [
  "advance.lexis.com",
  "d1.manupatra.in",
  "d2.manupatra.in",
  "docs.manupatra.in",
  "document.manupatra.com",
  "manupatra.com",
  "manupatrafast.com",
  "manupatrafast.in",
  "manupatrainternational.in",
  "mobile.manupatra.in",
  "newsroom.manupatra.com",
  "roundup.manupatra.in",
  "www.manupatra.co.in",
  "www.manupatra.com",
  "www.manupatra.net",
  "www.manupatrafast.com",
  "www.manupatrafast.in",
  "www.manupatrainternational.in",
];

function ToInput(value: LegalResearchPrecedentModel) {
  return {
    searchQuery: value.searchQuery || "",
    url: value.url || "",
    title: value.title || "",
    year: value.year || "",
    court: value.court || "",
    strength: value.strength || "",
    citation: value.citation || "",
    facts: value.facts || "",
    excerpt: value.excerpt || "",
    status: value.status || ""
  } as LegalResearchPrecedentInputModel;
}

export const CommentOptions: [number, number, ReviewOption, number, string][] = [
  [1, 2, ReviewOption.Empty, 0, ""],
  [1, 2, ReviewOption.Spam, -1, ""],
  [1, 2, ReviewOption.Offensive, -1, ""],
  [1, 2, ReviewOption.Plagiarised, -1, ""],
  [3, 8, ReviewOption.LegalResearchPrecedentCitationPoor, -1, ""],
  [3, 6, ReviewOption.LegalResearchPrecedentCourtPoor, 0, ""],
  [7, 10, ReviewOption.LegalResearchPrecedentCourtExcellent, 1, ""],
  [3, 8, ReviewOption.LegalResearchPrecedentSearchQueryPoor, 0, ""],
  [3, 8, ReviewOption.LegalResearchPrecedentUrlPoor, 0, ""],
  [3, 6, ReviewOption.LegalResearchPrecedentFactsPoor, -1, ""],
  [7, 8, ReviewOption.LegalResearchPrecedentFactsFair, -1, ""],
  [9, 10, ReviewOption.LegalResearchPrecedentFactsExcellent, 1, ""],
  [3, 6, ReviewOption.LegalResearchPrecedentExcerptPoor, -1, ""],
  [7, 8, ReviewOption.LegalResearchPrecedentExcerptFair, -1, ""],
  [9, 10, ReviewOption.LegalResearchPrecedentExcerptExcellent, 1, ""],
];

export const Is = (value: TaskModel): value is TaskModel & { details: LegalResearchPrecedentModel } =>
  value.type === TaskType.LegalResearchPrecedent

export const IsValid = (value: LegalResearchPrecedentModel) =>
  value.searchQuery && len(value.searchQuery) <= 140
  && (!value.url || isUrl(value.url))
  && value.title && between(len(value.title), 14, 140)
  && value.year && between(parseInt(value.year), 1200, 2200)
  && value.court && between(len(value.court), 14, 140)
  && value.citation && len(value.citation) <= 140
  && value.facts && between(len(value.facts), 70, 700)
  && value.excerpt && between(len(value.excerpt), 140, 1400)
  || false;

function ValidateUrl({ value }: { value: string }) {
  const hostname = isUrl(value) && getHostname(value);
  return hostname && _inaccessibleHostnames.some(h => hostname.indexOf(h) !== -1)
    ? <span className="small text-muted"><FontAwesomeIcon className="mr-1 text-warning align-baseline" icon="exclamation-triangle" /> Not accessible</span>
    : null;
}

export function InputText({ id, value, help, onUpdate }: {
  value: LegalResearchPrecedentModel,
  id: string,
  help?: boolean,
  onUpdate: (value: LegalResearchPrecedentModel) => void
}) {
  const input = ToInput(value || {});
  const [mutation] = useMutation<OperationResults>();
  const update = async (partial: Partial<LegalResearchPrecedentModel>) => {
    const value = { ...input, ...partial };
    onUpdate(value as LegalResearchPrecedentModel);
    await mutation(_updateTaskDetails, { id, value }, { debounce: 5000 });
  }
  return (
    <CardText tag='div'>
      <FormGroup label="Search Query" openHelp={help} help="Use Google or a law related search engine to find the most recent case-law relevant to this matter. Make sure the ruling has not since been overruled. If no case-laws apply, leave empty. Additional keywords like &quot;case&quot;, &quot;judgement&quot; may be useful. Ignore cases that simply casually mention the issue, and instead look for cases that have decided on the crux of the matter." required maxLength={140} value={input.searchQuery}>
        <Input type="text" onUpdate={searchQuery => update({ searchQuery })} />
      </FormGroup>
      <FormGroup label="Url" openHelp={help} help="URL of a publicly accessible webpage containing the relevant portion of the actual judgement. Do not link to commentary. Government websites are most reliable. Prefer sites that do not require a paid subscription. If the site is not publicly accessible, you can attach a public portion of the document below." required url value={input.url} addon={<ValidateUrl value={input.url} />}>
        <Input type="text" spellCheck={false} onUpdate={url => update({ url })} />
      </FormGroup>
      <FormGroup label="Title" openHelp={help} help="" required minLength={14} maxLength={140} value={input.title}>
        <Input type="text" onUpdate={title => update({ title })} />
      </FormGroup>
      <Row form>
        <Col sm={3}>
          <FormGroup label="Year" openHelp={help} help="Year of decision" required number minValue={1200} maxValue={2200} value={input.year}>
            <Input type="text" onUpdate={year => update({ year })} />
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup label="Court" openHelp={help} help="Select the court deciding on the matter. If the court is not in the list, you can type it in." required minLength={14} maxLength={140} value={input.court}>
            <Input list="court" type="text" onUpdate={court => update({ court })} />
            <datalist id="court">
              {_courts.map((_, i) => <option key={i} value={_} />)}
            </datalist>
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup label="Bench" openHelp={help} help="" required minLength={1} maxLength={140} value={input.strength}>
            <Input list="strength" type="text" onUpdate={strength => update({ strength })} />
            <datalist id="strength">
              {_strengths.map((_, i) => <option key={i} value={_} />)}
            </datalist>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup label="Citation" openHelp={help} help="Check the judgement text for its citation, or search for other cases that have cited this case using a print-journal citation like AIR, SCC, ILJ etc. Do not enter online citations here." required maxLength={140} value={input.citation}>
        <Input type="text" onUpdate={citation => update({ citation })} />
      </FormGroup>
      <FormGroup label="Facts" openHelp={help} help="Briefly summarize the facts of the case. Focus only on facts similar to the question. If too dissimilar, it is likely your case is not relevant." required minLength={70} maxLength={700} value={input.facts}>
        <Input type="textarea" rows={5} onUpdate={facts => update({ facts })} />
      </FormGroup>
      <FormGroup label="Excerpt" openHelp={help} help="Excerpt of article or section most relevant to the matter. Use Ctrl-F in the browser to search within the text of the judgement. Find the paragraph that is most relevant to the matter above. Your excerpt must directly support your opinion below." required minLength={140} maxLength={1400} value={input.excerpt}>
        <Input type="textarea" rows={5} onUpdate={excerpt => update({ excerpt })} />
      </FormGroup>
    </CardText>
  );
}

export default ({ value }: { value: LegalResearchPrecedentModel }) => {
  const [documentType] = useDocumentContext();
  const strength = value.strength ? `, ${value.strength}` : "";
  return (
    <>
      <CardSubtitle tag='h5'>
        <Highlight value={`${value.year} ${value.court}${strength}`} />
      </CardSubtitle>
      <CardTitle tag='h3' className="text-capitalize">
        <Highlight value={(value.title || "").toLowerCase()} />
      </CardTitle>
      <CardText className='text-justify'>
        <Highlight value={value.facts} />
      </CardText>
      <blockquote className='text-justify'>
        <Highlight value={value.excerpt} />
        <CiteLink title={value.title} href={value.url} citeKey={value.citation} />
      </blockquote>
      {documentType !== DocumentType.Document && <CardText><SearchLink query={value.searchQuery} /></CardText>}
    </>
  );
}
