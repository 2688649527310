import { library } from "@fortawesome/fontawesome-svg-core";
import { faBookmark as farBookmark, faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { faBookmark as fasBookmark, faChevronRight, faCircle, faCircleNotch, faEllipsisV, faInfoCircle, faTasks, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { MouseEvent, SVGProps, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import InputRange from "react-input-range";
import { Badge, Button, Col, Collapse, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, UncontrolledButtonDropdown, UncontrolledDropdown } from "reactstrap";
import { Badges as LanguageBadges, Fragment as LanguagesFragment } from "../cards/MyProfile/LanguagesCard";
import { Badges as LocationBadges, Fragment as LocationsFragment } from "../cards/MyProfile/LocationsCard";
import { Areas as ProfileAreas, Badges as TopicBadges, Fragment as TopicsFragment, Services as ProfileServices } from "../cards/MyProfile/TopicsCard";
import { Buttons as OrderButtons } from "../cards/OrderCard";
import { UserThumbnail, UserThumbnailFragment } from "../cards/PersonCard";
import ProductCard, { Fragment as ProductFragment } from "../cards/ProductCard";
import CardEventsList, { Fragment as EventListFragment } from "../components/CardEventsList";
import CardMarkdownText from "../components/CardMarkdownText";
import { CardBadge, CardBadges, CardButton, StarRatingDiv } from "../components/CardStatus";
import Cell from "../components/Cell";
import ContentPreviewDiv, { Fragment as ContentPreviewFragment } from "../components/ContentPreviewDiv";
import { CustomDropdown, CustomDropdownItem } from "../components/CustomDropdown";
import DatalistInput from "../components/DatalistInput";
import FormGroup, { isArrayEqual } from "../components/FormGroup";
import ValidatedInput from "../components/Input";
import LoadingProgress from "../components/LoadingProgress";
import { MoneyRangeInput } from "../components/MoneyRangeInput";
import SectionProgress from "../components/SectionProgress";
import Show, { useScopes } from "../components/Show";
import { TooltipContext, TooltipTarget, UncontrolledTooltip } from "../components/Tooltip";
import { byKey, cannotRead, DeepPartial, nextSequence, ReadyState, setDelete, setReplace, useMutation, useQuery, useReadyState } from "../hooks/ApiProvider";
import { useTrackedEvent } from "../hooks/AppInsightsProvider";
import { useClipboard } from "../hooks/ClipboardHook";
import { useToggle, useUpdatableState } from "../hooks/CommonHooks";
import { useConfirmation } from "../hooks/ConfirmationProvider";
import { compress, download, locationBase, preventDefault, stopPropagation, useFragment, useNavigation, useQueryString } from "../hooks/NavigationHook";
import { useSearch } from "../hooks/SearchProvider";
import { useTitle } from "../hooks/TitleHook";
import { useToasts } from "../hooks/ToastsProvider";
import { DateDisplayFormat, NumberDisplayFormat, toLocalDate, toUtcString, useTranslation } from "../hooks/TranslationProvider";
import OrdersInputModal, { DetailsFragment as OrderDetailsInputFragment, ImportModal as OrdersImportModal, updateProductOrdersQuery, View as OrderInputView } from "../modals/OrdersInputModal";
import ProductChooserModal from "../modals/ProductChooserModal";
import { CopyModal as ProductCopyModal, CreateLabelModal } from "../modals/ProductInputModal";
import SendMessageModal from "../modals/SendMessageModal";
import { MessageModal as ShareMessageModal } from "../modals/ShareModal";
import { AreaType, AttachmentModel, CityCode, EventModel, JobModel, LabelModel, MessageModel, MoneyModel, OrderDetailsModel, OrderModel, OrderStage, PersonModel, ProductDetailsInputModel, ProductModel, ProductType, ReferenceDataModel, ReferenceDataType, ResourceEvent, ResourceRole, ResourcesSummaryModel, ResourceState, RuleType, ServiceType, LanguageCode } from "../types/api-graph-types";
import LoadingController from "./LoadingController";

library.add(faCircle, faEllipsisV, faCircleNotch, fasBookmark, farBookmark, faCheckSquare, faSquare, faTimes, faTasks, faInfoCircle, faChevronRight);

const OrderFragment = `
  stage
  state
  expiresOn
  details { startOn endOn  ${OrderDetailsInputFragment} }
  actions {
    accept
    add
    allow
    cancel
    change
    decline
    deliver
    event
    interview
    offer
    refuse
    remove
    retract
    return
    review
    screen
    shortlist
    start
    submit
    undo
    uninterview
    unscreen
    unshortlist
    withdraw
    seen
    unseen
  }
  policy { addToken accessToken }
`;

const _getProduct = `query getProduct($id:ID! $count:Int $stages:[OrderStage] $query:String $interval:String $minDuration:String $gradOids:[ID] $gradInterval:String $sources:[ID!] $relatedIds:[ID!] $ratingAverage:Int $eduRatingAverage:Int $isSupplierCreated:Boolean $cities:[CityCode] $services:[ServiceType] $areas:[AreaType] $languages:[LanguageCode] $compensationAnnualAmountMin:Int $compensationAnnualAmountMax:Int $suggested:Boolean! $showDiscussion:Boolean! $showShares:Boolean!  $showActivity:Boolean!) {
  product(id:$id) {
    ${ProductFragment({ showStatus: true, showVariants: true, showButtons: true, showInput: true, showDiscussion: true, includeMessages: true })}
    publicUrl
    publicEmail
    details { 
      title 
      subtitle
      displayTitle
      duration 
      eduRatingDisplayMax
      labels { key color value }
    }
    policy { role }
    ordersSummary { 
      requestedCount
      stoppedCount 
      startedCount 
      submittedCount 
      shortlistedCount 
      screeningScheduledCount 
      interviewScheduledCount 
      offeredCount 
      offerExpiredCount 
      acceptedCount 
      deliveredCount 
      declinedCount
      deliveredCount
      returnedCount 
      removedCount
    }
    orders(first:$count deliveryInterval:$interval minDuration:$minDuration stages:$stages query:$query graduationOrgs:$gradOids graduationInterval:$gradInterval sources:$sources relatedIds:$relatedIds ratingAverage:$ratingAverage eduRatingAverage:$eduRatingAverage isSupplierCreated:$isSupplierCreated cities:$cities services:$services areas:$areas languages:$languages compensationAnnualAmountMin:$compensationAnnualAmountMin compensationAnnualAmountMax:$compensationAnnualAmountMax) {
      id
      kind
      cursor
      createdOn
      expiresOn
      stage 
      details {
        startOn
        startIn
        endOn
        ${OrderDetailsInputFragment}
        customerCompensation { currency amount period isPrivate }
        labels { key color value }
      }
      snippet(query:$query)
      policy { 
        role 
        accessToken @include(if:$suggested)
      }
      customer {
        name
        title
        email
        phone
        resume {
          updatedOn
          ${ContentPreviewFragment} 
          snippet(query:$query includeOperators:"inresume")
        }
        affiliation { organization { displayName nickname } organizationName  title }
        eduAffiliation { organization { displayName nickname } organizationName endOn cgpa { value maxValue} rank { value maxValue } }
        ${UserThumbnailFragment}
        topicPreferences { ${TopicsFragment} }
        locationPreferences { ${LocationsFragment} }
        languagePreferences { ${LanguagesFragment} }
        communicationTasksSummary: tasksSummary(types:LEGAL_MEETING_PARTICIPATION) {
          ratingCount
          ratingAverage
        }
        ratingCount
        ratingAverage
        jobsSummary @include(if:$suggested) {
          resolvedCount
          completedCount
        }
        researchTasksSummary: tasksSummary(types:LEGAL_RESEARCH_PRECEDENT) @include(if:$suggested) {
          ratingCount
          ratingAverage
        }
        analysisTasksSummary: tasksSummary(types:LEGAL_RESEARCH_CONCLUSION) @include(if:$suggested) {
          ratingCount
          ratingAverage
        }
      }
      ratingAverage
      ratingCount
      jobsSummary {
        resolvedCount
        completedCount
      }
      researchTasksSummary: tasksSummary(types:LEGAL_RESEARCH_PRECEDENT) {
        ratingCount
        ratingAverage
      }
      analysisTasksSummary: tasksSummary(types:LEGAL_RESEARCH_CONCLUSION) {
        ratingCount
        ratingAverage
      }
      attachments(types:[RESUME]) {
          updatedOn
          ${ContentPreviewFragment} 
          snippet(query:$query includeOperators:"inresume")
      }
      comments (flatten:true) @include(if:$showDiscussion) {
        createdOn
        updatedOn    
        author { name ${UserThumbnailFragment} }
        isEdited
        message
      }
      children @include(if:$showShares) {
        stage
        product {
          details { displayTitle }
          organization { logo }
          owner { name picture accentColor }
        }
      }
      events @include(if:$showActivity) { ${EventListFragment} }
      viewEvents: events(first:1 types:SUPPLIER_VIEWED_ORDER) { id }
      ${OrderFragment}
    }
  }
  me { products(first:2 states:[PROPOSED,IN_PROGRESS,RESOLVED] roles:[OWNER,CONTRIBUTOR]) { id }}
}`;

const _getOrganizations = `query getOrganizations($ids:[ID!]) {
  reference(type:ORGANIZATION_NAME relIds:$ids) { organization { id kind displayName nickname } }
}`;

const _exportOrders = `mutation exportOrders($id:ID! $stages:[OrderStage] $query:String $interval:String $minDuration:String $gradOids:[ID] $gradInterval:String) {
  product(id:$id) {
    orders(deliveryInterval:$interval minDuration:$minDuration states:[PROPOSED,IN_PROGRESS,RESOLVED,COMPLETED] stages:$stages query:$query graduationOrgs:$gradOids graduationInterval:$gradInterval) {
      export {
        dataUri
      }
    } 
  } 
}`;

const _reviewOrdersJobs = `mutation reviewOrdersJobs($pid:ID! $ids:[ID!]) {
  product(id:$pid) {
    orders(ids:$ids) {
      jobs {
        prioritize { id }
      }
    } 
  } 
}`;

const _addOrdersEvents = `mutation addOrdersEvents($pid:ID! $ids:[ID!] $type:ResourceEvent) {
  product(id:$pid) {
    orders(ids:$ids) {
      events(type:$type) { 
        track commit { id objectId } 
      }
    } 
  } 
}`;

const _removeOrdersEvents = `mutation removeOrdersEvents($pid:ID! $ids:[ID!] $type:ResourceEvent) {
  product(id:$pid) {
    orders(ids:$ids) {
      events(type:$type) {
        remove commit { id }
      }
    } 
  } 
}`;

const _addSuggestedOrdersEvents = `mutation addSuggestedOrdersEvents($type:ResourceEvent $tokens:[String]) {
  events: addEvents(type:$type accessTokens:$tokens) {
    track commit { id objectId }
  }
}`;

const _removeSuggestedOrdersEvents = `mutation removeEvents($ids:[ID!]) {
  events(ids:$ids) {
    remove commit { id }
  }
}`;

const _updateProductDetails = `mutation updateProductDetails($id:ID! $value:ProductDetailsInputModel) {
  product(id:$id) {
    details { change(value:$value) }
    commit { ${ProductFragment({ showInput: true })} }
  }
}`;

const _addLabels = `mutation addLabels($pid:ID $ids:[ID!] $key:String) {
  product(id:$pid) {
    orders(ids:$ids) {
      details { addLabel(key:$key) }
      commit { id details { labels { key color value } } }
    }
  }
}`

const _removeLabels = `mutation removeLabels($pid:ID $ids:[ID!] $key:String) {
  product(id:$pid) {
    orders(ids:$ids) {
      details { removeLabel(key:$key) }
      commit { id details { labels { key color value } } }
    }
  }
}`

type PersonExtensions = {
  researchTasksSummary: ResourcesSummaryModel
  analysisTasksSummary: ResourcesSummaryModel
  communicationTasksSummary: ResourcesSummaryModel
} & PersonModel;

type OrderExtensions = {
  researchTasksSummary: ResourcesSummaryModel
  analysisTasksSummary: ResourcesSummaryModel
  viewEvents: ReadonlyArray<EventModel>
  customer: PersonExtensions
} & OrderModel;

type ProductExtensions = {
  orders: ReadonlyArray<OrderExtensions>
} & ProductModel;

interface Result {
  product: ProductExtensions;
  reference: ReadonlyArray<ReferenceDataModel>;
  me: { products: ReadonlyArray<ProductModel> }
}

interface OperationResults {
  product: {
    commit: ProductModel
    order: {
      commit: OrderModel
    }
    orders: {
      export: AttachmentModel
      preview: MessageModel[]
      move: OrderModel[]
      merge: OrderModel
      commit: OrderModel[]
      jobs: {
        prioritize: JobModel[]
      }
      events: {
        commit: EventModel[]
      }
    }
  }
  events: {
    commit: EventModel[]
  }
}

type RatingType = "overall" | "motivation" | "research" | "analysis" | "communication";

enum View {
  Source = "SOURCE",
  All = "ALL",
  Applied = "APPLIED",
  Shortlisted = "SHORTLISTED",
  Offered = "OFFERED",
  Archived = "ARCHIVED",
  Other = "OTHER"
}

enum Property {
  Labels = "LABELS",
  Locations = "LOCATIONS",
  Topics = "TOPICS",
  Languages = "LANGUAGES",
  Discussion = "DISCUSSION",
  Activity = "ACTIVITY",
  AdditionalDetails = "ADDITIONAL_DETAILS",
  EduRating = "EDU_RATING",
  Compensation = "COMPENSATION",
  Shares = "SHARES"
}

enum Flag {
  Unseen = "UNSEEN",
  Shared = "SHARED"
}

const _stageColors = new Map<OrderStage, string>([
  [OrderStage.Refused, "light"],
  [OrderStage.Submitted, "light"],
  [OrderStage.Shortlisted, "dark"],
  [OrderStage.ScreeningScheduled, "dark"],
  [OrderStage.InterviewScheduled, "dark"],
  [OrderStage.Offered, "success"],
  [OrderStage.Accepted, "success"],
  [OrderStage.Delivered, "success"],
  [OrderStage.OfferExpired, "warning"],
  [OrderStage.Declined, "warning"],
  [OrderStage.Removed, "light"],
]);

function DateRange({ min, max, valueStart, valueEnd, width, height, ...props }: { min: string, max: string, valueStart?: string, valueEnd?: string } & SVGProps<SVGSVGElement>) {
  const [l, r, s, e] = [moment(min), moment(max), moment(valueStart), moment(valueEnd)];
  const ne = !valueEnd;
  const d = r.diff(l, "d");
  const xo = 4;
  const w = typeof (width) === "number" ? width - 2 * xo : 10;
  const h = typeof (height) === "number" ? height : 10;
  const x1 = s.isBefore(l) ? 0 : s.diff(l, "d") * w / d;
  const x2 = (ne || e.isAfter(r)) ? w : e.diff(l, "d") * w / d;
  return <svg width={width} height={height} {...props}>
    <line x1={xo} y1={0} x2={xo} y2={h} stroke="#dee2e6" />
    <line x1={w + xo} y1={0} x2={w + xo} y2={h} stroke="#dee2e6" />
    {s.isBefore(r) && (ne || e.isAfter(l))
      ? <line x1={x1 + xo} y1={h / 2} x2={x2 + xo} y2={h / 2} stroke="#868e96" />
      : null}
    {s.isBetween(l, r, "d", "[)")
      ? <ellipse cx={x1 + xo} cy={h / 2} rx={3} ry={3} fill="#868e96" stroke="#868e96" />
      : null}
    {e.isBetween(l, r, "d", "[)")
      ? <ellipse cx={x2 + xo} cy={h / 2} rx={3} ry={3} fill="#868e96" stroke="#868e96" />
      : null}
  </svg>;
}

const _allStages = [OrderStage.Stopped, OrderStage.Started, OrderStage.Submitted, OrderStage.Shortlisted, OrderStage.ScreeningScheduled, OrderStage.InterviewScheduled, OrderStage.Offered, OrderStage.OfferExpired, OrderStage.Accepted, OrderStage.Declined, OrderStage.Delivered, OrderStage.Returned, OrderStage.Removed] as OrderStage[];
const _sourceStages = [OrderStage.Suggested];
const _appliedStages = [OrderStage.Stopped, OrderStage.Started, OrderStage.Submitted];
const _shortlistedStages = [OrderStage.Shortlisted, OrderStage.ScreeningScheduled, OrderStage.InterviewScheduled];
const _offeredStages = [OrderStage.Offered, OrderStage.OfferExpired, OrderStage.Accepted, OrderStage.Declined, OrderStage.Delivered, OrderStage.Returned];
const _archivedStages = [OrderStage.Removed];
const _permitRoles = [ResourceRole.Owner, ResourceRole.Contributor, ResourceRole.Reader];

interface QueryString {
  during: string,
  labels: string,
  stages: string[],
  minDuration: string,
  graduationOrgs: string[],
  graduationOn: string,
  ratingAverage: number,
  eduRatingAverage: number,
  amount: string,
  cities: string[],
  areas: string[],
  services: string[],
  languages: string[],
  sources: string[],
  relatedIds: string[],
  sourcesLabel: string,
  flags: string[],
  show: string[]
}

interface Vars {
  count: number,
  ts: number,
  isSupplierCreated: boolean
}

const _initialQueryString: QueryString = {
  during: "",
  labels: "",
  stages: _appliedStages,
  minDuration: "",
  amount: "",
  graduationOrgs: [] as string[],
  graduationOn: "",
  ratingAverage: 0,
  eduRatingAverage: 0,
  cities: [],
  areas: [],
  services: [],
  languages: [],
  sources: [],
  relatedIds: [],
  sourcesLabel: "",
  flags: [],
  show: []
};

const _initialFragment = {
  show: ""
}

const _initialVars: Vars = {
  count: 10,
  ts: 0,
  isSupplierCreated: false
}

const _initialOrdersInputProps = {
  isOpen: false,
  eventKey: "" as string | undefined,
  values: [] as ReadonlyArray<OrderModel>,
  view: "add" as OrderInputView,
  previews: undefined as ReadonlyArray<MessageModel> | undefined
}

export function OrderCustomerCompensationSpan({ value, period }: {
  value: MoneyModel,
  period?: "P30D" | "P365D"
}) {
  const [t, , n] = useTranslation();
  const monthly = (period ?? value?.period) === "P30D";
  const yearly = (period ?? value?.period) === "P365D";
  const f = monthly ? 30 / moment.duration(value?.period ?? "P30D").asDays()
    : yearly ? 365 / moment.duration(value?.period ?? "P30D").asDays()
      : 1;
  const minAmount = (value?.amount ?? 0) * f;
  const variableFraction = value?.variableFraction ?? 20;
  const maxAmount = value?.amount && value.amount * f * (100 + variableFraction) / 100;
  return value?.amount && !value?.isPrivate && (
    <span className="small mr-1">
      expecting
      {" "}
      {n(minAmount, variableFraction ? NumberDisplayFormat.HumanRange : NumberDisplayFormat.Human, maxAmount, t(value.currency))}+
      {monthly ? " per month" : yearly ? " per year" : " per pay period"}
    </span>
  ) || null;
};

function ItemRow({ value, view, hash, className, search, ratingType, startOn, endOn, onView, showAvailability, showRating, showSnippet, selected, toggleSelection, query, onUpdate, onUpdateProduct, product }: {
  value: OrderExtensions,
  product: ProductModel,
  ratingType: RatingType,
  view: View,
  className?: string,
  onUpdate: (value: DeepPartial<OrderExtensions>) => void,
  onUpdateProduct: (value: DeepPartial<ProductModel>) => void,
  onView: (value: DeepPartial<OrderExtensions>) => void,
  startOn: string,
  hash: string,
  search: string,
  endOn: string,
  showRating: boolean,
  showAvailability: boolean,
  showSnippet: boolean,
  selected?: boolean,
  toggleSelection: (id: string, shiftModifier: boolean) => void,
  query: QueryString
}) {
  const [t, d] = useTranslation();
  const [navigate] = useNavigation();
  const track = useTrackedEvent();
  const [isOpenResume, _toggleResume] = useToggle();
  const toggleResume = track(_toggleResume, "VIEW ApplicantResume");
  const productLabels = new Map<string, LabelModel>(product.details.labels.map(_ => [_.key, _]));
  const labels = value.details.labels || [];
  const customer = value.customer || {};
  const jobsSummary = value.jobsSummary || {};
  const doneCount = jobsSummary.completedCount + jobsSummary.resolvedCount;
  const noteResolved = jobsSummary.resolvedCount > 0 ? "*" : "";
  const position = customer.affiliation;
  const edu = customer.eduAffiliation;
  const eduEnd = edu && d(edu.endOn, DateDisplayFormat.YearShort);
  const eduOrg = customer.eduAffiliation?.organization;
  const eduRating = edu && [
    edu.cgpa?.value > 0
      ? edu.cgpa.maxValue === 100
        ? `Percentage: ${edu.cgpa.value}%`
        : !edu.cgpa.maxValue || edu.cgpa.maxValue === 10
          ? `CGPA: ${edu.cgpa.value}`
          : `CGPA: ${edu.cgpa.value}/${edu.cgpa.maxValue}`
      : "",
    edu.rank?.value > 0
      ? edu.rank.maxValue > 0
        ? `Rank: ${edu.rank.value}/${edu.rank.maxValue}`
        : `Rank: ${edu.rank.value}`
      : "",
  ].join(" ").trim() || undefined;
  const isViewSource = view === View.Source;
  const colorLabels = labels.filter(_ => _.color || productLabels.get(_.key)?.color);
  const showAdditionalDetails = query.show.includes(Property.AdditionalDetails);
  const showLabels = query.show.includes(Property.Labels);
  const showEduRating = query.show.includes(Property.EduRating);
  const showCompensation = query.show.includes(Property.Compensation) && view !== View.Source;
  const showLocations = query.show.includes(Property.Locations);
  const showDiscussion = query.show.includes(Property.Discussion);
  const showShares = query.show.includes(Property.Shares) || !!value.children?.length;
  const showActivity = query.show.includes(Property.Activity);
  const showTopics = query.show.includes(Property.Topics);
  const showLanguages = query.show.includes(Property.Languages);
  const showCompleteness = !showRating;
  const showBadges = (showLabels && labels.length > 0)
    || (showLocations && customer.locationPreferences?.length > 0)
    || (showLanguages && customer.languagePreferences?.length > 0)
    || (showTopics && customer.topicPreferences?.length > 0);
  const resume = [customer.resume, ...(value.attachments || [])].filter(_ => !!_?.updatedOn).sort(byKey(_ => _.updatedOn, true))[0];
  const showResume = isOpenResume && !!resume;
  const stickyClassNames = showResume ? "sticky-top shadow" : "";
  const isViewed = value.viewEvents?.length > 0;

  return (
    <Row className="">
      <Col xs={12} className={`${className} bt-1 bl-1 br-1 pl-0 bg-white hover-container cursor-pointer hover-shadow d-flex align-items-top ${stickyClassNames}`}>
        <Button color="link" active={selected} className="d-flex flex-column toggle-left" onClick={e => toggleSelection(value.id, e.getModifierState("Shift"))}>
          <FontAwesomeIcon className={selected ? "mt-2" : "mt-2 hover-visible"} size="lg" icon={["far", selected ? "check-square" : "square"]} />
        </Button>
        <Row className="w-100 ml-0" onClick={isViewSource ? toggleResume : navigate(window.location.pathname + `/candidates/${value.id}` + hash)}>
          <Col sm={6} md={5} lg={6} className="pl-0">
            <Cell className="d-flex pl-0 w-100">
              <UserThumbnail className="mr-2" width={32} value={customer} />
              <div className="w-100">
                <span style={{ textTransform: "capitalize" }} className="mr-1">
                  {value.details.customerName || customer.name || customer.email || customer.phone}
                </span>
                {position ?
                  <span className='mr-1 text-muted small'>
                    {position.title && <span>{position.title} at </span>}
                    <span>{position.organization?.displayName || position.organizationName}</span>
                  </span>
                  : edu ?
                    <span className='mr-1 text-muted small' title={eduRating}>
                      {eduOrg?.nickname && <abbr title={eduOrg.displayName}>{eduOrg.nickname} {eduEnd}</abbr>}
                      {eduOrg?.displayName && !eduOrg?.nickname && <span>{eduOrg.displayName} {eduEnd}</span>}
                      {!eduOrg && <span>{edu.organizationName} {eduEnd}</span>}
                      {showEduRating && <span className="ml-1">{eduRating}</span>}
                    </span>
                    : null
                }
                {!isViewed && <FontAwesomeIcon transform="shrink-8" className="mr-1 text-primary" icon="circle" />}
                <div>
                  {value.details.startIn && <span className="small mr-1">Notice period {d(value.details.startIn, DateDisplayFormat.HumanDuration)}</span>}
                  {!value.details.startIn && value.details.startOn && <span className="small mr-1">Availability {d(value.details.startOn, DateDisplayFormat.HumanDateRange, value.details.endOn)}</span>}
                  {showCompensation && <OrderCustomerCompensationSpan period="P30D" value={value.details.customerCompensation} />}
                  {resume && <a className="small mr-1" href="#" title={`Updated ${d(resume.updatedOn, DateDisplayFormat.TimeAgo)}`} onClick={preventDefault(toggleResume)}>Résumé</a>}
                  {_stageColors.get(value.stage) && query.stages[0] !== value.stage && <Badge className="mr-1" color={_stageColors.get(value.stage)}>{t(value.stage)}</Badge>}
                  {value.stage === OrderStage.ScreeningScheduled && <span className="small text-muted mr-1">{d(value.details.screeningOn, DateDisplayFormat.HumanDateTime)}</span>}
                  {value.stage === OrderStage.InterviewScheduled && <span className="small text-muted mr-1">{d(value.details.interviewOn, DateDisplayFormat.HumanDateTime)}</span>}
                  {value.stage === OrderStage.Offered && value.details.offerExpiresOn && <span className="small text-muted mr-1">expires {d(value.details.offerExpiresOn, DateDisplayFormat.HumanDate)}</span>}
                </div>
                {showBadges &&
                  <CardBadges>
                    {showLabels && labels.filter(_ => !productLabels.get(_.key)?.isArchived && ((_.value || productLabels.get(_.key)?.value) || (_.color || productLabels.get(_.key)?.color))).map((_, i) => <CardBadge size="sm" className="cursor-pointer" style={{ backgroundColor: _.color || productLabels.get(_.key)?.color || '#f8f9fa', minWidth: "1.75rem" }} key={i} value={_.value || productLabels.get(_.key)?.value || '\u00A0'} />)}
                    {showLocations && <LocationBadges size="sm" values={customer.locationPreferences} />}
                    {showTopics && <TopicBadges size="sm" values={customer.topicPreferences} />}
                    {showLanguages && <LanguageBadges size="sm" values={customer.languagePreferences} />}
                  </CardBadges>
                }
                {showDiscussion && value.comments?.length > 0 &&
                  <ul className="mt-2 list-unstyled bt-1">
                    {Array.from(value.comments).sort(byKey(_ => _.createdOn)).map((_, i) =>
                      <li key={i} className="d-flex mt-1 bb-1">
                        <UserThumbnail title={`${_.author.name} ${_.isEdited ? "edited " : ""}${d(_.updatedOn, DateDisplayFormat.DateShortSameYear)}`} className="mr-2" width={16} value={_.author} />
                        <CardMarkdownText className="small" clampHeight="1.5em" source={_.message} />
                      </li>
                    )}
                  </ul>
                }
                {showAdditionalDetails && value.details?.customerDescription &&
                  <CardMarkdownText className="mt-2" justify hyphens clampHeight="6em" source={value.details.customerDescription} />
                }
                {showSnippet && (resume?.snippet || value.snippet) &&
                  <CardMarkdownText plain className="mt-2" justify hyphens source={resume?.snippet || value.snippet} />
                }
                {showShares && value.children?.length > 0 &&
                  <ul className="mt-2 list-unstyled bt-1">
                    {value.children.map((_, i) =>
                      <li key={i} className="d-flex align-items-baseline mt-1 bb-1">
                        {_.product.details.manufacturerOrg?.logo
                          ? <img src={_.product.details.manufacturerOrg.logo} width={16} height={16} className="mr-2" />
                          : <UserThumbnail className="mr-2" width={16} value={_.product.owner} />}
                        <div>
                          {_.product.details.displayTitle}
                          <Badge className="ml-1" color={_stageColors.get(_.stage)}>{t(_.stage)}</Badge>
                        </div>
                      </li>
                    )}
                  </ul>
                }
                {showActivity && value.events?.length > 0 &&
                  <CardEventsList values={value.events} />
                }
                {!showLabels && colorLabels.length > 0 &&
                  <div style={{ height: "1rem" }}>
                    {colorLabels.filter(_ => !productLabels.get(_.key)?.isArchived).map((_, i) => <Badge className="p-0 mr-2 mt-2" key={i} title={_.value || productLabels.get(_.key)?.value} style={{ backgroundColor: _.color || productLabels.get(_.key)?.color, maxHeight: 4, minWidth: "2.5rem" }}>{"\u00A0"}</Badge>)}
                  </div>
                }
              </div>
            </Cell>
          </Col>
          <Col xs={6} sm={3} md={2} className="text-muted pl-0">
            <Cell className="mt-3">
              {showRating && value.jobsSummary &&
                <TooltipContext>
                  <TooltipTarget>
                    {ratingType === "overall" && <StarRatingDiv value={value.ratingAverage} />}
                    {ratingType === "motivation" && <StarRatingDiv value={20.0 * ((1 / (1 + Math.exp(-(value.jobsSummary?.resolvedCount + value.jobsSummary?.completedCount) / 2))) - 0.5)} />}
                    {ratingType === "research" && <StarRatingDiv value={value.researchTasksSummary?.ratingAverage || customer.researchTasksSummary?.ratingAverage} />}
                    {ratingType === "analysis" && <StarRatingDiv value={value.analysisTasksSummary?.ratingAverage || customer.analysisTasksSummary?.ratingAverage} />}
                    {ratingType === "communication" && <StarRatingDiv value={customer.communicationTasksSummary?.ratingAverage} />}
                  </TooltipTarget>
                  <UncontrolledTooltip placement="bottom">
                    <ul className="text-right">
                      <StarRatingDiv title={`Overall${noteResolved}`} value={value.ratingAverage} />
                      <StarRatingDiv title="Motivation" value={20.0 * ((1 / (1 + Math.exp(-(value.jobsSummary?.resolvedCount + value.jobsSummary?.completedCount) / 2))) - 0.5)} />
                      <StarRatingDiv title="Research" value={value.researchTasksSummary?.ratingAverage || customer.researchTasksSummary?.ratingAverage} />
                      <StarRatingDiv title="Analysis" value={value.analysisTasksSummary?.ratingAverage || customer.analysisTasksSummary?.ratingAverage} />
                      <StarRatingDiv title="Communication" value={customer.communicationTasksSummary?.ratingAverage} />
                    </ul>
                    {doneCount < product.details.ordersJobsCount
                      ? <div className="mt-0">{doneCount}/{product.details.ordersJobsCount} assessments</div>
                      : <div className="mt-0">{doneCount} assessments</div>
                    }
                    {(value.state === ResourceState.Proposed || value.state === ResourceState.InProgress) && value.expiresOn &&
                      <div className="text-muted">
                        assessments due {d(value.expiresOn, DateDisplayFormat.TimeAgo)}
                      </div>
                    }
                    {value.ratingCount > 1 && <div>{value.ratingCount - 1} Reviews</div>}
                  </UncontrolledTooltip>
                </TooltipContext>
              }
              {showCompleteness &&
                <SectionProgress values={[
                  ["Résumé", !!resume],
                  ["Availability, Additional details", (!!value.details.startIn || !!value.details.startOn) && !!value.details.customerDescription],
                  ["CGPA and Rank", !!eduRating],
                  ["Locations, Interests", customer.locationPreferences.length > 0 && customer.topicPreferences.length > 0],
                  ["Compensation", value.details.customerCompensation?.amount > 0]
                ]} />
              }
            </Cell>
          </Col>
          <Col xs={6} sm={3} md={2} className="align-middle pl-0">
            <Cell className="mt-3">
              {showAvailability && value.details && value.details.startOn &&
                <TooltipContext>
                  <TooltipTarget>
                    <DateRange min={startOn || moment().format("YYYY-MM-DD")} max={endOn || moment().add(3, "M").format("YYYY-MM-DD")} valueStart={value.details.startOn} valueEnd={value.details.endOn}
                      height={22.5}
                      width={84.5} />
                  </TooltipTarget>
                  <UncontrolledTooltip placement="bottom">
                    {d(value.details.startOn, DateDisplayFormat.HumanDateRange, value.details.endOn)}
                  </UncontrolledTooltip>
                </TooltipContext>
              }
            </Cell>
          </Col>
          <Col md={3} lg={2} onClick={stopPropagation} className="pl-0">
            <Cell className="pl-0">
              <OrderButtons prependChildren fullWidth className="w-md-100" value={value} onUpdate={onUpdate} product={product} onUpdateProduct={onUpdateProduct}>
                {!isViewSource && <CardButton onClick={navigate(window.location.pathname + `/candidates/${value.id}` + hash)}>View</CardButton>}
              </OrderButtons>
            </Cell>
          </Col>
        </Row>
      </Col>
      {showResume &&
        <Col xs={12} className="bl-1 br-1 bg-white" onClick={stopPropagation}>
          <ContentPreviewDiv hideTextLayer pageClassName="bih-1" value={resume} onLoad={() => onView(value)} />
        </Col>
      }
    </Row>
  );
}

function RatingFilterDropdownItems({ query, updateQuery }: { query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  return (
    <>
      <CustomDropdownItem header>Rating</CustomDropdownItem>
      <CustomDropdownItem checked={query.ratingAverage === 9} onClick={() => updateQuery({ ratingAverage: 9 })}>Excellent</CustomDropdownItem>
      <CustomDropdownItem checked={query.ratingAverage === 7} onClick={() => updateQuery({ ratingAverage: 7 })}>Good</CustomDropdownItem>
      <CustomDropdownItem checked={query.ratingAverage === 0} onClick={() => updateQuery({ ratingAverage: 0 })}>All</CustomDropdownItem>
    </>
  );
}

function LocationFilterDropdownItems({ value, query, updateQuery }: { value: ProductExtensions, query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const [t] = useTranslation();
  const { location } = value.details;
  const { city } = location || {};
  const selected = query.cities.includes(city);
  const hasCity = city && city !== CityCode.Null;
  return (
    <>
      {false && <CustomDropdownItem className="mt-3" header>Location</CustomDropdownItem>}
      {hasCity && <CustomDropdownItem checked={selected} onClick={() => updateQuery({ cities: selected ? [] : [city] })}>{t(city)}</CustomDropdownItem>}
    </>
  );
}

function CompensationFilterDropdownItems({ query, updateQuery }: { query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  return (
    <div className="d-inline-block w-100">
      <CustomDropdownItem header>Compensation</CustomDropdownItem>
      <MoneyRangeInput max={150000} value={query.amount} onUpdate={amount => updateQuery({ amount, show: (amount ? [...query.show, Property.Compensation] : query.show.filter(_ => _ != Property.Compensation)) })} />
    </div>
  );
}

function CompensationFilterNavItems({ query, updateQuery }: { query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const [t, , n] = useTranslation();
  return (
    <NavItem className='mr-1'>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="primary">
          {n(parseInt(query.amount.split(":")[1].split("-")[0]) / 12, NumberDisplayFormat.HumanRange, parseInt(query.amount.split(":")[1].split("-")[1]) / 12, t(query.amount.split(":")[0]))} per month
        </DropdownToggle>
        <DropdownMenu style={{ width: 300 }}>
          <CustomDropdownItem header clear onClear={() => updateQuery({ amount: "", show: query.show.filter(_ => _ != Property.Compensation) })}>Compensation</CustomDropdownItem>
          <MoneyRangeInput max={150000} value={query.amount} onUpdate={amount => updateQuery({ amount, show: (amount ? [...query.show, Property.Compensation] : query.show.filter(_ => _ != Property.Compensation)) })} />
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </NavItem>
  );
}


const _defaultCities = [CityCode.InBlr, CityCode.InMaa, CityCode.InBom, CityCode.InDel];
const _moreCities = [CityCode.InDlNcr, CityCode.InHrGur, CityCode.InHyd];
function LocationsFilterDropdownItems({ value, query, updateQuery }: { value: ProductExtensions, query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const [t] = useTranslation();
  const [isOpen, toggle] = useToggle();
  const select = (city: CityCode) => (e: React.MouseEvent) => {
    const cities = query.cities.includes(city) ? query.cities.filter(c => c != city)
      : e.getModifierState("Control") ? [...query.cities, city]
        : [city];
    updateQuery({ cities });
  }
  const noCity = value.details.location?.city === CityCode.Null;
  return noCity ? (
    <>
      <CustomDropdownItem className="mt-3" header clear={query.cities.length > 0} onClear={() => updateQuery({ cities: [] })}>Location</CustomDropdownItem>
      {_defaultCities.map((city, i) => <CustomDropdownItem key={i} checked={query.cities.includes(city)} onClick={select(city)}>{t(city)}</CustomDropdownItem>)}
      <CustomDropdownItem isOpen={isOpen} toggle={false} onClick={toggle}>Additional locations</CustomDropdownItem>
      <Collapse isOpen={isOpen}>
        <div className="ml-3">
          {_moreCities.map((city, i) => <CustomDropdownItem key={i} checked={query.cities.includes(city)} onClick={select(city)}>{t(city)}</CustomDropdownItem>)}
        </div>
      </Collapse>
    </>
  ) : null;
}

const _defaultLanguages = [LanguageCode.Kn, LanguageCode.Gu, LanguageCode.Ta, LanguageCode.Ml, LanguageCode.Mr, LanguageCode.Te];
function LanguagesFilterDropdownItems({ value, query, updateQuery }: { value: ProductExtensions, query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const [t] = useTranslation();
  const [isOpen, toggle] = useToggle();
  const select = (lang: LanguageCode) => (e: React.MouseEvent) => {
    const languages = query.languages.includes(lang) ? query.languages.filter(l => l != lang)
      : e.getModifierState("Control") ? [...query.languages, lang]
        : [lang];
    updateQuery({ languages });
  }
  const languages = [...value.details.languages, ..._defaultLanguages.filter(l => !value.details.languages.includes(l))];
  return (
    <>
      <CustomDropdownItem className="mt-3" header clear={query.languages.length > 0} onClear={() => updateQuery({ languages: [] })}>Languages</CustomDropdownItem>
      {languages.slice(0, 2).map((lang, i) => <CustomDropdownItem key={i} checked={query.languages.includes(lang)} onClick={select(lang)}>{t(lang)}</CustomDropdownItem>)}
      <CustomDropdownItem isOpen={isOpen} toggle={false} onClick={toggle}>Additional languages</CustomDropdownItem>
      <Collapse isOpen={isOpen}>
        <div className="ml-3">
          {languages.slice(2).map((lang, i) => <CustomDropdownItem key={i} checked={query.languages.includes(lang)} onClick={select(lang)}>{t(lang)}</CustomDropdownItem>)}
        </div>
      </Collapse>
    </>
  );
}

const _defaultServices = [ServiceType.LegalServiceCorporateTransactional, ServiceType.LegalServiceLitigation, ServiceType.LegalServiceInHouseCounsel];
function TopicsFilterDropdownItems({ value, query, updateQuery }: { value: ProductExtensions, query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const [t] = useTranslation();
  const [isOpen, toggle] = useToggle();
  const { service: _service, areas: _areas } = value.details;
  const services = _service !== ServiceType.Null ? [_service] : _defaultServices;
  const areas = _areas;
  const hasServices = services.length > 0;
  const hasAreas = areas.length > 0;
  const toggleArea = (value: AreaType) => (e: React.MouseEvent) => {
    const areas = query.areas.includes(value) ? query.areas.filter(v => v !== value)
      : e.getModifierState("Control") ? [...query.areas, value]
        : [value];
    const services = e.getModifierState("Control") ? query.services : [];
    updateQuery({ areas, services })
  }
  const toggleService = (value: ServiceType) => (e: React.MouseEvent) => {
    const services = query.services.includes(value) ? query.services.filter(v => v !== value) : [value];
    const areas = e.getModifierState("Control") ? query.areas : [];
    updateQuery({ services, areas })
  }
  const functionPrefix = value.details.function.replace("FUNCTION", "");
  return (hasServices || hasAreas) ? (
    <>
      <CustomDropdownItem className="mt-3" header>Interests</CustomDropdownItem>
      {hasServices && services.map((service, i) => <CustomDropdownItem key={i} checked={query.services.includes(service)} onClick={toggleService(service)}>{t(service)}</CustomDropdownItem>)}
      {hasAreas && areas.map((area, i) => <CustomDropdownItem key={i} checked={query.areas.includes(area)} onClick={toggleArea(area)}>{t(area)}</CustomDropdownItem>)}
      <CustomDropdownItem isOpen={isOpen} toggle={false} onClick={toggle}>Additional interests</CustomDropdownItem>
      <Collapse isOpen={isOpen}>
        <div className="ml-3">
          {ProfileServices.filter(_ => _.startsWith(functionPrefix) && !services.includes(_)).map((service, i) => <CustomDropdownItem key={i} checked={query.services.includes(service)} onClick={toggleService(service)}>{t(service)}</CustomDropdownItem>)}
          {ProfileAreas.filter(_ => _.startsWith(functionPrefix) && !areas.includes(_)).map((area, i) => <CustomDropdownItem key={i} checked={query.areas.includes(area)} onClick={toggleArea(area)}>{t(area)}</CustomDropdownItem>)}
        </div>
      </Collapse>
    </>
  ) : null;
}

function GraduationFilterDropdownItems({ value, query, updateQuery }: { value: ProductExtensions, query: QueryString, updateQuery: (value: Partial<QueryString>) => void }) {
  const [input, setInput] = useState({ gradOrg: "" });
  const setGraduationOnFilter = (graduationOn: string) => updateQuery({ graduationOn });
  const setGraduationOrgsFilter = (id: string, name: string) => {
    if (id) {
      setInput({ ...input, gradOrg: "" });
      //toggleFilterDropdown(false);
      updateQuery({ graduationOrgs: [...query.graduationOrgs, id.toLowerCase()] });
    } else {
      setInput({ ...input, gradOrg: name });
    }
  }
  const day = moment().dayOfYear();
  const lastYearRange = `-P1Y${day - 1}D/-P${day}D`;
  const thisYearRange = `-P${day - 1}D/P${365 - day}D`;
  const nextYearRange = `P${367 - day}D/P${730 - day}D`;
  const isEntryLevel = value.details.type === ProductType.EmploymentEntryLevel;
  const isTrainingLevel = value.details.type === ProductType.EmploymentTrainingLevel;
  return (
    <>
      <CustomDropdownItem header>Graduation</CustomDropdownItem>
      {isTrainingLevel
        ? <>
          <CustomDropdownItem checked={query.graduationOn === thisYearRange} onClick={() => setGraduationOnFilter(thisYearRange)}>This year</CustomDropdownItem>
          <CustomDropdownItem checked={query.graduationOn === nextYearRange} onClick={() => setGraduationOnFilter(nextYearRange)}>Next year</CustomDropdownItem>
          <CustomDropdownItem checked={query.graduationOn === "P18M/P42M"} onClick={() => setGraduationOnFilter("P18M/P42M")}>In 2 to 3 years</CustomDropdownItem>
        </>
        :
        <>
          {isEntryLevel && <CustomDropdownItem checked={query.graduationOn === nextYearRange} onClick={() => setGraduationOnFilter(nextYearRange)}>Next year</CustomDropdownItem>}
          {isEntryLevel && <CustomDropdownItem checked={query.graduationOn === thisYearRange} onClick={() => setGraduationOnFilter(thisYearRange)}>This year</CustomDropdownItem>}
          <CustomDropdownItem checked={query.graduationOn === "-P30M/P0M"} onClick={() => setGraduationOnFilter("-P30M/P0M")}>Last 2 years</CustomDropdownItem>
          {!isEntryLevel && <CustomDropdownItem checked={query.graduationOn === "-P42M/-P18M"} onClick={() => setGraduationOnFilter("-P42M/-P18M")}>2 to 3 years ago</CustomDropdownItem>}
          {!isEntryLevel && <CustomDropdownItem checked={query.graduationOn === "-P66M/-P30M"} onClick={() => setGraduationOnFilter("-P66M/-P30M")}>3 to 5 years ago</CustomDropdownItem>}
        </>}
      <Form id="form-gradorg-2">
        <fieldset>
          <FormGroup className="px-3">
            <DatalistInput types={[ReferenceDataType.OrganizationName]} className="form-control" placeholder="Name of institution" placement="left-start" container="form-gradorg-2" value={input.gradOrg} onUpdate={setGraduationOrgsFilter} />
          </FormGroup>
        </fieldset>
      </Form>
    </>
  );
}

function PropertiesDisplayDropdownItems({ value, view, search, query, updateQuery }: {
  value: ProductExtensions,
  view: View,
  search: string,
  query: QueryString;
  updateQuery: (query: Partial<QueryString>) => void;
}) {
  const toggle = (property: Property) => (e: React.MouseEvent) => {
    const show = query.show.includes(property) ? query.show.filter(p => p != property)
      : e.getModifierState("Control") ? [...query.show, property]
        : [property];
    updateQuery({ show });
  }
  const hasSharesFilter = search.indexOf("is:shared") !== -1;
  const isSource = view == View.Source;
  const showCompensation = !!value.details.compensation?.amount;
  return (
    <>
      <CustomDropdownItem checked={query.show.includes(Property.AdditionalDetails)} onClick={toggle(Property.AdditionalDetails)}>Additional details</CustomDropdownItem>
      {!isSource && <CustomDropdownItem checked={query.show.includes(Property.Labels)} onClick={toggle(Property.Labels)}>Labels</CustomDropdownItem>}
      <CustomDropdownItem checked={query.show.includes(Property.EduRating)} onClick={toggle(Property.EduRating)}>CGPA and Rank</CustomDropdownItem>
      <CustomDropdownItem checked={query.show.includes(Property.Locations)} onClick={toggle(Property.Locations)}>Locations</CustomDropdownItem>
      <CustomDropdownItem checked={query.show.includes(Property.Topics)} onClick={toggle(Property.Topics)}>Interests</CustomDropdownItem>
      <CustomDropdownItem checked={query.show.includes(Property.Languages)} onClick={toggle(Property.Languages)}>Languages</CustomDropdownItem>
      {!isSource && showCompensation && <CustomDropdownItem checked={query.show.includes(Property.Compensation)} onClick={toggle(Property.Compensation)}>Compensation</CustomDropdownItem>}
      {!isSource && <CustomDropdownItem checked={query.show.includes(Property.Discussion)} onClick={toggle(Property.Discussion)}>Comments</CustomDropdownItem>}
      {!isSource && <CustomDropdownItem checked={query.show.includes(Property.Activity)} onClick={toggle(Property.Activity)}>Activity</CustomDropdownItem>}
      {!isSource && <CustomDropdownItem disabled={hasSharesFilter} checked={query.show.includes(Property.Shares) || hasSharesFilter} onClick={toggle(Property.Shares)}>Shares</CustomDropdownItem>}
    </>
  );
}

function AvailabilityFilterDropdownItems({ setAvailabilityFilter, startOn }: { setAvailabilityFilter: (startOn: string, endOn?: string | undefined, minDuration?: string | undefined) => void; startOn: string; }) {
  return (
    <>
      <DropdownItem header>Availability</DropdownItem>
      <DropdownItem onClick={() => setAvailabilityFilter(moment().format("YYYY-MM-DD"))}>Now</DropdownItem>
      <DropdownItem onClick={() => setAvailabilityFilter(moment().add(1, "M").format("YYYY-MM-01"))}>Next month</DropdownItem>
      <DropdownItem onClick={() => setAvailabilityFilter(moment().add(2, "M").format("YYYY-MM-01"))}>Month after next</DropdownItem>
      <Form>
        <fieldset>
          <FormGroup className="px-3">
            <Input type="date" className="form-control" value={startOn} onChange={e => setAvailabilityFilter(e.currentTarget.value)} />
          </FormGroup>
        </fieldset>
      </Form>
    </>
  );
}

function ClearFilterNavItems({ onClick }: { onClick: () => void }) {
  return (
    <NavItem>
      <NavLink color="link" href="#" onClick={onClick}>Clear filters</NavLink>
    </NavItem>
  );
}

function RatingFilterNavItems({ query, updateQuery }: { query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  return (
    <NavItem className='mr-1'>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="primary">
          {query.ratingAverage / 2}+ stars
            </DropdownToggle>
        <DropdownMenu>
          <CustomDropdownItem checked={query.ratingAverage === 9} onClick={() => updateQuery({ ratingAverage: 9 })}>Excellent</CustomDropdownItem>
          <CustomDropdownItem checked={query.ratingAverage === 7} onClick={() => updateQuery({ ratingAverage: 7 })}>Good</CustomDropdownItem>
          <CustomDropdownItem checked={query.ratingAverage === 0} onClick={() => updateQuery({ ratingAverage: 0 })}>All</CustomDropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </NavItem>
  );
}

function EduRatingFilterNavItems({ query, updateQuery, displayMax = 10 }: {
  query: QueryString;
  updateQuery: (query: Partial<QueryString>) => void;
  displayMax: number
}) {
  const [min, setMin] = useState(query.eduRatingAverage);
  const max = 10;
  const format = (value: number) => {
    const scaled = (value * displayMax / 10);
    return `${scaled.toFixed(scaled % 1 == 0 ? 0 : 1)}${displayMax === 100 ? '%' : ''}+`;
  }
  useEffect(() => setMin(query.eduRatingAverage), [query.eduRatingAverage]);
  return (
    <NavItem className='mr-1'>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="primary">{format(query.eduRatingAverage)} CGPA</DropdownToggle>
        <DropdownMenu>
          <CustomDropdownItem header clear onClear={() => updateQuery({ eduRatingAverage: 0, show: query.show.filter(_ => _ !== Property.EduRating) })}>Grades</CustomDropdownItem>
          <DropdownItem tag="div" toggle={false} className="pt-2 input-range-primary input-range-hide-max input-range-hide-min input-range-hide-high">
            <InputRange minValue={0} maxValue={max} step={1} value={{ min, max }} onChange={value => typeof (value) === "object" && setMin(Math.max(value.min, 1))} onChangeComplete={value => typeof (value) === "object" && updateQuery({ eduRatingAverage: value.min })} formatLabel={format} />
          </DropdownItem>
          <CustomDropdownItem checked={query.eduRatingAverage === 9} onClick={() => updateQuery({ eduRatingAverage: 9 })}>Excellent</CustomDropdownItem>
          <CustomDropdownItem checked={query.eduRatingAverage === 7} onClick={() => updateQuery({ eduRatingAverage: 7 })}>Good</CustomDropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </NavItem>
  );
}

function LocationFilterNavItems({ query, updateQuery }: { query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const [t] = useTranslation();
  return (
    <NavItem className='mr-1'>
      <Button color="primary" onClick={() => updateQuery({ cities: [] })}>
        {query.cities.length === 1 ? t(query.cities[0]) : `${query.cities.length} cities`}
      </Button>
    </NavItem>
  );
}

function LanguageFilterNavItems({ query, updateQuery }: { query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const [t] = useTranslation();
  return (
    <NavItem className='mr-1'>
      <Button color="primary" onClick={() => updateQuery({ languages: [] })}>
        {query.languages.length === 1 ? t(query.languages[0]) : `${query.languages.length} languages`}
      </Button>
    </NavItem>
  );
}

function FlagsFilterNavItems({ query, updateQuery }: { query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const toggle = (flag: Flag, prop?: Property) => (e: React.MouseEvent) => {
    const flags = query.flags.includes(flag) ? query.flags.filter(f => f != flag) : [flag];
    const show = query.show.filter(_ => _ !== prop);
    updateQuery({ flags, show });
  }
  const hasUnseen = query.flags.includes(Flag.Unseen);
  const hasShared = query.flags.includes(Flag.Shared);
  return (
    <NavItem className='mr-1'>
      {hasUnseen && <Button color="primary" onClick={toggle(Flag.Unseen)}>Unseen</Button>}
      {hasShared && <Button color="primary" onClick={toggle(Flag.Shared, Property.Shares)}>Shared</Button>}
    </NavItem>
  );
}

function SourcesFilterNavItems({ query, updateQuery }: { query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const hasRelatedIds = query.relatedIds?.length > 0;
  return (
    <>
      <NavItem className='mr-1'><Button color="primary">{query.sourcesLabel || `${query.sources?.length} source`}</Button></NavItem>
      {hasRelatedIds && <NavItem className='mr-1'><Button color="primary" onClick={() => updateQuery({ relatedIds: [] })}>{query.relatedIds.length} applicants</Button></NavItem>}
    </>
  );
}

function TopicsFilterNavItems({ value, query, updateQuery }: { value: ProductExtensions, query: QueryString; updateQuery: (query: Partial<QueryString>) => void; }) {
  const [t] = useTranslation();
  const { service: _service, areas: _areas } = value.details;
  const services = _service !== ServiceType.Null ? [_service] : _defaultServices;
  const areas = _areas;
  const hasServices = services.length > 0;
  const hasAreas = areas.length > 0;
  const toggleArea = (value: AreaType) => (e: React.MouseEvent) => {
    const areas = query.areas.includes(value) ? query.areas.filter(v => v !== value)
      : e.getModifierState("Control") ? [...query.areas, value]
        : [value];
    const services = e.getModifierState("Control") ? query.services : [];
    updateQuery({ areas, services })
  }
  const toggleService = (value: ServiceType) => (e: React.MouseEvent) => {
    const services = query.services.includes(value) ? query.services.filter(v => v !== value) : [value];
    const areas = e.getModifierState("Control") ? query.areas : [];
    updateQuery({ services, areas })
  }
  const functionPrefix = value.details.function.replace("FUNCTION", "");
  const count = query.areas.length + query.services.length;
  return (
    <NavItem className='mr-1'>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="primary">
          {count > 1 ? `${count} interests` : query.services?.[0] ? t(query.services[0]) : t(query.areas[0])}
        </DropdownToggle>
        <DropdownMenu>
          {hasServices && services.map((service, i) => <CustomDropdownItem key={i} checked={query.services.includes(service)} onClick={toggleService(service)}>{t(service)}</CustomDropdownItem>)}
          {ProfileServices.filter(_ => _.startsWith(functionPrefix) && !services.includes(_)).map((service, i) => <CustomDropdownItem key={i} checked={query.services.includes(service)} onClick={toggleService(service)}>{t(service)}</CustomDropdownItem>)}
          {hasAreas && areas.map((area, i) => <CustomDropdownItem key={i} checked={query.areas.includes(area)} onClick={toggleArea(area)}>{t(area)}</CustomDropdownItem>)}
          {ProfileAreas.filter(_ => _.startsWith(functionPrefix) && !areas.includes(_)).map((area, i) => <CustomDropdownItem key={i} checked={query.areas.includes(area)} onClick={toggleArea(area)}>{t(area)}</CustomDropdownItem>)}
          <DropdownItem className="text-muted small" disabled>Ctrl-click to select multiple.</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </NavItem>
  );
}

function AvailabilityFilterNavItems(d: (value?: any, format?: DateDisplayFormat | undefined, otherValue?: any, thenValue?: string | undefined, elseValue?: string | undefined) => string, startOn: string, endOn: string, setAvailabilityFilter: (startOn: string, endOn?: string | undefined, minDuration?: string | undefined) => void, queryString: QueryString) {
  return (
    <NavItem className='mr-1'>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="primary">
          {d(startOn, DateDisplayFormat.MonthDayRange, endOn)}
        </DropdownToggle>
        <DropdownMenu>
          <CustomDropdownItem header clear onClear={() => setAvailabilityFilter("")}>Availability</CustomDropdownItem>
          <DropdownItem onClick={() => setAvailabilityFilter(moment().format("YYYY-MM-DD"))}>Now</DropdownItem>
          <DropdownItem onClick={() => setAvailabilityFilter(moment().add(1, "M").format("YYYY-MM-01"))}>Next month</DropdownItem>
          <DropdownItem onClick={() => setAvailabilityFilter(moment().add(2, "M").format("YYYY-MM-01"))}>Month after next</DropdownItem>
          <DropdownItem divider />
          <Form>
            <fieldset>
              <FormGroup className="px-3" label='Duration'>
                <select className="form-control" value={queryString.minDuration} onChange={e => setAvailabilityFilter(startOn, endOn, e.currentTarget.value)}>
                  <option value="P1D">Any</option>
                  <option value="P7D">1+ weeks</option>
                  <option value="P14D">2+ weeks</option>
                  <option value="P21D">3+ weeks</option>
                </select>
              </FormGroup>
              <Row form>
                <Col>
                  <FormGroup label='Start'>
                    <Input type="date" className="form-control" value={startOn} onChange={e => setAvailabilityFilter(e.currentTarget.value)} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup label='End'>
                    <Input type="date" className="form-control" value={endOn} onChange={e => setAvailabilityFilter(startOn, e.currentTarget.value)} />
                  </FormGroup>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </NavItem>
  );
}

function LabelFilterNavItems({ value, labels, setLabels }: {
  value: ProductModel;
  labels: string,
  setLabels: (labels: string) => void
}) {
  return (
    <NavItem className='mr-1'>
      <UncontrolledButtonDropdown className="h-100">
        <DropdownToggle tag="div" color="link" className="d-flex align-items-center">
          {value.details.labels.map((_, i) => {
            const key = ` #${_.key} `; // spaces important since user query is appennded, and query tokenized on spaces
            const xkey = ` -#${_.key} `; // spaces important 
            const hasKey = labels.indexOf(key) > -1;
            const hasXKey = labels.indexOf(xkey) > -1;
            return (hasKey || hasXKey) ? (
              <CardBadge key={i} value={_.value} icon={hasXKey ? "minus" : undefined} className="cursor-pointer" size="inline" style={{ backgroundColor: _.color || '#f8f9fa', minWidth: '1rem' }} />
            ) : null;
          })}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="div" className="no-hover">
            <div className="d-flex flex-wrap">
              {value.details.labels.map((_, i) => {
                const key = ` #${_.key} `; // spaces important since user query is appennded, and query tokenized on spaces
                const xkey = ` -#${_.key} `; // spaces important 
                const hasFilter = labels.indexOf(key) > -1;
                const hasXFilter = labels.indexOf(xkey) > -1;
                const has = hasFilter || hasXFilter;
                const updateLabels = (e: React.MouseEvent<any, globalThis.MouseEvent>) => {
                  const nextLabels = e.getModifierState("Control") ? `${labels.replace(key, "").replace(xkey, "")}${key}`
                    : e.getModifierState("Alt") ? `${labels.replace(key, "").replace(xkey, "")}${xkey}`
                      : hasFilter ? labels.replace(key, "")
                        : hasXFilter ? labels.replace(xkey, "")
                          : key;
                  setLabels(nextLabels);
                }
                return (
                  <CardBadge key={i} value={_.value} size="sm" icon={has ? "times" : undefined} style={{ backgroundColor: _.color || '#f8f9fa' }} onClick={updateLabels} />
                );
              })}
            </div>
          </DropdownItem>
          <DropdownItem className="text-muted small" disabled>Ctrl-click to add label filter, Alt-click to exclude.</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </NavItem>
  );
}

function BadgeSpan({ label, count }: { label: string, count: number }) {
  const [, , n] = useTranslation();
  return (
    <>
      {label}
      <Show unless={!count}>
        <Badge className="ml-1" color="light">
          {n(count, NumberDisplayFormat.Human)}
        </Badge>
      </Show>
    </>
  );
}

function StageFilterNavItems({ value, view, query, updateQuery }: {
  value: ProductModel,
  query: QueryString,
  view: View,
  updateQuery: (value: Partial<QueryString>) => void;
}) {
  const viewAll = view === View.All;
  const viewArchived = view === View.Archived;
  const viewApplied = isArrayEqual(query.stages, _appliedStages);
  const viewInProgress = isArrayEqual(query.stages, [OrderStage.Stopped, OrderStage.Started]);
  const viewSubmitted = isArrayEqual(query.stages, [OrderStage.Submitted]);
  const viewShortlisted = isArrayEqual(query.stages, _shortlistedStages);
  const viewSaved = isArrayEqual(query.stages, [OrderStage.Shortlisted]);
  const viewScreening = isArrayEqual(query.stages, [OrderStage.ScreeningScheduled]);
  const viewInterviewing = isArrayEqual(query.stages, [OrderStage.InterviewScheduled]);
  const viewAnyShortlisted = viewShortlisted || viewSaved || viewScreening || viewInterviewing;
  const viewAnyApplied = viewApplied || viewInProgress || viewSubmitted;
  const hasMore = viewAll || viewArchived;

  const { count, requestedCount, removedCount, startedCount, stoppedCount, submittedCount, shortlistedCount, screeningScheduledCount, interviewScheduledCount, offeredCount, offerExpiredCount, acceptedCount, declinedCount, deliveredCount, returnedCount } = value.ordersSummary;
  const newCount = startedCount + stoppedCount + submittedCount;
  const inprogressCount = startedCount + stoppedCount;
  const anyShortlistedCount = shortlistedCount + screeningScheduledCount + interviewScheduledCount;
  const allOfferedCount = offeredCount + offerExpiredCount + acceptedCount + declinedCount + deliveredCount + returnedCount;

  const hasSourcing = value.details.ordersStages.includes(OrderStage.Suggested);
  const hasScreening = value.details.ordersStages.includes(OrderStage.ScreeningScheduled);
  const hasInterviewing = value.details.ordersStages.includes(OrderStage.InterviewScheduled);

  const showAppliedDropdown = viewAnyApplied;
  const showShortlistedDropdown = viewAnyShortlisted && (hasScreening || hasInterviewing);

  return (
    <>
      {hasSourcing && <NavItem><NavLink active={isArrayEqual(query.stages, _sourceStages)} href="#" onClick={() => updateQuery({ stages: _sourceStages })}>Source</NavLink></NavItem>}

      {requestedCount > 0 &&
        <NavItem>
          <NavLink active={isArrayEqual(query.stages, [OrderStage.Requested])} href="#" onClick={() => updateQuery({ stages: [OrderStage.Requested] })}>
            <BadgeSpan label="Waiting" count={requestedCount} />
          </NavLink>
        </NavItem>
      }

      {showAppliedDropdown ?
        <UncontrolledDropdown nav>
          <DropdownToggle nav caret className={viewAnyApplied ? "active" : ""}>
            {viewApplied && <BadgeSpan label="Applied" count={newCount} />}
            {viewInProgress && <BadgeSpan label="In-progress" count={inprogressCount} />}
            {viewSubmitted && <BadgeSpan label="Submitted" count={submittedCount} />}
          </DropdownToggle>
          <DropdownMenu>
            <CustomDropdownItem active={viewApplied} onClick={() => updateQuery({ stages: _appliedStages })} badge={newCount}>Applied</CustomDropdownItem>
            <CustomDropdownItem className="pl-4" active={viewInProgress} onClick={() => updateQuery({ stages: [OrderStage.Stopped, OrderStage.Started] })} badge={inprogressCount}>In-progress</CustomDropdownItem>
            <CustomDropdownItem className="pl-4" active={viewSubmitted} onClick={() => updateQuery({ stages: [OrderStage.Submitted] })} badge={submittedCount}>Submitted</CustomDropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        :
        <NavItem>
          <NavLink active={viewApplied} href="#" onClick={() => updateQuery({ stages: _appliedStages })}>
            <BadgeSpan label="Applied" count={newCount} />
          </NavLink>
        </NavItem>
      }

      {showShortlistedDropdown ?
        <UncontrolledDropdown nav>
          <DropdownToggle nav caret className={viewAnyShortlisted ? "active" : ""}>
            {viewShortlisted && <BadgeSpan label="Shortlisted" count={anyShortlistedCount} />}
            {viewSaved && <BadgeSpan label="Saved" count={shortlistedCount} />}
            {viewScreening && <BadgeSpan label="Screening" count={screeningScheduledCount} />}
            {viewInterviewing && <BadgeSpan label="Interviewing" count={interviewScheduledCount} />}
          </DropdownToggle>
          <DropdownMenu>
            <CustomDropdownItem active={viewShortlisted} onClick={() => updateQuery({ stages: _shortlistedStages })} badge={anyShortlistedCount}>Shortlisted</CustomDropdownItem>
            <CustomDropdownItem className="pl-4" active={viewSaved} onClick={() => updateQuery({ stages: [OrderStage.Shortlisted] })} badge={shortlistedCount}>Saved</CustomDropdownItem>
            {hasScreening && <CustomDropdownItem className="pl-4" active={viewScreening} onClick={() => updateQuery({ stages: [OrderStage.ScreeningScheduled] })} badge={screeningScheduledCount}>Screening</CustomDropdownItem>}
            {hasInterviewing && <CustomDropdownItem className="pl-4" active={viewInterviewing} onClick={() => updateQuery({ stages: [OrderStage.InterviewScheduled] })} badge={interviewScheduledCount}>Interviewing</CustomDropdownItem>}
          </DropdownMenu>
        </UncontrolledDropdown>
        :
        <NavItem>
          <NavLink active={isArrayEqual(query.stages, _shortlistedStages)} href="#" onClick={() => updateQuery({ stages: _shortlistedStages })}>
            <BadgeSpan label="Shortlisted" count={anyShortlistedCount} />
          </NavLink>
        </NavItem>
      }
      <NavItem className={hasMore ? undefined : "mr-3"}>
        <NavLink active={isArrayEqual(query.stages, _offeredStages)} href="#" onClick={() => updateQuery({ stages: _offeredStages })}>
          <BadgeSpan label="Offered" count={allOfferedCount} />
        </NavLink>
      </NavItem>
      {viewAll &&
        <NavItem className="mr-3">
          <NavLink active={viewAll} href="#" onClick={() => updateQuery({ stages: _allStages })}>
            <BadgeSpan label="All" count={count} />
          </NavLink>
        </NavItem>
      }
      {viewArchived &&
        <NavItem className="mr-3">
          <NavLink active={viewArchived} href="#" onClick={() => updateQuery({ stages: _archivedStages })}>
            <BadgeSpan label="Archived" count={removedCount} />
          </NavLink>
        </NavItem>
      }
    </>
  );
}

function GraduationFilterNavItems({ value, query, updateQuery, }: { updateQuery: (value: Partial<QueryString>) => void, query: QueryString, value: ProductExtensions }) {
  const [, d] = useTranslation();
  const [graduationOnAfter, graduationOnBefore] = query.graduationOn.split("/", 2).map(_ => moment().add(moment.duration(_)).format("YYYY-MM-DD"));
  const [range, setRange] = useState({ min: moment(graduationOnAfter).year(), max: moment(graduationOnBefore).year() });
  const hasGraduationOnFilter = !!query.graduationOn;
  const hasGraduationOrgsFilter = query.graduationOrgs?.length > 0;
  const [input, setInput] = useState({ gradOrg: "" });
  const [filterGradOrg] = useQuery<Result>(hasGraduationOrgsFilter ? _getOrganizations : "", { ids: query.graduationOrgs });
  const reset = () => {
    setRange({ min: moment(graduationOnAfter).year(), max: moment(graduationOnBefore).year() });
  }
  useEffect(reset, [query.graduationOn]);
  const setGraduationOnFilter = (graduationOn: string) => updateQuery({ graduationOn });
  const setGraduationOrgsFilter = (id: string, name: string) => {
    if (id) {
      setInput({ ...input, gradOrg: "" });
      //toggleFilterDropdown(false);
      updateQuery({ graduationOrgs: [...query.graduationOrgs, id.toLowerCase()] });
    } else {
      setInput({ ...input, gradOrg: name });
    }
  }
  const updateRange = ({ min, max }: { min: number, max: number }) => {
    const from = moment(`${min}-01-02`).diff(moment(), "d");
    const to = moment(`${max}-12-30`).diff(moment(), "d");
    const dFrom = from < 0 ? `-P${-from}D` : `P${from}D`
    const dTo = to < 0 ? `-P${-to}D` : `P${to}D`
    setGraduationOnFilter(`${dFrom}/${dTo}`);
  }
  const now = moment();
  const day = now.dayOfYear();
  const year = now.year();
  const lastYearRange = `-P1Y${day - 2}D/-P${day}D`;
  const thisYearRange = `-P${day - 1}D/P${365 - day}D`;
  const nextYearRange = `P${367 - day}D/P${730 - day}D`;

  const organizations = filterGradOrg?.reference.map(_ => _.organization) ?? [];
  return (
    <NavItem className='mr-1'>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="primary">
          {(organizations.length ?? 0) === 0 ? null
            : organizations.length === 1 ? organizations[0].nickname || organizations[0].displayName
              : `${organizations.length} organizations`}
          {" "}
          {hasGraduationOnFilter && d(graduationOnAfter, DateDisplayFormat.YearRange, graduationOnBefore) || null}
        </DropdownToggle>
        <DropdownMenu>
          <CustomDropdownItem header clear={hasGraduationOnFilter} onClear={() => updateQuery({ graduationOn: "" })}>Graduation</CustomDropdownItem>
          <DropdownItem tag="div" toggle={false} className={`pt-2 input-range-primary input-range-hide-max input-range-hide-min ${range.min === range.max ? "input-range-hide-track-active" : ""}`}>
            <InputRange minValue={year - 5} maxValue={year + 5} step={1} allowSameValues value={range.min === range.max ? range.min : range} onChange={value => setRange(typeof (value) === "object" ? value : { min: value, max: value })} onChangeComplete={value => updateRange(typeof (value) === "object" ? value : { min: value, max: value })} formatLabel={_ => `'${_ % 100}`} />
          </DropdownItem>
          {value.details.type === ProductType.EmploymentTrainingLevel
            ? <>
              <CustomDropdownItem checked={query.graduationOn === thisYearRange} onClick={() => setGraduationOnFilter(thisYearRange)}>This year</CustomDropdownItem>
              <CustomDropdownItem checked={query.graduationOn === nextYearRange} onClick={() => setGraduationOnFilter(nextYearRange)}>Next year</CustomDropdownItem>
              <CustomDropdownItem checked={query.graduationOn === "P18M/P42M"} onClick={() => setGraduationOnFilter("P18M/P42M")}>In 2 to 3 years</CustomDropdownItem>
            </>
            : <>
              <CustomDropdownItem checked={query.graduationOn === nextYearRange} onClick={() => setGraduationOnFilter(nextYearRange)}>Next year</CustomDropdownItem>
              <CustomDropdownItem checked={query.graduationOn === thisYearRange} onClick={() => setGraduationOnFilter(thisYearRange)}>This year</CustomDropdownItem>
              <CustomDropdownItem checked={query.graduationOn === lastYearRange} onClick={() => setGraduationOnFilter(lastYearRange)}>Last year</CustomDropdownItem>
              <CustomDropdownItem checked={query.graduationOn === "-P30M/P0M"} onClick={() => setGraduationOnFilter("-P30M/P0M")}>Last 2 years</CustomDropdownItem>
              <CustomDropdownItem checked={query.graduationOn === "-P42M/-P18M"} onClick={() => setGraduationOnFilter("-P42M/-P18M")}>2 to 3 years ago</CustomDropdownItem>
              <CustomDropdownItem checked={query.graduationOn === "-P66M/-P30M"} onClick={() => setGraduationOnFilter("-P66M/-P30M")}>3 to 5 years ago</CustomDropdownItem>
            </>
          }
          <CustomDropdownItem header clear={hasGraduationOrgsFilter} onClear={() => updateQuery({ graduationOrgs: [] })}>Institution</CustomDropdownItem>
          {organizations.map((_, i) =>
            <CustomDropdownItem key={i} title={_.nickname ? _.displayName : undefined} checked onClick={() => updateQuery({ graduationOrgs: query.graduationOrgs.filter(o => o !== _.id) })}>
              {_.nickname || _.displayName}
            </CustomDropdownItem>
          )}
          <Form id="form-gradorg-1">
            <fieldset>
              <FormGroup className="px-3">
                <DatalistInput types={[ReferenceDataType.OrganizationName]} placement="right-start" container="form-gradorg-1" exclude={query.graduationOrgs} className="form-control" placeholder="Name of institution" value={input.gradOrg} onUpdate={setGraduationOrgsFilter} />
              </FormGroup>
            </fieldset>
          </Form>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </NavItem>
  );
}

function OtherFiltersDropdownItems({ view, query, vars, updateQuery, updateVars }: {
  query: QueryString,
  vars: Vars;
  updateQuery: (value: Partial<QueryString>) => void;
  updateVars: (vars: DeepPartial<Vars>) => void;
  view: View
}) {
  const isViewArchived = view === View.Archived;
  const isViewAll = view === View.All;
  const isViewSource = view === View.Source;
  const hasUnseen = query.flags.includes(Flag.Unseen);
  const hasShared = query.flags.includes(Flag.Shared);
  return (
    <>
      <CustomDropdownItem checked={isViewAll} onClick={() => updateQuery({ stages: _allStages })}>All</CustomDropdownItem>
      {!isViewSource && <CustomDropdownItem checked={hasUnseen} onClick={() => updateQuery({ flags: hasUnseen ? query.flags.filter(_ => _ !== Flag.Unseen) : [...query.flags, Flag.Unseen] })}>Unseen</CustomDropdownItem>}
      {!isViewSource && <CustomDropdownItem checked={hasShared} onClick={() => updateQuery({ flags: hasShared ? query.flags.filter(_ => _ !== Flag.Shared) : [...query.flags, Flag.Shared], show: [...query.show.filter(_ => _ !== Property.Shares), Property.Shares] })}>Shared</CustomDropdownItem>}
      <CustomDropdownItem checked={query.eduRatingAverage === 7} onClick={() => updateQuery({ eduRatingAverage: 7, show: Array.from(new Set([...query.show, Property.EduRating])) })}>Good grades</CustomDropdownItem>
      <CustomDropdownItem checked={isViewArchived} onClick={() => updateQuery({ stages: _archivedStages })}>Archived</CustomDropdownItem>
    </>
  );
}

function LabelActionDropdownItems({ value, hide, showCreate, toggleCreate, selectedOrders, onUpdate }: {
  value: ProductModel;
  hide?: boolean;
  showCreate?: boolean;
  toggleCreate?: () => void;
  selectedOrders: OrderModel[];
  onUpdate: (product: DeepPartial<ProductModel>) => void;
}) {
  const [mutation] = useMutation<OperationResults>();
  const confirm = useConfirmation();
  const addLabel = async (key: string) => {
    const ids = selectedOrders.map(_ => _.id);
    const result = await mutation(_addLabels, { pid: value.id, ids, key });
    onUpdate({ orders: result.product.orders.commit });
  }
  const removeLabel = async (key: string) => {
    const ids = selectedOrders.map(_ => _.id);
    const result = await mutation(_removeLabels, { pid: value.id, ids, key });
    onUpdate({ orders: result.product.orders.commit.map(o => ({ id: o.id, details: setReplace({ labels: o.details.labels } as OrderDetailsModel) } as OrderModel)) });
  }
  const needConfirm = selectedOrders.length >= 5;
  return value.details.labels.length > 0 && !hide ? (
    <>
      <DropdownItem header>Label</DropdownItem>
      <DropdownItem tag="div" className="no-hover">
        <div className="d-flex flex-wrap">
          {value.details.labels.filter(_ => !_.isArchived).map((_, i) => {
            const allHasLabel = selectedOrders.length > 0 && selectedOrders.every(o => o.details.labels.some(l => l.key === _.key));
            const addOrRemoveLabel = () => allHasLabel ? removeLabel(_.key) : addLabel(_.key);
            return (
              <Button key={i} className="mr-1 mt-1 b-0 p-0" title={_.value} style={{ backgroundColor: _.color || '#f8f9fa', minWidth: 20, minHeight: 20 }} onClick={needConfirm ? confirm(addOrRemoveLabel, `Are you sure you want to update labels on ${selectedOrders.length} applicants?`) : addOrRemoveLabel}>
                {allHasLabel && <FontAwesomeIcon icon="times" />}
              </Button>
            );
          })}
          {showCreate &&
            <Button className="mr-1 mt-1 b-0 p-0" color="link" style={{ minWidth: 20, minHeight: 20 }} onClick={toggleCreate}>
              <FontAwesomeIcon icon="plus" />
            </Button>
          }
        </div>
      </DropdownItem>
      <DropdownItem divider />
    </>
  ) : null;
}

function LabelFilterDropdownItems({ value, labels, setLabels }: {
  value: ProductModel;
  labels: string,
  setLabels: (labels: string) => void
}) {
  return value.details.labels.length > 0 ? (
    <DropdownItem tag="div" className="no-hover">
      <div className="d-flex flex-wrap">
        {value.details.labels.filter(_ => !_.isArchived).map((_, i) => {
          const key = ` #${_.key} `; // spaces important since user query is appennded, and query tokenized on spaces
          const xkey = ` -#${_.key} `; // spaces important
          const hasFilter = labels.indexOf(key) > -1;
          const hasXFilter = labels.indexOf(xkey) > -1;
          const updateLabels = (e: React.MouseEvent<any, globalThis.MouseEvent>) => {
            const nextLabels = e.getModifierState("Control") ? `${labels.replace(key, "").replace(xkey, "")}${key}`
              : e.getModifierState("Alt") ? `${labels.replace(key, "").replace(xkey, "")}${xkey}`
                : hasFilter ? labels.replace(key, "")
                  : hasXFilter ? labels.replace(xkey, "")
                    : key;
            setLabels(nextLabels);
          }
          return (
            <Button key={i} className="mr-1 mt-1 b-0 p-0" title={_.value} style={{ backgroundColor: _.color || '#f8f9fa', minWidth: 20, minHeight: 20 }} onClick={updateLabels}>
              {hasFilter && <FontAwesomeIcon icon="times" />}
              {hasXFilter && <FontAwesomeIcon icon="times" />}
            </Button>
          );
        })}
      </div>
    </DropdownItem>
  ) : null;
}

function HeaderRow(isSelectionAll: boolean, setSelectionNone: () => void, setSelectionAll: () => void, ratingType: string, setRatingType: React.Dispatch<React.SetStateAction<RatingType>>, showAvailability: boolean, showRating: boolean, showCompleteness: boolean, isLoading: boolean) {
  return (
    <Row className="hover-container">
      <Col className="bt-1 bl-1 br-1 bg-white d-flex align-items-top">
        <Button disabled={isLoading} color="link" className={`pl-0 ${isSelectionAll || isLoading ? "" : "hover-visible"}`} onClick={isSelectionAll ? setSelectionNone : setSelectionAll}>
          <FontAwesomeIcon size="lg" icon={["far", isSelectionAll ? "check-square" : "square"]} />
        </Button>
        <Row className="w-100 ml-0">
          <Col xs={4} sm={6} md={5} lg={6} className="pl-0">
            <Cell className="d-flex pl-0">
              <b>Name</b>
            </Cell>
          </Col>
          <Col xs={4} sm={3} md={2} className="text-nowrap pl-0">
            <Cell>
              {showRating &&
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" color="link">
                    {ratingType === "overall" && <b>Rating</b>}
                    {ratingType === "motivation" && <b>Motivation</b>}
                    {ratingType === "research" && <b>Research</b>}
                    {ratingType === "analysis" && <b>Analysis</b>}
                    {ratingType === "communication" && <b>Communication</b>}
                    <FontAwesomeIcon className="ml-2 hover-visible" icon={["fas", "ellipsis-v"]} />
                  </DropdownToggle>
                  <DropdownMenu>
                    <CustomDropdownItem checked={ratingType === "overall"} onClick={() => setRatingType("overall")}>Overall</CustomDropdownItem>
                    <CustomDropdownItem checked={ratingType === "motivation"} onClick={() => setRatingType("motivation")}>Motivation</CustomDropdownItem>
                    <CustomDropdownItem checked={ratingType === "research"} onClick={() => setRatingType("research")}>Research</CustomDropdownItem>
                    <CustomDropdownItem checked={ratingType === "analysis"} onClick={() => setRatingType("analysis")}>Analysis</CustomDropdownItem>
                    <CustomDropdownItem checked={ratingType === "communication"} onClick={() => setRatingType("communication")}>Communication</CustomDropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              {showCompleteness && <b>Profile</b>}
            </Cell>
          </Col>
          <Col xs={4} sm={3} md={2} className="text-nowrap pl-0">
            <Cell>
              {showAvailability && <b>Availability</b>}
            </Cell>
          </Col>
          <Col md={2} className="text-nowrap pl-0 text-center">
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function PollModal({ baseUrl, isOpen, toggle }: {
  baseUrl: string,
  isOpen?: boolean,
  toggle?: () => void,
}) {
  const [, setClipboard] = useClipboard();
  const track = useTrackedEvent();
  const [, go] = useNavigation();
  const [uid] = useState(nextSequence());
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState<string[]>([""]);
  const [expireOn, setExpireOn] = useState<string>();
  const [multiple, setMultiple] = useState(false);
  const reset = () => {
    setQuestion("");
    setOptions([""]);
    setMultiple(false);
    setExpireOn(undefined);
  }
  useEffect(reset, [isOpen]);

  const updateOption = (i: number) => (value: string) => {
    setOptions(options.map((_, j) => i === j ? value : _));
  }
  const oneline = question.length < 50 && question.indexOf("\n") === -1;
  const rows = oneline ? 1 : Math.max(3, question.split("\n").length + 1);

  const getLink = () => {
    const form = { q: [{ q: question, o: options, m: multiple ? 1 : 0 }], x: expireOn };
    const link = `${baseUrl}#comment_form=${compress(JSON.stringify(form))}`;
    return link;
  }
  const endOfDay = (value: Date | null) => {
    value && value.setHours(23, 59, 59, 999);
    return value;
  }
  const isValid = !!question && options.length > 0 && options.every(o => !!o);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Create poll link</ModalHeader>
      <ModalBody>
        <FormGroup label="Question" className="mb-2" value={question} maxLength={330}
          addon={options.length > 1 && <CustomInput id={`${uid}.0`} className="col-form-label-sm" bsSize="sm" checked={multiple} type="switch" label="Allow multiple selections" onChange={() => setMultiple(!multiple)} /> || undefined}>
          <ValidatedInput type="textarea" rows={rows} placeholder="Enter your question here" onUpdate={setQuestion} />
        </FormGroup>
        {options.map((_, i) =>
          <FormGroup key={i} className="mb-2 d-flex align-items-center">
            <CustomInput id={`${uid}.1.${i}`} key={i} checked={false} type={multiple ? "checkbox" : "radio"} readOnly />
            <ValidatedInput placeholder="Enter an option here" value={_} onUpdate={updateOption(i)} />
            {options.length > 1 && <Button className="ml-auto" color="link" size="sm" onClick={() => setOptions(options.filter((_, j) => i !== j))}>Remove</Button>}
          </FormGroup>
        )}
        <FormGroup className="d-flex mt-3 align-items-center">
          <CustomInput id={`${uid}.2`} checked={!!expireOn} type="switch" label="Expire poll" onChange={() => setExpireOn(!expireOn ? moment(endOfDay(new Date()) || "").add(1, "w").utc().toISOString() : undefined)} />
          <Button className="ml-auto" color="link" onClick={() => setOptions([...options, ""])}>Add</Button>
        </FormGroup>
        <Collapse isOpen={!!expireOn}>
          <FormGroup label="Expire date">
            <DatePicker className="form-control" minDate={new Date()} selected={toLocalDate(expireOn)} dateFormat="MMMM d, yyyy" onChange={v => setExpireOn(toUtcString(endOfDay(v)) || "")} />
          </FormGroup>
        </Collapse>
      </ModalBody>
      <ModalFooter>
        <UncontrolledDropdown direction="up">
          <DropdownToggle id="send-link" color="link">
            <FontAwesomeIcon className="m-1" icon="link" />
          </DropdownToggle>
          <DropdownMenu>
            <CustomDropdownItem disabled={!isValid} event="SHARE ListingPoll [WhatsApp]; FINISH ListingPoll" icon={["fab", "whatsapp"]} onClick={() => go(`https://api.whatsapp.com/send?text=${encodeURIComponent(getLink())}&lang=en`)}>Share via WhatsApp</CustomDropdownItem>
            <CustomDropdownItem disabled={!isValid} event="SHARE ListingPoll [Facebook]; FINISH ListingPoll" icon={["fab", "facebook"]} onClick={() => go(`http://www.facebook.com/sharer.php?u=${encodeURIComponent(getLink())}`)}>Share via Facebook</CustomDropdownItem>
            <CustomDropdownItem disabled={!isValid} event="SHARE ListingPoll [LinkedIn]; FINISH ListingPoll" icon={["fab", "linkedin"]} onClick={() => go(`http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(getLink())}`)}>Share via LinkedIn</CustomDropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <Button className="ml-auto" color="link" onClick={toggle}>Cancel</Button>
        <Button disabled={!isValid} color="primary" onClick={track(() => setClipboard(getLink()), "SHARE ListingPoll [Clipboard]; FINISH ListingPoll")}>Copy</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ({ id }: { id: string }) => {
  const [, d] = useTranslation();
  const [, go] = useNavigation();
  const [readyState, setReadyState] = useReadyState();
  const [search, setSearch] = useSearch();
  const [query, setQuery, updateQuery] = useQueryString(_initialQueryString);
  const [fragment, setFragment] = useFragment(_initialFragment);
  const [ordersInputProps, setOrdersInputProps] = useState(_initialOrdersInputProps);
  const [selectAllFromResult, setSelectAllFromResult] = useState<number>();
  const [startOn, endOn] = query.during.split("/", 2);
  const [graduationOnAfter, graduationOnBefore] = query.graduationOn.split("/", 2).map(_ => moment().add(moment.duration(_)).format("YYYY-MM-DD"));
  const hasAvailabilityFilter = !!startOn;
  const hasSearchFilter = !!search;
  const hasStagesFilter = query.stages?.length > 0;
  const hasGraduationOrgsFilter = query.graduationOrgs?.length > 0;
  const hasGraduationOnFilter = !!query.graduationOn;
  const hasGraduationFilter = hasGraduationOrgsFilter || hasGraduationOnFilter;
  const hasSourcesFilter = query.sources?.length > 0;
  const hasRelatedIdsFilter = query.relatedIds?.length > 0;
  const [vars, setVars, updateVars] = useUpdatableState(_initialVars);
  const hasRatingFilter = query.ratingAverage > 0;
  const hasEduRatingFilter = query.eduRatingAverage > 0;
  const hasLocationFilter = query.cities.length > 0;
  const hasLanguageFilter = query.languages.length > 0;
  const hasTopicsFilter = query.areas.length > 0 || query.services.length > 0;
  const hasLabelsFilter = !!query.labels;
  const hasCompensationFilter = !!query.amount;
  const hasUnseenFilter = query.flags.includes(Flag.Unseen);
  const hasSharedFilter = query.flags.includes(Flag.Shared);
  const [selection, setSelection] = useState(new Set<string>());
  const [lastSelectedId, setLastSelectedId] = useState("");
  const [isOpenMessage, toggleMessage] = useToggle();
  const [isOpenShare, toggleShare] = useToggle();
  const [isOpenViewDropdown, toggleViewDropdown] = useToggle();
  const [isOpenFilterDropdown, toggleFilterDropdown] = useToggle();
  const [isOpenMoveToProductChooser, toggleMoveToProductChooser] = useToggle();
  const [isOpenCopyToProductChooser, toggleCopyToProductChooser] = useToggle();
  const [isOpenPoll, togglePoll] = useToggle();
  const [isOpenImport, toggleImport] = useToggle();
  const [isOpenCreateLabel, toggleCreateLabel] = useToggle();
  const [isOpenCopy, toggleCopy] = useToggle();
  const [hasScope] = useScopes();
  const [, setClipboard] = useClipboard();
  const [, setToast] = useToasts();
  const [ratingType, setRatingType] = useState("overall" as RatingType);
  const getMyProductVars = {
    ...vars,
    id,
    suggested: query.stages.includes(OrderStage.Suggested),
    showDiscussion: query.show.includes(Property.Discussion),
    showActivity: query.show.includes(Property.Activity),
    // showResume: query.show.includes(Property.Resume),
    showShares: query.show.includes(Property.Shares) || search.indexOf("is:shared") !== -1,
    stages: hasStagesFilter ? query.stages : null,
    interval: hasAvailabilityFilter ? `${startOn}/${endOn}` : null,
    minDuration: hasAvailabilityFilter ? query.minDuration : null,
    compensationAnnualAmountMin: hasCompensationFilter ? parseInt(query.amount.split(":")[1].split("-")[0]) : null,
    compensationAnnualAmountMax: hasCompensationFilter ? parseInt(query.amount.split(":")[1].split("-")[1]) : null,
    query: search + query.labels
      + (hasUnseenFilter ? " is:unseen" : "")
      + (hasSharedFilter ? " is:shared" : ""),
    gradOids: hasGraduationOrgsFilter ? query.graduationOrgs : null,
    gradInterval: hasGraduationOnFilter ? `${graduationOnAfter}/${graduationOnBefore}` : null,
    ratingAverage: query.ratingAverage,
    eduRatingAverage: query.eduRatingAverage,
    sources: query.sources,
    cities: query.cities,
    areas: query.areas,
    services: query.services,
    languages: query.languages,
    relatedIds: query.stages.includes(OrderStage.Suggested) ? query.relatedIds : []
  };
  const [result, updateResult, isLoading, , resultProperties] = useQuery<Result>(_getProduct, getMyProductVars);
  const [mutation] = useMutation<OperationResults>();
  const orders = result?.product?.orders || [];
  useEffect(() => {
    setSelection(new Set(result && orders.map(_ => _.id).filter(_ => selection.has(_))));
  }, [result]);

  useEffect(() => {
    if (selectAllFromResult !== undefined && resultProperties !== undefined && resultProperties.index === selectAllFromResult) {
      setSelection(new Set(orders.map(_ => _.id)));
      setSelectAllFromResult(undefined);
    }
  }, [selectAllFromResult, resultProperties?.index]);

  const value = result?.product;
  useTitle(value && value.details.displayTitle);
  if (!result || !value || !_permitRoles.includes(value.policy?.role)) {
    return <LoadingController isLoading={isLoading} noResult={!value || !_permitRoles.includes(value.policy?.role)} />;
  }

  const setAvailabilityFilter = (startOn: string, endOn?: string, minDuration?: string) => {
    const duration = moment.duration(value.details.duration === "P65535D" ? "P180D" : value.details.duration || "P28D");
    endOn = endOn || moment(startOn).add(duration).format("YYYY-MM-DD");
    minDuration = minDuration || "P1D" || moment.duration(Math.round(duration.asDays() - (duration.asDays() / 2)), "d").toIsoString();
    updateQuery({
      during: `${startOn}/${endOn}`,
      minDuration
    });
  }
  const setLabelQuery = (labels: string) => updateQuery({ labels });
  const clearFilters = () => {
    setSearch("");
    setLabelQuery("");
    setQuery({ ..._initialQueryString, stages: query.stages });
    setVars(_initialVars);
  }

  const isSelectionAll = vars.count > orders.length && orders.length > 0 && orders.every(_ => selection.has(_.id));
  const toggleSelection = (id: string, shiftModifier: boolean) => {
    const nextSelection = new Set(selection);
    if (shiftModifier) {
      const fromIndex = orders.findIndex(_ => _.id === lastSelectedId);
      const toIndex = orders.findIndex(_ => _.id === id);
      const [from, to] = fromIndex < toIndex ? [fromIndex, toIndex] : [toIndex, fromIndex];
      orders.slice(from, to + 1).map(_ => nextSelection.add(_.id));
      const textselection = document.getSelection();
      textselection?.empty();
    } else {
      nextSelection.has(id) ? nextSelection.delete(id) : nextSelection.add(id);
      setSelectAllFromResult(undefined);
    }
    setSelection(nextSelection);
    setLastSelectedId(id);
  }
  const setSelectionAll = () => {
    setVars({ ...vars, count: 1024 });
    setSelectAllFromResult((resultProperties?.index ?? 0) + (vars.count === 1024 ? 0 : 1));
    setSelection(new Set(orders.map(_ => _.id)));
  }
  const setSelectionNone = () => {
    setSelection(new Set());
    setSelectAllFromResult(undefined);
  }
  const previewOrChangeOrders = (view: OrderInputView, key?: string) => async () => {
    const query = updateProductOrdersQuery[view];
    const ids = selectedOrders.map(_ => _.id);
    const tokens = selectedOrders.map(_ => _.policy?.addToken).filter(_ => _);
    const previewResult = await mutation(query, { ids, tokens, pid: id, key, preview: true })
    const previews = previewResult.product.orders.preview;
    if (previews.length > 0) {
      setOrdersInputProps({ view, values: selectedOrders, isOpen: true, eventKey: key, previews });
    } else {
      const result = await mutation(query, { ids, tokens, pid: id, key, preview: false })
      if (view === "add" || view === "addShortlist") {
        update({ ...result.product.commit, orders: selectedOrders.map(setDelete) });
        const collection = result.product.orders.commit.map(_ => _.id).join(",");
        const hash = `#collection=${collection}&up=${encodeURI(`${window.location.pathname}${window.location.search}`)}`;
        go(window.location.pathname + `/candidates/${result.product.orders.commit[0].id}` + hash)
      } else {
        update({ ...result.product.commit, orders: result.product.orders.commit });
      }
    }
  }
  const change = async (details: Partial<ProductDetailsInputModel>) => {
    const result = await mutation(_updateProductDetails, { id: value.id, value: details });
    update(result.product.commit);
  }
  const updatedLabel = async (value: DeepPartial<ProductModel>, label: LabelModel) => {
    update(value);
    const ids = selectedOrders.map(_ => _.id);
    const result = await mutation(_addLabels, { pid: value.id, ids, key: label.key });
    update({ orders: result.product.orders.commit });
  }
  const exportOrders = async () => {
    const result = await mutation(_exportOrders, getMyProductVars, { setReadyState });
    download(result.product.orders.export.dataUri, `${value.details.displayTitle}.xlsx`.replace(/[^A-Za-z0-9.]+/g, "-"));
  }
  const moveOrders = async (pid_to: string) => {
    const ids = selectedOrders.map(_ => _.id);
    const result = await mutation(updateProductOrdersQuery.move, { pid: id, ids, pid_to }, { setReadyState });
    update({ orders: result.product.orders.move.map(setDelete) });
  }
  const copyOrders = async (pid_to: string) => {
    const ids = selectedOrders.map(_ => _.id);
    const result = await mutation(updateProductOrdersQuery.copy, { pid: id, ids, pid_to }, { setReadyState });
  }
  const mergeOrders = async () => {
    const ids = selectedOrders.map(_ => _.id);
    const result = await mutation(updateProductOrdersQuery.merge, { pid: id, ids }, { setReadyState });
    const merged = result.product.orders.merge;
    update({ orders: [merged, ...selectedOrders.filter(_ => _.id !== merged.id).map(setDelete)] });
  }
  const addOrdersViewEvent = async () => {
    const ids = selectedOrders.map(_ => _.id);
    const result = await mutation(_addOrdersEvents, { pid: id, ids, type: ResourceEvent.SupplierViewedOrder }, { setReadyState });
    const orders = result.product.orders.events.commit.map(e => ({ id: e.objectId, viewEvents: [e] }) as Partial<OrderExtensions>);
    update({ orders });
  }
  const removeOrdersViewEvent = async () => {
    const ids = selectedOrders.map(_ => _.id);
    const result = await mutation(_removeOrdersEvents, { pid: id, ids, type: ResourceEvent.SupplierViewedOrder }, { setReadyState });
    update({ orders: selectedOrders.map(_ => setReplace({ id: _.id, viewEvents: [] } as Partial<OrderExtensions>)) });
  }
  const addSuggestedOrdersViewEvent = async () => {
    const tokens = selectedOrders.map(_ => _.policy?.accessToken).filter(_ => _);
    const result = await mutation(_addSuggestedOrdersEvents, { tokens, type: ResourceEvent.SupplierViewedOrder }, { setReadyState });
    const orders = result.events.commit.map(e => ({ id: e.objectId, viewEvents: [e] }) as Partial<OrderExtensions>);
    update({ orders });
  }
  const removeSuggestedOrdersViewEvent = async () => {
    const ids = selectedOrders.flatMap(_ => _.viewEvents.map(e => e.id));
    const result = await mutation(_removeSuggestedOrdersEvents, { ids }, { setReadyState });
    update({ orders: selectedOrders.map(_ => setReplace({ id: _.id, viewEvents: [] } as Partial<OrderExtensions>)) });
  }
  const reviewOrdersJobs = async () => {
    const ids = selectedOrders.map(_ => _.id);
    const result = await mutation(_reviewOrdersJobs, { pid: id, ids }, { setReadyState });
    setToast({ message: `Prioritized ${result.product.orders.jobs.prioritize.length} reviews` }, 5000);
  }
  const getMore = (e: MouseEvent) => updateVars({ count: e.getModifierState("Shift") ? 1024 : vars.count * 2 });
  const selectedOrders = orders.filter(_ => selection.has(_.id));
  const openAdd = () => {
    setOrdersInputProps({ view: "add", values: [], isOpen: true, eventKey: undefined, previews: undefined });
  }
  const trackView = async (value: OrderExtensions) => {
    if (value.stage === OrderStage.Suggested) {
      const tokens = [value.policy?.accessToken];
      const result = await mutation(_addSuggestedOrdersEvents, { tokens, type: ResourceEvent.SupplierViewedOrder }, { setReadyState });
      const orders = result.events.commit.map(e => ({ id: e.objectId, viewEvents: [e] }) as Partial<OrderExtensions>);
      update({ orders });
    }
  }

  const showAvailability = !!hasAvailabilityFilter;
  const view = isArrayEqual(query.stages, _sourceStages) ? View.Source
    : isArrayEqual(query.stages, _allStages) ? View.All
      : isArrayEqual(query.stages, _appliedStages) ? View.Applied
        : isArrayEqual(query.stages, _shortlistedStages) ? View.Shortlisted
          : isArrayEqual(query.stages, _offeredStages) ? View.Offered
            : isArrayEqual(query.stages, _archivedStages) ? View.Archived
              : View.Other;
  const isOwner = value.policy.role === ResourceRole.Owner;
  const isContributor = isOwner || value.policy.role === ResourceRole.Contributor;
  const isReader = isContributor || value.policy.role === ResourceRole.Reader;
  const canMerge = selection.size > 0 && selectedOrders.every(_ => _.customer.id == selectedOrders[0].customer.id);

  const update = (product: DeepPartial<ProductExtensions & ProductModel>) => updateResult({ product });

  const showSnippet = !!search;
  const showCompensation = !!value.details.compensation?.amount;
  const showRating = value.details.ordersJobsMaxCount !== 0;
  const showCompleteness = !showRating;
  const openInput = fragment.show === "edit";
  const hasFlagsFilter = hasUnseenFilter || hasSharedFilter;
  const hasFilters = hasFlagsFilter || hasSearchFilter || hasCompensationFilter || hasSourcesFilter || hasRelatedIdsFilter || hasGraduationFilter || hasAvailabilityFilter || hasLocationFilter || hasRatingFilter || hasEduRatingFilter || hasTopicsFilter || hasLabelsFilter;
  const hasMoreProducts = result.me.products?.length > 1;
  const showStagesFilter = isContributor;
  const showOrders = isReader;
  const hashCollection = orders.map(_ => _.id).join(",");
  const hashUp = encodeURI(`${window.location.pathname}${window.location.search}`);
  const hashAfter = orders.length >= vars.count ? `&after=${orders[orders.length - 1].cursor}` : "";
  const hash = `#collection=${hashCollection}&up=${hashUp}${hashAfter}`;
  const customActions = value.details.rules?.filter(_ => _.isEnabled && _.type === RuleType.EventTriggered && _.eventsTrigger.includes(ResourceEvent.CustomEvent));

  return (
    <>
      <Row className="mt-3">
        <Col>
          <ProductCard key={value.id} value={value} onUpdate={update} showDetailedDescription showVariants variantsPath="/my/listings" hideVariant={cannotRead} showNotice showStatus showMenu showButtons showShare showDetailsMenu showInput={isContributor} openInput={openInput}
            appendDetails={
              <>
                {value.publicUrl && <CustomDropdownItem icon="link" onClick={() => setClipboard(value.publicUrl)}>Copy invitation link</CustomDropdownItem>}
                {value.publicEmail && <CustomDropdownItem icon={["far", "envelope"]} onClick={() => setClipboard(value.publicEmail)}>Copy forwarding address</CustomDropdownItem>}
                {value.publicUrl && <CustomDropdownItem viewEvent="DISCOVER ListingPoll" event="START ListingPoll" icon="tasks" onClick={togglePoll}>Create poll link</CustomDropdownItem>}
                <CustomDropdownItem divider />
                {value.actions.copy && <CustomDropdownItem icon={["far", "copy"]} onClick={toggleCopy}>Create linked variant</CustomDropdownItem>}
              </>
            } />
          {value.actions.copy && <ProductCopyModal value={value} isOpen={isOpenCopy} toggle={toggleCopy} />}
          {value.publicUrl && <PollModal isOpen={isOpenPoll} toggle={togglePoll} baseUrl={value.publicUrl} />}
        </Col>
      </Row>
      {showOrders &&
        <>
          <Row className='mt-3'>
            <Col>
              <Nav>
                {showStagesFilter && <StageFilterNavItems view={view} value={value} query={query} updateQuery={updateQuery} />}
                {hasAvailabilityFilter && AvailabilityFilterNavItems(d, startOn, endOn, setAvailabilityFilter, query)}
                {hasGraduationFilter && <GraduationFilterNavItems value={value} query={query} updateQuery={updateQuery} />}
                {hasRatingFilter && <RatingFilterNavItems query={query} updateQuery={updateQuery} />}
                {hasEduRatingFilter && <EduRatingFilterNavItems displayMax={value.details.eduRatingDisplayMax} query={query} updateQuery={updateQuery} />}
                {hasLocationFilter && <LocationFilterNavItems query={query} updateQuery={updateQuery} />}
                {hasLanguageFilter && <LanguageFilterNavItems query={query} updateQuery={updateQuery} />}
                {hasFlagsFilter && <FlagsFilterNavItems query={query} updateQuery={updateQuery} />}
                {hasTopicsFilter && <TopicsFilterNavItems value={value} query={query} updateQuery={updateQuery} />}
                {hasSourcesFilter && view === View.Source && <SourcesFilterNavItems query={query} updateQuery={updateQuery} />}
                {hasCompensationFilter && <CompensationFilterNavItems query={query} updateQuery={updateQuery} />}
                {hasLabelsFilter && view !== View.Source && <LabelFilterNavItems value={value} labels={query.labels} setLabels={setLabelQuery} />}
                {hasFilters && <ClearFilterNavItems onClick={clearFilters} />}
                <CustomDropdown isOpen={isOpenFilterDropdown} toggle={toggleFilterDropdown} className="ml-auto" columns={2} label="Filters">
                  <AvailabilityFilterDropdownItems startOn={startOn} setAvailabilityFilter={setAvailabilityFilter} />
                  <GraduationFilterDropdownItems value={value} query={query} updateQuery={updateQuery} />
                  {showRating && <RatingFilterDropdownItems query={query} updateQuery={updateQuery} />}
                  {showCompensation && <CompensationFilterDropdownItems query={query} updateQuery={updateQuery} />}
                  <LocationsFilterDropdownItems value={value} query={query} updateQuery={updateQuery} />
                  <LanguagesFilterDropdownItems value={value} query={query} updateQuery={updateQuery} />
                  <TopicsFilterDropdownItems value={value} query={query} updateQuery={updateQuery} />
                  <CustomDropdownItem className="mt-3" header>Additional filters</CustomDropdownItem>
                  {view !== View.Source && <LabelFilterDropdownItems value={value} labels={query.labels} setLabels={setLabelQuery} />}
                  <LocationFilterDropdownItems value={value} query={query} updateQuery={updateQuery} />
                  <OtherFiltersDropdownItems view={view} vars={vars} updateVars={updateVars} query={query} updateQuery={updateQuery} />
                </CustomDropdown>
                <CustomDropdown isOpen={isOpenViewDropdown} toggle={toggleViewDropdown} label="View">
                  <PropertiesDisplayDropdownItems view={view} value={value} query={query} updateQuery={updateQuery} search={search} />
                </CustomDropdown>
                <CustomDropdown label="Actions" spin={readyState === ReadyState.Loading}>
                  {view === View.Source ?
                    <>
                      {isContributor && selection.size === 0 && <DropdownItem onClick={openAdd}>Add applicant</DropdownItem>}
                      {isContributor && <DropdownItem divider />}
                      {isContributor && selection.size > 0 && selectedOrders.some(_ => !_.viewEvents.length) && <DropdownItem onClick={addSuggestedOrdersViewEvent}>Mark applicants as seen</DropdownItem>}
                      {isContributor && selection.size > 0 && selectedOrders.every(_ => _.viewEvents.length > 0) && <DropdownItem onClick={removeSuggestedOrdersViewEvent}>Mark applicants as unseen</DropdownItem>}
                      {isContributor && selection.size > 0 && <DropdownItem onClick={previewOrChangeOrders("addShortlist")}>Save to shortlist</DropdownItem>}
                    </> : <>
                      {isContributor && <DropdownItem onClick={openAdd}>Add applicant</DropdownItem>}
                      {isContributor && <DropdownItem onClick={toggleImport}>Import applicants</DropdownItem>}
                      {isContributor && <DropdownItem divider />}
                      {isContributor && selection.size > 0 && <LabelActionDropdownItems value={value} selectedOrders={selectedOrders} onUpdate={update} showCreate={isContributor} toggleCreate={toggleCreateLabel} />}
                      {isContributor && <DropdownItem onClick={exportOrders}>Export to Excel</DropdownItem>}
                      {isContributor && selection.size > 0 && <DropdownItem onClick={toggleMessage}>Message applicants</DropdownItem>}
                      {isContributor && selection.size > 0 && selectedOrders.some(_ => !_.viewEvents.length) && <DropdownItem onClick={addOrdersViewEvent}>Mark as seen</DropdownItem>}
                      {isContributor && selection.size > 0 && selectedOrders.every(_ => _.viewEvents.length > 0) && <DropdownItem onClick={removeOrdersViewEvent}>Mark as unseen</DropdownItem>}
                      {isOwner && selection.size > 0 && <DropdownItem onClick={toggleShare}>Share applicants</DropdownItem>}
                      {isContributor && selection.size > 0 && selectedOrders.every(_ => _.actions.allow) && <DropdownItem onClick={previewOrChangeOrders("allow")}>Allow applicants</DropdownItem>}
                      {isContributor && selection.size > 0 && selectedOrders.every(_ => _.actions.shortlist) && <DropdownItem onClick={previewOrChangeOrders("shortlist")}>Save to shortlist</DropdownItem>}
                      {hasScope("Site.Admin") && selection.size > 0 && <DropdownItem onClick={reviewOrdersJobs}>Prioritize reviews</DropdownItem>}
                      {isReader && hasMoreProducts && selection.size > 0 && <DropdownItem onClick={toggleCopyToProductChooser}>Copy applicants</DropdownItem>}
                      {isContributor && hasMoreProducts && selection.size > 0 && <DropdownItem onClick={toggleMoveToProductChooser}>Move applicants</DropdownItem>}
                      {isContributor && canMerge && <DropdownItem onClick={mergeOrders}>Merge applicants</DropdownItem>}
                      {isContributor && selection.size > 0 && selectedOrders.every(_ => _.actions.unshortlist) && <DropdownItem onClick={previewOrChangeOrders("unshortlist")}>Remove from shortlist</DropdownItem>}
                      {isContributor && selection.size > 0 && <DropdownItem disabled={!selectedOrders.every(_ => _.actions.refuse)} onClick={previewOrChangeOrders("refuse")}>Archive applicants</DropdownItem>}
                      {isContributor && <DropdownItem divider />}
                      {isContributor && selection.size > 0 && customActions?.map((_, i) => <DropdownItem key={i} onClick={previewOrChangeOrders("event", _.key)}>{_.name}</DropdownItem>)}
                    </>
                  }
                </CustomDropdown>
              </Nav>
              {isContributor && <OrdersImportModal product={value} onUpdateProduct={update} isOpen={isOpenImport} toggle={toggleImport} />}
              {isContributor && <CreateLabelModal isOpen={isOpenCreateLabel} toggle={toggleCreateLabel} value={value} onUpdate={updatedLabel} />}
              {isContributor && <OrdersInputModal product={value} onUpdateProduct={update} onUpdate={orders => update({ orders })} toggle={() => setOrdersInputProps(_initialOrdersInputProps)} showPreview {...ordersInputProps} />}
              {isContributor && <ProductChooserModal isOpen={isOpenMoveToProductChooser} toggle={toggleMoveToProductChooser} roles={[ResourceRole.Owner, ResourceRole.Contributor]} label="Move" onSelect={moveOrders} />}
              {isReader && <ProductChooserModal isOpen={isOpenCopyToProductChooser} toggle={toggleCopyToProductChooser} roles={[ResourceRole.Owner, ResourceRole.Contributor]} label="Copy" onSelect={copyOrders} />}
              {isOwner && selection.size > 0 &&
                <ShareMessageModal value={value} title={`${selection.size} applicants from ${value.details.displayTitle}`}
                  url={`${locationBase}/my/listings/${value.id}/candidates/${selectedOrders[0]?.id}?scope=claim:MELLON#collection=${selectedOrders.map(_ => _.id).join(",")}`}
                  recruiterUrl={`${locationBase}/my/shares/{{Id}}?scope=claim:MELLON#token={{AccessToken}}&collection=${selectedOrders.map(_ => _.id).join(",")}`}
                  showTemplates templates={value.details.messages} onUpdateTemplates={messages => update?.({ id, details: setReplace({ messages }) })}
                  isOpen={isOpenShare} toggle={toggleShare} />
              }
              {isContributor && selection.size > 0 &&
                <SendMessageModal isOpen={isOpenMessage} toggle={toggleMessage}
                  to={selectedOrders.map(_ => _.customer)}
                  subject={`About your application for ${value.details.displayTitle}`}
                  content={`Hi {{Name}},\n\nPlease update your application at ${value.publicUrl}.\n\nSincerely,\n{{Me}}`} />
              }
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col style={{ padding: "0px 30px" }}>
              {HeaderRow(isSelectionAll, setSelectionNone, setSelectionAll, ratingType, setRatingType, showAvailability, showRating, showCompleteness, isLoading)}
              <LoadingProgress show={isLoading} className="bl-1 bg-white br-1" flush />
              {orders.map((_, i) =>
                <ItemRow key={_.id} value={_} className={i == orders.length - 1 ? "bb-1" : ""} product={value} onUpdateProduct={product => updateResult({ product })} selected={selection.has(_.id)} onUpdate={order => updateResult({ product: { orders: [order] } })} {...{
                  ratingType, startOn, endOn,
                  view, hash, search,
                  onView: trackView,
                  showAvailability, showRating, showSnippet, query, selection, toggleSelection,
                }} />
              )}
              {!isLoading && !orders.length &&
                <Row>
                  <Col className="b-1 bg-white">
                    <Cell>
                      No matching results. <a href="#" onClick={preventDefault(clearFilters)}>View all</a>
                    </Cell>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              {selection.size > 0 && <span className="text-muted">Selected {selection.size} of {orders.length}</span>}
              {selection.size == 0 && orders.length > 0 && <span className="text-muted">Showing 1 &ndash; {orders.length}</span>}
              {!isLoading && orders.length >= vars.count && <a className="ml-3" href="#" onClick={preventDefault(getMore)}>See more</a>}
              {!isLoading && selection.size > 0 && <a className="ml-3" href="#" onClick={preventDefault(setSelectionNone)}>Clear selection</a>}
              {isLoading && <FontAwesomeIcon className="ml-3 text-muted" icon="circle-notch" spin />}
            </Col>
          </Row>
        </>
      }
    </>
  )
};
