import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, ListGroup, ListGroupItem, Modal, ModalFooter, CardSubtitle, CardTitle } from "reactstrap";
import ValidatingInput from "../components/Input";
import { useQuery } from "../hooks/ApiProvider";
import DebouncedInput from "../components/Input";
import { Preference, usePreference } from "../hooks/PreferencesProvider";
import { AccountSubscriptionModel } from "../types/api-graph-types";
import DatalistInput from "../components/DatalistInput";

library.add(faCheckSquare);

const _getSubscriptions = `query getMySubscriptions($query:String) {
  me {
    subscriptions(query:$query first:10) {
      id
      kind
      title 
      plan { id title }
    }
  }
}`;

interface Result {
  me: {
    subscriptions: AccountSubscriptionModel[]
  }
}

export function Input({ value, onUpdate }: {
  value?: AccountSubscriptionModel,
  onUpdate: (value: AccountSubscriptionModel) => void
}) {
  const [query, setQuery] = usePreference<string>(Preference.SubscriptionChooserQuery);
  const [result] = useQuery<Result>(_getSubscriptions, { query });
  const update = (query: string) => {
    var value = result?.me?.subscriptions?.filter(_ => _.title == query)[0];
    value && onUpdate(value);
    !value && setQuery(query);
  };
  return (
    <>
      <DebouncedInput list="subscriptions" type="text" value={value?.title} delay={300} onUpdate={update} />
      <datalist id="subscriptions">
        {result?.me?.subscriptions?.map((_, i) => <option key={i} value={_.title} />)}
      </datalist>
    </>
  );
}

export default ({ isOpen, toggle, label, onSelect }: {
  isOpen?: boolean,
  toggle?: () => void,
  label?: string,
  onSelect: (id: string, value: AccountSubscriptionModel) => void
}) => {
  const [query, setQuery] = usePreference<string>(Preference.SubscriptionChooserQuery);
  const [value, setValue] = useState<AccountSubscriptionModel>();
  const [result] = useQuery<Result>(isOpen ? _getSubscriptions : "", { query });
  const reset = () => setValue(undefined);
  useEffect(reset, [isOpen]);
  const select = () => {
    value && onSelect(value.id, value);
    toggle?.();
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ListGroup flush>
        <ListGroupItem>
          <ValidatingInput type="search" delay={300} value={query} onUpdate={setQuery} />
        </ListGroupItem>
        {result?.me?.subscriptions?.map((_, i) => (
          <ListGroupItem key={i} className={value?.id === _.id ? "shadow" : value ? "opacity-50" : undefined} onClick={() => setValue(_)}>
            <Button color="link" className={`btn-float ${value?.id === _.id ? "" : "invisible"}`}><FontAwesomeIcon icon={["far", "check-square"]} /></Button>
            <div>
              {_.title && <CardSubtitle tag="h5">{_.plan.title}</CardSubtitle>}
              <CardTitle tag="h3">{_.title || _.plan.title}</CardTitle>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
      <ModalFooter>
        <Button color="link" onClick={toggle}>Cancel</Button>
        <Button color="primary" disabled={!value} onClick={select}>{label ?? "Select"}</Button>
      </ModalFooter>
    </Modal>
  );
}

