import React from "react";
import { Col, Row } from "reactstrap";
import SubscriptionCard, { Fragment as SubscriptionFragment } from "../cards/SubscriptionCard";
import { DeepPartial, useQuery } from "../hooks/ApiProvider";
import { useMe } from "../hooks/MeProvider";
import { useTitle } from "../hooks/TitleHook";
import { PersonModel } from "../types/api-graph-types";
import LoadingController from "./LoadingController";

const _getMyProfile = `query getMyProfile{
  me {
    subscriptions { id ${SubscriptionFragment} } 
  }
}`;

interface QueryResult {
  me: PersonModel
}

export default () => {
  const [, updateMe] = useMe();
  const [result, updateResult, isLoading] = useQuery<QueryResult>(_getMyProfile);
  useTitle("Subscriptions");
  const update = (me: DeepPartial<PersonModel>) => {
    updateResult({ me });
    updateMe(me);
  }
  const value = result?.me;
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  return (
    <>
      {value.subscriptions.map(_ =>
        <Row key={_.id} className="mt-3">
          <Col>
            <SubscriptionCard showInput showShare showButtons value={_} onUpdate={value => update({ subscriptions: [value] })} />
          </Col>
        </Row>
      )}
    </>
  );
};
