import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { preventDefault } from "../hooks/NavigationHook";

export default ({ loading, count, limit, onClick }: {
  loading: boolean;
  count: number;
  limit: number;
  onClick: () => void;
}) => {
  return (
    <span>
      {loading && <FontAwesomeIcon className="text-muted" icon="circle-notch" spin />}
      {!loading && count >= limit && <a href="#" onClick={preventDefault(onClick)}>See more</a>}
    </span>
  );
}
