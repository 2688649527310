import React from "react";
import { Col, Row } from "reactstrap";
import AlternatesCard, { Fragment as AlternatesFragment } from "../cards/MyProfile/AlternatesCard";
import AttachmentsCard, { Fragment as AttachmentsFragment } from "../cards/MyProfile/AttachmentsCard";
import ExternalAccountsCard, { Fragment as ContactInformationFragment } from "../cards/MyProfile/ContactInformationCard";
import LanguagesCard, { Fragment as LanguagesFragment } from "../cards/MyProfile/LanguagesCard";
import LocationsCard, { Fragment as LocationsFragment } from "../cards/MyProfile/LocationsCard";
import MembershipsCard, { Fragment as MembershipsFragment } from "../cards/MyProfile/MembershipsCard";
import PersonCard, { Fragment as PersonFragment } from "../cards/MyProfile/PersonCard";
import TopicsCard, { Fragment as TopicsFragment } from "../cards/MyProfile/TopicsCard";
import { DeepPartial, useQuery } from "../hooks/ApiProvider";
import { useMe } from "../hooks/MeProvider";
import { useTitle } from "../hooks/TitleHook";
import { MeModel } from "../types/api-graph-types";
import LoadingController from "./LoadingController";

const Fragment = `
    ${PersonFragment}
    contactInformation { ${ContactInformationFragment} }
    attachments { ${AttachmentsFragment} }
    memberships { ${MembershipsFragment} }
    locationPreferences { ${LocationsFragment} }
    languagePreferences { ${LanguagesFragment} }
    topicPreferences { ${TopicsFragment} }
    alternates { ${AlternatesFragment} }
`;

const _getMyProfile = `query getMyProfile{
  me { ${Fragment} }
}`;

interface QueryResult {
  me: MeModel
}

export default () => {
  const [, updateMe] = useMe();
  const [result, updateResult, isLoading] = useQuery<QueryResult>(_getMyProfile);
  useTitle("Profile");
  const update = (me: DeepPartial<MeModel>) => {
    updateResult({ me });
    updateMe(me);
  }
  const value = result?.me;
  if (!result || !value) {
    return <LoadingController isLoading={isLoading} noResult={!value} />;
  }

  return (
    <>
      <Row className="mt-3">
        <Col>
          <PersonCard value={value} onUpdate={update} showInput />
        </Col>
      </Row>
      {value.alternates.length > 0 &&
        <Row className="mt-3">
          <Col>
            <AlternatesCard values={value.alternates} onUpdate={alternates => update({ alternates })} />
          </Col>
        </Row>
      }
      <Row className="mt-3">
        <Col>
          <ExternalAccountsCard values={value.contactInformation} onUpdate={contactInformation => update({ contactInformation })} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <AttachmentsCard values={value.attachments} onUpdate={attachments => update({ attachments })} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <MembershipsCard values={value.memberships} onUpdate={memberships => update({ memberships })} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <LocationsCard values={value.locationPreferences} onUpdate={locationPreferences => update({ locationPreferences })} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <TopicsCard values={value.topicPreferences} onUpdate={topicPreferences => update({ topicPreferences })} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <LanguagesCard values={value.languagePreferences} onUpdate={languagePreferences => update({ languagePreferences })} />
        </Col>
      </Row>
    </>
  );
};
