import React, { useEffect, useState } from "react";
import { Toast, ToastBody, Container, ToastHeader } from "reactstrap";
import { useToasts } from "../hooks/ToastsProvider";

type Permission = NotificationPermission | "pending";

export default () => {
  const [toast, setToast] = useToasts();
  const [permission, setPermission] = useState("default" as Permission);
  useEffect(() => {
    if (toast && permission === "default") {
      setPermission("pending");
      (async () => setPermission(window.Notification ? await Notification.requestPermission() : "denied"))();
    } else if (toast && permission === "granted") {
      const notification = new Notification(toast.title || "", {
        body: toast.message
      });
      if (toast.expireAt) {
        const now = new Date().valueOf();
        const timeout = Math.max(3000, toast.expireAt - now);
        setTimeout(notification.close.bind(notification), timeout);
      }
    } else if (toast && permission === "denied" && toast.expireAt) {
      const now = new Date().valueOf();
      const timeout = Math.max(3000, toast.expireAt - now);
      const timer = setTimeout(() => setToast(toast, 0), timeout);
      return () => clearTimeout(timer);
    }
  }, [permission, toast]);
  return permission === "denied" ? (
    <div className="fixed-bottom">
      <div className="p-3">
        <Toast className="toast-dark" isOpen={!!toast} onClick={() => toast && setToast(toast, 0)}>
          {toast?.title && <ToastHeader>{toast.title}</ToastHeader>}
          {toast && <ToastBody>{toast.message}</ToastBody>}
        </Toast>
      </div>
    </div>
  ) : null;
}
