import React from "react";
import { CardText } from "reactstrap";
import SearchLink from "../../components/SearchLink";
import { LegalResearchBackgroundModel, ReviewOption, TaskModel, TaskType } from "../../types/api-graph-types";
import { DocumentType, useDocumentContext } from "../../hooks/DocumentProvider";
import CiteLink from "../../components/CiteLink";
import FormGroup, { len, isUrl, trunc, between } from "../../components/FormGroup";
import Input from "../../components/Input";
import { ApiErrors, useMutation } from "../../hooks/ApiProvider";
import Highlight from "../../components/Highlight";

export const Fragment = `... on LegalResearchBackgroundModel { 
  searchQuery
  excerpt
  url
}`;

const _updateTaskDetails = `mutation updateLegalResearchBackgroundTaskDetails($id:ID! $value:LegalResearchBackgroundInputModel) {
  task(id:$id) {
    details { ... on LegalResearchBackgroundOperations { change(value:$value) } } 
    commit { details { ${Fragment} } }
  }
}`;

interface OperationResults {
  task: {
    commit: LegalResearchBackgroundModel
  }
};

const ToInput = (value: LegalResearchBackgroundModel) => ({
  searchQuery: value.searchQuery || "",
  url: value.url || "",
  excerpt: value.excerpt || ""
} as any);

export const CommentOptions: [number, number, ReviewOption, number, string][] = [
  [1, 2, ReviewOption.Empty, 0, ""],
  [1, 2, ReviewOption.Spam, -1, ""],
  [1, 2, ReviewOption.Offensive, -1, ""],
  [1, 2, ReviewOption.Plagiarised, -1, ""],
  [3, 8, ReviewOption.LegalResearchBackgroundSearchQueryPoor, 0, ""],
  [3, 8, ReviewOption.LegalResearchBackgroundUrlPoor, 0, ""],
  [9, 10, ReviewOption.LegalResearchBackgroundUrlExcellent, 1, ""],
  [3, 6, ReviewOption.LegalResearchBackgroundExcerptPoor, 0, ""],
  [7, 10, ReviewOption.LegalResearchBackgroundExcerptExcellent, 1, ""],
];

export const Is = (value: TaskModel): value is TaskModel & { details: LegalResearchBackgroundModel } =>
  value.type === TaskType.LegalResearchBackground

export const IsValid = (value: LegalResearchBackgroundModel) =>
  value.searchQuery && len(value.searchQuery) <= 140
  && value.url && isUrl(value.url)
  && value.excerpt && between(len(value.excerpt), 140, 1400)
  || false;

export function InputText({ id, value, help, onUpdate }: {
  value: LegalResearchBackgroundModel,
  id: string,
  help?: boolean,
  onUpdate: (value: LegalResearchBackgroundModel) => void
}) {
  const input = ToInput(value || {});
  const [mutation] = useMutation<OperationResults>();
  const update = async (partial: Partial<LegalResearchBackgroundModel>) => {
    const value = { ...input, ...partial };
    onUpdate(value);
    await mutation(_updateTaskDetails, { id, value }, { debounce: 5000 });
  }
  return (
    <CardText tag='div'>
      <FormGroup label="Search Query" openHelp={help} help="Use Google to find relevant webpages, blog posts, forum comments etc. You can start your search by entering the original question or the crux of the matter. Use additional keywords to restrict results to your jurisdiction of interest. If you don't find too many relevant pages in the top ten results, try refining your search by searching for exact phrases by adding quotation marks around groups of words, or by excluding irrelevant results by adding a minus sign in front of a keyword." required maxLength={140} value={input.searchQuery}>
        <Input type="text" onUpdate={searchQuery => update({ searchQuery })} />
      </FormGroup>
      <FormGroup label="Url" openHelp={help} help="URL of webpage discussing this issue. When looking through search results, prefer webpages from reputed law firms, popular law blogs and legal forums, over other webpages." required url value={input.url}>
        <Input type="text" spellCheck={false} onUpdate={url => update({ url })} />
      </FormGroup>
      <FormGroup label="Excerpt" openHelp={help} help="Excerpt from the webpage most relevant to the matter. Use Ctrl-F in the browser to search within the webpage. Find a sentence or two that discusses the matter at hand. Look for references to specific articles or paragraphs in an act/statute that you can use next." required minLength={140} maxLength={1400} value={input.excerpt}>
        <Input type="textarea" rows={5} onUpdate={excerpt => update({ excerpt })} />
      </FormGroup>
    </CardText>
  );
}

export default ({ value }: {
  value: LegalResearchBackgroundModel
}) => {
  const [documentType] = useDocumentContext();
  return (
    <>
      <blockquote className='text-justify'>
        <Highlight value={value.excerpt} />
        <CiteLink href={value.url} />
      </blockquote>
      {documentType !== DocumentType.Document && <CardText><SearchLink query={value.searchQuery} /></CardText>}
    </>
  );
}

