import moment from "moment";
import React, { useState } from "react";
import { Card, CardBody, CardTitle, Col, Form, ListGroup, ListGroupItem, Row } from "reactstrap";
import MutationButton from "../components/MutationButton";
import PeriodInput from "../components/PeriodInput";
import { useMutation, useReadyState } from "../hooks/ApiProvider";
import { download } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import { AttachmentModel } from "../types/api-graph-types";

const _getReport = `mutation exportReport($type:ReportType $since:DateTime $until:DateTime) {
  administration {
    reports {
      export(type:$type since:$since until:$until) {
        dataUri
      }
    }
  }
}`;

interface OperationResult {
  administration: {
    reports: {
      export: AttachmentModel
    }
  }
}

enum ReportType {
  QualifiedActivities = "QUALIFIED_ACTIVITIES",
  ReviewerDiscussions = "REVIEWER_DISCUSSIONS",
  ListingActivity = "LISTING_ACTIVITY",
  ReviewerActivity = "REVIEWER_ACTIVITY",
  ListingOwnerRetention = "LISTING_OWNER_RETENTION",
  UserActivity = "USER_ACTIVITY",
  ApplicationTags  = "APPLICATION_TAGS"
}

const _titles = new Map<ReportType, string>([
  [ReportType.QualifiedActivities, "Qualified Activities"],
  [ReportType.ReviewerDiscussions, "Reviewer Discussions"],
  [ReportType.ListingActivity, "Listing Activity"],
  [ReportType.ReviewerActivity, "Reviewer Activity"],
  [ReportType.ListingOwnerRetention, "Listing Owner Retention"],
  [ReportType.UserActivity, "User Activity"],
  [ReportType.ApplicationTags, "Application Tags"],
]);

interface Vars {
  since?: string | null,
  until?: string | null
}

const _initialVars: Vars = {
  since: undefined,
  until: undefined,
};

function ReportRow({ type, vars }: { type: ReportType, vars: Vars }) {
  const [mutation] = useMutation<OperationResult>();
  const [readyState, setReadyState] = useReadyState();

  const exportReport = async () => {
    const result = await mutation(_getReport, { ...vars, type }, { setReadyState });
    const since = vars.since ? moment(vars.since).format("YYYYMMDD") : "";
    const until = vars.until ? moment(vars.until).add(-1, "s").format("-YYYYMMDD") : "";
    const reportname = (_titles.get(type) ?? "report").replace(/[^a-z0-9]/i, "");
    const filename = `${reportname}-${since}${until}.csv`;
    download(result.administration.reports.export.dataUri, filename);
  }

  return (
    <ListGroupItem className="d-flex align-items-center">
      <div>{_titles.get(type)}</div>
      <MutationButton className="ml-auto" readyState={readyState} onClick={exportReport} color="primary">Export</MutationButton>
    </ListGroupItem>
  );
}

function ReportsCard({ }: {}) {
  const [vars, setVars] = useState(_initialVars);
  const updateVars = (partial: Partial<Vars>) => setVars({ ...vars, ...partial });

  return (
    <Card>
      <CardBody>
        <Form inline>
          <CardTitle className="d-flex w-100 mb-0">
            <h3 className="mb-0">Reports</h3>
            <PeriodInput className="ml-auto" value={`${vars.since || ""}/${vars.until || ""}`} onUpdate={value => updateVars({ since: value.split("/")[0], until: value.split("/")[1] })} />
          </CardTitle>
        </Form>
      </CardBody>
      <ListGroup flush className="bt-1">
        <ReportRow type={ReportType.QualifiedActivities} vars={vars} />
        <ReportRow type={ReportType.ReviewerDiscussions} vars={vars} />
        <ReportRow type={ReportType.ListingActivity} vars={vars} />
        <ReportRow type={ReportType.ReviewerActivity} vars={vars} />
        <ReportRow type={ReportType.ListingOwnerRetention} vars={vars} />
        <ReportRow type={ReportType.UserActivity} vars={vars} />
        <ReportRow type={ReportType.ApplicationTags} vars={vars} />
      </ListGroup>
    </Card>
  );
}

export default () => {
  useTitle("Reports")
  return (
    <>
      <Row className="mt-3" >
        <Col>
          <ReportsCard />
        </Col>
      </Row>
    </>
  );
};
