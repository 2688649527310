import React, { ReactNode } from "react";
import { Card, CardProps } from "reactstrap";
import { DeepPartial } from "../hooks/ApiProvider";

export interface CardButtonsAttributes<T> {
  value: T,
  className?: string,

  onUpdate?: (value: DeepPartial<T> & { id: string }) => void,

  toggleInput?: () => void,
  toggleShare?: () => void,
  toggleDiscussion?: () => void,

  children?: ReactNode
}

export interface CardAttributionAttributes<T> {
  value: T,
  className?: string,

  selected?: boolean,

  onSelect?: () => void,
  onUpdate?: (value: DeepPartial<T> & { id: string }) => void,
}

export interface CardBodyAttributes<T> extends CardButtonsAttributes<T> {
  className?: string,
  href?: string,

  selected?: boolean,

  showSelect?: boolean,
  showNotice?: boolean,
  showAttachments?: boolean,
  showStatus?: boolean,
  showMenu?: boolean,
  showButtons?: boolean,
  showDetailsMenu?: boolean,

  hideRating?: boolean,

  onClick?: () => void,
  onSelect?: () => void,

  appendNotice?: ReactNode,
  appendDetails?: ReactNode,
  children?: ReactNode
}

export interface CardAttributes<T> {
  value: T,

  className?: string,
  href?: string,

  selected?: boolean,

  hideRemoved?: boolean,
  hideRating?: boolean,

  showHeader?: boolean,
  showAttribution?: boolean,
  showDiscussion?: boolean;
  showInput?: boolean;
  showShare?: boolean;
  showReview?: boolean;
  showSelect?: boolean,
  showNotice?: boolean,
  showAttachments?: boolean,
  showStatus?: boolean,
  showMenu?: boolean,
  showButtons?: boolean,
  showDetailsMenu?: boolean,

  openDiscussion?: boolean;
  openInput?: boolean;
  openShare?: boolean;
  openReview?: boolean;

  privateDiscussion?: string;
  publicDiscussion?: boolean;

  onSelect?: () => void,
  onSelectText?: (value: string) => void,
  onClick?: () => void,
  onUpdate?: (value: DeepPartial<T> & { id: string }) => void,

  appendHeader?: ReactNode
  appendNotice?: ReactNode
  appendDetails?: ReactNode

  children?: ReactNode
}

export default ({ children, ...attrs }: CardProps) => {
  return (
    <Card {...attrs}>
      {children}
    </Card>
  );
}

