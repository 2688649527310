import React, { createContext, ReactNode, useContext, useState, useEffect } from "react";

export type Size = "xs" | "sm" | "md" | "lg" | "xl";
type ContextType = [Size];
const initialContext: any = undefined;
const Context = createContext<ContextType>(initialContext);

export function useWindowState() {
  return useContext(Context);
}

export function widthToSize(width: number) {
  return width < 576 ? "xs" : width < 768 ? "sm" : width < 992 ? "md" : width < 1200 ? "lg" : "xl";
}

export function sizeToMinWidth(size: Size): number {
  return size === "xs" ? 0 : size === "sm" ? 576 : size === "md" ? 768 : size === "lg" ? 992 : size === "xl" ? 1200 : 9999;
}

export default (props: { children?: ReactNode }) => {
  const [size, setSize] = useState<Size>(widthToSize(window.innerWidth));
  useEffect(() => {
    const onResize = () => {
      const sz = widthToSize(window.innerWidth);
      sz !== size && setSize(sz);
    }
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [size]);
  return (
    <Context.Provider value={[size]}>
      {props.children}
    </Context.Provider>
  );
}
