import React from "react";
import { CardBody, CardBodyProps, CardSubtitle, CardTitle } from "reactstrap";
import Card, { CardAttributes, CardBodyAttributes, CardButtonsAttributes } from "../components/Card";
import CardMarkdownText from "../components/CardMarkdownText";
import { CardButton, CardButtons } from "../components/CardStatus";
import ExternalLink from "../components/ExternalLink";
import PhoneNumberSpan from "../components/PhoneNumberSpan";
import { useMutation } from "../hooks/ApiProvider";
import { useCardState } from "../hooks/CardStateHook";
import { useNavigation } from "../hooks/NavigationHook";
import OrganizationInputModal from "../modals/OrganizationInputModal";
import { AccessTokenModel, OrganizationModel } from "../types/api-graph-types";

export const Fragment = `
  id
  key
  type
  name
  unitName
  nickname
  logo
  industry
  description
  phoneNumbers
  emailAddresses
  websiteUrl
  actions { change switch }
  policy { role }
`;

const _switchOrganization = `mutation switchOrganization($id:ID) {
  organization(id:$id) { switch { token_type access_token expires_in } }
}`;

interface OperationResults {
  organization: {
    ["switch"]: AccessTokenModel
  }
}

export function Title({ value }: { value: OrganizationModel }) {
  return (
    <div className="d-flex">
      {value.logo && <img src={value.logo} width={48} height={48} className="mr-2 align-baseline" />}
      <div>
        {value.unitName && <CardSubtitle tag="h5">{value.name}</CardSubtitle>}
        <CardTitle tag='h3' className="mb-0">{value.unitName || value.name}</CardTitle>
      </div>
    </div>
  );
}

export function Text({ value }: { value: OrganizationModel }) {
  const showWebsite = !!value.websiteUrl;
  const showPhones = !!value.phoneNumbers;
  const showEmails = !!value.emailAddresses;
  const showDetails = showWebsite;
  return (
    <>
      <Title value={value} />
      <CardMarkdownText className="mt-3" justify hyphens source={value.description} />
      {showDetails &&
        <ul className="list-unstyled mt-3 mb-0">
          {showWebsite && <li><b className="mr-1">Website</b><ExternalLink href={value.websiteUrl} /></li>}
          {showPhones && value.phoneNumbers.split(",").map(_ =>
            <li>
              <b className="mr-1">Phone</b>
              <PhoneNumberSpan className="mr-1" value={_} />
            </li>
          )}
          {showEmails && <li><b className="mr-1">Email</b>{value.emailAddresses}</li>}
        </ul>
      }
    </>
  );
}

function Buttons({ value, toggleInput }: CardButtonsAttributes<OrganizationModel>) {
  const [, go] = useNavigation();
  const [mutation] = useMutation<OperationResults>();
  const switchToken = async () => {
    const result = await mutation(_switchOrganization, { id: value.id });
    const { token_type, access_token, expires_in } = result.organization.switch;
    go(`${window.location.protocol}//${window.location.host}/sign-in#token_type=${token_type}&access_token=${access_token}&expires_in=${expires_in}&storage=session`);
  }
  const { actions } = value;
  return (
    <CardButtons className="mt-3">
      {actions.change && toggleInput && <CardButton onClick={toggleInput}>Edit</CardButton>}
      {actions.switch && <CardButton onClick={switchToken}>View as</CardButton>}
    </CardButtons>
  );
}

export function Body({ value, href, onUpdate, onClick, toggleInput, className, showButtons, children, ...attrs }: CardBodyAttributes<OrganizationModel> & CardBodyProps) {
  const [navigate] = useNavigation();
  const classNames = [
    className,
    "hover-container",
    (href || onClick || toggleInput) ? "hover-shadow cursor-pointer" : ""
  ].join(" ");
  return (
    <CardBody className={classNames} onClick={onClick || navigate(href) || toggleInput || undefined} {...attrs}>
      <Text value={value} />
      {children}
      {showButtons && <Buttons {...{ value, onUpdate, toggleInput }} />}
    </CardBody>
  );
}

export default ({ value, href, onClick, onUpdate, children, showButtons, showInput, showShare }: CardAttributes<OrganizationModel>) => {
  const { isOpenInput, toggleInput } = useCardState({ showInput, showShare });
  return (
    <>
      <Card>
        <Body {...{ href, value, onUpdate, onClick, showButtons, toggleInput }} >
          {children}
        </Body>
      </Card>
      {showInput && <OrganizationInputModal value={value} onUpdate={onUpdate} isOpen={isOpenInput} toggle={toggleInput} />}
    </>
  );
}
