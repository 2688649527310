import React from "react";
import { Container, ContainerProps } from "reactstrap";
import NavigationFooter from "../components/NavigationFooter";
import NavigationHeader from "../components/NavigationHeader";
import ToastsContainer from "./ToastsContainer";

export default ({ children, ...attrs }: {} & ContainerProps) => (
  <>
    <NavigationHeader />
    <Container fluid {...attrs}>
      {children}
    </Container>
    <ToastsContainer />
    <NavigationFooter />
  </>
);
