import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, UncontrolledDropdown, Collapse } from "reactstrap";
import { CardBadge, preventOverflow } from "../../components/CardStatus";
import { CustomDropdownItem } from "../../components/CustomDropdown";
import { DeepPartial, setDelete, useMutation, byKey } from "../../hooks/ApiProvider";
import { useIcons } from "../../hooks/IconizeHook";
import { useTranslation } from "../../hooks/TranslationProvider";
import { AreaType, IndustryType, PersonModel, ServiceType, TopicPreferenceInputModel, TopicPreferenceModel, TopicPreferenceOption, FunctionType } from "../../types/api-graph-types";
import { useScopes } from "../../components/Show";

library.add(faTrashAlt, faHeart);

export const Fragment = `
  key
  value {
    industry
    function
    service
    area
  }
  options  
`;

const _updateMyTopicPreference = `mutation updateMyTopicPreference($key:String $value:TopicPreferenceInputModel) {
  me {
    changeTopicPreference(key:$key value:$value)
    commit { topicPreferences { ${Fragment} } }
  }
}`

const _removeMyTopicPreference = `mutation removeMyTopicPreference($key:String) {
  me {
    removeTopicPreference(key:$key)
    commit { topicPreferences { ${Fragment} } }
  }
}`

interface OperationResults {
  me: {
    commit: PersonModel
  }
}

const { Null, Current, Past, Love, Like, Dislike } = TopicPreferenceOption;

export function ItemRow({ value, readonly, onUpdate }: {
  value: TopicPreferenceModel,
  readonly?: boolean,
  onUpdate?: (value: DeepPartial<TopicPreferenceModel>) => void
}) {
  const [i] = useIcons();
  const [t] = useTranslation();
  const experience = value.options.includes(Current) ? Current : value.options.includes(Past) ? Past : Null;
  const rating = value.options.includes(Love) ? Love : value.options.includes(Dislike) ? Dislike : TopicPreferenceOption.Like;
  const [mutation] = useMutation<OperationResults>();
  const remove = async () => {
    const result = await mutation(_removeMyTopicPreference, { key: value.key });
    onUpdate?.(setDelete(value));
  }
  const modifyOptions = async (add: TopicPreferenceOption[], remove: TopicPreferenceOption[]) => {
    const result = await mutation(_updateMyTopicPreference, {
      key: value.key,
      value: {
        options: [...value.options.filter(_ => !remove.includes(_)), ...add]
      }
    });
    onUpdate?.(result.me.commit.topicPreferences.filter(p => p.key === value.key)[0])
  };

  return (
    <ListGroupItem className='hover-container d-flex align-items-baseline'>
      {readonly &&
        <span>
          <FontAwesomeIcon fixedWidth size='lg' className='mr-1' icon={i(rating)} />
          {value.value.function && t(value.value.function)}
          {" "}
          {value.value.service && t(value.value.service)}
          {" "}
          {value.value.area && t(value.value.area)}
        </span>
      }
      {!readonly &&
        <UncontrolledDropdown>
          <DropdownToggle color='white' className='p-0'>
            <FontAwesomeIcon fixedWidth size='lg' className='mr-1' icon={i(rating)} />
            {value.value.function && t(value.value.function)}
            {" "}
            {value.value.service && t(value.value.service)}
            {" "}
            {value.value.area && t(value.value.area)}
          </DropdownToggle>
          <DropdownMenu>
            <CustomDropdownItem onClick={() => modifyOptions([Love], [Like, Dislike])}>{t(Love)}</CustomDropdownItem>
            <CustomDropdownItem onClick={() => modifyOptions([Like], [Love, Dislike])}>{t(Like)}</CustomDropdownItem>
            <CustomDropdownItem onClick={() => modifyOptions([Dislike], [Love, Like])}>{t(Dislike)}</CustomDropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      }

      {readonly && <small className="text-muted ml-3">{t(experience)}</small>}
      {!readonly &&
        <UncontrolledDropdown className='ml-3'>
          <DropdownToggle color='white' className='p-0 btn-sm text-muted hover-caret-visible' caret>
            {t(experience) || <span className='d-none d-sm-inline hover-visible'>Add experience</span>}
          </DropdownToggle>
          <DropdownMenu>
            <CustomDropdownItem onClick={() => modifyOptions([], [Current, Past])}>No experience</CustomDropdownItem>
            <CustomDropdownItem onClick={() => modifyOptions([Current], [Past])}>{t(Current)}</CustomDropdownItem>
            <CustomDropdownItem onClick={() => modifyOptions([Past], [Current])}>{t(Past)}</CustomDropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      }
      {!readonly && <Button className="d-none d-sm-block ml-auto hover-visible" color="link" size="sm" onClick={remove}>Remove</Button>}
      {!readonly && <Button className="d-sm-none ml-auto text-muted" color="link" size="sm" onClick={remove}><FontAwesomeIcon icon={["far", "trash-alt"]} /></Button>}
    </ListGroupItem>
  );
}

export function Badges({ values, size }: {
  values: ReadonlyArray<TopicPreferenceModel>
  size?: string,
}) {
  const [t] = useTranslation();
  return (
    <>
      {values.map((_, i) => <CardBadge key={i} size={size}
        value={_.value.service !== ServiceType.Null ? t(_.value.service)
          : _.value.area !== AreaType.Null ? t(_.value.area)
            : ""}
        del={_.options.includes(TopicPreferenceOption.Dislike)}
        icon={_.options.includes(TopicPreferenceOption.Love) ? ["far", "heart"] : undefined}
        title={_.options.includes(TopicPreferenceOption.Current) ? "Current"
          : _.options.includes(TopicPreferenceOption.Dislike) ? "Dislike"
            : _.options.includes(TopicPreferenceOption.Love) ? "Love"
              : undefined}
      />)}
    </>
  );
}

export const Functions = [
  FunctionType.LegalFunction,
  FunctionType.AccountingFunction,
  FunctionType.BusinessDevelopmentFunction,
  FunctionType.SoftwareEngineeringFunction,
];

export const Services = [
  ServiceType.LegalServiceCorporateTransactional,
  ServiceType.LegalServiceInHouseCounsel,
  ServiceType.LegalServiceLitigation,
  ServiceType.AccountingServiceCorporateFinance,
  ServiceType.AccountingServiceTax,
  ServiceType.BusinessDevelopmentServiceLeadGeneration,
  ServiceType.BusinessDevelopmentServiceCustomerRelationshipManagement,
  ServiceType.SoftwareEngineeringServiceWebDevelopment,
  ServiceType.SoftwareEngineeringServiceBackEndDevelopment,
  ServiceType.SoftwareEngineeringServiceMobileDevelopment,
  ServiceType.SoftwareEngineeringServiceFullStackDevelopment,
];

export const Areas = [
  AreaType.LegalAreaCompanyLaw,
  AreaType.LegalAreaCompetitionLaw,
  AreaType.LegalAreaInformationTechnologyLaw,
  AreaType.LegalAreaIntellectualPropertyLaw,
  AreaType.LegalAreaInternationalLaw,
  AreaType.LegalAreaLabourLaw,
  AreaType.LegalAreaLandLaw,
  AreaType.LegalAreaMediaLaw,
  AreaType.LegalAreaMergersAndAcquisitionsLaw,
  AreaType.LegalAreaRealEstateLaw,
  AreaType.LegalAreaTaxLaw,
  AreaType.LegalAreaTradeLaw,
  AreaType.AccountingAreaCostAccounting,
  AreaType.AccountingAreaFinancialAccounting,
  AreaType.AccountingAreaManagerialAccounting,
  AreaType.AccountingAreaIncomeTaxAccounting,
  AreaType.AccountingAreaSalesTaxAccounting,
  AreaType.AccountingAreaAuditingResearch,
  AreaType.AccountingAreaTaxationResearch,
  AreaType.BusinessDevelopmentAreaMicroEnterprises,
  AreaType.BusinessDevelopmentAreaSmallEnterprises,
  AreaType.BusinessDevelopmentAreaMediumEnterprises,
  AreaType.BusinessDevelopmentAreaLargeEnterprises,
  AreaType.SoftwareEngineeringAreaCSharpProgramming,
  AreaType.SoftwareEngineeringAreaJavascriptProgramming,
  AreaType.SoftwareEngineeringAreaSqlProgramming,
  AreaType.SoftwareEngineeringAreaReactProgramming,
  AreaType.SoftwareEngineeringAreaGraphQlProgramming,
  AreaType.SoftwareEngineeringAreaAzureProgramming,
  AreaType.SoftwareEngineeringAreaAwsProgramming,
  AreaType.SoftwareEngineeringAreaTypescriptProgramming,
  AreaType.SoftwareEngineeringAreaJavaProgramming,
  AreaType.SoftwareEngineeringAreaSwiftProgramming,
];

export default ({ className, values, onUpdate }: {
  className?: string,
  values: ReadonlyArray<TopicPreferenceModel>,
  onUpdate: (values: DeepPartial<ReadonlyArray<TopicPreferenceModel>>) => void
}) => {
  const [t] = useTranslation();
  const [openFunction, setOpenFunction] = useState(FunctionType.Null);
  const [mutation] = useMutation<OperationResults>();
  const update = (value: DeepPartial<TopicPreferenceModel>) => onUpdate([value]);
  const addService = async (service: ServiceType) => {
    const result = await mutation(_updateMyTopicPreference, { value: { service } as Partial<TopicPreferenceInputModel> });
    onUpdate?.(result.me.commit.topicPreferences);
  }
  const addArea = async (area: AreaType) => {
    const result = await mutation(_updateMyTopicPreference, { value: { area } as Partial<TopicPreferenceInputModel> });
    onUpdate?.(result.me.commit.topicPreferences);
  }
  return (
    <>
      <Card className={className}>
        <CardHeader className="bg-white d-flex align-items-baseline">
          <h3>Interests</h3>
          {values.length < 5 ? (
            <UncontrolledDropdown className="ml-auto">
              <DropdownToggle color="link" caret>Add</DropdownToggle>
              <DropdownMenu modifiers={preventOverflow}>
                {Functions.flatMap((_, j) => {
                  const prefix = _.replace("FUNCTION", "");
                  const services = Services.filter(_ => _.startsWith(prefix) && values.findIndex(__ => __.value.service === _) === -1).sort(byKey(t));
                  const areas = Areas.filter(_ => _.startsWith(prefix) && values.findIndex(__ => __.value.area === _) === -1).sort(byKey(t));
                  const isOpen = _ === openFunction;
                  const toggle = () => setOpenFunction(_);
                  return [
                    <CustomDropdownItem isOpen={isOpen} toggle={false} onClick={toggle} > {t(_)}</CustomDropdownItem>,
                    <Collapse isOpen={isOpen} className={j !== Functions.length - 1 ? "bb-1" : ""}>
                      {services.length > 0 && <DropdownItem header>Practice</DropdownItem>}
                      {services.map((_, i) => <DropdownItem key={i} toggle={false} onClick={() => addService(_)}>{t(_)}</DropdownItem>)}
                      {areas.length > 0 && <DropdownItem header>Focus</DropdownItem>}
                      {areas.map((_, i) => <DropdownItem key={i} toggle={false} onClick={() => addArea(_)}>{t(_)}</DropdownItem>)}
                    </Collapse>
                  ];
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
              <span className="ml-auto text-muted small">0 slots left</span>
            )}
        </CardHeader>
        <ListGroup flush>
          {values.filter(_ => _.value.function !== FunctionType.Null).map((_, i) => <ItemRow key={i} value={_} onUpdate={update} />)}
          {values.filter(_ => _.value.service !== ServiceType.Null).map((_, i) => <ItemRow key={i} value={_} onUpdate={update} />)}
          {values.filter(_ => _.value.area !== AreaType.Null).map((_, i) => <ItemRow key={i} value={_} onUpdate={update} />)}
          {values.length === 0 && <ListGroupItem className="text-muted">Use interests to help refine the opportunities you're looking for.</ListGroupItem>}
        </ListGroup>
      </Card>
    </>
  );
}
