import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import { faEyeSlash, faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faEllipsisH, faStar as fasStar, faStarHalfAlt, faTimes, faUserFriends, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useEffect, useState } from "react";
import { Button, ButtonGroup, CustomInput, Form, FormGroup, Input, ListGroup, ListGroupItem, ListGroupItemProps } from "reactstrap";
import Collapse from "reactstrap/lib/Collapse";
import { Anonymous, UserThumbnail, UserThumbnailFragment } from "../cards/PersonCard";
import { Fragment as AttachmentFragment, List as CardAttachmentsList } from "../components/CardAttachmentsListGroup";
import MutationButton from "../components/MutationButton";
import { DeepPartial, merge, useReadyState, nextSequence } from "../hooks/ApiProvider";
import { useUpdatableState, useToggle } from "../hooks/CommonHooks";
import { useConfirmation } from "../hooks/ConfirmationProvider";
import { useCommentOperations, useReviewOperations } from "../hooks/DiscussionHook";
import { useMe } from "../hooks/MeProvider";
import { preventDefault, stopPropagation } from "../hooks/NavigationHook";
import { DateDisplayFormat, useTranslation } from "../hooks/TranslationProvider";
import { CommentInputModel, CommentModel, Maybe, ResourceKind, ResourcesSummaryModel, ResourceState, ReviewInputModel, ReviewModel, ReviewOption, ReviewsSummaryModel, ReviewType } from "../types/api-graph-types";
import { Markdown } from "./CardMarkdownText";
import { CustomDropdown, CustomDropdownItem } from "./CustomDropdown";

library.add(faTimes, farStar, fasStar, faStarHalfAlt, faEllipsisH, faEyeSlash, faUserFriends, faInfoCircle);

const _starFull = ["fas", "star"] as ["fas", "star"];
const _starHalf = ["fas", "star-half-alt"] as ["fas", "star-half-alt"];
const _starEmpty = ["far", "star"] as ["far", "star"];

const _commentFragment = `
  id
  kind
  message
  isPrivate
  isEdited
  createdOn
  updatedOn
  visibilityId
  author { name ${UserThumbnailFragment} }
  reviewsSummary { likeCount didLike }
  actions { change remove review }
  policy { accessToken }
`

export const CommentsFragment = `
  ${_commentFragment}
  comments { ${_commentFragment} }
`;

export const ReviewsFragment = `
  id
  kind
  rating
  message
  isPrivate
  visibilityId
  isEdited
  like
  love
  approved
  rejected
  options
  createdOn
  updatedOn
  author { name ${UserThumbnailFragment} }
  attachments { ${AttachmentFragment} }
  actions { change remove review }
  reviewsSummary { likeCount didLike }
  comments(first:20) {
    ${_commentFragment}
  }
`;

export const Fragment = `
  review { ${ReviewsFragment} }
  reviews(hasMessage:true) { ${ReviewsFragment} }
  comments(first:20) { ${CommentsFragment} }
  commentsSummary { count completedCount }
  reviewsSummary { likeCount loveCount ratingCount ratingAverage messageCount didLike didLove }
`

export type ReviewCriteria = [number, number, ReviewOption, number, string];

interface ResourceModel {
  readonly id: Maybe<string>;
  readonly kind: Maybe<ResourceKind>;
  readonly reviewsSummary: Maybe<ReviewsSummaryModel>;
  readonly commentsSummary: Maybe<ResourcesSummaryModel>;
  readonly review: Maybe<ReviewModel>;
  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewModel>>>;
  readonly comments: Maybe<ReadonlyArray<Maybe<CommentModel>>>;
}

export interface Settings {
  readonly defaultIsPrivate: boolean,
  readonly changeIsPrivate: boolean,
  readonly help?: string,
  readonly helpPrivate: ReactNode,
  readonly helpPublic: ReactNode,
  readonly visibilityId?: string,
}

const _replySettings: Settings = {
  defaultIsPrivate: false,
  changeIsPrivate: false,
  helpPrivate: null,
  helpPublic: null
}

function _paste(e: HTMLInputElement, text: string, setValue: (value: string) => void) {
  e.focus();
  if (typeof e.selectionStart == "number" && typeof e.selectionEnd == "number") {
    const value = e.value;
    const start = e.selectionStart;
    const next = value.slice(0, start) + text + value.slice(e.selectionEnd);
    e.value = next;
    e.selectionEnd = e.selectionStart = start + text.length;
    setValue(next);
  }
}

const _submitOnEnter = (submit: () => void, setValue: (value: string) => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.which === 13) {
    e.preventDefault();
    if (e.getModifierState("Control") || e.getModifierState("Shift")) {
      _paste(e.currentTarget, "\n", setValue);
    } else {
      submit();
    }
  }
}

function _byCreatedOn(a: CommentModel | ReviewModel, b: CommentModel | ReviewModel) {
  return (a.createdOn || "") <= (b.createdOn || "") ? -1 : 1
}

function _getRatingBoost(criteria: ReadonlyArray<ReviewCriteria>, nextOptions: Set<ReviewOption>) {
  return Math.max(-1, Math.min(1, criteria.filter(_ => nextOptions.has(_[2])).map(_ => _[3]).reduce((sum, v) => sum + v, 0)));
}

function _setEquals<T>(first: Set<T>, second: Set<T>): boolean {
  return first.size === second.size && Array.from(first).every(v => second.has(v));
}

function _toCommentInput(value: CommentModel | undefined, settings: Settings): CommentInputModel {
  return {
    isPrivate: value ? value.isPrivate : settings.defaultIsPrivate,
    message: value?.message || "",
    clientUri: "",
    referencesIds: [],
    visibilityId: value?.visibilityId || ""
  };
}

function _toReviewInput(value: Partial<ReviewModel>, settings: Settings): Partial<ReviewInputModel> {
  return {
    ...{} as ReviewInputModel,
    type: value.type,
    state: value.state,
    isPrivate: value ? value.isPrivate : settings.defaultIsPrivate,
    message: value?.message || "",
    clientUri: "",
    rating: value.rating,
    options: value.options,
    visibilityId: value.visibilityId
  };
}


export function SettingsMenu({ value, onUpdate, input, settings, compact, size, className, children }: {
  value: { isPrivate?: boolean },
  input?: boolean,
  compact?: boolean,
  size?: string,
  className?: string,
  settings: Settings,
  children?: ReactNode,
  onUpdate?: (value: DeepPartial<{ isPrivate: boolean }>) => void
}) {
  const label = compact ? undefined : value.isPrivate ? "Private" : "Standard";
  const icon: IconProp = value.isPrivate ? ["far", "eye-slash"] : "globe-asia";
  const toggleClassName = [
    "px-0",
    size ? `btn-${size}` : ""
  ].join(" ");
  return (
    <CustomDropdown input={input} button={!input} muted={input} className={className} icon={icon} label={label} color={input ? "input" : "link"} toggleClassName={toggleClassName} caret={!input}>
      <CustomDropdownItem checked={value.isPrivate} help={settings.helpPrivate} onClick={() => onUpdate?.({ isPrivate: true })} icon={["far", "eye-slash"]}>Private</CustomDropdownItem>
      <CustomDropdownItem checked={!value.isPrivate} help={settings.helpPublic} onClick={() => onUpdate?.({ isPrivate: false })} icon="globe-asia">Standard</CustomDropdownItem>
      {children}
    </CustomDropdown>
  );
}

function CommentForm({ objectId, objectKind, settings, references, value, placeholder, onUpdate, toggle: _toggle }: {
  objectId: string,
  objectKind: ResourceKind,
  settings: Settings,
  references?: CommentModel,
  value?: CommentModel,
  placeholder?: string,
  onUpdate?: (value: DeepPartial<CommentModel>) => void,
  toggle?: () => void
}) {
  const { addComment, updateComment, readyState } = useCommentOperations();
  const [showHelp, toggleHelp] = useToggle();
  const [input, setInput, updateInput] = useUpdatableState(_toCommentInput(value, settings));
  const reset = () => setInput(_toCommentInput(value, settings));
  useEffect(reset, [value?.message]);
  const post = async () => {
    const referencesIds = references ? [references.id] : undefined;
    const visibilityId = settings.visibilityId;
    const result = await addComment(objectId, objectKind, { ...input, visibilityId, referencesIds });
    onUpdate?.(result);
    cancel();
  }
  const update = value && (async () => {
    const result = await updateComment(value.id, input, undefined, value.policy?.accessToken);
    onUpdate?.(result);
    cancel();
  }) || undefined;
  const cancel = () => {
    reset();
    _toggle?.();
  }
  const showSettings = settings.changeIsPrivate;
  const oneline = input.message.length < 50 && input.message.indexOf("\n") === -1;
  const rows = oneline ? 1 : Math.max(3, input.message.split("\n").length + 1);
  const emptyMessage = input.message.length === 0;
  return (
    <div className="w-100">
      <Form className={`w-100 ${oneline ? "d-flex" : ""}`} onSubmit={preventDefault(post)}>
        <Input type="textarea" rows={rows} placeholder={placeholder || "Enter your comment here"} value={input.message} onChange={e => updateInput({ message: e.target.value })} onKeyPress={oneline ? _submitOnEnter(update || post, message => updateInput({ message })) : undefined} />
        {oneline && settings.help && <Button onClick={toggleHelp} color="input" className="text-muted"><FontAwesomeIcon icon="info-circle" /></Button>}
        {oneline && showSettings && <SettingsMenu settings={settings} value={input} onUpdate={updateInput} input compact />}
        {!oneline && settings.help &&
          <Collapse isOpen={showHelp}>
            <Markdown className="small text-muted" source={settings.help} />
          </Collapse>
        }
        <div className={oneline ? "d-flex" : "mt-2 d-flex"}>
          {!oneline && showSettings && <SettingsMenu settings={settings} value={input} onUpdate={updateInput} />}
          {!oneline && settings.help && <Button onClick={toggleHelp} color="link"><FontAwesomeIcon icon="info-circle" /></Button>}
          {update && <Button className="ml-auto" color={"link"} onClick={cancel}>Cancel</Button>}
          {update && <MutationButton readyState={readyState} color={emptyMessage ? "link" : "primary"} disabled={emptyMessage} onClick={update}>Update</MutationButton>}
          {!update && <MutationButton className="ml-auto" readyState={readyState} color={emptyMessage ? "link" : "primary"} disabled={emptyMessage} onClick={post}>Post</MutationButton>}
        </div>
      </Form>
      {oneline && settings.help &&
        <Collapse isOpen={showHelp}>
          <Markdown className="small text-muted" source={settings.help} />
        </Collapse>
      }
    </div>
  );
}

function ReviewForm({ objectId, objectKind, settings, criteria, value, onUpdate, onChangeRating, toggle: _toggle }: {
  objectId: string,
  objectKind: ResourceKind,
  settings: Settings,
  criteria: ReadonlyArray<ReviewCriteria>,
  value?: ReviewModel,
  onUpdate?: (value: DeepPartial<ReviewModel>) => void,
  onChangeRating?: (value: number) => void,
  toggle?: () => void
}) {
  const initialOptions = new Set(value?.options || []);
  const initialRatingBoost = _getRatingBoost(criteria, initialOptions);
  const initialRatingBase = Math.max(0, (value && value.rating || 0) - initialRatingBoost);
  const initialMessage = value?.message || "";
  const initialIsPrivate = value ? value.isPrivate : settings.defaultIsPrivate;
  const [t] = useTranslation();
  const [uid] = useState(nextSequence());
  const { updateReview } = useReviewOperations();
  const [readyState, setReadyState] = useReadyState();
  const [message, setMessage] = useState(initialMessage);
  const [isPrivate, setIsPrivate] = useState(initialIsPrivate);
  const [options, setOptions] = useState(initialOptions);
  const [ratingBoost, setRatingBoost] = useState(initialRatingBoost);
  const [ratingBase, setRatingBase] = useState(initialRatingBase);
  const rating = ratingBase + ratingBoost;
  const reset = () => {
    setIsPrivate(initialIsPrivate);
    setMessage(initialMessage);
    setOptions(initialOptions);
    setRatingBoost(initialRatingBoost);
    setRatingBase(initialRatingBase);
  }
  useEffect(reset, [value?.id]);
  useEffect(() => onChangeRating?.(rating), [rating]);

  const hasCriteria = (value: ReviewCriteria) => {
    return value[2] !== ReviewOption.Null ? options.has(value[2])
      : !!value[4] ? message.indexOf(value[4]) >= 0
        : false;
  }
  const activeCriteria = criteria.filter(_ => hasCriteria(_) || (ratingBase >= _[0] && ratingBase <= _[1]));
  const toggleCriteria = (value: ReviewCriteria) => () => {
    const _nextOptions = value[2] === ReviewOption.Null ? Array.from(options)
      : options.has(value[2]) ? Array.from(options).filter(_ => _ !== value[2])
        : Array.from(options).concat([value[2]]);
    const nextOptions = new Set(_nextOptions);
    const criteriaMessage = value[4] || t(value[2]) || "";
    const nextMessage = !criteriaMessage ? message
      : message.indexOf(criteriaMessage) >= 0 ? message.replace(`${criteriaMessage}.`, "").replace(criteriaMessage, "").trim()
        : `${message.replace(/\.*$/, "")}${message ? "." : ""} ${criteriaMessage}`.trim();
    const nextRatingBoost = _getRatingBoost(criteria, nextOptions);

    setOptions(nextOptions);
    setRatingBoost(nextRatingBoost);
    setMessage(nextMessage.trim());
  }
  const toggleOption = (value: ReviewOption, remove?: ReviewOption) => () => {
    const nextOptions = options.has(value)
      ? new Set(Array.from(options).filter(_ => _ !== value && _ !== remove))
      : new Set(Array.from(options).filter(_ => _ !== remove)).add(value);
    setOptions(nextOptions);
  }
  const update = async () => {
    const result = await updateReview(objectId, objectKind, { state: ResourceState.Resolved, rating, isPrivate, message, options: Array.from(options) }, setReadyState);
    onUpdate?.(result);
    toggle();
  }
  const add = async () => {
    const visibilityId = settings.visibilityId;
    const result = await updateReview(objectId, objectKind, { state: ResourceState.Resolved, rating, isPrivate, visibilityId, message, options: Array.from(options) }, setReadyState);
    onUpdate?.(result);
    toggle();
  }
  const toggle = () => {
    reset();
    _toggle?.();
  }
  const showSettings = settings.changeIsPrivate;
  const isModified = (initialMessage !== message)
    || (initialRatingBase !== ratingBase)
    || (initialRatingBoost !== ratingBoost)
    || (initialIsPrivate !== isPrivate)
    || !_setEquals(initialOptions, options);
  const rows = Math.max(3, message.split("\n").length + 1);
  return (
    <Form onSubmit={preventDefault(update)} className="w-100">
      <FormGroup className="d-flex hover-container">
        <ButtonGroup className="mr-auto">
          <Button title="Poor" className="m-0 p-0" color="link" onClick={() => setRatingBase(2)}><FontAwesomeIcon fixedWidth icon={rating > 1 ? _starFull : rating > 0 ? _starHalf : _starEmpty} /></Button>
          <Button title="Fair" className="m-0 p-0" color="link" onClick={() => setRatingBase(4)}><FontAwesomeIcon fixedWidth icon={rating > 3 ? _starFull : rating > 2 ? _starHalf : _starEmpty} /></Button>
          <Button title="Average" className="m-0 p-0" color="link" onClick={() => setRatingBase(6)}><FontAwesomeIcon fixedWidth icon={rating > 5 ? _starFull : rating > 4 ? _starHalf : _starEmpty} /></Button>
          <Button title="Good" className="m-0 p-0" color="link" onClick={() => setRatingBase(8)}><FontAwesomeIcon fixedWidth icon={rating > 7 ? _starFull : rating > 6 ? _starHalf : _starEmpty} /></Button>
          <Button title="Excellent" className="m-0 p-0" color="link" onClick={() => setRatingBase(9)}><FontAwesomeIcon fixedWidth icon={rating > 9 ? _starFull : rating > 8 ? _starHalf : _starEmpty} /></Button>
        </ButtonGroup>
        <Button className={`ml-1 p-0 ${options.has(ReviewOption.Love) ? "" : "hover-display hover-opacity-125"}`} color="link" onClick={toggleOption(ReviewOption.Love, ReviewOption.Like)}><FontAwesomeIcon icon={["far", "heart"]} /></Button>
        <Button className={`ml-1 p-0 ${options.has(ReviewOption.Like) ? "" : "hover-display hover-opacity-125"}`} color="link" onClick={toggleOption(ReviewOption.Like, ReviewOption.Love)}><FontAwesomeIcon icon={["far", "thumbs-up"]} /></Button>
      </FormGroup>
      {activeCriteria.length > 0 &&
        <FormGroup>
          {activeCriteria.map((_, i) => <CustomInput key={i} id={`${uid}.0.${i}`} type="checkbox" label={_[4] || t(_[2])} checked={hasCriteria(_)} onChange={toggleCriteria(_)} />)}
        </FormGroup>
      }
      <FormGroup>
        <Input type="textarea" rows={rows} className="w-100" placeholder="Enter your review here" value={message} onChange={e => setMessage(e.target.value)} />
      </FormGroup>
      <FormGroup className="d-flex">
        {showSettings && <SettingsMenu settings={settings} value={{ isPrivate }} onUpdate={e => setIsPrivate(e.isPrivate || false)} />}
        <Button className="ml-auto" color="link" onClick={toggle}>Cancel</Button>
        {value && value.id && <MutationButton readyState={readyState} color="primary" disabled={rating === 0 || !isModified} onClick={update}>Update</MutationButton>}
        {(!value || !value.id) && <MutationButton readyState={readyState} color="primary" disabled={rating === 0 || !isModified} onClick={add}>Post</MutationButton>}
      </FormGroup>
    </Form>
  );
}

export function CommentInputItem({ objectId, objectKind, settings, onUpdate, ...attrs }: {
  objectId: string,
  objectKind: ResourceKind,
  settings: Settings,
  onUpdate?: (value: DeepPartial<CommentModel>) => void,
} & ListGroupItemProps) {
  const [me] = useMe();
  return (
    <ListGroupItem className="d-flex align-items-start" onClick={stopPropagation} {...attrs}>
      <UserThumbnail className="mr-3" width={32} value={me || Anonymous} />
      <CommentForm {...{ objectId, objectKind, settings, onUpdate }} />
    </ListGroupItem>
  );
}

export function ReviewInputItem({ objectId, objectKind, settings, criteria, className, onUpdate }: {
  objectId: string,
  objectKind: ResourceKind,
  settings: Settings,
  criteria: ReadonlyArray<ReviewCriteria>,
  className?: string,
  onUpdate?: (value: ReviewModel) => void
}) {
  const [me] = useMe();
  const [rating, setRating] = useState(0);
  const isApproved = rating > 7;
  const isRejected = rating > 0 && rating < 7;
  return (
    <ListGroupItem className={`d-flex align-items-top ${className}`} onClick={stopPropagation}>
      <UserThumbnail className="mr-3" width={32} value={me || Anonymous} icon={isApproved ? "check" : isRejected ? "times" : undefined} iconColor={isApproved ? "success" : isRejected ? "warning" : undefined} />
      <ReviewForm {...{ objectId, objectKind, settings, onUpdate }} criteria={criteria} onChangeRating={setRating} />
    </ListGroupItem>
  );
}

export function CommentItem({ value, objectId, objectKind, settings, onUpdate }: {
  objectId: string,
  objectKind: ResourceKind,
  value: CommentModel,
  settings: Settings,
  onUpdate?: (value: DeepPartial<CommentModel>) => void
}) {
  const [me] = useMe();
  const confirm = useConfirmation();
  const [, d] = useTranslation();
  const [references, setReferences] = useState<CommentModel>();
  const [editingId, setEditingId] = useState("");
  const { updateComment, removeComment } = useCommentOperations();
  const { toggleReviewOption } = useReviewOperations();
  const updateReply = (comment: DeepPartial<CommentModel>) => onUpdate?.({ id: value.id, comments: [comment], rootCommentsSummary: comment.rootCommentsSummary });
  const toggleReply = () => setEditingId("reply");
  const toggleCommentReply = (value: CommentModel) => () => {
    setReferences(value);
    setEditingId("reply");
  }
  const toggleEdit = () => setEditingId(value.id);
  const toggleEditReply = (value: CommentModel) => setEditingId(value.id);
  const toggle = () => {
    setEditingId("");
    setReferences(undefined);
  }
  const remove = async () => {
    const result = await removeComment(value.id, value.policy?.accessToken);
    onUpdate?.(result);
    toggle();
  }
  const removeReply = async (id: string) => {
    const result = await removeComment(id);
    updateReply?.(result);
    toggle();
  }
  const toggleLike = async (comment: CommentModel) => {
    const result = await toggleReviewOption(comment.id, ResourceKind.Comment, ReviewOption.Like);
    const updated = { id: comment.id, reviewsSummary: result.relatedSummary };
    onUpdate?.(value.id === comment.id ? updated : { id: value.id, comments: [updated] });
  }
  const update = async (partial: DeepPartial<CommentInputModel>) => {
    const input = _toCommentInput({ ...value, ...partial }, settings);
    const result = await updateComment(value.id, input);
    onUpdate?.(result);
  }
  return (
    <ListGroupItem className="d-flex align-items-start">
      <UserThumbnail className="mr-3" width={32} value={value.author} />
      <div className="d-flex flex-column w-100">
        <div className="d-flex align-items-center hover-container">
          {value.author?.name && <small className="text-truncate">{value.author.name}</small>}
          {value.isEdited && <small className='text-muted ml-1'>edited</small>}
          <small className='text-muted ml-1'>{d(value.updatedOn, DateDisplayFormat.DateShortSameYear)}</small>
          {value.reviewsSummary.likeCount > 0 && <small className="ml-3 text-nowrap"><FontAwesomeIcon icon={["far", "thumbs-up"]} /> {value.reviewsSummary.likeCount}</small>}
          {value.actions.review && !value.reviewsSummary.didLike && <Button size="sm" color="link" className="ml-3 p-0 b-0" onClick={() => toggleLike(value)}>Helpful</Button>}
          <Button size="sm" color="link" className="ml-3 p-0 b-0" onClick={toggleReply}>Reply</Button>
          {value.actions.change && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={toggleEdit}>Edit</Button>}
          {value.actions.remove && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={confirm(remove, "Are you sure you want to remove this comment?")}>Remove</Button>}
          {value.actions.review && value.reviewsSummary.didLike && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={() => toggleLike(value)}>Undo helpful</Button>}
          {value.isPrivate && <SettingsMenu className="ml-auto" size="sm" value={value} settings={settings} onUpdate={update} />}
        </div>
        {editingId !== value.id && <Markdown>{value.message}</Markdown>}
        {editingId === value.id && <CommentForm {...{ value, objectId, objectKind, settings, onUpdate, toggle }} />}
        {value.comments && value.comments.filter(_ => _.state !== ResourceState.Removed).sort(_byCreatedOn).map((_, i) =>
          <div key={_.id} className="mt-2 d-flex align-items-start">
            <UserThumbnail className="mr-2" width={24} value={_.author} />
            <div className="d-flex flex-column w-100">
              <div className="d-flex align-items-center hover-container">
                <small className="text-truncate">{_.author.name}</small>
                {_.isEdited && <small className='text-muted ml-1'>edited</small>}
                <small className='text-muted ml-1'>{d(_.updatedOn, DateDisplayFormat.DateShortSameYear)}</small>
                {_.reviewsSummary.likeCount > 0 && <small className="ml-3 text-nowrap"><FontAwesomeIcon icon={["far", "thumbs-up"]} /> {_.reviewsSummary.likeCount}</small>}
                {_.actions.review && !_.reviewsSummary.didLike && <Button size="sm" color="link" className="ml-3 p-0 b-0" onClick={() => toggleLike(_)}>Helpful</Button>}
                <Button size="sm" color="link" className="ml-3 p-0 b-0" onClick={toggleCommentReply(_)}>Reply</Button>
                {_.actions.change && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={() => toggleEditReply(_)}>Edit</Button>}
                {_.actions.remove && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={confirm(() => removeReply(_.id), "Are you sure you want to remove this comment?")}>Remove</Button>}
                {_.actions.review && _.reviewsSummary.didLike && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={() => toggleLike(_)}>Undo helpful</Button>}
                {_.isPrivate && <SettingsMenu className="ml-auto" size="sm" value={_} settings={settings} />}
              </div>
              {editingId !== _.id && <Markdown>{_.message}</Markdown>}
              {editingId === _.id && <CommentForm objectId={value.id} objectKind={ResourceKind.Comment} settings={_replySettings} value={_} onUpdate={updateReply} toggle={toggle} placeholder="Enter your reply here" />}
            </div>
          </div>
        )}
        <Collapse isOpen={editingId === "reply"} className="w-100">
          <div className="mt-2 d-flex align-items-start">
            <UserThumbnail className="mr-2" width={24} value={me || Anonymous} />
            <CommentForm objectId={value.id} objectKind={ResourceKind.Comment} settings={_replySettings} references={references} onUpdate={updateReply} toggle={toggle} placeholder="Enter your reply here" />
          </div>
        </Collapse>
      </div>
    </ListGroupItem >
  );
}


export function ReviewItem({ value, objectId, objectKind, settings, criteria, onUpdate }: {
  value: ReviewModel,
  objectId: string,
  objectKind: ResourceKind,
  settings: Settings,
  criteria: ReadonlyArray<ReviewCriteria>,
  onUpdate?: (value: DeepPartial<ReviewModel>) => void
}) {
  const [me] = useMe();
  const confirm = useConfirmation();
  const [, d] = useTranslation();
  const [rating, setRating] = useState(value.rating);
  const [editingId, setEditingId] = useState("");
  const [references, setReferences] = useState<CommentModel>();
  const { updateReview, removeReview, toggleReviewOption } = useReviewOperations()
  const { removeComment } = useCommentOperations();
  const toggleReply = () => setEditingId("reply");
  const toggleCommentReply = (value: CommentModel) => () => {
    setReferences(value);
    setEditingId("reply");
  }
  const toggleEdit = () => setEditingId(value.id);
  const toggleEditReply = (value: CommentModel) => setEditingId(value.id);
  const toggle = () => {
    setEditingId("");
    setReferences(undefined);
  }
  const updateReply = (comment: DeepPartial<CommentModel>) => onUpdate?.({ id: value.id, comments: [comment], commentsSummary: comment.rootCommentsSummary });
  const remove = async () => {
    const result = await removeReview(value.id, value.policy?.accessToken);
    onUpdate?.(result);
  }
  const toggleLike = async () => {
    const result = await toggleReviewOption(value.id, ResourceKind.Review, ReviewOption.Like);
    onUpdate?.({ id: value.id, reviewsSummary: result.relatedSummary });
  }
  const toggleReplyLike = async (comment: CommentModel) => {
    const result = await toggleReviewOption(comment.id, ResourceKind.Comment, ReviewOption.Like);
    onUpdate?.({ id: value.id, comments: [{ id: comment.id, reviewsSummary: result.relatedSummary }] });
  }
  const removeReply = async (id: string) => {
    const result = await removeComment(id);
    updateReply?.(result);
    toggle();
  }
  const update = async (partial: Partial<ReviewModel>) => {
    const input = _toReviewInput({ ...value, ...partial }, settings);
    const result = await updateReview(objectId, objectKind, input);
    onUpdate?.(result);
  }
  const isEditing = editingId === value.id;
  const approved = isEditing ? rating > 7 : value.approved;
  const rejected = isEditing ? rating > 0 && rating < 7 : value.rejected;
  return (
    <ListGroupItem className="d-flex align-items-start">
      <UserThumbnail className="mr-3" width={32} value={value.author} title={`${(rating / 2).toFixed(1)} stars`} icon={approved ? "check" : rejected ? "times" : undefined} iconColor={approved ? "success" : rejected ? "warning" : undefined} />
      <div className="d-flex flex-column w-100">
        <div className="d-flex align-items-center hover-container">
          <small className="text-truncate">{value.author.name}</small>
          {value.isEdited && <small className='text-muted ml-1'>edited</small>}
          <small className='text-muted ml-1'>{d(value.updatedOn, DateDisplayFormat.DateShortSameYear)}</small>
          {value.reviewsSummary.likeCount > 0 && <small className="ml-3 text-nowrap"><FontAwesomeIcon icon={["far", "thumbs-up"]} /> {value.reviewsSummary.likeCount}</small>}
          {value.actions.review && !value.reviewsSummary.didLike && <Button size="sm" color="link" className="ml-3 p-0 b-0" onClick={toggleLike}>Helpful</Button>}
          <Button size="sm" color="link" className="ml-3 p-0 b-0" onClick={toggleReply}>Reply</Button>
          {value.actions.change && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={toggleEdit}>Edit</Button>}
          {value.actions.remove && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={confirm(remove, "Are you sure you want to remove this review?")}>Remove</Button>}
          {value.actions.review && value.reviewsSummary.didLike && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={toggleLike}>Undo helpful</Button>}
          {value.isPrivate && <SettingsMenu className="ml-auto" size="sm" value={value} settings={settings} onUpdate={update} />}
        </div>
        {editingId !== value.id && value.message && <Markdown>{value.message}</Markdown>}
        {editingId !== value.id && <CardAttachmentsList className="mt-2" values={value.attachments} />}
        {editingId === value.id && <ReviewForm {...{ objectId, objectKind, settings, value, onUpdate, toggle }} criteria={criteria} onChangeRating={setRating} />}
        {value.comments && Array.from(value.comments).sort(_byCreatedOn).map(_ =>
          <div key={_.id} className="mt-2 d-flex align-items-start">
            <UserThumbnail className="mr-2" width={24} value={_.author} />
            <div className="d-flex flex-column w-100">
              <div className="d-flex align-items-center hover-container">
                <small className="text-truncate">{_.author.name}</small>
                {_.isEdited && <small className='text-muted ml-1'>edited</small>}
                <small className='text-muted ml-1'>{d(_.updatedOn, DateDisplayFormat.DateShortSameYear)}</small>
                {_.isPrivate && <FontAwesomeIcon className="small text-muted ml-3" icon={["far", "eye-slash"]} />}
                {_.reviewsSummary.likeCount > 0 && <small className="ml-3 text-nowrap"><FontAwesomeIcon icon={["far", "thumbs-up"]} /> {_.reviewsSummary.likeCount}</small>}
                {_.actions.review && !_.reviewsSummary.didLike && <Button size="sm" color="link" className="ml-3 p-0 b-0" onClick={() => toggleReplyLike(_)}>Helpful</Button>}
                <Button size="sm" color="link" className="ml-3 p-0 b-0" onClick={toggleCommentReply(_)}>Reply</Button>
                {_.actions.change && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={() => toggleEditReply(_)}>Edit</Button>}
                {_.actions.remove && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={confirm(() => removeReply(_.id), "Are you sure you want to remove this comment?")}>Remove</Button>}
                {_.actions.review && _.reviewsSummary.didLike && <Button size="sm" color="link" className="ml-3 p-0 b-0 hover-visible" onClick={() => toggleReplyLike(_)}>Undo helpful</Button>}
              </div>
              {editingId !== _.id && <Markdown>{_.message}</Markdown>}
              {editingId === _.id && <CommentForm objectId={value.id} objectKind={ResourceKind.Review} settings={_replySettings} value={_} onUpdate={updateReply} toggle={toggle} placeholder="Enter your reply here" />}
            </div>
          </div>
        )}
        <Collapse isOpen={editingId === "reply"} className="w-100">
          <div className="mt-2 d-flex align-items-start">
            <UserThumbnail className="mr-2" width={24} value={me || Anonymous} />
            <CommentForm objectId={value.id} objectKind={ResourceKind.Review} settings={_replySettings} references={references} onUpdate={updateReply} toggle={toggle} placeholder="Enter your reply here" />
          </div>
        </Collapse>
      </div>
    </ListGroupItem >
  );
}

export default ({ value, criteria, right, isOpen, onUpdate, help, helpPublic, helpPrivate, visibilityPrivate, forcePublic = false, showReview, openReview: _openReview, ...attrs }: {
  value: ResourceModel,
  right?: boolean,
  visibilityPrivate?: string,
  help?: string,
  helpPrivate?: string,
  helpPublic?: string,
  forcePublic?: boolean,
  showReview?: boolean,
  openReview?: boolean,
  isOpen?: boolean,
  criteria?: ReadonlyArray<ReviewCriteria>,
  onUpdate?: (value: DeepPartial<ResourceModel>) => void
} & ListGroupItemProps) => {
  const hasReview = !!value.review;
  const reviews = value.review ? merge(value.reviews, [value.review]) : value.reviews;
  const openReview = showReview && _openReview && !hasReview;
  const settings: Settings = {
    defaultIsPrivate: !!visibilityPrivate && !forcePublic,
    changeIsPrivate: !forcePublic,
    help: help,
    helpPublic: helpPublic,
    helpPrivate: helpPrivate,
    visibilityId: visibilityPrivate
  };
  const props = { objectId: value.id, objectKind: value.kind, settings };
  return (
    <Collapse isOpen={isOpen} className={right ? "h-100" : undefined}>
      <ListGroup className={right ? "bl-1 h-100" : "bt-1"} flush {...attrs}>
        {reviews && Array.from(reviews).sort(_byCreatedOn).map((_, i) => <ReviewItem key={i} {...props} criteria={criteria || []} value={_} onUpdate={review => onUpdate?.({ id: value.id, review, reviews: [review], reviewsSummary: review.relatedSummary })} />)}
        {value.comments && Array.from(value.comments).sort(_byCreatedOn).map((_, i) => <CommentItem {...props} key={i} value={_} onUpdate={comment => onUpdate?.({ id: value.id, comments: [comment], commentsSummary: comment.rootCommentsSummary })} />)}
        {openReview && <ReviewInputItem {...props} className={right ? "sticky-top" : undefined} criteria={criteria || []} onUpdate={review => onUpdate?.({ id: value.id, review, reviews: [review], reviewsSummary: review.relatedSummary })} />}
        {!openReview && <CommentInputItem {...props} onUpdate={comment => onUpdate?.({ id: value.id, comments: [comment], commentsSummary: comment.rootCommentsSummary })} />}
      </ListGroup>
    </Collapse>
  );
}
