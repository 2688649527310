import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import CardMarkdownText from "../components/CardMarkdownText";
import { useQuery } from "../hooks/ApiProvider";

library.add(faTimes);

const _getPolicy = `query getPolicy($name:String) {
  policy: file(namespace:"policies" name:$name)
}`;

interface Result {
  policy: string
}

export const Policies = {
  TermsOfUse: "terms",
  Privacy: "privacy",
  CookieNotice: "cookie-notice",
  Trademark: "trademark",
  CodeOfConduct: "code-of-conduct",
  Refunds: "refunds",
}

export default ({ name, className, isOpen, showClose, toggle, onAccept, onDecline }: {
  name: string,
  className?: string,
  isOpen?: boolean,
  showClose?: boolean,
  toggle?: () => void,
  onAccept?: () => void,
  onDecline?: () => void,
}) => {
  const [result] = useQuery<Result>(_getPolicy, { name });
  const value = result?.policy;
  const accept = () => {
    onAccept?.();
    toggle?.();
  }
  const decline = () => {
    onDecline?.();
    toggle?.();
  }
  return (
    <Modal className={className} isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        {showClose &&
          <Button color="link" className="close text-muted" onClick={toggle}>
            <FontAwesomeIcon icon="times" />
          </Button>
        }
        <CardMarkdownText>{value}</CardMarkdownText>
      </ModalBody>
      <ModalFooter>
        {onDecline && <Button color="link" onClick={decline}>Decline</Button>}
        {onAccept && <Button color="primary" onClick={accept}>Accept</Button>}
        {!onAccept && !onDecline && <Button color="primary" onClick={toggle}>Close</Button>}
      </ModalFooter>
    </Modal>
  );
}

