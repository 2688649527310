import moment from "moment";
import React, { createContext, ReactNode, useContext } from "react";
import { AreaType, AttachmentType, CityCode, CurrencyCode, ErrorType, ExternalAccountType, FunctionType, IndustryType, LanguageCode, LanguagePreferenceOption, LocationPreferenceOption, OrderStage, ProductType, ProductVariantType, ResourceRole, ResourceState, ReviewOption, ServiceType, TaskType, TopicPreferenceOption, WorkItemType } from "../types/api-graph-types";
import { PaymentTargetType } from "../types/common-types";

export enum DateDisplayFormat {
  Date = "DATE",
  DateRange = "DATE_RANGE",
  DateShort = "SHORT",
  DateLong = "LONG",
  DateShortSameYear = "SHORT_Y",
  HumanDate = "HUMAN_DATE",
  HumanDateRange = "HUMAN_DATE_RANGE",
  HumanDateTime = "HUMAN_DATE_TIME",
  HumanDuration = "HUMAN_DURATION",
  HumanTime = "HUMAN_TIME",
  HumanTimeRange = "HUMAN_TIME_RANGE",
  IsAfter = "IS_AFTER",
  Month = "YEAR_MONTH",
  MonthDay = "MONTH_DAY",
  MonthDayRange = "MONTH_DAY_RANGE",
  TimeAgo = "TIME_AGO",
  TimeUntil = "TIME_UNTIL",
  YearDurationRange = "YEAR_DURATION_RANGE",
  NegativeYearDurationRange = "NEGATIVE_YEAR_DURATION_RANGE",
  YearDurationRangeAgo = "YEAR_DURATION_RANGE_AGO",
  YearDurationRangeIn = "YEAR_DURATION_RANGE_IN",
  YearMonthRange = "YEAR_MONTH_RANGE",
  YearRange = "YEAR_RANGE",
  YearShort = "YEAR_SHORT",
}

export enum NumberDisplayFormat {
  Null = "NULL",
  Human = "HUMAN_NUMBER",
  HumanRange = "HUMAN_NUMBER_RANGE",
  Currency = "CURRENCY",
}

type DateTime = any;
type FormatDate = (value?: DateTime, format?: DateDisplayFormat, otherValue?: DateTime, thenValue?: string, elseValue?: string) => string;
export type FormatEnum = (key?: string) => string;
type FormatNumber = (number: number, format?: NumberDisplayFormat, otherValue?: number, prepend?: string) => string;
const initialContext: any = undefined;
const Context = createContext<[FormatEnum, FormatDate, FormatNumber]>(initialContext);

export const toLocal = (value: string) => {
  const v = value ? moment(value) : undefined;
  return v && v.isValid() ? v.local().format("YYYY-MM-DDTHH:mm:ss") : undefined;
}
export const toUtc = (value: string) => {
  const v = moment(value);
  return v.isValid() ? moment(value).utc().format() : undefined;
}
export function toLocalDate(value: string | undefined | null) {
  return value && moment(value).local().toDate() || null;
}
export function toUtcString(value: Date | null) {
  return value && moment(value).utc().toISOString() || null;
}

export function toLocalString(value: Date | null) {
  return value && moment(value).toISOString() || null;
}

export function toEndOfDay(value: Date | null) {
  value = value ? moment(value).startOf("day").add(1, "day").toDate() : value;
  return value;
}

export function toStartOfDay(value: Date | null) {
  value && value.setHours(0, 0, 0, 0);
  return value;
}

const strings = new Map<string, string>([
  [ProductVariantType.Location, "Location"],
  [ProductVariantType.ServiceAreas, "Service Areas"],
  [ProductVariantType.LocationServiceAreas, "Location and Service Areas"],
  [ExternalAccountType.Amazon, "Amazon"],
  [ExternalAccountType.Facebook, "Facebook"],
  [ExternalAccountType.LinkedIn, "LinkedIn"],
  [ExternalAccountType.Google, "Google"],
  [ExternalAccountType.Email, "Email"],
  [ExternalAccountType.Phone, "Phone"],
  [ExternalAccountType.Mail, "Address"],
  [ExternalAccountType.Instagram, "Instagram"],
  [ExternalAccountType.QuickBooks, "QuickBooks"],
  [ExternalAccountType.Twitter, "Twitter"],
  [ExternalAccountType.Microsoft, "Microsoft"],
  [ExternalAccountType.Payment, "Payment"],
  [ExternalAccountType.Authenticator, "Authenticator"],
  [PaymentTargetType.Upi, "UPI - Unified Payments Interface"],
  [PaymentTargetType.Ifsc, "IFSC - India Bank Account"],
  [PaymentTargetType.Iban, "IBAN - International Bank Account"],
  [PaymentTargetType.Ilp, "Interledger"],
  [PaymentTargetType.Bitcoin, "Bitcoin"],
  [PaymentTargetType.Ach, "ACH - US Bank Account"],
  [ErrorType.InvalidEmail, "Email address is not valid."],
  [ErrorType.InvalidPhone, "Phone number is not valid."],
  [ErrorType.InvalidVerificationCode, "OTP is not valid."],
  [ErrorType.Unauthorized, "Not authorized."],
  [ErrorType.Invalid, "Not valid."],
  [ErrorType.Exhausted, "Too many attempts."],
  [ResourceState.Removed, "Archived"],
  [ResourceState.Completed, "Completed"],
  [ResourceState.Resolved, "Resolved"],
  [ResourceState.InProgress, "In-progress"],
  [ResourceState.Proposed, "Proposed"],
  [WorkItemType.LegalResearch, "Research"],
  [ProductType.EmploymentTrainingLevel, "Internship"],
  [ProductType.EmploymentEntryLevel, "Entry-level Position"],
  [ProductType.EmploymentSeniorLevel, "Senior-level Position"],
  [ProductType.EmploymentManagerLevel, "Manager Position"],
  [ProductType.EmploymentDirectorLevel, "Director Position"],
  [ProductType.EmploymentVicePresidentLevel, "VP Position"],
  [ProductType.EmploymentChiefOfficerLevel, "CxO Position"],
  [ProductType.EmploymentPartnerLevel, "Partner Position"],
  [ProductType.EmploymentUnpaidLevel, "Volunteer Position"],
  [ProductType.CustomerService, "Customer Service"],
  [TaskType.LegalResearchRephrasing, "Issue"],
  [TaskType.LegalResearchBackground, "Background"],
  [TaskType.LegalResearchStatute, "Statute"],
  [TaskType.LegalResearchPrecedent, "Precedent"],
  [TaskType.LegalResearchConclusion, "Conclusion"],
  [CityCode.InBlr, "Bengaluru"],
  [CityCode.InBom, "Mumbai"],
  [CityCode.InDel, "Delhi"],
  [CityCode.InMaa, "Chennai"],
  [CityCode.InPnq, "Pune"],
  [CityCode.InHyd, "Hyderabad"],
  [CityCode.InAmd, "Ahmedabad"],
  [CityCode.InCcu, "Kolkata"],
  [CityCode.InJai, "Jaipur"],
  [CityCode.InCok, "Kochi"],
  [CityCode.InIdr, "Indore"],
  [CityCode.InNag, "Nagpur"],
  [CityCode.InPat, "Patna"],
  [CityCode.InKnu, "Kanpur"],
  [CityCode.InUpNoi, "Noida"],
  [CityCode.InDlNcr, "Delhi area (NCR)"],
  [CityCode.InHrGur, "Gurugram"],
  [CityCode.Other, "Other"],
  ["NULL", ""],
  [FunctionType.LegalFunction, "Legal"],
  [FunctionType.AccountingFunction, "Accounting"],
  [FunctionType.BusinessDevelopmentFunction, "Business Development"],
  [FunctionType.HumanResourcesFunction, "Human Resources"],
  [FunctionType.EducationFunction, "Education"],
  [FunctionType.HealthcareFunction, "Healthcare"],
  [FunctionType.MediaFunction, "Media"],
  [FunctionType.InformationTechnologyFunction, "Information Technology"],
  [FunctionType.AdministrativeFunction, "Administrative"],
  [FunctionType.CommunityFunction, "Community and Social"],
  [FunctionType.ConsultingFunction, "Consulting"],
  [FunctionType.DesignFunction, "Design"],
  [FunctionType.EngineeringFunction, "Engineering"],
  [FunctionType.EntrepreneurshipFunction, "Entrepreneurship"],
  [FunctionType.FinanceFunction, "Finance"],
  [FunctionType.MarketingFunction, "Marketing"],
  [FunctionType.OperationsFunction, "Operations"],
  [FunctionType.ProductManagementFunction, "Product Management"],
  [FunctionType.ProtectionFunction, "Security"],
  [FunctionType.PurchasingFunction, "Purchasing"],
  [FunctionType.QualityAssuranceFunction, "Quality Assurance"],
  [FunctionType.RealEstateFunction, "Facilities and Real-Estate"],
  [FunctionType.ResearchFunction, "Research"],
  [FunctionType.SalesFunction, "Sales"],
  [FunctionType.SupportFunction, "Customer Support"],
  [FunctionType.SoftwareEngineeringFunction, "Software Engineering"],
  [IndustryType.LegalIndustry, "Legal Services"],
  [IndustryType.AccountingIndustry, "Accounting Services"],
  [IndustryType.MediaIndustry, "Mass Media"],
  [IndustryType.InformationTechnologyIndustry, "Information Technology"],
  [IndustryType.HealthcareIndustry, "Healthcare"],
  [IndustryType.EducationIndustry, "Education"],
  [ServiceType.LegalServiceFullService, "Full-service Law Firm"],
  [ServiceType.LegalServiceBoutique, "Boutique Law Firm"],
  [ServiceType.LegalServiceSoloPractitioner, "Solo Practitioner"],
  [ServiceType.LegalServiceCorporateTransactional, "Corporate Transactions"],
  [ServiceType.LegalServiceInHouseCounsel, "In-house Counsel"],
  [ServiceType.LegalServiceInHouse, "In-house Counsel"],
  [ServiceType.LegalServiceCivilLitigation, "Civil Litigation"],
  [ServiceType.LegalServiceTransactional, "Transactions"],
  [ServiceType.LegalServiceOutsourcing, "Legal Outsourcing"],
  [ServiceType.LegalServiceJudicial, "Judicial"],
  [ServiceType.LegalServiceResearch, "Legal Research"],
  [ServiceType.LegalServiceCriminalLitigation, "Criminal Litigation"],
  [ServiceType.LegalServiceLitigation, "Litigation"],
  [ServiceType.LegalServiceVirtualFirm, "Virtual Law Firm"],
  [ServiceType.AccountingServiceBigFour, "Big-four Accounting Firm"],
  [ServiceType.AccountingServiceMidTier, "Mid-tier Accounting Firm"],
  [ServiceType.AccountingServiceRegional, "Regional Accounting Firm"],
  [ServiceType.AccountingServiceBoutique, "Boutique Accounting Firm"],
  [ServiceType.AccountingServiceLocal, "Local Accounting Firm"],
  [ServiceType.AccountingServicePublicFinance, "Public Finance"],
  [ServiceType.AccountingServiceCorporateFinance, "Corporate Finance"],
  [ServiceType.AccountingServiceActuarial, "Actuarial"],
  [ServiceType.AccountingServiceAudit, "Audit"],
  [ServiceType.AccountingServiceAssurance, "Assurance"],
  [ServiceType.AccountingServiceTax, "Taxation"],
  [ServiceType.AccountingServicePayroll, "Payroll"],
  [ServiceType.AccountingServiceBookkeeping, "Boookkeeping"],
  [ServiceType.BusinessDevelopmentServiceLeadGeneration, "Lead Generation"],
  [ServiceType.BusinessDevelopmentServiceCustomerRelationshipManagement, "Customer Relationships"],
  [ServiceType.BusinessDevelopmentServicePartnerRelationshipManagement, "Partner Relationships"],
  [ServiceType.BusinessDevelopmentServiceMarketAnalysis, "Market Analysis"],
  [ServiceType.BusinessDevelopmentServiceBusinessStrategyDevelopment, "Business Strategy"],
  [ServiceType.SoftwareEngineeringServiceFullStackDevelopment, "Full-stack Development"],
  [ServiceType.SoftwareEngineeringServiceWebDevelopment, "Web Development"],
  [ServiceType.SoftwareEngineeringServiceBackEndDevelopment, "Back-end Development"],
  [ServiceType.SoftwareEngineeringServiceMobileDevelopment, "Mobile Development"],
  [AreaType.LegalAreaAdministrativeLaw, "Administrative Law"],
  [AreaType.LegalAreaArbitrationProcedureLaw, "Arbitration Procedural Law"],
  [AreaType.LegalAreaBankingLaw, "Banking Law"],
  [AreaType.LegalAreaCivilProcedureLaw, "Civil Procedural Law"],
  [AreaType.LegalAreaCompanyLaw, "Company Law"],
  [AreaType.LegalAreaCompetitionLaw, "Competition Law"],
  [AreaType.LegalAreaConstitutionLaw, "Constitution Law"],
  [AreaType.LegalAreaConsumerLaw, "Consumer Law"],
  [AreaType.LegalAreaContractLaw, "Contract Law"],
  [AreaType.LegalAreaCriminalLaw, "Criminal Law"],
  [AreaType.LegalAreaCriminalProcedureLaw, "Criminal Procedural Law"],
  [AreaType.LegalAreaElectionLaw, "Election Law"],
  [AreaType.LegalAreaEnvironmentLaw, "Environment Law"],
  [AreaType.LegalAreaFamilyLaw, "Family Law"],
  [AreaType.LegalAreaFinanceLaw, "Finance Law"],
  [AreaType.LegalAreaHinduLaw, "Hindu Law"],
  [AreaType.LegalAreaHumanRightsLaw, "Human-rights Law"],
  [AreaType.LegalAreaInformationTechnologyLaw, "Information-technology Law"],
  [AreaType.LegalAreaIntellectualPropertyLaw, "Intellectual-property Law"],
  [AreaType.LegalAreaInternationalLaw, "International Law"],
  [AreaType.LegalAreaInternationalTradeLaw, "International Trade Law"],
  [AreaType.LegalAreaLabourLaw, "Labour Law"],
  [AreaType.LegalAreaLandLaw, "Land Law"],
  [AreaType.LegalAreaMediaLaw, "Media Law"],
  [AreaType.LegalAreaMedicalLaw, "Medical Law"],
  [AreaType.LegalAreaMergersAndAcquisitionsLaw, "Mergers and Acquisitions Law"],
  [AreaType.LegalAreaProceduralLaw, "Procedural Law"],
  [AreaType.LegalAreaPropertyLaw, "Property Law"],
  [AreaType.LegalAreaRealEstateLaw, "Real-estate Law"],
  [AreaType.LegalAreaStateLaw, "State Law"],
  [AreaType.LegalAreaSubstantiveLaw, "Substantive Law"],
  [AreaType.LegalAreaTaxLaw, "Tax Law"],
  [AreaType.LegalAreaTortLaw, "Tort Law"],
  [AreaType.LegalAreaTradeLaw, "Trade Law"],
  [AreaType.LegalAreaTrustLaw, "Trust Law"],
  [AreaType.LegalAreaOther, "Other"],
  [AreaType.AccountingAreaFinancialAccounting, "Financial Accounting"],
  [AreaType.AccountingAreaManagerialAccounting, "Managerial Accounting"],
  [AreaType.AccountingAreaAuditingResearch, "Auditing Research"],
  [AreaType.AccountingAreaTaxationResearch, "Taxation Research"],
  [AreaType.AccountingAreaGovernanceResearch, "Governance Research"],
  [AreaType.AccountingAreaCapitalMarketResearch, "Capital-market Research"],
  [AreaType.AccountingAreaCommodityMarketResearch, "Commodity-market Research"],
  [AreaType.AccountingAreaCostAccounting, "Cost Accounting"],
  [AreaType.AccountingAreaIncomeTaxAccounting, "Income-tax Accounting"],
  [AreaType.AccountingAreaSalesTaxAccounting, "Sales-tax Accounting"],
  [AreaType.AccountingAreaBusinessAccounting, "Business Accounting"],
  [AreaType.AccountingAreaAccountingStandards, "Accounting Standards"],
  [AreaType.BusinessDevelopmentAreaMicroEnterprises, "Micro Enterprises"],
  [AreaType.BusinessDevelopmentAreaSmallEnterprises, "Small Enterprises"],
  [AreaType.BusinessDevelopmentAreaMediumEnterprises, "Medium Enterprises"],
  [AreaType.BusinessDevelopmentAreaLargeEnterprises, "Large Enterprises"],
  [AreaType.SoftwareEngineeringAreaCSharpProgramming, "C#"],
  [AreaType.SoftwareEngineeringAreaJavascriptProgramming, "Javascript"],
  [AreaType.SoftwareEngineeringAreaPythonProgramming, "Python"],
  [AreaType.SoftwareEngineeringAreaJavaProgramming, "Java"],
  [AreaType.SoftwareEngineeringAreaRProgramming, "R"],
  [AreaType.SoftwareEngineeringAreaSwiftProgramming, "Swift"],
  [AreaType.SoftwareEngineeringAreaCPlusPlusProgramming, "C++"],
  [AreaType.SoftwareEngineeringAreaSqlProgramming, "SQL"],
  [AreaType.SoftwareEngineeringAreaGoProgramming, "Go"],
  [AreaType.SoftwareEngineeringAreaRustProgramming, "Rust"],
  [AreaType.SoftwareEngineeringAreaPhpProgramming, "PHP"],
  [AreaType.SoftwareEngineeringAreaReactProgramming, "React"],
  [AreaType.SoftwareEngineeringAreaGraphQlProgramming, "GraphQL"],
  [AreaType.SoftwareEngineeringAreaAzureProgramming, "Azure"],
  [AreaType.SoftwareEngineeringAreaAwsProgramming, "AWS"],
  [AreaType.SoftwareEngineeringAreaTypescriptProgramming, "Typescript"],
  [LanguageCode.Ar, "Arabic"],
  [LanguageCode.En, "English"],
  [LanguageCode.Bn, "Bengali"],
  [LanguageCode.Hi, "Hindi"],
  [LanguageCode.Gu, "Gujarati"],
  [LanguageCode.Kn, "Kannada"],
  [LanguageCode.Ml, "Malayalam"],
  [LanguageCode.Mr, "Marathi"],
  [LanguageCode.Pa, "Punjabi"],
  [LanguageCode.Ta, "Tamil"],
  [LanguageCode.Te, "Telugu"],
  [LanguageCode.Ur, "Urdu"],
  [LanguageCode.Fr, "French"],
  [LanguageCode.De, "German"],
  [LanguageCode.Es, "Spanish"],
  [LanguageCode.Ja, "Japanese"],
  [LanguagePreferenceOption.Speak, "Speak"],
  [LanguagePreferenceOption.Read, "Read"],
  [LanguagePreferenceOption.Write, "Read and write"],
  [LanguagePreferenceOption.Fluent, "Speak fluently"],
  [LocationPreferenceOption.Current, "Current"],
  [LocationPreferenceOption.Love, "Love"],
  [LocationPreferenceOption.Like, "Like"],
  [LocationPreferenceOption.Dislike, "Dislike"],
  [TopicPreferenceOption.Current, "Current"],
  [TopicPreferenceOption.Past, "Past experience"],
  [TopicPreferenceOption.Love, "Love"],
  [TopicPreferenceOption.Like, "Like"],
  [TopicPreferenceOption.Dislike, "Dislike"],
  [ReviewOption.Spam, "Spam or junk content."],
  [ReviewOption.Offensive, "Abusive or offensive content."],
  [ReviewOption.Plagiarised, "Plagiarised content."],
  [ReviewOption.Empty, "Missing content."],
  [ReviewOption.LanguageExcellent, "Excellent use of language."],
  [ReviewOption.LanguagePoor, "Poor use of language."],
  [ReviewOption.LegalResearchBackgroundExcerptExcellent, "Excerpt concise and to the point."],
  [ReviewOption.LegalResearchBackgroundExcerptPoor, "Excerpt not relevant."],
  [ReviewOption.LegalResearchBackgroundSearchQueryPoor, "Search query too vague."],
  [ReviewOption.LegalResearchBackgroundUrlExcellent, "Well-respected source."],
  [ReviewOption.LegalResearchBackgroundUrlPoor, "URL inaccessible or dubious source."],
  [ReviewOption.LegalResearchConclusionActionItemExcellent, "Vital action item."],
  [ReviewOption.LegalResearchConclusionActionItemPoor, "Action item not relevant."],
  [ReviewOption.LegalResearchConclusionClarifyingQuestionExcellent, "Excellent clarifying question."],
  [ReviewOption.LegalResearchConclusionClarifyingQuestionPoor, "Clarifying question not significant."],
  [ReviewOption.LegalResearchConclusionOpinionExcellent, "Outstanding opinion."],
  [ReviewOption.LegalResearchConclusionOpinionPoor, "Opinion not relevant or not supported."],
  [ReviewOption.LegalResearchPrecedentCitationPoor, "Citation not normative."],
  [ReviewOption.LegalResearchPrecedentCourtFair, "Court not the best court."],
  [ReviewOption.LegalResearchPrecedentCourtPoor, "Court not authoritative."],
  [ReviewOption.LegalResearchPrecedentCourtExcellent, "Court highly authoritative."],
  [ReviewOption.LegalResearchPrecedentExcerptExcellent, "Excerpt concise and to the point."],
  [ReviewOption.LegalResearchPrecedentExcerptFair, "Excerpt not the best excerpt."],
  [ReviewOption.LegalResearchPrecedentExcerptPoor, "Excerpt not relevant or not verbatim."],
  [ReviewOption.LegalResearchPrecedentFactsExcellent, "Case facts highly pertinent to issue."],
  [ReviewOption.LegalResearchPrecedentFactsFair, "Case facts raise different issue."],
  [ReviewOption.LegalResearchPrecedentFactsPoor, "Case facts not relevant or inaccurate."],
  [ReviewOption.LegalResearchPrecedentSearchQueryPoor, "Search query too vague."],
  [ReviewOption.LegalResearchPrecedentUrlPoor, "URL inaccessible or not of precedent full-text."],
  [ReviewOption.LegalResearchRephrasingAreaPoor, "Inaccurate area of law."],
  [ReviewOption.LegalResearchRephrasingJurisdictionPoor, "Inaccurate jurisdictional area."],
  [ReviewOption.LegalResearchRephrasingQueryExcellent, "Concise and accurate issue."],
  [ReviewOption.LegalResearchRephrasingQueryPoor, "Issue not relevant."],
  [ReviewOption.LegalResearchStatuteExcerptExcellent, "Excerpt concise and to the point."],
  [ReviewOption.LegalResearchStatuteExcerptFair, "Excerpt not the best excerpt."],
  [ReviewOption.LegalResearchStatuteExcerptPoor, "Excerpt not relevant or not verbatim."],
  [ReviewOption.LegalResearchStatuteSearchQueryPoor, "Search query too vague."],
  [ReviewOption.LegalResearchStatuteSectionFair, "Section not the best section."],
  [ReviewOption.LegalResearchStatuteSectionPoor, "Section not relevant."],
  [ReviewOption.LegalResearchStatuteTitlePoor, "Statute not relevant."],
  [ReviewOption.LegalResearchStatuteUrlPoor, "URL inaccessible or not of statute full-text."],
  [OrderStage.Requested, "Waiting"],
  [OrderStage.Stopped, "Paused"],
  [OrderStage.Started, "In progress"],
  [OrderStage.Submitted, "Submitted"],
  [OrderStage.Shortlisted, "Saved"],
  [OrderStage.Refused, "Refused"],
  [OrderStage.ScreeningScheduled, "Screening"],
  [OrderStage.InterviewScheduled, "Interviewing"],
  [OrderStage.Offered, "Offered"],
  [OrderStage.Accepted, "Accepted"],
  [OrderStage.Declined, "Declined"],
  [OrderStage.OfferExpired, "Expired"],
  [OrderStage.Delivered, "Joined"],
  [ResourceRole.Owner, "Owner"],
  [ResourceRole.Contributor, "Contributor"],
  [ResourceRole.Reader, "Reader"],
  [CurrencyCode.Usd, "$"],
  [CurrencyCode.Eur, "€"],
  [CurrencyCode.Gbp, "£"],
  [CurrencyCode.Inr, "₹"],
  [AttachmentType.Resume, "Résumé"],
  [AttachmentType.EducationalTranscript, "Transcript or Marksheet"],
  [AttachmentType.EmploymentCertificate, "Employment Certificate"],
  [AttachmentType.GraduationCertificate, "Graduation Certificate"],
  [AttachmentType.ProfessionalLicense, "Professional License"],
  [AttachmentType.RecommendationLetter, "Recommendation Letter"],
  [AttachmentType.OtherCertificate, "Other Certificate"],
  [AttachmentType.ReferenceLetter, "Reference Letter"],
  [AttachmentType.ProductOrdersReport, "Candidate Report"],
  [AttachmentType.OrderInterviewCalendarEvent, "Calendar event"],
  [AttachmentType.OrderScreeningCalendarEvent, "Calendar event"],
  [AttachmentType.OrderStartCalendarEvent, "Calendar event"],
  [AttachmentType.Other, "Other Document"],
]);

function _pad(value: number, length: number) {
  return String(Math.floor(value)).padStart(length, '0');
}

function _fix(value: number, places?: number) {
  return value % 1 === 0 ? value.toFixed(0)
    : value < 1 ? value.toFixed(2)
      : value > 10 ? value.toFixed(0)
        : value.toFixed(1);
}

function formatNumber(value: number, format?: NumberDisplayFormat, otherValue?: number, prepend?: string): string {
  value = isNaN(value) ? 0 : value;
  otherValue = otherValue === undefined || isNaN(otherValue) ? undefined : otherValue;
  prepend = prepend ?? "";
  switch (format) {
    case NumberDisplayFormat.HumanRange:
      return otherValue === undefined ? `${formatNumber(value ?? 0, NumberDisplayFormat.Human, undefined, prepend)}+`
        : value === 0 ? `upto ${formatNumber(otherValue ?? 0, NumberDisplayFormat.Human, undefined, prepend)}`
          : max(value, otherValue ?? value) > 9999999 ? `${prepend}${_fix(value / 10000000)}–${_fix((otherValue ?? value) / 10000000)}Cr`
            : max(value, otherValue ?? value) > 99999 ? `${prepend}${_fix(value / 100000)}–${_fix((otherValue ?? value) / 100000)}L`
              : max(value, otherValue ?? value) > 999 ? `${prepend}${_fix(value / 1000)}–${_fix((otherValue ?? value) / 1000)}K`
                : `${prepend}${_fix(value)} – ${_fix(otherValue ?? value)}`;
    case NumberDisplayFormat.Human:
      return value > 9999999 ? `${prepend}${_fix(value / 10000000)}Cr`
        : value > 99999 ? `${prepend}${_fix(value / 100000)}L`
          : value > 999 ? `${prepend}${_fix(value / 1000)}K`
            : `${prepend}${_fix(value)}`;
    case NumberDisplayFormat.Currency:
      return value < 0 ? formatNumber(-value, format, otherValue, `-${prepend}`)
        : value > 9999999 ? `${prepend}${formatNumber(floor(value / 10000000))},${_pad(floor((value % 10000000) / 100000), 2)},${_pad(floor((value % 100000) / 1000), 2)},${_pad(value % 1000, 3)}`
          : value > 99999 ? `${prepend}${formatNumber(floor(value / 100000))},${_pad(floor((value % 100000) / 1000), 2)},${_pad(value % 1000, 3)}`
            : value > 999 ? `${prepend}${formatNumber(floor(value / 1000))},${_pad(value % 1000, 3)}`
              : `${prepend}${value % 1 == 0 ? value : value.toFixed(2)}`;
    default:
      return value < 0 ? formatNumber(-value, format, otherValue, `${prepend}-`)
        : value > 9999999 ? `${prepend}${formatNumber(floor(value / 10000000))},${_pad(floor((value % 10000000) / 100000), 2)},${_pad(floor((value % 100000) / 1000), 2)},${_pad(value % 1000, 3)}`
          : value > 99999 ? `${prepend}${formatNumber(floor(value / 100000))},${_pad(floor((value % 100000) / 1000), 2)},${_pad(value % 1000, 3)}`
            : value > 999 ? `${prepend}${formatNumber(floor(value / 1000))},${_pad(value % 1000, 3)}`
              : `${prepend}${value % 1 == 0 ? value : value.toFixed(2)}`;
  }
}

function formatEnum(key: string) {
  const result = strings.get(key);
  return result !== undefined ? result : key;
}

const { round, abs, floor, max, min } = Math;

function formatDate(value: DateTime, format?: DateDisplayFormat, other?: DateTime, thenResult?: string, elseResult?: string): string {
  const localValue = toLocal(value);
  const localOther = toLocal(other);
  const first = localValue ? moment(localValue) : moment.invalid();
  const second = localOther ? moment(localOther) : moment.invalid();
  const secondDuration = other ? moment.duration(other) : moment.duration("invalid");
  const isValidFirst = first.isValid();
  const isValidSecond = second.isValid();
  const isValidSecondDuration = secondDuration.isValid();
  switch (format) {
    case DateDisplayFormat.Date: return isValidFirst ? first.format("YYYY-MM-DD") : "";
    case DateDisplayFormat.Month: return isValidFirst ? first.format("YYYY-MM") : "";
    case DateDisplayFormat.DateShort: return isValidFirst ? first.format("D/M/YY") : ""
    case DateDisplayFormat.DateShortSameYear: return isValidFirst ? first.isSame(moment(), "y") ? first.format("D/M") : first.format("D/M/YY") : "";
    case DateDisplayFormat.DateLong: return isValidFirst ? first.format("MMMM D, YYYY") : "";
    case DateDisplayFormat.YearShort: return isValidFirst ? first.format("'YY") : "";
    case DateDisplayFormat.TimeAgo: return isValidFirst ? first.fromNow() : "";
    case DateDisplayFormat.TimeUntil: return isValidFirst ? first.from() : "";
    case DateDisplayFormat.MonthDay: return isValidFirst ? first.format("MMM D") : "";
    case DateDisplayFormat.IsAfter: return (isValidFirst && isValidSecond ? first.isAfter(second) ? thenResult : elseResult : "") || "";
    case DateDisplayFormat.HumanDuration: return moment.duration(value).humanize();
    case DateDisplayFormat.HumanDate: return isValidFirst ? first.isSame(moment(), "y") ? first.format("MMMM D") : first.format("MMMM D, YYYY") : "";
    case DateDisplayFormat.HumanTime: return isValidFirst ? first.minute() == 0 ? first.format("h a") : first.format("h:mm a") : "";
    case DateDisplayFormat.HumanDateTime: return isValidFirst ? `${formatDate(value, DateDisplayFormat.HumanDate)}, ${formatDate(value, DateDisplayFormat.HumanTime)}` : "";
    case DateDisplayFormat.HumanDateRange:
      return isValidSecond
        ? isValidFirst
          ? second.isSame(first, "y")
            ? `${formatDate(value, DateDisplayFormat.HumanDate)} – ${formatDate(other, DateDisplayFormat.HumanDate)}`
            : `${first.format("MMMM D, YYYY")} – ${second.format("MMMM D, YYYY")}`
          : `until ${formatDate(other, DateDisplayFormat.HumanDate)}`
        : isValidFirst ? `from ${formatDate(value, DateDisplayFormat.HumanDate)}` : ""
    case DateDisplayFormat.HumanTimeRange:
      return isValidSecondDuration
        ? isValidFirst
          ? `${formatDate(value, DateDisplayFormat.HumanTime)} – ${formatDate(first.clone().utc().add(secondDuration).format("YYYY-MM-DDTHH:mm:ssZ"), DateDisplayFormat.HumanTime)}`
          : `for ${formatDate(other, DateDisplayFormat.HumanDuration)}`
        : isValidFirst ? `from ${formatDate(value, DateDisplayFormat.HumanTime)}` : ""
    case DateDisplayFormat.DateRange:
      return isValidSecond
        ? isValidFirst
          ? `${first.format("D/M/YY")} –  ${second.format("D/M/YY")}`
          : `until ${second.format("D/M/YY")}`
        : isValidFirst ? `from ${first.format("D/M/YY")}` : ""
    case DateDisplayFormat.MonthDayRange:
      return isValidSecond
        ? isValidFirst
          ? `${first.format("MMM D")} –  ${second.format("MMM D")}`
          : `until ${second.format("MMM D")}`
        : isValidFirst ? `from ${first.format("MMM D")}` : ""
    case DateDisplayFormat.YearMonthRange:
      return isValidSecond
        ? isValidFirst
          ? `${first.format("MMM 'YY")} –  ${second.format("MMM 'YY")}`
          : `– ${second.format("MMM 'YY")}`
        : isValidFirst ? `${first.format("MMM 'YY")} –` : ""
    case DateDisplayFormat.YearRange:
      return isValidSecond
        ? isValidFirst
          ? first.year() == second.year()
            ? `${first.format("'YY")}`
            : `${first.format("'YY")} –  ${second.format("'YY")}`
          : `until ${second.format("'YY")}`
        : isValidFirst ? `from ${first.format("'YY")}` : ""
    case DateDisplayFormat.YearDurationRange:
      return moment.duration(other).isValid() && moment.duration(other).asYears() > 0
        ? moment.duration(value).isValid() && moment.duration(value).asYears() > 0
          ? `${round(moment.duration(value).asYears())} – ${round(moment.duration(other).asYears())} years`
          : `upto ${round(moment.duration(other).asYears())} years`
        : `${round(moment.duration(value).asYears())}+ years`
    case DateDisplayFormat.YearDurationRangeIn:
      return moment.duration(other).isValid() && moment.duration(other).asYears() > 0
        ? moment.duration(value).isValid() && moment.duration(value).asYears() > 0
          ? `in ${round(moment.duration(value).asYears())} – ${round(moment.duration(other).asYears())} years`
          : `within ${round(moment.duration(other).asYears())} years`
        : `in ${round(moment.duration(value).asYears())}+ years`
    case DateDisplayFormat.YearDurationRangeAgo:
      return moment.duration(other).isValid() && abs(moment.duration(other).asYears()) > 0
        ? moment.duration(value).isValid() && abs(moment.duration(value).asYears()) > 0
          ? `${round(abs(moment.duration(other).asYears()))} – ${round(abs(moment.duration(value).asYears()))} years ago`
          : `${round(abs(moment.duration(other).asYears()))}+ years ago`
        : `upto ${round(abs(moment.duration(value).asYears()))} years ago`
    case DateDisplayFormat.NegativeYearDurationRange:
      return moment.duration(other).isValid() && abs(moment.duration(other).asYears()) > 0
        ? moment.duration(value).isValid() && abs(moment.duration(value).asYears()) > 0
          ? `${round(abs(moment.duration(other).asYears()))} – ${round(abs(moment.duration(value).asYears()))} years`
          : `${round(abs(moment.duration(other).asYears()))}+ years`
        : `upto ${round(abs(moment.duration(value).asYears()))} years`
    default: return isValidFirst ? first.format("D/M/YY") : "";
  }
}

export function useTranslation() {
  return useContext(Context);
}

export default (props: { children?: ReactNode }) => {
  const value: [FormatEnum, FormatDate, FormatNumber] = [formatEnum, formatDate, formatNumber];
  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  );
}
