import { library } from "@fortawesome/fontawesome-svg-core";
import { faClock, faCopy, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faArchive, faBook, faCheck, faGlobeAsia, faInbox, faMapMarkerAlt, faPause, faPencilAlt, faPlay, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { ReactNode } from "react";
import DatePicker from "react-datepicker";
import { Alert, Button, CardBody, CardBodyProps, CardHeader, CardSubtitle, CardText, CardTitle, Collapse, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledDropdown } from "reactstrap";
import Card, { CardAttributes, CardBodyAttributes, CardButtonsAttributes } from "../components/Card";
import { CardSelectButton } from "../components/CardAttributionBody";
import CardDiscussionListGroup, { Fragment as DiscussionFragment } from "../components/CardDiscussionListGroup";
import CardMarkdownText from "../components/CardMarkdownText";
import { CardButton, CardButtons, CardDiscussionSummaryStatusItems, CardStatus, CardStatusGroup, CardUsersStatusItem } from "../components/CardStatus";
import { DeepPartial, includeIf, setReplace, useMutation } from "../hooks/ApiProvider";
import { useCardState } from "../hooks/CardStateHook";
import { useToggle } from "../hooks/CommonHooks";
import { useConfirmation } from "../hooks/ConfirmationProvider";
import { doNothing, locationBase, preventDefault, stopPropagation, updateURIComponents, useFragment, useNavigation } from "../hooks/NavigationHook";
import { DateDisplayFormat, NumberDisplayFormat, toLocalDate, toLocalString, toUtcString, useTranslation } from "../hooks/TranslationProvider";
import ProductInputModal, { Fragment as ProductInputFragment } from "../modals/ProductInputModal";
import SendMessageModal from "../modals/SendMessageModal";
import { MessageModal as ShareMessageModal } from "../modals/ShareModal";
import { AreaType, LanguageCode, LocationModel, MoneyModel, OrderModel, OrderStage, ProductDetailsInputModel, ProductDetailsModel, ProductModel, ProductType, ResourceRole, ResourceState, ServiceType } from "../types/api-graph-types";
import { UserThumbnail, UserThumbnailFragment } from "./PersonCard";

library.add(
  faArchive,
  faBook,
  faClock,
  faGlobeAsia,
  faMapMarkerAlt,
  faPause,
  faShare,
  faCheck,
  faPlay,
  faCopy,
  faInbox,
  faPencilAlt,
  faEnvelope
);

export const ProductDetailsFragment = `
  isPrivate
  manufacturerOrg { id kind name description logo }
  description
  detailedDescription
  skipOrgDescription
  requirementsDescription
  service
  areas
  languages
  duration
  location { city locality neighborhood }
  compensation { isPrivate currency amount variableFraction period isGross }
  restartOn
  availableOn
  minCustomersGraduationIn
  maxCustomersGraduationIn
  ordersStages
`;

const ProductActionsFragment = `
  state
  actions {
    complete
    order
    remove
    link
    copy
    unlink
    share
    start
    stop
    undo
    change
  }
`;

export const Fragment = ({ showStatus, showVariants, showButtons, showInput, showDiscussion, includeMessages }: {
  showInput?: boolean,
  showDiscussion?: boolean,
  showStatus?: boolean,
  showButtons?: boolean,
  showVariants?: boolean,
  includeMessages?: boolean
} = {}) => `
  id
  kind
  state  
  details {
    type
    subtype
    title
    subtitle
    displayTitle
    ${ProductDetailsFragment}
    ordersDescription
    ordersJobsCount
    ordersJobsMaxCount
    ordersDueIn
    ordersDueOn
    ${includeIf(includeMessages, `messages { 
      key
      name
      subject
      content
      shortContent
      attachments { key filename length token url }
    }`)}
  }
  owner { ${UserThumbnailFragment} email phone }
  supplier { id kind name description logo }
  ${includeIf(showButtons, ProductActionsFragment)}
  policy { role }
  ${includeIf(showStatus, `ordersSummary { count }`)}  
  ${includeIf(showVariants, `
  collection { 
    id 
    kind 
    details { title variantType variantTitle } 
    policy { role } 
    ${showStatus ? `ordersSummary { count }` : ""}
  }`)}
  ${includeIf(showInput, ProductInputFragment)}
  ${includeIf(showDiscussion, DiscussionFragment)}
`;

const _updateProductDetails = `mutation updateProductDetails($id:ID! $value:ProductDetailsInputModel) {
  product(id:$id) {
    details { change(value:$value) }
    commit { ${Fragment()} }
  }
}`;

const _startProduct = `mutation startProduct($id:ID!) {
  product(id:$id) { start commit { id ${ProductActionsFragment} } }
}`;

const _stopProduct = `mutation stopProduct($id:ID! $details:ProductDetailsInputModel) {
  product(id:$id) {
    stop
    details { change(value:$details) }
    commit {
      id
      details { restartOn } 
      ${ProductActionsFragment} 
    } 
  }
}`;

const _completeProduct = `mutation completeProduct($id:ID!) {
  product(id:$id) { complete commit { id ${ProductActionsFragment} } }
}`;

const _removeProduct = `mutation removeProduct($id:ID!) {
  product(id:$id) { remove commit { id ${ProductActionsFragment} } }
}`;

const _undoProduct = `mutation undoProduct($id:ID!) {
  product(id:$id) { undo commit { id ${ProductActionsFragment} } }
}`;

const _orderProduct = `mutation orderProduct($id:ID! $label:String) {
  product: displayProduct(id:$id) { 
    order: addOrder(label:$label) { commit { id } } 
  }
}`;

interface OperationResults {
  product: {
    commit: ProductModel
    order: {
      commit: OrderModel
    }
  }
};

function getMapUrl(query: string) {
  return `https://maps.google.com/?q=${query}`;
}

export const IsEmployment = (value: ProductModel) => value.details.type.startsWith("EMPLOYMENT_");
export const IsCustomerService = (value: ProductModel) => value.details.type.startsWith("CUSTOMER_SERVICE_");

function ProductLocationListItem({ value }: { value: LocationModel }) {
  const [t] = useTranslation();
  const city = value && (value.locality || t(value.city) || "").trim();
  const location = value?.neighborhood ? `${value.neighborhood}, ${city}` : city;
  return location && (
    <li>
      <b className="mr-1">Location</b>
      <a target='_blank' rel="noopener noreferrer" className='text-body' href={getMapUrl(location)} onClick={stopPropagation}>
        <FontAwesomeIcon className="text-muted mr-1" fixedWidth icon={["fas", "map-marker-alt"]} />
      </a>
      {location}
    </li>
  ) || null;
};

function ProductCompensationListItem({ value }: { value: MoneyModel }) {
  const [t, , n] = useTranslation();
  const minAmount = value?.amount ?? 0;
  const maxAmount = value?.amount && value.amount * (100 + (value.variableFraction ?? 0)) / 100;
  return !value?.isPrivate && value?.amount && (
    <li>
      <b className="mr-1">Compensation</b>
      {n(minAmount, value.variableFraction ? NumberDisplayFormat.HumanRange : NumberDisplayFormat.Human, maxAmount, t(value.currency))}
      {value.period === "P30D" ? " per month" : value.period === "P365D" ? " per year" : ""}
    </li>
  ) || null;
};

function ProductServiceListItem({ value }: { value: ServiceType }) {
  const [t] = useTranslation();
  return value && value !== ServiceType.Null && (
    <li>
      <b className="mr-1">Services</b>
      {t(value)}
    </li>
  ) || null;
};

function ProductDurationListItem({ value }: { value: string }) {
  const weeks = Math.round(moment.duration(value).asWeeks());
  return (
    value && weeks > 0 && weeks < 9000 && (
      <li>
        <b className="mr-1">Duration</b>
        {weeks}
        {" weeks"}
      </li>
    ) || null
  );
}

function ProductAvailableOnListItem({ value }: { value: string | undefined }) {
  const [, d] = useTranslation();
  const val = value ? moment(value) : undefined;
  return (
    value && val?.isAfter() && (
      <li>
        <b className="mr-1">Next available</b>
        {d(val, DateDisplayFormat.HumanDate)}
      </li>
    ) || null
  );
}

function ProductAvailableOnListItemInput({ id, value, onUpdate }: {
  id: string,
  value: string | undefined,
  onUpdate: (value: string) => void
}) {
  const [isOpenNextAvailable, toggleNextAvailable] = useToggle();
  const [mutation] = useMutation<OperationResults>();
  const confirm = useConfirmation();
  const [, d] = useTranslation();
  const updateAvailableOn = async (availableOn: string | null) => {
    const value = { availableOn: availableOn ? moment(availableOn).toISOString() : null }
    const result = await mutation(_updateProductDetails, { id, value });
    onUpdate(result.product.commit.details.availableOn || "");
    toggleNextAvailable();
  }
  return (
    <li onClick={stopPropagation} className="d-flex">
      <b className="text-nowrap mr-1">Next available</b>
      {isOpenNextAvailable && <DatePicker customInput={
        <span>
          {d(value, DateDisplayFormat.HumanDate)}
          <Button disabled={!value} color="link" size="sm" className={`ml-2 p-0 b-0 align-baseline`} onClick={confirm(() => updateAvailableOn(null), "Are you sure you want to clear the next available date?")}>Clear</Button>
          <Button color="link" size="sm" className={`ml-2 p-0 b-0 align-baseline`} onClick={toggleNextAvailable}>Cancel</Button>
        </span>
      } open monthsShown={3} minDate={new Date()} selected={value ? toLocalDate(value) : undefined} dateFormat="MMMM d, yyyy" onChange={e => e && confirm((_, e: Date) => updateAvailableOn(toUtcString(e)), `Are you sure you want to update the next available date to ${d(toLocalString(e), DateDisplayFormat.HumanDate)}?`)(e)} />}
      {!isOpenNextAvailable &&
        <span>
          {d(value, DateDisplayFormat.HumanDate)}
          <Button color="link" size="sm" className={`ml-2 p-0 b-0 align-baseline`} onClick={toggleNextAvailable}>Edit</Button>
        </span>
      }
    </li>
  );
}

function ProductAreasListItem({ values, service }: { values: ReadonlyArray<AreaType>, service: ServiceType }) {
  const [t] = useTranslation();
  return (values?.length > 0 || (service ?? ServiceType.Null) !== ServiceType.Null) && (
    <li>
      <b className="mr-1">Focus</b>
      {[service, ...(values ?? [])].map(t).filter(_ => !!_).join(", ")}
    </li>
  ) || null;
};

function ProductGraduationListItem({ maxValue, minValue }: { maxValue: string, minValue: string }) {
  const [, d] = useTranslation();
  const isAgo = minValue?.startsWith("-") || maxValue?.startsWith("-");
  return (maxValue || minValue) && (
    <li>
      {isAgo && <b className="mr-1">Experience</b>}
      {!isAgo && <b className="mr-1">Graduation</b>}
      {d(minValue, isAgo ? DateDisplayFormat.NegativeYearDurationRange : DateDisplayFormat.YearDurationRangeIn, maxValue)}
    </li>
  ) || null;
};

function ProductLanguagesListItem({ values }: { values: ReadonlyArray<LanguageCode> }) {
  const [t] = useTranslation();
  return values?.length > 0 && (
    <li>
      <b className="mr-1">Languages</b>
      {values.map(t).join(", ")}
    </li>
  ) || null;
};

export function ProductKeyFeaturesText({ value, product, onUpdateProduct }: {
  value: ProductDetailsModel,
  product?: ProductModel,
  onUpdateProduct?: (value: DeepPartial<ProductModel>) => void
}) {
  const showLocation = value.subtype !== ProductType.EmploymentRemote;
  const showNextAvailableInput = !!product?.actions?.change;
  return (
    <ul className="list-unstyled mb-0">
      {showLocation && <ProductLocationListItem value={value.location} />}
      <ProductCompensationListItem value={value.compensation} />
      {false && <ProductDurationListItem value={value.duration} />}
      {false && <ProductServiceListItem value={value.service} />}
      <ProductAreasListItem values={value.areas} service={value.service} />
      {!showNextAvailableInput && <ProductAvailableOnListItem value={value.availableOn} />}
      {showNextAvailableInput && product && <ProductAvailableOnListItemInput id={product.id} value={value.availableOn} onUpdate={availableOn => onUpdateProduct?.({ details: { availableOn } })} />}
    </ul>
  );
}

export function ProductDescriptionText({ value, className, showDetailedDescription }: {
  value: ProductModel,
  showDetailedDescription?: boolean,
  className?: string
}) {
  const showDescription = value.details.description
    || (!value.details.skipOrgDescription && value.details.manufacturerOrg?.description)
    || (showDetailedDescription && value.details.detailedDescription);
  return showDescription ? (
    <div className={className}>
      {!value.details.skipOrgDescription && value.details.manufacturerOrg?.description && <CardMarkdownText className="mt-3" justify hyphens source={value.details.manufacturerOrg.description} />}
      {value.details.description && <CardMarkdownText className="mt-3" justify hyphens source={value.details.description} />}
      {showDetailedDescription && value.details.detailedDescription && <CardMarkdownText className="mt-3" justify hyphens source={value.details.detailedDescription} />}
    </div>
  ) : null;
};

export function ProductDescriptionCollapsibleText({ value, isOpen: _isOpen, showDetailedDescription }: {
  value: ProductModel,
  showDetailedDescription?: boolean,
  isOpen?: boolean
}) {
  const [isOpen, toggle] = useToggle(_isOpen);
  return (
    <>
      <Collapse isOpen={isOpen}>
        <ProductDescriptionText {...{ value, showDetailedDescription }} />
      </Collapse>
      <div className="small">
        <a href='#' onClick={preventDefault(toggle)}>{isOpen ? "See less" : "See more"}</a>
      </div>
    </>
  );
};

function ProductOrdersJobsListItem({ count, dueIn, dueOn, stages }: {
  count: number,
  dueIn?: string,
  dueOn?: string,
  stages?: ReadonlyArray<OrderStage>
}) {
  const [, d] = useTranslation();
  const hasOnline = count > 0;
  const hasScreening = stages?.includes(OrderStage.ScreeningScheduled);
  const hasInterview = stages?.includes(OrderStage.InterviewScheduled);
  const hasAssessments = hasOnline || hasScreening || hasInterview;
  // dueIn && false ? <span>{count} due in {d(dueIn, DateDisplayFormat.HumanDuration)}</span>
  // dueOn && false ? <span>{count} due by {d(dueOn, DateDisplayFormat.DateShort)}</span>
  return hasAssessments && (
    <li>
      <b className="mr-1">Assessments</b>
      {[hasOnline && `${count} online`, hasScreening && "phone screen", hasInterview && "interview"]
        .filter(_ => _).join(", ")}
    </li>
  ) || null;
};

function ProductRequirementsDescriptionListItem({ value, className }: { value: string, className?: string }) {
  return value && (
    <li className={className}>
      <CardMarkdownText justify hyphens source={value} />
    </li>
  ) || null;
};


export function ProductKeyRequirementsText({ value }: { value: ProductDetailsModel }) {
  const showRequirements = (value.maxCustomersGraduationIn || value.minCustomersGraduationIn)
    || value.ordersJobsCount
    || value.requirementsDescription;
  return showRequirements ? (
    <CardText tag='div' className="mt-3">
      <h5>Requirements</h5>
      <ul className='list-unstyled'>
        <ProductGraduationListItem maxValue={value.maxCustomersGraduationIn} minValue={value.minCustomersGraduationIn} />
        <ProductLanguagesListItem values={value.languages} />
        <ProductOrdersJobsListItem count={value.ordersJobsCount} dueIn={value.ordersDueIn} dueOn={value.ordersDueOn} stages={value.ordersStages} />
        <ProductRequirementsDescriptionListItem className="mt-3" value={value.requirementsDescription} />
      </ul>
    </CardText>
  ) : null;
}

export function ProductTitleText({ value, showDetailsMenu, appendDetails }: {
  value: ProductModel,
  showDetailsMenu?: boolean,
  appendDetails?: ReactNode
}) {
  const isReader = [ResourceRole.Owner, ResourceRole.Contributor, ResourceRole.Reader].includes(value.policy?.role ?? ResourceRole.Public);
  return (
    <div className="d-flex">
      {value.details.manufacturerOrg?.logo
        ? <img src={value.details.manufacturerOrg.logo} width={48} height={48} className="mr-2 align-baseline" />
        : <UserThumbnail width={48} className="mr-2" value={value.owner} />}
      <div>
        <CardSubtitle tag='h5'>
          {value.details.subtitle && <span className="mr-3">{value.details.subtitle}</span>}
          {isReader && value.details.isPrivate && <FontAwesomeIcon className="small text-muted" icon={["far", "eye-slash"]} />}
        </CardSubtitle>
        <CardTitle tag='h3'>{value.details.title}</CardTitle>
      </div>
      {showDetailsMenu &&
        <UncontrolledDropdown className="ml-auto" onClick={stopPropagation}>
          <DropdownToggle color="white">
            <FontAwesomeIcon icon="ellipsis-h" />
          </DropdownToggle>
          <DropdownMenu right>
            {appendDetails}
          </DropdownMenu>
        </UncontrolledDropdown>
      }
    </div>
  );
}

export function Text({ value, appendDetails, onUpdate, hideDescription, hideRequirements, showDetailsMenu, showDetailedDescription }: {
  value: ProductModel,
  appendDetails?: ReactNode,
  showDetailsMenu?: boolean,
  hideRequirements?: boolean,
  hideDescription?: boolean,
  showDetailedDescription?: boolean,
  onUpdate?: (value: DeepPartial<ProductModel>) => void
}) {
  const [t] = useTranslation();
  return (
    <>
      <ProductTitleText {...{ value, showDetailsMenu, appendDetails }} />
      <ProductKeyFeaturesText value={value.details} product={value} onUpdateProduct={onUpdate} />
      {!hideDescription && <ProductDescriptionText {...{ value, showDetailedDescription }} />}
      {!hideRequirements && <ProductKeyRequirementsText value={value.details} />}
    </>
  );
}

export function Notice({ value, className, children }: {
  value: ProductModel,
  className?: string,
  children?: ReactNode
}) {
  const [, d] = useTranslation();
  return value.state === ResourceState.Proposed ? (
    <Alert className={className} color="primary">
      <h5>Paused</h5>
      {value.details.restartOn
        ? <p>This listing is currently paused until {d(value.details.restartOn, DateDisplayFormat.HumanDate)}.</p>
        : <p>This listing is currently paused.</p>
      }
      {children}
    </Alert>
  ) : children ? (
    <Alert className={className} color="primary">
      {children}
    </Alert>
  ) : null;
}

export function Buttons({ value, onUpdate, toggleInput, toggleShare, children }: CardButtonsAttributes<ProductModel>) {
  const [, go] = useNavigation();
  const [fragment] = useFragment({ label: "", comment_form: "" })
  const [isOpenMessage, toggleMessage] = useToggle();
  const [isOpenPause, togglePause] = useToggle();
  const [mutation] = useMutation<OperationResults>();
  const { id, actions } = value;
  const startProduct = async () => {
    const result = await mutation(_startProduct, { id })
    onUpdate?.(result.product.commit);
  }
  const stopProduct = async (restartOn: string | null) => {
    const details = { restartOn } as Partial<ProductDetailsInputModel>;
    const result = await mutation(_stopProduct, { id, details })
    onUpdate?.(result.product.commit);
  }
  const completeProduct = async () => {
    const result = await mutation(_completeProduct, { id })
    onUpdate?.(result.product.commit);
  }
  const removeProduct = async () => {
    const result = await mutation(_removeProduct, { id })
    onUpdate?.(result.product.commit);
  }
  const undoProduct = async () => {
    const result = await mutation(_undoProduct, { id })
    onUpdate?.(result.product.commit);
  }
  const orderProduct = async () => {
    const label = fragment.label
      || (window.location.hash.startsWith("#") && window.location.hash.indexOf("=") == -1 && window.location.hash.substring(1))
      || null;
    const result = await mutation(_orderProduct, { id, label })
    const hash = updateURIComponents("#", { comment_form: fragment.comment_form });
    go(`/my/goals/${result.product.order.commit.id}${hash}`);
  }
  return (
    <>
      <CardButtons showIcons className="mt-3">
        {actions.order && <CardButton onClick={orderProduct}>Apply</CardButton>}
        {actions.start && <CardButton icon="play" onClick={startProduct}>Start</CardButton>}
        {actions.change && toggleInput && <CardButton icon="pencil-alt" onClick={toggleInput}>Edit</CardButton>}
        {false && actions.change && <CardButton icon={["far", "envelope"]} onClick={toggleMessage}>Send</CardButton>}
        {actions.share && toggleShare && <CardButton icon="share" onClick={toggleShare}>Share</CardButton>}
        {actions.stop && <CardButton isOpen={isOpenPause} icon="pause" toggle={false} onClick={togglePause}>Pause</CardButton>}
        {actions.stop &&
          <Collapse isOpen={isOpenPause}>
            <CardButton onClick={() => stopProduct(moment().utc().add(3, "M").toISOString())}>for 3 months</CardButton>
            <CardButton onClick={() => stopProduct(moment().utc().add(6, "M").toISOString())}>for 6 months</CardButton>
            <CardButton onClick={() => stopProduct(null)}>until I restart</CardButton>
          </Collapse>
        }
        {false && actions.complete && <CardButton icon="check" onClick={completeProduct} confirm confirmMessage="You are about to mark this listing as completed. All active candidates without an outstanding offer will be informed that this position is no longer available. You can confirm, or cancel to leave this position open.">Complete</CardButton>}
        {actions.remove && <CardButton icon="archive" onClick={removeProduct}>Archive</CardButton>}
        {actions.undo && value.state === ResourceState.Removed && <CardButton icon="inbox" onClick={undoProduct}>Unarchive</CardButton>}
        {children}
      </CardButtons>
      {actions.change && false &&
        <SendMessageModal isOpen={isOpenMessage} toggle={toggleMessage}
          link={value.publicUrl}
          subject={value.details.displayTitle}
          content={`Hi,\n\nPlease apply at ${value.publicUrl}.\n\nSincerely,\n{{Me}}`}
          showTemplates resource={value} templates={value.details.messages} onUpdateTemplates={messages => onUpdate?.({ id, details: setReplace({ messages }) })} />
      }
    </>
  )
}

export function Status({ value, showVariants, toggleDiscussion }: {
  value: ProductModel,
  showVariants?: boolean,
  toggleDiscussion?: () => void
}) {
  var count = value.ordersSummary?.count ?? 0;
  var maxCount = showVariants ? value.collection?.map(_ => _.ordersSummary?.count ?? 0).reduce((a, _) => a + _, 0) ?? 0 : 0;
  return (
    <CardStatus>
      <CardStatusGroup>
        {count > 0 && <CardUsersStatusItem value={count} max={maxCount} />}
      </CardStatusGroup>
      <CardDiscussionSummaryStatusItems reviews={value.reviewsSummary} toggleDiscussion={toggleDiscussion} />
    </CardStatus>
  );
}

export function Variants({ value, hide = () => false, path }: {
  value: ProductModel,
  hide?: (value: ProductModel) => boolean
  path?: string
}) {
  const [navigate] = useNavigation();
  return value.collection.length > 1 ? (
    <Nav>
      {value.collection.filter(_ => !(hide?.(_) ?? false)).map((_, i) =>
        <NavItem key={i} onClick={doNothing}>
          <NavLink active={_.id === value.id} href="#" onClick={navigate(`${path}/${_.id}`)} >
            {_.details?.variantTitle || _.details?.title}
          </NavLink>
        </NavItem>
      )}
    </Nav>
  ) : null;
}

export function Body({ value, className, href, appendDetails, variantsPath, toggleInput, showDetailsMenu, showDetailedDescription, toggleShare, toggleDiscussion, hideVariant, selected, onUpdate, onSelect, onClick, showSelect, showAvailability, showVariants, showNotice, showStatus, showMenu, showButtons, appendNotice, children, ...attrs }: CardBodyAttributes<ProductModel> & CardBodyProps & {
  showAvailability?: boolean,
  showVariants?: boolean,
  hideVariant?: (value: ProductModel) => boolean,
  showDetailedDescription?: boolean,
  variantsPath?: string
}) {
  const [navigate] = useNavigation();
  const handleClick = onClick || navigate(href) || toggleInput || undefined;
  const classNames = [
    className,
    "hover-container",
    handleClick ? "hover-shadow cursor-pointer" : "",
  ].join(" ");
  return (
    <CardBody className={classNames} onClick={handleClick} {...attrs}>
      {showSelect && <CardSelectButton className="btn-float" {...{ selected, onSelect }} />}
      <Text {...{ value, onUpdate, appendDetails, showDetailsMenu, showAvailability, showDetailedDescription }} />
      {children}
      {showNotice && <Notice className="mt-3" value={value}>{appendNotice}</Notice>}
      {showVariants && <Variants value={value} path={variantsPath} hide={hideVariant} />}
      {showStatus && <Status {...{ value, showVariants, toggleDiscussion }} />}
      {showButtons && <Buttons {...{ value, onUpdate, toggleInput, toggleShare }} />}
    </CardBody>
  );
}

export default ({ value, onClick, className, href, variantsPath, hideVariant, showDetailedDescription, selected, onUpdate, onSelect, showSelect, showAvailability, showVariants, showNotice, showStatus, showMenu, showHeader, showButtons, showAttribution, showDetailsMenu, showInput, showDiscussion, showShare, openInput, openDiscussion, openShare, appendNotice, appendDetails, children }: CardAttributes<ProductModel> & {
  showAvailability?: boolean,
  showVariants?: boolean,
  hideVariant?: (value: ProductModel) => boolean,
  showDetailedDescription?: boolean
  variantsPath?: string
}) => {
  const [t] = useTranslation();
  const { isOpenInput, toggleInput, isOpenShare, toggleShare, isOpenDiscussion, toggleDiscussion } = useCardState({ showInput, showShare, showDiscussion, openDiscussion, openInput, openShare });
  const classNames = [
    className,
    selected || onSelect ? "card-selectable" : "",
    selected ? "active" : "",
  ].join(" ");
  return (
    <>
      <Card className={classNames}>
        {showHeader && <CardHeader className="bg-white" tag="h3">{t(value.details.type)}</CardHeader>}
        <Body {...{ value, href, variantsPath, hideVariant, selected, onUpdate, onClick, onSelect, showSelect, showAvailability, showVariants, showNotice, showStatus, showMenu, showButtons, showDetailedDescription, showDetailsMenu, appendNotice, appendDetails, toggleInput, toggleShare, toggleDiscussion }} >
          {children}
        </Body>
        {showDiscussion && <CardDiscussionListGroup value={value} onUpdate={onUpdate} isOpen={isOpenDiscussion} />}
      </Card>
      {showInput && <ProductInputModal value={value} onUpdate={onUpdate} isOpen={isOpenInput} toggle={toggleInput} />}
      {showShare && <ShareMessageModal value={value} title={value.details.displayTitle}
        url={`${locationBase}/my/listings/${value.id}?scope=claim:MELLON`}
        recruiterUrl={`${locationBase}/my/shares/{{Id}}?scope=claim:MELLON#token={{AccessToken}}`}
        isOpen={isOpenShare} toggle={toggleShare}
        showTemplates templates={value.details.messages} onUpdateTemplates={messages => onUpdate?.({ id: value.id, details: setReplace({ messages }) })} />}
    </>
  );
}
