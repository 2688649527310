import React, { useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter } from "reactstrap";
import FormGroup from "../components/FormGroup";
import Input from "../components/Input";
import MutationButton from "../components/MutationButton";
import { ApiErrors, useMutation, useQuery, useReadyState } from "../hooks/ApiProvider";
import { AccountPlanModel, AccountSubscriptionInputModel, AccountSubscriptionModel, OrganizationModel, PersonModel } from "../types/api-graph-types";

export const Fragment = `
  id
  kind
  title
  plan { id title description }
  customer { email }
  actions { upgrade change share }
`;

const _getPlans = `query getPlans {
  settings {
    plans { id title }
  }
}
`;

const _updateSubscription = `mutation updateSubscription($id:ID! $value:AccountSubscriptionInputModel) {
  subscription(id:$id) {
    change(value:$value)
    complete
    commit { ${Fragment} }
  }
}`
const _createOrgSubscription = `mutation createOrgSubscription($oid:ID $value:AccountSubscriptionInputModel) {
  resource: organization(id:$oid) {
    subscription: addSubscription {
      change(value:$value)
      complete
      commit { ${Fragment} }
    }
  }
}`

const _createPersonSubscription = `mutation createPersonSubscription($pid:ID $value:AccountSubscriptionInputModel) {
  resource: person(id:$pid) {
    subscription: addSubscription {
      change(value:$value)
      complete
      commit { ${Fragment} }
    }
  }
}`

interface Result {
  settings: {
    plans: ReadonlyArray<AccountPlanModel>
  }
}

interface OperationResults {
  resource: {
    subscription: {
      commit: AccountSubscriptionModel
    }
  },
  subscription: {
    commit: AccountSubscriptionModel
  }
}

interface OperationErrors {
  subscription: {
    change: ApiErrors<{ value: AccountSubscriptionInputModel }>
  }
}

export const New: AccountSubscriptionModel = {
  id: "",
  planId: ""
} as any;

const _toAccountSubscriptionInputModel = (value: AccountSubscriptionModel): AccountSubscriptionInputModel => ({
  title: value.title,
  planId: value.plan?.id ?? "",
  customerExternalId: value.customer?.email ?? ""
});

export default ({ isOpen, value, customer, customerOrg, onUpdate, toggle }: {
  isOpen?: boolean,
  value: AccountSubscriptionModel,
  customer?: PersonModel,
  customerOrg?: OrganizationModel,
  onUpdate?: (value: AccountSubscriptionModel) => void,
  toggle?: () => void
}) => {
  const [readyState, setReadyState] = useReadyState();
  const [input, setInput] = useState(_toAccountSubscriptionInputModel(value));
  const [errors, setErrors] = useState<ApiErrors<AccountSubscriptionInputModel>>({});
  const [result] = useQuery<Result>(_getPlans, {});
  const updateInput = (props: Partial<AccountSubscriptionInputModel>) => setInput({ ...input, ...props });
  const [mutation] = useMutation<OperationResults>();
  const create = async () => {
    try {
      const query = customerOrg?.id ? _createOrgSubscription : customer?.id ? _createPersonSubscription : "";
      const result = await mutation(query, {
        pid: customer?.id,
        oid: customerOrg?.id,
        value: input
      }, { setReadyState });
      onUpdate?.(result.resource.subscription.commit);
      toggle?.();
    } catch (errors) {
      setErrors((errors as OperationErrors).subscription.change.value || {})
    }
  }
  const change = async () => {
    try {
      const result = await mutation(_updateSubscription, { id: value.id, value: input }, { setReadyState });
      onUpdate?.(result.subscription.commit);
      toggle?.();
    } catch (errors) {
      setErrors((errors as OperationErrors).subscription.change.value || {})
    }
  }
  const cancel = () => {
    setInput(_toAccountSubscriptionInputModel(value));
    toggle?.();
  }

  const plans = result?.settings?.plans || [];

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header w-100">
        <FormGroup className="w-100" error={errors.title} value={input.title || ""}>
          <Input type="text" className="form-control-lg" placeholder="Enter your subscription title here" onUpdate={title => updateInput({ title })} />
        </FormGroup>
      </div>
      <ModalBody>
        <Form>
          <fieldset>
            <FormGroup label='Plan' error={errors.planId}>
              <select className="custom-select" value={input.planId} onChange={e => updateInput({ planId: e.currentTarget.value })}>
                <option value=""></option>
                {plans.map((_, i) => <option key={i} value={_.id}>{_.title}</option>)}
              </select>
            </FormGroup>
            <FormGroup label="Primary contact">
              <Input type="text" placeholder="Enter email address" value={input.customerExternalId} onUpdate={customerExternalId => updateInput({ customerExternalId })} />
            </FormGroup>
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>Cancel</Button>
        {!value.id && <MutationButton readyState={readyState} color="primary" onClick={create}>Post</MutationButton>}
        {value.id && <MutationButton readyState={readyState} color="primary" onClick={change}>Update</MutationButton>}
      </ModalFooter>
    </Modal>
  );
}

