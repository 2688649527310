import React, { useEffect, useState } from "react";
import { Progress } from "reactstrap";
import NotFoundPage from "../pages/NotFoundPage";

export default ({ isLoading: _isLoading, noResult }: {
  isLoading?: boolean;
  noResult?: boolean;
}) => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [value, setValue] = useState(0);
  useEffect(() => {
    setTimeout(() => setValue(10), 300);
    setTimeout(() => setValue(90), 500);
    setTimeout(() => setIsInitializing(false), 300);
  }, []);
  const isLoading = _isLoading || isInitializing;
  const showProgress = isLoading && value > 0;
  const showNotFound = !isLoading && noResult;
  return (
    <>
      {showProgress && <Progress value={value} style={{ margin: "0px -15px", height: "3px" }} />}
      {!showProgress && <div style={{ margin: "0px -15px", height: "3px" }} />}
      {showNotFound && <NotFoundPage />}
    </>
  );
}
