import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Col, Collapse, Form, Input, Modal as ModalBase, Progress } from "reactstrap";
import FormGroup from "../components/FormGroup";
import { nextNonce } from "../hooks/ApiProvider";
import { useNavigation } from "../hooks/NavigationHook";
import { AttachmentModel, ErrorType } from "../types/api-graph-types";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const _pdf = "application/pdf";
const _msword = "application/msword";
const _jpeg = "image/jpeg";
const _png = "image/png";
const _doc = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const _xls = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const _ppt = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
const _image = [_jpeg, _png];
const _office = [_msword, _doc, _xls, _ppt];
const _preview = [_pdf, ..._office, ..._image];

enum PasswordResponses {
  NeedPassword = 1,
  IncorrectPassword = 2
}

export const Fragment = `
  mediaType 
  url 
  pdfUrl
`;

export function canPreview(value: AttachmentModel) {
  return _preview.includes(value.mediaType) || value.pdfUrl;
}

export function Modal({ value: _value, values, isOpen, toggle }: {
  value: AttachmentModel;
  values?: ReadonlyArray<AttachmentModel>;
  isOpen?: boolean;
  toggle?: () => void
}) {
  const [index, setIndex] = useState(values && values.indexOf(_value) || 0);
  const [navigate] = useNavigation();
  const showPager = values?.length ?? 0 > 1;
  const showPrev = index > 0;
  const showNext = index < ((values?.length ?? 0) - 1);
  const value = values && values.length > 1 ? values[index] : _value;
  const hasTitle = !!value?.subtitle || !!value?.title;

  return (
    <ModalBase isOpen={isOpen} toggle={toggle} className="w-max-90">
      <div className="modal-header flex-row align-items-baseline">
        <h5 className="mb-0 mr-auto text-truncate">
          {hasTitle
            ? <span>{value.subtitle} {value.title}</span>
            : <span>{value.filename}</span>
          }
        </h5>
        {showPager &&
          <div className="text-nowrap">
            <Button disabled={!showPrev} color="link" onClick={() => setIndex(index - 1)}><FontAwesomeIcon icon="chevron-left" /></Button>
            {index + 1} of {values?.length ?? 0 + 1}
            <Button disabled={!showNext} color="link" onClick={() => setIndex(index + 1)}><FontAwesomeIcon icon="chevron-right" /></Button>
          </div>
        }
        <Button color="link" onClick={toggle}>Close</Button>
        <Button color="primary" onClick={navigate(value.url, true)}>Download</Button>
      </div>
      <Col>
        {isOpen && <ContentPreviewDiv value={value} />}
      </Col>
    </ModalBase>
  )
}

interface PasswordInput {
  value: string,
  error: ErrorType,
  callback: (value: string) => void
}

function ContentPreviewDiv({ value, src, type, hideTextLayer, showAnnotationLayer = false, sideBySide, pdfRenderMode, className, onLoad, pageClassName }: {
  value?: AttachmentModel,
  src?: string,
  type?: string,
  hideTextLayer?: boolean,
  showAnnotationLayer?: boolean,
  className?: string,
  pageClassName?: string,
  onLoad?: () => void,
  pdfRenderMode?: "canvas" | "svg"
  sideBySide?: boolean
}) {
  const previewDiv = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number>();
  const [, forceRender] = useState(nextNonce());
  const [progress, setProgress] = useState(0);
  const password = useRef<PasswordInput>({ error: ErrorType.Ok } as PasswordInput);
  useEffect(() => {
    const onResize = () => forceRender(nextNonce());
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [src ?? value?.url]);
  useEffect(() => {
    setProgress(0);
    setNumPages(undefined);
    password.current.error = ErrorType.Ok;
  }, [src ?? value?.pdfUrl]);
  useEffect(() => setProgress(50), []);
  const rerender = () => forceRender(nextNonce());
  const onPassword = (...args: any[]) => {
    const callback = args[0] as (password: string | null) => void;
    const reason = args[1] as PasswordResponses;
    console.log({ reason, password });
    if (progress != -1) {
      switch (reason) {
        case PasswordResponses.NeedPassword: {
          password.current.error === ErrorType.Ok && callback(password.current.value || "12345");
          break;
        }

        case PasswordResponses.IncorrectPassword: {
          password.current.error = password.current.value ? ErrorType.Invalid : ErrorType.Unauthorized;
          password.current.callback = callback;
          password.current.value = "";
          rerender();
          break;
        }

        default:
      }
    }
  }
  const submitPassword = () => {
    password.current.error = ErrorType.Ok;
    password.current.callback(password.current.value);
    rerender();
  }
  const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    password.current.error = ErrorType.Unauthorized;
    password.current.value = e.currentTarget.value;
    rerender();
  }

  const mediaType = type
    ?? (src?.startsWith("data:") ? src.split(",")[0].split(":")[1].split(";")[0] : null)
    ?? value?.mediaType
    ?? "application/binary";
  const embedImage = _image.includes(mediaType);
  const embedPdf = mediaType === _pdf || false;
  const embedRenderedPdf = (value?.pdfUrl || false) && progress >= 0;
  const embedOffice = _office.includes(mediaType);
  const showPreview = embedImage || embedPdf || embedRenderedPdf || embedOffice;
  const pageClassNames = [pageClassName, sideBySide ? "col-6" : ""].join(" ");

  const pdfLoaded = ({ numPages }: pdfjs.PDFDocumentProxy) => setNumPages(numPages);
  const columns = sideBySide ? 2 : 1;
  const width = (previewDiv.current?.clientWidth ?? 0) / columns || undefined;
  const height = width ? width * 1.41421356 : undefined;
  return showPreview ? (
    <div ref={previewDiv} className={className} style={{ margin: "0px -15px", overflow: "hidden" }}>
      {(embedPdf || embedRenderedPdf) ? (
        <>
          {progress < 100 && <Progress style={{ height: "2px" }} color={progress < 0 ? "danger" : "primary"} value={progress < 0 ? 100 : progress} />}
          {password.current.error !== ErrorType.Ok &&
            <Col className="my-3">
              <Form>
                <FormGroup required label="Password" help="This document is protected with a password. Please enter this document's password to view it." feedback={password.current.error === ErrorType.Invalid ? "Not valid" : undefined}>
                  <div className="d-flex">
                    <Input invalid={password.current.error === ErrorType.Invalid} type="password" value={password.current.value} onChange={updatePassword} />
                    <Button className="ml-auto" color="primary" onClick={submitPassword}>Submit</Button>
                  </div>
                </FormGroup>
              </Form>
            </Col>
          }
          {progress != -1 &&
            <Collapse isOpen={progress === 100}>
              <Document key={src ?? value?.pdfUrl} file={src ?? value?.pdfUrl} externalLinkTarget="_blank" loading=""
                onSourceError={() => setProgress(-1)}
                onSourceSuccess={() => setProgress(66)}
                onPassword={onPassword}
                onLoadError={() => setProgress(-1)}
                onLoadSuccess={pdfLoaded}>
                {numPages && previewDiv.current != null && [...Array(numPages)].map((_, i) =>
                  <Page key={i} className={pageClassNames} renderMode={pdfRenderMode || "canvas"}
                    pageIndex={i} width={width}
                    onLoadError={() => setProgress(-1)}
                    onLoadSuccess={() => i === 0 && progress !== -1 && setProgress(90)}
                    onRenderError={() => setProgress(-1)}
                    onRenderSuccess={() => {
                      if (i === numPages - 1 && progress !== -1) {
                        setProgress(100);
                        onLoad?.();
                      }
                    }}
                    onGetTextError={() => setProgress(-1)}
                    onGetAnnotationsError={() => setProgress(-1)}
                    renderTextLayer={!hideTextLayer}
                    renderAnnotationLayer={showAnnotationLayer}
                    renderInteractiveForms={false} />
                )}
              </Document>
            </Collapse>
          }
        </>
      ) : embedOffice ? (
        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(src ?? value?.url ?? "")}`} width={width} height={height} frameBorder={0} onLoad={onLoad}>
          This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.
        </iframe>
      ) : embedImage ? (
        <img className="w-100" src={src ?? value?.url} onLoad={onLoad} />
      ) : null}
    </div>
  ) : null;
}

export default ContentPreviewDiv;
