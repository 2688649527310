import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Diff from "diff";
import React, { useState } from "react";
import { Button, CustomInput, Form, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Fragment as ProductFragment } from "../cards/ProductCard";
import { Markdown } from "../components/CardMarkdownText";
import FormGroup from "../components/FormGroup";
import LoadingProgress from "../components/LoadingProgress";
import MutationButton from "../components/MutationButton";
import { DeepPartial, nextSequence, useMutation, useReadyState } from "../hooks/ApiProvider";
import { useNavigation } from "../hooks/NavigationHook";
import { ChangeOperationType, MessageModel, ProductModel, ResourceOperationModel } from "../types/api-graph-types";

library.add(faPlus, faFilter);

const _importProducts = `mutation importProducts($filename:String $preview:Boolean!) {
  me {
    products(first:0) {
      change(filename:$filename)
      ops { objectId changes { type message sourceText targetText } }
      commit @skip(if:$preview) {
        id
        ${ProductFragment({ showStatus: true })}
      }
    }
  }
}`

interface OperationResults {
  me: {
    products: {
      count: number
      ops: ResourceOperationModel[]
      preview: MessageModel[]
      commit: ProductModel[]
    }
  }
}

function _e(s: string) {
  s = s ?? "";
  s = s == "Null" || s == "NULL" || s == "null" ? "" : s;
  return s;
}

export function ImportModal({ onUpdate, isOpen, toggle }: {
  onUpdate?: (values: [DeepPartial<ProductModel>]) => void,
  isOpen: boolean,
  toggle?: () => void
}) {
  const pageSize = 10;
  const [, go] = useNavigation();
  const [uid] = useState(nextSequence());
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [readyState, setReadyState] = useReadyState();
  const [ops, setOps] = useState<ResourceOperationModel[]>([]);
  const [error, setError] = useState<string>();
  const maxPage = Math.floor(ops.length / pageSize);
  const [file, setFile] = useState<File>();
  const [mutation] = useMutation<OperationResults>();
  const isValid = ops.length > 0 && ops.every(o => o.changes.every(c => c.type !== ChangeOperationType.Problem));

  const cancel = () => {
    toggle?.();
    setPage(0);
    setOps([]);
    setFile(undefined);
    setError(undefined);
  }
  const updateFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]?.name) {
      const file = e.target.files?.[0];
      setFile(file);
      setOps([]);
      if (file.name.toLowerCase().endsWith(".xlsx")) {
        setError(undefined);
        var result = await mutation(_importProducts, { filename: file.name, preview: true }, { files: [file], setReadyState: setReadyState });
        setOps(result.me.products.ops);
      } else {
        setError("Not supported");
      }
    }
  }
  const importData = async () => {
    if (file) {
      var result = await mutation(_importProducts, { filename: file.name, preview: false }, { files: [file], setReadyState: setReadyState });
      cancel();
    }
  }

  const opsPage = ops.slice(page * pageSize, Math.min(page * pageSize + pageSize, ops.length));

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-header d-flex flex-row align-items-baseline">
        <h5 className="modal-title">Import {ops.length > 0 ? ops.length : ""} items</h5>
        {maxPage > 0 &&
          <div className="ml-auto d-flex align-items-baseline">
            <Button className="py-0" color="link" disabled={page === 0} onClick={() => setPage(page - 1)}><FontAwesomeIcon icon="chevron-left" /></Button>
            <span>{page * pageSize + 1} &ndash; {Math.min(page * pageSize + pageSize, ops.length)} of {ops.length}</span>
            <Button className="py-0" color="link" disabled={page === maxPage} onClick={() => setPage(page + 1)}><FontAwesomeIcon icon="chevron-right" /></Button>
          </div>
        }
      </div>
      <ModalBody className="pt-0">
        <LoadingProgress flush show={isLoading} />
        <Form className="mt-3">
          <FormGroup label="File" feedback={error} help="Upload an Excel file containing listings to be updated.">
            <CustomInput type="file" id={uid} name="file" className="text-nowrap text-truncate" label={<Label className={file?.name ? "" : "text-muted"}>{file?.name || "Select your file here"}</Label>} onChange={updateFile} />
          </FormGroup>
        </Form>
      </ModalBody >
      {ops.length > 0 &&
        <ListGroup flush className="bt-1">
          {opsPage.map(o =>
            <ListGroupItem>
              <small className="text-muted">{o.objectId.substr(0, 8)}</small>
              <ul>
                {o.changes.map(c => {
                  var diff = Diff.diffWordsWithSpace(_e(c.sourceText), _e(c.targetText))
                    .map(({ added, removed, value }) => added ? `__${value.trim()}__` : removed ? `~~${value.trim()}~~` : `${value}`).join("");
                  return (
                    <li className={c.type === ChangeOperationType.Problem ? "text-danger" : ""}>
                      <Markdown className="text-highlight-diff">{c.message + " " + diff}</Markdown>
                    </li>
                  );
                })}
              </ul>
            </ListGroupItem>
          )}
        </ListGroup>
      }
      <ModalFooter>
        {ops.length > 0 &&
          <span>
            <strong>{ops.length}</strong> listings will be updated. Continue?
          </span>
        }
        <Button className="ml-auto" color="link" onClick={cancel}>Cancel</Button>
        <MutationButton readyState={readyState} disabled={!isValid} color="primary" onClick={importData}>Import</MutationButton>
      </ModalFooter>
    </Modal >
  );
}

export default ({ }: {}) => {
  return (<Modal />);
}
