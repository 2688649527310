import React, { useEffect } from "react";
import { useNavigation } from "../hooks/NavigationHook";

const _goCodeProducts = new Map<string, string>([
  ["3MBLR", "860dd832-0df4-402d-968c-0e28ec9befc8"],
  ["AARNA", "37a7ae8a-f162-4c73-a066-b19bf1d6b2ba"],
  ["AARNA5", "bf99307a-eabd-4450-96f6-51ce896d8d0c"],
  ["AARNAREC", "36a34b4f-1a23-481f-99b4-d70dd2786173"],
  ["ACUITYLAWMUM", "fa05ea53-ceb2-4c60-97a2-746a333cfb86"],
  ["ADVAYALEGAL", "2e90e75f-1892-44a3-b6cb-46f0b2768635"],
  ["ALG", "c44de000-cb87-4fbf-9ab6-da35aa194b45"],
  ["ALGINTERN", "e91e56ab-bcaf-47a4-8cbc-4e467fdcecf0"],
  ["ALGREC", "4da908c0-2970-4cf0-b6e1-e2c50caf7839"],
  ["ALMT15", "222a8cad-2fe2-4270-b1a8-2f4dd4f38258"],
  ["AMICORP", "971faff3-1070-4b33-88e3-a2993e896659"],
  ["ARALAWMUM", "8b46b9d4-4e53-4ef9-a51c-a5eb0a3b9e77"],
  ["ARALAWREC", "deae29e4-744c-451a-a6ec-b7fb88874877"],
  ["ARGUS", "38fdc676-add8-488b-9d85-5f8473090db5"],
  ["ARGUSBLR", "4220afc5-dca9-4d62-932c-9ac7290130ac"],
  ["ARGUSBLRREC", "38fdc676-add8-488b-9d85-5f8473090db5"],
  ["ASCHANDHIOK", "234bc20b-1994-4374-b528-8e61f2fd21a3"],
  ["ASHOKLEYCH", "c5f4cef8-d93a-4b2b-8ae8-8ef0d1252765"],
  ["ASLC15", "a799702d-952d-4434-a4d6-1e24b0889ad4"],
  ["ASLC16", "a799702d-952d-4434-a4d6-1e24b0889ad4"],
  ["ASLC17", "a799702d-952d-4434-a4d6-1e24b0889ad4"],
  ["ASLC18", "a799702d-952d-4434-a4d6-1e24b0889ad4"],
  ["ASLC19", "a799702d-952d-4434-a4d6-1e24b0889ad4"],
  ["AZBMUM", "feb951fb-9717-4375-9577-d8d184971ee0"],
  ["BHARUCHA", "f0778656-02c1-4f74-9f97-b8b0a401af5a"],
  ["BHARUCHADEL", "12814f5b-dbb8-4224-91a8-7f79a78b8b07"],
  ["BHARUCHAREC", "33e79c38-ac52-4dd4-be61-5d2498b8317d"],
  ["BHARUCHARECDEL", "d0d3e2d3-d726-4569-8fbd-4cae66b31751"],
  ["CAM", "a1611beb-cfae-40bf-b9e8-791b27d35088"],
  ["CLONECT15", "d4da5922-fdd4-4a98-954f-fffe928f41a8"],
  ["COUNSEPRO", "28238f26-2565-4af7-b128-d57757c0de57"],
  ["COUNSEPROREC", "59337d87-7df7-472e-bc0c-17e07a6709f7"],
  ["CRESTLAW", "4739d74a-bd1c-4acf-9bd1-6a0603f2f5bb"],
  ["CRESTLAWREC", "b6c69153-7002-4958-90a5-72f460554459"],
  ["DISNEY", "c4e5767b-038e-4f94-9bb4-e111ac119d20"],
  ["DMD", "142fd2c6-748b-4c3b-8c45-56a533c5d67f"],
  ["DSK", "7cc320ac-9809-428a-86c6-d49d3025657a"],
  ["DSKPUNE", "7cc320ac-9809-428a-86c6-d49d3025657a"],
  ["DUA15", "d944f64b-1e75-4ac9-8ad2-ba3e1687a561"],
  ["DUABLR", "43592bef-ce14-4f14-8a7f-ee46d9bcb822"],
  ["DUACHE", "3a3d0145-e6f1-4bd0-b051-755d9265fd7b"],
  ["DUADEL", "220768a2-e487-4124-90a2-242e5e94dd37"],
  ["ELPJINCR", "423c47d2-af1c-46b6-85f3-9e06fae98bd1"],
  ["ELPMUMREC", "939ce105-6902-47ed-94ac-02bb42ce21b8"],
  ["ELPPUNE", "55426279-49fc-4c11-8a87-8eaafbc7fd19"],
  ["FOX15", "d5fc63ed-9eb5-42ff-a7a5-8bc947a41b02"],
  ["GOOGLE", "f5052f77-61fb-40b7-b6df-3ef0e8eaa8ff"],
  ["GRP", "3e012a9a-00d5-46cd-b6c2-bf2142246b34"],
  ["HKINTERN", "4ba2bed8-f7c7-41f7-88f4-9427928310b9"],
  ["HKREC", "b68c45fc-e0c8-4640-bb49-61ed90bc60d5"],
  ["HRLNMUM", "d668ae15-e340-4e8b-b7b9-07dc4167718e"],
  ["HSB", "6132c0d4-8d6c-413f-8551-767b2401a733"],
  ["INDUSBLR", "442930ba-0f3a-4f1d-a545-6c26917e9b60"],
  ["INDUSCO", "ec105619-8409-473c-af02-2b9a7567bee4"],
  ["INDUSCOEXT", "14fa6bf3-603e-4dce-84ec-50002909fee8"],
  ["INDUSCOEXT1", "26837c70-e88d-4c6f-951b-86780175973f"],
  ["INDUSCOEXT2", "5ed1d3ae-6bb0-4fa9-99be-c1629bfa0026"],
  ["INDUSLIT", "fe7f96b3-adb8-4ac0-aebf-284ffe279fcd"],
  ["INDUSLITEXT", "f1770497-cbaf-450f-a5be-d83f3dfd1398"],
  ["INDUSRE", "3125aca6-7974-47ee-aecf-01fba8d51174"],
  ["INFY", "752c01d5-61d5-408e-a28e-dcd7cf0a20cf"],
  ["INFY15", "752c01d5-61d5-408e-a28e-dcd7cf0a20cf"],
  ["INFY16", "621bd73c-c774-4c5e-beca-1aee810c27ef"],
  ["INFY17", "752c01d5-61d5-408e-a28e-dcd7cf0a20cf"],
  ["INFY18", "752c01d5-61d5-408e-a28e-dcd7cf0a20cf"],
  ["INFY19", "752c01d5-61d5-408e-a28e-dcd7cf0a20cf"],
  ["JSA15", "79d27160-0e7a-421d-b70b-2e54916ee70c"],
  ["JSAAHM", "99ecf75b-17b0-4f33-aa22-77d2cb356c77"],
  ["JSAAHMREC", "b278d45e-439f-4dff-bbd5-7f10f8f970c8"],
  ["JSABLR", "9a7fd3a5-ca8d-4cee-b1cb-adbc357f6fb7"],
  ["JSABLR15", "9a7fd3a5-ca8d-4cee-b1cb-adbc357f6fb7"],
  ["JSABLRREC", "a70d40cd-24b0-4489-af67-32a83abd5581"],
  ["JSACHE", "891f6fe1-267e-44aa-a2e6-b16abb8c4c3a"],
  ["JSACHEPART", "295e6d53-bf44-4f45-89bd-a3e7d0c128d9"],
  ["JSACHEREC", "887aabda-8f66-46f6-b655-6f80d5c58950"],
  ["JSACOMP", "143c736f-bb71-4321-9249-cb2d48046974"],
  ["JSADEL", "481148ca-4f27-44ae-9aa6-c8fca87142de"],
  ["JSADELGUR", "9f184d96-39da-4283-b903-68a93ae0c300"],
  ["JSADELREC", "7508b14f-b1cd-4593-9196-f8283d5d3625"],
  ["JSAGUR", "fdd0812d-5eba-470d-9656-af3a55477072"],
  ["JSAGURREC", "05cce08a-ebfc-49a4-9b04-a94e7c98a6db"],
  ["JSAHYD", "c660325d-d8b4-4211-931a-c3f8cd1bee74"],
  ["JSAHYDREC", "b382f98e-7722-41a9-aae2-c22eb971da8e"],
  ["JSAMUM", "3536971d-eedf-4fde-9e5f-3244de3cb928"],
  ["JSAMUMREC", "618fb792-cb20-4e79-9d09-56cf47680b7d"],
  ["JSAPTCHE", "295e6d53-bf44-4f45-89bd-a3e7d0c128d9"],
  ["JURISCORPREC", "399900ac-b7d7-4b82-bbc8-e02076b12279"],
  ["KANDSBLR", "0f1a0665-c138-4803-97c6-65c520856899"],
  ["KHAIJINCR", "8b33d1e5-3be5-4a04-8f0b-a8f5942a7026"],
  ["KHAITANMUMBAI", "9d3970d4-f45e-4ef7-a20d-592614bc7139"],
  ["KLAW", "3c327c0a-a846-453b-b4b8-e90fc0c82f38"],
  ["KLAWREC", "7e0d176c-fb0a-4fa9-b2dd-f8e4c33ed40a"],
  ["KNP", "c2a92cc5-7222-4527-85f6-3d7e72e2d1b6"],
  ["KNSBLR", "90635ac2-c8d9-403e-9274-6ec28d77b1bf"],
  ["KNSGUR", "b788cd09-1ab7-4d83-b7b5-26c5156a04a4"],
  ["KNSREC", "0ef04984-1654-4457-a5a9-4a68d8c52d69"],
  ["KOCH15", "7a873af8-f643-4cff-8f83-fb16f1c66cf3"],
  ["KOCHBLR", "66834bab-f01d-4b0d-958e-2204a1015941"],
  ["KOCHBLRREC", "9cf78d52-e68f-48a0-bd3b-309873dd5a08"],
  ["KOCHCHE", "3bc3b002-8c0d-4f00-bf75-5ca116873cb0"],
  ["KOCHDEL", "8f982249-2339-4e0e-92d8-b12e9cbb199b"],
  ["KRPLACEMENT", "f9abd240-c121-40ed-bdf0-634211b58583"],
  ["LANDTMUM", "59c0d1b4-08d1-4cd0-aa7a-3e7d0d0e8358"],
  ["LAWB", "d0510b74-bce7-48c6-8f46-4a341c7bd54f"],
  ["LAWYERSINC", "a6608930-2b40-4ff9-abb2-1fdb364c2139"],
  ["LAWYERSINCREC", "4bb3df18-9c63-4998-9e4d-e570ac81f214"],
  ["LEXPLEXUS", "5e3cc26e-ab98-48a9-b6c8-ada97da6dfad"],
  ["LEXPLEXUSREC", "1855058d-0b07-4b54-93b8-4e3efae6b16f"],
  ["LINKLEGALDE", "117592b5-7a26-42bb-82d4-3deba65a6359"],
  ["LSA", "e5856cb9-0a8b-4b1d-8800-60e4402e19bf"],
  ["LSAREC", "9265be83-635b-4e92-9c0c-7d091a580912"],
  ["LSBLR", "bdad1287-ab45-45e3-a68d-90f425427776"],
  ["LSDE", "b19c8f93-3050-47b9-b034-79f0138088f2"],
  ["MLC15", "e02bad9c-6d9a-4747-a48b-5d28bf49069d"],
  ["MSA", "2a625c6a-34a4-4e4f-b16b-0bea9698f5ef"],
  ["MSAREC", "73ec90e5-b60d-4d5f-a795-e93e1d39f9fe"],
  ["NALSAR15", "06fce81b-7d9c-42f9-8b55-e65e419de4aa"],
  ["NNICO", "2bdef9c6-7b58-4848-87d0-337107e7cbba"],
  ["PC", "2a79acf9-96c9-4a06-a376-d33c95f25a92"],
  ["PHOENIXCHE", "30c74785-2288-4d3d-888b-f5c39d0de10e"],
  ["PLACEMENT", "086fd59d-fd76-46ae-b253-547d9e6df78e"],
  ["PLPBLR", "5810b709-3a15-4c95-8228-6b3e293fc03d"],
  ["PNX15", "e02bad9c-6d9a-4747-a48b-5d28bf49069d"],
  ["PSA", "b01bf3fb-4f41-4791-9b08-69ec2dc57047"],
  ["RESEARCH", "3e012a9a-00d5-46cd-b6c2-bf2142246b34"],
  ["SAHA", "15ac7181-82e5-4722-b32f-eaa52e5ac4b6"],
  ["SAM", "cfa1930c-e634-4ca3-a06d-33830200dcef"],
  ["SAMREC", "035ff60e-2153-4808-987b-f63d84667a82"],
  ["SOLMUM", "b89a226f-3970-4512-be15-bb6b46742c01"],
  ["SPMUMBAI", "4d086cf4-2ab9-48bc-afb9-a71117c1f3d2"],
  ["SUR15", "6543a2fc-e4c1-47bd-abcb-8e463684871f"],
  ["SVDBLR", "28ed509b-b473-4639-baa6-68ba4cc85002"],
  ["SYMBINOIDA2", "775f5ca1-d63e-4039-9a8a-40ab83d7f6cd"],
  ["TATVABLR", "c6cebcb1-1cbf-4c10-b6e6-704e6ed13c86"],
  ["TATVABLRREC", "bf28b1fb-7201-4437-93a3-c7d69fe6cf24"],
  ["TATVAHYD", "ea740e6c-9275-42ea-96a9-8595c114535c"],
  ["TRA", "c662881f-f38b-4e82-ab39-ffd5c11dcabe"],
  ["TRA15", "c662881f-f38b-4e82-ab39-ffd5c11dcabe"],
  ["TRAREC", "8e49258f-eec7-41b3-90ea-d81817aafc3c"],
  ["TRI", "e1746e8a-f122-46c5-9c1a-feb4e933e898"],
  ["TRI15", "e9ed3cd7-9936-443d-b529-be30bb81d294"],
  ["TRIBLR", "e9ed3cd7-9936-443d-b529-be30bb81d294"],
  ["TRIBLRREC", "4bc31c66-a7e7-49e6-8a86-c67257202648"],
  ["TRIDEL", "a788f0e8-1496-4b23-81b2-064f33090c2b"],
  ["TRIDELREC", "be498b84-0c92-4fbc-a242-1f3224da1834"],
  ["TRIGLCCR", "34f55a02-91ce-4b53-8017-45ebc77e6b19"],
  ["TRIGNLU", "a7c4b149-5aa8-4e40-a900-4a269de3e170"],
  ["TRIGNLUCR", "34f55a02-91ce-4b53-8017-45ebc77e6b19"],
  ["TRIGUR", "80a6ed79-3e19-425e-82e3-58377205a093"],
  ["TRIGURREC", "137cc5f2-ccd7-4c0d-95cb-2997d6b7a5d9"],
  ["TRIHYD", "7a66e24e-b3a9-4658-b80a-3b18fb6dccff"],
  ["TRIHYDREC", "ebae34e3-22bd-4ccc-9d5b-ef01c63e421f"],
  ["TRIINTERN", "e7a85d74-4c64-4b0c-8655-80744e080f0f"],
  ["TRIJIN", "a7c4b149-5aa8-4e40-a900-4a269de3e170"],
  ["TRIJINCR", "34f55a02-91ce-4b53-8017-45ebc77e6b19"],
  ["TRILEGAL", "6af7362d-d833-4777-803d-4d37bd2ce3e2"],
  ["TRIMUM", "a0ecf002-9028-4bcd-a7b3-73bd4aef257e"],
  ["TRIMUMREC", "a7b03630-602d-4eb4-b07d-a25db00b4063"],
  ["TRINALSAR", "a7c4b149-5aa8-4e40-a900-4a269de3e170"],
  ["TRINALSARCR", "34f55a02-91ce-4b53-8017-45ebc77e6b19"],
  ["TRINLIUB", "a7c4b149-5aa8-4e40-a900-4a269de3e170"],
  ["TRINLIUCR", "34f55a02-91ce-4b53-8017-45ebc77e6b19"],
  ["TRINLSIU", "a7c4b149-5aa8-4e40-a900-4a269de3e170"],
  ["TRINLSIUCR", "34f55a02-91ce-4b53-8017-45ebc77e6b19"],
  ["TRINLUD", "a7c4b149-5aa8-4e40-a900-4a269de3e170"],
  ["TRINLUDCR", "34f55a02-91ce-4b53-8017-45ebc77e6b19"],
  ["TRINLUJ", "a7c4b149-5aa8-4e40-a900-4a269de3e170"],
  ["TRINLUJCR", "34f55a02-91ce-4b53-8017-45ebc77e6b19"],
  ["TRINUJS", "a7c4b149-5aa8-4e40-a900-4a269de3e170"],
  ["TRINUJSCR", "34f55a02-91ce-4b53-8017-45ebc77e6b19"],
  ["TTA", "854b6899-7fe9-4759-98f0-f27197e2133f"],
  ["TTAREC", "4f604cdc-015f-479a-8713-9b8defbac2b1"],
  ["UNILEGALCH", "4941205b-29a1-4c72-b99f-7cf412eafc8b"],
  ["UNILEGALCHE", "4941205b-29a1-4c72-b99f-7cf412eafc8b"],
  ["UNILEGALDE", "46cc1b0f-64b6-4403-99b9-419dac5d41a2"],
  ["UNILEVERCHE", "e4456b20-c804-45ca-ac8d-7ab7e7b086a6"],
  ["UNILEVERCHEPART", "20b2f7ba-a8d4-45a1-adef-ecf655d83b09"],
  ["UNILEVERMUM", "93f12d22-2b82-40d4-97ad-b58b9fb3312e"],
  ["VAISHDEL", "858c0a5e-8c9e-498e-8de9-e3538a8eec13"],
  ["VAISHMUM", "ba1bd2c1-3489-4a8a-9401-87e3b19c3747"],
  ["VERITASMUM", "44e6b916-ce10-474b-ae20-5a70b3ca6955"],
  ["VF", "fea54b22-e4a6-42ff-a9b6-8c22ff340998"],
  ["VOXDELHI", "29d50702-b6f0-4742-be94-f5a30d488af1"],
  ["WADIA15", "42b1fdf2-0b89-4b5e-b30b-137a282a5fe7"],
  ["WADIACH", "1fe86a2c-839f-4f7c-96ba-ec065c9c288d"],
  ["WIPRO", "611c2c7b-9d03-4f2b-a902-cd36883b9b6b"],
]);

export default () => {
  const path = window.location.pathname;
  const [, go] = useNavigation();
  useEffect(() => {
    if (path.startsWith("/go/")) {
      const code = path.split("/")[2].toUpperCase();
      const productId = _goCodeProducts.get(code);
      if (productId) {
        go(`/my/opportunities/${productId}?scope=claim:SINIATHAMBAR#error=login_required`);
      } else {
        go(`/my/dashboard?scope=claim:${code}#error=login_required`);
      }
    } else if (path.startsWith("/law/student/me/submissions/")) {
      const jobId = decodeURIComponent(path).split("/")[5].split(";")[0];
      go(`/my/tasks/${jobId}`);
    } else {
      go(`/`);
    }
  }, [window.location.pathname]);
  return null;
}
