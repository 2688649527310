import React, { CSSProperties, ReactChildren, ElementType } from "react";
import ReactMarkdown from "react-markdown";
import Clamp from "../components/Clamp";
import Emoji from 'react-emoji-render';
import ExternalLink from "../components/ExternalLink";
import { useDocumentContext, DocumentType } from "../hooks/DocumentProvider";
import { useNavigation } from "../hooks/NavigationHook";

interface Attributes {
  className?: string;
  source?: string;
  clampHeight?: string | number;
  children?: string;
  style?: CSSProperties;
  justify?: boolean;
  hyphens?: boolean;
  plain?: boolean
}

function RenderLink({ href, children }: { href: string, children?: ReactChildren }) {
  const [navigate] = useNavigation();
  const title = React.Children.map(children, (_: any) => _ && _.props && _.props.value as string).filter(_ => _ && _ !== href)[0];
  const [documentType, addReference] = useDocumentContext();
  const showInline = documentType !== DocumentType.Document;
  const isInternal = href.startsWith("/");
  const isButton = href.indexOf("#__button") != -1;
  const btnHref = isButton ? href.replace(/#__button[a-z_]*/, "") : "";
  const btnClassName = !isButton ? undefined
    : href.indexOf("#__button_primary") != -1 ? "btn btn-primary mr-2"
      : href.indexOf("#__button_light") != -1 ? "btn btn-light mr-2"
        : "btn mr-2";
  return isButton ? <a className={btnClassName} href={btnHref} onClick={navigate(btnHref)}>{title || btnHref}</a>
    : isInternal ? <a href={href} onClick={navigate(href)}>{title || href}</a>
      : showInline ? <ExternalLink href={href} title={title} />
        : <span>[{addReference({ title, href })}]</span>;
}

function RenderText({ value }: { value: string }) {
  return <Emoji text={value} />;
}

function RenderYoutube({ vid }: { vid: string }) {
  return (
    <div>
      <div style={{ position: "relative", paddingTop: "56.25%" }}>
        <iframe width="100%" src={`https://www.youtube.com/embed/${vid}`}
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          frameBorder="0" allow="encrypted-media; picture-in-picture" allowFullScreen />
      </div>
    </div>
  );
}

const youtubePattern1 = /^https:\/\/www.youtube.com\/watch\?v=(?<vid>[^&#]*)/;
const youtubePattern2 = /^https:\/\/youtu.be\/(?<vid>[^?#]*)/;
function RenderImage({ alt, src }: { alt: string, src: string }) {
  const youtubeLink = src.match(youtubePattern1) || src.match(youtubePattern2);
  return youtubeLink?.groups?.vid ? <RenderYoutube vid={youtubeLink.groups.vid} />
    : <img src={src} alt={alt} />;
}

function RenderLinkPlain({ href, children }: { href: string, children?: ReactChildren }) {
  const title = React.Children.map(children, (_: any) => _ && _.props && _.props.value as string).filter(_ => _ && _ !== href)[0];
  return <span>{title}</span>;
}

const _renderers = {
  "link": RenderLink,
  "text": RenderText,
  "image": RenderImage
};

const _plainRenderers = {
  "link": RenderLinkPlain
};

const _softBreak = /([^\n])\n([^\n])/g;
function _convertSoftLineBreaksToHardLineBreaks(value: string) {
  return value.replace(_softBreak, "$1  \n$2");
}

function _ignoreYamlMatter(value: string) {
  if (value.startsWith("---\n")) {
    const start = value.indexOf("\n---\n", 3);
    if (start > 0) {
      value = value.substring(start + 5);
    }
  }
  const trimmed = value.trimRight();
  if (trimmed.endsWith("\n---")) {
    const end = trimmed.lastIndexOf("\n---\n", trimmed.length - 5);
    if (end > 0) {
      value = value.substring(0, end);
    }
  }
  return value;
}

function _prepare(value: string) {
  value = _ignoreYamlMatter(value);
  value = _convertSoftLineBreaksToHardLineBreaks(value);
  return value;
}

export function Markdown({ className = "", justify, hyphens, source, children, clampHeight, plain, ...attrs }: Attributes) {
  const classNames = [
    className,
    justify ? "text-justify" : "",
    hyphens ? "text-hyphens" : "",
  ].join(" ");
  return (
    <ReactMarkdown className={`markdown ${classNames}`} renderers={plain ? _plainRenderers : _renderers} {...attrs}>
      {_prepare(source || children || "")}
    </ReactMarkdown>
  );
}


export default ({ className = "", hyphens, justify, source, children, clampHeight, plain, ...attrs }: Attributes) => {
  const classNames = [
    !!clampHeight ? "" : className,
    justify ? "text-justify" : "",
    hyphens ? "text-hyphens" : "",
  ].join(" ");
  const el = (
    <ReactMarkdown className={`markdown card-text ${classNames}`} renderers={plain ? _plainRenderers : _renderers} {...attrs} >
      {_prepare(source || children || "")}
    </ReactMarkdown>
  );
  return clampHeight ? <Clamp height={clampHeight} className={className}>{el}</Clamp> : el;
};
