import { ResourceKind } from "../types/api-graph-types";
import { useToggle } from "./CommonHooks";

export function useCardState(options: {
  showInput?: boolean,
  showDiscussion?: boolean,
  showShare?: boolean,
  openInput?: boolean,
  openDiscussion?: boolean,
  openShare?: boolean,
}) {
  const [isOpenInput, toggleInput] = useToggle(options.openInput);
  const [isOpenDiscussion, toggleDiscussion] = useToggle(options.openDiscussion);
  const [isOpenShare, toggleShare] = useToggle(options.openShare);
  return {
    isOpenInput: options.showInput ? isOpenInput : false,
    isOpenDiscussion: options.showDiscussion ? isOpenDiscussion : false,
    isOpenShare: options.showShare ? isOpenShare : false,
    toggleInput: options.showInput ? toggleInput : undefined,
    toggleDiscussion: options.showDiscussion ? toggleDiscussion : undefined,
    toggleShare: options.showShare ? toggleShare : undefined
  };
}
