import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, Alert, CustomInput } from "reactstrap";
import { Group as ColorButtonGroup } from "../components/ColorButton";
import ContentPreviewDiv, { Fragment as ContentPreviewFragment } from "../components/ContentPreviewDiv";
import FormGroup from "../components/FormGroup";
import ValidatingInput from "../components/Input";
import MutationButton from "../components/MutationButton";
import { ApiErrors, DeepPartial, nextNonce, useMutation, useReadyState, useRoute, nextSequence } from "../hooks/ApiProvider";
import { useUpdatableState, useToggle } from "../hooks/CommonHooks";
import { useMe } from "../hooks/MeProvider";
import { parseURIComponents, preventDefault, useNavigation } from "../hooks/NavigationHook";
import { DateDisplayFormat, useTranslation, toLocalDate, toUtcString } from "../hooks/TranslationProvider";
import { AttachmentInputModel, AttachmentModel, ResourceState, AttachmentType } from "../types/api-graph-types";
import moment from "moment";

library.add(faEdit, faTrashAlt);

export const Fragment = `
  id
  kind
  state
  type
  title
  subtitle
  filename
  contentUri
  url
  updatedOn
  length
  issuerName
  issuerOrganizationName
  issuerTitle
  issuerEmail
  issuerPhone
  issuerWebsite
  issuerReferenceId
  issuedOn
  issuedUntil
  ${ContentPreviewFragment}
`;

const _updateAttachment = `mutation updateAttachment($id:ID $value:AttachmentInputModel) {
  attachment(id:$id) {
    change(value:$value)
    commit { ${Fragment} }
  }
}`

const _addAttachment = `mutation addAttachment($value:AttachmentInputModel) {
  me {
    attachment: addAttachment {
      change(value:$value)
      commit { ${Fragment} }
    }
  }
}`

interface OperationResults {
  attachment: {
    commit: AttachmentModel
  },
  me: {
    attachment: {
      commit: AttachmentModel
    }
  }
}

interface InputProps {
  value: AttachmentModel;
  titlePlaceholder?: string;
  subtitlePlaceholder?: string;
  showIssuer?: boolean;
  showIssuerOrg?: boolean;
}

const _newAttachment = {} as AttachmentModel;
const _resumeUri = "hk:content:generated:resume";
const _resumeStyles = [
  ["2020", "/img/generated-resume-style-0.webp"],
  ["2019", "/img/generated-resume-style-1.png"],
];

// https://coolors.co/3e007b-003277-14454a-144a21-675107-722800-670707-760064
const _resumeColors = [
  "#3e007b",
  "#003277",
  "#14454a",
  "#144a21",
  "#675107",
  "#722800",
  "#670707",
  "#760064",
  "#ffffff",
  "#f8f9fa",
  "#000000"
];

function _toAttachmentInput(value: AttachmentModel): AttachmentInputModel {
  return {
    type: value.type,
    state: ResourceState.Resolved,
    filename: value.filename,
    title: value.title,
    subtitle: value.subtitle,
    content: value.filename,
    contentUri: value.contentUri,
    issuerName: value.issuerName,
    issuerOrganizationName: value.issuerOrganizationName,
    issuerTitle: value.issuerTitle,
    issuerEmail: value.issuerEmail,
    issuerPhone: value.issuerPhone,
    issuerWebsite: value.issuerWebsite,
    issuerReferenceId: value.issuerReferenceId,
    issuedOn: value.issuedOn,
    issuedUntil: value.issuedUntil,
  } as AttachmentInputModel as any;
}

export default ({ isOpen, value, titlePlaceholder: _titlePlaceholder, subtitlePlaceholder: _subtitlePlaceholder, showIssuer: _showIssuer, showIssuerOrg: _showIssuerOrg, onUpdate, toggle }: {
  value: AttachmentModel,
  onUpdate?: (value: DeepPartial<AttachmentModel>) => void,
  isOpen?: boolean,
  toggle?: () => void
} & InputProps) => {
  const route = useRoute("/api/content/persons/{me}/resume/{nonce}/resume.pdf?style={style}&color={color}&access_token={access_token}");
  const [, d] = useTranslation();
  const [, go] = useNavigation();
  const [uid] = useState(nextSequence());
  const [me] = useMe();
  const [readyState, setReadyState] = useReadyState();
  const [input, setInput, updateInput] = useUpdatableState<AttachmentInputModel>(_toAttachmentInput(value));
  const [files, setFiles] = useState<File[]>();
  const [errors, setErrors] = useState<ApiErrors<AttachmentInputModel>>({});
  const [nonce, setNonce] = useState(nextNonce());
  const [mutation] = useMutation<OperationResults>();
  const reset = () => {
    setInput(_toAttachmentInput(value));
    setFiles(undefined);
  }
  useEffect(reset, [value]);
  const updateContent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filename = e.target.files?.[0]?.name || "";
    if (filename) {
      updateInput({ filename, content: filename });
      setFiles(Array.from(e.target.files || []));
    }
  }
  const create = async () => {
    const result = await mutation(_addAttachment, { value: input }, { setReadyState, files });
    onUpdate?.(result.me.attachment.commit);
    toggle?.();
  }
  const update = async () => {
    const result = await mutation(_updateAttachment, { id: value.id, value: input }, { setReadyState, files });
    onUpdate?.(result.attachment.commit);
    toggle?.();
  }
  const updateStyle = (style: string) => {
    updateInput({ contentUri: `${_resumeUri}?style=${style}&color=${encodeURIComponent(color)}` });
    setNonce(nextNonce());
  }
  const updateColor = (color: string) => {
    updateInput({ contentUri: `${_resumeUri}?style=${style}&color=${encodeURIComponent(color)}` });
    setNonce(nextNonce());
  }
  const cancel = async () => {
    reset();
    toggle?.();
  }
  const isGeneratedResume = input.contentUri?.startsWith(_resumeUri);
  const { style, color } = parseURIComponents(input.contentUri?.split('?')[1] || "", { style: "2020", color: me?.accentColor || "#000000" });
  const resumePreviewUrl = route.replace("{nonce}", nonce).replace("{style}", style).replace("{color}", encodeURIComponent(color));
  const isGenerated = isGeneratedResume;
  const hasIssuer = !!value.issuerName || !!value.issuerTitle || !!value.issuerEmail || !!value.issuerPhone;
  const hasIssuerOrg = !!value.issuerOrganizationName || !!value.issuerWebsite || !!value.issuerReferenceId;
  const titlePlaceholder = _titlePlaceholder || "Enter document title here";
  const subtitlePlaceholder = _subtitlePlaceholder || "Enter document subtitle here";
  const showIssueDate = ![AttachmentType.Resume, AttachmentType.TaxpayerIdentification, AttachmentType.BusinessCard].includes(input.type);
  const showIssuer = _showIssuer || hasIssuer;
  const showIssuerOrg = _showIssuerOrg || hasIssuerOrg;
  const showTitle = !isGeneratedResume;
  const isUpdate = !!value.id;
  const event = isGeneratedResume ? isUpdate ? "UPDATE GeneratedResume" : "ADD GeneratedResume"
    : undefined;

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        {value.id === "" ? "Upload document" : "Update document"}
      </ModalHeader>
      <ModalBody>
        <Form>
          <fieldset>
            {!isGenerated &&
              <FormGroup label='Document' error={errors.content}>
                <div className='custom-file'>
                  <Input type="file" className={`custom-file-input ${errors.content && "is-invalid"}`} onChange={updateContent} />
                  <label className={`custom-file-label ${!input.filename && "text-muted"}`}>{input.filename || "Select your file here"}</label>
                </div>
                {value.updatedOn && <span className="text-muted small">Last updated {d(value.updatedOn, DateDisplayFormat.TimeAgo)}.</span>}
              </FormGroup>
            }
            {isGeneratedResume &&
              <>
                <Alert color="primary">
                  Update your name, contact information, experience, education and other
                  résumé content in your <a href="/me">profile</a>.
                </Alert>
                {false &&
                  <FormGroup label="Style">
                    <Nav>
                      {_resumeStyles.map((_, i) =>
                        <NavItem key={i}>
                          <NavLink href="#" className="px-0 mr-3" active={style === _[0]} onClick={preventDefault(() => updateStyle(_[0]))}>
                            <img height={150} src={_[1]} />
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </FormGroup>
                }
                <FormGroup label="Accent color">
                  <ColorButtonGroup value={color} options={_resumeColors} onUpdate={updateColor} />
                </FormGroup>
              </>
            }
            {showTitle &&
              <Row form>
                <Col>
                  <FormGroup label='Title' value={input.title} error={errors.title}>
                    <ValidatingInput type="text" placeholder={titlePlaceholder} onUpdate={title => updateInput({ title })} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup label='Subtitle' value={input.subtitle} error={errors.subtitle}>
                    <ValidatingInput type="text" placeholder={subtitlePlaceholder} onUpdate={subtitle => updateInput({ subtitle })} />
                  </FormGroup>
                </Col>
              </Row>
            }
            {showIssueDate &&
              <Row form className="align-items-end">
                <Col>
                  <FormGroup label='Issue date' error={errors.issuedOn}
                    addon={input.issuedOn && !input.issuedUntil ? <CustomInput id={`${uid}.0`} className="ml-3 py-0 col-form-label-sm" type="switch" bsSize="sm" label="No expiry" checked={!input.issuedUntil} onChange={() => updateInput({ issuedUntil: moment(input.issuedOn).add(2, "years").utc().toISOString() })} /> : undefined}>
                    <DatePicker className="form-control" selected={toLocalDate(input.issuedOn)} showMonthYearPicker dateFormat="MMMM yyyy" onChange={v => updateInput({ issuedOn: toUtcString(v) || undefined })} />
                  </FormGroup>
                </Col>
                {!!input.issuedUntil &&
                  <Col>
                    <FormGroup label='Expiration date' error={errors.issuedUntil}
                      addon={<CustomInput id={`${uid}.1`} className="ml-3 py-0 col-form-label-sm" type="switch" bsSize="sm" label="No expiry" checked={!input.issuedUntil} onChange={() => updateInput({ issuedUntil: "" })} />}>
                      <DatePicker className="form-control" selected={toLocalDate(input.issuedUntil)} showMonthYearPicker dateFormat="MMMM yyyy" onChange={v => updateInput({ issuedUntil: toUtcString(v) || undefined })} />
                    </FormGroup>
                  </Col>
                }
              </Row>
            }
            {showIssuer &&
              <>
                <FormGroup label='Issuer name' value={input.issuerName} error={errors.issuerName}>
                  <ValidatingInput type="text" placeholder="Enter issuer name here" onUpdate={issuerName => updateInput({ issuerName })} />
                </FormGroup>
                <FormGroup label='Issuer title' value={input.issuerTitle} error={errors.issuerTitle}>
                  <ValidatingInput type="text" placeholder="Enter issuer title here" onUpdate={issuerTitle => updateInput({ issuerTitle })} />
                </FormGroup>
                <Row form>
                  <Col>
                    <FormGroup label='Issuer email' email value={input.issuerEmail} error={errors.issuerEmail}>
                      <ValidatingInput type="text" placeholder="Enter issuer email here" onUpdate={issuerEmail => updateInput({ issuerEmail })} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup label='Issuer phone' phone value={input.issuerPhone} error={errors.issuerPhone}>
                      <ValidatingInput type="text" placeholder="Enter issuer phone here" onUpdate={issuerPhone => updateInput({ issuerPhone })} />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            }
            {showIssuerOrg &&
              <>
                <FormGroup label='Issuer organization' value={input.issuerOrganizationName} error={errors.issuerOrganizationName}>
                  <ValidatingInput type="text" placeholder="Enter issuer organization here" onUpdate={issuerOrganizationName => updateInput({ issuerOrganizationName })} />
                </FormGroup>
                <FormGroup label='Issuer website' url value={input.issuerWebsite} error={errors.issuerWebsite}>
                  <ValidatingInput type="text" placeholder="Enter issuer website here" onUpdate={issuerWebsite => updateInput({ issuerWebsite })} />
                </FormGroup>
                <FormGroup label='Issuer document number' phone value={input.issuerReferenceId} error={errors.issuerReferenceId}>
                  <ValidatingInput type="text" placeholder="Enter document or credential id here" onUpdate={issuerReferenceId => updateInput({ issuerReferenceId })} />
                </FormGroup>
              </>
            }
            {isGeneratedResume &&
              <FormGroup label="Preview">
                <Col>
                  <ContentPreviewDiv value={{ ..._newAttachment, pdfUrl: resumePreviewUrl }} />
                </Col>
              </FormGroup>
            }
          </fieldset>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>Cancel</Button>
        {isUpdate
          ? <MutationButton readyState={readyState} event={event} color="primary" onClick={update}>Update</MutationButton>
          : <MutationButton readyState={readyState} event={event} color="primary" onClick={create}>Upload</MutationButton>
        }
      </ModalFooter>
    </Modal>
  );
}
