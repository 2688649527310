import React from "react";
import Highlighter from "react-highlight-words";
import { useSearchContext } from "../hooks/SearchProvider";

const _punctuation = /[^A-Za-z0-9 ]/

export default ({ value, children }: { value?: string, children?: string }) => {
  const [query] = useSearchContext();
  const cleansedQuery = query?.value && query.value.replace(_punctuation, "");
  return (
    <Highlighter searchWords={[cleansedQuery, ...cleansedQuery.split(" ")]}
      caseSensitive={false}
      textToHighlight={value || children || ""} />
  );
}


