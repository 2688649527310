import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as fasStar, faStarHalfAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Card, CardBody, CardBodyProps, CardHeader } from "reactstrap";
import { CardAttributes, CardBodyAttributes, CardButtonsAttributes } from "../components/Card";
import { Fragment as AttachmentFragment, List as AttachmentsList } from "../components/CardAttachmentsListGroup";
import CardAttributionBody, { CardRatingButton, Fragment as AttributionFragment } from "../components/CardAttributionBody";
import CardDiscussionListGroup, { Fragment as DiscussionFragment } from "../components/CardDiscussionListGroup";
import CardMarkdownText from "../components/CardMarkdownText";
import { CardButton, CardButtons, CardDiscussionSummaryStatusItems, CardStatus } from "../components/CardStatus";
import { useCardState } from "../hooks/CardStateHook";
import { useReviewOperations } from "../hooks/DiscussionHook";
import { useNavigation } from "../hooks/NavigationHook";
import { useTranslation } from "../hooks/TranslationProvider";
import ReviewInputModal, { Fragment as ReviewInputFragment } from "../modals/ReviewInputModal";
import { ResourceKind, ResourceState, ReviewModel, ReviewOption } from "../types/api-graph-types";

library.add(
  faTimes,
  farStar,
  fasStar,
  faStarHalfAlt
);

export type Option = [number, number, ReviewOption, number, string];

export const ReviewsSummaryFragment = `
  ratingCount
  ratingAverage
  likeCount
  loveCount
`;

export const Fragment = `
  id
  kind
  rating
  message
  like
  love
  approved
  rejected
  options
  author { ${AttributionFragment} }
  attachments(states:[RESOLVED,COMPLETED]) { ${AttachmentFragment} }
  actions { change remove undo }
  ${ReviewInputFragment}  
  ${DiscussionFragment}
`;

function Text({ value }: { value: ReviewModel }) {
  const [t] = useTranslation();
  return (
    <>
      <CardMarkdownText justify hyphens source={value.message} />
    </>
  );
}

function Status({ value, toggleDiscussion }: {
  value: ReviewModel,
  toggleDiscussion?: () => void
}) {
  return (
    <CardStatus>
      <CardDiscussionSummaryStatusItems comments={value.commentsSummary} toggleDiscussion={toggleDiscussion} />
    </CardStatus>
  );
}

function Buttons({ objectId, objectKind, value, toggleInput, onUpdate }: CardButtonsAttributes<ReviewModel> & {
  objectId: string,
  objectKind: ResourceKind
}) {
  const { updateReview, removeReview } = useReviewOperations();
  const { id, actions } = value;
  const update = async () => {
    const { message, options, rating } = value;
    const result = await updateReview(objectId, objectKind, { message, options, rating });
    onUpdate?.({ id: value.id, ...result });
  }
  const remove = async () => {
    const result = await removeReview(value.id);
    onUpdate?.({ id: value.id, ...result });
  }
  return (
    <CardButtons className="mt-3">
      {actions.change && toggleInput && <CardButton icon="pencil-alt" onClick={toggleInput}>Edit</CardButton>}
      {actions.remove && <CardButton icon="archive" onClick={remove}>Archive</CardButton>}
      {actions.undo && value.state == ResourceState.Removed && <CardButton icon="inbox" onClick={update}>Unarchive</CardButton>}
    </CardButtons>
  );
}

export function Body({ objectId, objectKind, value, href, showAttachments, toggleInput, toggleDiscussion, showStatus, showMenu, showButtons, onUpdate, onClick, className, ...attrs }: CardBodyAttributes<ReviewModel> & CardBodyProps & {
  objectId: string,
  objectKind: ResourceKind
}) {
  const [navigate] = useNavigation();
  const classNames = [
    className,
    "hover-container",
    onClick || href || toggleInput ? "hover-shadow cursor-pointer" : ""
  ].join(" ");
  return (
    <CardBody className={classNames} onClick={onClick || navigate(href) || toggleInput} {...attrs}>
      <Text value={value} />
      {showAttachments && <AttachmentsList values={value.attachments} onUpdate={attachments => onUpdate?.({ id: value.id, attachments })} />}
      {showStatus && <Status {...{ value, toggleDiscussion }} />}
      {showButtons && <Buttons {...{ objectId, objectKind, value, onUpdate, toggleInput }} />}
    </CardBody>
  );
}

function Attribution({ value }: { value: ReviewModel }) {
  return (
    <CardAttributionBody value={value.author} at={value.updatedOn}>
      <CardRatingButton readonly {...{ value }} objectId={value.id} objectKind={ResourceKind.Review} />
    </CardAttributionBody>
  );
}

export default ({ value, objectId, objectKind, title, href, showHeader, showAttribution, showStatus, showMenu, showAttachments, showDiscussion, showInput, showShare, openDiscussion, openInput, showButtons, onClick, onUpdate }: CardAttributes<ReviewModel> & {
  objectId: string,
  objectKind: ResourceKind,
  title: string
}) => {
  const [t] = useTranslation();
  const { isOpenInput, isOpenDiscussion, toggleInput, toggleDiscussion } = useCardState({ showInput, showDiscussion, openDiscussion, openInput });
  return (
    <Card>
      {showAttribution && <Attribution value={value} />}
      {showHeader && <CardHeader className="bg-white" tag="h3">{t(value.type)}</CardHeader>}
      <Body {...{ objectId, objectKind, value, href, onUpdate, onClick, showAttachments, showStatus, showMenu, showButtons, toggleInput, toggleDiscussion }} />
      {showDiscussion && <CardDiscussionListGroup forcePublic value={value} onUpdate={onUpdate} isOpen={isOpenDiscussion} />}
      {showInput && <ReviewInputModal objectId={objectId} objectKind={objectKind} title={title} value={value} onUpdate={onUpdate} isOpen={isOpenInput} toggle={toggleInput} />}
    </Card>
  );
}
