import React from "react";
import { Route, Switch } from "react-router";
import { Col, Row, Nav, NavItem, NavLink } from "reactstrap";
import StaticContentCard, { Policies, Namespaces } from "../cards/StaticContentCard";
import { useNavigation } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";

interface Key { key: string; }
interface R<T> { match: { params: T; } }

const _pages = [
  { name: Policies.TermsOfUse, title: "Terms of Use" },
  { name: Policies.Privacy, title: "Privacy" },
  { name: Policies.CookiesNotice, title: "Cookies" },
  { name: Policies.CodeOfConduct, title: "Code of Conduct" },
  { name: Policies.Trademark, title: "Trademark" },
  { name: Policies.Refunds, title: "Refunds" },
];

function Content({ name }: { name: string }) {
  const page = _pages.filter(_ => _.name === name)[0];
  useTitle(`${page?.title}`);
  return (
    <StaticContentCard namespace={Namespaces.Policies} name={name} />
  );
}

export default () => {
  const path = window.location.pathname;
  const [navigate] = useNavigation();
  return (
    <Row className="mt-3">
      <Col lg={3}>
        <Nav className='flex-md-column sticky-top'>
          {_pages.map((_, i) => {
            const href = `/our/policies/${_.name}`;
            return (
              <NavItem key={i} className='mr-1'>
                <NavLink active={path === href} href={href} onClick={navigate(href)}>{_.title}</NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </Col>
      <Col lg={9}>
        <Switch>
          <Route exact path="/our/policies/:key" render={(_: R<Key>) => <Content name={_.match.params.key} />} />
        </Switch>
      </Col>
    </Row>
  );
}
