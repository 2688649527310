import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArchive, faDownload, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, CardHeader, Collapse, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, UncontrolledDropdown } from "reactstrap";
import { CardButton, CardButtons, preventOverflow } from "../../components/CardStatus";
import { Fragment as ContentPreviewFragment } from "../../components/ContentPreviewDiv";
import { CustomDropdownItem } from "../../components/CustomDropdown";
import Show from "../../components/Show";
import { DeepPartial, setDelete, useMutation } from "../../hooks/ApiProvider";
import { useToggle } from "../../hooks/CommonHooks";
import { useConfirmation } from "../../hooks/ConfirmationProvider";
import { useIcons } from "../../hooks/IconizeHook";
import { useMe } from "../../hooks/MeProvider";
import { preventDefault, useNavigation } from "../../hooks/NavigationHook";
import { DateDisplayFormat, useTranslation } from "../../hooks/TranslationProvider";
import AttachmentInputModal, { Fragment as AttachmentInputFragment } from "../../modals/AttachmentInputModal";
import { AttachmentModel, AttachmentType } from "../../types/api-graph-types";

library.add(faEdit, faTrashAlt, faPencilAlt, faArchive, faDownload);

export const Fragment = `
  id
  kind
  type
  title
  subtitle
  filename
  contentUri
  url
  updatedOn
  length
  ${AttachmentInputFragment}
  ${ContentPreviewFragment}
`;

const _removeAttachment = `mutation removeAttachment($id:ID) {
  attachment(id:$id) {
    remove
    commit { ${Fragment} }
  }
}`

interface InputProps {
  value: AttachmentModel;
  titlePlaceholder?: string;
  subtitlePlaceholder?: string;
  showIssuer?: boolean;
  showIssuerOrg?: boolean;
}

const _newAttachment = {} as AttachmentModel;
const _resumeUri = "hk:content:generated:resume";

export function ItemRow({ value, onUpdate }: {
  value: AttachmentModel,
  onUpdate?: (value: DeepPartial<AttachmentModel>) => void
}) {
  const [, d] = useTranslation();
  const [navigate] = useNavigation();
  const confirm = useConfirmation();
  const [i] = useIcons();
  const [mutation] = useMutation();
  const [isOpenInput, toggleInput] = useToggle();
  const remove = async () => {
    await mutation(_removeAttachment, { id: value.id });
    onUpdate?.(setDelete(value));
  }
  const showIssuer = [AttachmentType.EmploymentCertificate, AttachmentType.RecommendationLetter].includes(value.type);
  const showIssuerOrg = [AttachmentType.GraduationCertificate, AttachmentType.CourseCertificate, AttachmentType.OtherCertificate, AttachmentType.Publication].includes(value.type);
  const isGeneratedResume = !!value.contentUri?.startsWith(_resumeUri);
  return (
    <ListGroupItem className='hover-container d-flex align-items-baseline'>
      <FontAwesomeIcon fixedWidth size='lg' className='mr-1' icon={i(value.type)} />
      <span className="text-truncate">{value.subtitle}{" "}{value.title}</span>
      {isGeneratedResume ?
        <>
          <small className='text-muted ml-2 text-nowrap'>Automatically updated from your profile details.</small>
          <CardButtons className="ml-auto">
            <CardButton event="VIEW GeneratedResume" onClick={navigate(`/my/documents/${value.id}`)}>View</CardButton>
            <CardButton event="DOWNLOAD GeneratedResume" icon="download" onClick={navigate(value.url, true)}>Download</CardButton>
            <CardButton event="EDIT GeneratedResume" icon="pencil-alt" onClick={preventDefault(toggleInput)}>Edit</CardButton>
            <CardButton event="REMOVE GeneratedResume" icon="archive" onClick={confirm(remove, "Are you sure you want to remove this document?")}>Remove</CardButton>
          </CardButtons>
        </> : <>
          <small className='text-muted ml-2 text-nowrap'>Last updated {d(value.updatedOn, DateDisplayFormat.TimeAgo)}.</small>
          <Button className={`d-none d-sm-inline ml-2 hover-visible`} color="link" size="sm" onClick={navigate(`/my/documents/${value.id}`)}>View</Button>
          <Button className="d-none d-sm-block hover-visible" color="link" size="sm" onClick={preventDefault(toggleInput)}>Edit</Button>
          <Button className="d-none d-sm-block ml-auto hover-visible" color="link" size="sm" onClick={preventDefault(confirm(remove, "Are you sure you want to remove this document?"))}>Remove</Button>
          <Button className="d-sm-none" color="link" size="sm" onClick={navigate(`/my/documents/${value.id}`)}><FontAwesomeIcon fixedWidth className='ml-2 mr-1' icon={["fas", "download"]} /></Button>
          <Button className="d-sm-none ml-auto text-muted" color="link" size="sm" onClick={preventDefault(confirm(remove, "Are you sure you want to remove this document?"))}><FontAwesomeIcon icon={["far", "trash-alt"]} /></Button>
        </>
      }
      <AttachmentInputModal {...{ value, onUpdate, showIssuer, showIssuerOrg }} isOpen={isOpenInput} toggle={toggleInput} />
    </ListGroupItem>
  );
}

export default ({ className, values, onUpdate }: {
  className?: string,
  values: ReadonlyArray<AttachmentModel>,
  onUpdate?: (values: DeepPartial<ReadonlyArray<AttachmentModel>>) => void
}) => {
  const [me] = useMe();
  const [isOpenCertificates, toggleCertificates] = useToggle();
  const [inputProps, setInputProps] = useState<InputProps>();
  const hasResume = values.some(_ => _.type === AttachmentType.Resume && !_.contentUri?.startsWith(_resumeUri));
  const hasGeneratedResume = values.some(_ => _.type === AttachmentType.Resume && _.contentUri?.startsWith(_resumeUri));
  return (
    <>
      <Card className={className}>
        <CardHeader className="bg-white d-flex align-baseline">
          <h3>Documents</h3>
          <UncontrolledDropdown className="ml-auto">
            <DropdownToggle color="link" caret>Add</DropdownToggle>
            <DropdownMenu modifiers={preventOverflow}>
              <DropdownItem header>Required</DropdownItem>
              {!hasResume && <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.Resume, title: "Résumé", subtitle: me?.name || "" } })}>Résumé</DropdownItem>}
              {!hasGeneratedResume && <CustomDropdownItem viewEvent="DISCOVER GeneratedResume" event="PREVIEW GeneratedResume" onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.Resume, contentUri: _resumeUri, title: "Automatic Résumé", subtitle: me?.name || "" } })} badge="new">Automatic Résumé</CustomDropdownItem>}
              <Show scope="Wallet.Withdraw">
                <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.TaxpayerIdentification, title: "PAN card", subtitle: "India" } })}>PAN card</DropdownItem>
              </Show>
              <DropdownItem className="mt-3" header>Certificates</DropdownItem>
              <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.CourseCertificate, title: "Certificate of Completion" }, subtitlePlaceholder: "Enter course name here", showIssuerOrg: true })}>Course certificate</DropdownItem>
              <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.GraduationCertificate, title: "Graduation Certificate" }, subtitlePlaceholder: "Enter degree title here", showIssuerOrg: true })}>Graduation certificate</DropdownItem>
              <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.EmploymentCertificate, title: "Internship Certificate" }, subtitlePlaceholder: "Enter organization name here", showIssuer: true })}>Internship certificate</DropdownItem>
              <CustomDropdownItem isOpen={isOpenCertificates} toggle={false} onClick={toggleCertificates}>Additional certificates</CustomDropdownItem>
              <Collapse isOpen={isOpenCertificates} className="ml-3">
                <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.EmploymentCertificate, title: "Employment certificate" }, subtitlePlaceholder: "Enter organization name here" })}>Employment certificate</DropdownItem>
                <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.OtherCertificate, title: "Certificate of Participation" }, subtitlePlaceholder: "Enter event name here", showIssuerOrg: true })}>Participation certificate</DropdownItem>
                <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.OtherCertificate, title: "Certificate of ________" }, subtitlePlaceholder: "Enter event name here", showIssuerOrg: true })}>Other certificate</DropdownItem>
              </Collapse>
              <DropdownItem className="mt-3" header>Other documents</DropdownItem>
              <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.BusinessCard }, titlePlaceholder: "Enter position title here", subtitlePlaceholder: "Enter organization name here", showIssuer: false })}>Business card</DropdownItem>
              <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.ProfessionalLicense }, subtitlePlaceholder: "Enter issuing organization name here" })}>Professional license</DropdownItem>
              <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.Publication }, subtitlePlaceholder: "Enter publishing organization name here" })}>Publication</DropdownItem>
              <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.RecommendationLetter, title: "Recommendation Letter" }, subtitlePlaceholder: "Enter organization name here", showIssuer: true })}>Recommendation letter</DropdownItem>
              <DropdownItem onClick={() => setInputProps({ value: { ..._newAttachment, type: AttachmentType.EducationalTranscript, title: "Transcript" }, subtitlePlaceholder: "Enter institution name here" })}>Transcript or marksheet</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardHeader>
        <ListGroup flush>
          {values.filter(_ => _.type !== AttachmentType.Photo).map((v, i) => <ItemRow key={i} value={v} onUpdate={value => onUpdate?.([value])} />)}
          {values.length === 0 && <ListGroupItem className="text-muted">Use documents to share your résumé and showcase your credentials.</ListGroupItem>}
        </ListGroup>
      </Card>
      <AttachmentInputModal {...inputProps} isOpen={!!inputProps} toggle={() => setInputProps(undefined)} value={inputProps?.value || _newAttachment} onUpdate={value => onUpdate?.([value])} />
    </>
  );
}
