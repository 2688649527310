import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useNavigation } from "../hooks/NavigationHook";
import { useTitle } from "../hooks/TitleHook";
import Image from '../images/for-employers.svg';

function HeroRow() {
  const [navigate] = useNavigation();
  useTitle("HigherKnowledge for Employers");
  return (
    <Row>
      <Card className="w-100 b-0 text-white" color="dark">
        <CardBody>
          <Row className="align-items-center">
            <Col sm={8} md={7} lg={8}>
              <h1>HigherKnowledge for your recruiting</h1>
              <h3>
                We manage your recruiting inbox to help you
                find candidates with real intelligence.
              </h3>
              <div className="mt-3 d-flex">
                <Button className="mr-2" color="primary" onClick={navigate("/sign-up?scope=claim:MELLON")}>
                  <h4 className="mb-0">Sign up for free</h4>
                </Button>
                <Button className="mr-2" color="light" outline onClick={navigate("/sign-in")}>
                  <h4 className="mb-0">Sign in</h4>
                </Button>
              </div>
            </Col>
            <Col className="d-none d-sm-block text-center" sm={4} md={5} lg={4}>
              <img width="70%" src={Image} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
}

export default () => {
  useTitle("HigherKnowledge for Employers");
  return (
    <>
      <HeroRow />
    </>
  );
}
