import React from "react";
import { CardSubtitle, CardText, CardTitle, Row, Col } from "reactstrap";
import SearchLink from "../../components/SearchLink";
import { LegalResearchStatuteModel, ReviewOption, TaskModel, TaskType } from "../../types/api-graph-types";
import { DocumentType, useDocumentContext } from "../../hooks/DocumentProvider";
import CiteLink from "../../components/CiteLink";
import FormGroup, { len, isUrl, between } from "../../components/FormGroup";
import Input from "../../components/Input";
import { ApiErrors, useMutation } from "../../hooks/ApiProvider";
import Highlight from "../../components/Highlight";

export const Fragment = `... on LegalResearchStatuteModel { 
  searchQuery
  title
  year
  section
  excerpt
  url
}`;

const _updateTaskDetails = `mutation updateLegalResearchStatuteTaskDetails($id:ID! $value:LegalResearchStatuteInputModel) {
  task(id:$id) {
    details { ... on LegalResearchStatuteOperations { change(value:$value) } } 
    commit { details { ${Fragment} } }
  }
}`;

interface OperationResults {
  task: {
    commit: LegalResearchStatuteModel
  }
};

const ToInput = (value: LegalResearchStatuteModel) => ({
  searchQuery: value.searchQuery || "",
  url: value.url || "",
  title: value.title || "",
  year: value.year || "",
  section: value.section || "",
  excerpt: value.excerpt || ""
} as any);

export const CommentOptions: [number, number, ReviewOption, number, string][] = [
  [1, 2, ReviewOption.Empty, 0, ""],
  [1, 2, ReviewOption.Spam, -1, ""],
  [1, 2, ReviewOption.Offensive, -1, ""],
  [1, 2, ReviewOption.Plagiarised, -1, ""],
  [3, 8, ReviewOption.LegalResearchStatuteSearchQueryPoor, 0, ""],
  [3, 4, ReviewOption.LegalResearchStatuteTitlePoor, 0, ""],
  [3, 4, ReviewOption.LegalResearchStatuteSectionPoor, 0, ""],
  [5, 6, ReviewOption.LegalResearchStatuteSectionFair, 0, ""],
  [3, 8, ReviewOption.LegalResearchStatuteUrlPoor, 0, ""],
  [3, 4, ReviewOption.LegalResearchStatuteExcerptPoor, 0, ""],
  [5, 6, ReviewOption.LegalResearchStatuteExcerptFair, 0, ""],
  [7, 10, ReviewOption.LegalResearchStatuteExcerptExcellent, 1, ""],
];

export const Is = (value: TaskModel): value is TaskModel & { details: LegalResearchStatuteModel } =>
  value.type === TaskType.LegalResearchStatute

export const IsValid = (value: LegalResearchStatuteModel) =>
  value.searchQuery && len(value.searchQuery) <= 140
  && value.url && isUrl(value.url)
  && value.title && between(len(value.title), 14, 140)
  && value.year && between(parseInt(value.year), 1200, 2200)
  && value.section && between(len(value.section), 0, 140)
  && value.excerpt && between(len(value.excerpt), 140, 1400)
  || false;

export function InputText({ id, value, help, onUpdate }: {
  value: LegalResearchStatuteModel,
  id: string,
  help?: boolean,
  onUpdate: (value: LegalResearchStatuteModel) => void
}) {
  const input = ToInput(value || {});
  const [mutation] = useMutation<OperationResults>();
  const update = async (partial: Partial<LegalResearchStatuteModel>) => {
    const value = { ...input, ...partial };
    onUpdate(value);
    await mutation(_updateTaskDetails, { id, value }, { debounce: 5000 });
  }
  return (
    <CardText tag="div">
      <FormGroup label="Search Query" openHelp={help} help="Use Google or a law related search engine to find relevant acts/statutes. Use keywords from before, keywords from the pages you found, in addition to keywords like &quot;act&quot; or &quot;law&quot;. You may also restrict your Google search results to specific websites using &quot;site:&quot; in your search terms." required maxLength={140} value={input.searchQuery}>
        <Input type="text" onUpdate={searchQuery => update({ searchQuery })} />
      </FormGroup>
      <FormGroup label="Url" openHelp={help} help="URL of webpage containing relevant portion of the act or statute. When looking through search results, find reputed websites that contain the text of the act/statute. Government sites, websites of reputed law firms, are good candidates. Blog sites are less reliable, but still sometimes useful." required url value={input.url}>
        <Input type="text" spellCheck={false} onUpdate={url => update({ url })} />
      </FormGroup>
      <FormGroup label="Title" openHelp={help} help="Name of the act or statute. Do not include the year of the act in the name." required minLength={14} maxLength={140} value={input.title}>
        <Input type="text" onUpdate={title => update({ title })} />
      </FormGroup>
      <Row form>
        <Col sm={3}>
          <FormGroup label="Year" openHelp={help} help="Year of the most recent revision of the act or statute" number minValue={1200} maxValue={2200} required value={input.year}>
            <Input type="text" onUpdate={year => update({ year })} />
          </FormGroup>
        </Col>
        <Col sm={9}>
          <FormGroup label="Section" openHelp={help} help="Relevant section, article or paragraph number." required maxLength={140} value={input.section}>
            <Input type="text" onUpdate={section => update({ section })} />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup label="Excerpt" openHelp={help} help="Excerpt of article or paragraph most relevant to the matter. Use Ctrl-F in the browser to search within the text of the act. Find the paragraph that is most relevant to the matter above. You must be able to rely on the excerpt for your final opinion below." required minLength={140} maxLength={1400} value={input.excerpt}>
        <Input type="textarea" rows={5} onUpdate={excerpt => update({ excerpt })} />
      </FormGroup>
    </CardText>
  );
}

export default ({ value, }: {
  value: LegalResearchStatuteModel
}) => {
  const [documentType] = useDocumentContext();
  return (
    <>
      <CardSubtitle tag='h5'>
        <Highlight value={value.section} />
      </CardSubtitle>
      <CardTitle tag='h3' className="text-capitalize">
        <Highlight value={`${(value.title || "").toLowerCase()}, ${value.year}`} />
      </CardTitle>
      <blockquote className='text-justify'>
        <Highlight value={value.excerpt} />
        <CiteLink title={value.title} href={value.url} />
      </blockquote>
      {documentType !== DocumentType.Document && <CardText><SearchLink query={value.searchQuery} /></CardText>}
    </>
  );
}
