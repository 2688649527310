import moment from "moment";
import { useEffect, useState } from "react";

export function useTimer(timestamp: string | undefined) {
  const [tick, setTick] = useState(0);
  const value = timestamp && moment(timestamp) || undefined;
  const secondsLeft = value && value.diff(moment(), "s") || undefined;
  useEffect(() => {
    if (secondsLeft && secondsLeft > 0) {
      const interval = secondsLeft > 3600 ? 60 : secondsLeft > 120 ? 30 : 5;
      const timer = setTimeout(() => setTick(tick + 1), interval * 1000);
      return () => clearTimeout(timer);
    }
  }, [tick, timestamp]);
  return [value];
}
